import * as React from 'react';
import Svg, { Path, G, Rect } from 'react-native-svg';

import { BLACK } from '../../../styles/colors';

type Props = {
  size?: string | number;
  color?: string;
  disabled?: boolean;
  onPress?: () => void;
};

const ClientsMenu: React.FC<Props> = ({ size = 24, color = BLACK, disabled = false, onPress }) => (
  <Svg width={size} height={size} viewBox="0 0 24 24" disabled={disabled} onPress={onPress}>
    <G transform="translate(-285.064 -408.773)">
      <Rect width="24" height="24" transform="translate(285.064 408.773)" fill="none" />
      <Path
        d="M308.769,429.289h-7.8a.806.806,0,0,1,0-1.613h6.713a2.628,2.628,0,0,0-2.072-1.377c-3.2-.64-3.6-2.012-3.59-3.136v-.655a.806.806,0,0,1,.8-.806,20.117,20.117,0,0,0,3.139-.255c-.966-1.138-1.563-2.639-1.563-6.405a3.634,3.634,0,0,0-1.162-3.1c-1.4-1.029-3.712-.318-3.735-.31a.683.683,0,0,1-.257.038H299.2a2.525,2.525,0,0,0-2.5,2.1.807.807,0,1,1-1.589-.28,4.131,4.131,0,0,1,4.023-3.43c.614-.17,3.19-.771,5.042.58a5.114,5.114,0,0,1,1.828,4.41c0,4.28.786,5.031,1.78,5.984q.181.171.364.354a.807.807,0,0,1-.387,1.354,21.778,21.778,0,0,1-4.132.564c.027.467.266,1.016,2.21,1.408a4.2,4.2,0,0,1,3.725,3.672.806.806,0,0,1-.8.911Z"
        transform="translate(-0.511)"
        fill={color}
      />
      <Path
        d="M302.225,432.425H285.871a.807.807,0,0,1-.794-.947,4.814,4.814,0,0,1,4.149-3.951c1.156-.143,2.529-.481,2.529-1.289v0a6.629,6.629,0,0,1-1.618-2.295c-1.078-.5-1.318-2.3-1.318-2.843a3.162,3.162,0,0,1,.4-1.728,5.635,5.635,0,0,1,.64-4.381,2.241,2.241,0,0,1,1.546-.886c2.162-1.413,4.633-1.546,6.348-.321,1.6,1.144,2.2,3.231,1.655,5.645a3.477,3.477,0,0,1,.395,1.7c0,.533-.234,2.3-1.28,2.825a6.229,6.229,0,0,1-1.4,2.288c0,.367.012.975,2.271,1.288a4.1,4.1,0,0,1,3.642,4.088A.806.806,0,0,1,302.225,432.425Zm-15.236-1.613h14.3a2.492,2.492,0,0,0-2.087-1.678l-.02,0c-3.256-.449-3.674-1.782-3.674-2.894v-.331a.807.807,0,0,1,.258-.591,4.654,4.654,0,0,0,1.333-2.225.806.806,0,0,1,.7-.6,3.191,3.191,0,0,0,.387-1.369,2.987,2.987,0,0,0-.114-.773.8.8,0,0,1-.339-.9c.556-1.955.22-3.544-.922-4.359-1.2-.86-3.041-.679-4.681.462a.807.807,0,0,1-.46.145.643.643,0,0,0-.543.281,4.614,4.614,0,0,0-.235,3.5.811.811,0,0,1-.373.889,3.323,3.323,0,0,0-.086.729,3.066,3.066,0,0,0,.382,1.362.808.808,0,0,1,.689.539,5.009,5.009,0,0,0,1.569,2.243.809.809,0,0,1,.3.626v.369c0,1.6-1.326,2.567-3.943,2.889A3.2,3.2,0,0,0,286.989,430.813Z"
        transform="translate(0 -0.162)"
        fill={color}
      />
    </G>
  </Svg>
);

export default React.memo(ClientsMenu);
