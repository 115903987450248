import { CheckoutTransactionCodeC } from '../../checkout/checkoutHttpClient/checkoutJson';
import { CheckoutTransactionCode } from '../../checkout/checkoutTransactionCode';
import { ClientId } from '../../clients';
import { ProductId } from '../../pro/products/product/productId';
import { CheckoutReportTotals } from './checkoutReportTotals';
import {
  JSONable,
  MeroUnits,
  Money,
  ScaledNumber,
  optionull,
  OptionalFirstname,
  OptionalLastname,
} from '@mero/shared-sdk';
import * as t from 'io-ts';
import * as tt from 'io-ts-types';

export type CheckoutSaleOwnerProductSalesReportItem<Unit extends MeroUnits.Any> = {
  readonly product: {
    readonly _id: ProductId;
    readonly name: string;
  };
  readonly client:
    | {
        readonly _id: ClientId;
        readonly firstname: OptionalFirstname;
        readonly lastname: OptionalLastname;
        readonly phone: string;
      }
    | undefined;
  readonly finishedAt: Date;
  /**
   * Transaction code
   */
  readonly code: CheckoutTransactionCode;
  /**
   * Product quantity
   */
  readonly quantity: ScaledNumber;
  /**
   * Items totals
   */
  readonly totals: CheckoutReportTotals<Unit>;
};

const json = <Unit extends MeroUnits.Any>(
  unit: Unit,
): t.Type<CheckoutSaleOwnerProductSalesReportItem<Unit>, JSONable> => {
  return t.type(
    {
      product: t.type(
        {
          _id: ProductId,
          name: t.string,
        },
        'Product',
      ),
      client: optionull(
        t.type(
          {
            _id: ClientId,
            firstname: OptionalFirstname,
            lastname: OptionalLastname,
            phone: t.string,
          },
          'Client',
        ),
      ),
      finishedAt: tt.DateFromISOString,
      code: CheckoutTransactionCodeC,
      totals: CheckoutReportTotals.json(unit),
      quantity: ScaledNumber.JSON,
    },
    `CheckoutSaleOwnerProductSalesReportItem<${unit}>`,
  );
};

export const CheckoutSaleOwnerProductSalesReportItem = {
  json,
};
