import { moneyC } from '../../memberships/membershipJson';
import { CheckoutReportTotals } from './checkoutReportTotals';
import { JSONable, MeroUnits, PositiveInt, Numbers, Money, ScaledNumber } from '@mero/shared-sdk';
import * as t from 'io-ts';

export type CheckoutServiceSalesReportTotals<Unit extends MeroUnits.Any> = {
  /**
   * Total number of times service was found in checkout transaction (including services on a booking)
   */
  readonly count: Numbers.Zero | PositiveInt;
  /**
   * Total quantity of service in checkout transaction (including services on a booking)
   */
  readonly quantity: Numbers.Zero | PositiveInt;
  /**
   * Items totals
   */
  readonly totals: CheckoutReportTotals<Unit>;
  /**
   * Membership consumption total
   */
  readonly membershipConsumption: Money<ScaledNumber, Unit>;
};

const json = <Unit extends MeroUnits.Any>(unit: Unit): t.Type<CheckoutServiceSalesReportTotals<Unit>, JSONable> => {
  return t.type(
    {
      count: t.union([Numbers.Zero, PositiveInt.JSON]),
      quantity: t.union([Numbers.Zero, PositiveInt.JSON]),
      totals: CheckoutReportTotals.json(unit),
      membershipConsumption: moneyC(unit),
    },
    `CheckoutServiceSalesReportTotals<${unit}>`,
  );
};

export const CheckoutServiceSalesReportTotals = {
  json,
};
