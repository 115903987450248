import { JSONable, optionull } from '@mero/shared-sdk';
import * as t from 'io-ts';

export type OwnAppointmentNotificationSettings = {
  readonly ownAppointmentCancelledByAnotherPro: boolean;
  readonly ownAppointmentCancelledByClient: boolean;
  readonly ownAppointmentModifiedByAnotherPro: boolean;
  readonly ownAppointmentAddedByAnotherPro: boolean;
  readonly ownAppointmentAddedByClient: boolean;
  readonly ownAppointmentRequestedByClient: boolean;
  readonly privateWorkerClientJoinRequest: boolean;
  readonly manualSmsReminder: boolean;
  readonly clientAddedToOwnWaitingList: boolean | undefined;
};

const JSON: t.Type<OwnAppointmentNotificationSettings, JSONable> = t.type(
  {
    ownAppointmentCancelledByAnotherPro: t.boolean,
    ownAppointmentCancelledByClient: t.boolean,
    ownAppointmentModifiedByAnotherPro: t.boolean,
    ownAppointmentAddedByAnotherPro: t.boolean,
    ownAppointmentAddedByClient: t.boolean,
    ownAppointmentRequestedByClient: t.boolean,
    privateWorkerClientJoinRequest: t.boolean,
    manualSmsReminder: t.boolean,
    clientAddedToOwnWaitingList: optionull(t.boolean),
  },
  'OwnAppointmentNotificationSettings',
);

const DEFAULT: OwnAppointmentNotificationSettings = {
  ownAppointmentCancelledByAnotherPro: true,
  ownAppointmentCancelledByClient: true,
  ownAppointmentModifiedByAnotherPro: true,
  ownAppointmentAddedByAnotherPro: true,
  ownAppointmentAddedByClient: true,
  ownAppointmentRequestedByClient: true,
  privateWorkerClientJoinRequest: true,
  manualSmsReminder: true,
  clientAddedToOwnWaitingList: true,
};

export const OwnAppointmentNotificationSettings = {
  JSON,
  DEFAULT,
};
