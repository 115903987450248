import { PromotionInterval } from '../boost/promotionInterval';
import { BusinessHours } from '../business';
import { PageGiftCardSettings } from '../pro/onlinePayments/onlinePaymentsSettings/pageGiftCardSettings';
import { ServiceGroup, SavedService } from '../services';
import { WorkerId } from '../workers';
import { PageId } from './page-id';
import { PageImage } from './pageImage';
import { PageOnlinePaymentsSettings } from './pageOnlinePaymentsSettings';
import { UserPageRole } from './user-page-role';
import { Location, OptionalEmail, optionull } from '@mero/shared-sdk';
import { ProfileImage } from '@mero/shared-sdk/dist/assets/profile-image';
import * as t from 'io-ts';

export const PageDetails = t.intersection(
  [
    t.type(
      {
        _id: PageId,
        slug: t.string,
        name: t.string,
        phoneNo: t.string,
        description: t.string,
        published: t.boolean,
        profilePhoto: ProfileImage,
        images: t.array(PageImage.JSON),
        services: t.array(SavedService),
        serviceGroups: t.array(ServiceGroup),
        people: t.array(UserPageRole),
        businessHours: BusinessHours.JSON,
        feedback: t.type({
          score: t.number,
          total: t.number,
        }),
        /**
         * @deprecated moved to pageOnlinePaymentsSettings
         */
        giftCardSettings: PageGiftCardSettings.JSON,
      },
      '!',
    ),
    t.partial(
      {
        searchableRequested: t.union([t.boolean, t.null]),
        publishRequested: t.union([t.boolean, t.null]),
        isSearchable: t.union([t.boolean, t.null]),
        email: OptionalEmail.TRUSTED_JSON,
        location: t.union([Location, t.null]),
        /**
         * @deprecated moved to pageOnlinePaymentsSettings
         */
        hasOnlinePaymentsEnabled: optionull(t.boolean),
        /**
         * @deprecated moved to pageOnlinePaymentsSettings
         */
        onlinePaymentsSettings: PageOnlinePaymentsSettings.JSON,
        hasStripeAccountConnected: t.union([t.boolean, t.null]),
        workerIds: t.array(WorkerId.JSON),
        /**
         * @deprecated use `seatCount` field from SubscriptionInfo
         */
        availableWorkerSeatCount: t.union([t.number, t.null]),
        promotionIntervals: t.array(PromotionInterval),
        hideWorkerReviews: t.boolean,
        isCashRegistryEnabled: t.boolean,
      },
      '?',
    ),
  ],
  'PageDetails',
);

export interface PageDetails extends t.TypeOf<typeof PageDetails> {}

export const PageClient = t.type({
  isPageClient: t.boolean,
});

export interface PageClient extends t.TypeOf<typeof PageClient> {}
