import { CheckoutReportPaymentsByType } from './checkoutReportPaymentsByType';
import { CheckoutReportPaymentsDetailed } from './checkoutReportPaymentsDetailed';
import { CheckoutReportPercents } from './checkoutReportPercents';
import { CheckoutReportTotals } from './checkoutReportTotals';
import {
  HasId,
  HasOptionalFirstLastName,
  JSONable,
  MeroUnits,
  Money,
  PortionPercentScaledNumber,
  PositiveInt,
  ScaledNumber,
  UserId,
  Numbers,
} from '@mero/shared-sdk';
import * as t from 'io-ts';

export type CheckoutSaleOwnerSalesReportItem<Unit extends MeroUnits.Any> = {
  /**
   * Owner of the sale
   */
  readonly saleOwner: HasId<UserId> & HasOptionalFirstLastName;
  /**
   * Number of sold bookings that was assigned to this sale owner (PRO)
   */
  readonly appointmentsCount: Numbers.Zero | PositiveInt;
  /**
   * Sale owner appointments count percent from total appointments count
   */
  readonly appointmentsPercent: PortionPercentScaledNumber;
  /**
   * Number of sold memberships that was assigned to this sale owner (PRO)
   */
  readonly membershipsCount: Numbers.Zero | PositiveInt;
  /**
   * Sale owner memberships count percent from total memeberships count
   */
  readonly membershipsPercent: PortionPercentScaledNumber;
  /**
   * Number of sold products that was assigned to this sale owner
   */
  readonly productsCount: number;
  /**
   * Sale owner products count percent from total products count
   */
  readonly productsPercent: PortionPercentScaledNumber;
  /**
   * Total number of sold services that was assigned to this sale owner (including services on booking)
   */
  readonly servicesCount: Numbers.Zero | PositiveInt;
  /**
   * Sale owner services count percent from total products count
   */
  readonly servicesPercent: PortionPercentScaledNumber;
  /**
   * Services totals (includes only sold services, without services paid with membership)
   */
  readonly servicesTotals: CheckoutReportTotals<Unit>;
  /**
   * Products totals (includes only sold products, without products paid with membership)
   */
  readonly productsTotals: CheckoutReportTotals<Unit>;
  /**
   * Delivered totals (includes services and products paid with or without membership)
   */
  readonly deliveredTotals: CheckoutReportTotals<Unit>;
  /**
   * Items totals
   */
  readonly totals: CheckoutReportTotals<Unit>;
  /**
   * Totals percents
   */
  readonly percents: CheckoutReportPercents;
  /**
   * Payments by type
   */
  readonly payments: CheckoutReportPaymentsByType<Unit>;
  /**
   * Payments by type (vat applied)
   */
  readonly paymentsDetailed: CheckoutReportPaymentsDetailed<Unit>;
  /**
   * Membership consumption total
   */
  readonly membershipConsumption: Money<ScaledNumber, Unit>;
};

const json = <Unit extends MeroUnits.Any>(unit: Unit): t.Type<CheckoutSaleOwnerSalesReportItem<Unit>, JSONable> => {
  return t.type(
    {
      saleOwner: t.intersection([HasId.json(UserId), HasOptionalFirstLastName], 'SaleOwner'),
      appointmentsCount: t.union([Numbers.Zero, PositiveInt.JSON]),
      appointmentsPercent: PortionPercentScaledNumber.JSON,
      membershipsCount: t.union([Numbers.Zero, PositiveInt.JSON]),
      membershipsPercent: PortionPercentScaledNumber.JSON,
      productsCount: t.number,
      productsPercent: PortionPercentScaledNumber.JSON,
      servicesCount: t.union([Numbers.Zero, PositiveInt.JSON]),
      servicesPercent: PortionPercentScaledNumber.JSON,
      servicesTotals: CheckoutReportTotals.json(unit),
      productsTotals: CheckoutReportTotals.json(unit),
      deliveredTotals: CheckoutReportTotals.json(unit),
      totals: CheckoutReportTotals.json(unit),
      percents: CheckoutReportPercents.JSON,
      payments: CheckoutReportPaymentsByType.json(unit),
      paymentsDetailed: CheckoutReportPaymentsDetailed.json(unit),
      membershipConsumption: Money.json(ScaledNumber.JSON, t.literal(unit)),
    },
    `CheckoutSaleOwnerSalesReportItem<${unit}>`,
  );
};

export const CheckoutSaleOwnerSalesReportItem = {
  json,
};
