import { ObjectId } from '@mero/shared-sdk';
import * as t from 'io-ts';

export interface ReminderIdBrand {
  readonly ReminderId: unique symbol;
}

export const ReminderId = t.brand(
  ObjectId,
  (_id: ObjectId): _id is t.Branded<ObjectId, ReminderIdBrand> => true,
  'ReminderId',
);

export type ReminderId = t.TypeOf<typeof ReminderId>;
