import { JSONable } from '@mero/shared-sdk';
import * as t from 'io-ts';

export type OnlinePaymentsCommissionInfo = {
  readonly feeText: string;
};

const JSON: t.Type<OnlinePaymentsCommissionInfo, JSONable> = t.type(
  {
    feeText: t.string,
  },
  'OnlinePaymentsCommissionInfo',
);

export const OnlinePaymentsCommissionInfo = {
  JSON,
};
