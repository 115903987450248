import { Email } from './email';
import { Option } from './option';
import { UndefinedString } from './string';
import * as t from 'io-ts';

export type OptionalEmail = Option<Email>;

/**
 * Email or undefined, where undefined may be parsed from null, undefined or empty string
 */
const JSON: t.Type<OptionalEmail, Option<string>> = t.union([Email.JSON, UndefinedString], 'OptionalEmail');

/**
 * Decode OptionalEmail type without validation
 * Only use this to read data from server to avoid breaking changes in data validation so clients won't crash for reads
 */
const TRUSTED_JSON: t.Type<OptionalEmail, Option<string>> = t.union(
  [Email.TRUSTED_JSON, UndefinedString],
  'OptionalEmail',
);

export const OptionalEmail = {
  JSON,
  TRUSTED_JSON,
};
