import * as React from 'react';
import Svg, { G, Path, Rect } from 'react-native-svg';

type Props = {
  size?: string | number;
  color?: string;
  disabled?: boolean;
  onPress?: () => void;
};

const Info: React.FC<Props> = ({ size = 24, color = '#f5365c', disabled = false, onPress }) => (
  <Svg width={size} height={size} viewBox={`0 0 ${size} ${size}`} disabled={disabled} onPress={onPress}>
    <G id="Group_6115" data-name="Group 6115" transform="translate(-2024 -17486)">
      <Path
        id="error_outline-black-24dp"
        d="M10.326,14.027h1.85v1.85h-1.85Zm0-7.4h1.85v5.551h-1.85ZM11.242,2A9.252,9.252,0,1,0,20.5,11.252,9.247,9.247,0,0,0,11.242,2Zm.009,16.653a7.4,7.4,0,1,1,7.4-7.4A7.4,7.4,0,0,1,11.252,18.653Z"
        transform="translate(2024.65 17486.65)"
        fill={color}
      />
      <Rect
        id="Rectangle_1487"
        data-name="Rectangle 1487"
        width={size}
        height={size}
        transform="translate(2024 17486)"
        fill="none"
      />
    </G>
  </Svg>
);

export default React.memo(Info);
