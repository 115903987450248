import { CheckoutItemTotal } from '../checkoutItemTotal';
import { CheckoutItemTotalScaledNumber } from '../checkoutItemTotalScaledNumber';
import { CheckoutTotals } from '../checkoutTotals';
import { CheckoutTotalsScaledNumber } from '../checkoutTotalsScaledNumber';
import { isItemPaidWithMembership } from '../checkoutTransactionDetails/utils';
import { CheckoutTransactionPayment } from '../checkoutTransactionPayment';
import { CheckoutTransactionServiceDetails } from '../checkoutTransactionServiceDetails';
import { CheckoutUserPreview } from '../checkoutUserPreview';
import { CompanyVatStatus } from '../companies';
import { MeroUnits, ScaledNumber, Money, PositiveInt } from '@mero/shared-sdk';

export type Service<Unit extends MeroUnits.Any> = {
  readonly type: 'Service';
  readonly transactionItemId?: string;
  readonly service: CheckoutTransactionServiceDetails<Unit>;
  readonly quantity: PositiveInt;
  /**
   * This is the total value for all units, SHOULD NOT be multiplied by quantity to get the total
   */
  readonly total: CheckoutItemTotal<ScaledNumber, Unit>;
  readonly saleOwner: CheckoutUserPreview;
};

export const service = <Unit extends MeroUnits.Any>(params: {
  transactionItemId?: string;
  service: CheckoutTransactionServiceDetails<Unit>;
  quantity: PositiveInt;
  total: CheckoutItemTotal<ScaledNumber, Unit>;
  saleOwner: CheckoutUserPreview;
}): Service<Unit> => {
  return {
    type: 'Service',
    transactionItemId: params.transactionItemId,
    service: params.service,
    quantity: params.quantity,
    total: params.total,
    saleOwner: params.saleOwner,
  };
};

const getTotals = <Unit extends MeroUnits.Any>(
  item: Service<Unit>,
  unit: Unit,
  payments: CheckoutTransactionPayment.Any<ScaledNumber, Unit>[],
  companyVat: CompanyVatStatus.Any<ScaledNumber>,
  decimals: number,
  includeItemsPaidWithMembership = false,
): CheckoutTotals<ScaledNumber, Unit> => {
  const itemPaidWithMembership = isItemPaidWithMembership(item, payments);
  if (itemPaidWithMembership.isPaidWithMembership) {
    if (includeItemsPaidWithMembership) {
      const effectivePrice = itemPaidWithMembership.effectivePrice;

      return CheckoutItemTotalScaledNumber.getTotals(
        {
          amount: Money.of(
            ScaledNumber.mul(effectivePrice.amount.amount, ScaledNumber.fromInteger(item.quantity)),
            unit,
          ),
          vatStatus: effectivePrice.vatStatus,
        },
        companyVat,
        decimals,
      );
    }

    return CheckoutTotalsScaledNumber.zero(unit);
  }

  return CheckoutItemTotalScaledNumber.getTotals(item.total, companyVat, decimals);
};

/**
 * Check if given service item is paid with a membership
 */
const isPaidWithMembership = <Unit extends MeroUnits.Any>(
  item: Service<Unit>,
  unit: Unit,
  payments: CheckoutTransactionPayment.Any<ScaledNumber, Unit>[],
  companyVat: CompanyVatStatus.Any<ScaledNumber>,
  decimals: number,
):
  | {
      readonly isPaidWithMembership: true;
      readonly totals: CheckoutTotals<ScaledNumber, Unit>;
    }
  | {
      readonly isPaidWithMembership: false;
    } => {
  const result = isItemPaidWithMembership(item, payments);
  if (result.isPaidWithMembership) {
    const totals = getTotals(item, unit, payments, companyVat, decimals, true);
    return {
      isPaidWithMembership: true,
      totals: totals,
    };
  } else {
    return {
      isPaidWithMembership: false,
    };
  }
};

export const Service = {
  getTotals,
  isPaidWithMembership,
};
