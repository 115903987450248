import * as React from 'react';
import Svg, { Path, G } from 'react-native-svg';

import { BLACK } from '../../../styles/colors';

type Props = {
  size?: string | number;
  color?: string;
  disabled?: boolean;
  onPress?: () => void;
};

const FavoritesMenu = ({ size = 24, color = BLACK, disabled, onPress }: Props) => (
  <Svg width={size} height={size} viewBox="0 0 24 24" disabled={disabled} onPress={onPress}>
    <G transform="translate(645.364 15931)">
      <Path
        d="M18.5,5A5.964,5.964,0,0,0,13,8.667,5.964,5.964,0,0,0,7.5,5,5.5,5.5,0,0,0,2,10.5c0,4.767,4.033,9.533,11,14.3,6.967-4.767,11-9.533,11-14.3A5.5,5.5,0,0,0,18.5,5Z"
        transform="translate(-646.514 -15933.044)"
        fill="none"
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit="10"
        strokeWidth="1.7"
      />
    </G>
  </Svg>
);

export default React.memo(FavoritesMenu);
