export const BLACK = '#000000';
export const COMET = '#52577F';
export const CADET_BLUE = '#A8ABBF';
export const ATHENS_GRAY = '#E9ECEF';
export const ALABASTER = '#FAFAFA';
export const WHITE = '#FFFFFF';
export const DARK_BLUE = '#080DE0';
export const PORTAGE = '#A27AF1';
export const CHAMPAGNE = '#F9E0D2';
export const SHAMROCK = '#2DCE89';
export const RADICAL_RED = '#F5365C';
export const YELLOW_ORANGE = '#FBAC40';
export const OUTRAGEOUS_ORANGE = '#FB6340';
export const ELECTRIC_VIOLET = '#7431F7';
export const NILE_BLUE = '#172B4D';
export const SKY_BLUE = '#F2F2FE';
/**
 * Inputs border
 */
export const GEYSER = '#DEE2E6';
/**
 * @deprecated
 */
export const DOVE_GRAY = '#707070';
/**
 * @deprecated
 */
export const SANTAS_GRAY = '#A2A4B9';
/**
 * @deprecated
 */
export const MISCHKA = '#D0D2DC';
