import { DefinedString } from './string';
import * as t from 'io-ts';

export interface DateStringBrand {
  readonly DateString: unique symbol;
}

/**
 * Decodes a valid date string, in YYYY-MM-DD format
 */
export const DateString = t.brand(
  DefinedString,
  (s): s is t.Branded<DefinedString, DateStringBrand> => /^\d{4}-(0[1-9]|1[0-2])-(0[1-9]|[12]\d|3[01])$/.test(s),
  'DateString',
);

export type DateString = t.TypeOf<typeof DateString>;

const fromDate = (date: Date): DateString => {
  const year = date.getFullYear();

  const month = date.getMonth() + 1;
  const formattedMonth = month < 10 ? `0${month}` : month;

  const day = date.getDate();
  const formattedDay = day < 10 ? `0${day}` : day;

  return `${year}-${formattedMonth}-${formattedDay}` as DateString;
};

const toDate = (dateString: DateString): Date => {
  const [year, month, day] = dateString.split('-').map(Number);
  return new Date(year, month - 1, day);
};

export const dateStringUtils = {
  fromDate,
  toDate,
};
