import { ObjectId } from '@mero/shared-sdk';
import * as t from 'io-ts';

export interface PurchasedMembershipHistoryRecordIdBrand {
  readonly PurchasedMembershipHistoryRecordId: unique symbol;
}

export type PurchasedMembershipHistoryRecordId = t.Branded<ObjectId, PurchasedMembershipHistoryRecordIdBrand>;

const JSON: t.Type<PurchasedMembershipHistoryRecordId, string> = t.brand(
  ObjectId,
  (_id: ObjectId): _id is PurchasedMembershipHistoryRecordId => true,
  'PurchasedMembershipHistoryRecordId',
);

export const PurchasedMembershipHistoryRecordId = {
  JSON,
};
