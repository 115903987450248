import * as React from 'react';
import { View, Text, StyleProp, ViewStyle, Platform, TextStyle, Pressable } from 'react-native';

const getHeight = (component: Text): Promise<number> =>
  new Promise((resolve) => component.measure((_x, _y, _w, h) => resolve(h)));

const nextFrameAsync = (): Promise<void> => new Promise((resolve) => window.requestAnimationFrame(() => resolve()));

type State = {
  fullHeight?: number;
  height?: number;
  shouldShowMore: boolean;
  isExpanded: boolean;
};

type Action = {
  type: 'update';
  payload: Record<string, unknown>;
};

const initialState = {
  fullHeight: undefined,
  height: undefined,
  shouldShowMore: false,
  isExpanded: false,
};

const reducer = (state: State, action: Action) => {
  switch (action.type) {
    case 'update':
      return { ...state, ...action.payload };
    default:
      throw new Error();
  }
};

type ReadMoreProps = {
  style?: StyleProp<ViewStyle>;
  numberOfLines?: number;
  onShowMore?: () => void;
  onShowLess?: () => void;
  children: React.ReactNode | React.ReactNode[];
};

const ReadMore = ({
  style,
  onShowMore = () => null,
  onShowLess = () => null,
  numberOfLines = 2,
  children,
}: ReadMoreProps): React.ReactElement => {
  const [state, dispatch] = React.useReducer(reducer, initialState);
  const ref = React.useRef<Text>(null);

  const calculateHeight = React.useCallback(
    async (component: Text) => {
      await nextFrameAsync();
      const height = await getHeight(component);
      if (state.fullHeight && state.fullHeight > height) {
        dispatch({
          type: 'update',
          payload: {
            height,
            shouldShowMore: true,
          },
        });
      } else {
        dispatch({
          type: 'update',
          payload: {
            fullHeight: height,
          },
        });
      }
    },
    [state.fullHeight],
  );

  React.useEffect(() => {
    if (ref.current) {
      calculateHeight(ref.current);
    }
  }, [calculateHeight]);

  React.useEffect(() => {
    if (state.fullHeight && ref.current) {
      calculateHeight(ref.current);
    }
  }, [calculateHeight, state.fullHeight]);

  const onShowMorePress = () => {
    onShowMore();
    dispatch({
      type: 'update',
      payload: {
        isExpanded: true,
      },
    });
  };

  const onShowLessPress = () => {
    onShowLess();
    dispatch({
      type: 'update',
      payload: {
        isExpanded: false,
      },
    });
  };

  //@ts-expect-error Support for: webkit-line-clamp
  const textStyle: StyleProp<TextStyle> = {
    ...(Platform.OS === 'web'
      ? {
          display: '-webkit-box',
        }
      : {}),
  };

  const readMoreStyle: StyleProp<ViewStyle> = {
    ...(Platform.OS === 'web'
      ? {
          background: 'linear-gradient(to right, rgba(247, 247, 250, 0) 0%, rgb(255, 255, 255) 15%)',
          position: 'absolute',
          width: 70,
          alignItems: 'flex-end',
        }
      : {
          backgroundColor: 'transparent',
        }),
    bottom: 0,
    right: 0,
    justifyContent: 'center',
  };

  const shouldShowMe = (condition: boolean) => state.shouldShowMore && condition;

  return (
    <View style={style}>
      <Text style={textStyle} numberOfLines={state.fullHeight && !state.isExpanded ? numberOfLines : 0} ref={ref}>
        {children}
      </Text>
      {shouldShowMe(!state.isExpanded) && (
        <Pressable style={readMoreStyle} onPress={onShowMorePress}>
          <Text style={{ fontFamily: 'open-sans', fontSize: 14, color: '#080de0' }}>mai mult</Text>
        </Pressable>
      )}
      {shouldShowMe(state.isExpanded) && (
        <Pressable onPress={onShowLessPress}>
          <Text style={{ fontFamily: 'open-sans', fontSize: 14, color: '#080de0' }}>mai puţin</Text>
        </Pressable>
      )}
    </View>
  );
};

export default ReadMore;
