import { HasFirstname, HasOptionalFirstname } from './firstname';
import { HasLastname, HasOptionalLastname } from './lastname';
import * as t from 'io-ts';

export * from './firstname';
export * from './lastname';

export const HasFirstLastName = t.intersection([HasFirstname, HasLastname], 'HasFirstLastName');
export type HasFirstLastName = t.TypeOf<typeof HasFirstLastName>;

export const HasOptionalFirstLastName = t.intersection(
  [HasOptionalFirstname, HasOptionalLastname],
  'HasOptionalFirstLastName',
);
export type HasOptionalFirstLastName = t.TypeOf<typeof HasOptionalFirstLastName>;

export * from './fullname';
