import { Dimensions } from 'react-native';

import * as colors_ from './colors';

export { default as sizes } from './sizes';
export { styles as text } from './text';

export const colors = colors_;

// stuff from marketplace
export const PHONE = 'phone';
export const TABLET = 'tablet';
export const DESKTOP = 'desktop';

export const SMALL = 770;
export const MEDIUM = 1090;

export const getDevice = (): string => {
  const { width } = Dimensions.get('window');
  if (width <= SMALL) {
    return PHONE;
  } else if (width < MEDIUM) {
    return TABLET;
  }
  return DESKTOP;
};
