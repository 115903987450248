import { formatValidationErrors } from './io-error-reporter';
import { JSONable } from './jsonable';
import * as errors from '@mero/novabooker-errors';
import { setLocale, format } from 'exceptional.js';
import * as E from 'fp-ts/lib/Either';
import { pipe, identity } from 'fp-ts/lib/function';
import i18n from 'i18next';
import * as t from 'io-ts';

errors.loadNovabookerErrors();
setLocale(i18n.language);

i18n.on('languageChanged', (lng) => {
  setLocale(lng);
});

export const isError = (e: unknown): e is Error => e instanceof Error;

/**
 * Base application error class
 */
export class AppError<Cause> extends Error {
  constructor(public readonly message: string, public readonly originalCause: Cause) {
    super(message);
  }

  toJSON(): JSONable {
    return AppError.toObject(this);
  }

  public static toObject(error: Error, keepStack = false): JSONable {
    return Object.fromEntries(
      [['.constructorName', error.constructor.name]].concat(
        Object.getOwnPropertyNames(error).map((pn) => {
          // hide stack trace
          if (!keepStack && pn === 'stack') {
            return [pn, undefined];
          }

          const pd = Object.getOwnPropertyDescriptor(error, pn);
          const value = pd?.get?.() ?? pd?.value;

          return [pn, value instanceof Error ? AppError.toObject(value, keepStack) : value];
        }),
      ),
    );
  }
}

/**
 * Builds new AppError<Cause> type guard using given [isCause] type guard
 */
export const isAppError =
  <Cause>(isCause: (c: unknown) => c is Cause) =>
  (e: unknown): e is AppError<Cause> =>
    e instanceof AppError && isCause(e.originalCause);

/**
 * Returns true if given value is an AppError<unknown>
 */
export const isUnknownAppError = isAppError(t.unknown.is);

/**
 * Class for errors returned by API server
 * ApiError message field is to be shown to the user
 */
export class ApiError<Payload> extends AppError<undefined> {
  constructor(
    message: string,
    public readonly namespace: string,
    public readonly code: number,
    public readonly payload: Payload,
  ) {
    super(message, undefined);
  }

  public toString(): string {
    return `ApiError("${this.message}", ${this.namespace}, ${this.code}, ${JSON.stringify(this.payload)})`;
  }
}

/**
 * @returns ApiError<Payload> instance if found within given error hierarchy [e], using provided type guard [isPayload], or undefined otherwise
 */
export const extractApiError = <Payload>(
  isPayload: (p: unknown) => p is Payload,
): ((e: unknown) => ApiError<Payload> | undefined) => {
  const isApiErr = isApiError(isPayload);
  const isAppErr = isAppError(t.unknown.is);

  const extract = (e: unknown): ApiError<Payload> | undefined =>
    isApiErr(e) ? e : isAppErr(e) ? extract(e.originalCause) : undefined;

  return extract;
};

export type ApiErrorObject<T> = {
  namespace: string;
  code: number;
  payload: T;
};

const apiErrorObject = <T extends t.Mixed>(payload: T) =>
  t.type(
    {
      namespace: t.string,
      code: t.number,
      payload: payload,
    },
    `ApiErrorObject<${payload.name}>`,
  );

const formatMessageInternal = (e: ApiErrorObject<unknown>): string =>
  `ApiError(namespace=${e.namespace}, code=${e.code}, payload=${e.payload})`;

export const formatMessage = (e: ApiErrorObject<unknown>, defaultMessage?: string): string => {
  const m = defaultMessage ?? formatMessageInternal(e);
  try {
    return format(e) ?? m;
  } catch {
    return m;
  }
};

const isApiError =
  <Payload>(isPayload: (p: unknown) => p is Payload) =>
  (e: unknown): e is ApiError<Payload> =>
    e instanceof ApiError && isPayload(e.payload);

/**
 * Build new ApiError<T> codec, from/to ApiErrorObject<T>
 */
export const apiError = <T extends t.Mixed>(payload: T): t.Type<ApiError<t.TypeOf<T>>, ApiErrorObject<t.TypeOf<T>>> => {
  const o = apiErrorObject(payload);

  return new t.Type<ApiError<t.TypeOf<T>>, ApiErrorObject<t.TypeOf<T>>>(
    `ApiError<${payload.name}>`,
    isApiError(payload.is),
    (i, c) =>
      pipe(
        o.validate(i, c),
        E.map((e) => new ApiError(formatMessage(e), e.namespace, e.code, e.payload)),
      ),
    (error) => ({
      namespace: error.namespace,
      code: error.code,
      payload: error.code,
    }),
  );
};

export const UnknownApiError = apiError(t.unknown);

/**
 * Class for data decoding error
 */
export class DecodeError extends AppError<undefined> {
  public readonly messages: string[];

  constructor(errors: t.Errors) {
    const messages = formatValidationErrors(errors);
    super(messages.slice(0, 5).join('\n'), undefined);
    this.messages = messages;
  }

  public toString(): string {
    return `DecodeError("${this.message}")`;
  }
}

export class NetworkError extends AppError<unknown> {
  constructor(message: string, cause: unknown) {
    super(message, cause);
  }

  public toString(): string {
    return `NetworkError("${this.message}", ${this.originalCause})`;
  }

  static is(e: unknown): e is NetworkError {
    return e instanceof NetworkError;
  }
}

export const unsafeRight = <E extends Error, T>(r: E.Either<E, T>): T =>
  pipe(
    r,
    E.fold((e) => {
      throw e;
    }, identity),
  );
