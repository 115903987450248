import * as t from 'io-ts';
import { NumberFromString } from 'io-ts-types';

export interface PageRoleIdBrand {
  readonly PageRoleId: unique symbol;
}

export const PageRoleId = t.brand(t.number, (n): n is t.Branded<number, PageRoleIdBrand> => true, 'PageRoleId');

export type PageRoleId = t.TypeOf<typeof PageRoleId>;

/**
 * Decode PageRoleId from string
 */
export const PageRoleIdFromString = NumberFromString.pipe(PageRoleId, 'PageRoleIdFromString');
