import { CalendarId } from './calendar-id';
import { DayTime, JSONable } from '@mero/shared-sdk';
import * as t from 'io-ts';

export type CalendarDayAvailableTimeSlots = {
  readonly slots: DayTime[];
  readonly calendarId: CalendarId;
};

const JSON: t.Type<CalendarDayAvailableTimeSlots, JSONable> = t.type(
  {
    slots: DayTime.ARRAY_JSON,
    calendarId: CalendarId,
  },
  'CalendarDayAvailableTimeSlots',
);

export const CalendarDayAvailableTimeSlots = {
  JSON,
};
