import * as React from 'react';
import { StyleProp, TextProps as TextPropsRN, Text as TextRN, TextStyle, StyleSheet } from 'react-native';

import { COMET, DARK_BLUE } from '../../styles/colors';
import { MeroFontFamily } from '../../styles/fonts';

/**
 * @deprecated
 */
type StyledTextProps = React.PropsWithChildren<
  TextPropsRN & {
    fontFamily?: MeroFontFamily;
    size?: number;
    fontWeight?: TextStyle['fontWeight'];
    lineHeight?: number;
    color?: string;
    textTransform?: TextStyle['textTransform'];
    'aria-level'?: string;
  }
>;

const StyledText: React.FC<StyledTextProps> = ({
  fontFamily = 'open-sans',
  size,
  fontWeight,
  lineHeight,
  color,
  textTransform,
  children,
  style,
  ...props
}) => {
  return (
    <TextRN
      style={[
        {
          fontFamily: fontFamily ?? 'open-sans',
          fontSize: size,
          fontWeight: fontWeight,
          lineHeight: lineHeight,
          color: color,
          textTransform: textTransform,
        },
        style,
      ]}
      {...props}
    >
      {children}
    </TextRN>
  );
};

/**
 * @deprecated
 */
export type TextProps = React.PropsWithChildren<{
  /**
   * Set custom text font (limited to fonts loaded by the app)
   */
  fontFamily?: MeroFontFamily;
  /**
   * Custom line height
   */
  lineHeight?: number;
  /**
   * Custom text color
   */
  color?: string;
  /**
   * Apply text transofrmations
   */
  textTransform?: TextStyle['textTransform'];
  /**
   * 40pt, Merriweather Bold
   */
  d1?: boolean;
  /**
   * 27pt, Merriweather Bold
   */
  d2?: boolean;
  /**
   * 24pt, Merriweather Bold or 20pt Open Sans and others
   */
  h1?: boolean;
  /**
   * 18pt, Merriweather Bold or 17pt Open Sans and others
   */
  h2?: boolean;
  /**
   * Same as h2 but defaults to Open Sans font
   */
  h2s?: boolean;
  /**
   * Same as h1 but defaults to Open Sans font
   */
  h3s?: boolean;
  /**
   * 16pt, Open Sans Semibold
   */
  title?: boolean;
  /**
   * 16pt, Open Sans
   */
  body?: boolean;
  /**
   * 14pt, Open Sans
   */
  smallBody?: boolean;
  /**
   * 12pt, Open Sans, UPPERCASE
   */
  label?: boolean;
  /**
   * Sets color to DARK_BLUE and font to Bold for body, smallBody and label (if not custom color set)
   */
  link?: boolean;
  /**
   * Sets color to COMET (if no custom color set)
   */
  hint?: boolean;
  /**
   * Centers text
   */
  center?: boolean;
  /**
   * Sets font weight to 600 (semibold)
   */
  semibold?: boolean;
  /**
   * Sets font weight to 700 (bold)
   */
  bold?: boolean;
  /**
   * Customise styling
   */
  style?: StyleProp<TextStyle>;
  /**
   * Number of text lines to show
   */
  numberOfLines?: TextPropsRN['numberOfLines'];
  /**
   * Ellipsize mode
   */
  ellipsizeMode?: TextPropsRN['ellipsizeMode'];
  children?: React.ReactNode;
  onPress?: TextPropsRN['onPress'];
  onLongPress?: TextPropsRN['onLongPress'];
  accessibilityRole?: any;
  ariaLevel?: string;
}>;

/**
 * @deprecated
 */
const chooseFont = ({
  fontFamily,
  bold,
  semibold,
}: {
  fontFamily: MeroFontFamily;
  bold?: boolean;
  semibold?: boolean;
}): MeroFontFamily | undefined => {
  if (fontFamily === 'open-sans') {
    if (bold) {
      return 'open-sans-bold';
    } else if (semibold) {
      return 'open-sans-semibold';
    }
  }

  return fontFamily;
};

/**
 * @deprecated
 */
const getProps = ({
  d1,
  d2,
  h1,
  h2,
  h2s,
  h3s,
  title,
  body,
  smallBody,
  label,
  bold,
  semibold,
  fontFamily,
}: TextProps): StyledTextProps => {
  if (d1) {
    return { size: 40, fontFamily: chooseFont({ fontFamily: fontFamily ?? 'merriweather-bold', bold, semibold }) };
  } else if (d2) {
    return { size: 27, fontFamily: chooseFont({ fontFamily: fontFamily ?? 'merriweather-bold', bold, semibold }) };
  } else if (h1) {
    const ff = chooseFont({ fontFamily: fontFamily ?? 'merriweather-bold', bold, semibold });
    return {
      accessibilityRole: 'header',
      'aria-level': '1',
      fontFamily: ff,
      size: 24,
    };
  } else if (h2) {
    const ff = chooseFont({ fontFamily: fontFamily ?? 'merriweather-bold', bold, semibold });
    return {
      accessibilityRole: 'header',
      'aria-level': '2',
      fontFamily: ff,
      size: ff === 'merriweather-bold' ? 18 : 20,
    };
  } else if (h2s) {
    const ff = chooseFont({ fontFamily: fontFamily ?? 'open-sans-bold', bold, semibold });
    return {
      accessibilityRole: 'header',
      'aria-level': '2',
      fontFamily: ff,
      size: ff === 'merriweather-bold' ? 18 : 20,
    };
  } else if (h3s) {
    const ff = chooseFont({ fontFamily: fontFamily ?? 'open-sans-bold', bold, semibold });
    return {
      accessibilityRole: 'header',
      'aria-level': '3',
      fontFamily: ff,
      size: 17,
    };
  } else if (title) {
    return {
      size: 16,
      lineHeight: 22,
      fontFamily: chooseFont({ fontFamily: fontFamily ?? 'open-sans-semibold', bold, semibold }),
    };
  } else if (body) {
    return {
      size: 16,
      lineHeight: 22,
      fontFamily: chooseFont({ fontFamily: fontFamily ?? 'open-sans', bold, semibold }),
    };
  } else if (smallBody) {
    return {
      size: 14,
      lineHeight: 19,
      fontFamily: chooseFont({ fontFamily: fontFamily ?? 'open-sans', bold, semibold }),
    };
  } else if (label) {
    return {
      size: 12,
      lineHeight: 17,
      fontFamily: chooseFont({ fontFamily: fontFamily ?? 'open-sans', bold, semibold }),
      textTransform: 'uppercase',
    };
  } else {
    return {
      size: 16,
      lineHeight: 22,
      fontFamily: chooseFont({ fontFamily: fontFamily ?? 'open-sans', bold, semibold }),
    };
  }
};

/**
 * @deprecated in favor of D1, D2, H1, H2, H1s, H2s, Title, Body, SmallBody, Label
 * @param props
 * @returns
 */
const Text: React.FC<TextProps> = ({
  d1 = false,
  d2 = false,
  h1 = false,
  h2 = false,
  h2s = false,
  h3s = false,
  title = false,
  body = false,
  smallBody = false,
  label = false,
  bold = false,
  semibold = false,
  fontFamily,
  children,
  lineHeight,
  link = false,
  hint = false,
  center = false,
  color,
  textTransform,
  numberOfLines,
  ellipsizeMode,
  style = {},
  onPress,
  onLongPress,
  accessibilityRole,
  ariaLevel,
}) => {
  return (
    <StyledText
      numberOfLines={numberOfLines}
      ellipsizeMode={ellipsizeMode}
      lineHeight={lineHeight}
      textTransform={textTransform}
      {...getProps({ d1, d2, h1, h2, h2s, h3s, title, body, smallBody, label, bold, semibold, fontFamily })}
      color={color ?? (link ? DARK_BLUE : hint ? COMET : undefined)}
      style={StyleSheet.compose(style, center ? { textAlign: 'center' } : {})}
      onPress={onPress}
      onLongPress={onLongPress}
      accessibilityRole={accessibilityRole}
      aria-level={ariaLevel}
    >
      {children}
    </StyledText>
  );
};

export default Text;
