import { RecurrenceEndCriteria } from '../recurrenceEndCriteria';
import { RecurrenceInterval } from '../recurrenceInterval';
import * as t from 'io-ts';

export { Predefined } from './predefined';

export type Any = {
  readonly repeatEvery: RecurrenceInterval.Any;
  readonly endsOn: RecurrenceEndCriteria.Any;
};

export const JSON = t.type(
  {
    repeatEvery: RecurrenceInterval.JSON,
    endsOn: RecurrenceEndCriteria.JSON,
  },
  'RecurrenceRule',
);

export const is = JSON.is;
