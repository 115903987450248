import { DefinedTrimedString, UndefinedString } from './string';
import * as t from 'io-ts';

/**
 * Source: https://emailregex.com/
 */
const emailRegex =
  /(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])/i;

export interface EmailBrand {
  readonly Email: unique symbol;
}

export type Email = t.Branded<DefinedTrimedString, EmailBrand>;

const isEmail = (s: string): s is Email => {
  return emailRegex.test(s);
};

const JSON: t.Type<Email, string> = t.brand(DefinedTrimedString, isEmail, 'Email');

/**
 * Decode Email type without validation
 * Only use this to read data from server to avoid breaking changes in data validation so clients won't crash for reads
 */
const TRUSTED_JSON: t.Type<Email, string> = t.brand(t.string, (s: string): s is Email => true, 'Email');

export const Email = {
  is: isEmail,
  decode: JSON.decode,
  encode: JSON.encode,
  JSON,
  TRUSTED_JSON,
};
