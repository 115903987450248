import { literalIso } from '@mero/shared-sdk';
import * as t from 'io-ts';

export const HiddenPriceType = literalIso('hidden', 0);
export type HiddenPriceType = t.TypeOf<typeof HiddenPriceType>;

export const FixedPriceType = literalIso('fixed', 1);
export type FixedPriceType = t.TypeOf<typeof FixedPriceType>;

export const RangePriceType = literalIso('range', 2);
export type RangePriceType = t.TypeOf<typeof RangePriceType>;

/**
 * @deprecated use Price type
 */
export const PriceType = t.union([HiddenPriceType, FixedPriceType, RangePriceType], 'PriceType');
/**
 * @deprecated use Price type
 */
export type PriceType = t.TypeOf<typeof PriceType>;
