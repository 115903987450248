import { ObjectId } from '@mero/shared-sdk';
import * as t from 'io-ts';

export interface BusinessCategoryIdBrand {
  readonly BusinessCategoryId: unique symbol;
}

export const BusinessCategoryId = t.brand(
  ObjectId,
  (_id: ObjectId): _id is t.Branded<ObjectId, BusinessCategoryIdBrand> => true,
  'BusinessCategoryId',
);

export type BusinessCategoryId = t.TypeOf<typeof BusinessCategoryId>;
