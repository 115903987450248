import * as React from 'react';
import { ViewStyle, StyleProp, View, TouchableOpacityProps, TouchableOpacity } from 'react-native';

import * as colors from '../../styles/colors';
import Icon from '../Icon';
import Body from '../Text/Body';

type ContainerProps = React.PropsWithChildren<{
  borderColor?: string;
  disabled?: boolean;
}>;

const Container: React.FC<ContainerProps & TouchableOpacityProps> = ({
  borderColor,
  disabled,
  style,
  children,
  ...props
}) => {
  return (
    <TouchableOpacity
      style={[
        {
          flexDirection: 'row',
          height: 43,
          width: '100%',
          borderWidth: 1,
          borderColor: borderColor ?? colors.GEYSER,
          borderRadius: 5,
          backgroundColor: disabled ? colors.ATHENS_GRAY : colors.WHITE,
          justifyContent: 'center',
          alignItems: 'center',
          paddingLeft: 14,
        },
        style,
      ]}
      disabled={disabled}
      {...props}
    >
      {children}
    </TouchableOpacity>
  );
};

type Props = React.PropsWithChildren<{
  style?: StyleProp<ViewStyle>;
  text?: string;
  color?: string;
  disabled?: boolean;
  onPress?: () => void;
}>;

const SelectButton: React.FC<Props> = ({ style, text, color, disabled, onPress }) => {
  const finalColor = color ? color : disabled ? colors.CADET_BLUE : colors.BLACK;

  return (
    <Container style={style} onPress={onPress} disabled={disabled}>
      {!!text ? (
        <Body style={{ flex: 1, color: finalColor }} numberOfLines={1} ellipsizeMode="tail">
          {text}
        </Body>
      ) : (
        <View style={{ flex: 1 }}></View>
      )}
      <View
        style={{
          paddingRight: 11,
          paddingLeft: 8,
          justifyContent: 'center',
        }}
      >
        <Icon type="dropdown" color={finalColor} />
      </View>
    </Container>
  );
};

export default SelectButton;
