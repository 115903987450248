import { Platform, StyleSheet } from 'react-native';

export default StyleSheet.create({
  hideOnMobile: {
    height: 0,
    opacity: 0,
  },
  hideOnDesktop: {},
  button: {
    height: 0,
    opacity: 0,
  },
  itemContainer: {},
  itemContainerLast: {},
  img: {
    flex: 1,
  },
  previousButton: {
    position: 'absolute',
    left: 24,
    top: '50%',
    marginTop: -16,
  },
  nextButton: {
    position: 'absolute',
    right: 24,
    top: '50%',
    marginTop: -16,
  },
  buttonGallery: {
    ...(Platform.OS === 'web' ? { cursor: 'pointer' } : {}),
    backgroundColor: '#FAFAFA',
    width: 32,
    height: 32,
    borderRadius: 16,
    justifyContent: 'center',
    alignItems: 'center',
  },
});
