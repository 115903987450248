import { ObjectId } from '@mero/shared-sdk';
import * as t from 'io-ts';

export interface InvoiceIdBrand {
  readonly InvoiceId: unique symbol;
}

export const InvoiceId = t.brand(
  ObjectId,
  (_id: ObjectId): _id is t.Branded<ObjectId, InvoiceIdBrand> => true,
  'InvoiceId',
);

export type InvoiceId = t.TypeOf<typeof InvoiceId>;

export const HasInvoiceId = t.type(
  {
    _id: InvoiceId,
  },
  'HasInvoiceId',
);
export interface HasInvoiceId extends t.TypeOf<typeof HasInvoiceId> {}
