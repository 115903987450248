import { Appointment } from './appointment';
import { BlockedTime } from './blockedTime';
import * as t from 'io-ts';

export type Any = Appointment | BlockedTime;

const JSON = t.union([Appointment.JSON, BlockedTime.JSON]);

export const Any = {
  JSON,
};
