import { Any } from './any';
import { Home } from './home';
import { SearchResults } from './searchResults';

export * from './any';
export * from './searchResults';
export * from './home';

export const JSON = Any.JSON;

export const searchResults = (query: SearchResults['query']): SearchResults => {
  return {
    type: 'SearchResults',
    query: query,
  };
};

export const HOME: Home = {
  type: 'Home',
};
