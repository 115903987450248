import * as React from 'react';
import Svg, { Rect, Path, G, Defs, ClipPath } from 'react-native-svg';

import { BLACK } from '../../../styles/colors';

type Props = {
  size?: string | number;
  color?: string;
  disabled?: boolean;
  onPress?: () => void;
};

const Mero: React.FC<Props> = ({ size = 32, color = BLACK, disabled = false, onPress }) => (
  <Svg width={size} height={size} viewBox="0 0 32 32" disabled={disabled} onPress={onPress}>
    <Defs>
      <ClipPath id="clip-path">
        <Rect width="24" height="24" rx="5" fill="none" />
      </ClipPath>
      <ClipPath id="clip-path-2">
        <Rect width="24.24" height="24.24" transform="translate(0 0)" fill="none" />
      </ClipPath>
    </Defs>
    <G transform="translate(-5.15 -5.09)">
      <G transform="translate(5.15 5.09)" clip-path="url(#clip-path)">
        <G transform="translate(-0.12 -0.12)" clip-path="url(#clip-path-2)"></G>
      </G>
      <Path
        d="M166.515,250.667a2.2,2.2,0,0,1-1.562,2.228h5.718a2.2,2.2,0,0,1-1.562-2.228v-7.989a2.2,2.2,0,0,1,1.562-2.228H166.52l-3.734,7.69-4.552-7.69H154.5a3.465,3.465,0,0,1,1.849,3.051v6.586a2.792,2.792,0,0,1-2.3,2.809h4.895a2.8,2.8,0,0,1-2.269-2.809v-7.15l4.966,8.3,4.878-10.045Z"
        transform="translate(-145.21 -229.583)"
        fill="#fff"
      />
    </G>
  </Svg>
);

export default React.memo(Mero);
