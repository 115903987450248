import * as React from 'react';
import Svg, { G, Path } from 'react-native-svg';

type Props = {
  size?: string | number;
  color?: string;
  disabled?: boolean;
  onPress?: () => void;
};

const CalendarGiftCard = ({ size = 12, color = '#ffffff', disabled, onPress }: Props) => (
  <Svg width={size} height={size} viewBox={`0 0 ${size} ${size}`} disabled={disabled} onPress={onPress}>
    <G data-name="Group 6853">
      <G fill="#202020">
        <Path d="M9.599 3.388A1.935 1.935 0 0 0 8.322.001a1.819 1.819 0 0 0-1.509.716A5.245 5.245 0 0 0 6 2.307 5.245 5.245 0 0 0 5.187.72a1.8 1.8 0 0 0-1.51-.719A1.935 1.935 0 0 0 2.4 3.388a6.661 6.661 0 0 0 2.438 1.006v2.187h2.323V4.394a6.661 6.661 0 0 0 2.438-1.006ZM7.413 1.22h.018a1.045 1.045 0 0 1 .89-.445 1.161 1.161 0 0 1 .775 2.032 7.467 7.467 0 0 1-2.671.968 5.9 5.9 0 0 1 .988-2.555Zm-4.9.716A1.161 1.161 0 0 1 3.674.775a1.026 1.026 0 0 1 .89.426h.019a5.9 5.9 0 0 1 .987 2.555 7.854 7.854 0 0 1-2.671-.968 1.161 1.161 0 0 1-.387-.852Z" />
        <Path d="M7.935 4.259h3.483v2.322H7.935Zm-7.354 0h3.483v2.322H.581Zm.774 3.1h2.709v4.645H1.355Zm6.58 0h2.709v4.645H7.935Z" />
        <Path data-name="layer1" d="M4.839 7.355h2.322V12H4.839Z" />
      </G>
      <Path data-name="Rectangle 2640" fill="none" d="M0 0h12v12H0z" />
    </G>
  </Svg>
);

export default React.memo(CalendarGiftCard);
