import { ProfileImage } from '../assets';
import { HasOptionalFirstLastName, optionull } from '../common';
import * as t from 'io-ts';

export const UserProfile = t.intersection(
  [
    HasOptionalFirstLastName,
    t.partial({
      photo: optionull(ProfileImage),
    }),
  ],
  'UserProfile',
);

export type UserProfile = t.TypeOf<typeof UserProfile>;
