import { JSONable } from '../../common';
import { Numbers } from '../../numbers';
import * as t from 'io-ts';

/**
 * Event repeats indefinitely (never ends)
 */
export type Indefinite = {
  readonly times: Numbers.Zero;
  /**
   * This property is defined for easier type refinement checks
   */
  readonly date?: null | undefined;
};

const JSON: t.Type<Indefinite, JSONable> = t.intersection(
  [
    t.type({
      times: Numbers.Zero,
    }),
    t.partial({
      /**
       * Date has higher priority so it shouldn't be defined for this case
       */
      date: t.union([t.null, t.undefined]),
    }),
  ],
  'Indefinite',
);

export const Indefinite = {
  JSON,
  is: JSON.is,
};
