import { AvailableDay } from './available-day';
import { CalendarId } from './calendar-id';
import { JSONable } from '@mero/shared-sdk';
import * as t from 'io-ts';

export type CalendarAvailableDays = {
  readonly calendarId: CalendarId;
  readonly days: AvailableDay[];
};

const JSON: t.Type<CalendarAvailableDays, JSONable> = t.type(
  {
    calendarId: CalendarId,
    days: t.array(AvailableDay),
  },
  'CalendarAvailableDays',
);

export const CalendarAvailableDays = {
  JSON,
};
