import { UserOrderPaymentId } from '../../pro/onlinePayments/userOrderPaymentId';
import { ServiceId } from '../../services';
import { CheckoutClientPreview } from '../checkoutClientPreview';
import { CheckoutPagePreview } from '../checkoutPagePreview';
import { CheckoutTotals } from '../checkoutTotals';
import { CheckoutTransactionCode } from '../checkoutTransactionCode';
import { CheckoutTransactionCompany } from '../checkoutTransactionCompany';
import { CheckoutTransactionId } from '../checkoutTransactionId';
import { CheckoutTransactionItem } from '../checkoutTransactionItem';
import { CheckoutTransactionPayment } from '../checkoutTransactionPayment';
import { CheckoutUserPreview } from '../checkoutUserPreview';
import { TransactionItemId } from '../transactionItemId';
import { computeTransactionTotals, mayHaveDiscount } from './utils';
import {
  AppliedDiscount,
  MeroCurrency,
  MeroUnits,
  Money,
  Numbers,
  PositiveInt,
  ScaledNumber,
  isDefined,
  Result,
  None,
  ok,
  err,
  Validated,
  validated,
} from '@mero/shared-sdk';
import * as NEA from 'fp-ts/lib/NonEmptyArray';

/**
 * Finished transaction
 * Cannot be modified anymore
 */
export type Finished<Unit extends MeroUnits.Any> = {
  readonly _id: CheckoutTransactionId;
  readonly status: 'Finished';
  readonly unit: Unit;
  readonly page: CheckoutPagePreview;
  /**
   * Page-unique transaction code
   */
  readonly code: CheckoutTransactionCode;
  /**
   * Transaction creation time
   */
  readonly createdAt: Date;
  /**
   * Preview of the user who created this transaction
   */
  readonly createdBy: CheckoutUserPreview;
  readonly finishedAt: Date;
  readonly finishedBy: CheckoutUserPreview;
  /**
   * Client details
   */
  readonly client?: CheckoutClientPreview;
  /**
   * List of order items
   */
  readonly items: NEA.NonEmptyArray<CheckoutTransactionItem.Any<Unit>>;
  /**
   * Discount applied to the transaction
   */
  readonly discount?: AppliedDiscount<ScaledNumber, Unit>;
  /**
   * Payments applied to the transaction
   */
  readonly payments: CheckoutTransactionPayment.Any<ScaledNumber, Unit>[];
  /**
   * Company details
   */
  readonly company: CheckoutTransactionCompany.Company;
  /**
   * Total amount(s) of the transaction (this value is aggregated from the items)
   */
  readonly total: CheckoutTotals<ScaledNumber, Unit>;
  /**
   * Protocol are transactions for which no payments actually done.
   */
  readonly isProtocol: boolean;
  /**
   * entity version
   */
  readonly version: number;
};

export type AnyFinished = Finished<MeroUnits.RON> | Finished<MeroUnits.EUR>;

type MembershipPaymentItemCounts = {
  readonly services: {
    readonly [K in ServiceId]: PositiveInt | undefined;
  };
};

const MembershipPaymentItemCounts = {
  zero: (): MembershipPaymentItemCounts => ({ services: {} }),
  getServiceCount: (counts: MembershipPaymentItemCounts, serviceId: ServiceId): PositiveInt | undefined => {
    return counts.services[serviceId];
  },
  hasService: (counts: MembershipPaymentItemCounts, serviceId: ServiceId): boolean => {
    return isDefined(MembershipPaymentItemCounts.getServiceCount(counts, serviceId));
  },
  incService: (
    counts: MembershipPaymentItemCounts,
    serviceId: ServiceId,
    by: PositiveInt = Numbers._1,
  ): MembershipPaymentItemCounts => {
    const currentServiceCount = MembershipPaymentItemCounts.getServiceCount(counts, serviceId);

    return {
      services: {
        ...counts.services,
        [serviceId]: currentServiceCount ? PositiveInt.add(currentServiceCount, by) : by,
      },
    };
  },
  decService: (
    counts: MembershipPaymentItemCounts,
    serviceId: ServiceId,
    by: PositiveInt = Numbers._1,
  ): MembershipPaymentItemCounts => {
    const { services: { [serviceId]: currentServiceCount, ...otherServices } = {} } = counts;

    if (!currentServiceCount) {
      throw new Error(`Cannot decrease service count for service ${serviceId} because it is not present in counts`);
    }

    return {
      services: {
        ...otherServices,
        ...(currentServiceCount > by ? { [serviceId]: PositiveInt.unsafeFrom(currentServiceCount - by) } : {}),
      },
    };
  },
};

const getMembershipPaymentItemCounts = <Unit extends MeroUnits.Any>(
  transaction: Pick<Finished<Unit>, 'payments'>,
): MembershipPaymentItemCounts => {
  return transaction.payments
    .filter(CheckoutTransactionPayment.isMembership)
    .reduce((acc: MembershipPaymentItemCounts, payment) => {
      return payment.items.reduce((acc, item) => {
        switch (item.type) {
          case 'Service': {
            return MembershipPaymentItemCounts.incService(acc, item.service._id, item.quantity);
          }
          case 'Product': {
            // TODO: count products when products support is implemented
            return acc;
          }
        }
      }, acc);
    }, MembershipPaymentItemCounts.zero());
};

/**
 * @returns true if transaction has valid membership payments (i.e. all services paid with membership are present in items list)
 */
const validateMembershipPayments = <Unit extends MeroUnits.Any>(
  transaction: Pick<Finished<Unit>, 'items' | 'payments'>,
): Result<void, Error> => {
  if (TransactionItemId.hasDuplicateIdsInTransactionItems(transaction.items)) {
    return err(new Error('Transaction items has duplicate IDs'));
  }

  if (TransactionItemId.hasDuplicateTransactionItemIdsInMembershipPayments(transaction.payments)) {
    return err(new Error('Transaction has duplicate item IDs in payments'));
  }

  const membershipPayments = transaction.payments.filter(CheckoutTransactionPayment.isMembership);

  const hasInvalidMembershipServiceConsumption = membershipPayments.some((membershipPayment) => {
    // check every membership payment to find one invalid
    return membershipPayment.items.some((membershipItem) => {
      // check every membership payment item to find one invalid
      switch (membershipItem.type) {
        case 'Service': {
          // try to find matching transaction item by ID and quantity
          return !transaction.items.some((transactionItem) => {
            if (transactionItem.type === 'Service') {
              return (
                transactionItem.quantity === membershipItem.quantity &&
                transactionItem.transactionItemId === membershipItem.transactionItemId
              );
            } else if (transactionItem.type === 'Booking') {
              return transactionItem.items.some((bookingItem) => {
                return (
                  bookingItem.transactionItemId === membershipItem.transactionItemId &&
                  bookingItem.quantity === membershipItem.quantity
                );
              });
            } else {
              return false;
            }
          });
        }
        case 'Product': {
          //TODO: add support for products
          return false;
        }
      }
    });
  });

  if (hasInvalidMembershipServiceConsumption) {
    return err(new Error('Transaction has invalid membership consumption'));
  }

  // Count services (and products) paid using membership
  const membershipPaymentItems = getMembershipPaymentItemCounts(transaction);

  // validate that items contains all services paid with membership (or more)
  const getServiceItemsCount = (serviceId: string): number => {
    return transaction.items.reduce((acc, item) => {
      if (item.type === 'Booking') {
        return item.items.reduce((acc2, item2) => {
          if (item2.type === 'Service') {
            return item2.service._id === serviceId ? acc2 + item2.quantity : acc2;
          } else {
            return acc2;
          }
        }, acc);
      } else if (item.type === 'Service') {
        return item.service._id === serviceId ? acc + item.quantity : acc;
      } else {
        return acc;
      }
    }, 0);
  };

  const hasInvalidMembershipServicePayments = Object.entries(membershipPaymentItems.services).some(
    ([serviceId, paidCount]) => {
      // check if there are less items than paid with membership
      return isDefined(paidCount) && getServiceItemsCount(serviceId) < paidCount;
    },
  );

  if (hasInvalidMembershipServicePayments) {
    return err(new Error('Transaction has invalid membership payments'));
  }

  return ok(None);
};

/**
 * @returns true if transaction has valid online payments (i.e. single payment may be used only once)
 */
const validateOnlinePayments = <Unit extends MeroUnits.Any>(
  transaction: Pick<Finished<Unit>, 'payments'>,
): Result<void, Error> => {
  const usedOrderPaymentIds: UserOrderPaymentId[] = [];
  for (const payment of transaction.payments) {
    if (CheckoutTransactionPayment.isOnline(payment)) {
      if (payment.source.type === 'UserOrderPayment') {
        const alreadyUsed = usedOrderPaymentIds.some((id) => {
          return id === payment.source.paymentId;
        });

        if (alreadyUsed) {
          return err(new Error(`Online payment with id ${payment.source.paymentId} was used multiple times`));
        }

        usedOrderPaymentIds.push(payment.source.paymentId);
      }
    }
  }

  return ok(None);
};

const MoneyScaledNumber = Money.build(ScaledNumber, MeroUnits);

/**
 * @returns true if items total (excluding paid with membership) equals to payments total
 */
const validateItemsTotalMatchesPayments = <Unit extends MeroUnits.Any>(
  transaction: Pick<Finished<Unit>, 'unit' | 'items' | 'payments' | 'discount' | 'company' | 'isProtocol'>,
): Result<void, Error> => {
  if (transaction.isProtocol) {
    if (transaction.payments.length > 0) {
      // No payments allowed with protocol transactions
      return err(new Error('No payments allowed with protocol transactions'));
    }

    return ok(None);
  }

  const itemsTotal = computeTransactionTotals(transaction, MeroCurrency[transaction.unit].exponent);

  const paymentsTotalMoney: Money<ScaledNumber, Unit> = transaction.payments.reduce((totals, payment) => {
    switch (payment.type) {
      case 'Cash': {
        return MoneyScaledNumber.add(totals, payment.total);
      }
      case 'Card': {
        return MoneyScaledNumber.add(totals, payment.total);
      }
      case 'BankTransfer': {
        return MoneyScaledNumber.add(totals, payment.total);
      }
      case 'Giftcard': {
        // FIXME: implement giftcard payments (when applying a giftcard - total may go over the items total)
        return totals;
      }
      case 'Membership': {
        // membership payment does not affect total money (as it just reduces items quantity)
        return totals;
      }
      case 'Online': {
        return MoneyScaledNumber.add(totals, payment.total);
      }
    }
  }, MoneyScaledNumber.zero(transaction.unit));

  const isEqual = MoneyScaledNumber.equals(itemsTotal.total, paymentsTotalMoney);

  if (!isEqual) {
    return err(new Error('Items total does not match payments total'));
  }

  return ok(None);
};

/**
 * @returns true if transaction has printReceipt on and all payments support printing receipts (excluding membership payments)
 * and transaction does not include only membership payments, or print receipts is off
 */
const validatePrintReceipt = <Unit extends MeroUnits.Any>(
  transaction: Pick<Finished<Unit>, 'payments' | 'company'>,
): Result<void, Error> => {
  if (transaction.company.receipt.emit) {
    let hasReceiptPayments: boolean = false;

    for (const payment of transaction.payments) {
      const isReceiptPayment = CheckoutTransactionPayment.supportsReceiptsPrinting(payment);

      /**
       * We only support payments receipts printing for membership + cash | card payments combination for now
       * (bank + cash | card payments should be also supported, but we don't have the computation logic in place yet)
       */
      if (!isReceiptPayment && !CheckoutTransactionPayment.isMembership(payment)) {
        return err(new Error(`Printing receipt is not supported for "${payment.type}" payment type`));
      }

      hasReceiptPayments = hasReceiptPayments || isReceiptPayment;
    }

    if (!hasReceiptPayments) {
      return err(new Error('At least one payment type supported by receipt printer is required'));
    }

    return ok(None);
  } else {
    return ok(None);
  }
};

/**
 * @returns true if transaction has no discount or transaction has discount and
 * items do not include Membership or MembershipInstallment type items
 */
const validateTransactionDiscount = <Unit extends MeroUnits.Any>(
  transaction: Pick<Finished<Unit>, 'discount' | 'items'>,
): Result<void, Error> => {
  if (isDefined(transaction.discount) && !mayHaveDiscount(transaction)) {
    return err(new Error('A discount cannot be applied to this transaction'));
  }

  return ok(None);
};

const validate = <
  Unit extends MeroUnits.Any,
  T extends Pick<Finished<Unit>, 'unit' | 'items' | 'payments' | 'company' | 'isProtocol' | 'discount'>,
>(
  transaction: T,
): Result<Validated<T>, Error> => {
  const discountResult = validateTransactionDiscount(transaction);
  if (!discountResult.isOk) {
    return discountResult;
  }

  const membershipsResult = validateMembershipPayments(transaction);
  if (!membershipsResult.isOk) {
    return membershipsResult;
  }

  const onlinePaymentsResult = validateOnlinePayments(transaction);
  if (!onlinePaymentsResult.isOk) {
    return onlinePaymentsResult;
  }

  const totalsMatchResult = validateItemsTotalMatchesPayments(transaction);
  if (!totalsMatchResult.isOk) {
    return totalsMatchResult;
  }

  const printReceiptResult = validatePrintReceipt(transaction);
  if (!printReceiptResult.isOk) {
    return printReceiptResult;
  }

  return ok(validated(transaction));
};

export const Finished = {
  validateMembershipPayments,
  validateItemsTotalMatchesPayments,
  validateTransactionDiscount,
  validate,
};
