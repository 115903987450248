import * as React from 'react';
import Svg, { Path, G, Rect } from 'react-native-svg';

type Props = {
  size?: string | number;
  color?: string;
  disabled?: boolean;
  onPress?: () => void;
};

const AndroidSettings = ({ size = 24, color = '#777', disabled, onPress }: Props) => (
  <Svg width={size} height={size} viewBox={`0 0 ${size} ${size}`} disabled={disabled} onPress={onPress}>
    <G id="settings" transform="translate(-177 -451)">
      <Rect id="Rectangle_2589" width="24" height="24" transform="translate(177 451)" fill="none" />
      <Path
        id="Settings-595b40b75ba036ed117d76e7"
        d="M12.063,0c-2.288,0-2.25.219-2.25.219a20.021,20.021,0,0,1-.75,2.75,9.507,9.507,0,0,0-3.156,1.75,18.783,18.783,0,0,1-2.687-.75s-.167-.145-1.344,1.844-1,2.094-1,2.094A20.489,20.489,0,0,1,2.75,9.875,9.477,9.477,0,0,0,2.5,12a9.588,9.588,0,0,0,.188,1.938A20.1,20.1,0,0,1,.781,15.875s-.168.082.969,2.094,1.281,1.875,1.281,1.875a18.5,18.5,0,0,1,2.719-.687,9.383,9.383,0,0,0,3.156,1.813,19.289,19.289,0,0,1,.781,2.813S9.651,24,11.938,24s2.281-.219,2.281-.219a21.079,21.079,0,0,1,.719-2.75,9.5,9.5,0,0,0,3.156-1.75,18.577,18.577,0,0,1,2.688.75s.163.144,1.344-1.844,1-2.094,1-2.094a20.481,20.481,0,0,1-1.875-1.969,9.584,9.584,0,0,0,.063-4.062,20.21,20.21,0,0,1,1.906-1.937s.168-.081-.969-2.094-1.281-1.875-1.281-1.875a18.466,18.466,0,0,1-2.719.688,9.409,9.409,0,0,0-3.156-1.812A19.555,19.555,0,0,1,14.313.219S14.347,0,12.063,0ZM12,4a8,8,0,1,1-8,8A8,8,0,0,1,12,4Zm0,1a7,7,0,1,0,7,7A7,7,0,0,0,12,5Zm0,3a4,4,0,1,1-4,4A4,4,0,0,1,12,8Z"
        transform="translate(177.228 451)"
        fill={color}
      />
    </G>
  </Svg>
);

export default React.memo(AndroidSettings);
