import * as t from 'io-ts';

/**
 * Appointment created by client
 */
export const AppointmentCreatedByClientHistoryType = t.literal('appointmentCreatedByClient');
export type AppointmentCreatedByClientHistoryType = t.TypeOf<typeof AppointmentCreatedByClientHistoryType>;

/**
 * Appointment created by pro
 */
export const AppointmentCreatedByProHistoryType = t.literal('appointmentCreatedByPro');
export type AppointmentCreatedByProHistoryType = t.TypeOf<typeof AppointmentCreatedByProHistoryType>;

/**
 * Client cancelled an appointment
 */
export const AppointmentCancelledByClientHistoryType = t.literal('appointmentCancelledByClient');
export type AppointmentCancelledByClientHistoryType = t.TypeOf<typeof AppointmentCancelledByClientHistoryType>;

/**
 * Pro cancelled an appointment history record
 */
export const AppointmentCancelledByProHistoryType = t.literal('appointmentCancelledByPro');
export type AppointmentCancelledByProHistoryType = t.TypeOf<typeof AppointmentCancelledByProHistoryType>;

/**
 * Pro modified an appointment
 */
export const AppointmentModifiedByProHistoryType = t.literal('appointmentModifiedByPro');
export type AppointmentModifiedByProHistoryType = t.TypeOf<typeof AppointmentModifiedByProHistoryType>;

/**
 * Pro confirmed an appointment
 */
export const AppointmentConfirmedHistoryType = t.literal('appointmentConfirmed');
export type AppointmentConfirmedHistoryType = t.TypeOf<typeof AppointmentConfirmedHistoryType>;

/**
 * Pro refused an appointment
 */
export const AppointmentRefusedHistoryType = t.literal('appointmentRefused');
export type AppointmentRefusedHistoryType = t.TypeOf<typeof AppointmentRefusedHistoryType>;

/**
 * Pro marked an appointment as no show
 */
export const AppointmentMarkedAsNoShowHistoryType = t.literal('appointmentMarkedAsNoShow');
export type AppointmentMarkedAsNoShowHistoryType = t.TypeOf<typeof AppointmentMarkedAsNoShowHistoryType>;

/**
 * Pro added a client to anonymous appointment
 */
export const AppointmentClientAddedHistoryType = t.literal('appointmentClientAdded');
export type AppointmentClientAddedHistoryType = t.TypeOf<typeof AppointmentClientAddedHistoryType>;

export const AppointmentHistoryType = t.union(
  [
    AppointmentCreatedByClientHistoryType,
    AppointmentCreatedByProHistoryType,
    AppointmentCancelledByClientHistoryType,
    AppointmentCancelledByProHistoryType,
    AppointmentModifiedByProHistoryType,
    AppointmentConfirmedHistoryType,
    AppointmentRefusedHistoryType,
    AppointmentMarkedAsNoShowHistoryType,
    AppointmentClientAddedHistoryType,
  ],
  'AppointmentHistoryType',
);

export type AppointmentHistoryType = t.TypeOf<typeof AppointmentHistoryType>;
