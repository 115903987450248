import * as React from 'react';
import { ScrollView, ViewProps } from 'react-native';
import { StyleSheet } from 'react-native';

import AvoidKeyboard from '../AvoidKeyboard';
import DismissKeyboard from '../DismissKeyboard';

type Props = React.PropsWithChildren<ViewProps> & {
  containerStyle?: ViewProps['style'];
  scrollStyle?: ViewProps['style'];
  scrollContentStyle?: ViewProps['style'];
};

/**
 * Avoids keyboard
 * Scrolls on overflow
 * Dismisses keyboard on touch
 * Adds left/right 24px padding
 */
function PageBox({ children, style, containerStyle, scrollStyle, scrollContentStyle, ...rest }: Props) {
  return (
    <AvoidKeyboard style={containerStyle}>
      <ScrollView style={scrollStyle} contentContainerStyle={scrollContentStyle}>
        <DismissKeyboard style={StyleSheet.compose({ paddingLeft: 24, paddingRight: 24 }, style)} {...rest}>
          {children}
        </DismissKeyboard>
      </ScrollView>
    </AvoidKeyboard>
  );
}

export default PageBox;
