import { CheckoutTransactionCompany, CheckoutTransactionItem, CheckoutTransactionPayment } from '../../checkout';
import { CheckoutReportTotals } from './checkoutReportTotals';
import { CheckoutReportTotalsByType } from './checkoutReportTotalsByType';
import { AppliedDiscount, MeroUnits, Money, ScaledNumber } from '@mero/shared-sdk';

const MoneyScaledNumber = Money.build(ScaledNumber, MeroUnits);

const NO_MONEY: { [Unit in MeroUnits.Any]: Money<ScaledNumber, Unit> } = {
  [MeroUnits.RON.code]: MoneyScaledNumber.zero(MeroUnits.RON.code),
  [MeroUnits.EUR.code]: MoneyScaledNumber.zero(MeroUnits.EUR.code),
};

const zeroMoney = <Unit extends MeroUnits.Any>(unit: Unit): Money<ScaledNumber, Unit> => {
  return NO_MONEY[unit];
};

export type CheckoutReportSaleOwnerTotalsByType<Unit extends MeroUnits.Any> = CheckoutReportTotalsByType<Unit> & {
  /**
   * Totals delivered items (sum of services, products and membership consumption)
   */
  readonly delivered: CheckoutReportTotals<Unit>;
};

/**
 * Get totals zero value for given unit
 */
const zero = <Unit extends MeroUnits.Any>(unit: Unit): CheckoutReportSaleOwnerTotalsByType<Unit> => {
  return {
    ...CheckoutReportTotalsByType.zero(unit),
    delivered: CheckoutReportTotals.zero(unit),
  };
};

const add = <Unit extends MeroUnits.Any>(
  a: CheckoutReportSaleOwnerTotalsByType<Unit>,
  b: CheckoutReportSaleOwnerTotalsByType<Unit>,
): CheckoutReportSaleOwnerTotalsByType<Unit> => {
  return {
    ...CheckoutReportTotalsByType.add(a, b),
    delivered: CheckoutReportTotals.add(a.delivered, b.delivered),
  };
};

const fromCheckoutTransaction = <Unit extends MeroUnits.Any>(
  transaction: {
    readonly unit: Unit;
    readonly items: CheckoutTransactionItem.Any<Unit>[];
    readonly payments: CheckoutTransactionPayment.Any<ScaledNumber, Unit>[];
    readonly company: CheckoutTransactionCompany.Company;
    readonly isProtocol: boolean;
    readonly discount?: AppliedDiscount<ScaledNumber, Unit>;
  },
  decimals: number,
): CheckoutReportSaleOwnerTotalsByType<Unit> => {
  const totalsIncludingItemsPaidWithMembership = CheckoutReportTotalsByType.fromCheckoutTransaction(
    transaction,
    decimals,
    true,
  );
  const totalsExcludingItemsPaidWithMembership = CheckoutReportTotalsByType.fromCheckoutTransaction(
    transaction,
    decimals,
  );
  /**
   * Delivered include sum of totals from: all services and products (paid with membership or not), amounts
   * and protocol value of memberships
   */
  const delivered: CheckoutReportTotals<Unit> = [
    totalsIncludingItemsPaidWithMembership.services,
    totalsIncludingItemsPaidWithMembership.products,
    totalsIncludingItemsPaidWithMembership.amounts,
    {
      gross: totalsIncludingItemsPaidWithMembership.memberships.gross,
      discount: totalsIncludingItemsPaidWithMembership.memberships.discount,
      vat: zeroMoney(transaction.unit),
      net: zeroMoney(transaction.unit),
      total: zeroMoney(transaction.unit),
      protocol: totalsIncludingItemsPaidWithMembership.memberships.protocol,
    },
  ].reduce(CheckoutReportTotals.add, CheckoutReportTotals.zero(transaction.unit));

  return {
    ...totalsExcludingItemsPaidWithMembership,
    delivered: delivered,
  };
};

export const CheckoutReportSaleOwnerTotalsByType = {
  fromCheckoutTransaction,
  zero,
  add,
};
