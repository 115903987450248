import * as React from 'react';
import Svg, { Path, Rect, G } from 'react-native-svg';

import { BLACK } from '../../../styles/colors';

type Props = {
  size?: string | number;
  color?: string;
  disabled?: boolean;
  onPress?: () => void;
};

const LocationServicesSquare: React.FC<Props> = ({ size = 24, color = BLACK, disabled = false, onPress }) => (
  <Svg width={size} height={size} viewBox="0 0 24 24" disabled={disabled} onPress={onPress}>
    <G transform="translate(-160 -550)">
      <G transform="translate(-32 -3)">
        <G transform="translate(14370 7817)">
          <G transform="translate(-14178 -7264)">
            <G transform="translate(0 0)">
              <Rect width="24" height="24" rx="5" fill="#007df8" />
            </G>
          </G>
          <G transform="translate(-14178 -7264)">
            <Rect width="24" height="24" transform="translate(0 0)" fill="none" />
          </G>
        </G>
      </G>
      <G transform="translate(166.146 556.763)">
        <Path
          d="M.4,4.445,10.566.057a.65.65,0,0,1,.873.8L7.073,11.145a.614.614,0,0,1-1.191-.16L5.823,6.279a.544.544,0,0,0-.556-.558H.563A.669.669,0,0,1,.4,4.445Z"
          fill="#fff"
        />
      </G>
    </G>
  </Svg>
);

export default LocationServicesSquare;
