import { BulkCalendarData } from './bulkCalendarData';
import { BulkDailyBounds } from './bulkDailyBounds';
import { JSONable, optionull } from '@mero/shared-sdk';
import * as t from 'io-ts';

export type BulkCalendarsResponse = {
  /**
   * Calendar entries for all calendars
   */
  readonly calendars: BulkCalendarData[];
  /**
   * DayTime that fits all the appointments and working hours, for all calendars
   */
  readonly activeDailyBounds: BulkDailyBounds | undefined;
};

const JSON: t.Type<BulkCalendarsResponse, JSONable> = t.type(
  {
    calendars: t.array(BulkCalendarData.JSON),
    activeDailyBounds: optionull(BulkDailyBounds.JSON),
  },
  'BulkCalendarsResponse',
);

export const BulkCalendarsResponse = {
  JSON,
};
