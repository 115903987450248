import * as React from 'react';
import Svg, { Circle, G, Path } from 'react-native-svg';

import { DARK_BLUE } from '../../../styles/colors';

type Props = {
  size?: number | string;
  color?: string;
  disabled?: boolean;
  onPress?: () => void;
};

const PlusScrewSmall = ({ size = 24, color = DARK_BLUE, disabled, onPress }: Props) => (
  <Svg width={size} height={size} viewBox="0 0 24 24" disabled={disabled} onPress={onPress}>
    <G transform="translate(4 3.939)">
      <Circle
        cx="6"
        cy="6"
        r="6"
        transform="translate(2 2.061)"
        fill="none"
        stroke={color}
        stroke-linecap="round"
        stroke-linejoin="round"
        stroke-miterlimit="10"
        stroke-width="1.2"
      />
      <Path
        d="M19.195,16v6.39m3.195-3.195H16"
        transform="translate(-11.204 -11.125)"
        fill="none"
        stroke={color}
        stroke-linecap="round"
        stroke-linejoin="round"
        stroke-miterlimit="10"
        stroke-width="1.2"
      />
    </G>
  </Svg>
);

export default React.memo(PlusScrewSmall);
