import { DefinedTrimedString, JSONable } from '@mero/shared-sdk';
import * as t from 'io-ts';

/**
 * Validation algorithm
 * https://gist.github.com/brokenthorn/dc4cadd2ecfcbb57bf6bdb7721a83239
 */
export type RoFiscalCode = DefinedTrimedString;

const JSON: t.Type<RoFiscalCode, JSONable> = DefinedTrimedString;

export const RoFiscalCode = {
  JSON,
};
