import { ProductId } from './product-id';
import * as t from 'io-ts';

export const ProductReview = t.type({
  count: t.number,
  average: t.number,
});

export type ProductReview = t.TypeOf<typeof ProductReview>;

export const Product = t.intersection([
  t.type({
    name: t.string,
    price: t.number,
    mainPictureUrl: t.string,
    pictureUrls: t.array(t.string),
    url: t.string,
    reviews: ProductReview,
  }),
  t.partial({
    sku: t.string,
    mainCategory: t.string,
    subCategory: t.string,
    description: t.string,
    oldPrice: t.number,
    discountPercentage: t.number,
    manufacturer: t.string,
  }),
]);

export type Product = t.TypeOf<typeof Product>;
