import { ObjectId } from '@mero/shared-sdk';
import * as t from 'io-ts';

export interface CheckoutCashRegisterIdBrand {
  readonly CheckoutCashRegisterId: unique symbol;
}

export type CheckoutCashRegisterId = t.Branded<ObjectId, CheckoutCashRegisterIdBrand>;

export const CheckoutCashRegisterId = {
  //
};
