import * as React from 'react';
import { TouchableOpacity } from 'react-native';

import * as colors from '../../styles/colors';
import Icon from '../Icon';
import Null from '../Null';
import TextInput, { Props as TextInputProps } from '../TextInput';

export type Props = {
  value?: string;
  isError?: boolean;
  isFocused?: boolean;
  autoFocus?: boolean;
  placeholder?: string;
  onChange?: (value: string) => void;
  background?: string;
} & TextInputProps;

const SearchTextInput: React.FC<Props> = ({
  value,
  isError,
  isFocused,
  autoFocus,
  placeholder,
  onChange,
  onSubmitEditing,
  textContentType,
  keyboardType,
  keyboardAppearance,
  editable,
  background,
  ...rest
}: Props) => {
  const valueIsEmpty = value === '' || value === undefined;

  const inputClearCallback = React.useCallback(() => {
    if (onChange) {
      onChange('');
    }
  }, [onChange]);

  const ClearInputComponent: React.FC = React.useMemo(
    () =>
      function ClearInput() {
        return (
          <TouchableOpacity onPress={inputClearCallback} disabled={valueIsEmpty}>
            <Icon type="close" size={24} color={colors.COMET} />
          </TouchableOpacity>
        );
      },
    [inputClearCallback, valueIsEmpty],
  );

  const SearchIconComponent = React.memo(function SearchIcon() {
    return <Icon type="search" size={24} color={colors.COMET} />;
  });

  return (
    <TextInput
      value={value}
      isError={isError}
      isFocused={isFocused}
      autoFocus={autoFocus}
      placeholder={placeholder}
      rounded={true}
      LeftComponent={SearchIconComponent}
      RightComponent={valueIsEmpty ? Null : ClearInputComponent}
      onChange={onChange}
      onSubmitEditing={onSubmitEditing}
      textContentType={textContentType}
      keyboardType={keyboardType}
      keyboardAppearance={keyboardAppearance}
      editable={editable}
      background={background ?? colors.WHITE}
      {...rest}
    />
  );
};

export default SearchTextInput;
