import * as React from 'react';
import Svg, { G, Path, Rect } from 'react-native-svg';

type Props = {
  size?: string | number;
  color?: string;
  disabled?: boolean;
  onPress?: () => void;
};

const GoUp = ({ size = 24, color = '#0820e0', disabled, onPress }: Props) => (
  <Svg width={size} height={size} viewBox={`0 0 ${size} ${size}`} disabled={disabled} onPress={onPress}>
    <G id="Group_7016" data-name="Group 7016" transform="translate(-352 14393)">
      <G id="Orion_arrow-up" transform="translate(340.022 -14402.686)">
        <Path
          id="layer2"
          d="M32,14V29.372"
          transform="translate(-8.023)"
          fill="none"
          stroke={color}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeMiterlimit="10"
          strokeWidth="1.7"
        />
        <Path
          id="layer1"
          d="M29.956,19.978,23.978,14,18,19.978"
          fill="none"
          stroke={color}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeMiterlimit="10"
          strokeWidth="1.7"
        />
      </G>
      <Rect
        id="Rectangle_2673"
        data-name="Rectangle 2673"
        width="24"
        height="24"
        transform="translate(352 -14393)"
        fill="none"
      />
    </G>
  </Svg>
);

export default React.memo(GoUp);
