import * as t from 'io-ts';

export type BlockedTime = 1;

const JSON = t.literal(1, 'BlockedTime');

const VALUE: BlockedTime = 1;

export const BlockedTime = {
  JSON,
  VALUE,
};
