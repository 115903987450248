import { ServiceId } from '../services';
import { WorkerId } from '../workers';
import { JSONable, optionull } from '@mero/shared-sdk';
import * as t from 'io-ts';

export type ImageServiceRef = {
  /**
   * ID of the service performed in the picture
   */
  readonly serviceId: ServiceId;
  /**
   * Optional worker ID who performed the service
   */
  readonly workerId: WorkerId | undefined;
};

const JSON: t.Type<ImageServiceRef, JSONable> = t.type(
  {
    serviceId: ServiceId,
    workerId: optionull(WorkerId.JSON),
  },
  'ImageService',
);

export const ImageServiceRef = {
  JSON,
};
