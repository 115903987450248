import { ObjectId } from '@mero/shared-sdk';
import * as t from 'io-ts';

export interface ClientIdBrand {
  readonly ClientId: unique symbol;
}

export const ClientId = t.brand(
  ObjectId,
  (_id: ObjectId): _id is t.Branded<ObjectId, ClientIdBrand> => true,
  'ClientId',
);

export type ClientId = t.TypeOf<typeof ClientId>;

export const HasClientId = t.type(
  {
    _id: ClientId,
  },
  'HasClientId',
);
export interface HasClientId extends t.TypeOf<typeof HasClientId> {}
