import { ANALYTICS_NAMESPACE } from '../namespaces';
import { IErrorTable } from 'exceptional.js';

export const TABLE: IErrorTable = {
  namespace: ANALYTICS_NAMESPACE,
  locale: 'ro',
  errors: {
    10: 'placeholder',
  },
};
