import { JSONable } from '@mero/shared-sdk';
import * as t from 'io-ts';

/**
 * Count change based on previous month count
 */
export type MonthlyCountChangeReport = {
  readonly count: number;
  readonly change:
    | {
        readonly type: 'Increased';
        readonly increase: number;
      }
    | {
        readonly type: 'Decreased';
        readonly decrease: number;
      }
    | {
        readonly type: 'None';
      };
};

const JSON: t.Type<MonthlyCountChangeReport, JSONable> = t.type(
  {
    count: t.number,
    change: t.union(
      [
        t.type(
          {
            type: t.literal('Increased'),
            /**
             * increase percent as absolute value
             */
            increase: t.number,
          },
          'Increased',
        ),
        t.type(
          {
            type: t.literal('Decreased'),
            /**
             * decrease percent as absolute value
             */
            decrease: t.number,
          },
          'Decreased',
        ),
        t.type(
          {
            type: t.literal('None'),
          },
          'None',
        ),
      ],
      'Change',
    ),
  },
  'MonthlyCountChangeReport',
);

export const MonthlyCountChangeReport = {
  JSON: JSON,
};
