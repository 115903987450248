import * as React from 'react';
import Svg, { G, Path } from 'react-native-svg';

type Props = {
  size?: number | string;
  color?: string;
  disabled?: boolean;
  onPress?: () => void;
};

const ProMenuGiftcards = ({ size = 32, color = '#000000', disabled, onPress }: Props) => (
  <Svg width={size} height={size} disabled={disabled} onPress={onPress}>
    <G data-name="Group 5930" fill="none">
      <Path data-name="Rectangle 59" d="M0 0h32v32H0z" />
      <G data-name="Group 6882">
        <G stroke={color} strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit={10} strokeWidth={1.7}>
          <Path d="M22.128 16.854v7.787H8.778v-7.787m-1.112-3.337H23.24v3.337H7.666Z" />
          <Path d="M13.228 13.517v11.125m4.45-11.125v11.125M13.84 8.789a2.058 2.058 0 0 0-1.725-.835 2.225 2.225 0 0 0-1.474 3.895c1.168 1.029 4.812 1.668 4.812 1.668a9.054 9.054 0 0 0-1.613-4.728Zm3.226 0a2.058 2.058 0 0 1 1.722-.835 2.225 2.225 0 0 1 1.474 3.894c-1.166 1.03-4.809 1.669-4.809 1.669a9.054 9.054 0 0 1 1.613-4.728Z" />
        </G>
        <Path data-name="Rectangle 2603" d="M4 4h24v24H4z" />
      </G>
    </G>
  </Svg>
);

export default React.memo(ProMenuGiftcards);
