import * as t from 'io-ts';

/**
 * Membership purchased by client
 */
export const MembershipPurchasedByClientHistoryType = t.literal('MembershipPurchasedByClient');
export type MembershipPurchasedByClientHistoryType = t.TypeOf<typeof MembershipPurchasedByClientHistoryType>;

/**
 * Membership payment collected by pro
 */
export const MembershipPaymentCollectedByProHistoryType = t.literal('PaymentCollectedByPro');
export type MembershipPaymentCollectedByProHistoryType = t.TypeOf<typeof MembershipPaymentCollectedByProHistoryType>;

/**
 * Appointment paid using membership
 */
export const AppointmentPaidUsingMembershipHistoryType = t.literal('AppointmentPaidUsingMembership');
export type AppointmentPaidUsingMembershipHistoryType = t.TypeOf<typeof AppointmentPaidUsingMembershipHistoryType>;

/**
 * Appointment membership payment cancelled
 */
export const AppointmentMembershipPaymentCancelledHistoryType = t.literal('AppointmentMembershipPaymentCancelled');
export type AppointmentMembershipPaymentCancelledHistoryType = t.TypeOf<
  typeof AppointmentMembershipPaymentCancelledHistoryType
>;

/**
 * Service paid using membership
 */
export const ServicesPaidUsingMembershipHistoryType = t.literal('ServicesPaidUsingMembership');
export type ServicesPaidUsingMembershipHistoryType = t.TypeOf<typeof ServicesPaidUsingMembershipHistoryType>;

/**
 * Service membership payment cancelled
 */
export const ServicesMembershipPaymentCancelledHistoryType = t.literal('ServicesMembershipPaymentCancelled');
export type ServicesMembershipPaymentCancelledHistoryType = t.TypeOf<
  typeof ServicesMembershipPaymentCancelledHistoryType
>;

/**
 * Membership cancelled by pro
 */
export const MembershipCancelledByProHistoryType = t.literal('MembershipCancelledByPro');
export type MembershipCancelledByProHistoryType = t.TypeOf<typeof MembershipCancelledByProHistoryType>;

/**
 * Membership payment cancelled by pro
 */
export const MembershipPaymentCancelledByProHistoryType = t.literal('PaymentCancelledByPro');
export type MembershipPaymentCancelledByProHistoryType = t.TypeOf<typeof MembershipPaymentCancelledByProHistoryType>;

export const PurchasedMembershipHistoryType = t.union(
  [
    MembershipPurchasedByClientHistoryType,
    MembershipPaymentCollectedByProHistoryType,
    AppointmentPaidUsingMembershipHistoryType,
    AppointmentMembershipPaymentCancelledHistoryType,
    ServicesPaidUsingMembershipHistoryType,
    ServicesMembershipPaymentCancelledHistoryType,
    MembershipCancelledByProHistoryType,
    MembershipPaymentCancelledByProHistoryType,
  ],
  'PurchasedMembershipHistoryType',
);

export type PurchasedMembershipHistoryType = t.TypeOf<typeof PurchasedMembershipHistoryType>;
