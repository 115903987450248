import { CheckoutServiceSalesReportItem } from './checkoutServiceSalesReportItem';
import { CheckoutServiceSalesReportTotals } from './checkoutServiceSalesReportTotals';
import { JSONable, MeroUnits } from '@mero/shared-sdk';
import * as t from 'io-ts';

export type CheckoutServiceSalesReportPage<Unit extends MeroUnits.Any> = {
  readonly items: CheckoutServiceSalesReportItem<Unit>[];
  readonly totals: CheckoutServiceSalesReportTotals<Unit>;
};

const json = <Unit extends MeroUnits.Any>(unit: Unit): t.Type<CheckoutServiceSalesReportPage<Unit>, JSONable> => {
  return t.type(
    {
      items: t.array(CheckoutServiceSalesReportItem.json(unit)),
      totals: CheckoutServiceSalesReportTotals.json(unit),
    },
    `CheckoutServiceSalesReportPage<${unit}>`,
  );
};

export const CheckoutServiceSalesReportPage = {
  json,
};
