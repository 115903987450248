import * as t from 'io-ts';

export type Month = 'm' | 'month' | 'months';

const JSON: t.Type<Month, string> = t.union([t.literal('m'), t.literal('month'), t.literal('months')], 'Month');

export const Month = {
  JSON,
  is: JSON.is,
};
