import * as React from 'react';
import Svg, { Path, Circle, Rect, G } from 'react-native-svg';

type Props = {
  size?: string | number;
  disabled?: boolean;
  onPress?: () => void;
};

const ClientHistoryNoteIcon = ({ size = 32, disabled = false, onPress }: Props) => (
  <Svg width={size} height={size} viewBox="0 0 32 32" disabled={disabled} onPress={onPress}>
    <Circle id="Mask" cx="16" cy="16" r="16" fill="#e9ecef" />
    <G transform="translate(7 7)">
      <Rect width="18" height="18" fill="none" />
      <Path
        d="M15,4.5v6.75H11.25V15H4.5V4.5H15M15,3H4.5A1.5,1.5,0,0,0,3,4.5V15a1.5,1.5,0,0,0,1.5,1.5H12L16.5,12V4.5A1.5,1.5,0,0,0,15,3ZM9.75,11.25H6V9.75H9.75Zm3.75-3H6V6.75h7.5Z"
        transform="translate(-0.75 -0.75)"
        fill="#52577f"
      />
    </G>
  </Svg>
);

export default React.memo(ClientHistoryNoteIcon);
