import { DiscountPercent, DiscountPercentModule } from './discountPercent';
import { ScaledNumber } from './scaledNumber';
import * as t from 'io-ts';

export type DiscountPercentScaledNumber = DiscountPercent<ScaledNumber>;

export type DiscountPercentScaledNumberModule = DiscountPercentModule<ScaledNumber> & {
  readonly JSON: t.Type<DiscountPercent<ScaledNumber>, t.OutputOf<typeof ScaledNumber.JSON>>;
};

const DiscountPercentN = DiscountPercent.build(ScaledNumber);

const JSON: t.Type<DiscountPercent<ScaledNumber>, t.OutputOf<typeof ScaledNumber.JSON>> = DiscountPercentN.json(
  ScaledNumber.JSON,
);

export const DiscountPercentScaledNumber: DiscountPercentScaledNumberModule = {
  ...DiscountPercentN,
  JSON: JSON,
};
