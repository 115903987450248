import { BusinessCategoryId } from './businessCategoryId';
import { optionull, JSONable } from '@mero/shared-sdk';
import * as t from 'io-ts';

export type BusinessCategory = {
  readonly _id: BusinessCategoryId;
  readonly name: string;
  readonly imageUrl: string;
  readonly order: number;
  readonly seo:
    | {
        readonly title: string | undefined;
        readonly description: string | undefined;
      }
    | undefined;
};

const JSON: t.Type<BusinessCategory, JSONable> = t.type(
  {
    _id: BusinessCategoryId,
    name: t.string,
    imageUrl: t.string,
    order: t.number,
    seo: optionull(
      t.type(
        {
          title: optionull(t.string),
          description: optionull(t.string),
        },
        'Seo',
      ),
    ),
  },
  'BusinessCategory',
);

export const BusinessCategory = {
  JSON,
};
