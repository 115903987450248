import { JSONable } from '@mero/shared-sdk';
import * as t from 'io-ts';

export type ClientTypeReport = {
  readonly count: number;
  readonly income: number;
};

const JSON: t.Type<ClientTypeReport, JSONable> = t.type(
  {
    count: t.number,
    income: t.number,
  },
  'ClientTypeReport',
);

export const ClientTypeReport = {
  JSON: JSON,
};
