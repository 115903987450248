import { JSONable } from '@mero/shared-sdk';
import * as t from 'io-ts';

export type CheckoutReportBySaleOwnerSortType =
  | 'appointments-count'
  | 'memberships-count'
  | 'products-count'
  | 'services-count'
  | 'gross'
  | 'discount'
  | 'vat'
  | 'total'
  | 'net'
  | 'protocol';

const APPOINTMENTS_COUNT = 'appointments-count' as const;
const MEMBERSHIPS_COUNT = 'memberships-count' as const;
const PRODUCTS_COUNT = 'products-count' as const;
const SERVICES_COUNT = 'services-count' as const;
const GROSS = 'gross' as const;
const DISCOUNT = 'discount' as const;
const VAT = 'vat' as const;
const TOTAL = 'total' as const;
const NET = 'net' as const;
const PROTOCOL = 'protocol' as const;

const JSON: t.Type<CheckoutReportBySaleOwnerSortType, JSONable> = t.union(
  [
    t.literal(APPOINTMENTS_COUNT),
    t.literal(MEMBERSHIPS_COUNT),
    t.literal(PRODUCTS_COUNT),
    t.literal(SERVICES_COUNT),
    t.literal(GROSS),
    t.literal(DISCOUNT),
    t.literal(VAT),
    t.literal(TOTAL),
    t.literal(NET),
    t.literal(PROTOCOL),
  ],
  'CheckoutReportByServiceSortType',
);

export const CheckoutReportBySaleOwnerSortType = {
  APPOINTMENTS_COUNT,
  MEMBERSHIPS_COUNT,
  PRODUCTS_COUNT,
  SERVICES_COUNT,
  GROSS,
  DISCOUNT,
  VAT,
  TOTAL,
  NET,
  PROTOCOL,
  JSON,
};
