import * as A from 'fp-ts/Array';
import { pipe } from 'fp-ts/lib/function';

const par = async <T>(tasks: (() => Promise<T>)[], concurrency: number): Promise<T[]> => {
  const chunks = pipe(tasks, A.chunksOf(concurrency));
  const out: T[] = [];

  for (const chunk of chunks) {
    out.push(...(await Promise.all(chunk.map((p) => p()))));
  }

  return out;
};

export const Promises = {
  par,
};
