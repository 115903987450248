import { MembershipPurchaseId } from '../../memberships';
import { SavedService } from '../../services';
import { JSONable, Option, PositiveInt, Numbers } from '@mero/shared-sdk';
import * as t from 'io-ts';

export type ServiceWithAvailableMemberships = SavedService & {
  readonly availableMemberships: Option<
    {
      readonly membership: {
        readonly _id: MembershipPurchaseId;
        readonly name: string;
      };
      readonly availableQuantity:
        | {
            readonly type: 'Limited';
            readonly remaining: Numbers.Zero | PositiveInt;
          }
        | {
            readonly type: 'Unlimited';
          };
    }[]
  >;
};

const JSON: t.Type<ServiceWithAvailableMemberships, JSONable> = t.intersection(
  [
    SavedService,
    t.type({
      availableMemberships: Option.json(
        t.array(
          t.type(
            {
              membership: t.type(
                {
                  _id: MembershipPurchaseId.JSON,
                  name: t.string,
                },
                'Membership',
              ),
              availableQuantity: t.union(
                [
                  t.type(
                    {
                      type: t.literal('Limited'),
                      remaining: t.union([Numbers.Zero, PositiveInt.JSON], 'Remaining'),
                    },
                    'LimitedAvailableQuantity',
                  ),
                  t.type(
                    {
                      type: t.literal('Unlimited'),
                    },
                    'UnlimitedAvailableQuantity',
                  ),
                ],
                'AvailableQuantity',
              ),
            },
            'AvailableMembership',
          ),
          'AvailableMemberships',
        ),
      ),
    }),
  ],
  'ServiceWithAvailableMemberships',
);

export const ServiceWithAvailableMemberships = {
  JSON,
};
