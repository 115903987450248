import * as t from 'io-ts';

export type Appointment = 0;

const JSON = t.literal(0, 'Appointment');

const VALUE: Appointment = 0;

export const Appointment = {
  JSON,
  VALUE,
};
