export type PagesSearch2Config = {
  readonly filters: {
    /**
     * Allow filtering using hasPromoPrice filter
     */
    readonly hasPromoPrice: boolean;
    /**
     * Allow filter using isTabyaCertified filter
     */
    readonly isTabyaCertified: boolean;
    /**
     * Allow filtering using hasGiftcards filter
     */
    readonly hasGiftcards: boolean;
    /**
     * Allow filtering using acceptsCardPayments filter
     */
    readonly acceptsCardPayments: boolean;
  };
};

/**
 * Resonable default filters config, to be used by client apps before server responds with real config for a query
 */
const CLIENT_DEFAULT: PagesSearch2Config = {
  filters: {
    hasPromoPrice: true,
    isTabyaCertified: false,
    hasGiftcards: false,
    acceptsCardPayments: false,
  },
};

const clientDefault = (): PagesSearch2Config => {
  return CLIENT_DEFAULT;
};

const hasAnyFilter = (config: PagesSearch2Config): boolean => {
  return (
    config.filters.hasPromoPrice ||
    config.filters.isTabyaCertified ||
    config.filters.hasGiftcards ||
    config.filters.acceptsCardPayments
  );
};

export const PagesSearch2Config = {
  clientDefault,
  hasAnyFilter,
};
