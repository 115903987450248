import { BillingCycle } from '../../../payments';
import { SubscriptionPriceOffer } from '../subscriptionPriceOffer';
import {
  AppliedDiscount,
  DiscountPercentScaledNumber,
  JSONable,
  MeroUnits,
  Money,
  Option,
  ScaledNumber,
} from '@mero/shared-sdk';
import * as t from 'io-ts';

const MeroUnitsC: { [Unit in MeroUnits.Any]: t.Type<Unit> } = {
  [MeroUnits.RON.code]: MeroUnits.RON.JSON,
  [MeroUnits.EUR.code]: MeroUnits.EUR.JSON,
};

const unitC = <Unit extends MeroUnits.Any>(unit: Unit): t.Type<Unit> => MeroUnitsC[unit];

const MeroMoneyC: { [Unit in MeroUnits.Any]: t.Type<Money<ScaledNumber, Unit>, JSONable> } = {
  [MeroUnits.RON.code]: Money.json(ScaledNumber.JSON, unitC(MeroUnits.RON.code)),
  [MeroUnits.EUR.code]: Money.json(ScaledNumber.JSON, unitC(MeroUnits.EUR.code)),
};

const moneyC = <Unit extends MeroUnits.Any>(unit: Unit): t.Type<Money<ScaledNumber, Unit>, JSONable> => {
  return MeroMoneyC[unit];
};

export const appliedDiscountC = <Unit extends MeroUnits.Any>(
  unit: Unit,
): t.Type<AppliedDiscount<ScaledNumber, Unit>, JSONable> => {
  return t.union(
    [
      t.strict(
        {
          type: t.literal('Percent'),
          percent: DiscountPercentScaledNumber.JSON,
        },
        'Percent',
      ),
      t.strict(
        {
          type: t.literal('Value'),
          value: moneyC(unit),
        },
        'Value',
      ),
    ],
    `AppliedDiscount<${unit}>`,
  );
};

export const SubscriptionPriceOfferC = <Unit extends MeroUnits.Any>(
  unit: Unit,
): t.Type<SubscriptionPriceOffer<Unit>, JSONable> => {
  return t.type(
    {
      billingCycle: BillingCycle,
      price: moneyC(unit),
      discount: Option.json(appliedDiscountC(unit)),
      title: t.string,
      description: t.string,
      bonus: Option.json(
        t.type(
          {
            title: t.string,
            color: t.string,
            backgroundColor: t.string,
          },
          'Bonus',
        ),
      ),
    },
    `SubscriptionPriceOfferC`,
  );
};

export const AnySubscriptionPriceOfferC: t.Type<
  SubscriptionPriceOffer<MeroUnits.RON> | SubscriptionPriceOffer<MeroUnits.EUR>,
  JSONable
> = t.union(
  [SubscriptionPriceOfferC(MeroUnits.RON.code), SubscriptionPriceOfferC(MeroUnits.EUR.code)],
  'AnySubscriptionPriceOfferC',
);
