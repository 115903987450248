import { ObjectId } from '@mero/shared-sdk';
import * as t from 'io-ts';

export interface ClientHistoryRecordIdBrand {
  readonly ClientHistoryRecordId: unique symbol;
}

export const ClientHistoryRecordId = t.brand(
  ObjectId,
  (_id: ObjectId): _id is t.Branded<ObjectId, ClientHistoryRecordIdBrand> => true,
  'ClientHistoryRecordId',
);

export type ClientHistoryRecordId = t.TypeOf<typeof ClientHistoryRecordId>;

export const HasClientHistoryRecordId = t.type(
  {
    _id: ClientHistoryRecordId,
  },
  'HasClientHistoryRecordId',
);
export interface HasClientHistoryRecordId extends t.TypeOf<typeof HasClientHistoryRecordId> {}
