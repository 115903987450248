import { ObjectId } from '@mero/shared-sdk';
import * as t from 'io-ts';

export interface InventoryIdBrand {
  readonly InventoryId: unique symbol;
}

export type InventoryId = t.Branded<ObjectId, InventoryIdBrand>;

export const InventoryId = t.brand(
  ObjectId,
  (_id: ObjectId): _id is t.Branded<ObjectId, InventoryIdBrand> => true,
  'InventoryId',
);
