import * as React from 'react';
import Svg, { Path, G } from 'react-native-svg';

type Props = {
  size?: string | number;
  color?: string;
  disabled?: boolean;
  onPress?: () => void;
};

const Facebook: React.FC<Props> = ({ size = 32, color = '#1977f3', disabled = false, onPress }: Props) => (
  <Svg width={size} height={size} viewBox="0 0 32 32" disabled={disabled} onPress={onPress}>
    <G transform="translate(-40 -566)">
      <G transform="translate(-104.5 501.5)">
        <Path
          d="M171.054,80.527a10.527,10.527,0,1,0-12.171,10.4V83.57h-2.674V80.527h2.674v-2.32c0-2.638,1.572-4.1,3.976-4.1a16.183,16.183,0,0,1,2.357.206v2.591h-1.328a1.522,1.522,0,0,0-1.716,1.645v1.975h2.919l-.466,3.044h-2.453v7.356a10.528,10.528,0,0,0,8.882-10.4Z"
          fill={color}
          fillRule="evenodd"
        />
        <Path
          d="M235.118,130.245l.466-3.044h-2.919v-1.975a1.522,1.522,0,0,1,1.716-1.645h1.328v-2.591a16.182,16.182,0,0,0-2.357-.206c-2.4,0-3.976,1.457-3.976,4.1v2.32H226.7v3.044h2.674V137.6a10.582,10.582,0,0,0,1.645.127,10.765,10.765,0,0,0,1.645-.127v-7.356h2.453Z"
          transform="translate(-70.493 -46.675)"
          fill="#fefefe"
          fillRule="evenodd"
        />
      </G>
    </G>
  </Svg>
);

export default React.memo(Facebook);
