import { SavedCardPreview } from '../savedCardPreview';
import { JSONable } from '@mero/shared-sdk';
import * as t from 'io-ts';

export type ListSubscriptionSavedCardsResponse = {
  readonly result: SavedCardPreview[];
};
export const ListSubscriptionSavedCardsResponse: t.Type<ListSubscriptionSavedCardsResponse, JSONable> = t.type(
  {
    result: t.array(SavedCardPreview.JSON),
  },
  'ListSubscriptionSavedCardsResponse',
);
