import { DefinedTrimedString } from '../string';
import * as t from 'io-ts';

/**
 * Code 128 Barcode
 * Matches strings that can contain any ASCII character (values 0-127)
 */
interface Code128StringBrand {
  readonly Code128String: unique symbol;
}

export type Code128String = t.Branded<DefinedTrimedString, Code128StringBrand>;

const JSON: t.Type<Code128String, string> = t.brand(
  t.string,
  (s): s is Code128String => /^[\x00-\x7F]*$/.test(s),
  'Code128String',
);

export const Code128String = {
  JSON,
};
