import { ObjectId } from '@mero/shared-sdk';
import * as t from 'io-ts';

export interface CityIdBrand {
  readonly CityId: unique symbol;
}

export const CityId = t.brand(ObjectId, (_id: ObjectId): _id is t.Branded<ObjectId, CityIdBrand> => true, 'CityId');

export type CityId = t.TypeOf<typeof CityId>;

export const City = t.type(
  {
    _id: CityId,
    name: t.string,
    ref: t.number,
    location: t.type({
      lat: t.number,
      lng: t.number,
    }),
  },
  'City',
);

export type City = t.TypeOf<typeof City>;
