import * as React from 'react';
import Svg, { Path, G } from 'react-native-svg';

type Props = {
  size?: number | string;
  color?: string;
  disabled?: boolean;
  onPress?: () => void;
};

const UserColor = '#9DA0B6';

const User: React.FC<Props> = ({ size = 24, color = UserColor, disabled = false, onPress }) => (
  <Svg width={size} height={size} viewBox="0 0 24 24" onPress={onPress} disabled={disabled}>
    <G transform="translate(-12 -11.5)">
      <Path
        d="M-8148,13357.5a1,1,0,0,1-1-1v-1.871a4.783,4.783,0,0,1,3.919-3.506c3.379-.577,3.379-1.6,3.379-2.232v-1.022a6.235,6.235,0,0,1-2.028-4.717v-3.061c0-3.315,2.5-4.593,4.73-4.593s4.73,1.277,4.73,4.593v3.061a6.234,6.234,0,0,1-2.028,4.717v1.022c0,.637,0,1.625,3.379,2.232a4.854,4.854,0,0,1,3.919,3.506v1.871a1,1,0,0,1-1,1Z"
        transform="translate(8162.998 -13323)"
        fill={color}
      />
    </G>
  </Svg>
);

export default React.memo(User);
