import { JSONable } from '../../common/jsonable';
import * as t from 'io-ts';

export type BillingCompanyId = 'mero_programari_srl' | 'mero_pro_apps_srl';

const JSON: t.Type<BillingCompanyId, JSONable> = t.union(
  [t.literal('mero_programari_srl'), t.literal('mero_pro_apps_srl')],
  'BillingCompany',
);

export const BillingCompanyId = {
  JSON,
};
