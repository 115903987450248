import * as t from 'io-ts';
import * as tt from 'io-ts-types';

export const BoostClientClaimStatusPending = t.type(
  {
    type: t.literal('pending'),
    at: tt.DateFromISOString,
  },
  'BoostClientClaimStatusPending',
);
export interface BoostClientClaimStatusPending extends t.TypeOf<typeof BoostClientClaimStatusPending> {}

export const BoostClientClaimStatusCommissionAccepted = t.type(
  {
    type: t.literal('commissionAccepted'),
    at: tt.DateFromISOString,
  },
  'BoostClientClaimStatusCommissionAccepted',
);
export interface BoostClientClaimStatusCommissionAccepted
  extends t.TypeOf<typeof BoostClientClaimStatusCommissionAccepted> {}

export const BoostClientClaimStatusApproved = t.type(
  {
    type: t.literal('approved'),
    at: tt.DateFromISOString,
  },
  'BoostClientClaimStatusApproved',
);
export interface BoostClientClaimStatusApproved extends t.TypeOf<typeof BoostClientClaimStatusApproved> {}

export const BoostClientClaimStatusRejected = t.type(
  {
    type: t.literal('rejected'),
    at: tt.DateFromISOString,
  },
  'BoostClientClaimStatusRejected',
);
export interface BoostClientClaimStatusRejected extends t.TypeOf<typeof BoostClientClaimStatusRejected> {}

/**
 * Page appointment commission claim status
 * (none) -> Pending -> Accepted
 *  \          \ \----> Rejected
 *   \          \ \---<---/ /
 *    \          \         /
 *     \------> CommissionAccepted
 *
 * (none) - no claim has been made (BoostClaimRequest record does not exist)
 * Pending - claim has been made but not yet accepted or rejected
 * Accepted - claim has been accepted - commission must NOT be paid (or must be refunded)
 * Rejected - claim has been rejected - commission must be paid to the page
 * CommissionAccepted - commission has been accepted by merchant, no more claim is possible
 */
export const BoostClientClaimStatus = t.union(
  [
    BoostClientClaimStatusPending,
    BoostClientClaimStatusCommissionAccepted,
    BoostClientClaimStatusApproved,
    BoostClientClaimStatusRejected,
  ],
  'BoostClientClaimStatus',
);
export type BoostClientClaimStatus = t.TypeOf<typeof BoostClientClaimStatus>;
