import * as React from 'react';
import Svg, { Path, Rect } from 'react-native-svg';

type Props = {
  width?: number;
  height?: number;
  color?: string;
};

const PhotoPlaceholder = ({ width = 375, height = 200, color = '#f4f5f7' }: Props): React.ReactElement => (
  <Svg width={width} height={height} viewBox={`0 0 ${width} ${height}`}>
    <Rect id="Rectangle_2574" data-name="Rectangle 2574" width={width} height={height} fill={color} />
    <Path
      id="Path_8482"
      data-name="Path 8482"
      d="M41.745,35.486c0,6.34-5.232,7.675-5.232,7.675H55.662s-5.232-1.335-5.232-7.675V7.956c0-6.34,5.232-7.675,5.232-7.675h-13.9l-12.5,26.5-3.451-6L14.014.281H1.527A11.933,11.933,0,0,1,7.714,10.792V33.484A9.5,9.5,0,0,1,0,43.161H16.394a9.517,9.517,0,0,1-7.6-9.677V8.844l16.63,28.6L41.761,2.828Z"
      transform={`translate(${width / 2 - 28.5} ${height / 2 - 21.721})`}
      fill="#e9ecef"
    />
  </Svg>
);

export default PhotoPlaceholder;
