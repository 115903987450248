import * as React from 'react';
import { TextProps, Text } from 'react-native';

import { styles } from '../../styles/text';

export type H2sProps = React.PropsWithChildren<TextProps>;

const H2s: React.FC<H2sProps> = (props: H2sProps) => (
  // This style of settings props avoids generation of _rest helper function in js output
  <Text accessibilityRole="header" aria-level="2" {...props} style={[styles.h2s, props.style]}>
    {props.children}
  </Text>
);

export default H2s;
