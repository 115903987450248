import { JSONable } from '@mero/shared-sdk';
import * as t from 'io-ts';

export interface UserOrderIdBrand {
  readonly UserOrderId: unique symbol;
}

export type UserOrderId = t.Branded<string, UserOrderIdBrand>;

const JSON: t.Type<UserOrderId, JSONable> = t.brand(
  t.string,
  (_id: string): _id is t.Branded<string, UserOrderIdBrand> => true,
  'UserOrderId',
);

export const UserOrderId = {
  JSON,
};
