import { Editable } from './editable';
import { NonEditable } from './nonEditable';
import { JSONable } from '@mero/shared-sdk';
import * as t from 'io-ts';

export type Any = NonEditable | Editable;

const JSON: t.Type<Any, JSONable> = t.union([NonEditable.JSON, Editable.JSON]);

export const Any = {
  JSON,
};
