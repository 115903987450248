import * as t from 'io-ts';

export const SubscriptionSeat = t.type(
  {
    addonPrice: t.number,
    proRataPrice: t.number,
    totalPrice: t.number,
    shouldConfirmNewPrice: t.boolean,
  },
  'SubscriptionSeat',
);

export type SubscriptionSeat = t.TypeOf<typeof SubscriptionSeat>;
