import { JSONable } from '@mero/shared-sdk';
import * as t from 'io-ts';

export type NoShow = 3;

const VALUE: NoShow = 3;

const JSON: t.Type<NoShow, JSONable> = t.literal(VALUE);

export const NoShow = {
  JSON,
  VALUE,
};
