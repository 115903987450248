import { appliedDiscountC } from '../checkout/checkoutHttpClient/checkoutJson';
import { JSONable, MeroUnits, Money, ScaledNumber, Option, AppliedDiscount } from '@mero/shared-sdk';
import * as t from 'io-ts';

const moneyC = Money.json(ScaledNumber.JSON, MeroUnits.RON.JSON);

type Fixed = {
  readonly type: 'Fixed';
  readonly fixed: Money<ScaledNumber, MeroUnits.RON>;
  readonly discount: Option<AppliedDiscount<ScaledNumber, MeroUnits.RON>>;
};

type Range = {
  readonly type: 'Range';
  readonly range: {
    readonly from: Money<ScaledNumber, MeroUnits.RON>;
    readonly to: Money<ScaledNumber, MeroUnits.RON>;
  };
  readonly discount: Option<AppliedDiscount<ScaledNumber, MeroUnits.RON>>;
};

export type BookingServiceCustomPrice = Fixed | Range;

const JSON: t.Type<BookingServiceCustomPrice, JSONable> = t.union([
  t.type(
    {
      type: t.literal('Fixed'),
      fixed: moneyC,
      discount: Option.json(appliedDiscountC(MeroUnits.RON.code)),
    },
    'FixedBookingServiceTotal',
  ),
  t.type(
    {
      type: t.literal('Range'),
      range: t.type(
        {
          from: moneyC,
          to: moneyC,
        },
        'Range',
      ),
      discount: Option.json(appliedDiscountC(MeroUnits.RON.code)),
    },
    'RangeBookingServiceTotal',
  ),
]);

export const BookingServiceCustomPrice = {
  JSON,
};
