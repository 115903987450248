import { JSONable, MeroUnits, Money, ScaledNumber } from '@mero/shared-sdk';
import * as t from 'io-ts';

export type CheckoutReportPaymentsDetailed<Unit extends MeroUnits.Any> = {
  readonly card: {
    readonly gross: Money<ScaledNumber, Unit>;
    readonly vat: Money<ScaledNumber, Unit>;
    readonly net: Money<ScaledNumber, Unit>;
  };
  readonly cash: {
    readonly gross: Money<ScaledNumber, Unit>;
    readonly vat: Money<ScaledNumber, Unit>;
    readonly net: Money<ScaledNumber, Unit>;
  };
  readonly bankTransfer: {
    readonly gross: Money<ScaledNumber, Unit>;
    readonly vat: Money<ScaledNumber, Unit>;
    readonly net: Money<ScaledNumber, Unit>;
  };
};

/**
 * Build new JSON codec for type {@link CheckoutReportPaymentsDetailed} with given unit {@link unit}
 */
const json = <Unit extends MeroUnits.Any>(unit: Unit): t.Type<CheckoutReportPaymentsDetailed<Unit>, JSONable> => {
  const unitC = t.literal(unit);
  const MoneyC = Money.json(ScaledNumber.JSON, unitC);
  const PaymentsValuesC = t.type(
    {
      gross: MoneyC,
      vat: MoneyC,
      net: MoneyC,
    },
    'PaymentsValues',
  );

  return t.type(
    {
      cash: PaymentsValuesC,
      card: PaymentsValuesC,
      bankTransfer: PaymentsValuesC,
    },
    `CheckoutPaymentsDetailed<${unit}>`,
  );
};

export const CheckoutReportPaymentsDetailed = {
  json,
};
