import { JSONable } from '@mero/shared-sdk';
import * as t from 'io-ts';

export interface SubscriptionFeatureIdBrand {
  readonly SubscriptionFeatureId: unique symbol;
}

/**
 * Unique subscription feature identifier
 */
export type SubscriptionFeatureId = t.Branded<string, SubscriptionFeatureIdBrand>;

const JSON: t.Type<SubscriptionFeatureId, JSONable> = t.brand(
  t.string,
  (_id: string): _id is SubscriptionFeatureId => {
    return true;
  },
  'SubscriptionFeatureId',
);

const equals = (a: SubscriptionFeatureId, b: SubscriptionFeatureId): boolean => {
  return a === b;
};

const unsafeFrom = (featureId: string): SubscriptionFeatureId => {
  if (!JSON.is(featureId)) {
    throw new Error(`Unknown SubscriptionFeatureId ${featureId}`);
  }

  return featureId;
};

/**
 * Vânzare online abonamente
 */
const MEMBERSHIPS_ONLINE_SALES = unsafeFrom('MembershipsOnlineSales');
/**
 * Registru de casă
 */
const CASH_REGISTRY = unsafeFrom('CashRegistry');
/**
 * Gestiuni multiple
 */
const MULTIPLE_INVENTORIES = unsafeFrom('MultipleInventories');
/**
 * Documente de gestiune
 */
const INVENTORY_DOCUMENTS = unsafeFrom('InventoryDocuments');
/**
 * Emitere facturi clienți din încasările offiline
 */
const OFFLINE_PAYMENTS_INVOICES = unsafeFrom('OfflinePaymentsInvoices');

export const SubscriptionFeatureId = {
  JSON,
  MEMBERSHIPS_ONLINE_SALES,
  CASH_REGISTRY,
  MULTIPLE_INVENTORIES,
  INVENTORY_DOCUMENTS,
  OFFLINE_PAYMENTS_INVOICES,
  equals,
};
