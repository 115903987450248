import * as React from 'react';
import { TextProps, Text } from 'react-native';

import { styles } from '../../styles/text';

export type TitleProps = React.PropsWithChildren<TextProps>;

const Title: React.FC<TitleProps> = (props: TitleProps) => (
  // This style of settings props avoids generation of _rest helper function in js output
  <Text {...props} style={[styles.title, props.style]}>
    {props.children}
  </Text>
);

export default Title;
