import { PageId } from '../pages/page-id';
import { WorkerId } from '../workers/workerId';
import { InvitePreview } from './invite-preview';
import { InvitesApi } from './invites-api';
import { HttpClient, unsafeRight, UnknownApiError } from '@mero/shared-sdk';
import * as t from 'io-ts';

export const invitesHttpClient = (env: { apiBaseUrl: string; http: HttpClient }): InvitesApi => {
  const { apiBaseUrl, http } = env;

  const unknownError = UnknownApiError;
  const inviteWorkerResponseDecoder = http.decode.response(unknownError, t.unknown);
  const fulfillInviteResponseDecoder = http.decode.response(unknownError, t.unknown);
  const getPendingInvitesResponseDecoder = http.decode.response(unknownError, t.array(InvitePreview));

  return {
    inviteWorkerToPage: async (params) => {
      const requestBody: {
        toWorkerId: WorkerId;
        fromPageId: PageId;
        serviceIds?: string[];
        roleId?: number;
      } = {
        toWorkerId: params.workerId,
        fromPageId: params.pageId,
        ...(params.serviceIds ? { serviceIds: params.serviceIds } : {}),
        ...(params.roleId ? { roleId: params.roleId } : {}),
      };

      unsafeRight(
        await http.request(
          {
            method: 'POST',
            url: `${apiBaseUrl}/business/invite/work`,
            data: requestBody,
            withCredentials: true,
          },
          inviteWorkerResponseDecoder,
        ),
      );
    },
    fulfillInvite: async (params) => {
      const requestBody: {
        accept: boolean;
      } = {
        accept: params.resolution === 'accept',
      };

      unsafeRight(
        await http.request(
          {
            method: 'PUT',
            url: `${apiBaseUrl}/business/invite/${encodeURIComponent(params.inviteId)}`,
            data: requestBody,
          },
          fulfillInviteResponseDecoder,
        ),
      );
    },
    getPendingInvites: async () => {
      return unsafeRight(
        await http.request(
          {
            method: 'GET',
            url: `${apiBaseUrl}/business/invite/pending`,
          },
          getPendingInvitesResponseDecoder,
        ),
      );
    },
  };
};
