import * as React from 'react';
import Svg, { Path, G } from 'react-native-svg';

type Props = {
  readonly size?: number | string;
  readonly disabled?: boolean;
  readonly onPress?: () => void;
  readonly color?: string;
  readonly backgroundColor?: string;
  readonly isFilled?: boolean;
};

const Favorite: React.FC<Props> = ({
  size = 32,
  color = '#FFFFFF',
  backgroundColor = 'none',
  disabled,
  onPress,
  isFilled,
}) => {
  return (
    <Svg width={size} height={size} viewBox="0 0 32 32" disabled={disabled} onPress={onPress}>
      <G transform="translate(649 15935)">
        <Path
          d="M19.271,5a6.243,6.243,0,0,0-5.757,3.838A6.243,6.243,0,0,0,7.757,5,5.757,5.757,0,0,0,2,10.757c0,4.989,4.222,9.979,11.514,14.968,7.292-4.989,11.514-9.979,11.514-14.968A5.757,5.757,0,0,0,19.271,5Z"
          transform="translate(-646.514 -15933.044)"
          fill={isFilled ? '#F05F7C' : backgroundColor ?? 'none'}
          stroke={color}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeMiterlimit="10"
          strokeWidth="1.7"
        />
      </G>
    </Svg>
  );
};

export default Favorite;
