import { Price } from './price';
import { ServiceId } from './service-id';
import * as t from 'io-ts';

export const ServicePreview = t.intersection(
  [
    t.type({
      _id: ServiceId,
      name: t.string,
      price: Price,
    }),
    t.partial({
      durationInMinutes: t.number,
    }),
  ],
  'ServicePreview',
);

export interface ServicePreview extends t.TypeOf<typeof ServicePreview> {}
