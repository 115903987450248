import * as React from 'react';
import { AppState, AppStateStatus } from 'react-native';

/**
 * Hook to read react native application AppStateStatus
 * https://reactnative.dev/docs/appstate
 */
export default function useAppState(): AppStateStatus {
  const [status, setStatus] = React.useState<AppStateStatus>(AppState.currentState);

  React.useEffect(() => {
    const listener = AppState.addEventListener('change', setStatus);

    return () => {
      listener.remove();
    };
  }, [setStatus]);

  return status;
}
