import { WorkerId } from '../workers';
import { WorkerDepartment } from './workerDepartment';
import { WorkerDepartmentApi } from './workerDepartmentApi';
import { WorkerDepartmentId } from './workerDepartmentId';
import { HasId, HttpClient, UnknownApiError, unsafeRight } from '@mero/shared-sdk';
import * as t from 'io-ts';

export const workerDepartmentHttpClient = (env: { apiBaseUrl: string; http: HttpClient }): WorkerDepartmentApi => {
  const { apiBaseUrl, http } = env;

  const createDepartmentDecoder = http.decode.response(UnknownApiError, HasId.json(WorkerDepartmentId.JSON));
  const updateDepartmentNameDecoder = http.decode.response(UnknownApiError, t.unknown);
  const deleteDepartmentDecoder = http.decode.response(UnknownApiError, t.unknown);
  const getDepartmensDecoder = http.decode.response(UnknownApiError, t.array(WorkerDepartment.JSON));
  const updateDepartmentOrderDecoder = http.decode.response(UnknownApiError, t.unknown);

  const enc = encodeURIComponent;

  return {
    createDepartment: async (params) => {
      const requestBody: {
        name: string;
      } = {
        ...params,
      };

      const result = unsafeRight(
        await http.request(
          {
            method: 'POST',
            url: `${apiBaseUrl}/page/${enc(params.pageId)}/departments`,
            data: requestBody,
          },
          createDepartmentDecoder,
        ),
      );

      return result._id;
    },
    updateDepartmentName: async (params) => {
      const requestBody: {
        name: string;
      } = {
        name: params.name,
      };

      unsafeRight(
        await http.request(
          {
            method: 'PUT',
            url: `${apiBaseUrl}/page/${enc(params.pageId)}/departments/${enc(params.workerDepartmentId)}`,
            data: requestBody,
          },
          updateDepartmentNameDecoder,
        ),
      );
    },
    removeDepartment: async (params) => {
      unsafeRight(
        await http.request(
          {
            method: 'DELETE',
            url: `${apiBaseUrl}/page/${enc(params.pageId)}/departments/${enc(params.workerDepartmentId)}`,
          },
          deleteDepartmentDecoder,
        ),
      );
    },
    getDepartments: async (params) => {
      return unsafeRight(
        await http.request(
          {
            method: 'GET',
            url: `${apiBaseUrl}/page/${enc(params.pageId)}/departments`,
          },
          getDepartmensDecoder,
        ),
      );
    },
    updateDepartmentsOrder: async (params) => {
      const requestBody: {
        departmentId: WorkerDepartmentId;
        workerIds: WorkerId[];
      }[] = params.workerDepartments;

      unsafeRight(
        await http.request(
          {
            method: 'PUT',
            url: `${apiBaseUrl}/page/${enc(params.pageId)}/departments-order`,
            data: requestBody,
          },
          updateDepartmentOrderDecoder,
        ),
      );
    },
    updateDepartmentsForWorker: async (params) => {
      const requestBody: {
        departmentIds: WorkerDepartmentId[];
      } = {
        departmentIds: params.departmentIds,
      };

      unsafeRight(
        await http.request(
          {
            method: 'PUT',
            url: `${apiBaseUrl}/page/${enc(params.pageId)}/worker/${enc(params.workerId)}/departments`,
            data: requestBody,
          },
          updateDepartmentOrderDecoder,
        ),
      );
    },
  };
};
