import { CheckoutItemVatStatus } from './checkoutItemVatStatus';
import { CheckoutTotals } from './checkoutTotals';
import { CompanyVatStatus } from './companies';
import { ExtendedCalculator, HasFromNumber, MeroUnits, Money, PortionPercent } from '@mero/shared-sdk';

/**
 * A total of a membership transaction item
 */
export type CheckoutMembershipItemTotal<Num, Unit extends MeroUnits.Any> = {
  /**
   * Total calculated amount
   */
  readonly amount: Money<Num, Unit>;

  readonly vatStatus: CheckoutItemVatStatus.Included<Num>;
};

export type CheckoutMembershipItemTotalModule<Num> = {
  /**
   * @returns Total amount to pay, including VAT with discount applied
   */
  readonly getTotal: <Unit extends MeroUnits.Any>(
    itemTotal: CheckoutMembershipItemTotal<Num, Unit>,
    companyVat: CompanyVatStatus.Any<Num>,
    decimals: number,
  ) => Money<Num, Unit>;

  /**
   * VatAmount = NoVATProductPrice * VatPercent / 100
   * Total = NoVATProductPrice + VatAmount
   * VatFromTotal = Total * VatPercent / (100 + VatPercent)
   *
   * @returns undefined if there is no VAT or VAT amount included in total value
   */
  readonly getVat: <Unit extends MeroUnits.Any>(
    itemTotal: CheckoutMembershipItemTotal<Num, Unit>,
    companyVat: CompanyVatStatus.Any<Num>,
    decimals: number,
  ) => Money<Num, Unit> | undefined;

  /**
   * Get totals for given item
   */
  readonly getTotals: <Unit extends MeroUnits.Any>(
    itemTotal: CheckoutMembershipItemTotal<Num, Unit>,
    companyVat: CompanyVatStatus.Any<Num>,
    decimals: number,
  ) => CheckoutTotals<Num, Unit>;
};

/**
 * Build new instance of CheckoutItemTotalModule<Num, Unit>
 */
const build = <Num>(num: ExtendedCalculator<Num> & HasFromNumber<Num>): CheckoutMembershipItemTotalModule<Num> => {
  const PortionPercentNum = PortionPercent.build(num);

  const getTotal = <Unit extends MeroUnits.Any>(
    itemTotal: CheckoutMembershipItemTotal<Num, Unit>,
  ): Money<Num, Unit> => {
    return itemTotal.amount;
  };

  const getVat = <Unit extends MeroUnits.Any>(
    itemTotal: CheckoutMembershipItemTotal<Num, Unit>,
    companyVat: CompanyVatStatus.Any<Num>,
    decimals: number,
  ): Money<Num, Unit> | undefined => {
    const finalTotal = getTotal(itemTotal);
    const vatRate =
      itemTotal.vatStatus.rate ?? (companyVat.type === 'VatPayer' ? companyVat.defaultRate : PortionPercentNum.zero());

    // finalTotal already includes VAT
    const vatValue = num.div(num.mul(finalTotal.amount, vatRate), num.add(num.fromNumber(100, 0), vatRate), decimals);

    return Money.of(vatValue, itemTotal.amount.unit);
  };

  const getTotals = <Unit extends MeroUnits.Any>(
    itemTotal: CheckoutMembershipItemTotal<Num, Unit>,
    companyVat: CompanyVatStatus.Any<Num>,
    decimals: number,
  ): CheckoutTotals<Num, Unit> => {
    const vat = getVat(itemTotal, companyVat, decimals);
    const total = getTotal(itemTotal);

    return {
      subtotal: total,
      discount: undefined,
      vat: vat,
      total: total,
    };
  };

  return {
    getTotal,
    getVat,
    getTotals,
  };
};
export const CheckoutMembershipItemTotal = {
  build,
};
