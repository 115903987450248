import * as React from 'react';
import { View, StyleSheet, ViewStyle } from 'react-native';

import { styles } from './styles';

type TextSkeletonHeight = 'h1' | 'h2' | 'title' | 'body' | 'label';

const TextSkeletonHeightValues = {
  h1: 24,
  h2: 20,
  title: 16,
  body: 16,
  label: 12,
};

type TextSkeletonWidth = 'short' | 'medium' | 'long' | 'fill';

const TextSkeletonWidthValues = {
  short: 92,
  medium: 148,
  long: 212,
  fill: '100%',
};

type TextSkeletonProps = {
  height?: TextSkeletonHeight;
  width?: TextSkeletonWidth;
};

const TextSkeleton: React.FC<TextSkeletonProps> = ({ height = 'body', width = 'medium' }: TextSkeletonProps) => {
  return (
    <View
      style={StyleSheet.compose<ViewStyle, ViewStyle, ViewStyle>(styles.textSkeleton, {
        height: TextSkeletonHeightValues[height],
        width: TextSkeletonWidthValues[width],
      })}
    />
  );
};

export default TextSkeleton;
