import * as t from 'io-ts';

export const AnalyticsStats = t.type(
  {
    totalAppointments: t.number,
    totalCancelledAppointments: t.number,
    totalIncome: t.number,
    totalNewClients: t.number,
  },
  'AnalyticsBoostStats',
);
export type AnalyticsStats = t.TypeOf<typeof AnalyticsStats>;
