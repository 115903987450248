import * as React from 'react';
import { View } from 'react-native';

import Checkbox, { Props as CheckboxProps } from '../Checkbox';

type Props = CheckboxProps & {
  children?: React.ReactNode;
};

const CheckboxLayout: React.FC<Props> = ({ children, ...rest }) => {
  return (
    <View
      style={{
        flexDirection: 'row',
      }}
    >
      <View
        style={{
          marginTop: 4,
          marginRight: 12,
          marginBottom: 4,
          marginLeft: 4,
        }}
      >
        <Checkbox {...rest} />
      </View>
      <View style={{ flex: 1 }}>{children}</View>
    </View>
  );
};

export default CheckboxLayout;
