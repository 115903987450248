import * as t from 'io-ts';

export interface LatitudeBrand {
  readonly Latitude: unique symbol;
}

/**
 * https://en.wikipedia.org/wiki/Latitude
 */
export type Latitude = t.Branded<number, LatitudeBrand>;

const JSON: t.Type<Latitude, number> = t.brand(
  t.number,
  (val: number): val is Latitude => val >= -90 && val <= 90,
  'Latitude',
);

export const Latitude = {
  JSON,
};
