import { CouponId } from './couponId';
import { CouponType } from './couponType';
import { JSONable, MeroUnits, Money, Option, ScaledNumber } from '@mero/shared-sdk';
import * as t from 'io-ts';

export type Coupon = {
  readonly _id: CouponId;
  readonly type: CouponType;
  readonly code: string;
  readonly title: string;
  readonly value: Money<ScaledNumber, MeroUnits.RON>;
  /**
   * coupon flags
   */
  readonly flags: Option<{
    /**
     * Coupon applies only to boost appointments
     */
    readonly boostOnly: boolean;
  }>;
};

const JSON: t.Type<Coupon, JSONable> = t.type(
  {
    _id: CouponId.JSON,
    type: t.string,
    code: t.string,
    title: t.string,
    value: Money.json(ScaledNumber.JSON, MeroUnits.RON.JSON),
    flags: Option.json(
      t.type(
        {
          boostOnly: t.boolean,
        },
        'CouponFlags',
      ),
    ),
  },
  'Coupon',
);

export const Coupon = {
  JSON,
};
