import { OnlinePaymentSource } from './onlinePaymentSource';
import { MeroUnits, Money } from '@mero/shared-sdk';

/**
 * Online payments type stub
 */
export type Online<Num, Unit extends MeroUnits.Any> = {
  readonly type: 'Online';
  readonly total: Money<Num, Unit>;
  readonly source: OnlinePaymentSource.Any;
};

export const online = <Num, Unit extends MeroUnits.Any>(params: {
  readonly total: Money<Num, Unit>;
  readonly source: OnlinePaymentSource.Any;
}): Online<Num, Unit> => {
  return {
    type: 'Online',
    total: params.total,
    source: params.source,
  };
};
