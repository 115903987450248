import * as React from 'react';
import Svg, { Circle, G, Path } from 'react-native-svg';

type Props = {
  size?: number | string;
  color?: string;
  disabled?: boolean;
  onPress?: () => void;
};

const ProMenuPermissions = ({ size = 32, color = '#000000', disabled, onPress }: Props) => (
  <Svg width={size} height={size} viewBox="0 0 32 32" disabled={disabled} onPress={onPress}>
    <G transform="translate(4 4)">
      <Circle cx="1.12" cy="1.12" r="1.12" transform="translate(15.88 14.38)" fill={color} />
      <Path
        d="M17,17.5c-.73,0-2.19.36-2.24,1.08a2.729,2.729,0,0,0,4.48,0C19.19,17.86,17.73,17.5,17,17.5Z"
        fill-rule="evenodd"
        fill={color}
      />
      <Path
        d="M18,11.09V6.27L10.5,3,3,6.27v4.91A10.213,10.213,0,0,0,10.5,21a9.166,9.166,0,0,0,1.6-.55A6,6,0,1,0,18,11.09ZM11,17a5.766,5.766,0,0,0,.23,1.62,6.587,6.587,0,0,1-.73.3A8.208,8.208,0,0,1,5,11.18V7.58l5.5-2.4L16,7.58v3.51A6,6,0,0,0,11,17Zm6,4a4,4,0,1,1,4-4A4,4,0,0,1,17,21Z"
        fill-rule="evenodd"
        fill={color}
      />
    </G>
  </Svg>
);

export default React.memo(ProMenuPermissions);
