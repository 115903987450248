import * as React from 'react';
import Svg, { Circle, G } from 'react-native-svg';

import { DARK_BLUE } from '../../../styles/colors';

type Props = {
  size?: number | string;
  color?: string;
  disabled?: boolean;
  onPress?: () => void;
};

const Unchecked: React.FC<Props> = ({ size = 24, disabled = false, color = DARK_BLUE, onPress }) => (
  <Svg width={size} height={size} viewBox="0 0 24 24" disabled={disabled} onPress={onPress}>
    <G transform="translate(-126 -558)">
      <G transform="translate(126 558)" fill="none" stroke={color} strokeWidth="1.5">
        <Circle cx="12" cy="12" r="12" stroke="none" />
        <Circle cx="12" cy="12" r="11.25" fill="none" />
      </G>
    </G>
  </Svg>
);

export default React.memo(Unchecked);
