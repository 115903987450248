import { AppointmentId } from './appointment-id';
import * as t from 'io-ts';

export const HasAppointmentId = t.type(
  {
    _id: AppointmentId,
  },
  'HasAppointmentId',
);

export type HasAppointmentId = t.TypeOf<typeof HasAppointmentId>;

export const HasAppointmentIdSimple = t.type(
  {
    id: AppointmentId,
  },
  'HasAppointmentIdSimple',
);

export type HasAppointmentIdSimple = t.TypeOf<typeof HasAppointmentIdSimple>;
