import { HasOptionalFirstLastName } from '../common';
import { optionull } from '@mero/shared-sdk';
import { ProfileImage } from '@mero/shared-sdk/dist/assets';
import * as t from 'io-ts';

export const UserProfile = t.intersection(
  [
    HasOptionalFirstLastName,
    t.partial({
      photo: optionull(ProfileImage),
    }),
  ],
  'UserProfile',
);

export type UserProfile = t.TypeOf<typeof UserProfile>;
