import { ShortTimeUnit } from './short-time-unit';
import * as t from 'io-ts';

export const MomentTimeUnit = t.keyof(
  {
    years: true,
    year: true,
    y: true,
    quarters: true,
    quarter: true,
    Q: true,
    months: true,
    month: true,
    M: true,
    weeks: true,
    week: true,
    w: true,
    days: true,
    day: true,
    d: true,
    hours: true,
    hour: true,
    h: true,
    minute: true,
    minutes: true,
    m: true,
    seconds: true,
    second: true,
    s: true,
    milliseconds: true,
    millisecond: true,
    ms: true,
  },
  'MomentTimeUnit',
);

export type MomentTimeUnit = t.TypeOf<typeof MomentTimeUnit>;

export const MomentYearsTimeUnit = t.keyof(
  {
    years: true,
    year: true,
    y: true,
  },
  'MomentYearsTimeUnit',
);

export type MomentYearsTimeUnit = t.TypeOf<typeof MomentYearsTimeUnit>;

export const MomentQuartersTimeUnit = t.keyof(
  {
    quarters: true,
    quarter: true,
    Q: true,
  },
  'MomentQuartersTimeUnit',
);

export type MomentQuartersTimeUnit = t.TypeOf<typeof MomentQuartersTimeUnit>;

export const MomentMonthsTimeUnit = t.keyof(
  {
    months: true,
    month: true,
    M: true,
  },
  'MomentMonthsTimeUnit',
);

export type MomentMonthsTimeUnit = t.TypeOf<typeof MomentMonthsTimeUnit>;

export const MomentWeeksTimeUnit = t.keyof(
  {
    weeks: true,
    week: true,
    w: true,
  },
  'MomentWeeksTimeUnit',
);

export type MomentWeeksTimeUnit = t.TypeOf<typeof MomentWeeksTimeUnit>;

export const MomentDaysTimeUnit = t.keyof(
  {
    days: true,
    day: true,
    d: true,
  },
  'MomentDaysTimeUnit',
);

export type MomentDaysTimeUnit = t.TypeOf<typeof MomentDaysTimeUnit>;

export const MomentHoursTimeUnit = t.keyof(
  {
    hours: true,
    hour: true,
    h: true,
  },
  'MomentHoursTimeUnit',
);

export type MomentHoursTimeUnit = t.TypeOf<typeof MomentHoursTimeUnit>;

export const MomentMinutesTimeUnit = t.keyof(
  {
    minutes: true,
    minute: true,
    m: true,
  },
  'MomentMinutesTimeUnit',
);

export type MomentMinutesTimeUnit = t.TypeOf<typeof MomentMinutesTimeUnit>;

export const MomentSecondsTimeUnit = t.keyof(
  {
    seconds: true,
    second: true,
    s: true,
  },
  'MomentSecondsTimeUnit',
);

export type MomentSecondsTimeUnit = t.TypeOf<typeof MomentSecondsTimeUnit>;

export const MomentMillisecondsTimeUnit = t.keyof(
  {
    milliseconds: true,
    millisecond: true,
    ms: true,
  },
  'MomentMillisecondsTimeUnit',
);

export type MomentMillisecondsTimeUnit = t.TypeOf<typeof MomentMillisecondsTimeUnit>;

const toShort = (unit: MomentTimeUnit): ShortTimeUnit => {
  switch (unit) {
    case 'y':
      return 'y';
    case 'year':
      return 'y';
    case 'years':
      return 'y';
    case 'quarters':
      return 'Q';
    case 'quarter':
      return 'Q';
    case 'Q':
      return 'Q';
    case 'months':
      return 'M';
    case 'month':
      return 'M';
    case 'M':
      return 'M';
    case 'weeks':
      return 'w';
    case 'week':
      return 'w';
    case 'w':
      return 'w';
    case 'days':
      return 'd';
    case 'day':
      return 'd';
    case 'd':
      return 'd';
    case 'hours':
      return 'h';
    case 'hour':
      return 'h';
    case 'h':
      return 'h';
    case 'minutes':
      return 'm';
    case 'minute':
      return 'm';
    case 'm':
      return 'm';
    case 'seconds':
      return 's';
    case 'second':
      return 's';
    case 's':
      return 's';
    case 'milliseconds':
      return 'ms';
    case 'millisecond':
      return 'ms';
    case 'ms':
      return 'ms';
  }
};

/**
 * MomentTimeUnit type class intance
 */
export const momentTimeUnit = {
  toShort,
};

/**
 * @deprecated use {@link momentTimeUnit.toShort}
 */
export const simplifyMomentTimeUnit = (
  unit: MomentTimeUnit,
): 'years' | 'quarters' | 'months' | 'weeks' | 'days' | 'hours' | 'minutes' | 'seconds' | 'milliseconds' => {
  switch (unit) {
    case 'years':
      return 'years';
    case 'year':
      return 'years';
    case 'y':
      return 'years';
    case 'quarters':
      return 'quarters';
    case 'quarter':
      return 'quarters';
    case 'Q':
      return 'quarters';
    case 'months':
      return 'months';
    case 'month':
      return 'months';
    case 'M':
      return 'months';
    case 'weeks':
      return 'weeks';
    case 'week':
      return 'weeks';
    case 'w':
      return 'weeks';
    case 'days':
      return 'days';
    case 'day':
      return 'days';
    case 'd':
      return 'days';
    case 'hours':
      return 'hours';
    case 'hour':
      return 'hours';
    case 'h':
      return 'hours';
    case 'minutes':
      return 'minutes';
    case 'minute':
      return 'minutes';
    case 'm':
      return 'minutes';
    case 'seconds':
      return 'seconds';
    case 'second':
      return 'seconds';
    case 's':
      return 'seconds';
    case 'milliseconds':
      return 'milliseconds';
    case 'millisecond':
      return 'milliseconds';
    case 'ms':
      return 'milliseconds';
  }
};
