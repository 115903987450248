import { DiscountPercent, DiscountPercentModule } from './discountPercent';
import { Numbers } from './numbers';
import * as t from 'io-ts';

export type DiscountPercentNumber = DiscountPercent<number>;

export type DiscountPercentNumberModule = DiscountPercentModule<number> & {
  readonly JSON: t.Type<DiscountPercent<number>, number>;
};

const DiscountPercentN = DiscountPercent.build(Numbers);

export const DiscountPercentNumber: DiscountPercentNumberModule = {
  ...DiscountPercentN,
  JSON: DiscountPercentN.json(t.number),
};
