import { AppointmentId, CalendarId } from '../calendar';
import { HasOptionalFirstLastName } from '../common';
import { PageId } from '../pages';
import { UserId } from '../users/user-id';
import { Worker } from '../workers/worker';
import { InviteId } from './invite-id';
import { InviteStatus } from './invite-status';
import { InviteTypeWork, InviteTypeClientRequest, InviteTypeAppointmentRequest } from './invite-type';
import { optionull } from '@mero/shared-sdk';
import { ProfileImage } from '@mero/shared-sdk/dist/assets';
import * as t from 'io-ts';
import { DateFromISOString } from 'io-ts-types';

const InvitePreviewUser = t.intersection(
  [
    t.type({
      _id: UserId,
      isGhost: t.boolean,
      phone: t.string,
      profile: t.intersection(
        [
          HasOptionalFirstLastName,
          t.partial({
            photo: optionull(ProfileImage),
          }),
        ],
        'InvitePreviewUserProfile',
      ),
    }),
    t.partial({
      facebook: t.partial({
        id: t.string,
      }),
    }),
  ],
  'InvitePreviewUser',
);

const WorkerInvitePagePreview = t.type(
  {
    _id: PageId,
    name: t.string,
    profilePhoto: optionull(ProfileImage),
  },
  'WorkerInvitePagePreview',
);

export const WorkInvitePreview = t.type(
  {
    _id: InviteId,
    type: InviteTypeWork,
    status: InviteStatus,
    toWorker: Worker.JSON,
    fromPage: WorkerInvitePagePreview,
    createdAt: DateFromISOString,
  },
  'WorkInvitePreview',
);

export interface WorkInvitePreview extends t.TypeOf<typeof WorkInvitePreview> {}

export const ClientJoinInvitePreview = t.type(
  {
    _id: InviteId,
    type: InviteTypeClientRequest,
    status: InviteStatus,
    toWorker: Worker.JSON,
    fromUser: InvitePreviewUser,
    createdAt: DateFromISOString,
  },
  'ClientJoinInvitePreview',
);

export interface ClientJoinInvitePreview extends t.TypeOf<typeof ClientJoinInvitePreview> {}

export const AppointmentRequestInvitePreview = t.type(
  {
    _id: InviteId,
    type: InviteTypeAppointmentRequest,
    status: InviteStatus,
    toWorker: Worker.JSON,
    fromUser: InvitePreviewUser,
    appointment: t.type({
      appointmentId: AppointmentId,
      calendarId: CalendarId,
      start: DateFromISOString,
    }),
    createdAt: DateFromISOString,
  },
  'AppointmentRequestInvitePreview',
);

export interface AppointmentRequestInvitePreview extends t.TypeOf<typeof AppointmentRequestInvitePreview> {}

export const InvitePreview = t.union(
  [WorkInvitePreview, ClientJoinInvitePreview, AppointmentRequestInvitePreview],
  'InvitePreview',
);

export type InvitePreview = WorkInvitePreview | ClientJoinInvitePreview | AppointmentRequestInvitePreview;
