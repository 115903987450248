import * as luxon from 'luxon';
import * as React from 'react';

import H1 from '../../Text/H1';
import H2s from '../../Text/H2s';

export type Props = {
  /**
   * Booking start date
   */
  readonly date: Date;

  /**
   * Booking date worker timezone
   */
  readonly timezone: string;

  /**
   * Size of the text
   */
  size?: 'small' | 'large';
};

function capitalizeFirstLetter(str: string) {
  return str.charAt(0).toUpperCase() + str.slice(1);
}

/**
 * Component used to render the time part of an user appointment
 * as well as its status and recurrence rule.
 */
export default function UserAppointmentDate({ date, timezone, size = 'small' }: Props): React.ReactElement {
  const [dateDisplayStr, setDateDisplayStr] = React.useState('');

  React.useEffect(() => {
    // format start date
    const luxonDate = luxon.DateTime.fromJSDate(date).setLocale('ro').setZone(timezone);
    const dayOfWeek = capitalizeFirstLetter(luxonDate.toFormat('EEE').substr(0, 2).concat('.'));
    const dayAndMonth = luxonDate.toFormat('d MMM');
    const startHour = luxonDate.toFormat('HH:mm');

    if (size === 'small') {
      setDateDisplayStr(`${dayOfWeek} ${dayAndMonth}`);
    } else {
      setDateDisplayStr(`${dayOfWeek} ${dayAndMonth} - ${startHour}`);
    }
  }, []);

  if (size === 'small') return <H2s>{dateDisplayStr}</H2s>;

  return <H1>{dateDisplayStr}</H1>;
}
