import * as t from 'io-ts';

export const ProfileImage = t.type(
  {
    _id: t.string,
    thumbnail: t.string,
    small: t.string,
    medium: t.string,
    large: t.string,
    original: t.string,
  },
  'ProfileImage',
);

export type ProfileImage = t.TypeOf<typeof ProfileImage>;
