import { CompanyName } from '../companyName';
import { RoFiscalCode } from '../roFiscalCode';
import { RoIBANish } from '../roIBANish';
import { RoRegistrationNumber } from '../roRegistrationNumber';
import { DefinedTrimedString, Email, Firstname, JSONable, Lastname, Option, StrictPhoneNumber } from '@mero/shared-sdk';
import * as t from 'io-ts';

export type Company = {
  readonly type: 'Company';
  /**
   * This field may be a country discriminator in the future
   */
  readonly country: 'RO';
  readonly name: CompanyName;
  readonly fiscalCode: RoFiscalCode;
  readonly isVatPayer: boolean;
  readonly registrationNumber: RoRegistrationNumber;
  readonly iban: Option<RoIBANish>;
  readonly contact: {
    readonly firstname: Firstname;
    readonly lastname: Lastname;
    readonly email: Email;
    readonly phone: StrictPhoneNumber;
  };
  readonly address: {
    readonly county: DefinedTrimedString;
    readonly city: DefinedTrimedString;
    readonly address: DefinedTrimedString;
  };
};

const JSON = t.type(
  {
    type: t.literal('Company'),
    country: t.literal('RO'),
    name: CompanyName.JSON,
    fiscalCode: RoFiscalCode.JSON,
    isVatPayer: t.boolean,
    registrationNumber: RoRegistrationNumber.JSON,
    iban: Option.json(RoIBANish.JSON),
    contact: t.type(
      {
        firstname: Firstname,
        lastname: Lastname,
        email: Email.TRUSTED_JSON,
        phone: StrictPhoneNumber,
      },
      'Contact',
    ),
    address: t.type(
      {
        county: DefinedTrimedString,
        city: DefinedTrimedString,
        address: DefinedTrimedString,
      },
      'Address',
    ),
  },
  'Company',
);

export const Company = {
  JSON,
};
