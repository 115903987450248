import { ObjectId } from '@mero/shared-sdk';
import * as t from 'io-ts';

export interface ClientAppointmentIdBrand {
  readonly ClientAppointmentId: unique symbol;
}

export const ClientAppointmentId = t.brand(
  ObjectId,
  (_id: ObjectId): _id is t.Branded<ObjectId, ClientAppointmentIdBrand> => true,
  'ClientAppointmentId',
);

export type ClientAppointmentId = t.TypeOf<typeof ClientAppointmentId>;
