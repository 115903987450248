import { Numbers, PositiveInt } from '../numbers';
import { optionull, unsafeDecode } from './io';
import { JSONable } from './jsonable';
import * as E from 'fp-ts/Either';
import { pipe } from 'fp-ts/lib/function';
import * as t from 'io-ts';
import * as tt from 'io-ts-types';

export type AppVersion = {
  readonly major: Numbers.Zero | PositiveInt;
  readonly minor: Numbers.Zero | PositiveInt;
  readonly patch: Numbers.Zero | PositiveInt;
};

const greaterThanOrEqual = (a: AppVersion, b: AppVersion): boolean => {
  if (a.major < b.major) {
    return false;
  } else if (a.major > b.major) {
    return true;
  } else if (a.minor < b.minor) {
    return false;
  } else if (a.minor > b.minor) {
    return true;
  }
  return a.patch >= b.patch;
};

const VersionNum = t.union([Numbers.Zero, PositiveInt.JSON]);

const VersionNumFromString: t.Type<Numbers.Zero | PositiveInt, string> = tt.NumberFromString.pipe(VersionNum);

const JSON: t.Type<AppVersion, JSONable> = t.type(
  {
    major: VersionNum,
    minor: VersionNum,
    patch: VersionNum,
  },
  'AppVersion',
);

const unsafeParse = (s: unknown): AppVersion => {
  return unsafeDecode(FromString, s);
};

const AppVersionFromStringValues = t.type(
  {
    major: VersionNumFromString,
    minor: optionull(VersionNumFromString),
    patch: optionull(VersionNumFromString),
  },
  'AppVersion',
);

const FromString: t.Type<AppVersion, string> = t.string.pipe(
  new t.Type<AppVersion, string, string>(
    'AppVersionFromString',
    JSON.is,
    (s): t.Validation<AppVersion> => {
      const dashIndex = s.indexOf('-');
      const cleanS = dashIndex !== -1 ? s.substring(0, dashIndex) : s;

      const [major, minor, patch]: (string | undefined)[] = cleanS.split('.');

      return pipe(
        {
          major,
          minor,
          patch,
        },
        AppVersionFromStringValues.decode,
        E.map((v): AppVersion => {
          return {
            major: v.major,
            minor: v.minor ?? Numbers._0,
            patch: v.patch ?? Numbers._0,
          };
        }),
      );
    },
    ({ major, minor, patch }) => `${major}.${minor}.${patch}`,
  ),
  'AppVersionFromString',
);

const parse = FromString.decode;

export const AppVersion = {
  greaterThanOrEqual,
  JSON,
  FromString,
  parse,
  unsafeParse,
};
