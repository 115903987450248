import * as React from 'react';
import { View, StyleSheet, StyleProp, ViewStyle } from 'react-native';

import * as colors from '../../styles/colors';

type PaddingConfig = 'inputs' | 'button' | 'none';

const Paddings: {
  [k in PaddingConfig]: { paddingTop?: number; paddingRight?: number; paddingBottom?: number; paddingLeft?: number };
} = {
  inputs: {
    paddingTop: 24,
    paddingRight: 16,
    paddingBottom: 24,
    paddingLeft: 16,
  },
  button: {
    paddingTop: 16,
    paddingRight: 24,
    paddingBottom: 16,
    paddingLeft: 16,
  },
  none: {},
};

type Props = React.PropsWithChildren<{
  readonly style?: StyleProp<ViewStyle>;
  readonly rounded?: boolean;
  readonly dropShaddow?: boolean;
  /**
   * paddings (top right bottom left):
   * "inputs" - 24 16 24 16
   * "button" - 16 24 16 24
   */
  readonly paddings?: PaddingConfig;
}>;

const FormCard: React.FC<Props> = ({ children, style, rounded, dropShaddow, paddings = 'button' }: Props) => {
  return (
    <View
      style={StyleSheet.compose(
        {
          backgroundColor: colors.WHITE,
          borderRadius: rounded ? 5 : 0,
          ...Paddings[paddings],
        },
        StyleSheet.compose(
          dropShaddow ? { shadowColor: '#8898AA', shadowOpacity: 0.15, shadowRadius: 16, elevation: 16 } : {},
          style,
        ),
      )}
    >
      {children}
    </View>
  );
};

export default FormCard;
