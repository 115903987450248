import { MeroUnits, Money } from '@mero/shared-sdk';

type Hidden = {
  readonly type: 'Hidden';
};

type Fixed<Num, Unit extends MeroUnits.Any> = {
  readonly type: 'Fixed';
  readonly fixed: Money<Num, Unit>;
};

type Range<Num, Unit extends MeroUnits.Any> = {
  readonly type: 'Range';
  readonly range: {
    readonly from?: Money<Num, Unit>;
    readonly to?: Money<Num, Unit>;
  };
};

export type CheckoutTransactionServicePrice<Num, Unit extends MeroUnits.Any> =
  | Hidden
  | Fixed<Num, Unit>
  | Range<Num, Unit>;

const HIDDEN: Hidden = {
  type: 'Hidden',
};

const hidden = (): Hidden => {
  return HIDDEN;
};

const fixed = <Num, Unit extends MeroUnits.Any>(amount: Money<Num, Unit>): Fixed<Num, Unit> => {
  return {
    type: 'Fixed',
    fixed: amount,
  };
};

const range = <Num, Unit extends MeroUnits.Any>(
  from: Money<Num, Unit> | undefined,
  to: Money<Num, Unit> | undefined,
): Range<Num, Unit> => {
  return {
    type: 'Range',
    range: {
      from,
      to,
    },
  };
};

export const CheckoutTransactionServicePrice = {
  hidden,
  fixed,
  range,
};
