import { User } from './user';
import * as t from 'io-ts';

export const GrantResponse = t.intersection(
  [
    t.type({
      tokenType: t.string,
      expiresIn: t.number,
      accessToken: t.string,
      state: t.string,
    }),
    t.partial({
      refreshToken: t.union([t.string, t.null]),
    }),
  ],
  'GrantResponse',
);

export type GrantResponse = t.TypeOf<typeof GrantResponse>;

export const Authentication = t.intersection(
  [
    GrantResponse,
    t.partial({
      user: t.union([User, t.null]),
    }),
  ],
  'Authentication',
);

export type Authentication = t.TypeOf<typeof Authentication>;

export type GrantStorage = {
  /**
   * Get grant from storage
   */
  getGrant: () => Promise<GrantResponse | undefined>;

  /**
   * Save grant to storage
   */
  saveGrant: (grant: GrantResponse) => Promise<void>;

  /**
   * Delete saved grant
   */
  deleteGrant: () => Promise<void>;

  /**
   * Mutate grant
   */
  mutateGrant: (
    fn: (grant: GrantResponse | undefined) => Promise<GrantResponse | undefined>,
  ) => Promise<GrantResponse | undefined>;
};
