import * as E from 'fp-ts/lib/Either';
import { flow, identity } from 'fp-ts/lib/function';
import * as t from 'io-ts';

/**
 * Boolean type with nulls and undefined
 * Decodes from true | false | null | undefined to true | false
 * Encodes to true | false
 */
export const Nullean = new t.Type<boolean>(
  'Nullean',
  t.boolean.is,
  flow(
    t.union([t.boolean, t.null, t.undefined]).decode,
    E.map((p) => p === true),
  ),
  identity,
);
export type Nullean = t.TypeOf<typeof Nullean>;
