import { JSONable, MeroUnits, Money, ScaledNumber, optionull } from '..';
import * as t from 'io-ts';
import * as tt from 'io-ts-types';

/**
 * Stats for the client of a page
 */
export type PageClientStats<Unit extends MeroUnits.Any> = {
  readonly completedBookings: number;
  readonly cancelledBookings: number;
  readonly noShowBookings: number;
  readonly lastCompletedBookingTs: Date | undefined;
  readonly totalCheckoutCharge: Money<ScaledNumber, Unit>;
  readonly debt: Money<ScaledNumber, Unit>;
  readonly activeMembershipsCount: number;
};

const json = <Unit extends MeroUnits.Any>(unit: Unit): t.Type<PageClientStats<Unit>, JSONable> => {
  const unitC = t.literal(unit);

  return t.type(
    {
      completedBookings: t.number,
      cancelledBookings: t.number,
      noShowBookings: t.number,
      lastCompletedBookingTs: optionull(tt.DateFromISOString),
      totalCheckoutCharge: Money.json(ScaledNumber.JSON, unitC),
      debt: Money.json(ScaledNumber.JSON, unitC),
      activeMembershipsCount: t.number,
    },
    `PageClientStats<${unit}>`,
  );
};

export const ClientPageStats = {
  json,
};
