import { Numbers } from '../../numbers';
import { RecurrenceTimeUnit } from '../recurrenceTimeUnit';
import * as t from 'io-ts';

export type Monthly = {
  readonly value: Numbers.One;
  readonly unit: RecurrenceTimeUnit.Month;
};

const JSON = t.type(
  {
    value: Numbers.One,
    unit: RecurrenceTimeUnit.Month.JSON,
  },
  'Monthly',
);

export const Monthly = {
  JSON,
  is: JSON.is,
};
