import { ServiceId } from '../services/service-id';
import { BookingServiceCustomPrice } from './bookingServiceCustomPrice';
import { JSONable, PositiveInt, Option } from '@mero/shared-sdk';
import * as t from 'io-ts';

export type BookedServicePayload = {
  readonly _id: ServiceId;
  readonly quantity: PositiveInt;
  readonly customPrice: Option<BookingServiceCustomPrice>;
};

const JSON: t.Type<BookedServicePayload, JSONable> = t.type(
  {
    _id: ServiceId,
    quantity: PositiveInt.JSON,
    customPrice: Option.json(BookingServiceCustomPrice.JSON),
  },
  'BookedServicesPayload',
);

export const BookedServicePayload = {
  JSON: JSON,
};
