import { JSONable } from '@mero/shared-sdk';
import * as t from 'io-ts';

export type NonRecurrent = {
  readonly recurrent: false;
};

/**
 * Marker for Non-Recurrent calendar entry
 */
const JSON: t.Type<NonRecurrent, JSONable> = t.type(
  {
    recurrent: t.literal(false),
  },
  'NonRecurrent',
);

export const NonRecurrent = {
  JSON,
};
