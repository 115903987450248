import { JSONable } from '@mero/shared-sdk';
import * as t from 'io-ts';

export type DistrictName = string;

const JSON: t.Type<DistrictName, JSONable> = t.string;

export const DistrictName = {
  JSON,
};
