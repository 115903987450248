import { DateTime } from 'luxon';

export const formatTimeDiff = (ts: Date, to: Date): string | undefined => {
  const diffMs = to.getTime() - ts.getTime();

  if (diffMs < 0) {
    // future
    return DateTime.fromJSDate(ts).toFormat('d LLL yyyy', { locale: 'ro' });
  } else if (diffMs < 120000) {
    // 2 minutes
    return 'Acum';
  } else if (diffMs < 3600000) {
    // up to 1 hour
    return `Acum ${Math.round(diffMs / 60000)} minute`;
  } else if (diffMs < 21600000) {
    // up to 6 hours
    const hours = Math.floor(diffMs / 3600000);
    const minutes = Math.round((diffMs - hours * 3600000) / 60000);
    return `Acum ${hours} ${diffMs < 7200000 ? 'oră' : 'ore'} ${minutes} minute`;
  } else if (diffMs < 172800000) {
    return `Acum ${Math.floor(diffMs / 3600000)} ore`;
  } else if (diffMs < 1209600000) {
    // up to 14 days
    return `Acum ${Math.round(diffMs / 86400000)} zile`;
  } else {
    return DateTime.fromJSDate(ts).toFormat('d LLL yyyy', { locale: 'ro' });
  }
};

/**
 * Format a duration in minutes to a friendly readable string.
 */
export const formatDurationInMinutes = (minutes: number, short = false): string => {
  const mUnit = short ? 'm' : ' min';
  const hUnit = short ? 'h' : ' h';
  if (minutes < 60) {
    return `${minutes}${mUnit}`;
  } else {
    const h = Math.floor(minutes / 60);
    const m = minutes - h * 60;

    return `${h}${hUnit}${m > 0 ? ` ${m}${mUnit}` : ''}`;
  }
};

/**
 * Format a range duration to a friendly readable string.
 */
export function formatRangeDuration(from: number, to: number): string {
  if (from === to) {
    return `${formatDurationInMinutes(from)}`;
  }

  // show a shorter/merged version when both intervals are < 1h
  if (from < 60 && to < 60) {
    return `${from} - ${formatDurationInMinutes(to)}.`;
  }

  return `${formatDurationInMinutes(from, true)} - ${formatDurationInMinutes(to, true)}`;
}
