import { StyleSheet } from 'react-native';

import { WHITE } from '../../styles/colors';

export const styles = StyleSheet.create({
  container: {
    width: '100%',
    height: 54,
  },
  fakeInputsContainer: {
    flex: 1,
    position: 'absolute',
    flexDirection: 'row',
    flexWrap: 'wrap',
  },
  fakeInput: {
    width: 45,
    height: 50,
    backgroundColor: WHITE,
    flex: 1,
    padding: 5,
    margin: 2,
    justifyContent: 'center',
    alignItems: 'center',
    borderColor: 'grey',
    borderRadius: 2,
    borderWidth: 1,
  },
  realInput: {
    // Hide real input behind first text box so user is not able to touch it direclty
    margin: 8,
    width: 1, // It behaves unexpected with zero width/height
    height: 1,
    backgroundColor: 'transparent',
    color: 'transparent',
    position: 'absolute',
  },
});
