import * as t from 'io-ts';

/**
 * Stock is used internally
 */
export const UsedInternally = t.literal('UsedInternally');
export type UsedInternally = t.TypeOf<typeof UsedInternally>;

/**
 * Stock is expired
 */
export const Expired = t.literal('Expired');
export type Expired = t.TypeOf<typeof Expired>;

/**
 * Other reason
 */
export const Other = t.type({ type: t.literal('Other'), value: t.string });
export type Other = t.TypeOf<typeof Other>;

/**
 * Stock is transfered from this inventory to another one
 */
export const TransferredFrom = t.literal('TransferredFrom');
export type TransferredFrom = t.TypeOf<typeof TransferredFrom>;

/**
 * Stock is returned from the inventory back to the supplier
 */
export const ReturnedToSupplier = t.literal('ReturnedToSupplier');
export type ReturnedToSupplier = t.TypeOf<typeof ReturnedToSupplier>;

/**
 * Stock is adjusted to reflect reality
 */
export const Adjusted = t.literal('Adjusted');
export type Adjusted = t.TypeOf<typeof Adjusted>;

export type All = Adjusted | UsedInternally | Expired | Other | TransferredFrom | ReturnedToSupplier;

export const All = {
  JSON: t.union([Adjusted, UsedInternally, Expired, Other, TransferredFrom, ReturnedToSupplier], 'All'),
  list: () => ['Adjusted', 'ReturnedToSupplier', 'TransferredFrom', 'UsedInternally', 'Expired', 'Other'],
};
