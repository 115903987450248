import { AppointmentNumericStatus } from './appointmentNumericStatus';
import { literalIso } from '@mero/shared-sdk';
import * as t from 'io-ts';

export const AppointmentStatusPending = literalIso('pending', AppointmentNumericStatus.Pending.VALUE);
export type AppointmentStatusPending = t.TypeOf<typeof AppointmentStatusPending>;

export const AppointmentStatusAccepted = literalIso('accepted', AppointmentNumericStatus.Accepted.VALUE);
export type AppointmentStatusAccepted = t.TypeOf<typeof AppointmentStatusAccepted>;

export const AppointmentStatusRejected = literalIso('rejected', AppointmentNumericStatus.Rejected.VALUE);
export type AppointmentStatusRejected = t.TypeOf<typeof AppointmentStatusRejected>;

export const AppointmentStatusNoShow = literalIso('noShow', AppointmentNumericStatus.NoShow.VALUE);
export type AppointmentStatusNoShow = t.TypeOf<typeof AppointmentStatusNoShow>;

export const AppointmentStatusCancelled = literalIso('cancelled', AppointmentNumericStatus.Cancelled.VALUE);
export type AppointmentStatusCancelled = t.TypeOf<typeof AppointmentStatusCancelled>;

/**
 * @deprecated use {@link AppointmentNumericStatus}
 */
export const AppointmentStatus = t.union(
  [
    AppointmentStatusPending,
    AppointmentStatusAccepted,
    AppointmentStatusRejected,
    AppointmentStatusNoShow,
    AppointmentStatusCancelled,
  ],
  'AppointmentStatus',
);

/**
 * @deprecated use {@link AppointmentNumericStatus}
 */
export type AppointmentStatus = t.TypeOf<typeof AppointmentStatus>;
