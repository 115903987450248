import * as React from 'react';
import Svg, { Path, G, Circle } from 'react-native-svg';

type Props = {
  size?: string | number;
  color?: string;
  backgroundColor?: string;
  disabled?: boolean;
  onPress?: () => void;
};

const NotificationStarIcon = ({
  size = 32,
  color = '#fafafa',
  backgroundColor = '#fbac40',
  disabled = false,
  onPress,
}: Props) => (
  <Svg width={size} height={size} viewBox="0 0 32 32" disabled={disabled} onPress={onPress}>
    <Circle cx={16} cy={16} r={16} fill={backgroundColor} />
    <G data-name="grade-24px (1)">
      <Path
        data-name="Path 8316"
        d="m16 20.555 5.056 2.945-1.338-5.55 4.467-3.734-5.883-.482L16 8.5l-2.3 5.234-5.882.482 4.467 3.734-1.342 5.55Z"
        fill="none"
        stroke={color}
        strokeLinejoin="round"
        strokeWidth={1.2}
      />
    </G>
  </Svg>
);

export default React.memo(NotificationStarIcon);
