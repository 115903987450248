import * as React from 'react';
import Svg, { Path } from 'react-native-svg';

import { BLACK } from '../../../styles/colors';

type Props = {
  size?: string | number;
  color?: string;
  disabled?: boolean;
  onPress?: () => void;
};

const CircleChecked: React.FC<Props> = ({ size = 24, color = BLACK, disabled = false, onPress }: Props) => (
  <Svg width={size} height={size} viewBox={`0 0 ${size} ${size}`} disabled={disabled} onPress={onPress}>
    <Path id="M0,0H24V24H0Z" fill="none" />
    <Path
      d="M12,2A10,10,0,1,0,22,12,10,10,0,0,0,12,2Zm0,18a8,8,0,1,1,8-8A8.011,8.011,0,0,1,12,20ZM16.59,7.58,10,14.17,7.41,11.59,6,13l4,4,8-8Z"
      fill={color}
    />
  </Svg>
);

export default React.memo(CircleChecked);
