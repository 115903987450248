import * as React from 'react';
import Svg, { Rect, Path, G } from 'react-native-svg';

import * as colors from '../../../styles/colors';

type Props = {
  size?: number | string;
  color?: string;
  disabled?: boolean;
  onPress?: () => void;
};

const ArrowDown = ({ size = 32, color = colors.DARK_BLUE, disabled, onPress }: Props) => (
  <Svg
    width={size}
    height={size}
    viewBox="0 0 32 32"
    disabled={disabled}
    onPress={onPress}
    style={{ transform: [{ rotate: '90deg' }] }}
  >
    <Path d="M1.385 11.743L0 10.358l4.569-4.489L0 1.384 1.385 0l5.954 5.869z" fill={color} />
  </Svg>
);

export default React.memo(ArrowDown);
