import * as React from 'react';
import Svg, { G, Circle, Path } from 'react-native-svg';

type Props = {
  size?: number | string;
  disabled?: boolean;
  onPress?: () => void;
};

const Cash = ({ size = 32, disabled, onPress }: Props) => (
  <Svg width={size} height={size} viewBox={`0 0 ${size} ${size}`} disabled={disabled} onPress={onPress}>
    <G data-name="Group 7659" transform="translate(-15244 5851)">
      <Circle cx="16" cy="16" r="16" fill="#e9ecef" data-name="Ellipse 686" transform="translate(15244 -5851)" />
      <G data-name="Orion_money (1)">
        <Path fill="none" d="M15249-5845.656h22v21.313h-22z" data-name="Rectangle 2760" />
        <Path
          fill="rgba(255,255,255,0)"
          stroke="#52577f"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeMiterlimit="10"
          d="M15251.722-5830.443v-9s1.081-1.133 4.323-1.133 4.107 1.621 7.566 1.621a17.132 17.132 0 0 0 4.323-.676v9.187a8.255 8.255 0 0 1-3.783.676c-2.594 0-4.648-1.621-7.566-1.621a12.106 12.106 0 0 0-4.863.946Z"
        />
        <Path
          fill="rgba(255,255,255,0)"
          stroke="#52577f"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeMiterlimit="10"
          d="M15262.499-5835.282a2.2 2.2 0 0 1-2.185 2.7 3.358 3.358 0 0 1-3.135-2.7 2.2 2.2 0 0 1 2.189-2.7 3.306 3.306 0 0 1 3.131 2.7Z"
        />
        <Path
          fill="rgba(255,255,255,0)"
          stroke="#52577f"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeMiterlimit="10"
          d="M15254.397-5840.468a2.7 2.7 0 0 1-2.675 3.135m0 3.783a2.7 2.7 0 0 1 2.675 2.351m13.538-5.593a2.7 2.7 0 0 1-2.675-2.27m.027 9.241a2.654 2.654 0 0 1 2.648-3.189"
          data-name="layer1"
        />
      </G>
    </G>
  </Svg>
);

export default React.memo(Cash);
