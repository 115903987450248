import * as React from 'react';
import Svg, { Path, G, Circle } from 'react-native-svg';

const ConfirmLarge = () => (
  <Svg width="88" height="88" viewBox="0 0 88 88">
    <G id="Orion_check-validate-circle" transform="translate(-6.147 -6.147)">
      <Circle id="layer1" cx="43" cy="43" r="43" transform="translate(7.147 7.147)" fill="#5ade9c" />
      <Circle
        id="stroke"
        cx="43"
        cy="43"
        r="43"
        transform="translate(7.147 93.147) rotate(-90)"
        fill="none"
        stroke="#2e4369"
        strokeLinecap="round"
        stroke-linejoin="round"
        strokeWidth="2"
      />
      <Path
        id="stroke-2"
        data-name="stroke"
        d="M21,35.057,33.886,47.945,56.835,25"
        transform="translate(13.379 15.112)"
        fill="none"
        stroke="#2e4369"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
      />
    </G>
  </Svg>
);

export default React.memo(ConfirmLarge);
