import { JSONable, Option } from '@mero/shared-sdk';
import * as t from 'io-ts';

export type OnlinePaymentsPayoutDestination =
  | {
      readonly type: 'BankAccount';
      readonly last4: Option<string>;
    }
  | {
      readonly type: 'Card';
      readonly last4: Option<string>;
    };

const JSON: t.Type<OnlinePaymentsPayoutDestination, JSONable> = t.union(
  [
    t.type(
      {
        type: t.literal('BankAccount'),
        last4: Option.json(t.string),
      },
      'BankAccount',
    ),
    t.type(
      {
        type: t.literal('Card'),
        last4: Option.json(t.string),
      },
      'Card',
    ),
  ],
  'Destination',
);

export const OnlinePaymentsPayoutDestination = {
  JSON,
};
