export type CouponType = string;

const DISCOUNT = 'discount';
const GIFTCARD = 'giftcard';
const VOUCHER = 'voucher';

export const CouponType = {
  DISCOUNT,
  GIFTCARD,
  VOUCHER,
};
