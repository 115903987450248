import * as React from 'react';
import Svg, { Path, G, Rect } from 'react-native-svg';

type Props = {
  size?: string | number;
  disabled?: boolean;
  onPress?: () => void;
};

const Google: React.FC<Props> = ({ size = 32, disabled = false, onPress }) => (
  <Svg width={size} height={size} viewBox="0 0 32 32" disabled={disabled} onPress={onPress}>
    <G transform="translate(-32.5 -490)">
      <Rect width="32" height="32" transform="translate(32.5 490)" fill="none" />
      <G transform="translate(38.31 495.572)">
        <Path
          d="M282.134,225.223a12.285,12.285,0,0,0-.18-2.123H272.1v4.023h5.643a4.835,4.835,0,0,1-2.088,3.174v2.61h3.366A10.2,10.2,0,0,0,282.134,225.223Z"
          transform="translate(-261.704 -214.554)"
          fill="#4285f4"
        />
        <Path
          d="M38.235,332.745a10,10,0,0,0,6.925-2.522l-3.366-2.61a6.336,6.336,0,0,1-9.42-3.313H28.9v2.691A10.448,10.448,0,0,0,38.235,332.745Z"
          transform="translate(-27.839 -311.87)"
          fill="#34a853"
        />
        <Path
          d="M4.531,156.69a6.257,6.257,0,0,1,0-4V150H1.061a10.455,10.455,0,0,0,0,9.381Z"
          transform="translate(0 -144.26)"
          fill="#fbbc04"
        />
        <Path
          d="M38.235,4.116a5.677,5.677,0,0,1,4.007,1.566L45.225,2.7a10.04,10.04,0,0,0-6.99-2.718A10.444,10.444,0,0,0,28.9,5.74l3.47,2.691a6.248,6.248,0,0,1,5.865-4.314Z"
          transform="translate(-27.839 0)"
          fill="#ea4335"
        />
      </G>
    </G>
  </Svg>
);

export default React.memo(Google);
