import { UserOrderDetails } from './userOrderDetails';
import { UserOrderPaymentId } from './userOrderPaymentId';
import { UserOrderPaymentStatusPreview } from './userOrderPaymentStatusPreview';
import { JSONable, MeroUnits, Money, Option, ScaledNumber } from '@mero/shared-sdk';
import * as t from 'io-ts';
import * as tt from 'io-ts-types';

export type UserOrderPaymentDetails = {
  readonly _id: UserOrderPaymentId;
  readonly status: UserOrderPaymentStatusPreview;
  readonly byUser: {
    readonly firstname: Option<string>;
    readonly lastname: Option<string>;
    readonly phone: Option<string>;
  };
  /**
   * Payment amount
   */
  readonly amount: Money<ScaledNumber, MeroUnits.RON>;
  /**
   * Payment refunds total
   */
  readonly refunds: Option<Money<ScaledNumber, MeroUnits.RON>>;
  /**
   * Payment total (amount - refunds)
   */
  readonly total: Money<ScaledNumber, MeroUnits.RON>;
  readonly order: UserOrderDetails;
  readonly paidAt: Date;
};

const JSON: t.Type<UserOrderPaymentDetails, JSONable> = t.type(
  {
    _id: UserOrderPaymentId.JSON,
    status: UserOrderPaymentStatusPreview.JSON,
    byUser: t.type(
      {
        firstname: Option.json(t.string),
        lastname: Option.json(t.string),
        phone: Option.json(t.string),
      },
      'ByUser',
    ),
    amount: Money.json(ScaledNumber.JSON, MeroUnits.RON.JSON),
    refunds: Option.json(Money.json(ScaledNumber.JSON, MeroUnits.RON.JSON)),
    total: Money.json(ScaledNumber.JSON, MeroUnits.RON.JSON),
    order: UserOrderDetails.JSON,
    paidAt: tt.DateFromISOString,
  },
  'UserOrderPaymentDetails',
);

export const UserOrderPaymentDetails = {
  JSON,
};
