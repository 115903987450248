import * as t from 'io-ts';

export const UserDeviceTypeIOS = t.literal('IOS');
export type UserDeviceTypeIOS = t.TypeOf<typeof UserDeviceTypeIOS>;

export const UserDeviceTypeAndroid = t.literal('Android');
export type UserDeviceTypeAndroid = t.TypeOf<typeof UserDeviceTypeAndroid>;

export const UserDeviceTypeWeb = t.literal('Web');
export type UserDeviceTypeWeb = t.TypeOf<typeof UserDeviceTypeWeb>;

export const UserDeviceType = t.union([UserDeviceTypeIOS, UserDeviceTypeAndroid, UserDeviceTypeWeb], 'UserDeviceType');
export type UserDeviceType = t.TypeOf<typeof UserDeviceType>;
