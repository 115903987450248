import { JSONable } from '@mero/shared-sdk';
import * as t from 'io-ts';

export type Editable = {
  readonly type: 'Editable';
};

const JSON: t.Type<Editable, JSONable> = t.type(
  {
    type: t.literal('Editable'),
  },
  'Editable',
);

export const Editable = {
  JSON,
};
