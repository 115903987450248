import * as React from 'react';
import Svg, { G, Path, Rect } from 'react-native-svg';

type Props = {
  size?: string | number;
  color?: string;
  disabled?: boolean;
  onPress?: () => void;
};

const GoRight = ({ size = 32, color = '#ffffff', disabled, onPress }: Props) => (
  <Svg width={size} height={size} viewBox={`0 0 ${size} ${size}`} disabled={disabled} onPress={onPress}>
    <G id="icons_back-light" data-name="icons/back-light" transform="translate(32 32) rotate(180)">
      <Rect id="bg" width="32" height="32" fill="none" />
      <G id="Orion_arrow-left" transform="translate(-8 -10)">
        <Path
          id="layer2"
          d="M14,32H34"
          transform="translate(0 -5.499)"
          fill="none"
          stroke={color}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeMiterlimit="10"
          strokeWidth="2"
        />
        <Path
          id="layer1"
          d="M22.332,18,14,26.5,22.332,35"
          transform="translate(0 0)"
          fill="none"
          stroke={color}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeMiterlimit="10"
          strokeWidth="2"
        />
      </G>
    </G>
  </Svg>
);

export default React.memo(GoRight);
