import { AppointmentId } from '../calendar/appointment-id';
import { AppointmentStatus } from '../calendar/appointment-status';
import { CalendarId } from '../calendar/calendar-id';
import { OptionalFirstname, OptionalLastname } from '../common';
import { PageId } from '../pages/page-id';
import { ServiceId } from '../services/service-id';
import { WorkerId } from '../workers';
import { ClientAppointmentId } from './client-appointment-id';
import { ClientId } from './client-id';
import { optionull } from '@mero/shared-sdk';
import { ProfileImage } from '@mero/shared-sdk/dist/assets';
import * as t from 'io-ts';
import { DateFromISOString } from 'io-ts-types';

const ClientAppointmentService = t.type(
  {
    _id: ServiceId,
    name: t.string,
  },
  'ClientAppointmentService',
);

export const ClientAppointment = t.type(
  {
    _id: ClientAppointmentId,
    appointmentId: AppointmentId,
    occurrenceIndex: optionull(t.number),
    calendarId: CalendarId,
    clientId: ClientId,
    status: AppointmentStatus,
    start: DateFromISOString,
    end: DateFromISOString,
    page: t.type({
      _id: PageId,
      name: t.string,
    }),
    worker: t.type({
      _id: WorkerId.JSON,
      firstname: OptionalFirstname,
      lastname: OptionalLastname,
      profilePhoto: optionull(ProfileImage),
    }),
    bookedServices: t.array(ClientAppointmentService),
  },
  'ClientAppointment',
);

export interface ClientAppointment extends t.TypeOf<typeof ClientAppointment> {}
