import { Unit } from './unit';
import { PositiveInt, Numbers } from '@mero/shared-sdk';

export type Spec = {
  readonly name: string;
  readonly unit: Unit;
  readonly exponent: PositiveInt | Numbers.Zero;
  readonly order: number;
  readonly implicitValue: PositiveInt | undefined;
};

export const Spec = {
  // pass
};
