import { Editable } from './editable';
import { NonEditable } from './nonEditable';

export * from './any';
export * from './editable';
export * from './nonEditable';

export const NON_EDITABLE: NonEditable = {
  type: 'NonEditable',
};

export const EDITABLE: Editable = {
  type: 'Editable',
};
