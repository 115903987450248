import { AreaName } from '../business/areaName';
import { CityId } from './city';
import * as t from 'io-ts';

export const Area = t.type(
  {
    name: AreaName,
    cityId: CityId,
  },
  'Area',
);

export type Area = t.TypeOf<typeof Area>;
