import { Numbers } from '../../numbers';
import { RecurrenceTimeUnit } from '../recurrenceTimeUnit';
import * as t from 'io-ts';

export type Daily = {
  readonly value: Numbers.One;
  readonly unit: RecurrenceTimeUnit.Day;
};

const JSON = t.type(
  {
    value: Numbers.One,
    unit: RecurrenceTimeUnit.Day.JSON,
  },
  'Daily',
);

export const Daily = {
  JSON,
  is: JSON.is,
};
