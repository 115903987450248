import { AppointmentId } from '../../calendar/appointment-id';
import { AppointmentStatus } from '../../calendar/appointment-status';
import { CalendarId } from '../../calendar/calendar-id';
import { ClientId } from '../../clients/client-id';
import { enumType } from '../../common';
import { MembershipPurchaseId } from '../../memberships/membershipPurchaseId';
import { MembershipTemplateId } from '../../memberships/membershipTemplateId';
import { PageId } from '../../pages/page-id';
import { InventoryId } from '../../pro/inventories/inventoryId';
import { UserOrderPaymentId } from '../../pro/onlinePayments/userOrderPaymentId';
import { PageBillingDetails } from '../../pro/pageBillingDetails/pageBillingDetails';
import { PageBillingDetailsId } from '../../pro/pageBillingDetails/pageBillingDetailsId';
import { ProductAvailability } from '../../pro/products/product/productAvailability';
import { ProductId } from '../../pro/products/product/productId';
import { ProductMeasure } from '../../pro/products/product/productMeasure';
import { ProductBarcodeC, ProductCategoryIdC, ProductIdC } from '../../pro/products/productsHttpClient/productsJson';
import { ServiceGroupId } from '../../services';
import { ServiceId } from '../../services/service-id';
import { WorkerId } from '../../workers/workerId';
import { CheckoutCashRegisterDriverDetails, CheckoutCashRegisterDriverId } from '../cashRegisters';
import { CheckoutCashRegisterDriverConfig } from '../cashRegisters/checkoutCashRegisterDriverConfig';
import { CheckoutCashRegisterId } from '../cashRegisters/checkoutCashRegisterId';
import { CheckoutSavedCashRegister } from '../cashRegisters/checkoutSavedCashRegister';
import {
  CheckoutCashRegistry,
  CheckoutCashRegistryId,
  CheckoutCashRegistryTransaction,
  CheckoutCashRegistryTransactionId,
  CheckoutCashRegistryTransactionList,
  CheckoutCashRegistryTransactionOrigin,
  CheckoutCashRegistryTransactionType,
  IOperationAuditPreview,
} from '../cashRegistry';
import {
  CheckoutDraftRequest,
  CheckoutRequest,
  CreateCheckoutDraftRequest,
  CreateCheckoutTransactionItem,
  UpdateCheckoutDraftRequest,
} from '../checkoutApi';
import { CreateCheckoutTransactionPayment } from '../checkoutApi/createCheckoutTransactionPayment';
import { CheckoutCashRegisterPrintTicket } from '../checkoutCashRegisterPrintTicket';
import { CheckoutClientPreview } from '../checkoutClientPreview';
import { CheckoutFinishedTransactionsStats } from '../checkoutFinishedTrasactionsStats';
import { CheckoutItemTotal } from '../checkoutItemTotal';
import { CheckoutItemVatStatus } from '../checkoutItemVatStatus';
import { CheckoutMembershipItemTotal } from '../checkoutMembershipItemTotal';
import { CheckoutPageDefaults } from '../checkoutPageDefaults';
import { CheckoutPagePreview } from '../checkoutPagePreview';
import { CheckoutPageSettings } from '../checkoutPageSettings';
import { CheckoutReceiptId } from '../checkoutReceiptId';
import { CheckoutTotals } from '../checkoutTotals';
import { CheckoutTransactionCode } from '../checkoutTransactionCode';
import { CheckoutTransactionCompany } from '../checkoutTransactionCompany';
import { CheckoutTransactionCompanyDetails } from '../checkoutTransactionCompany/checkoutTransactionCompanyDetails';
import { CheckoutTransactionCompanyRef } from '../checkoutTransactionCompanyRef';
import { CheckoutTransactionDetails } from '../checkoutTransactionDetails';
import { CheckoutTransactionId } from '../checkoutTransactionId';
import { CheckoutTransactionItem } from '../checkoutTransactionItem';
import { CheckoutTransactionMembershipPreview } from '../checkoutTransactionMembershipPreview';
import { CheckoutTransactionMembershipTemplateDetails } from '../checkoutTransactionMembershipTemplateDetails';
import { CheckoutTransactionPayment } from '../checkoutTransactionPayment';
import { MembershipItemEffectivePrice } from '../checkoutTransactionPayment/membership';
import { CheckoutTransactionPreview } from '../checkoutTransactionPreview';
import { CheckoutTransactionProductDetails } from '../checkoutTransactionProductDetails';
import { CheckoutTransactionProductPrice } from '../checkoutTransactionProductPrice';
import { CheckoutTransactionServiceDetails } from '../checkoutTransactionServiceDetails';
import { CheckoutTransactionServicePreview } from '../checkoutTransactionServicePreview';
import { CheckoutTransactionServicePrice } from '../checkoutTransactionServicePrice';
import { CheckoutUserPreview } from '../checkoutUserPreview';
import { CheckoutWorkerPreview } from '../checkoutWorkerPreview';
import {
  CheckoutCompanyDetails,
  CheckoutCompanyId,
  CompanyEmitReceiptStatus,
  CompanyVatStatus,
  SavedCheckoutCompany,
  SavedCheckoutCompanyWithRegisters,
} from '../companies';
import { CheckoutCompanyAutocomplete } from '../companies/checkoutCompanyAutocomplete';
import { DefinedTrimedString, Email, Option, PositiveScaledNumber, StrictPhoneNumber } from '@mero/shared-sdk';
import {
  AppliedDiscount,
  DiscountPercentScaledNumber,
  HasId,
  HasOptionalFirstLastName,
  JSONable,
  MeroUnits,
  Money,
  ObjectId,
  PortionPercentScaledNumber,
  PositiveInt,
  ProfileImage,
  ScaledNumber,
  UserId,
  Numbers,
  optionull,
} from '@mero/shared-sdk';
import * as t from 'io-ts';
import * as tt from 'io-ts-types';

const MeroUnitsC: { [Unit in MeroUnits.Any]: t.Type<Unit> } = {
  [MeroUnits.RON.code]: MeroUnits.RON.JSON,
  [MeroUnits.EUR.code]: MeroUnits.EUR.JSON,
};

const unitC = <Unit extends MeroUnits.Any>(unit: Unit): t.Type<Unit> => MeroUnitsC[unit];

const MeroMoneyC: { [Unit in MeroUnits.Any]: t.Type<Money<ScaledNumber, Unit>, JSONable> } = {
  [MeroUnits.RON.code]: Money.json(ScaledNumber.JSON, unitC(MeroUnits.RON.code)),
  [MeroUnits.EUR.code]: Money.json(ScaledNumber.JSON, unitC(MeroUnits.EUR.code)),
};

const moneyC = <Unit extends MeroUnits.Any>(unit: Unit): t.Type<Money<ScaledNumber, Unit>, JSONable> => {
  return MeroMoneyC[unit];
};

const CompanyVatStatusC: t.Type<CompanyVatStatus.Any<ScaledNumber>, JSONable> = t.union(
  [
    t.type(
      {
        type: t.literal('NoVat'),
      },
      'NoVat',
    ),
    t.type(
      {
        type: t.literal('VatPayer'),
        defaultRate: PortionPercentScaledNumber.JSON,
        vatId: t.string,
      },
      'VatPayer',
    ),
  ],
  'CompanyVatStatus',
);

export const CheckoutPageDefaultsC: t.Type<CheckoutPageDefaults, JSONable> = t.strict(
  {
    vatRate: PortionPercentScaledNumber.JSON,
  },
  'CheckoutPageDefaults',
);

export const CheckoutPageSettingsC: t.Type<CheckoutPageSettings, JSONable> = t.strict(
  {
    checkoutEnabled: t.boolean,
  },
  'CheckoutPageSettings',
);

/**
 * @deprecated
 */
export const CheckoutCompanyDetailsC: t.Type<CheckoutCompanyDetails, JSONable> = t.intersection(
  [
    t.type(
      {
        name: tt.NonEmptyString,
        regNo: tt.NonEmptyString,
        fiscalCode: tt.NonEmptyString,
        countryCode: tt.NonEmptyString,
        city: tt.NonEmptyString,
        address: tt.NonEmptyString,
        vatStatus: CompanyVatStatusC,
        emitReceiptStatus: CompanyEmitReceiptStatus.JSON,
      },
      '!',
    ),
    t.partial(
      {
        county: tt.NonEmptyString,
        phone: StrictPhoneNumber,
        email: Email.TRUSTED_JSON,
      },
      '?',
    ),
  ],
  'CheckoutCompany',
);

export const CheckoutCompanyAutocompleteC: t.Type<CheckoutCompanyAutocomplete, JSONable> = t.strict(
  {
    cif: t.string,
    name: t.string,
    tradeRegisterNumber: tt.NonEmptyString,
    district: optionull(t.string),
    city: optionull(t.string),
    address: optionull(t.string),
    vatStatus: CompanyVatStatusC,
  },
  'CheckoutCompanyAutocomplete',
);

export const CheckoutCompanyIdC: t.Type<CheckoutCompanyId, string> = t.brand(
  ObjectId,
  (_id: ObjectId): _id is CheckoutCompanyId => true,
  'CheckoutCompanyId',
);

export const InventoryIdC: t.Type<InventoryId, string> = t.brand(
  ObjectId,
  (_id: ObjectId): _id is InventoryId => true,
  'InventoryId',
);

export const HasCheckoutCompanyIdC: t.Type<HasId<CheckoutCompanyId>, JSONable> = t.type(
  {
    _id: CheckoutCompanyIdC,
  },
  'HasCheckoutCompanyIdC',
);

export const CheckoutCashRegistryIdC: t.Type<CheckoutCashRegistryId, string> = t.brand(
  ObjectId,
  (_id: ObjectId): _id is CheckoutCashRegistryId => true,
  'CheckoutCashRegistryId',
);

export const CheckoutCashRegistryTransactionIdC: t.Type<CheckoutCashRegistryTransactionId, string> = t.brand(
  ObjectId,
  (_id: ObjectId): _id is CheckoutCashRegistryTransactionId => true,
  'CheckoutCashRegistryTransactionId',
);

export const CheckoutCashRegistryTransactionTypeC = enumType<CheckoutCashRegistryTransactionType>(
  CheckoutCashRegistryTransactionType,
);

export const CheckoutCashRegistryTransactionOriginC = enumType<CheckoutCashRegistryTransactionOrigin>(
  CheckoutCashRegistryTransactionOrigin,
);

export const SavedCheckoutCompanyC: t.Type<SavedCheckoutCompany, JSONable> = t.intersection(
  [
    HasCheckoutCompanyIdC,
    t.type(
      {
        pageId: PageId,
        version: t.number,
        emitReceiptStatus: CompanyEmitReceiptStatus.JSON,
        billingDetails: Option.json(
          t.intersection([PageBillingDetails.Company.JSON, HasId.json(PageBillingDetailsId.JSON)], 'BillingDetails'),
        ),
      },
      '!',
    ),
  ],
  'SaveCheckoutCompany',
);

export const CheckoutCashRegisterIdC: t.Type<CheckoutCashRegisterId, string> = t.brand(
  ObjectId,
  (a): a is CheckoutCashRegisterId => true,
  'CheckoutCashRegisterId',
);

export const CheckoutCashRegisterDriverIdC: t.Type<CheckoutCashRegisterDriverId, string> = t.brand(
  t.string,
  (s): s is CheckoutCashRegisterDriverId => true,
  'CheckoutCashRegisterDriverId',
);

export const CheckoutCashRegisterDriverConfigC: t.Type<CheckoutCashRegisterDriverConfig, JSONable> = t.strict(
  {
    fiscalNet: Option.json(
      t.strict(
        {
          vatGroup19: Option.json(t.number),
          vatGroup9: Option.json(t.number),
          vatGroup5: Option.json(t.number),
          vatGroup0: Option.json(t.number),
          vatGroupNoVat: Option.json(t.number),
        },
        'FiscalNet',
      ),
    ),
  },
  'CheckoutCashRegisterDriverConfig',
);

export const CheckoutSavedCashRegisterC: t.Type<CheckoutSavedCashRegister, JSONable> = t.type(
  {
    _id: CheckoutCashRegisterIdC,
    company: HasId.json(CheckoutCompanyIdC),
    name: optionull(t.string),
    driver: t.type(
      {
        _id: CheckoutCashRegisterDriverIdC,
        config: Option.json(CheckoutCashRegisterDriverConfigC),
      },
      'Driver',
    ),
    createdAt: tt.DateFromISOString,
  },
  'SavedCheckoutCashRegister',
);

export const CheckoutSavedCashRegisterArrayC = t.array(CheckoutSavedCashRegisterC);

export const SavedCheckoutCompanyArrayC: t.Type<SavedCheckoutCompany[], JSONable> = t.array(SavedCheckoutCompanyC);

export const SavedCheckoutCompanyWithRegistersC: t.Type<SavedCheckoutCompanyWithRegisters, JSONable> = t.intersection(
  [
    SavedCheckoutCompanyC,
    t.type(
      {
        cashRegisters: CheckoutSavedCashRegisterArrayC,
      },
      '!',
    ),
  ],
  'SaveCheckoutCompany',
);

export const CheckoutReceiptIdC: t.Type<CheckoutReceiptId, string> = t.brand(
  ObjectId,
  (_id: ObjectId): _id is CheckoutReceiptId => true,
  'CheckoutReceiptId',
);

export const CheckoutTransactionIdC: t.Type<CheckoutTransactionId, string> = t.brand(
  ObjectId,
  (_id: ObjectId): _id is CheckoutTransactionId => true,
  'CheckoutTransactionId',
);

export const CheckoutCashRegisterPrintTicketC: t.Type<CheckoutCashRegisterPrintTicket, JSONable> = t.type(
  { downloadUrl: t.string },
  'CheckoutPrintReceiptTicket',
);

export const checkoutCashRegistryC = <Unit extends MeroUnits.Any>(
  unit: Unit,
): t.Type<CheckoutCashRegistry<Unit>, JSONable> => {
  const MoneyC = moneyC(unit);

  return t.type(
    {
      _id: CheckoutCashRegistryIdC,
      checkoutCompany: t.type(
        {
          _id: CheckoutCompanyIdC,
        },
        'CheckoutCashRegistry.checkoutCompany',
      ),
      timezone: t.string,
      initialBalance: t.type(
        {
          amount: MoneyC,
          date: tt.DateFromISOString,
        },
        `CheckoutCashRegistry.initialBalance<${unit}>`,
      ),
    },
    `CheckoutCashRegistry<${unit}>`,
  );
};

export const CheckoutCashRegistryC = t.union(
  [checkoutCashRegistryC(MeroUnits.RON.code), checkoutCashRegistryC(MeroUnits.EUR.code)],
  'CheckoutCashRegistry',
);

export const IOperationAuditC: t.Type<IOperationAuditPreview, JSONable> = t.type(
  {
    user: t.type(
      {
        _id: UserId,
        firstname: t.string,
        lastname: t.string,
      },
      'IOperationAudit.user',
    ),
    date: tt.DateFromISOString,
  },
  'IOperationAudit',
);

export const checkoutCashRegistryTransactionC = <Unit extends MeroUnits.Any>(
  unit: Unit,
): t.Type<CheckoutCashRegistryTransaction<Unit>, JSONable> => {
  const MoneyC = moneyC(unit);

  return t.intersection(
    [
      t.type(
        {
          _id: CheckoutCashRegistryTransactionIdC,
          cashRegistry: t.type(
            {
              _id: CheckoutCashRegistryIdC,
            },
            'CheckoutCashRegistryTransaction.cashRegistry',
          ),
          created: IOperationAuditC,
          updated: IOperationAuditC,
          isDeleted: t.boolean,
          type: CheckoutCashRegistryTransactionTypeC,
          origin: CheckoutCashRegistryTransactionOriginC,
          details: t.string,
          docNo: t.string,
          transactionDate: tt.DateFromISOString,
          amount: MoneyC,
          sortingIndex: t.number,
          checkoutTransactionId: optionull(CheckoutTransactionIdC),
        },
        '!',
      ),
      t.partial(
        {
          checkoutTransaction: t.type(
            {
              _id: CheckoutTransactionIdC,
              isDeleted: t.boolean,
            },
            'CheckoutCashRegistryTransaction.checkoutTransaction',
          ),
        },
        '?',
      ),
    ],
    `CheckoutCashRegistryTransaction`,
  );
};

export const checkoutCashRegistryTransactionListC = <Unit extends MeroUnits.Any>(
  unit: Unit,
): t.Type<CheckoutCashRegistryTransactionList<Unit>, JSONable> => {
  const MoneyC = moneyC(unit);
  return t.type(
    {
      interval: t.type(
        {
          fromDate: tt.DateFromISOString,
          toDate: tt.DateFromISOString,
        },
        'CheckoutCashRegistryTransactionList.interval',
      ),
      initialBalance: t.type(
        {
          amount: MoneyC,
          date: tt.DateFromISOString,
        },
        'CheckoutCashRegistryTransactionList.initialBalance',
      ),
      currentBalance: MoneyC,
      dailyBreakdown: t.array(
        t.type({
          date: t.string,
          startDayBalance: MoneyC,
          endDayBalance: MoneyC,
          transactions: t.array(
            t.intersection([
              checkoutCashRegistryTransactionC(unit),
              t.type({
                intermediaryBalance: MoneyC,
              }),
            ]),
            'CheckoutCashRegistryTransactionList.dailyBreakdown.transactions',
          ),
        }),
        'CheckoutCashRegistryTransactionList.dailyBreakdown',
      ),
    },
    'CheckoutCashRegistryTransactionList',
  );
};

// FIXME: implement this codec
export const MembershipTemplateIdC: t.Type<MembershipTemplateId, string> = t.brand(
  ObjectId,
  (_id: ObjectId): _id is MembershipTemplateId => true,
  'MembershipTemplateId',
);

export const MembershipPurchaseIdC: t.Type<MembershipPurchaseId, string> = t.brand(
  ObjectId,
  (_id: ObjectId): _id is MembershipPurchaseId => true,
  'MembershipPurchaseId',
);

export const CheckoutCashRegisterDriverDetailsC: t.Type<CheckoutCashRegisterDriverDetails, JSONable> = t.type(
  {
    driverId: CheckoutCashRegisterDriverIdC,
    name: t.string,
  },
  'CheckoutCashRegisterDriverDetails',
);

export const CheckoutCashRegisterDriverDetailsArrayC = t.array(CheckoutCashRegisterDriverDetailsC);

export const CheckoutTransactionCodeC: t.Type<CheckoutTransactionCode, string> = t.brand(
  t.string,
  CheckoutTransactionCode.is,
  'CheckoutTransactionCode',
);

export const appliedDiscountC = <Unit extends MeroUnits.Any>(
  unit: Unit,
): t.Type<AppliedDiscount<ScaledNumber, Unit>, JSONable> => {
  return t.union(
    [
      t.strict(
        {
          type: t.literal('Percent'),
          percent: DiscountPercentScaledNumber.JSON,
        },
        'Percent',
      ),
      t.strict(
        {
          type: t.literal('Value'),
          value: moneyC(unit),
        },
        'Value',
      ),
    ],
    `AppliedDiscount<${unit}>`,
  );
};

const CheckoutItemVatStatusIncludedC: t.Type<CheckoutItemVatStatus.Included<ScaledNumber>, JSONable> = t.strict(
  {
    type: t.literal('Included'),
    rate: Option.json(PortionPercentScaledNumber.JSON),
  },
  'Included',
);

const CheckoutItemVatStatusExcludedC: t.Type<CheckoutItemVatStatus.Excluded<ScaledNumber>, JSONable> = t.strict(
  {
    type: t.literal('Excluded'),
    rate: Option.json(PortionPercentScaledNumber.JSON),
  },
  'Excluded',
);

const CheckoutItemVatStatusC: t.Type<CheckoutItemVatStatus.Any<ScaledNumber>, JSONable> = t.union(
  [CheckoutItemVatStatusIncludedC, CheckoutItemVatStatusExcludedC],
  'CheckoutVatStatus',
);

const checkoutMembershipItemTotalC = <Unit extends MeroUnits.Any>(
  unit: Unit,
): t.Type<CheckoutMembershipItemTotal<ScaledNumber, Unit>, JSONable> => {
  return t.strict(
    {
      amount: moneyC(unit),
      vatStatus: CheckoutItemVatStatusIncludedC,
    },
    `CheckoutMembershipItemTotal<${unit}>`,
  );
};

const checkoutItemTotalC = <Unit extends MeroUnits.Any>(
  unit: Unit,
): t.Type<CheckoutItemTotal<ScaledNumber, Unit>, JSONable> => {
  return t.exact(
    t.intersection(
      [
        t.type(
          {
            amount: moneyC(unit),
            vatStatus: CheckoutItemVatStatusC,
          },
          '!',
        ),
        t.partial(
          {
            discount: appliedDiscountC(unit),
          },
          '?',
        ),
      ],
      `CheckoutItemTotal<${unit}>`,
    ),
    `Exact<CheckoutItemTotal<${unit}>>`,
  );
};

const checkoutTotalsC = <Unit extends MeroUnits.Any>(
  unit: Unit,
): t.Type<CheckoutTotals<ScaledNumber, Unit>, JSONable> => {
  const MoneyC = moneyC(unit);
  return t.exact(
    t.intersection(
      [
        t.type(
          {
            subtotal: MoneyC,
            total: MoneyC,
          },
          '!',
        ),
        t.partial(
          {
            discount: MoneyC,
            vat: MoneyC,
          },
          '?',
        ),
      ],
      `CheckoutTotals<${unit}>`,
    ),
    `Exact<CheckoutTotals<${unit}>>`,
  );
};

const CheckoutPagePreviewC: t.Type<CheckoutPagePreview, JSONable> = t.strict(
  {
    _id: PageId,
  },
  'CheckoutPagePreview',
);

export const CheckoutUserPreviewC: t.Type<CheckoutUserPreview, JSONable> = t.strict(
  {
    _id: UserId,
    phone: t.string,
    profile: t.exact(
      t.intersection(
        [
          HasOptionalFirstLastName,
          t.partial({
            photo: ProfileImage,
          }),
        ],
        'Profile',
      ),
      'Exact<Profile>',
    ),
  },
  'CheckoutUserPreview',
);

export const CheckoutClientPreviewC: t.Type<CheckoutClientPreview, JSONable> = t.strict(
  {
    _id: ClientId,
    pageId: PageId,
    user: CheckoutUserPreviewC,
  },
  'CheckoutClientPreview',
);

const CheckoutWorkerPreviewC: t.Type<CheckoutWorkerPreview, JSONable> = t.strict(
  {
    _id: WorkerId.JSON,
    user: CheckoutUserPreviewC,
  },
  'CheckoutWorkerPreview',
);

const CheckoutTransactionOnlinePaymentSourceC: t.Type<CheckoutTransactionPayment.OnlinePaymentSource.Any, JSONable> =
  t.type({
    type: t.literal('UserOrderPayment'),
    paymentId: UserOrderPaymentId.JSON,
  });

const checkoutTransactionPaymentC = <Unit extends MeroUnits.Any>(
  unit: Unit,
): t.Type<CheckoutTransactionPayment.Any<ScaledNumber, Unit>, JSONable> => {
  return t.union(
    [
      t.strict(
        {
          type: t.literal('Cash'),
          total: moneyC(unit),
        },
        `Cash<${unit}>`,
      ),
      t.strict(
        {
          type: t.literal('Card'),
          total: moneyC(unit),
        },
        `Card<${unit}>`,
      ),
      t.exact(
        t.intersection(
          [
            t.type(
              {
                type: t.literal('BankTransfer'),
                total: moneyC(unit),
              },
              '!',
            ),
            t.partial(
              {
                note: t.string,
              },
              '?',
            ),
          ],
          `BankTransfer<${unit}>`,
        ),
        `Exact<BankTransfer<${unit}>>`,
      ),
      t.strict(
        {
          type: t.literal('Membership'),
          membership: CheckoutTransactionMembershipPreviewC,
          items: t.array(
            t.union(
              [
                t.strict(
                  {
                    type: t.literal('Service'),
                    transactionItemId: t.string,
                    service: CheckoutTransactionServicePreviewC,
                    effectivePrice: MembershipItemEffectivePriceC(unit),
                    quantity: PositiveInt.JSON,
                  },
                  'Service',
                ),
                t.strict(
                  {
                    type: t.literal('Product'),
                  },
                  'Product',
                ),
              ],
              `CheckoutTransactionPaymentMembershipItem<${unit}>`,
            ),
          ),
        },
        `Membership<${unit}>`,
      ),
      t.strict(
        {
          type: t.literal('Giftcard'),
          code: t.string,
          total: moneyC(unit),
        },
        `Giftcard<${unit}>`,
      ),
      t.strict(
        {
          type: t.literal('Online'),
          total: moneyC(unit),
          source: CheckoutTransactionOnlinePaymentSourceC,
        },
        `Online`,
      ),
    ],
    `CheckoutTransactionPayment<${unit}>`,
  );
};

const CreateCheckoutTransactionOnlinePaymentSourceC: t.Type<
  CreateCheckoutTransactionPayment.OnlinePaymentSource.Any,
  JSONable
> = t.type({
  type: t.literal('UserOrderPayment'),
  paymentId: UserOrderPaymentId.JSON,
});

const createCheckoutTransactionPaymentC = <Unit extends MeroUnits.Any>(
  unit: Unit,
): t.Type<CreateCheckoutTransactionPayment.Any<ScaledNumber, Unit>, JSONable> => {
  return t.union(
    [
      t.strict(
        {
          type: t.literal('Cash'),
          total: moneyC(unit),
        },
        `Cash<${unit}>`,
      ),
      t.strict(
        {
          type: t.literal('Card'),
          total: moneyC(unit),
        },
        `Card<${unit}>`,
      ),
      t.exact(
        t.intersection(
          [
            t.type(
              {
                type: t.literal('BankTransfer'),
                total: moneyC(unit),
              },
              '!',
            ),
            t.partial(
              {
                note: t.string,
              },
              '?',
            ),
          ],
          `BankTransfer<${unit}>`,
        ),
        `Exact<BankTransfer<${unit}>>`,
      ),
      t.strict(
        {
          type: t.literal('Membership'),
          membership: CheckoutTransactionMembershipPreviewC,
          items: t.array(
            t.union(
              [
                t.exact(
                  t.intersection(
                    [
                      t.type(
                        {
                          type: t.literal('Service'),
                          service: CheckoutTransactionServicePreviewC,
                          quantity: PositiveInt.JSON,
                          effectivePrice: MembershipItemEffectivePriceC(unit),
                        },
                        '!',
                      ),
                      t.partial(
                        {
                          effectivePrice: MembershipItemEffectivePriceC(unit),
                        },
                        '?',
                      ),
                    ],
                    'Service',
                  ),
                ),
                t.strict(
                  {
                    type: t.literal('Product'),
                  },
                  'Product',
                ),
              ],
              `CheckoutTransactionPaymentMembershipItem`,
            ),
          ),
        },
        `Membership`,
      ),
      t.strict(
        {
          type: t.literal('Giftcard'),
          code: t.string,
          total: moneyC(unit),
        },
        `Giftcard<${unit}>`,
      ),
      t.strict(
        {
          type: t.literal('Online'),
          total: moneyC(unit),
          source: CreateCheckoutTransactionOnlinePaymentSourceC,
        },
        `Online`,
      ),
    ],
    `CheckoutTransactionPayment<${unit}>`,
  );
};

const checkoutTransactionProductPriceC = <Unit extends MeroUnits.Any>(
  unit: Unit,
): t.Type<CheckoutTransactionProductPrice<ScaledNumber, Unit>, JSONable> => {
  return t.type({
    retailPrice: moneyC(unit),
    discountedPrice: moneyC(unit),
    vatRate: ScaledNumber.JSON,
  });
};

const checkoutTransactionServicePriceC = <Unit extends MeroUnits.Any>(
  unit: Unit,
): t.Type<CheckoutTransactionServicePrice<ScaledNumber, Unit>, JSONable> => {
  return t.union(
    [
      t.strict(
        {
          type: t.literal('Hidden'),
        },
        `Hidden<${unit}>`,
      ),
      t.strict(
        {
          type: t.literal('Fixed'),
          fixed: moneyC(unit),
        },
        `Fixed<${unit}>`,
      ),
      t.strict(
        {
          type: t.literal('Range'),
          range: t.partial(
            {
              from: moneyC(unit),
              to: moneyC(unit),
            },
            'Range',
          ),
        },
        `Range<${unit}>`,
      ),
    ],
    `CheckoutTransactionServicePrice<${unit}>`,
  );
};

const CheckoutTransactionServicePreviewC: t.Type<CheckoutTransactionServicePreview, JSONable> = t.strict(
  {
    _id: ServiceId,
    name: t.string,
  },
  `CheckoutTransactionServicePreview`,
);

const MembershipItemEffectivePriceC = <Unit extends MeroUnits.Any>(
  unit: Unit,
): t.Type<MembershipItemEffectivePrice<ScaledNumber, Unit>, JSONable> => {
  return t.type(
    {
      amount: moneyC(unit),
      vatStatus: CheckoutItemVatStatusC,
    },
    `MembershipItemEffectivePrice<${unit}>`,
  );
};

const checkoutTransactionServiceDetailsC = <Unit extends MeroUnits.Any>(
  unit: Unit,
): t.Type<CheckoutTransactionServiceDetails<Unit>, JSONable> => {
  return t.strict(
    {
      _id: ServiceId,
      name: t.string,
      groupIds: optionull(t.array(ServiceGroupId)),
      durationInMinutes: t.number,
      price: checkoutTransactionServicePriceC(unit),
    },
    `CheckoutTransactionServiceDetails<${unit}>`,
  );
};

const checkoutTransactionProductDetailsC = <Unit extends MeroUnits.Any>(
  unit: Unit,
): t.Type<CheckoutTransactionProductDetails<Unit>, JSONable> => {
  return t.intersection(
    [
      t.type({
        _id: ProductIdC,
        name: DefinedTrimedString,
        price: checkoutTransactionProductPriceC(unit),
        measure: ProductMeasure.JSON,
        categoryId: Option.json(ProductCategoryIdC),
      }),
      t.partial({
        barcode: ProductBarcodeC,
      }),
    ],
    `CheckoutTransactionServiceDetails<${unit}>`,
  );
};

const CheckoutTransactionMembershipPreviewC: t.Type<CheckoutTransactionMembershipPreview, JSONable> = t.strict(
  {
    _id: MembershipPurchaseIdC,
    name: t.string,
  },
  `checkoutTransactionMembershipDetails`,
);

const checkoutTransactionMembershipTemplateDetailsC = <Unit extends MeroUnits.Any>(
  unit: Unit,
): t.Type<CheckoutTransactionMembershipTemplateDetails<Unit>, JSONable> => {
  const MembershipItemC = t.union(
    [
      t.type(
        {
          type: t.literal('Service'),
          service: checkoutTransactionServiceDetailsC(unit),
          quantity: MembershipItemServiceQuantityC,
          total: checkoutItemTotalC(unit),
        },
        'Service',
      ),
      t.type(
        {
          type: t.literal('Product'),
        },
        'Product',
      ),
    ],
    'CheckoutTransactionItemMembershipItem',
  );

  return t.strict(
    {
      _id: MembershipTemplateIdC,
      name: t.string,
      validity: CheckoutPurchasedMembershipValidityC,
      items: tt.nonEmptyArray(MembershipItemC),
      discount: optionull(appliedDiscountC(unit)),
    },
    `CheckoutTransactionMembershipTemplateDetails`,
  );
};

const MembershipItemServiceQuantityC: t.Type<CheckoutTransactionItem.MembershipItemServiceQuantity, JSONable> = t.union(
  [
    t.type(
      {
        type: t.literal('Unlimited'),
      },
      'Unlimited',
    ),
    t.type(
      {
        type: t.literal('Limited'),
        value: PositiveInt.JSON,
      },
      'Limited',
    ),
  ],
  'MembershipItemServiceQuantity',
);

const checkoutTransactionItemC = <Unit extends MeroUnits.Any>(
  unit: Unit,
): t.Type<CheckoutTransactionItem.Any<Unit>, JSONable> => {
  const checkoutTransactionItemServiceC = <Unit extends MeroUnits.Any>(
    unit: Unit,
  ): t.Type<CheckoutTransactionItem.Service<Unit>, JSONable> => {
    return t.exact(
      t.intersection(
        [
          t.type(
            {
              type: t.literal('Service'),
              service: checkoutTransactionServiceDetailsC(unit),
              quantity: PositiveInt.JSON,
              total: checkoutItemTotalC(unit),
              saleOwner: CheckoutUserPreviewC,
            },
            '!',
          ),
          t.partial(
            {
              transactionItemId: t.string,
            },
            '?',
          ),
        ],
        `Service<${unit}>`,
      ),
      `Exact<Service<${unit}>>`,
    );
  };

  const checkoutTransactionItemBookingC = <Unit extends MeroUnits.Any>(
    unit: Unit,
  ): t.Type<CheckoutTransactionItem.Booking<Unit>, JSONable> => {
    return t.exact(
      t.intersection(
        [
          t.type(
            {
              type: t.literal('Booking'),
              calendarId: CalendarId,
              appointmentId: AppointmentId,
              occurrenceIndex: optionull(t.number),
              worker: CheckoutWorkerPreviewC,
              start: tt.DateFromISOString,
              status: AppointmentStatus,
              items: t.array(
                t.union([checkoutTransactionItemServiceC(unit), checkoutTransactionItemProductC(unit)], 'BookingItem'),
              ),
            },
            '!',
          ),
          t.partial(
            {
              transactionItemId: t.string,
            },
            '?',
          ),
        ],
        `Booking<${unit}>`,
      ),
      `Exact<Booking<${unit}>>`,
    );
  };

  const checkoutTransactionItemAmountC = <Unit extends MeroUnits.Any>(
    unit: Unit,
  ): t.Type<CheckoutTransactionItem.Amount<Unit>, JSONable> => {
    return t.exact(
      t.intersection(
        [
          t.type(
            {
              type: t.literal('Amount'),
              total: checkoutItemTotalC(unit),
              saleOwner: CheckoutUserPreviewC,
            },
            '!',
          ),
          t.partial(
            {
              transactionItemId: t.string,
              note: t.string,
            },
            '?',
          ),
        ],
        `Amount<${unit}>`,
      ),
      `Exact<Amount<${unit}>>`,
    );
  };

  const checkoutTransactionItemProductC = <Unit extends MeroUnits.Any>(
    unit: Unit,
  ): t.Type<CheckoutTransactionItem.Product<Unit>, JSONable> => {
    return t.exact(
      t.type(
        {
          type: t.literal('Product'),
          total: checkoutItemTotalC(unit),
          saleOwner: CheckoutUserPreviewC,
          product: checkoutTransactionProductDetailsC(unit),
          availability: ProductAvailability.JSON,
          quantity: PositiveScaledNumber.JSON,
          transactionItemId: t.string,
          inventoryId: InventoryIdC,
        },
        'Product',
      ),
      'Exact<Product>',
    );
  };
  const checkoutTransactionItemMembershipC = <Unit extends MeroUnits.Any>(
    unit: Unit,
  ): t.Type<CheckoutTransactionItem.Membership<Unit>, JSONable> => {
    return t.strict(
      {
        type: t.literal('Membership'),
        transactionItemId: optionull(t.string),
        membershipTemplate: checkoutTransactionMembershipTemplateDetailsC(unit),
        membershipPurchaseId: MembershipPurchaseIdC,
        quantity: Numbers.One,
        total: checkoutMembershipItemTotalC(unit),
        saleOwner: CheckoutUserPreviewC,
      },
      `Membership<${unit}>`,
    );
  };

  const CheckoutTransactionItemMembershipInstallmentC: t.Type<
    CheckoutTransactionItem.MembershipInstallment<Unit>,
    JSONable
  > = t.exact(
    t.intersection(
      [
        t.type(
          {
            type: t.literal('MembershipInstallment'),
            membership: CheckoutTransactionMembershipPreviewC,
            total: checkoutMembershipItemTotalC(unit),
            saleOwner: CheckoutUserPreviewC,
          },
          '!',
        ),
        t.partial(
          {
            transactionItemId: t.string,
          },
          '?',
        ),
      ],
      'MembershipInstallment',
    ),
    'Exact<MembershipInstallment>',
  );

  return t.union(
    [
      checkoutTransactionItemServiceC(unit),
      checkoutTransactionItemBookingC(unit),
      checkoutTransactionItemAmountC(unit),
      checkoutTransactionItemProductC(unit),
      checkoutTransactionItemMembershipC(unit),
      CheckoutTransactionItemMembershipInstallmentC,
    ],
    'CheckoutTransactionItem',
  );
};

const CheckoutTransactionCompanyNone: t.Type<CheckoutTransactionCompany.None, JSONable> = t.strict(
  {
    type: t.literal('None'),
  },
  'None',
);

export const CheckoutTransactionCompanyDetailsC: t.Type<CheckoutTransactionCompanyDetails, JSONable> = t.type(
  {
    _id: CheckoutCompanyIdC,
    name: tt.NonEmptyString,
    regNo: tt.NonEmptyString,
    fiscalCode: tt.NonEmptyString,
    countryCode: tt.NonEmptyString,
    county: Option.json(tt.NonEmptyString),
    city: tt.NonEmptyString,
    address: tt.NonEmptyString,
    phone: Option.json(StrictPhoneNumber),
    email: Option.json(Email.TRUSTED_JSON),
    vatStatus: CompanyVatStatusC,
    emitReceiptStatus: CompanyEmitReceiptStatus.JSON,
  },
  'CheckoutCompany',
);

const CheckoutTransactionCompanyCompanyC: t.Type<CheckoutTransactionCompany.Company, JSONable> = t.strict(
  {
    type: t.literal('Company'),
    company: CheckoutTransactionCompanyDetailsC,
    /**
     * @deprecated will keep this field for a while until all the clients will be updated and won't use this field anymore
     */
    emitReceipt: optionull(t.boolean),
    receipt: t.union(
      [
        t.strict(
          {
            emit: t.literal(false),
          },
          'NoEmit',
        ),
        t.strict(
          {
            emit: t.literal(true),
            cashRegister: HasId.json(CheckoutCashRegisterIdC),
            customerFiscalCode: Option.json(tt.NonEmptyString),
          },
          'Emit',
        ),
      ],
      'Receipt',
    ),
  },
  'Company',
);

const CheckoutTransactionCompanyC: t.Type<CheckoutTransactionCompany.Any, JSONable> = t.union(
  [CheckoutTransactionCompanyNone, CheckoutTransactionCompanyCompanyC],
  'CheckoutTransactionCompany',
);

const CheckoutTransactionCompanyRefNoneC: t.Type<CheckoutTransactionCompanyRef.None, JSONable> = t.strict(
  {
    type: t.literal('None'),
  },
  'None',
);

const CheckoutTransactionCompanyRefCompanyC: t.Type<CheckoutTransactionCompanyRef.Company, JSONable> = t.strict(
  {
    type: t.literal('Company'),
    company: HasCheckoutCompanyIdC,
    receipt: t.union(
      [
        t.strict(
          {
            emit: t.literal(false),
          },
          'NoEmit',
        ),
        t.strict(
          {
            emit: t.literal(true),
            cashRegister: HasId.json(CheckoutCashRegisterIdC),
            customerFiscalCode: Option.json(tt.NonEmptyString),
          },
          'Emit',
        ),
      ],
      'Receipt',
    ),
  },
  'Company',
);

const CheckoutTransactionCompanyRefC: t.Type<CheckoutTransactionCompanyRef.Any, JSONable> = t.union(
  [CheckoutTransactionCompanyRefNoneC, CheckoutTransactionCompanyRefCompanyC],
  'CheckoutTransactionCompany',
);

const CheckoutPurchasedMembershipValidityC = t.union(
  [
    t.type(
      {
        type: t.literal('Unlimited'),
        from: tt.DateFromISOString,
      },
      'Unlimited',
    ),
    t.type(
      {
        type: t.literal('Limited'),
        from: tt.DateFromISOString,
        days: PositiveInt.JSON,
      },
      'Limited',
    ),
  ],
  'CheckoutPurchasedMembershipValidity',
);

const createCheckoutTransactionItemC = <Unit extends MeroUnits.Any>(
  unit: Unit,
): t.Type<CreateCheckoutTransactionItem.Any<Unit>, JSONable> => {
  const createCheckoutTransactionItemServiceC = <Unit extends MeroUnits.Any>(
    unit: Unit,
  ): t.Type<CreateCheckoutTransactionItem.Service<Unit>, JSONable> => {
    return t.strict(
      {
        type: t.literal('Service'),
        service: HasId.json(ServiceId),
        quantity: PositiveInt.JSON,
        total: checkoutItemTotalC(unit),
        saleOwner: HasId.json(UserId),
      },
      `Service<${unit}>`,
    );
  };

  const createCheckoutTransactionItemBookingC = <Unit extends MeroUnits.Any>(
    unit: Unit,
  ): t.Type<CreateCheckoutTransactionItem.Booking<Unit>, JSONable> => {
    return t.exact(
      t.intersection([
        t.type(
          {
            type: t.literal('Booking'),
            calendarId: CalendarId,
            appointmentId: AppointmentId,
            items: t.array(
              t.union(
                [createCheckoutTransactionItemServiceC(unit), createCheckoutTransactionItemProductC(unit)],
                'BookingItem',
              ),
            ),
          },
          '!',
        ),
        t.partial(
          {
            occurrenceIndex: t.number,
          },
          '?',
        ),
      ]),
      `Booking<${unit}>`,
    );
  };

  const createCheckoutTransactionItemAmountC = <Unit extends MeroUnits.Any>(
    unit: Unit,
  ): t.Type<CreateCheckoutTransactionItem.Amount<Unit>, JSONable> => {
    return t.exact(
      t.intersection(
        [
          t.type(
            {
              type: t.literal('Amount'),
              total: checkoutItemTotalC(unit),
              saleOwner: HasId.json(UserId),
            },
            '!',
          ),
          t.partial(
            {
              note: t.string,
            },
            '?',
          ),
        ],
        `Amount<${unit}>`,
      ),
      `Exact<Amount<${unit}>>`,
    );
  };

  const createCheckoutTransactionItemProductC = <Unit extends MeroUnits.Any>(
    unit: Unit,
  ): t.Type<CreateCheckoutTransactionItem.Product<Unit>, JSONable> => {
    return t.strict(
      {
        type: t.literal('Product'),
        total: checkoutItemTotalC(unit),
        saleOwner: HasId.json(UserId),
        availability: Option.json(ProductAvailability.JSON),
        product: HasId.json(ProductId),
        quantity: PositiveScaledNumber.JSON,
        inventoryId: InventoryIdC,
      },
      'Product',
    );
  };

  const createCheckoutTransactionItemMembershipC = <Unit extends MeroUnits.Any>(
    unit: Unit,
  ): t.Type<CreateCheckoutTransactionItem.Membership<Unit>, JSONable> => {
    const MembershipItemC = t.union(
      [
        t.type(
          {
            type: t.literal('Service'),
            service: HasId.json(ServiceId),
            quantity: MembershipItemServiceQuantityC,
            total: checkoutItemTotalC(unit),
          },
          'Service',
        ),
        t.type(
          {
            type: t.literal('Product'),
          },
          'Product',
        ),
      ],
      'CreateCheckoutTransactionItemMembershipItem',
    );

    return t.strict(
      {
        type: t.literal('Membership'),
        membershipTemplate: t.type(
          {
            _id: MembershipTemplateIdC,
            name: t.string,
            validity: CheckoutPurchasedMembershipValidityC,
            items: tt.nonEmptyArray(MembershipItemC),
            discount: optionull(appliedDiscountC(unit)),
          },
          'MembershipTemplate',
        ),
        quantity: Numbers.One,
        total: t.type(
          {
            amount: moneyC(unit),
            vatStatus: CheckoutItemVatStatusIncludedC,
          },
          'Total',
        ),
        saleOwner: HasId.json(UserId),
      },
      `Membership<${unit}>`,
    );
  };

  const CreateCheckoutTransactionItemMembershipInstallmentC: t.Type<
    CreateCheckoutTransactionItem.MembershipInstallment<Unit>,
    JSONable
  > = t.strict(
    {
      type: t.literal('MembershipInstallment'),
      membership: CheckoutTransactionMembershipPreviewC,
      total: checkoutMembershipItemTotalC(unit),
      saleOwner: HasId.json(UserId),
    },
    `MembershipInstallment<${unit}>`,
  );

  return t.union(
    [
      createCheckoutTransactionItemServiceC(unit),
      createCheckoutTransactionItemBookingC(unit),
      createCheckoutTransactionItemAmountC(unit),
      createCheckoutTransactionItemProductC(unit),
      createCheckoutTransactionItemMembershipC(unit),
      CreateCheckoutTransactionItemMembershipInstallmentC,
    ],
    'CheckoutTransactionItem',
  );
};

export const createCheckoutDraftRequestC = <Unit extends MeroUnits.Any>(
  unit: Unit,
): t.Type<CreateCheckoutDraftRequest<Unit>, JSONable> => {
  return t.strict(
    {
      unit: unitC(unit),
      page: HasId.json(PageId),
      client: optionull(HasId.json(ClientId)),
      items: t.array(createCheckoutTransactionItemC(unit)),
      discount: optionull(appliedDiscountC(unit)),
      payments: t.array(createCheckoutTransactionPaymentC(unit)),
      company: CheckoutTransactionCompanyRefC,
      isProtocol: t.boolean,
    },
    `CreateCheckoutDraftRequest<${unit}>`,
  );
};

export const updateCheckoutDraftRequestC = <Unit extends MeroUnits.Any>(
  unit: Unit,
): t.Type<UpdateCheckoutDraftRequest<Unit>, JSONable> => {
  return t.strict(
    {
      _id: CheckoutTransactionIdC,
      unit: unitC(unit),
      page: HasId.json(PageId),
      client: optionull(HasId.json(ClientId)),
      items: t.array(createCheckoutTransactionItemC(unit)),
      discount: optionull(appliedDiscountC(unit)),
      payments: t.array(createCheckoutTransactionPaymentC(unit)),
      company: CheckoutTransactionCompanyRefC,
      isProtocol: t.boolean,
    },
    `UpdateCheckoutDraftRequest<${unit}>`,
  );
};

export const checkoutRequestC = <Unit extends MeroUnits.Any>(unit: Unit): t.Type<CheckoutRequest<Unit>, JSONable> => {
  return t.strict(
    {
      unit: unitC(unit),
      page: HasId.json(PageId),
      client: optionull(HasId.json(ClientId)),
      items: tt.nonEmptyArray(createCheckoutTransactionItemC(unit)),
      discount: optionull(appliedDiscountC(unit)),
      payments: t.array(createCheckoutTransactionPaymentC(unit)),
      company: CheckoutTransactionCompanyRefCompanyC,
      isProtocol: t.boolean,
    },
    `CheckoutRequest<${unit}>`,
  );
};

export const checkoutDraftRequestC = <Unit extends MeroUnits.Any>(
  unit: Unit,
): t.Type<CheckoutDraftRequest<Unit>, JSONable> => {
  return t.intersection(
    [
      checkoutRequestC(unit),
      t.type(
        {
          _id: CheckoutTransactionIdC,
        },
        '!',
      ),
    ],
    `CheckoutDraftRequest<${unit}>`,
  );
};

export const checkoutFinishedTransactionsStatsC = <Unit extends MeroUnits.Any>(
  unit: Unit,
): t.Type<CheckoutFinishedTransactionsStats<Unit>, JSONable> => {
  return t.type(
    {
      count: t.union([Numbers.Zero, PositiveInt.JSON]),
      totals: checkoutTotalsC(unit),
    },
    `CheckoutFinishedTransactionsStats<${unit}>`,
  );
};

export const checkoutTransactionDetailsDraftC = <Unit extends MeroUnits.Any>(
  unit: Unit,
): t.Type<CheckoutTransactionDetails.Draft<Unit>, JSONable> => {
  return t.exact(
    t.intersection(
      [
        t.type(
          {
            _id: CheckoutTransactionIdC,
            status: t.literal('Draft'),
            unit: unitC(unit),
            page: CheckoutPagePreviewC,
            code: CheckoutTransactionCodeC,
            createdAt: tt.DateFromISOString,
            createdBy: CheckoutUserPreviewC,
            items: t.array(checkoutTransactionItemC(unit)),
            payments: t.array(checkoutTransactionPaymentC(unit)),
            company: CheckoutTransactionCompanyC,
            total: checkoutTotalsC(unit),
            isProtocol: t.boolean,
            version: t.number,
          },
          '!',
        ),
        t.partial(
          {
            client: CheckoutClientPreviewC,
            discount: appliedDiscountC(unit),
          },
          '?',
        ),
      ],
      `CheckoutTransactionEntityDraft<${unit}>`,
    ),
    `Exact<CheckoutTransactionEntityDraft<${unit}>>`,
  );
};

export const CheckoutTransactionDetailsDraftC: t.Type<CheckoutTransactionDetails.AnyDraft, JSONable> = t.union(
  [checkoutTransactionDetailsDraftC(MeroUnits.RON.code), checkoutTransactionDetailsDraftC(MeroUnits.EUR.code)],
  'CheckoutTransactionDetails.AnyDraft',
);

export const CheckoutTransactionDetailsDraftArraC = t.array(CheckoutTransactionDetailsDraftC);

export const checkoutTransactionDetailsFinishedC = <Unit extends MeroUnits.Any>(
  unit: Unit,
): t.Type<CheckoutTransactionDetails.Finished<Unit>, JSONable> => {
  return t.exact(
    t.intersection(
      [
        t.type(
          {
            _id: CheckoutTransactionIdC,
            status: t.literal('Finished'),
            unit: unitC(unit),
            page: CheckoutPagePreviewC,
            code: CheckoutTransactionCodeC,
            createdAt: tt.DateFromISOString,
            createdBy: CheckoutUserPreviewC,
            finishedAt: tt.DateFromISOString,
            finishedBy: CheckoutUserPreviewC,
            items: tt.nonEmptyArray(checkoutTransactionItemC(unit)),
            payments: t.array(checkoutTransactionPaymentC(unit)),
            company: CheckoutTransactionCompanyCompanyC,
            total: checkoutTotalsC(unit),
            isProtocol: t.boolean,
            version: t.number,
          },
          '!',
        ),
        t.partial(
          {
            client: CheckoutClientPreviewC,
            discount: appliedDiscountC(unit),
          },
          '?',
        ),
      ],
      `CheckoutTransactionEntityFinished<${unit}>`,
    ),
    `Exact<CheckoutTransactionEntityFinished<${unit}>>`,
  );
};

export const CheckoutTransactionDetailsFinishedC: t.Type<CheckoutTransactionDetails.AnyFinished, JSONable> = t.union(
  [checkoutTransactionDetailsFinishedC(MeroUnits.RON.code), checkoutTransactionDetailsFinishedC(MeroUnits.EUR.code)],
  'CheckoutTransactionDetails.AnyFinished',
);

export const CheckoutTransactionDetailsFinishedArrayC = t.array(CheckoutTransactionDetailsFinishedC);

export const checkoutTransactionDetailsDeletedC = <Unit extends MeroUnits.Any>(
  unit: Unit,
): t.Type<CheckoutTransactionDetails.Deleted<Unit>, JSONable> => {
  return t.exact(
    t.intersection(
      [
        t.type(
          {
            _id: CheckoutTransactionIdC,
            status: t.literal('Deleted'),
            unit: unitC(unit),
            page: CheckoutPagePreviewC,
            code: CheckoutTransactionCodeC,
            createdAt: tt.DateFromISOString,
            createdBy: CheckoutUserPreviewC,
            finishedAt: tt.DateFromISOString,
            finishedBy: CheckoutUserPreviewC,
            deletedAt: tt.DateFromISOString,
            items: tt.nonEmptyArray(checkoutTransactionItemC(unit)),
            payments: t.array(checkoutTransactionPaymentC(unit)),
            company: CheckoutTransactionCompanyCompanyC,
            total: checkoutTotalsC(unit),
            isProtocol: t.boolean,
            version: t.number,
          },
          '!',
        ),
        t.partial(
          {
            client: CheckoutClientPreviewC,
            discount: appliedDiscountC(unit),
            deletedBy: CheckoutUserPreviewC,
          },
          '?',
        ),
      ],
      `CheckoutTransactionEntityDeleted<${unit}>`,
    ),
    `Exact<CheckoutTransactionEntityDeleted<${unit}>>`,
  );
};

export const CheckoutTransactionDetailsDeletedC: t.Type<CheckoutTransactionDetails.AnyDeleted, JSONable> = t.union(
  [checkoutTransactionDetailsDeletedC(MeroUnits.RON.code), checkoutTransactionDetailsDeletedC(MeroUnits.EUR.code)],
  'CheckoutTransactionDetails.AnyDeleted',
);

export const CheckoutTransactionDetailsDeletedArrayC = t.array(CheckoutTransactionDetailsDeletedC);

export const checkoutTransactionDetailsC = <Unit extends MeroUnits.Any>(
  unit: Unit,
): t.Type<CheckoutTransactionDetails.Of<Unit>, JSONable> => {
  return t.union(
    [
      checkoutTransactionDetailsDraftC(unit),
      checkoutTransactionDetailsFinishedC(unit),
      checkoutTransactionDetailsDeletedC(unit),
    ],
    `CheckoutTransactionDetails<${unit}>`,
  );
};

export const CheckoutTransactionDetailsC: t.Type<CheckoutTransactionDetails.Any, JSONable> = t.union(
  [checkoutTransactionDetailsC(MeroUnits.RON.code), checkoutTransactionDetailsC(MeroUnits.EUR.code)],
  'CheckoutTransactionDetails.Any',
);

const CheckoutTransactionPaymentTypeC = t.keyof(
  {
    Cash: null,
    Card: null,
    BankTransfer: null,
    Membership: null,
    Giftcard: null,
    Online: null,
  },
  'CheckoutTransactionPaymentType',
);

const checkoutTransactionPreviewDraftC = <Unit extends MeroUnits.Any>(
  unit: Unit,
): t.Type<CheckoutTransactionPreview.Draft<Unit>, JSONable> => {
  return t.exact(
    t.intersection(
      [
        t.type(
          {
            _id: CheckoutTransactionIdC,
            status: t.literal('Draft'),
            unit: unitC(unit),
            code: CheckoutTransactionCodeC,
            createdAt: tt.DateFromISOString,
            total: checkoutTotalsC(unit),
            paymentTypes: t.array(CheckoutTransactionPaymentTypeC),
          },
          '!',
        ),
        t.partial(
          {
            client: CheckoutClientPreviewC,
          },
          '?',
        ),
      ],
      `CheckoutTransactionEntityDraft<${unit}>`,
    ),
    `Exact<CheckoutTransactionEntityDraft<${unit}>>`,
  );
};

const checkoutTransactionPreviewFinishedC = <Unit extends MeroUnits.Any>(
  unit: Unit,
): t.Type<CheckoutTransactionPreview.Finished<Unit>, JSONable> => {
  return t.exact(
    t.intersection(
      [
        t.type(
          {
            _id: CheckoutTransactionIdC,
            status: t.literal('Finished'),
            unit: unitC(unit),
            code: CheckoutTransactionCodeC,
            createdAt: tt.DateFromISOString,
            finishedAt: tt.DateFromISOString,
            total: checkoutTotalsC(unit),
            paymentTypes: t.array(CheckoutTransactionPaymentTypeC),
            isProtocol: t.boolean,
          },
          '!',
        ),
        t.partial(
          {
            client: CheckoutClientPreviewC,
          },
          '?',
        ),
      ],
      `CheckoutTransactionEntityFinished<${unit}>`,
    ),
    `Exact<CheckoutTransactionEntityFinished<${unit}>>`,
  );
};

const checkoutTransactionPreviewDeletedC = <Unit extends MeroUnits.Any>(
  unit: Unit,
): t.Type<CheckoutTransactionPreview.Deleted<Unit>, JSONable> => {
  return t.exact(
    t.intersection(
      [
        t.type(
          {
            _id: CheckoutTransactionIdC,
            status: t.literal('Deleted'),
            unit: unitC(unit),
            code: CheckoutTransactionCodeC,
            createdAt: tt.DateFromISOString,
            finishedAt: tt.DateFromISOString,
            deletedAt: tt.DateFromISOString,
            total: checkoutTotalsC(unit),
            paymentTypes: t.array(CheckoutTransactionPaymentTypeC),
            isProtocol: t.boolean,
          },
          '!',
        ),
        t.partial(
          {
            client: CheckoutClientPreviewC,
          },
          '?',
        ),
      ],
      `CheckoutTransactionEntityDeleted<${unit}>`,
    ),
    `Exact<CheckoutTransactionEntityDeleted<${unit}>>`,
  );
};

export const checkoutTransactionPreviewC = <Unit extends MeroUnits.Any>(
  unit: Unit,
): t.Type<CheckoutTransactionPreview.Of<Unit>, JSONable> => {
  return t.union(
    [
      checkoutTransactionPreviewDraftC(unit),
      checkoutTransactionPreviewFinishedC(unit),
      checkoutTransactionPreviewDeletedC(unit),
    ],
    `CheckoutTransactionPreview<${unit}>`,
  );
};

export const CheckoutTransactionPreviewC: t.Type<CheckoutTransactionPreview.Any, JSONable> = t.union(
  [checkoutTransactionPreviewC(MeroUnits.RON.code), checkoutTransactionPreviewC(MeroUnits.EUR.code)],
  'CheckoutTransactionPreview.Any',
);

export const CheckoutTransactionPreviewArrayC = t.array(CheckoutTransactionPreviewC);

export const CheckoutTransactionPreviewDraftC: t.Type<
  CheckoutTransactionPreview.Draft<MeroUnits.RON> | CheckoutTransactionPreview.Draft<MeroUnits.EUR>,
  JSONable
> = t.union(
  [checkoutTransactionPreviewDraftC(MeroUnits.RON.code), checkoutTransactionPreviewDraftC(MeroUnits.EUR.code)],
  'CheckoutTransactionPreview.Draft',
);

export const CheckoutTransactionPreviewDraftArrayC = t.array(CheckoutTransactionPreviewDraftC);

export const CheckoutTransactionPreviewFinishedC: t.Type<
  CheckoutTransactionPreview.Finished<MeroUnits.RON> | CheckoutTransactionPreview.Finished<MeroUnits.EUR>,
  JSONable
> = t.union(
  [checkoutTransactionPreviewFinishedC(MeroUnits.RON.code), checkoutTransactionPreviewFinishedC(MeroUnits.EUR.code)],
  'CheckoutTransactionPreview.Finished',
);

export const CheckoutTransactionPreviewDeletedC: t.Type<
  CheckoutTransactionPreview.Deleted<MeroUnits.RON> | CheckoutTransactionPreview.Deleted<MeroUnits.EUR>,
  JSONable
> = t.union(
  [checkoutTransactionPreviewDeletedC(MeroUnits.RON.code), checkoutTransactionPreviewDeletedC(MeroUnits.EUR.code)],
  'CheckoutTransactionPreview.Deleted',
);

export const CheckoutTransactionPreviewFinishedArrayC = t.array(CheckoutTransactionPreviewFinishedC);

export const CheckoutTransactionPreviewSubmitedArrayC = t.array(
  t.union([CheckoutTransactionPreviewFinishedC, CheckoutTransactionPreviewDeletedC]),
);

export const GetPurchasedMembershipsAvailableForTransactionRequestC = t.strict(
  {
    page: HasId.json(PageId),
    client: HasId.json(ClientId),
    items: tt.nonEmptyArray(
      t.union(
        [
          t.type(
            {
              type: t.literal('Booking'),
              items: t.array(
                t.type(
                  {
                    type: t.literal('Service'),
                    service: HasId.json(ServiceId),
                    quantity: PositiveInt.JSON,
                    saleOwner: optionull(HasId.json(UserId)),
                  },
                  'BookingService',
                ),
                'BookingItems',
              ),
            },
            'BookingTransactionItem',
          ),
          t.type(
            {
              type: t.literal('Service'),
              service: HasId.json(ServiceId),
              quantity: PositiveInt.JSON,
              saleOwner: optionull(HasId.json(UserId)),
            },
            'ServiceTransactionItem',
          ),
        ],
        'TransactionItem',
      ),
      'TransactionItems',
    ),
  },
  `GetPurchasedMembershipsAvailableForTransactionRequestC`,
);

export const GetPurchasedMembershipsAvailableForTransactionRequest2C = t.strict(
  {
    client: HasId.json(ClientId),
    items: tt.nonEmptyArray(
      t.type(
        {
          type: t.literal('Service'),
          service: HasId.json(ServiceId),
          quantity: PositiveInt.JSON,
        },
        'ServiceTransactionItem',
      ),
      'TransactionItems',
    ),
    appointments: Option.json(
      tt.nonEmptyArray(
        t.type(
          {
            _id: AppointmentId,
            occurrenceIndex: t.number,
          },
          'Appointment',
        ),
      ),
    ),
    membershipPurchaseIds: Option.json(tt.nonEmptyArray(MembershipPurchaseId.JSON)),
  },
  `GetPurchasedMembershipsAvailableForTransactionRequest2C`,
);
