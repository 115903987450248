import { ServiceGroup } from './group';
import { MergedService } from './merged-service';
import * as t from 'io-ts';

export const GroupWithMergedServices = t.type(
  {
    group: ServiceGroup,
    services: t.array(MergedService),
  },
  'GroupWithMergedServices',
);
export interface GroupWithMergedServices extends t.TypeOf<typeof GroupWithMergedServices> {}

export const GroupedMergedServices = t.type(
  {
    grouped: t.array(GroupWithMergedServices),
    others: t.array(MergedService),
  },
  'GroupedMergedServices',
);
export interface GroupedMergedServices extends t.TypeOf<typeof GroupedMergedServices> {}
