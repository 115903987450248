import { HiddenPriceType, FixedPriceType, RangePriceType } from './price-type';
import { Interval, isDefined, optionull } from '@mero/shared-sdk';
import * as t from 'io-ts';

const optionalNumber = optionull(t.number);

/**
 * Hidden (or unknown) prices is a price that is not shown to user.
 *
 * Technically it's a RangePrice with `from` set to 0 and no `to` value [0, +Infinity).
 */
export const HiddenPrice = t.intersection(
  [
    t.type({
      type: HiddenPriceType,
    }),
    t.partial({
      fixed: optionalNumber,
      range: t.partial({
        from: optionalNumber,
        to: optionalNumber,
      }),
    }),
  ],
  'HiddenPrice',
);
export interface HiddenPrice extends t.TypeOf<typeof HiddenPrice> {}

const HiddenPriceS: HiddenPrice = {
  type: 'hidden',
};

const hiddenPrice = {
  sum: (a: HiddenPrice, b: HiddenPrice): HiddenPrice => HiddenPriceS,
  /**
   * Compares two hidden prices.
   *
   * Always return true, as both are "hidden", but in some case it may not be the case, use with caution.
   */
  add: (a: HiddenPrice, b: Price): Price => {
    switch (b.type) {
      case 'fixed':
        return fixedPrice.addHidden(b, a);
      case 'range':
        return rangePrice.addHidden(b, a);
      case 'hidden':
        return hiddenPrice.sum(a, b);
    }
  },
  equals: (a: HiddenPrice, b: HiddenPrice): boolean => true,
};

export const FixedPrice = t.intersection(
  [
    t.type({
      type: FixedPriceType,
      fixed: t.number,
    }),
    t.partial({
      promo: optionalNumber,
      range: t.partial({
        from: optionalNumber,
        to: optionalNumber,
      }),
    }),
  ],
  'FixedPrice',
);
export interface FixedPrice extends t.TypeOf<typeof FixedPrice> {}

export const Zero: FixedPrice = {
  type: 'fixed',
  fixed: 0,
};

const fixedPrice = {
  sum: (a: FixedPrice, b: FixedPrice): FixedPrice => ({
    type: 'fixed',
    fixed: a.fixed + b.fixed,
    ...((a.promo || b.promo) && {
      promo: (a.promo ?? a.fixed) + (b.promo ?? b.fixed),
    }),
  }),
  addHidden: (a: FixedPrice, b: HiddenPrice): Price => {
    return {
      type: 'range',
      range: {
        from: a.fixed,
      },
      ...(isDefined(a.promo) && { rangePromo: { from: a.promo } }),
    };
  },
  add: (a: FixedPrice, b: Price): Price => {
    switch (b.type) {
      case 'fixed':
        return fixedPrice.sum(a, b);
      case 'range':
        return rangePrice.addFixed(b, a);
      case 'hidden':
        return fixedPrice.addHidden(a, b);
    }
  },
  equals: (a: FixedPrice, b: FixedPrice): boolean => a.fixed === b.fixed && a.promo === b.promo,
};

export const RangePrice = t.intersection(
  [
    t.type({
      type: RangePriceType,
      range: t.partial({
        from: t.number,
        to: t.number,
      }),
    }),
    t.partial({
      /**
       * A range price may also have a promotion range, ex. price 10-20 with promo 8-15.
       */
      rangePromo: t.partial({
        from: t.number,
        to: t.number,
      }),
      fixed: optionalNumber,
    }),
  ],
  'RangePrice',
);
export interface RangePrice extends t.TypeOf<typeof RangePrice> {}

export const priceInterval = {
  /**
   * Sum two price intervals
   */
  sum: (a: Interval, b: Interval): Interval => {
    const from: number | undefined = isDefined(a.from)
      ? isDefined(b.from)
        ? a.from + b.from
        : a.from
      : isDefined(b.from)
      ? b.from
      : undefined;

    const to: number | undefined = isDefined(a.to) && isDefined(b.to) ? a.to + b.to : undefined;

    return {
      ...(isDefined(from) && { from }),
      ...(isDefined(to) && { to }),
    };
  },
  /**
   * Add fixed price value to an interval
   */
  addFixed: (a: Interval, b: number): Interval => ({
    ...(isDefined(a.from) ? { from: a.from + b } : { from: b }),
    ...(isDefined(a.to) && { to: a.to + b }),
  }),
  fixDirection: (a: Interval): Interval => {
    if (isDefined(a.from) && isDefined(a.to)) {
      if (a.from > a.to) {
        return {
          from: a.to,
          to: a.from,
        };
      }
    }

    return a;
  },
  merge: (a: Interval, b: Interval): Interval => {
    const aa = priceInterval.fixDirection(a);
    const bb = priceInterval.fixDirection(b);

    return {
      ...(isDefined(aa.from) && isDefined(bb.from) && { from: Math.min(aa.from, bb.from) }),
      ...(isDefined(aa.to) && isDefined(bb.to) && { to: Math.max(aa.to, bb.to) }),
    };
  },
  /**
   * Merge interval with single value
   * Technically it's the same ass merge with an interval with the same value, but without some extra allocations
   */
  mergeFixed: (a: Interval, b: number): Interval => {
    const aa = priceInterval.fixDirection(a);

    return {
      ...(isDefined(aa.from) && { from: Math.min(aa.from, b) }),
      ...(isDefined(aa.to) && { to: Math.max(aa.to, b) }),
    };
  },
  equals: (a: Interval, b: Interval): boolean => a.from === b.from && a.to === b.to,
};

const rangePrice = {
  sum: (a: RangePrice, b: RangePrice): RangePrice => ({
    type: 'range',
    range: priceInterval.sum(a.range, b.range),
    ...((isDefined(a.rangePromo) || isDefined(b.rangePromo)) && {
      rangePromo: priceInterval.sum(a.rangePromo ?? a.range, b.rangePromo ?? b.range),
    }),
  }),
  addFixed: (a: RangePrice, b: FixedPrice): RangePrice => {
    const range = a.range;
    const rangePromo = a.rangePromo;

    return {
      type: 'range',
      range: priceInterval.addFixed(range, b.fixed),
      ...((isDefined(b.promo) || isDefined(rangePromo?.from) || isDefined(rangePromo?.to)) && {
        rangePromo: priceInterval.addFixed(
          { from: rangePromo?.from ?? range.from, to: rangePromo?.to ?? range.to },
          b.promo ?? b.fixed,
        ),
      }),
    };
  },
  addHidden: (a: RangePrice, b: HiddenPrice): Price => {
    return {
      type: 'range',
      range: {
        from: a.range.from,
      },
      ...(isDefined(a.rangePromo) && { rangePromo: { from: a.rangePromo.from } }),
    };
    return HiddenPriceS;
  },
  add: (a: RangePrice, b: Price): Price => {
    switch (b.type) {
      case 'fixed':
        return rangePrice.addFixed(a, b);
      case 'range':
        return rangePrice.sum(a, b);
      case 'hidden':
        return rangePrice.addHidden(a, b);
    }
  },
  equals: (a: RangePrice, b: RangePrice): boolean =>
    priceInterval.equals(a.range, b.range) &&
    ((isDefined(a.rangePromo) && isDefined(b.rangePromo) && priceInterval.equals(a.rangePromo, b.rangePromo)) ||
      a.rangePromo === b.rangePromo),
};

export const Price = t.union([HiddenPrice, FixedPrice, RangePrice], 'Price');
export type Price = t.TypeOf<typeof Price>;

export const price = {
  /**
   * Sum 2 prices
   */
  sum: (left: Price, right: Price): Price => {
    switch (left.type) {
      case 'fixed':
        return fixedPrice.add(left, right);
      case 'range':
        return rangePrice.add(left, right);
      case 'hidden':
        return hiddenPrice.add(left, right);
    }
  },
  /**
   * Returns true if price is fixed with value 0
   */
  isZero: (price: Price): boolean => price.type === Zero.type && price.fixed === Zero.fixed,
  /**
   * Compare 2 prices and return true if price fields are the same
   * - It ignores price extra fields (ex. fixed field for range price type)
   *
   * WARNING: it returns true for two hidden prices, in some case it may not be the expected behavior
   */
  equals: (a: Price, b: Price): boolean => {
    switch (a.type) {
      case 'fixed': {
        return a.type === b.type ? fixedPrice.equals(a, b) : false;
      }
      case 'range': {
        return a.type === b.type ? rangePrice.equals(a, b) : false;
      }
      case 'hidden': {
        return a.type === b.type ? hiddenPrice.equals(a, b) : false;
      }
    }
  },
  /**
   * Merge 2 prices into one, as for ar group of products/services (ex. price for a service performed by different employees)
   */
  merge: (a: Price, b: Price): Price => {
    switch (a.type) {
      case 'fixed': {
        switch (b.type) {
          case 'fixed': {
            if (fixedPrice.equals(a, b)) {
              return a;
            } else {
              return {
                type: 'range',
                range: {
                  from: Math.min(a.fixed, b.fixed),
                  to: Math.max(a.fixed, b.fixed),
                },
                ...((isDefined(a.promo) || isDefined(b.promo)) && {
                  rangePromo: {
                    from: Math.min(a.promo ?? a.fixed, b.promo ?? b.fixed),
                    to: Math.max(a.promo ?? a.fixed, b.promo ?? b.fixed),
                  },
                }),
              };
            }
          }
          case 'range': {
            return price.merge(b, a);
          }
          case 'hidden': {
            return HiddenPriceS;
          }
        }
      }
      case 'range': {
        switch (b.type) {
          case 'fixed': {
            const range = priceInterval.mergeFixed(a.range, b.fixed);
            const rangePromo =
              isDefined(b.promo) || isDefined(a.rangePromo?.from) || isDefined(a.rangePromo?.to)
                ? priceInterval.mergeFixed(a.rangePromo ?? a.range, b.promo ?? b.fixed)
                : undefined;

            return {
              type: 'range',
              range,
              ...(rangePromo && !priceInterval.equals(range, rangePromo) && { rangePromo }),
            };
          }
          case 'range': {
            const range = priceInterval.merge(a.range, b.range);
            const rangePromo =
              isDefined(a.rangePromo?.from) ||
              isDefined(a.rangePromo?.to) ||
              isDefined(b.rangePromo?.from) ||
              isDefined(b.rangePromo?.to)
                ? priceInterval.merge(a.rangePromo ?? a.range, b.rangePromo ?? b.range)
                : undefined;

            return {
              type: 'range',
              range,
              ...(rangePromo && !priceInterval.equals(range, rangePromo) && { rangePromo }),
            };
          }
          case 'hidden': {
            return HiddenPriceS;
          }
        }
      }
      case 'hidden': {
        return HiddenPriceS;
      }
    }
  },
};
