import { Latitude } from './geo/latitude';
import { Longitude } from './geo/longitude';
import * as t from 'io-ts';

export const Location = t.intersection(
  [
    t.type({
      city: t.string,
      address: t.string,
      long: Longitude.JSON,
      lat: Latitude.JSON,
    }),
    t.partial({
      extraInfo: t.string,
      district: t.string,
      area: t.string,
    }),
  ],
  'Location',
);

export type Location = t.TypeOf<typeof Location>;
