import * as React from 'react';
import Svg, { Path, G } from 'react-native-svg';

type Props = {
  size?: string | number;
  color?: string;
  disabled?: boolean;
  onPress?: () => void;
};

const DEFAULT_COLOR = '#2dce89';

const Lock: React.FC<Props> = ({ size = 24, color = DEFAULT_COLOR, disabled = false, onPress }) => (
  <Svg width={size} height={size} viewBox="0 0 24 24" disabled={disabled} onPress={onPress}>
    <G data-name="Group 6197">
      <G data-name="Group 6198">
        <Path
          d="M16.25 10.875h-.281V7.219a4.219 4.219 0 10-8.437 0v3.656H7.25A2.25 2.25 0 005 13.125v5.625A2.25 2.25 0 007.25 21h9a2.25 2.25 0 002.25-2.25v-5.625a2.25 2.25 0 00-2.25-2.25zM8.656 7.219a3.094 3.094 0 116.188 0v3.656H8.656zm3.656 10.125a.563.563 0 01-1.125 0v-2.813a.563.563 0 111.125 0z"
          fill={color}
        />
        <Path data-name="Rectangle 2476" fill="none" d="M0 0h24v24H0z" />
      </G>
    </G>
  </Svg>
);

export default Lock;
