import * as React from 'react';
import Svg, { Path } from 'react-native-svg';

type Props = {
  size?: number | string;
  color?: string;
  disabled?: boolean;
  onPress?: () => void;
};

const ProMenuLocation = ({ size = 32, color = '#000000', disabled, onPress }: Props) => (
  <Svg width={size} height={size} disabled={disabled} onPress={onPress}>
    <Path data-name="Path 9097" d="M0 0h32v32H0Z" fill="none" />
    <Path
      data-name="Path 9098"
      d="M16 16a2 2 0 1 1 2-2 2.006 2.006 0 0 1-2 2Zm6-1.8a6 6 0 1 0-12 0c0 2.34 1.95 5.44 6 9.14 4.05-3.7 6-6.8 6-9.14ZM16 6a7.955 7.955 0 0 1 8 8.2q0 4.98-8 11.8-7.995-6.825-8-11.8A7.955 7.955 0 0 1 16 6Z"
      fill={color}
    />
  </Svg>
);

export default React.memo(ProMenuLocation);
