import { PortionPercent } from '@mero/shared-sdk';

/**
 * Company is a VAT payer
 */
export type VatPayer<Num> = {
  readonly type: 'VatPayer';
  /**
   * Default VAT rate for this company (usually default rate for the country)
   */
  readonly defaultRate: PortionPercent<Num>;
  /**
   * VAT ID of the company
   */
  readonly vatId: string;
};

export const payer = <Num>(defaultRate: PortionPercent<Num>, vatId: string): VatPayer<Num> => ({
  type: 'VatPayer',
  defaultRate,
  vatId: vatId,
});
