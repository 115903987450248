import { JSONable, MeroUnits, Money, ScaledNumber } from '@mero/shared-sdk';
import * as t from 'io-ts';

export type CheckoutPaymentMethodReportItem<Unit extends MeroUnits.Any> = {
  /**
   * Cash
   */
  readonly cash: Money<ScaledNumber, Unit>;

  /**
   * Card
   */
  readonly card: Money<ScaledNumber, Unit>;

  /**
   * Bank transfer
   */
  readonly bankTransfer: Money<ScaledNumber, Unit>;
};

const json = <Unit extends MeroUnits.Any>(unit: Unit): t.Type<CheckoutPaymentMethodReportItem<Unit>, JSONable> => {
  return t.type(
    {
      cash: Money.json(ScaledNumber.JSON, t.literal(unit)),
      card: Money.json(ScaledNumber.JSON, t.literal(unit)),
      bankTransfer: Money.json(ScaledNumber.JSON, t.literal(unit)),
    },
    `CheckoutPaymentMethodReportItem<${unit}>`,
  );
};

export const CheckoutPaymentMethodReportItem = {
  json,
};
