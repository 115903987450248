import { ServiceGroupId } from './serviceGroupId';
import * as t from 'io-ts';

export const ServiceGroup = t.type(
  {
    _id: ServiceGroupId,
    name: t.string,
  },
  'ServiceGroup',
);

export interface ServiceGroup extends t.TypeOf<typeof ServiceGroup> {}
