import { JSONable } from '@mero/shared-sdk';
import * as t from 'io-ts';

export type FeatureFlagsMp = {
  readonly barberStore?: {
    readonly enabled: boolean;
  };
  readonly authenticationWithFacebook?: {
    readonly enabled: boolean;
  };
  readonly notificationsRefresh?:
    | {
        readonly enabled: true;
        readonly value: number;
      }
    | {
        readonly enabled: false;
      };
  readonly showServiceSearch?:
    | {
        readonly enabled: true;
        readonly limit: number;
      }
    | {
        readonly enabled: false;
      };
  readonly showServiceCategories?:
    | {
        readonly enabled: true;
        readonly limit: number;
      }
    | {
        readonly enabled: false;
      };
};

export const FeatureFlagsMp: t.Type<FeatureFlagsMp, JSONable> = t.partial(
  {
    barberStore: t.type(
      {
        enabled: t.boolean,
      },
      'BarberStore',
    ),
    authenticationWithFacebook: t.type(
      {
        enabled: t.boolean,
      },
      'AuthenticationWithFacebook',
    ),
    notificationsRefresh: t.union(
      [
        t.type(
          {
            enabled: t.literal(true),
            value: t.number,
          },
          'Enabled',
        ),
        t.type(
          {
            enabled: t.literal(false),
          },
          'Disabled',
        ),
      ],
      'NotificationsRefresh',
    ),
    showServiceSearch: t.union([
      t.type(
        {
          enabled: t.literal(true),
          limit: t.number,
        },
        'Enabled',
      ),
      t.type(
        {
          enabled: t.literal(false),
        },
        'Disable',
      ),
    ]),
    showServiceCategories: t.union([
      t.type(
        {
          enabled: t.literal(true),
          limit: t.number,
        },
        'Enabled',
      ),
      t.type(
        {
          enabled: t.literal(false),
        },
        'Disabled',
      ),
    ]),
  },
  'FeatureFlagsMp',
);
