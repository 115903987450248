import * as React from 'react';
import { StyleProp, TouchableOpacity, View, ViewStyle } from 'react-native';

import { colors } from '../../styles';
import CloseIcon from '../Icon/Close';
import SmallBody from '../Text/SmallBody';
import { styles } from './styles';

type Props = {
  text: string;
  onClose?: () => void;
  containerStyle?: StyleProp<ViewStyle>;
};

const Tooltip: React.FC<Props> = ({ text, onClose, containerStyle }: Props) => {
  return (
    <View style={[styles.container, containerStyle]}>
      <SmallBody style={styles.text}>{text}</SmallBody>
      <TouchableOpacity style={styles.button} onPress={onClose}>
        <CloseIcon color={colors.WHITE} disabled={true} size={32} />
      </TouchableOpacity>
    </View>
  );
};

export default Tooltip;
