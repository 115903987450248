import * as t from 'io-ts';
import { NumberFromString } from 'io-ts-types';

export const ReviewScore = t.union(
  [t.literal(1), t.literal(2), t.literal(3), t.literal(4), t.literal(5)],
  'ReviewScore',
);

export type ReviewScore = t.TypeOf<typeof ReviewScore>;

export const ReviewScoreFromString = NumberFromString.pipe(ReviewScore);
