import { AutomaticBoostDefaultValues } from './automaticBoostDefaultValues';
import { AutomaticBoostStatus } from './automaticBoostStatus';
import { BoostApi } from './boostApi';
import { BoostCommissionPercent } from './boostCommissionPercent';
import { HttpClient, unsafeRight, UnknownApiError, Money, ScaledNumber, MeroUnits } from '@mero/shared-sdk';
import * as t from 'io-ts';

/**
 * BoostApi HTTP client implementation
 */
export const boostHttpClient = (env: { apiBaseUrl: string; http: HttpClient }): BoostApi => {
  const { apiBaseUrl, http } = env;

  const createPromotionIntervalDecoder = http.decode.response(UnknownApiError, t.unknown);
  const disableAutomaticReactivationDecoder = http.decode.response(UnknownApiError, t.unknown);
  const getAutomaticBoostStatusDecoder = http.decode.response(UnknownApiError, AutomaticBoostStatus.JSON);
  const getAutomaticBoostDefaultValuesDecoder = http.decode.response(UnknownApiError, AutomaticBoostDefaultValues.JSON);

  return {
    enableAutomaticBoost: async ({ pageId, interval, budget, acceptedTerms }) => {
      const requestBody: {
        interval: { from: Date };
        budget: Money<ScaledNumber, MeroUnits.RON>;
        acceptedTerms: {
          commissionPercent: BoostCommissionPercent;
          minCommissionValue: Money<ScaledNumber, MeroUnits.RON>;
        };
      } = { interval, budget, acceptedTerms };
      unsafeRight(
        await http.request(
          {
            method: 'POST',
            url: `${apiBaseUrl}/boost/page/${encodeURIComponent(pageId)}/boost/enable-automatic-boost`,
            data: requestBody,
          },
          createPromotionIntervalDecoder,
        ),
      );
    },
    disableAutomaticReactivation: async (pageId) => {
      unsafeRight(
        await http.request(
          {
            method: 'PUT',
            url: `${apiBaseUrl}/boost/page/${encodeURIComponent(pageId)}/boost/disable-reactivation`,
          },
          disableAutomaticReactivationDecoder,
        ),
      );
    },
    getAutomaticBoostStatus: async (pageId) => {
      return unsafeRight(
        await http.request(
          {
            method: 'GET',
            url: `${apiBaseUrl}/boost/page/${encodeURIComponent(pageId)}/boost/status`,
          },
          getAutomaticBoostStatusDecoder,
        ),
      );
    },
    getBoostDefaultValues: async () => {
      return unsafeRight(
        await http.request(
          {
            method: 'GET',
            url: `${apiBaseUrl}/boost/default-values`,
          },
          getAutomaticBoostDefaultValuesDecoder,
        ),
      );
    },
  };
};
