import { IApiConfig } from '.';
import axios from 'axios';
import { ServerException } from 'exceptional.js';

const urljoin = require('url-join');

export async function request({
  config,
  method,
  path,
  query = {},
  body = {},
}: {
  config: IApiConfig;
  method: 'GET' | 'POST' | 'PUT' | 'DELETE';
  path: string;
  query?: any;
  body?: any;
}) {
  const url = urljoin('api', config.version, path);
  try {
    const result = await axios(url, {
      method,
      baseURL: config.hostname,
      data: {
        ...body,
      },
      params: {
        ...query,
      },
    });

    if (result.status !== 200) {
      try {
        throw new ServerException(result.status, result.data);
      } catch (error) {
        throw result;
      }
    }

    return result.data;
  } catch (error) {
    try {
      throw new ServerException(500, {
        code: 0,
        namespace: 'default',
        payload: {
          message: 'API SDK crashed',
        },
      });
    } catch (error2) {
      throw error;
    }
  }
}

export async function requestWithAuth({
  config,
  method,
  path,
  query = {},
  body = {},
}: {
  config: IApiConfig;
  method: 'GET' | 'POST' | 'PUT' | 'DELETE';
  path: string;
  query?: any;
  body?: any;
}) {
  const url = urljoin('api', config.version, path);
  try {
    const result = await axios(url, {
      method,
      baseURL: config.hostname,
      headers: {
        Authorization: `Bearer ${config.accessToken}`,
      },
      data: {
        ...body,
      },
      params: {
        ...query,
      },
    });

    if (result.status !== 200) {
      try {
        throw new ServerException(result.status, result.data);
      } catch (error) {
        throw result;
      }
    }

    return result.data;
  } catch (error) {
    try {
      throw new ServerException(500, {
        code: 0,
        namespace: 'default',
        payload: {
          message: 'API SDK crashed',
        },
      });
    } catch (error2) {
      throw error;
    }
  }
}
