import * as t from 'io-ts';

/**
 * permission to publish on timeline
 * @deprecated
 */
export const MarketingPageRole = t.literal(0x01, 'MarketingPageRole');
/**
 * @deprecated
 */
export type MarketingPageRole = t.TypeOf<typeof MarketingPageRole>;

/**
 * permission to add appointments
 * @deprecated
 */
export const FrontOfficePageRole = t.literal(0x03, 'FrontOfficePageRole');
/**
 * @deprecated
 */
export type FrontOfficePageRole = t.TypeOf<typeof FrontOfficePageRole>;

/**
 * page worker, does not include other types of permissions
 * @deprecated
 */
export const WorkerPageRole = t.literal(0x04, 'WorkerPageRole');
/**
 * @deprecated
 */
export type WorkerPageRole = t.TypeOf<typeof WorkerPageRole>;

/**
 * page admin (able to view analytics and modify settings)
 * @deprecated
 */
export const ManagerPageRole = t.literal(0x07, 'ManagerPageRole');
/**
 * @deprecated
 */
export type ManagerPageRole = t.TypeOf<typeof ManagerPageRole>;

/**
 * workplace owner
 * @deprecated
 */
export const RootPageRole = t.literal(0xff, 'RootPageRole');
/**
 * @deprecated
 */
export type RootPageRole = t.TypeOf<typeof RootPageRole>;

/**
 * @deprecated
 */
export const PageRole = t.union(
  [MarketingPageRole, FrontOfficePageRole, WorkerPageRole, ManagerPageRole, RootPageRole],
  'PageRole',
);
/**
 * @deprecated
 */
export type PageRole = t.TypeOf<typeof PageRole>;
