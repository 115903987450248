import { ServiceId } from '../services';
import { HasId, MeroUnits, Money, PositiveInt, ScaledNumber } from '@mero/shared-sdk';

export type ServiceItem<Unit extends MeroUnits.Any> = {
  readonly type: 'Service';
  readonly service: HasId<ServiceId>;
  /**
   * This is the sellingPrice for all units, SHOULD NOT be multiplied by quantity to get the total sellingPrice
   */
  readonly sellingPrice: Money<ScaledNumber, Unit>;
  readonly quantity:
    | {
        readonly type: 'Limited';
        readonly value: PositiveInt;
      }
    | {
        readonly type: 'Unlimited';
      };
};

export type ProductItem = {
  readonly type: 'Product';
};

/**
 * Item used in membership template and purchased membership
 */
export type MembershipItem<Unit extends MeroUnits.Any> = ServiceItem<Unit> | ProductItem;

//TODO: remove unit after product type is implemented
const getSellingPrice = <Unit extends MeroUnits.Any>(
  item: MembershipItem<Unit>,
  unit: Unit,
): Money<ScaledNumber, Unit> => {
  switch (item.type) {
    case 'Service': {
      return item.sellingPrice;
    }
    case 'Product': {
      return Money.of(ScaledNumber.zero(), unit);
    }
  }
};

export const MembershipItem = { getSellingPrice };
