import { DEFAULT_NAMESPACE } from '../namespaces';
import { IErrorTable } from 'exceptional.js';

export const TABLE: IErrorTable = {
  namespace: DEFAULT_NAMESPACE,
  locale: 'ro',
  errors: {
    0: 'S-a intamplat ceva neprevazut',
    1: 'Aceasta metoda a API-ului ${apiVersion} nu a fost gasita.',
    2: 'Unknown API version',
    3: '${message}',
    4: 'Nu esti autorizat sa accesezi aceasta resursa.',
    5: 'Ai incercat sa accesezi aceasta metoda de prea multe ori. Incearca din nou peste un minut.',
  },
};
