import { Reply } from '@mero/api-sdk';
import * as React from 'react';
import { View } from 'react-native';

import { styles as text } from '../../styles/text';
import Avatar from '../Avatar';
import Icon from '../Icon';
import Column from '../Layout/Column';
import Row from '../Layout/Row';
import ReadMore from '../ReadMore';
import Spacer from '../Spacer';
import Body from '../Text/Body';
import SmallBody from '../Text/SmallBody';

type Props = {
  firstname: string;
  lastname: string;
  image?: string;
  score: number;
  review?: string;
  replies?: Reply[];
};

const Review = ({ firstname, lastname, image, score, review, replies = [] }: Props): React.ReactElement => {
  return (
    <Row>
      <View>
        <Avatar size={32} firstname={firstname} lastname={lastname} source={image} />
      </View>
      <Column style={{ marginLeft: 12 }} flex={1}>
        <Body style={text.semibold}>
          {firstname} {lastname}
        </Body>
        <Row alignItems="center">
          {Array(5)
            .fill('')
            .map((_, i) => (
              <View key={i} style={{ marginRight: 2 }}>
                <Icon
                  type="star"
                  size="tiny"
                  backgroundColor={i <= score - 1 ? '#FBAC40' : undefined}
                  color="#FBAC40"
                />
              </View>
            ))}
          <SmallBody style={{ paddingLeft: 8 }}>{score} din 5</SmallBody>
        </Row>
        <Spacer size="xxs" />
        {!!review && (
          <ReadMore numberOfLines={3}>
            <SmallBody style={text.hint}>{review}</SmallBody>
          </ReadMore>
        )}
        {replies.map((reply, index) => (
          <Row key={`${index}-${reply.message}`} style={{ paddingTop: 16 }}>
            <View>
              <Avatar
                size={32}
                firstname={reply.user.firstname ?? ''}
                lastname={reply.user.lastname ?? ''}
                source={image}
              />
            </View>
            <Column style={{ marginLeft: 12 }} flex={1}>
              <Body style={[text.semibold, { paddingBottom: 2 }]}>
                {reply.user.firstname} {reply.user.lastname}
              </Body>
              <ReadMore numberOfLines={3}>
                <SmallBody style={text.hint}>{reply.message}</SmallBody>
              </ReadMore>
            </Column>
          </Row>
        ))}
      </Column>
    </Row>
  );
};

export default Review;
