import { DefinedTrimedString, JSONable } from '@mero/shared-sdk';
import * as t from 'io-ts';

export type RoRegistrationNumber = DefinedTrimedString;

const JSON: t.Type<RoRegistrationNumber, JSONable> = DefinedTrimedString;

export const RoRegistrationNumber = {
  JSON,
};
