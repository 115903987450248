import * as React from 'react';
import Svg, { Path } from 'react-native-svg';

const MerLoading: React.FC = () => {
  return (
    <Svg width="55.662" height="42.88" viewBox="0 0 55.662 42.88">
      <Path
        id="Path_8485"
        data-name="Path 8485"
        d="M41.745,35.486c0,6.34-5.232,7.675-5.232,7.675H55.662s-5.232-1.335-5.232-7.675V7.956c0-6.34,5.232-7.675,5.232-7.675h-13.9l-12.5,26.5L14.014.281H1.527A11.933,11.933,0,0,1,7.714,10.792V33.484A9.5,9.5,0,0,1,0,43.161H16.394a9.517,9.517,0,0,1-7.6-9.677V8.844l16.63,28.6L41.761,2.828Z"
        transform="translate(0 -0.281)"
        fill="#e9ecef"
      />
    </Svg>
  );
};

export default MerLoading;
