import { JSONable } from '@mero/shared-sdk';
import * as t from 'io-ts';
import * as tt from 'io-ts-types';

export type RoIBANish = tt.NonEmptyString;

const JSON: t.Type<RoIBANish, JSONable> = tt.NonEmptyString;

export const RoIBANish = {
  JSON,
};
