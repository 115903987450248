import { HasOptionalFirstLastName } from '../common';
import { UserId } from '../users/user-id';
import { HasClientId } from './client-id';
import { SavedClient } from './saved-client';
import { PhoneNumber, Nullean, optionull } from '@mero/shared-sdk';
import { ProfileImage } from '@mero/shared-sdk/dist/assets';
import * as t from 'io-ts';

export const ClientPreview = t.intersection(
  [
    HasClientId,
    HasOptionalFirstLastName,
    t.type({
      userId: UserId,
    }),
    t.partial({
      phone: optionull(PhoneNumber),
      photo: optionull(ProfileImage),
      isBlocked: Nullean,
      isWarned: Nullean,
      isFavourite: Nullean,
      isBoost: Nullean,
      hideBoostDetails: Nullean,
    }),
  ],
  'ClientPreview',
);
export interface ClientPreview extends t.TypeOf<typeof ClientPreview> {}

export const clientPreview = {
  fromClient: (client: SavedClient): ClientPreview => ({
    _id: client._id,
    userId: client.user._id,
    firstname: client.user.firstname,
    lastname: client.user.lastname,
    phone: client.user.phone,
    photo: client.user.photo,
    isBlocked: client.isBlocked,
    isWarned: client.isWarned,
    isFavourite: client.isFavourite,
    isBoost: client.isBoost ?? false,
    hideBoostDetails: (client.isBoost === true && client.hideBoostDetails) ?? false,
  }),
};
