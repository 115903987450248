import * as t from 'io-ts';

export interface MainCategoryIdBrand {
  readonly MainCategoryId: unique symbol;
}

export const MainCategoryId = t.brand(
  t.string,
  (_id: string): _id is t.Branded<string, MainCategoryIdBrand> => true,
  'MainCategoryId',
);

export type MainCategoryId = t.TypeOf<typeof MainCategoryId>;

export const HasMainCategoryId = t.type(
  {
    id: MainCategoryId,
  },
  'HasMainCategoryId',
);
export interface HasMainCategoryId extends t.TypeOf<typeof HasMainCategoryId> {}
