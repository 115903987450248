import { RecurrenceEndCriteria } from '../../recurrenceEndCriteria';
import { RecurrenceInterval } from '../../recurrenceInterval';
import * as t from 'io-ts';

export type Every2WeeksIndefinite = {
  readonly repeatEvery: RecurrenceInterval.Every2Weeks;
  readonly endsOn: RecurrenceEndCriteria.Indefinite;
};

export const EVERY_2_WEEKS_INDEFINITE: Every2WeeksIndefinite = {
  repeatEvery: RecurrenceInterval.EVERY_2_WEEKS,
  endsOn: RecurrenceEndCriteria.INDEFINITE,
};

const JSON = t.type(
  {
    repeatEvery: RecurrenceInterval.Every2Weeks.JSON,
    endsOn: RecurrenceEndCriteria.Indefinite.JSON,
  },
  'Every2WeeksIndefinite',
);

export const Every2WeeksIndefinite = {
  JSON,
  is: JSON.is,
};
