import * as React from 'react';
import Svg, { G, Path, Ellipse } from 'react-native-svg';

type Props = {
  size?: string | number;
  color?: string;
  disabled?: boolean;
  onPress?: () => void;
};

const AppleSlider = ({ disabled, onPress }: Props) => (
  <Svg width="41.324" height="40.882" viewBox="0 0 41.324 40.882" disabled={disabled} onPress={onPress}>
    <G id="Switch" transform="translate(0 8.324)">
      <Path
        id="Track"
        d="M9.328.054C10.351,0,11.374,0,12.4,0h5.186c1.044,0,2.066,0,3.089.054A12.732,12.732,0,0,1,23.41.472a8.966,8.966,0,0,1,0,17.291,12.769,12.769,0,0,1-2.738.418c-1.023.059-2.045.054-3.069.054H12.416c-1.042,0-2.065,0-3.088-.054a12.769,12.769,0,0,1-2.737-.418,8.966,8.966,0,0,1,0-17.291A12.733,12.733,0,0,1,9.328.054Z"
        transform="translate(0 0)"
        fill="#4cd964"
      />
      <G transform="matrix(1, 0, 0, 1, 0, -8.32)">
        <G
          id="Knob-2"
          data-name="Knob"
          transform="translate(12.94 9.5)"
          fill="#fff"
          stroke="rgba(0,0,0,0.04)"
          strokeMiterlimit="10"
          strokeWidth="0.5"
        >
          <Ellipse cx="7.941" cy="7.941" rx="7.941" ry="7.941" stroke="none" />
          <Ellipse cx="7.941" cy="7.941" rx="8.191" ry="8.191" fill="none" />
        </G>
      </G>
    </G>
  </Svg>
);

export default React.memo(AppleSlider);
