import {
  MeroUnits,
  ScaledNumber,
  Money,
  JSONable,
  AppliedDiscount,
  DiscountPercentScaledNumber,
  AppliedDiscountScaledNumber,
  MeroCurrency,
} from '@mero/shared-sdk';
import * as t from 'io-ts';

export type AppointmentDiscount =
  | {
      readonly type: 'Percent';
      readonly percent: ScaledNumber;
    }
  | {
      readonly type: 'Value';
      readonly value: Money<ScaledNumber, MeroUnits.Any>;
    };

const moneyC = t.union([
  Money.json(ScaledNumber.JSON, MeroUnits.RON.JSON),
  Money.json(ScaledNumber.JSON, MeroUnits.EUR.JSON),
]);

const JSON: t.Type<AppointmentDiscount, JSONable> = t.union(
  [
    t.type(
      {
        type: t.literal('Percent'),
        percent: ScaledNumber.JSON,
      },
      'Percent',
    ),
    t.type(
      {
        type: t.literal('Value'),
        value: moneyC,
      },
      'Value',
    ),
  ],
  'AppointmentDiscount',
);

const toAppliedDiscount = (discount: AppointmentDiscount): AppliedDiscount<ScaledNumber, MeroUnits.Any> => {
  switch (discount.type) {
    case 'Percent': {
      return AppliedDiscount.percent(DiscountPercentScaledNumber.unsafeFrom(discount.percent));
    }
    case 'Value': {
      return discount;
    }
  }
};

const applyDiscount = (
  money: Money<ScaledNumber, MeroUnits.Any>,
  discount: AppointmentDiscount,
): Money<ScaledNumber, MeroUnits.Any> => {
  return AppliedDiscountScaledNumber.applyTo(money, toAppliedDiscount(discount), MeroCurrency[money.unit].exponent);
};

export const AppointmentDiscount = {
  JSON,
  toAppliedDiscount,
  applyDiscount,
};
