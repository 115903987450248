import * as React from 'react';
import Svg, { Path, Rect } from 'react-native-svg';

import { BLACK } from '../../../styles/colors';

type Props = {
  size?: number | string;
  color?: string;
  disabled?: boolean;
  onPress?: () => void;
};

const Close: React.FC<Props> = ({ size = 32, color = BLACK, disabled = false, onPress }: Props) => (
  <Svg width={size} height={size} viewBox="0 0 32 32" disabled={disabled} onPress={onPress}>
    <Rect width="32" height="32" fill="none" />
    <Path
      id="union"
      d="M9.667,20.333V11.667H1a1,1,0,0,1,0-2H9.667V1a1,1,0,1,1,2,0V9.666h8.667a1,1,0,0,1,0,2H11.667v8.666a1,1,0,1,1-2,0Z"
      transform="translate(16 0.915) rotate(45)"
      fill={color}
    />
  </Svg>
);

export default React.memo(Close);
