import { BookedServicePayload } from './bookedServicePayload';
import { JSONable } from '@mero/shared-sdk';
import * as t from 'io-ts';

export type BookedServicesPayload = BookedServicePayload[];

const JSON: t.Type<BookedServicesPayload, JSONable> = t.array(BookedServicePayload.JSON, 'BookedServicesPayload');

export const BookedServicesPayload = {
  JSON: JSON,
};
