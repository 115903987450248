import { UserId } from '../users/user-id';
import * as t from 'io-ts';

export const UserPageRole = t.type(
  {
    userId: UserId,
    /**
     * TODO: use PageRoleId when completely migrated to dynamic permissions
     */
    role: t.number, // PageRole and mixes
  },
  'UserPageRole',
);

export type UserPageRole = t.TypeOf<typeof UserPageRole>;
