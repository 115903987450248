import { Numbers } from './numbers';
import { PortionPercent, PortionPercentModule } from './portionPercent';
import * as t from 'io-ts';

export type PortionPercentNumber = PortionPercent<number>;

export type PortionPercentNumberModule = PortionPercentModule<number> & {
  readonly JSON: t.Type<PortionPercent<number>, number>;
};

const PortionPercentN = PortionPercent.build(Numbers);

export const PortionPercentNumber: PortionPercentNumberModule = {
  ...PortionPercentN,
  JSON: PortionPercentN.json(t.number),
};
