import * as React from 'react';
import Svg, { Path, G, Rect } from 'react-native-svg';

import { COMET } from '../../../styles/colors';

type Props = {
  size?: number | string;
  color?: string;
  disabled?: boolean;
  onPress?: () => void;
};

const ProfilePros = ({ size = 32, color = COMET, disabled, onPress }: Props) => (
  <Svg width={size} height={size} viewBox="0 0 32 32" disabled={disabled} onPress={onPress}>
    <G transform="translate(-25 -309)">
      <Rect width="32" height="32" transform="translate(25 309)" fill="none" />
      <G transform="translate(29 313)">
        <G>
          <Rect width="24" height="24" fill="none" />
        </G>
        <G>
          <G>
            <Path
              d="M21.9,8.89,20.85,4.52A1.964,1.964,0,0,0,18.94,3H5.05a1.964,1.964,0,0,0-1.9,1.52L2.1,8.89a3.375,3.375,0,0,0,.62,2.88,3.834,3.834,0,0,0,.28.29V19a2.006,2.006,0,0,0,2,2H19a2.006,2.006,0,0,0,2-2V12.06a3.7,3.7,0,0,0,.28-.28A3.364,3.364,0,0,0,21.9,8.89Zm-2.99-3.9,1.05,4.37a1.356,1.356,0,0,1-.25,1.17,1.173,1.173,0,0,1-.94.47,1.25,1.25,0,0,1-1.21-1.14L16.98,5ZM13,5h1.96l.54,4.52a1.344,1.344,0,0,1-.33,1.07,1.193,1.193,0,0,1-.95.41A1.272,1.272,0,0,1,13,9.69ZM8.49,9.52,9.04,5H11V9.69A1.285,1.285,0,0,1,9.71,11a1.2,1.2,0,0,1-.89-.41A1.421,1.421,0,0,1,8.49,9.52ZM4.04,9.36,5.05,5H7.02L6.44,9.86A1.257,1.257,0,0,1,5.23,11a1.151,1.151,0,0,1-.93-.47A1.322,1.322,0,0,1,4.04,9.36ZM5,19V12.97a1.81,1.81,0,0,0,.23.03,3.129,3.129,0,0,0,2.24-.95A3.216,3.216,0,0,0,9.78,13a3.17,3.17,0,0,0,2.23-.93A3.278,3.278,0,0,0,14.3,13a3.175,3.175,0,0,0,2.24-.95,3.129,3.129,0,0,0,2.24.95,1.81,1.81,0,0,0,.23-.03V19Z"
              fill={color}
            />
          </G>
        </G>
      </G>
    </G>
  </Svg>
);

export default React.memo(ProfilePros);
