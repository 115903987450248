import { Option, PortionPercent } from '@mero/shared-sdk';

/**
 * VAT is NOT included in the price, value in percent will be added to total
 */
export type Excluded<Num> = {
  readonly type: 'Excluded';
  /**
   * Custom VAT rate for the item, company VAt rate is used if not specified
   */
  readonly rate: Option<PortionPercent<Num>>;
};

export const excluded = <Num>(rate: Option<PortionPercent<Num>>): Excluded<Num> => {
  return {
    type: 'Excluded',
    rate: rate,
  };
};
