import { ObjectId } from '@mero/shared-sdk';
import * as t from 'io-ts';

export interface OrderIdBrand {
  readonly OrderId: unique symbol;
}

export const OrderId = t.brand(ObjectId, (_id: ObjectId): _id is t.Branded<ObjectId, OrderIdBrand> => true, 'OrderId');

export type OrderId = t.TypeOf<typeof OrderId>;

export const HasOrderId = t.type(
  {
    _id: OrderId,
  },
  'HasOrderId',
);
export interface HasOrderId extends t.TypeOf<typeof HasOrderId> {}
