import { JSONable } from '../../common';
import * as t from 'io-ts';

export type EUR = 'EUR';

export const EUR: MeroUnitModule<EUR> = {
  code: 'EUR',
  JSON: t.literal('EUR'),
  is: (unit: string): unit is EUR => unit === EUR.code,
};

export type RON = 'RON';

export const RON: MeroUnitModule<RON> = {
  code: 'RON',
  JSON: t.literal('RON'),
  is: (unit: string): unit is RON => unit === RON.code,
};

/**
 * Mero supported currency units
 */
export type Any = EUR | RON;

export type MeroUnitModule<Unit extends Any> = {
  /**
   * Currency code
   */
  readonly code: Unit;
  /**
   * JSON codec for the unit
   */
  readonly JSON: t.Type<Unit>;
  /**
   * Check if {@link unit} is of the given {@link Unit} type
   */
  readonly is: (unit: string) => unit is Unit;
};

export const JSON = t.union([EUR.JSON, RON.JSON]);

export const is = (unit: string): unit is Any => JSON.is(unit);

export const equals = <A extends Any>(a: A, b: Any): b is A => a === b;
