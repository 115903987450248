import * as React from 'react';
import Svg, { Path, Rect, G } from 'react-native-svg';

import { BLACK } from '../../../styles/colors';

type Props = {
  size?: string | number;
  color?: string;
  disabled?: boolean;
  onPress?: () => void;
};

const NotificationsMenu: React.FC<Props> = ({ size = 24, color = BLACK, disabled = false, onPress }) => (
  <Svg width={size} height={size} viewBox="0 0 24 24" disabled={disabled} onPress={onPress}>
    <G transform="translate(-319.234 -406.032)">
      <Rect width="24" height="24" transform="translate(319.234 406.032)" fill="none" />
      <Path
        d="M322.331,426.736a2.881,2.881,0,0,1-2.862-2.815v-.895A2.652,2.652,0,0,1,321,420.649a.942.942,0,0,0,.547-.842v-4.34a9.695,9.695,0,0,1,19.371-.02v4.363a.942.942,0,0,0,.546.839A2.648,2.648,0,0,1,343,423.018v.883a2.891,2.891,0,0,1-2.882,2.835H322.331Zm8.74-18.855a7.81,7.81,0,0,0-7.824,7.606v4.328a2.651,2.651,0,0,1-1.534,2.378.948.948,0,0,0-.545.843v.865a1.171,1.171,0,0,0,.366.808,1.2,1.2,0,0,0,.844.327h17.691a1.191,1.191,0,0,0,1.23-1.154v-.856a.94.94,0,0,0-.546-.833,2.649,2.649,0,0,1-1.533-2.37v-4.356a7.8,7.8,0,0,0-7.787-7.586C331.311,407.881,331.191,407.88,331.071,407.881Zm9.848,11.934h0Z"
        fill={color}
      />
      <Path
        d="M331.3,429.714h-.119a3.4,3.4,0,0,1-3.39-3.312v-.516a.849.849,0,0,1,.85-.85h5.2a.849.849,0,0,1,.85.85v.5A3.4,3.4,0,0,1,331.3,429.714Zm-.06-1.7c.02,0,.039,0,.059,0a1.7,1.7,0,0,0,1.641-1.279h-3.4a1.65,1.65,0,0,0,1.683,1.279Z"
        fill={color}
      />
    </G>
  </Svg>
);

export default React.memo(NotificationsMenu);
