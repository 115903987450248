import * as React from 'react';
import { TextProps, Text } from 'react-native';

import { styles } from '../../styles/text';

export type H1Props = React.PropsWithChildren<TextProps>;

const H1: React.FC<H1Props> = (props: H1Props) => (
  // This style of settings props avoids generation of _rest helper function in js output
  <Text accessibilityRole="header" aria-level="1" {...props} style={[styles.h1, props.style]}>
    {props.children}
  </Text>
);

export default H1;
