import * as React from 'react';
import Svg, { G, Path } from 'react-native-svg';

import { BLACK } from '../../../styles/colors';

type Props = {
  size?: string | number;
  color?: string;
  disabled?: boolean;
  onPress?: () => void;
};

const SearchMenu: React.FC<Props> = ({ size = 22.8, color = BLACK, disabled = false, onPress }: Props) => (
  <Svg width={size} height={size} viewBox={`0 0 ${size} ${size}`} disabled={disabled} onPress={onPress}>
    <G transform="translate(-2.1 -3.144)">
      <Path
        d="M14.572,14.571a8.536,8.536,0,1,1,2.5-6.035,8.51,8.51,0,0,1-2.5,6.035L20.958,21Z"
        transform="translate(3 4.044)"
        fill="none"
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit="10"
        strokeWidth="1.8"
      />
    </G>
  </Svg>
);

export default React.memo(SearchMenu);
