import { ObjectId } from '../common';
import * as t from 'io-ts';

export interface FeedbackIdBrand {
  readonly FeedbackId: unique symbol;
}

export const FeedbackId = t.brand(
  ObjectId,
  (_id: ObjectId): _id is t.Branded<ObjectId, FeedbackIdBrand> => true,
  'FeedbackId',
);

export type FeedbackId = t.TypeOf<typeof FeedbackId>;

export const HasFeedbackId = t.type(
  {
    _id: FeedbackId,
  },
  'HasFeedbackId',
);
export interface HasFeedbackId extends t.TypeOf<typeof HasFeedbackId> {}
