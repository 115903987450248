import { ObjectId } from '@mero/shared-sdk';
import * as t from 'io-ts';

export interface InviteIdBrand {
  readonly InviteId: unique symbol;
}

export const InviteId = t.brand(
  ObjectId,
  (_id: ObjectId): _id is t.Branded<ObjectId, InviteIdBrand> => true,
  'InviteId',
);

export type InviteId = t.TypeOf<typeof InviteId>;

export const HasInviteId = t.type(
  {
    _id: InviteId,
  },
  'HasInviteId',
);
export interface HasInviteId extends t.TypeOf<typeof HasInviteId> {}
