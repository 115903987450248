import { Numbers, PositiveInt } from '../../numbers';
import { Indefinite } from './indefinite';
import { OnDate } from './onDate';
import { WhenOccurred } from './whenOccurred';
import * as t from 'io-ts';

export { Indefinite } from './indefinite';
export { OnDate } from './onDate';
export { WhenOccurred } from './whenOccurred';

export type Any = Indefinite | OnDate | WhenOccurred;

export const JSON = t.union([Indefinite.JSON, OnDate.JSON, WhenOccurred.JSON], 'RecurrenceEndCriteria');

export const is = JSON.is;

export const INDEFINITE: Indefinite = {
  /**
   * Some predefined RecurrenceEndCriteria values
   */
  times: Numbers._0,
};

export const onDate = (date: Date): OnDate => {
  return {
    date,
    times: Numbers._0,
  };
};

export const whenOccurred = (times: PositiveInt): WhenOccurred => {
  return {
    times: times,
    date: undefined,
  };
};
