import * as React from 'react';
import Svg, { Path, G, Rect } from 'react-native-svg';

import { COMET } from '../../../styles/colors';

type Props = {
  size?: number | string;
  color?: string;
  disabled?: boolean;
  onPress?: () => void;
};

const ProfileDetails = ({ size = 32, color = COMET, disabled, onPress }: Props) => (
  <Svg width={size} height={size} viewBox="0 0 32 32" disabled={disabled} onPress={onPress}>
    <G transform="translate(-25 -309)">
      <Rect width="32" height="32" transform="translate(25 309)" fill="none" />
      <G transform="translate(29 313)">
        <Path d="M0,0H24V24H0Z" fill="none" />
        <Path
          d="M12,2A10,10,0,1,0,22,12,10,10,0,0,0,12,2ZM7.07,18.28c.43-.9,3.05-1.78,4.93-1.78s4.51.88,4.93,1.78a7.925,7.925,0,0,1-9.86,0Zm11.29-1.45c-1.43-1.74-4.9-2.33-6.36-2.33s-4.93.59-6.36,2.33a8,8,0,1,1,12.72,0ZM12,6a3.5,3.5,0,1,0,3.5,3.5A3.491,3.491,0,0,0,12,6Zm0,5a1.5,1.5,0,1,1,1.5-1.5A1.5,1.5,0,0,1,12,11Z"
          fill={color}
        />
      </G>
    </G>
  </Svg>
);

export default React.memo(ProfileDetails);
