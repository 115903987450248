import { MainCategoryId } from './main-category-id';
import { SubCategoryId } from './sub-category-id';
import * as t from 'io-ts';

export const Category = t.type({
  id: MainCategoryId,
  title: t.string,
  subCategories: t.array(
    t.type({
      id: SubCategoryId,
      title: t.string,
    }),
  ),
});

export type Category = t.TypeOf<typeof Category>;
