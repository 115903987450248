import { SavedPageBillingDetails } from '../savedPageBillingDetails';
import { JSONable, Option } from '@mero/shared-sdk';
import * as t from 'io-ts';

export type GetPageMeroSubscriptionBillingResponse = {
  readonly billingDetails: Option<SavedPageBillingDetails>;
};

const JSON: t.Type<GetPageMeroSubscriptionBillingResponse, JSONable> = t.type(
  {
    billingDetails: Option.json(SavedPageBillingDetails.JSON),
  },
  'GetPageMeroSubscriptionBillingResponse',
);

export const GetPageMeroSubscriptionBillingResponse = {
  JSON,
};
