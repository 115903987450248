import * as React from 'react';
import Svg, { G, Rect, Path, Circle } from 'react-native-svg';

const PromoIcon = () => (
  <Svg width={24} height={24}>
    <G data-name="Group 7487" transform="translate(-297 -390)">
      <Rect width={24} height={24} fill="#f05f7c" rx={4} transform="translate(297 390)" />
      <G fill="none" data-name="Group 7488">
        <Path d="M297 389.999h24v24h-24z" data-name="Rectangle 2603" opacity={0.24} />
        <G
          stroke="#fff"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeMiterlimit={10}
          strokeWidth={1.3}
          transform="translate(301.769 393.163)"
        >
          <Circle cx={2} cy={2} r={2} transform="translate(2.231 3.837)" />
          <Circle cx={2} cy={2} r={2} data-name="layer2" transform="translate(9.231 9.837)" />
          <Path d="m11.753 4.306-8.585 9.3" />
        </G>
      </G>
    </G>
  </Svg>
);

export default PromoIcon;
