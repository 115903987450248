import * as React from 'react';
import Svg, { Path, G } from 'react-native-svg';

import { DARK_BLUE } from '../../styles/colors';

const ASPECT_RATIO = 4.162;

export type Props = {
  color?: string;
  height?: number;
};

const Logo: React.FC<Props> = ({ color = DARK_BLUE, height = 20 }) => {
  const width = height * ASPECT_RATIO;
  return (
    <Svg width={width} height={height} viewBox="0 0 83.244 20">
      <G>
        <Path
          d="M322.4,2.385a9.506,9.506,0,0,0-13.664,1.1,10.171,10.171,0,0,0,1.447,14.022,9.506,9.506,0,0,0,13.663-1.1A10.17,10.17,0,0,0,322.4,2.385Zm-2.652,16.6c-2.759,1.1-6.56-2.019-8.452-7.049S310.074,1.887,312.869.887c2.852-1.021,6.529,2.036,8.422,7.066S322.51,17.887,319.75,18.987Z"
          transform="translate(-242.766 0)"
          fill={color}
        />
        <Path
          d="M234.6,18.143c-1.235,0-2.246-1.123-3.047-2.426-1.116-1.759-2.891-2.019-4.674-2.05,4.574-.164,7.75-2.726,7.75-6.912,0-4.641-3.694-6.466-7.865-6.466h-8.113A2.994,2.994,0,0,1,220.607,3.2V17.15a2.994,2.994,0,0,1-1.956,2.911h7.909a2.994,2.994,0,0,1-1.956-2.911V13.87h.142c1.985,0,3,3,3.319,3.591,1.15,2.144,2.61,2.767,3.983,2.767A4.515,4.515,0,0,0,236.28,17.2,1.621,1.621,0,0,1,234.6,18.143Zm-9.57-4.786h-.425V.8h.493c2.611,0,5.511,2.366,5.511,6.274C230.609,10.445,228.031,13.357,225.03,13.357Z"
          transform="translate(-173.141 -0.228)"
          fill={color}
        />
        <Path
          d="M126.774,20.054a3.4,3.4,0,0,0,2.4-3.389v-13a3.4,3.4,0,0,0-2.4-3.389h16.2V6.744c0-2.006-1.212-5.95-9.806-5.95V9.6c5.16,0,5.16-3.112,5.16-3.112v7.041s0-3.417-5.16-3.417v6.859a2.428,2.428,0,0,0,2.542,2.565c4.853,0,7.263-3.73,7.263-6.335v6.848h-16.2Z"
          transform="translate(-100.387 -0.221)"
          fill={color}
        />
        <Path
          d="M19.25,16.515a3.478,3.478,0,0,1-2.412,3.539h8.829a3.478,3.478,0,0,1-2.412-3.539V3.82A3.478,3.478,0,0,1,25.667.281h-6.41L13.492,12.5,6.462.281H.7A5.5,5.5,0,0,1,3.557,5.128V15.592A4.381,4.381,0,0,1,0,20.054H7.56a4.389,4.389,0,0,1-3.5-4.462V4.23l7.669,13.186,7.533-15.96Z"
          transform="translate(0 -0.221)"
          fill={color}
        />
      </G>
    </Svg>
  );
};

export default Logo;
