import { Any } from './any';
import { BankTransfer } from './bankTransfer';
import { Card } from './card';
import { Cash } from './cash';
import { Giftcard } from './giftcard';
import { Membership } from './membership';
import { Online } from './online';
import { MeroUnits } from '@mero/shared-sdk';

export * from './any';
export * from './bankTransfer';
export * from './card';
export * from './cash';
export * from './giftcard';
export * from './membership';
export * from './online';
export * from './onlinePaymentSource';

export const isBankTransfer = <Num, Unit extends MeroUnits.Any>(
  payment: Any<Num, Unit>,
): payment is BankTransfer<Num, Unit> => {
  return payment.type === 'BankTransfer';
};

export const isCard = <Num, Unit extends MeroUnits.Any>(payment: Any<Num, Unit>): payment is Card<Num, Unit> => {
  return payment.type === 'Card';
};

export const isCash = <Num, Unit extends MeroUnits.Any>(payment: Any<Num, Unit>): payment is Cash<Num, Unit> => {
  return payment.type === 'Cash';
};

export const isGiftcard = <Num, Unit extends MeroUnits.Any>(
  payment: Any<Num, Unit>,
): payment is Giftcard<Num, Unit> => {
  return payment.type === 'Giftcard';
};

export const isMembership = <Num, Unit extends MeroUnits.Any>(
  payment: Any<Num, Unit>,
): payment is Membership<Num, Unit> => {
  return payment.type === 'Membership';
};

export const isOnline = <Num, Unit extends MeroUnits.Any>(payment: Any<Num, Unit>): payment is Online<Num, Unit> => {
  return payment.type === 'Online';
};
