import { ObjectId } from '@mero/shared-sdk';
import * as t from 'io-ts';

export interface AppointmentHistoryRecordIdBrand {
  readonly AppointmentHistoryRecordId: unique symbol;
}

export const AppointmentHistoryRecordId = t.brand(
  ObjectId,
  (_id: ObjectId): _id is t.Branded<ObjectId, AppointmentHistoryRecordIdBrand> => true,
  'AppointmentHistoryRecordId',
);

export type AppointmentHistoryRecordId = t.TypeOf<typeof AppointmentHistoryRecordId>;

export const HasAppointmentHistoryRecordId = t.type(
  {
    _id: AppointmentHistoryRecordId,
  },
  'HasAppointmentHistoryRecordId',
);
export interface HasAppointmentHistoryRecordId extends t.TypeOf<typeof HasAppointmentHistoryRecordId> {}
