import { JSONable } from '@mero/shared-sdk';
import * as t from 'io-ts';

export type Home = {
  readonly type: 'Home';
};

const JSON: t.Type<Home, JSONable> = t.type(
  {
    type: t.literal('Home'),
  },
  'Home',
);

export const Home = {
  JSON,
};
