import * as t from 'io-ts';

export const CalendarSlotsInterval = t.union(
  [
    /**
     * Calendar slots will be generated as selected service length, with no overlap between slots or existing appointments
     */
    t.type(
      {
        type: t.literal('ServiceDuration'),
      },
      'ServiceDuration',
    ),
    /**
     * Calendar slots will be generated at given interval with no overlap with existing appointments,
     * Overlap between slots is allowed
     */
    t.type(
      {
        type: t.literal('CustomInterval'),
        intervalInMinutes: t.number,
      },
      'CustomInterval',
    ),
    /**
     * Only 2 slots will be
     */
    t.type(
      {
        type: t.literal('SticksToAppointments'),
      },
      'SticksToAppointments',
    ),
  ],
  'AppointmentBookInterval',
);

/**
 * Calendar slots interval configuration
 */
export type CalendarSlotsInterval = t.TypeOf<typeof CalendarSlotsInterval>;
