import * as t from 'io-ts';

type BarcodeTypeModule<Type extends BarcodeType> = {
  /**
   * Barcode type code
   */
  readonly code: string;
  /**
   * JSON codec for the barcode type
   */
  readonly JSON: t.Type<Type>;
};

export type Code128Type = 'Code128';
export const Code128Type: BarcodeTypeModule<Code128Type> = {
  code: 'Code128',
  JSON: t.literal('Code128'),
};

export type Code39Type = 'Code39';
export const Code39Type: BarcodeTypeModule<Code39Type> = {
  code: 'Code39',
  JSON: t.literal('Code39'),
};

export type UPCType = 'UPC';
export const UPCType: BarcodeTypeModule<UPCType> = {
  code: 'UPC',
  JSON: t.literal('UPC'),
};

export type EANType = 'EAN';
export const EANType: BarcodeTypeModule<EANType> = {
  code: 'EAN',
  JSON: t.literal('EAN'),
};

export type BarcodeType = Code128Type | Code39Type | UPCType | EANType;

const JSON = t.union([EANType.JSON, UPCType.JSON, Code128Type.JSON, Code39Type.JSON]);

export const BarcodeType = {
  JSON,
};
