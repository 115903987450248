import * as React from 'react';
import { StyleProp, View, ViewStyle, StyleSheet, Alert } from 'react-native';

import * as colors from '../../styles/colors';
import Button from '../Button';
import HSpacer from '../HSpacer';
import Body from '../Text/Body';
import { styles } from './styles';

export type ButtonAction = {
  readonly text: string;
  readonly onPress?: () => void;
  readonly flex?: number;
};

export type Props = React.PropsWithChildren<{
  readonly style?: StyleProp<ViewStyle>;
  readonly type: 'info' | 'error';
  readonly text?: string;
  readonly leftAction?: ButtonAction;
  readonly rightAction?: ButtonAction;
  readonly defaultAction?: 'left' | 'right';
}>;

const BottomNotification: React.FC<Props> = ({
  style,
  type,
  text,
  leftAction,
  rightAction,
  defaultAction = 'right',
  children,
}: Props) => {
  return (
    <View
      style={StyleSheet.compose<ViewStyle, ViewStyle, ViewStyle>(
        type === 'error' ? styles.errorContainer : styles.infoContainer,
        style,
      )}
    >
      {text !== undefined && text !== '' ? <Body style={styles.text}>{text}</Body> : null}
      {children}
      {leftAction !== undefined || rightAction !== undefined ? (
        <View style={styles.buttonsRow}>
          {leftAction !== undefined && (
            <>
              <View
                style={StyleSheet.compose<ViewStyle, ViewStyle, ViewStyle>(
                  styles.buttonBox,
                  leftAction?.flex !== undefined ? { flex: leftAction.flex } : {},
                )}
              >
                <Button
                  size="small"
                  text={leftAction.text}
                  backgroundColor={defaultAction === 'left' ? colors.WHITE : 'transparent'}
                  color={defaultAction === 'left' ? colors.DARK_BLUE : colors.WHITE}
                  onClick={leftAction.onPress}
                />
              </View>
              <HSpacer left={6} right={6} />
            </>
          )}
          {rightAction !== undefined && (
            <View
              style={StyleSheet.compose<ViewStyle, ViewStyle, ViewStyle>(
                styles.buttonBox,
                rightAction?.flex !== undefined ? { flex: rightAction.flex } : {},
              )}
            >
              <Button
                size="small"
                text={rightAction.text}
                backgroundColor={defaultAction === 'right' ? colors.WHITE : 'transparent'}
                color={defaultAction === 'right' ? colors.DARK_BLUE : colors.WHITE}
                onClick={rightAction.onPress}
              />
            </View>
          )}
        </View>
      ) : null}
    </View>
  );
};

export default BottomNotification;
