import { RecurrenceRule, RecurrenceInterval, RecurrenceEndCriteria } from '@mero/api-sdk';
import { Price, RangePrice } from '@mero/api-sdk/dist/services';
import { DateTime } from 'luxon';
import * as unorm from 'unorm';

export { formatTimeDiff } from '@mero/shared-components';

/**
 * Returns formatted price text
 */
export const formatPriceText = (price: Price, discounts = 0): string | undefined => {
  switch (price.type) {
    case 'fixed':
      const priceValue = (price.promo ?? price.fixed) - discounts;
      return `${priceValue > 0 ? priceValue : 0} lei`;
    case 'range':
      return `${price.range.from} - ${price.range.to} lei`;
    case 'hidden':
      return undefined;
  }
};

export const formatRecurrenceInterval = (interval: RecurrenceInterval.Any): string => {
  if (interval.unit === 'day' || interval.unit === 'days') {
    return interval.value > 1 ? `la fiecare ${interval.value} zile` : 'zilnic';
  } else if (interval.unit === 'week' || interval.unit === 'weeks') {
    return interval.value > 1 ? `la fiecare ${interval.value} săptămâni` : 'săptămânal';
  } else if (interval.unit === 'month' || interval.unit === 'months') {
    return interval.value > 1 ? `la fiecare ${interval.value} luni` : 'lunar';
  } else if (interval.unit === 'year' || interval.unit === 'years') {
    return interval.value > 1 ? `la fiecare ${interval.value} ani` : 'anual';
  } else {
    return `la fiecare ${interval.value} ${interval.unit}`;
  }
};

export const formatRecurrenceEndCriteria = (endsOn: RecurrenceEndCriteria.Any): string | undefined => {
  if (RecurrenceEndCriteria.Indefinite.is(endsOn)) {
    return undefined;
  } else if (RecurrenceEndCriteria.OnDate.is(endsOn)) {
    return `până la ${DateTime.fromJSDate(endsOn.date).toFormat('d LLL yyyy', { locale: 'ro' })}`;
  } else {
    return `de ${endsOn.times} ori`;
  }
};

export const formatRecurrenceRule = (rule: RecurrenceRule.Any | undefined): string => {
  if (rule) {
    const endsOnStr = formatRecurrenceEndCriteria(rule.endsOn);
    return `Se repetă ${formatRecurrenceInterval(rule.repeatEvery)}${endsOnStr ? `, ${endsOnStr}` : ''}`;
  } else {
    return 'Nu se repetă';
  }
};

/**
 * Formats given duration in minutes
 */
export const formatDurationInMinutes = (minutes: number, short = false): string => {
  const mUnit = short ? 'm' : ' min';
  const hUnit = short ? 'h' : ' h';
  if (minutes < 60) {
    return `${minutes}${mUnit}`;
  } else {
    const h = Math.floor(minutes / 60);
    const m = minutes - h * 60;

    return `${h}${hUnit}${m > 0 ? ` ${m}${mUnit}` : ''}`;
  }
};

/**
 * Removes special language characters, ex: ă -> a
 */

export const normalize = (s: string): string =>
  unorm
    .nfkd(s) // String.normalize('NFKD') crashes on Android
    .replace(/[^\w\s]/g, '')
    .trim()
    .toLowerCase();

export const formatRangePrice = (price: RangePrice['range']): string =>
  price.from === price.to ? (price.from ?? 'preț variabil').toString() : `${price.from}-${price.to}`;
