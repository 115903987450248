import { HttpClient, UnknownApiError, unsafeRight } from '@mero/shared-sdk';
import * as t from 'io-ts';

export interface IntegrationsApi {
  /**
   * Send a Facebook Pixel server side event.
   */
  readonly sendFbPixelEvent: (params: {
    pixelId: string;
    eventName: 'Search' | 'ViewContent' | 'InitiateCheckout' | 'Contact' | 'Schedule';
    actionSource: 'website' | 'other';
    eventSource?: string; // fill in with 'window.location.href'
  }) => Promise<unknown>;

  /**
   * Send a Tiktok Pixel server side event.
   */
  readonly sendTiktokPixelEvent: (params: {
    pixelCode: string;
    eventName: 'Search' | 'ViewContent' | 'InitiateCheckout' | 'Contact' | 'PlaceAnOrder';
    pageUrl: string; // fill in with 'window.location.href'
    ttclid?: string | undefined;
  }) => Promise<unknown>;
}

export const integrationsHttpClient = (env: { apiBaseUrl: string; http: HttpClient }): IntegrationsApi => {
  const { apiBaseUrl, http } = env;

  const sendFbPixelEventDecoder = http.decode.response(UnknownApiError, t.unknown);
  const sendTiktokPixelEventDecoder = http.decode.response(UnknownApiError, t.unknown);

  return {
    sendFbPixelEvent: async ({ pixelId, eventName, actionSource, eventSource }) =>
      unsafeRight(
        await http.request(
          {
            method: 'POST',
            url: `${apiBaseUrl}/integrations/facebook-pixel/${pixelId}/event`,
            data: {
              eventName,
              actionSource,
              eventSource,
            },
            withCredentials: true,
          },
          sendFbPixelEventDecoder,
        ),
      ),
    sendTiktokPixelEvent: async ({ pixelCode, eventName, pageUrl, ttclid }) =>
      unsafeRight(
        await http.request(
          {
            method: 'POST',
            url: `${apiBaseUrl}/integrations/tiktok/${pixelCode}/event`,
            data: {
              eventName,
              pageUrl,
              ttclid,
            },
            withCredentials: true,
          },
          sendTiktokPixelEventDecoder,
        ),
      ),
  };
};
