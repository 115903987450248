import { sleep } from './sleep';

type RetryResultDone<T> = {
  readonly type: 'done';
  /**
   * The result of the function
   */
  readonly result: T;
};

/**
 * Build new RetryResultDone<T> value
 */
export const retryDone = <T>(result: T): RetryResultDone<T> => ({
  type: 'done',
  result,
});

type RetryResultRetry<T> = {
  readonly type: 'retry';
  /**
   * The result of the function for cases when max retries is reached
   */
  readonly orElse: () => T | Promise<T>;
};

export const tryAgain = <T>(orElse: () => T | Promise<T>): RetryResultRetry<T> => ({
  type: 'retry',
  orElse,
});

export type RetryResult<T> = RetryResultDone<T> | RetryResultRetry<T>;

export type RetryOptions = {
  /**
   * Maximum number of retries
   * defaults to 5 {@link DEFAULT_MAX_RETRIES}
   */
  readonly retries?: number;
  /**
   * Minimum timeout between retries
   * defaults to 100 {@link DEFAULT_MIN_TIMEOUT}
   */
  readonly minTimeout?: number;
  /**
   * Maximum timeout between retries
   */
  readonly maxTimeout?: number;
  /**
   * The exponential factor to use.
   */
  readonly factor?: number | undefined;
  /**
   * Whether to retry on error
   */
  readonly retryOnError?: boolean;
};

const DEFAULT_MAX_RETRIES = 5;
const DEFAULT_MIN_TIMEOUT = 100;

/**
 * Retry a function until it returns a non-retry result or the max retries is reached
 * Max retries is 3 by default
 */
export const retry = async <T>(
  fn: (attempt: number) => RetryResult<T> | Promise<RetryResult<T>>,
  options?: RetryOptions,
): Promise<T> => {
  const retries = Math.max(0, options?.retries ?? DEFAULT_MAX_RETRIES);
  const minTimeout = options?.minTimeout ?? DEFAULT_MIN_TIMEOUT;
  const maxTimeout = options?.maxTimeout ?? Infinity;
  const factor = options?.factor ?? 2;
  const retryOnError = options?.retryOnError ?? true;

  // Retry on error if retryOnError is true
  const task = retryOnError
    ? async (attempt: number): Promise<RetryResult<T>> => {
        try {
          return await fn(attempt);
        } catch (e: unknown) {
          return {
            type: 'retry',
            orElse: (): never => {
              throw e;
            },
          };
        }
      }
    : fn;

  const first: RetryResult<T> = await task(0);

  if (first.type === 'done') {
    return first.result;
  } else {
    let attempt = 0;
    let last = first;
    while (attempt < retries) {
      await sleep(Math.min(maxTimeout, Math.pow(factor, attempt) * minTimeout));
      const current = await task(attempt + 1);

      if (current.type === 'done') {
        return current.result;
      } else {
        last = current;
      }

      attempt = attempt + 1;
    }

    return last.orElse();
  }
};
