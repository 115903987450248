import { StyleSheet } from 'react-native';

export const styles = StyleSheet.create({
  container: {
    width: 48,
    height: 48,
    backgroundColor: '#F2F2FE',
    borderRadius: 24,
    justifyContent: 'center',
    alignItems: 'center',
  },
});
