import { JSONable } from '@mero/shared-sdk';
import * as t from 'io-ts';

export type AppointmentBoostStatus =
  | {
      readonly isBoostClient: false;
    }
  | {
      readonly isBoostClient: true;
      readonly isBoostAppointment: false;
    }
  | {
      readonly isBoostClient: true;
      readonly isBoostAppointment: true;
    };

const JSON: t.Type<AppointmentBoostStatus, JSONable> = t.union(
  [
    t.type(
      {
        isBoostClient: t.literal(false),
      },
      'NonBoost',
    ),
    t.type(
      {
        isBoostClient: t.literal(true),
        isBoostAppointment: t.literal(false),
      },
      'BoostClient',
    ),
    t.type(
      {
        isBoostClient: t.literal(true),
        isBoostAppointment: t.literal(true),
      },
      'BoostAppointment',
    ),
  ],
  'CalendarEntry.AppointmentBoostStatus',
);

export const AppointmentBoostStatus = {
  JSON,
};
