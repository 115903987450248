import * as React from 'react';

import { useSimpleReducer } from './useSimpleReducer';

const DEFAULT_SHOW_AFTER = 500;
const DEFAULT_SHOW_TIME = 1000;

type Props = {
  showAfter?: number;
  keepOnScreen?: number;
};

type UseAdvancedLoading = (
  initialState?: boolean,
  config?: Props,
) => [isLoading: boolean, setLoadingState: (arg: boolean) => void, shouldShow: boolean];

export const useAdvancedLoading: UseAdvancedLoading = (
  initialState = false,
  { showAfter = DEFAULT_SHOW_AFTER, keepOnScreen = DEFAULT_SHOW_TIME } = {},
) => {
  const [state, updateState] = useSimpleReducer({
    shouldShow: initialState,
    isLoading: false,
    minimLoading: false,
  });
  const timer = React.useRef<{ show: number | undefined; hide: number | undefined }>({
    show: undefined,
    hide: undefined,
  }).current;

  const { isLoading, minimLoading, shouldShow } = state;

  console.log(`[${new Date().getTime()}] Loading screen status:`, { isLoading, shouldShow, minimLoading, timer });

  const setLoadingState = (shouldShow: boolean) => {
    updateState({ shouldShow });
  };

  React.useEffect(() => {
    if (shouldShow) {
      window.clearTimeout(timer.hide);
      timer.show = window.setTimeout(() => {
        updateState({
          isLoading: true,
          minimLoading: false,
        });
      }, showAfter);
    } else {
      window.clearTimeout(timer.show);
      if (isLoading) {
        if (minimLoading) {
          updateState({
            isLoading: false,
            minimLoading: false,
          });
        } else {
          timer.hide = window.setTimeout(() => {
            updateState({
              minimLoading: true,
            });
          }, keepOnScreen);
        }
      }
    }
  }, [shouldShow, minimLoading]);

  React.useEffect(() => {
    return () => {
      window.clearTimeout(timer.show);
      window.clearTimeout(timer.hide);
    };
  }, []);

  return [isLoading, setLoadingState, shouldShow];
};

export default useAdvancedLoading;
