import { AppointmentId } from '../calendar/appointment-id';
import { PageId } from '../pages/page-id';
import { GiftCardId } from './gift-card-id';
import * as t from 'io-ts';
import { DateFromISOString } from 'io-ts-types';

export const GiftCard = t.intersection(
  [
    t.type({
      _id: GiftCardId,
      createdAt: DateFromISOString,
      expiresAt: DateFromISOString,
      code: t.string, // 6 letters
      displayCode: t.string,
      price: t.number,
      paid: t.boolean,
      used: t.boolean,
      downloadUrl: t.string,
    }),
    t.partial({
      pageId: PageId,
      usedAt: t.type({
        appointmentId: AppointmentId,
        appointmentEnd: DateFromISOString,
        pageId: PageId,
        amountToPay: t.number,
        commission: t.number,
      }),
    }),
  ],
  'GiftCard',
);

export type GiftCard = t.TypeOf<typeof GiftCard>;
