import * as t from 'io-ts';

export interface CheckoutTransactionCodeBrand {
  readonly CheckoutTransactionCode: unique symbol;
}

export type CheckoutTransactionCode = t.Branded<string, CheckoutTransactionCodeBrand>;

/**
 * Checkout trransaction code is a unique (per page) string with format LLL-NNN
 * Later it may be longer, if conflicts arise but minimum should stau at 7 characters
 */
const is = (s: string): s is CheckoutTransactionCode => s.length >= 7 && s.trim().length >= 7;

export const CheckoutTransactionCode = {
  is,
};
