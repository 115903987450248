import { MembershipPurchaseId } from './membershipPurchaseId';
import { PurchasedMembershipTemplate } from './purchasedMembershipTemplate';
import { PurchasedMembershipValidity } from './purchasedMembershipValidity';
import { MeroUnits, Money, ScaledNumber } from '@mero/shared-sdk';
import {
  OptionalDefinedString,
  OptionalFirstname,
  OptionalLastname,
  StrictPhoneNumber,
  UserId,
} from '@mero/shared-sdk';

export type PurchasedMembership<Unit extends MeroUnits.Any> = {
  readonly _id: MembershipPurchaseId;
  readonly unit: Unit;
  readonly user: {
    readonly _id: UserId;
    readonly phone: StrictPhoneNumber;
    readonly profile: {
      readonly firstname: OptionalFirstname;
      readonly lastname: OptionalLastname;
    };
  };
  readonly purchasedOn: Date;
  readonly template: PurchasedMembershipTemplate<Unit>;
  readonly status: 'Active' | 'Consumed' | 'Cancelled';
  readonly note: OptionalDefinedString;
  readonly debt: Money<ScaledNumber, Unit>;
};

const getMembershipStatus = (params: {
  readonly status: 'Active' | 'Consumed' | 'Cancelled';
  readonly validFor: PurchasedMembershipValidity;
}): 'Active' | 'Expired' | 'Inactive' | 'Cancelled' | 'Consumed' => {
  const { status, validFor } = params;
  if (status !== 'Active') {
    return status;
  }

  const now = new Date();
  if (now < validFor.from) {
    return 'Inactive';
  }

  if (validFor.type === 'Unlimited') {
    return 'Active';
  } else {
    if (now <= validFor.to) {
      return 'Active';
    } else {
      return 'Expired';
    }
  }
};

export const PurchasedMembership = {
  getMembershipStatus,
};
