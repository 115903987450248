import { DefinedString } from './string';
import * as t from 'io-ts';

export interface NumericStringBrand {
  readonly NumericString: unique symbol;
}

export type NumericString = t.Branded<DefinedString, NumericStringBrand>;

/**
 * Decodes a valid numeric string, that contains only decimals, ex: "12445"
 */
const JSON: t.Type<NumericString, string> = t.brand(
  DefinedString,
  (s): s is t.Branded<DefinedString, NumericStringBrand> => /^\d+$/.test(s),
  'NumericString',
);

export const NumericString = {
  JSON,
};
