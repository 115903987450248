import * as React from 'react';
import Svg, { G, Path, Circle } from 'react-native-svg';

type Props = {
  size?: string | number;
  disabled?: boolean;
  onPress?: () => void;
};

const NotificationEmptyScreenIcon = ({ size = 62, disabled = false, onPress }: Props) => (
  <Svg width={size} height={size} viewBox="0 0 62 62" disabled={disabled} onPress={onPress}>
    <G transform="translate(-1 -1)">
      <Path
        d="M51.834,22A12.073,12.073,0,0,1,52,24V50A12,12,0,0,1,40,62H14A12,12,0,0,1,2,50V24A12,12,0,0,1,14,12H40a12.061,12.061,0,0,1,2,.166"
        fill="#bacae9"
      />
      <Path
        d="M51.834,22,42,12.166A12.061,12.061,0,0,0,40,12H38.837A10,10,0,0,0,52,25.163V24a12.073,12.073,0,0,0-.166-2Z"
        opacity="0.15"
      />
      <Circle cx="10" cy="10" r="10" transform="translate(42 2)" fill="#f66" />
      <Path
        d="M51.834,22A12.073,12.073,0,0,1,52,24V50A12,12,0,0,1,40,62H14A12,12,0,0,1,2,50V24A12,12,0,0,1,14,12H40a12.061,12.061,0,0,1,2,.166"
        fill="none"
        stroke="#3b3f5c"
        stroke-linecap="round"
        stroke-linejoin="round"
        stroke-width="2"
      />
      <Circle
        cx="10"
        cy="10"
        r="10"
        transform="translate(42 2)"
        fill="none"
        stroke="#3b3f5c"
        stroke-linecap="round"
        stroke-linejoin="round"
        stroke-width="2"
      />
    </G>
  </Svg>
);

export default React.memo(NotificationEmptyScreenIcon);
