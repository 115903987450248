import { Option, PortionPercent } from '@mero/shared-sdk';

/**
 * VAT is included in price
 */
export type Included<Num> = {
  readonly type: 'Included';
  /**
   * Custom VAT rate for the item, company VAt rate is used if not specified
   */
  readonly rate: Option<PortionPercent<Num>>;
};

export const included = <Num>(rate: Option<PortionPercent<Num>>): Included<Num> => {
  return {
    type: 'Included',
    rate: rate,
  };
};
