import { NumberCalculator } from '../numberCalculator';
import { PositiveInt } from '../positiveInt';
import * as t from 'io-ts';

export const Zero = t.intersection([t.literal(0), t.Int], '0');
export type Zero = t.TypeOf<typeof Zero>;

export const One = t.intersection([t.literal(1), PositiveInt.JSON], '1');
export type One = t.TypeOf<typeof One>;

export const Two = t.intersection([t.literal(2), PositiveInt.JSON], '2');
export type Two = t.TypeOf<typeof Two>;

export const Three = t.intersection([t.literal(3), PositiveInt.JSON], '3');
export type Three = t.TypeOf<typeof Three>;

export const Four = t.intersection([t.literal(4), PositiveInt.JSON], '4');
export type Four = t.TypeOf<typeof Four>;

export const Five = t.intersection([t.literal(5), PositiveInt.JSON], '5');
export type Five = t.TypeOf<typeof Five>;

export const zero = NumberCalculator.zero;

export const add = NumberCalculator.add;

export const sub = NumberCalculator.sub;

export const mul = NumberCalculator.mul;

export const div = NumberCalculator.div;

export const round = NumberCalculator.round;

export const compare = NumberCalculator.compare;

export const isZero = NumberCalculator.isZero;

export const equals = NumberCalculator.equals;

export const lessThan = NumberCalculator.lessThan;

export const lessThanOrEqual = NumberCalculator.lessThanOrEqual;

export const greaterThan = NumberCalculator.greaterThan;

export const greaterThanOrEqual = NumberCalculator.greaterThanOrEqual;

export const toNumber = (a: number): number => {
  return a;
};

export const fromNumber = (a: number, decimals: number): number => {
  return Math.round(a * Math.pow(10, decimals)) / Math.pow(10, decimals);
};

export const _0 = 0 as Zero;
export const _1 = 1 as One;
export const _2 = 2 as Two;
export const _3 = 3 as Three;
export const _4 = 4 as Four;
export const _5 = 5 as Five;
