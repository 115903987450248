import { Money } from '../../money';
import { DiscountPercent, ExtendedCalculator, HasFromNumber } from '../../numbers';
import { MeroCurrency } from '../meroCurrency';
import { MeroUnits } from '../meroUnits';
import { AppliedDiscountPercent } from './appliedDiscountPercent';
import { AppliedDiscountValue } from './appliedDiscountValue';

/**
 * {@link AppliedDiscount} represents a value to be aplied to a price to reduce the total amount
 */
export type AppliedDiscount<Num, Unit extends MeroUnits.Any> =
  | AppliedDiscountPercent<Num>
  | AppliedDiscountValue<Num, Unit>;

export type AppliedDiscountModule<Num> = {
  /**
   * Build new AppliedDiscountValue<Num, Unit>
   */
  readonly value: <Unit extends MeroUnits.Any>(value: Money<Num, Unit>) => AppliedDiscountValue<Num, Unit>;
  /**
   * Build new AppliedDiscountPercent<Num>
   */
  readonly percent: (percent: DiscountPercent<Num>) => AppliedDiscountPercent<Num>;
  /**
   * Convert a {@link AppliedDiscountValue} to a {@link AppliedDiscountPercent} based on given {@link money}
   */
  readonly valueToPercent: <Unit extends MeroUnits.Any>(
    money: Money<Num, Unit>,
    discount: AppliedDiscountValue<Num, Unit>,
    decimals?: number,
  ) => AppliedDiscountPercent<Num>;

  /**
   * Convert a {@link AppliedDiscountPercent} to a {@link AppliedDiscountValue} based on given {@link money}
   */
  readonly percentToValue: <Unit extends MeroUnits.Any>(
    money: Money<Num, Unit>,
    discount: AppliedDiscountPercent<Num>,
    decimals?: number,
  ) => AppliedDiscountValue<Num, Unit>;

  /**
   * Apply discount to give value {@link money} and return the discounted value, rounding discount to {@link decimals}
   */
  readonly applyTo: <Unit extends MeroUnits.Any>(
    money: Money<Num, Unit>,
    discount: AppliedDiscount<Num, Unit>,
    decimals: number,
  ) => Money<Num, Unit>;

  /**
   * Check if discount value equals to 0 (zero)
   * @returns true if discount is 0% or 0 Unit(s)
   */
  readonly isZero: <Unit extends MeroUnits.Any>(discount: AppliedDiscount<Num, Unit>) => boolean;
};

/**
 * Build new instance of DiscountPercentModule<Num>
 */
const build = <Num>(num: ExtendedCalculator<Num> & HasFromNumber<Num>): AppliedDiscountModule<Num> => {
  const DiscountPercentNum = DiscountPercent.build(num);
  const MoneyNum = Money.build(num, MeroUnits);

  const valueToPercent = <Unit extends MeroUnits.Any>(
    money: Money<Num, Unit>,
    discount: AppliedDiscountValue<Num, Unit>,
    decimals?: number,
  ): AppliedDiscountPercent<Num> => {
    const percent = DiscountPercentNum.unsafeFrom(
      num.div(
        num.mul(discount.value.amount, num.fromNumber(100, 0)),
        money.amount,
        decimals ?? MeroCurrency[money.unit].exponent,
      ),
    );

    return AppliedDiscountPercent.of(percent);
  };

  const percentToValue = <Unit extends MeroUnits.Any>(
    money: Money<Num, Unit>,
    discount: AppliedDiscountPercent<Num>,
    decimals?: number,
  ): AppliedDiscountValue<Num, Unit> => {
    return AppliedDiscountValue.of(
      Money.of(
        DiscountPercentNum.ofValue(money.amount, discount.percent, decimals ?? MeroCurrency[money.unit].exponent),
        money.unit,
      ),
    );
  };

  const applyTo = <Unit extends MeroUnits.Any>(
    money: Money<Num, Unit>,
    discount: AppliedDiscount<Num, Unit>,
    decimals: number,
  ): Money<Num, Unit> => {
    switch (discount.type) {
      case 'Percent': {
        return Money.of(DiscountPercentNum.applyTo(money.amount, discount.percent, decimals), money.unit);
      }
      case 'Value': {
        return MoneyNum.sub(money, discount.value);
      }
    }
  };
  const value = <Unit extends MeroUnits.Any>(value: Money<Num, Unit>): AppliedDiscountValue<Num, Unit> => {
    return AppliedDiscountValue.of(value);
  };

  const percent = (percent: DiscountPercent<Num>): AppliedDiscountPercent<Num> => {
    return AppliedDiscountPercent.of(percent);
  };

  const isZero = <Unit extends MeroUnits.Any>(discount: AppliedDiscount<Num, Unit>): boolean => {
    switch (discount.type) {
      case 'Percent': {
        return DiscountPercentNum.isZero(discount.percent);
      }
      case 'Value': {
        return MoneyNum.isZero(discount.value);
      }
    }
  };

  return {
    valueToPercent,
    percentToValue,
    applyTo,
    value,
    percent,
    isZero,
  };
};

export const AppliedDiscount = {
  percent: AppliedDiscountPercent.of,
  value: AppliedDiscountValue.of,
  build: build,
};
