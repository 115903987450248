import { AppointmentId } from '../../calendar/appointment-id';
import { AppointmentStatus } from '../../calendar/appointment-status';
import { CalendarId } from '../../calendar/calendar-id';
import { CheckoutTotals } from '../checkoutTotals';
import { CheckoutTotalsScaledNumber } from '../checkoutTotalsScaledNumber';
import { CheckoutTransactionPayment } from '../checkoutTransactionPayment';
import { CheckoutWorkerPreview } from '../checkoutWorkerPreview';
import { CompanyVatStatus } from '../companies';
import { Product } from './product';
import { Service } from './service';
import { MeroUnits, ScaledNumber } from '@mero/shared-sdk';

type BookingItem<Unit extends MeroUnits.Any> = Service<Unit> | Product<Unit>;

export type Booking<Unit extends MeroUnits.Any> = {
  readonly type: 'Booking';
  readonly transactionItemId?: string;
  readonly calendarId: CalendarId;
  readonly appointmentId: AppointmentId;
  readonly occurrenceIndex: number | undefined;
  readonly worker: CheckoutWorkerPreview;
  readonly start: Date;
  // end is calculated based on services duration
  readonly status: AppointmentStatus;
  readonly items: BookingItem<Unit>[];
};

export const booking = <Unit extends MeroUnits.Any>(params: {
  transactionItemId?: string;
  calendarId: CalendarId;
  appointmentId: AppointmentId;
  occurrenceIndex?: number;
  worker: CheckoutWorkerPreview;
  start: Date;
  status: AppointmentStatus;
  items: Service<Unit>[];
}): Booking<Unit> => {
  return {
    type: 'Booking',
    transactionItemId: params.transactionItemId,
    calendarId: params.calendarId,
    appointmentId: params.appointmentId,
    occurrenceIndex: params.occurrenceIndex,
    worker: params.worker,
    start: params.start,
    status: params.status,
    items: params.items,
  };
};

const getTotals = <Unit extends MeroUnits.Any>(
  item: Booking<Unit>,
  unit: Unit,
  payments: CheckoutTransactionPayment.Any<ScaledNumber, Unit>[],
  companyVat: CompanyVatStatus.Any<ScaledNumber>,
  decimals: number,
  includeItemsPaidWithMembership = false,
): CheckoutTotals<ScaledNumber, Unit> => {
  return item.items
    .map((item) => {
      switch (item.type) {
        case 'Service': {
          return Service.getTotals(item, unit, payments, companyVat, decimals, includeItemsPaidWithMembership);
        }
        case 'Product': {
          return Product.getTotals(item, unit, payments, companyVat, decimals, includeItemsPaidWithMembership);
        }
      }
    })
    .reduce(
      (totals, itemTotal) => CheckoutTotalsScaledNumber.add(totals, itemTotal),
      CheckoutTotalsScaledNumber.zero(unit),
    );
};

/**
 * Check if given booking item is fully or partially paid with a membership
 */
const isPaidWithMembership = <Unit extends MeroUnits.Any>(
  item: Booking<Unit>,
  unit: Unit,
  payments: CheckoutTransactionPayment.Any<ScaledNumber, Unit>[],
  companyVat: CompanyVatStatus.Any<ScaledNumber>,
  decimals: number,
):
  | {
      readonly isPaidWithMembership: true;
      readonly totals: CheckoutTotals<ScaledNumber, Unit>;
    }
  | {
      readonly isPaidWithMembership: false;
    } => {
  let isBookingPaidWithMembership = false;

  const totals = item.items
    .map((item) => {
      switch (item.type) {
        case 'Service': {
          return Service.isPaidWithMembership(item, unit, payments, companyVat, decimals);
        }
        case 'Product': {
          return Product.isPaidWithMembership(item, unit, payments, companyVat, decimals);
        }
      }
    })
    .reduce((totals, item) => {
      if (item.isPaidWithMembership) {
        isBookingPaidWithMembership = true;
        return CheckoutTotalsScaledNumber.add(totals, item.totals);
      } else {
        return totals;
      }
    }, CheckoutTotalsScaledNumber.zero(unit));

  return isBookingPaidWithMembership
    ? {
        isPaidWithMembership: true,
        totals: totals,
      }
    : {
        isPaidWithMembership: false,
      };
};

export const Booking = {
  getTotals,
  isPaidWithMembership,
};
