import * as A from 'fp-ts/lib/Array';
import * as O from 'fp-ts/lib/Option';
import { pipe } from 'fp-ts/lib/function';
import * as React from 'react';
import { StyleProp, ViewStyle } from 'react-native';
import { View } from 'react-native';

import { COMET, RADICAL_RED, SHAMROCK, YELLOW_ORANGE } from '../../styles/colors';
import Tooltip from '../Tooltip';

type NotificationType = 'info' | 'error' | 'warn' | 'success';

type NotificationProps = {
  text: string;
  type?: NotificationType;
  onClose: () => void;
};

const NotificationColor = {
  error: RADICAL_RED,
  info: COMET,
  warn: YELLOW_ORANGE,
  success: SHAMROCK,
};

const Notification: React.FC<NotificationProps> = ({ text, type = 'info', onClose }: NotificationProps) => (
  <View
    style={{
      paddingTop: 2,
      paddingRight: 0,
      paddingBottom: 2,
      paddingLeft: 0,
    }}
  >
    <Tooltip text={text} containerStyle={{ backgroundColor: NotificationColor[type] }} onClose={onClose} />
  </View>
);

type Notification = {
  type: NotificationType;
  text: string;
};

type ExpiringNotification = Notification & {
  expiresAt: Date;
};

type NotificationsStackProps = {
  defaultNotifications?: Notification[];
  /**
   * Seconds to autodismiss notifications
   */
  autoDismissIn?: number;
  containerStyle?: StyleProp<ViewStyle>;
};

export type NotificationsStackHandle = {
  push: (notification: Notification) => void;
  clear: () => void;
};

const NotificationsStack = React.forwardRef(function NotificationsStack(
  { defaultNotifications, autoDismissIn, containerStyle }: NotificationsStackProps,
  ref: React.ForwardedRef<NotificationsStackHandle>,
) {
  const renderTs = new Date();
  const getExpiration = (ts: Date): Date => new Date(ts.getTime() + (autoDismissIn ?? 0) * 1000);

  const [notifications, setNotifications] = React.useState<ExpiringNotification[]>(
    (defaultNotifications ?? []).map((n) => ({ ...n, expiresAt: getExpiration(renderTs) })),
  );

  const removeAt = (pos: number): void => {
    setNotifications(
      pipe(
        notifications,
        A.deleteAt(pos),
        O.getOrElse(() => notifications),
      ),
    );
  };

  // auto-dismiss notifications
  React.useEffect(() => {
    if (autoDismissIn) {
      const effectTs = new Date();

      if (notifications.length > 0) {
        const futureNotifications = notifications.filter((n) => effectTs.getTime() < n.expiresAt.getTime());

        if (futureNotifications.length !== notifications.length) {
          // this will trigger re-render and effect willl run again
          setNotifications(futureNotifications);
        } else {
          const nextExpiresAt = Math.min(...notifications.map((n) => n.expiresAt.getTime()));
          const diff = nextExpiresAt - effectTs.getTime();

          const timeoutId = setTimeout(() => {
            const nowTs = new Date();
            setNotifications(notifications.filter((n) => nowTs.getTime() < n.expiresAt.getTime()));
          }, Math.max(0, diff));

          return () => {
            clearTimeout(timeoutId);
          };
        }
      }
    }
  }, [notifications, setNotifications]);

  React.useImperativeHandle(ref, () => ({
    push: (notification) => {
      setNotifications(notifications.concat([{ ...notification, expiresAt: getExpiration(new Date()) }]));
    },
    clear: () => {
      setNotifications([]);
    },
  }));

  return (
    <View style={[{ flexDirection: 'column' }, containerStyle]}>
      {notifications.map(({ text, type }, idx) => (
        <Notification
          key={idx}
          text={text}
          type={type}
          onClose={() => {
            removeAt(idx);
          }}
        />
      ))}
    </View>
  );
});

export default NotificationsStack;
