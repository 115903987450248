import countryCodesMap from './country-codes-map';
import { DefinedTrimedString, UndefinedString } from './string';
import * as E from 'fp-ts/lib/Either';
import { flow, identity, pipe } from 'fp-ts/lib/function';
import * as t from 'io-ts';

export interface PhoneNumberBrand {
  readonly PhoneNumber: unique symbol;
}

export const PhoneNumber = t.brand(
  DefinedTrimedString,
  (p: string): p is t.Branded<DefinedTrimedString, PhoneNumberBrand> => true, // Validaton disabled because of invalid data: /^\+?\d{4,20}$/.test(p),
  'PhoneNumber',
);

export type PhoneNumber = t.TypeOf<typeof PhoneNumber>;

const COUNTRY_CODES: { [k in string]: string } = countryCodesMap;
const DEFAULT_COUNTRY_CODE = 'ro'; // ROMANIA
const DEFAULT_COUNT_PREFFIX = countryCodesMap[DEFAULT_COUNTRY_CODE];

/**
 * Strips extra symbols, adds default prefix and validates as PhoneNumber
 * @deprecated use [parseStrictPhoneNumber] function
 */
export const PhoneNumberParser = new t.Type<PhoneNumber>(
  'PhoneNumberParser',
  PhoneNumber.is,
  flow(
    DefinedTrimedString.decode,
    E.map((p): string => {
      const plusAndDigits = p.replace(/[^\+\d]/g, '');
      // remove all "+" but startig one
      const clean = plusAndDigits.startsWith('+') ? `+${plusAndDigits.replace(/\+/g, '')}` : plusAndDigits;

      if (clean.startsWith('00')) {
        return `+${clean.substr(2)}`;
      } else if (clean.startsWith('0')) {
        return `${DEFAULT_COUNT_PREFFIX}${clean.substr(1)}`;
      } else {
        return clean;
      }
    }),
    E.chain(PhoneNumber.decode),
  ),
  identity,
);

export const OptionalPhoneNumber = t.union([PhoneNumberParser, UndefinedString], 'OptionalPhoneNumber');
export type OptionalPhoneNumber = t.TypeOf<typeof OptionalPhoneNumber>;

export interface StrictPhoneNumberBrand {
  readonly StrictPhoneNumber: unique symbol;
}

/**
 * A stricter version of PhoneNumber
 * TODO: rename PhoneNumber to LoosePhoneNumber
 * TODO: rename StrictPhoneNumber to PhoneNumber
 */
export const StrictPhoneNumber = t.brand(
  PhoneNumber,
  (p: string): p is t.Branded<PhoneNumber, StrictPhoneNumberBrand> => /^\+?\d{10,15}$/.test(p),
  'StrictPhoneNumber',
);

export type StrictPhoneNumber = t.TypeOf<typeof StrictPhoneNumber>;

/**
 * Best effort phone numbers parsing
 * @param input phone number string
 * @param countryCode optional country code hint, for local phone number format parsing
 * @returns cleand phone number
 */
const cleanPhoneNumber = (phoneNumber: string | undefined, countryCode?: string): string | undefined => {
  if (phoneNumber !== undefined) {
    const plusAndDigits = phoneNumber.replace(/[^\d\+]/gi, '');
    const cleanNumber = plusAndDigits.startsWith('+') ? `+${plusAndDigits.replace(/\+/g, '')}` : plusAndDigits;

    if (cleanNumber.startsWith('+')) {
      // International number format
      return cleanNumber;
    } else if (cleanNumber.startsWith('00')) {
      // 00 prefixed international number format
      return `+${cleanNumber.substr(2)}`;
    } else if (cleanNumber.startsWith('0') && countryCode !== undefined && COUNTRY_CODES[countryCode]) {
      // local number format and country code available
      return `${COUNTRY_CODES[countryCode]}${cleanNumber.substr(1)}`;
    }
  }

  return phoneNumber;
};

/**
 * Cleanup and parse StrictFromNumber from given string using countryCode hint
 * @param phone phone number string
 * @param countryCode country code hint, to use with "local" phone number formats
 * @returns either valid StrictPhoneNumber or validation error
 */
export const parseStrictPhoneNumber = (
  phone: string | undefined,
  countryCode?: string,
): t.Validation<StrictPhoneNumber> => pipe(cleanPhoneNumber(phone, countryCode), StrictPhoneNumber.decode);

/**
 * Strips extra symbols, adds default prefix and validates as StrictPhoneNumber
 * Unlike StrictPhoneNumber, which strictly validates the input - this codec first does some
 * input clean and transformations to raise chances of StrictPhoneNumber validation success
 */
export const StrictPhoneNumberParsed = new t.Type<StrictPhoneNumber>(
  'StrictPhoneNumberParsed',
  StrictPhoneNumber.is,
  flow(
    DefinedTrimedString.decode,
    E.chain((s) => parseStrictPhoneNumber(s, DEFAULT_COUNTRY_CODE)),
  ),
  identity,
);
