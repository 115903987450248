import * as React from 'react';
import Svg, { Path, Circle } from 'react-native-svg';

import { WHITE } from '../../../styles/colors';

type Props = {
  size?: string | number;
  color?: string;
  backgroundColor?: string;
  disabled?: boolean;
  onPress?: () => void;
};

const NotificationClientProfileIcon = ({
  size = 32,
  color = WHITE,
  backgroundColor = '#2dce89',
  disabled = false,
  onPress,
}: Props) => (
  <Svg width={size} height={size} viewBox="0 0 32 32" disabled={disabled} onPress={onPress}>
    <Circle cx="16" cy="16" r="16" fill={backgroundColor} />
    <Path
      d="M32.134,17.227A8.845,8.845,0,0,0,28.922,15.6c1.12-.939,1.791-1.547,1.795-3.032a3.907,3.907,0,0,0-1.094-2.876,4.211,4.211,0,0,0-2.893-1.3,4.285,4.285,0,0,0-3,1.052,3.933,3.933,0,0,0-1.348,2.786c-.005.117-.005.233,0,.323,0,1.487.67,2.1,1.792,3.043a8.752,8.752,0,0,0-3.2,1.625,3.5,3.5,0,0,0-1.424,2.8v1.332c0,.872.726,2.031,7,2.031s7-1.159,7-2.031V20.027A3.5,3.5,0,0,0,32.134,17.227Zm-8.5-4.7a2.36,2.36,0,0,1,0-.243A2.872,2.872,0,0,1,26.547,9.6l.127,0a2.939,2.939,0,0,1,2.023.908,2.727,2.727,0,0,1,.764,2.019v.027a2.658,2.658,0,0,1-2.885,2.722h-.058A2.665,2.665,0,0,1,23.636,12.525Zm2.913,9.654c-4.41,0-5.643-.6-5.744-.823V20.022a2.313,2.313,0,0,1,.955-1.86,7.625,7.625,0,0,1,4.759-1.678l.03,0,.03,0a7.625,7.625,0,0,1,4.759,1.678l.019.015a2.3,2.3,0,0,1,.935,1.848l0,1.323C32.192,21.578,30.96,22.179,26.548,22.179Z"
      transform="translate(-10.549 0.112)"
      fill={color}
    />
  </Svg>
);

export default React.memo(NotificationClientProfileIcon);
