import { Positive, PositiveModule } from './positive';
import { PositiveInt } from './positiveInt';
import { ScaledNumber } from './scaledNumber';
import * as E from 'fp-ts/lib/Either';

export type PositiveScaledNumber = Positive<ScaledNumber>;

const PositiveScaledNumberN: PositiveModule<ScaledNumber> = Positive.build<ScaledNumber>(ScaledNumber);

const fromPositiveInt = (value: PositiveInt): PositiveScaledNumber => {
  return ScaledNumber.fromInteger(value) as PositiveScaledNumber;
};

const ONE: PositiveScaledNumber = fromPositiveInt(PositiveInt.unsafeFrom(1));

export const PositiveScaledNumber = {
  ...PositiveScaledNumberN,
  JSON: PositiveScaledNumberN.json(ScaledNumber.JSON),
  ONE,
  add: (a: PositiveScaledNumber, b: PositiveScaledNumber): PositiveScaledNumber => {
    return ScaledNumber.add(a, b) as PositiveScaledNumber;
  },

  mul: (a: PositiveScaledNumber, b: PositiveScaledNumber): PositiveScaledNumber => {
    return ScaledNumber.mul(a, b) as PositiveScaledNumber;
  },

  toNumber: (value: PositiveScaledNumber): number => {
    return ScaledNumber.toNumber(value);
  },

  fromInteger: (value: number): E.Either<Error, PositiveScaledNumber> => {
    const scaledNumber = ScaledNumber.fromInteger(value);
    if (!PositiveScaledNumber.is(scaledNumber)) {
      return E.left(new Error('Number is negative'));
    }

    return E.right(scaledNumber);
  },

  fromNumber: (value: number, decimals: number): E.Either<Error, PositiveScaledNumber> => {
    const scaledNumber = ScaledNumber.fromNumber(value, decimals);
    if (!PositiveScaledNumber.is(scaledNumber)) {
      return E.left(new Error('Number is negative'));
    }

    return E.right(scaledNumber);
  },

  fromPositiveInt,

  fromScaledNumber: (value: ScaledNumber): E.Either<Error, PositiveScaledNumber> => {
    if (!PositiveScaledNumber.is(value)) {
      return E.left(new Error('Number is negative'));
    }

    return E.right(value);
  },

  sub: (a: PositiveScaledNumber, b: PositiveScaledNumber): E.Either<Error, PositiveScaledNumber> => {
    const result = ScaledNumber.sub(a, b);
    if (!PositiveScaledNumber.is(result)) {
      return E.left(new Error('Number is negative'));
    }

    return E.right(result);
  },

  fromStr: (value: string): E.Either<Error, PositiveScaledNumber> => {
    const validation = ScaledNumber.fromStr(value);

    if (E.isLeft(validation)) {
      return E.left(new Error('String is not a number'));
    }
    if (!PositiveScaledNumber.is(validation.right)) {
      return E.left(new Error('Number is negative'));
    }

    return E.right(validation.right);
  },
};
