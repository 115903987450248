import * as AppleAuthentication from 'expo-apple-authentication';
import * as WebBrowser from 'expo-web-browser';
import * as React from 'react';

import LoginButton from '../../components/Button/LoginButton';

export type AppleLoginResult =
  | {
      readonly type: 'success';
      readonly authorizationCode: string;
      readonly identityToken?: string;
    }
  | {
      readonly type: 'cancel';
    }
  | {
      readonly type: 'error';
      readonly error: unknown;
    };

export type Props = {
  /**
   * Optional title to replace buttont text
   */
  readonly title?: string;

  /**
   * Facebook login started
   */
  readonly onStart?: () => void;

  /**
   * Facebook login done with given result
   */
  readonly onResult: (result: AppleLoginResult) => void;

  readonly disabled?: boolean;
};

WebBrowser.maybeCompleteAuthSession();

const AppleSignInButton: React.FC<Props> = ({
  title,
  onStart = () => {
    // nop
  },
  onResult,
  disabled,
}: Props) => {
  const [ready, setReady] = React.useState(false);

  React.useEffect(() => {
    if (!ready) {
      let mounted = true;

      const initialize = async () => {
        try {
          const isAvailable = await AppleAuthentication.isAvailableAsync();

          if (mounted) {
            setReady(isAvailable);
          }
        } catch (error) {
          if (mounted) {
            setReady(false);
          }
        }
      };

      initialize();

      return () => {
        mounted = false;
      };
    }
  }, [ready, setReady, onResult]);

  const signInWithApple = () => {
    if (ready) {
      const doSignIn = async () => {
        onStart();

        const credential = await AppleAuthentication.signInAsync({
          requestedScopes: [
            AppleAuthentication.AppleAuthenticationScope.FULL_NAME,
            AppleAuthentication.AppleAuthenticationScope.EMAIL,
          ],
        });

        if (credential.authorizationCode) {
          onResult({
            type: 'success',
            authorizationCode: credential.authorizationCode,
            identityToken: credential.identityToken ?? undefined,
          });
        } else {
          throw new Error('No apple authorization code returned!');
        }
      };

      doSignIn().catch((error) => {
        if (error && error.code === 'ERR_CANCELED') {
          onResult({
            type: 'cancel',
          });
        } else {
          onResult({
            type: 'error',
            error: error,
          });
        }
      });
    }
  };
  return (
    <LoginButton
      icon="apple"
      text={title ?? 'Continuă cu Apple'}
      onClick={signInWithApple}
      disabled={disabled || !ready}
    />
  );
};

export default AppleSignInButton;
