import { Eq } from 'fp-ts/lib/Eq';
import * as t from 'io-ts';

export const UserNotificationsSettings = t.type(
  {
    sms: t.boolean,
    web: t.boolean,
    mobile: t.boolean,
    email: t.boolean,
  },
  'UserNotificationsSettings',
);

export type UserNotificationsSettings = t.TypeOf<typeof UserNotificationsSettings>;

export const userNotificationsSettings: Eq<UserNotificationsSettings> = {
  equals: (x, y) => x.mobile === y.mobile && x.email === y.email && x.sms === y.sms && x.web === y.web,
};
