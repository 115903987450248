import * as React from 'react';
import Svg, { G, Rect } from 'react-native-svg';

import { BLACK } from '../../../styles/colors';

type Props = {
  size?: string | number;
  color?: string;
  disabled?: boolean;
  onPress?: () => void;
};

const Menu: React.FC<Props> = ({ size = 24, color = BLACK, disabled = false, onPress }) => (
  <Svg width={size} height={size} viewBox="0 0 24 24" disabled={disabled} onPress={onPress}>
    <G transform="translate(-25 -625)">
      <G transform="translate(27.456 629.701)">
        <Rect width="21" height="2" rx="1" transform="translate(-0.456 0.299)" fill={color} />
        <Rect width="21" height="2" rx="1" transform="translate(-0.456 6.299)" fill={color} />
        <Rect width="21" height="2" rx="1" transform="translate(-0.456 12.299)" fill={color} />
      </G>
      <Rect width="24" height="24" transform="translate(25 625)" fill="none" />
    </G>
  </Svg>
);

export default React.memo(Menu);
