import * as React from 'react';
import Svg, { Path } from 'react-native-svg';

import { COMET } from '../../../styles/colors';

type Props = {
  size?: string | number;
  color?: string;
  disabled?: boolean;
  onPress?: () => void;
};

const Telephone: React.FC<Props> = ({ size = 24, color = COMET, disabled = false, onPress }) => (
  <Svg width={size} height={size} viewBox={`0 0 ${size} ${size}`} disabled={disabled} onPress={onPress}>
    <Path d="M0,0H24V24H0Z" fill="none" />
    <Path
      d="M19.09,15.551l-3.124-2.042a1.442,1.442,0,0,0-1.952.39c-.721.871-1.592,2.313-4.866-.961s-1.862-4.175-.991-4.9a1.442,1.442,0,0,0,.39-1.952L6.505,2.966c-.27-.39-.631-1.021-1.472-.9A4.1,4.1,0,0,0,2,6.12c0,2.7,2.132,6.007,5.046,8.92s6.217,5.016,8.89,5.016a4.354,4.354,0,0,0,4.055-3C20.081,16.452,19.48,15.821,19.09,15.551Z"
      transform="translate(1 0.95)"
      fill="none"
      stroke={color}
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeMiterlimit="10"
      strokeWidth="1.2"
    />
  </Svg>
);

export default React.memo(Telephone);
