import type {
  AccountPermissions as AccountPermissionsType,
  PagePermissions as PagePermissionsType,
  BookingsPermissions as BookingsPermissionsType,
  CheckoutPermissions as CheckoutPermissionsType,
  ClientsPermissions as ClientsPermissionsType,
  ProProfilePermissions as ProProfilePermissionsType,
  NotificationsPermissions as NotificationsPermissionsType,
  StatisticsPermissions as StatisticsPermissionsType,
  ReviewsPermissions as ReviewsPermissionsType,
  MarketingPermissions as MarketingPermissionsType,
  MeroPermissions as MeroPermissionsType,
  ServicesPermissions as ServicesPermissionsType,
  ProductsPermissions as ProductsPermissionsType,
  OwnNotificationsSettingsPermissions as OwnNotificationsSettingsPermissionsType,
} from '../access';
import * as t from 'io-ts';

const AccountPermissions: t.Type<AccountPermissionsType, AccountPermissionsType> = t.type(
  {
    manageSubscription: t.boolean,
    manageOwnership: t.boolean,
    readOwnPermissions: t.boolean,
    managePermissions: t.boolean,
  },
  'AccountPermissions',
);

const PagePermissions: t.Type<PagePermissionsType, PagePermissionsType> = t.type(
  {
    manageDetails: t.boolean,
    manageGallery: t.boolean,
    manageServices: t.boolean,
    manageWorkingHours: t.boolean,
    manageOnlinePayments: t.boolean,
    readBillingDetails: t.boolean,
    manageBillingDetails: t.boolean,
    readNotificationSettings: t.boolean,
    manageNotificationSettings: t.boolean,
    manageGiftcards: t.boolean,
    manageDiscounts: t.boolean,
    manageCheckout: t.boolean,
    sendSMSNotifications: t.boolean,
    manageBoost: t.boolean,
    manageMemberships: t.boolean,
  },
  'PagePermissions',
);

const BookingsPermissions: t.Type<BookingsPermissionsType, BookingsPermissionsType> = t.type(
  {
    readOwn: t.boolean,
    readOwnDetails: t.boolean,
    markOwnNoShow: t.boolean,
    writeOwn: t.boolean,
    writeOwnBlockedTime: t.boolean,
    manageOwnBookingRequests: t.boolean,
    readAll: t.boolean,
    readAllDetails: t.boolean,
    markAllNoShow: t.boolean,
    writeAll: t.boolean,
    writeAllBlockedTime: t.boolean,
    manageAllBookingRequests: t.boolean,
  },
  'BookingsPermissions',
);

const CheckoutPermissions: t.Type<CheckoutPermissionsType, CheckoutPermissionsType> = t.type(
  {
    readAll: t.boolean,
    writeAll: t.boolean,
    isReceiptPrinter: t.boolean,
  },
  'BookingsPermissions',
);

const ClientsPermissions: t.Type<ClientsPermissionsType, ClientsPermissionsType> = t.type(
  {
    limitedSearch: t.boolean,
    search: t.boolean,
    listAll: t.boolean,
    viewDetails: t.boolean,
    readOwnInvites: t.boolean,
    writeOwnInvites: t.boolean,
    readAllInvites: t.boolean,
    writeAllInvites: t.boolean,
    acceptBoostClientCommission: t.boolean,
    requestBoostClientClaim: t.boolean,
    addClientFeedback: t.boolean,
    manageAll: t.boolean,
  },
  'ClientsPermissions',
);

const ProProfilePermissions: t.Type<ProProfilePermissionsType, ProProfilePermissionsType> = t.type(
  {
    editOwnDetails: t.boolean,
    editOwn: t.boolean,
    deleteOwn: t.boolean,
    listAll: t.boolean,
    manageAll: t.boolean,
  },
  'ProProfilePermissions',
);

const NotificationsPermissions: t.Type<NotificationsPermissionsType, NotificationsPermissionsType> = t.type(
  {
    receiveAllProsClientAppointmentMade: t.boolean,
    receiveAllProsAppointmentCanceledByClient: t.boolean,
    receiveAllProsClientPaymentMade: t.boolean,
    canSendOwnManualSMS: t.boolean,
    sendAllManualSMS: t.boolean,
    customizeNotificationTemplates: t.boolean,
    receiveOtherProsAppointmentAction: t.boolean,
  },
  'NotificationsPermissions',
);

const StatisticsPermissions: t.Type<StatisticsPermissionsType, StatisticsPermissionsType> = t.type(
  {
    readOwn: t.boolean,
    readAll: t.boolean,
  },
  'StatisticsPermissions',
);

const ReviewsPermissions: t.Type<ReviewsPermissionsType, ReviewsPermissionsType> = t.type(
  {
    readOwn: t.boolean,
    readAll: t.boolean,
    manageAll: t.boolean,
    manageSettings: t.boolean,
  },
  'ReviewsPermissions',
);

const MarketingPermissions: t.Type<MarketingPermissionsType, MarketingPermissionsType> = t.type(
  {
    manageAll: t.boolean,
  },
  'MarketingPermissions',
);

const ServicesPermissions: t.Type<ServicesPermissionsType, ServicesPermissionsType> = t.type(
  {
    readAll: t.boolean,
  },
  'ServicesPermissions',
);

const ProductsPermissions: t.Type<ProductsPermissionsType, ProductsPermissionsType> = t.type(
  {
    manageAll: t.boolean,
  },
  'ProductsPermissions',
);

const OwnNotificationsSettingsPermission: t.Type<
  OwnNotificationsSettingsPermissionsType,
  OwnNotificationsSettingsPermissionsType
> = t.type(
  {
    manageAll: t.boolean,
    manageOwnAppointments: t.boolean,
    manageOthersAppointments: t.boolean,
    manageFulfillRequest: t.boolean,
    manageSmsReminder: t.boolean,
    manageOwnWaitingList: t.boolean,
    manageOthersWaitingList: t.boolean,
  },
  'OwnNotificationsSettingsPermission',
);

export const MeroPermissions: t.Type<MeroPermissionsType, MeroPermissionsType> = t.type(
  {
    account: AccountPermissions,
    page: PagePermissions,
    bookings: BookingsPermissions,
    checkout: CheckoutPermissions,
    clients: ClientsPermissions,
    proProfile: ProProfilePermissions,
    notifications: NotificationsPermissions,
    statistics: StatisticsPermissions,
    reviews: ReviewsPermissions,
    marketing: MarketingPermissions,
    services: ServicesPermissions,
    products: ProductsPermissions,
    ownNotificationsSettings: OwnNotificationsSettingsPermission,
  },
  'MeroPermissions',
);
