import { JSONable } from '@mero/shared-sdk';
import * as t from 'io-ts';

export type Cancelled = 4;

const VALUE: Cancelled = 4;

const JSON: t.Type<Cancelled, JSONable> = t.literal(VALUE);

export const Cancelled = {
  JSON,
  VALUE,
};
