import { BasicCalculator } from './basicCalculator';
import { ExtendedCalculator } from './extendedCalculator';

const zero = (): 0 => 0;

const add = (a: number, b: number): number => {
  return a + b;
};

const sub = (a: number, b: number): number => {
  return a - b;
};

const mul = (a: number, b: number): number => {
  return a * b;
};

const div = (a: number, b: number, decimals: number): number => {
  return Math.round((a / b) * Math.pow(10, decimals)) / Math.pow(10, decimals);
};

const round = (a: number, decimals: number): number => {
  return Math.round(a * Math.pow(10, decimals)) / Math.pow(10, decimals);
};

/**
 * Compare two values
 * @returns -1 if a < b, 0 if a === b, 1 if a > b
 */
const compare = (a: number, b: number): -1 | 0 | 1 => {
  if (a < b) {
    return -1;
  } else if (a > b) {
    return 1;
  } else {
    return 0;
  }
};

const NumberBasicCalculator: BasicCalculator<number> = {
  zero,
  add,
  sub,
  mul,
  div,
  round,
  compare,
};

export const NumberCalculator: ExtendedCalculator<number> = ExtendedCalculator.build(NumberBasicCalculator);
