import * as t from 'io-ts';

export const AnalyticsBoostStats = t.type(
  {
    newClientsCount: t.number,
    totalBoostBookingsCount: t.number,
    totalValueFromBoostBookings: t.number,
    averageValueFromBoostBookings: t.number,
    cost: t.number,
    profit: t.number,
  },
  'AnalyticsBoostStats',
);
export type AnalyticsBoostStats = t.TypeOf<typeof AnalyticsBoostStats>;

export const AnalyticsBoostGlobalStats = t.type(
  {
    newClientBookingsCount: t.number,
    totalBoostBookingsCount: t.number,
    totalValueFromBoostBookings: t.number,
    cost: t.number,
    profit: t.number,
  },
  'AnalyticsBoostGlobalStats',
);
export type AnalyticsBoostGlobalStats = t.TypeOf<typeof AnalyticsBoostGlobalStats>;
