import { PageSearch } from '@mero/api-sdk/dist/pages/page-search';
import * as React from 'react';
import { View, Image, Pressable, Dimensions, TouchableOpacity } from 'react-native';
import Svg, { SvgProps, G, Path } from 'react-native-svg';

import { DESKTOP, getDevice } from '../../styles';
import Avatar from '../Avatar';
import Icon from '../Icon';
import Row from '../Layout/Row';
import PhotoSlider from '../PhotoSlider';
import Spacer from '../Spacer';
import Text from '../Text';
import Label from '../Text/Label';
import PromoIcon from './PromoIcon';
import { styles } from './styles';

const BoostIcon = (props: SvgProps) => (
  <Svg width={24} height={24} {...props}>
    <G data-name="Group 6844">
      <Path fill="none" d="M0 0h24v24H0z" data-name="Rectangle 55" />
      <Path
        fill="#7431f7"
        d="M10 16.137a4.246 4.246 0 0 1-3.828 2.427 4.279 4.279 0 0 1 2.452-3.828l.3.3a3.845 3.845 0 0 0-1.251 2.019A3.837 3.837 0 0 0 9.7 15.836Zm-.666-6.616a5.047 5.047 0 0 0-3.363 1.524A5.367 5.367 0 0 0 5 12.363a2.536 2.536 0 0 1 3-.234 13.123 13.123 0 0 1 1.334-2.608Zm3.036 10.208a5.332 5.332 0 0 0 1.319-.971 5.023 5.023 0 0 0 1.519-3.373 14.27 14.27 0 0 1-2.608 1.344 2.538 2.538 0 0 1-.23 3Zm6.599-13.974q-.375-.026-.736-.026c-5.447 0-8.438 4.332-9.392 7.589l2.583 2.583C14.8 14.819 19 11.947 19 6.571v-.034c0-.255-.011-.516-.031-.782ZM15.5 10.396a1.167 1.167 0 1 1 1.167-1.167 1.166 1.166 0 0 1-1.167 1.167Z"
      />
    </G>
  </Svg>
);

const REVIEWS_SCORE_LIMIT = 5;

export type Props = {
  profile: PageSearch;
  userCoords?: { lat: number; lon: number };
  onDetails?: () => void;
  toggleFavorite?: () => void;
};

const device = getDevice();

const getImageSize = (device: string): { width: number; height: number; size: 'large' | 'small' } => {
  return {
    width: device === DESKTOP ? 635 : 200,
    height: device === DESKTOP ? 384 : 200,
    size: device === DESKTOP ? 'large' : 'small',
  };
};

const getFullImageSize = (device: string): { width: number; height: number; size: 'large' | 'small' } => {
  const { width } = Dimensions.get('window');
  return {
    width: device === DESKTOP ? 635 : width,
    height: device === DESKTOP ? 384 : 217,
    size: device === DESKTOP ? 'large' : 'small',
  };
};

const { width, height } = getImageSize(device);
const { width: fullwidth, height: fullheight } = getFullImageSize(device);

function degreesToRadians(degrees: number) {
  return (degrees * Math.PI) / 180;
}

function distanceInKmBetweenEarthCoordinates(a: { lat: number; lon: number }, b: { lat: number; lon: number }) {
  const earthRadiusKm = 6371;

  const dLat = degreesToRadians(b.lat - a.lat);
  const dLon = degreesToRadians(b.lon - a.lon);

  const lat1 = degreesToRadians(a.lat);
  const lat2 = degreesToRadians(b.lat);

  const c =
    Math.sin(dLat / 2) * Math.sin(dLat / 2) + Math.sin(dLon / 2) * Math.sin(dLon / 2) * Math.cos(lat1) * Math.cos(lat2);
  const e = 2 * Math.atan2(Math.sqrt(c), Math.sqrt(1 - c));

  const kmDistance = earthRadiusKm * e;

  if (kmDistance < 1) {
    return `${(Math.round(kmDistance * 100) * 10).toFixed(0)}m`;
  }

  if (kmDistance < 10) {
    return `${(Math.round(kmDistance * 10) / 10).toFixed(1)}km`;
  } else {
    return `${kmDistance.toFixed(0)}km`;
  }
}

const PageCard: React.FC<Props> = ({ profile, userCoords, onDetails, toggleFavorite }: Props) => {
  const profileLocation = React.useMemo(
    () => ({
      lat: profile.location.coordinates[0],
      lon: profile.location.coordinates[1],
    }),
    [profile.location],
  );

  const distanceToLocation = React.useMemo(
    () => (userCoords ? distanceInKmBetweenEarthCoordinates(userCoords, profileLocation) + ' - ' : ''),
    [userCoords, profileLocation],
  );

  const { mainPicture, secondaryPicture, mainText, secondaryText } = React.useMemo(() => {
    try {
      // check if the search matched a worker
      // and adjust UI
      const matchedWorkerIndex = profile._highlightResult?.workers?.findIndex(
        (w) => w.user.firstname.matchLevel !== 'none' || w.user.lastname.matchLevel !== 'none',
      );
      const nameNotMatched = profile._highlightResult?.name.matchLevel === 'none';

      if (nameNotMatched && matchedWorkerIndex !== undefined && matchedWorkerIndex !== -1) {
        const worker = profile.workers[matchedWorkerIndex];

        return {
          mainPicture: worker.profilePhoto,
          secondaryPicture: profile.profilePhoto,
          mainText: `${worker.user.firstname} ${worker.user.lastname ?? ''}`,
          secondaryText: profile.name,
        };
      }
    } catch (error) {
      // pass to default values
    }

    return {
      mainPicture: profile.profilePhoto,
      secondaryPicture: undefined,
      mainText: profile.name,
      secondaryText: undefined,
    };
  }, [profile]);

  const images = profile.images;

  // @ts-ignore
  return (
    <View style={styles.container}>
      <View style={styles.gallery}>
        {images && images.length > 1 && (
          <PhotoSlider
            style={{ width: '100%' }}
            imageStyle={{
              borderRadius: device === DESKTOP ? 12 : 8,
            }}
            lastImageStyle={{
              paddingRight: 8,
            }}
            spaceBetween={8}
            onPhotoPress={onDetails}
            data={profile.images.map((image) => ({ url: image.medium }))}
            width={width}
            height={height}
            limit={6}
          />
        )}
        {images.length === 1 && (
          <Pressable onPress={onDetails}>
            <Image
              source={{ uri: images[0].medium }}
              style={{ width: fullwidth - 16, height: fullheight }}
              borderRadius={8}
            />
          </Pressable>
        )}
        {images.length === 0 && <Spacer size={16} />}

        {/* page extras */}
        {images.length > 0 && (
          <View style={styles.profileExtrasContainer}>
            {profile.isPromoted && (
              <View style={styles.promotedLabelContainer}>
                <BoostIcon />
                <Label style={styles.promotedLabel}>PROMOVAT</Label>
              </View>
            )}
            {profile.giftCardSettings.universalEnabled && (
              <View style={styles.giftCardIconBg}>
                <Icon type="gift" size={24} />
              </View>
            )}
            {/*@ts-expect-error support not added*/}
            {profile.hasPromoPrice && (
              <View style={[styles.promoIconBg, { marginRight: 8 }]}>
                <PromoIcon />
              </View>
            )}
          </View>
        )}
      </View>

      <Pressable onPress={onDetails}>
        <View>
          <Spacer size={8} />
          <View style={[styles.padding16]}>
            <Row>
              <View style={{ flex: 1 }}>
                <View>
                  <Text h2 accessibilityRole="header" ariaLevel="1">
                    {mainText}
                  </Text>
                  {secondaryText && (
                    <View style={{ marginVertical: 4 }}>
                      <Label style={{ fontFamily: 'open-sans-semibold' }}>{secondaryText}</Label>
                    </View>
                  )}
                </View>

                <Spacer size={4} />
                {profile.feedback.total > REVIEWS_SCORE_LIMIT && profile.feedback.score ? (
                  <>
                    <Row alignItems="center">
                      <View style={{ width: 15, height: 15 }}>
                        <Icon type="star" backgroundColor="#FBAC40" color="#FBAC40" size={'contain'} />
                      </View>
                      <Text bold> {parseFloat(`${profile.feedback.score}`).toFixed(2)} </Text>

                      <Text color="#52577F">
                        ({profile.feedback.total} {profile.feedback.total === 1 ? 'evaluare' : 'evaluări'})
                      </Text>
                    </Row>
                    <Spacer size="xs" />
                  </>
                ) : null}
                <View>
                  <Text color="#52577F">
                    {distanceToLocation}
                    {profile.address}
                  </Text>
                </View>
              </View>

              <View>
                <View style={styles.profileImage}>
                  <Avatar size={58} source={mainPicture.medium} firstname={profile.name} lastname="" />
                  {secondaryPicture && (
                    <View style={{ position: 'absolute', bottom: -7, right: -7, ...styles.profileImage }}>
                      <Avatar
                        source={secondaryPicture.medium}
                        firstname={secondaryText ?? ''}
                        lastname={''}
                        size={28}
                      />
                    </View>
                  )}
                </View>
              </View>
            </Row>
          </View>
          <Spacer size={24} />
        </View>
      </Pressable>

      {images && images.length > 0 ? (
        <TouchableOpacity style={styles.favoriteButton} onPress={toggleFavorite}>
          <Icon type={profile.isFavorite ? 'favorite-filled' : 'favorite'} backgroundColor="rgba(0, 0, 0, 0.6)" />
        </TouchableOpacity>
      ) : null}
    </View>
  );
};

export default PageCard;
