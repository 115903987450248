import {
  AppliedDiscount,
  DiscountPercent,
  ExtendedCalculator,
  HasFromNumber,
  MeroUnits,
  Money,
  isDefined,
} from '@mero/shared-sdk';

/**
 * Calculated totals for a checkout item or final amount
 */
export type CheckoutTotals<Num, Unit extends MeroUnits.Any> = {
  /**
   * Subtotal is the original price of the item, including VAT, without discount applied
   */
  readonly subtotal: Money<Num, Unit>;
  /**
   * Value for the discount to be applied
   */
  readonly discount?: Money<Num, Unit>;
  /**
   * VAT amount, if set, is included in the total value
   */
  readonly vat?: Money<Num, Unit>;
  /**
   * Total amount to pay, including VAT and discount
   *
   * This value is calculated as subtotal - discount
   * TODO: drop total field and use getTotal method instead?
   */
  readonly total: Money<Num, Unit>;
};

export type CheckoutTotalsModule<Num> = {
  /**
   * @returns (a + b)
   */
  readonly add: <Unit extends MeroUnits.Any>(
    a: CheckoutTotals<Num, Unit>,
    b: CheckoutTotals<Num, Unit>,
  ) => CheckoutTotals<Num, Unit>;

  /**
   * Apply discount {@link discount} to give {@link CheckoutTotals} {@link a}
   *
   * IMPORTANT: this method does not check if new subtotal is negative!
   * @param decimals - number of decimals to use for calculations when rounding is needed (ex.division)
   */
  readonly applyDiscount: <Unit extends MeroUnits.Any>(
    a: CheckoutTotals<Num, Unit>,
    discount: AppliedDiscount<Num, Unit>,
    decimals: number,
  ) => CheckoutTotals<Num, Unit>;

  /**
   * @returns zero value {@link CheckoutTotals}
   */
  readonly zero: <Unit extends MeroUnits.Any>(unit: Unit) => CheckoutTotals<Num, Unit>;
  /**
   * @returns true if {@link a} and {@link b} are equal
   */
  readonly equals: <Unit extends MeroUnits.Any>(a: CheckoutTotals<Num, Unit>, b: CheckoutTotals<Num, Unit>) => boolean;
};

const build = <Num>(num: ExtendedCalculator<Num> & HasFromNumber<Num>): CheckoutTotalsModule<Num> => {
  const MoneyC = Money.build(num, MeroUnits);
  const DiscountPercentC = DiscountPercent.build(num);

  // Precompute ZERO values
  const zeroTotals: { [Unit in MeroUnits.Any]: CheckoutTotals<Num, Unit> } = {
    [MeroUnits.RON.code]: {
      subtotal: MoneyC.zero(MeroUnits.RON.code),
      total: MoneyC.zero(MeroUnits.RON.code),
    },
    [MeroUnits.EUR.code]: {
      subtotal: MoneyC.zero(MeroUnits.EUR.code),
      total: MoneyC.zero(MeroUnits.EUR.code),
    },
  };

  const zero = <Unit extends MeroUnits.Any>(unit: Unit): CheckoutTotals<Num, Unit> => {
    return zeroTotals[unit];
  };

  const add = <Unit extends MeroUnits.Any>(
    a: CheckoutTotals<Num, Unit>,
    b: CheckoutTotals<Num, Unit>,
  ): CheckoutTotals<Num, Unit> => {
    const subtotal = MoneyC.add(a.subtotal, b.subtotal);
    const discount = a.discount ? (b.discount ? MoneyC.add(a.discount, b.discount) : a.discount) : b.discount;
    const vat = a.vat ? (b.vat ? MoneyC.add(a.vat, b.vat) : a.vat) : b.vat;
    const total = MoneyC.add(a.total, b.total);

    return {
      subtotal,
      ...(discount !== undefined ? { discount } : {}),
      ...(vat !== undefined ? { vat } : {}),
      total,
    };
  };

  const applyDiscount = <Unit extends MeroUnits.Any>(
    a: CheckoutTotals<Num, Unit>,
    discount: AppliedDiscount<Num, Unit>,
    decimals: number,
  ): CheckoutTotals<Num, Unit> => {
    switch (discount.type) {
      case 'Value': {
        const newTotal = MoneyC.sub(a.total, discount.value);
        const newDiscount = a.discount ? MoneyC.add(a.discount, discount.value) : discount.value;

        return {
          subtotal: a.subtotal,
          discount: newDiscount,
          ...(a.vat !== undefined
            ? { vat: Money.of(num.div(num.mul(a.vat.amount, newTotal.amount), a.total.amount, decimals), a.vat.unit) }
            : {}),
          total: newTotal,
        };
      }
      case 'Percent': {
        const discountValue = Money.of(
          DiscountPercentC.ofValue(a.total.amount, discount.percent, decimals),
          a.total.unit,
        );
        const newDiscount = a.discount ? MoneyC.add(a.discount, discountValue) : discountValue;
        const newTotal = MoneyC.sub(a.total, discountValue);

        return {
          subtotal: a.subtotal,
          discount: newDiscount,
          ...(a.vat !== undefined
            ? { vat: Money.of(num.div(num.mul(a.vat.amount, newTotal.amount), a.total.amount, decimals), a.vat.unit) }
            : {}),
          total: newTotal,
        };
      }
    }
  };

  const equals = <Unit extends MeroUnits.Any>(a: CheckoutTotals<Num, Unit>, b: CheckoutTotals<Num, Unit>): boolean => {
    const subtotalEquals = MoneyC.equals(a.subtotal, b.subtotal);
    if (!subtotalEquals) {
      return false;
    }

    const totalEquals = MoneyC.equals(a.total, b.total);
    if (!totalEquals) {
      return false;
    }

    const discountEquals =
      (!isDefined(a.discount) && !isDefined(b.discount)) ||
      (isDefined(a.discount) && isDefined(b.discount) && MoneyC.equals(a.discount, b.discount));
    if (!discountEquals) {
      return false;
    }

    const vatEquals =
      (!isDefined(a.vat) && !isDefined(b.vat)) || (isDefined(a.vat) && isDefined(b.vat) && MoneyC.equals(a.vat, b.vat));

    if (!vatEquals) {
      return false;
    }

    return true;
  };

  return {
    zero,
    add,
    applyDiscount,
    equals,
  };
};

export const CheckoutTotals = {
  build,
};
