import { ServiceGroup } from './group';
import { WorkerService } from './worker-service';
import * as t from 'io-ts';

export const GroupWithWorkerServices = t.type(
  {
    group: ServiceGroup,
    services: t.array(WorkerService),
  },
  'GroupWithWorkerServices',
);
export interface GroupWithWorkerServices extends t.TypeOf<typeof GroupWithWorkerServices> {}
