import { OrderId } from './order-id';
import { MeroMoney, optionull } from '@mero/shared-sdk';
import * as t from 'io-ts';

/**
 * User action is requried in order to finalize the payment
 */
export const StripePaymentIntenPreviewActionRequired = t.type(
  {
    type: t.literal('actionRequired'),
    orderId: OrderId,
    total: MeroMoney.JSON,
    intent: t.type(
      {
        id: t.string,
        status: t.union([
          t.literal('requires_payment_method'),
          t.literal('requires_confirmation'),
          t.literal('requires_action'),
        ]),
        clientSecret: optionull(t.string),
      },
      'Intent',
    ),
  },
  'StripePaymentIntenPreviewActionRequired',
);

/**
 * Payment is being processed
 */
export const StripePaymentIntentPreviewProcessing = t.type(
  {
    type: t.literal('processing'),
    orderId: OrderId,
    total: MeroMoney.JSON,
    intent: t.type(
      {
        id: t.string,
        status: t.literal('processing'),
      },
      'Intent',
    ),
  },
  'StripePaymentIntentPreviewProcessing',
);

/**
 * Payment failed (final state)
 */
export const StripePaymentIntentPreviewFailed = t.type(
  {
    type: t.literal('failed'),
    orderId: OrderId,
    total: MeroMoney.JSON,
    intent: t.type(
      {
        id: t.string,
        status: t.literal('canceled'),
      },
      'Intent',
    ),
  },
  'StripePaymentIntentPreviewFailed',
);

/**
 * Payment succeeded (final state)
 */
export const StripePaymentIntentPreviewSucceeded = t.type(
  {
    type: t.literal('succeeded'),
    orderId: OrderId,
    total: MeroMoney.JSON,
    intent: t.type(
      {
        id: t.string,
        status: t.literal('succeeded'),
      },
      'Intent',
    ),
  },
  'StripePaymentIntentPreviewSucceeded',
);

export const StripePaymentIntentPreview = t.union(
  [
    StripePaymentIntenPreviewActionRequired,
    StripePaymentIntentPreviewProcessing,
    StripePaymentIntentPreviewFailed,
    StripePaymentIntentPreviewSucceeded,
  ],
  'StripePaymentIntentPreview',
);

export type StripePaymentIntentPreview = t.TypeOf<typeof StripePaymentIntentPreview>;
