/**
 * Standard sizes
 */
export default {
  4: 4,
  6: 6,
  8: 8,
  12: 12,
  16: 16,
  24: 24,
  32: 32,
  48: 48,
};
