import * as React from 'react';
import { LayoutChangeEvent, View, Text } from 'react-native';

import { COMET } from '../../styles/colors';
import HSpacer from '../HSpacer';

type Props = {
  LeftComponent?: React.ComponentType;
  CenterComponent?: React.ComponentType;
  RightComponent?: React.ComponentType;
  text?: string;
  backgroundColor?: string;
  withShadow?: boolean;
  height?: number;
};

const Header: React.FC<Props> = ({
  LeftComponent,
  CenterComponent,
  RightComponent,
  text,
  backgroundColor,
  withShadow = false,
  height = 50,
}) => {
  const [leftComponentWidth, setLeftComponentWidth] = React.useState(0);
  const [rightComponentWidth, setRightComponentWidth] = React.useState(0);
  const [wrapperWidth, setWrapperWidth] = React.useState(0);

  const setWidth =
    (dispatch: React.Dispatch<number>) =>
    ({ nativeEvent }: LayoutChangeEvent) => {
      const { width } = nativeEvent.layout;
      dispatch(Math.round(width));
    };

  const { left, right } = React.useMemo(() => {
    if (leftComponentWidth && rightComponentWidth) {
      return {
        left: leftComponentWidth > rightComponentWidth ? 0 : rightComponentWidth - leftComponentWidth,
        right: leftComponentWidth > rightComponentWidth ? leftComponentWidth - rightComponentWidth : 0,
      };
    } else {
      return {
        left: !leftComponentWidth ? rightComponentWidth : 0,
        right: !rightComponentWidth ? leftComponentWidth : 0,
      };
    }
  }, [leftComponentWidth, rightComponentWidth]);

  return (
    <View
      style={{
        height: height,
        width: '100%',
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        backgroundColor: backgroundColor ?? 'transparent',
        ...(withShadow
          ? {
              shadowColor: '#000',
              shadowOffset: {
                width: 0,
                height: 5,
              },
              shadowOpacity: 0.08,
              shadowRadius: 16,
            }
          : {}),
      }}
      onLayout={setWidth(setWrapperWidth)}
    >
      {LeftComponent && (
        <View onLayout={setWidth(setLeftComponentWidth)}>
          <LeftComponent />
        </View>
      )}
      <HSpacer left={left} right={0} />
      {CenterComponent && !text ? (
        <View
          style={{
            flex: 1,
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <CenterComponent />
        </View>
      ) : (
        <Text
          style={{
            fontSize: 14,
            flex: 1,
            textAlign: 'center',
            fontFamily: 'open-sans-semibold',
            color: COMET,
          }}
        >
          {text}
        </Text>
      )}
      <HSpacer left={0} right={right} />
      {RightComponent && (
        <View onLayout={setWidth(setRightComponentWidth)}>
          <RightComponent />
        </View>
      )}
    </View>
  );
};

export default Header;
