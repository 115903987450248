import { DefinedString } from './string';
import * as E from 'fp-ts/lib/Either';
import * as Ord from 'fp-ts/lib/Ord';
import { identity, pipe } from 'fp-ts/lib/function';
import * as N from 'fp-ts/lib/number';
import * as t from 'io-ts';

/**
 * TODO: rename to LocalTime
 */
export type DayTime = {
  readonly hour: number; // TODO: add Hour type, refined 0..23
  readonly minute: number; // TODO: add Minute type, refined 0.59
};

const ord: Ord.Ord<DayTime> = Ord.contramap((dayTime: DayTime) => dayTime.hour * 60 + dayTime.minute)(N.Ord);

const DAY_TIME_JSON = t.type(
  {
    hour: t.number,
    minute: t.number,
  },
  'DayTime',
);

const DAY_START: DayTime = {
  hour: 0,
  minute: 0,
};

const DAY_END: DayTime = {
  hour: 23,
  minute: 59,
};

export const DayTime = {
  JSON: DAY_TIME_JSON,
  ARRAY_JSON: t.array(DAY_TIME_JSON),
  DAY_START,
  DAY_END,
  ...ord,
  min: Ord.min(ord),
  max: Ord.max(ord),
  lt: Ord.lt(ord),
  gt: Ord.gt(ord),
  of: (hour: number, minute: number): DayTime => ({ hour, minute }),
  isDayStart: (dayTime: DayTime): boolean => ord.equals(dayTime, DAY_START),
  isDayEnd: (dayTime: DayTime): boolean => ord.equals(dayTime, DAY_END),
};

export interface DayTimeStringBrand {
  readonly DayTimeString: unique symbol;
}

export const DayTimeString = t.brand(
  DefinedString,
  (s): s is t.Branded<DefinedString, DayTimeStringBrand> => /^(([01][0-9])|(2[0-3])):([0-5][0-9])$/.test(s),
  'DayTimeString',
);
export type DayTimeString = t.TypeOf<typeof DayTimeString>;

export const dayTimeToString = ({ hour, minute }: DayTime): DayTimeString =>
  pipe(
    `${hour < 10 ? '0' : ''}${hour}:${minute < 10 ? '0' : ''}${minute}`,
    DayTimeString.decode,
    E.fold(() => {
      throw new Error(`Failed to convert DayTime to DayTimeString from: ${JSON.stringify({ hour, minute })}`);
    }, identity),
  );

export const dayTimeStringParse = (dayTime: DayTimeString): DayTime =>
  pipe(
    dayTime.split(':'),
    ([hour, minute]) => ({ hour: parseInt(hour), minute: parseInt(minute) }),
    DayTime.JSON.decode,
    E.fold(() => {
      throw new Error(`Failed to convert DayTime to DayTimeString from: "${dayTime}"`);
    }, identity),
  );
