import { PositiveInt, Numbers } from '../numbers';
import * as t from 'io-ts';

export type OccurrenceIndex = Numbers.Zero | PositiveInt;

const ZERO = Numbers._0;

const JSON = t.union([Numbers.Zero, PositiveInt.JSON], 'OccurrenceIndex');

const unsafeFromNumber = (n: number): OccurrenceIndex => {
  if (!JSON.is(n)) {
    throw new Error(`Invalid OccurrenceIndex value ${n}`);
  }

  return n;
};

export const OccurrenceIndex = {
  ZERO,
  JSON,
  unsafeFromNumber,
};
