import { Context, getFunctionName, ValidationError } from 'io-ts';

const stringify = (v: unknown): string => {
  if (typeof v === 'function') {
    return getFunctionName(v);
  }
  if (typeof v === 'number' && !isFinite(v)) {
    if (isNaN(v)) {
      return 'NaN';
    }
    return v > 0 ? 'Infinity' : '-Infinity';
  }
  return JSON.stringify(v);
};

const getContextPath = (context: Context): string => context.map(({ key }) => (key !== '' ? key : '$')).join('.');

const getFirstType = (context: Context): string => (context.length > 0 ? context[0].type.name : '');

const getLastType = (context: Context): string => (context.length > 0 ? context[context.length - 1].type.name : '');

const MAX_TYPE_LENGTH = 32;
const MAX_VALUE_LENGTH = 64;

/**
 * Basic message format
 */
const getMessage = (e: ValidationError): string =>
  e.message !== undefined
    ? e.message
    : `Invalid value ${String(stringify(e.value)).substring(0, MAX_VALUE_LENGTH)} supplied to ${getFirstType(
        e.context,
      )} at ${getContextPath(e.context)}`;

const stringifyValue = (e: ValidationError): string => String(stringify(e.value)).substr(0, MAX_VALUE_LENGTH);

const expectedAndGotMessage = (e: ValidationError): string =>
  `expected ${getLastType(e.context).substring(0, MAX_TYPE_LENGTH)} at ${getContextPath(
    e.context,
  )} got ${stringifyValue(e)}`;

/**
 * Alternative message format
 */
const getAltMessage = (e: ValidationError): string =>
  e.message !== undefined
    ? e.message
    : `Failed to decode ${getFirstType(e.context).substring(0, MAX_TYPE_LENGTH)}${
        e.context.length > 1 ? `: ${expectedAndGotMessage(e)}` : ` from ${stringifyValue(e)}`
      }`;

export const formatValidationErrors = (es: Array<ValidationError>): Array<string> => es.map(getAltMessage);
