import { BusinessHours } from '../business';
import { CalendarId } from '../calendar/calendar-id';
import { CalendarSettings } from '../calendar/calendar-settings';
import { Firstname, HasOptionalFirstLastName, Lastname } from '../common';
import { PageId } from '../pages/page-id';
import { ServiceId, WorkerService } from '../services';
import { UserId } from '../users';
import { isSome, JSONable, Option, optionull } from '@mero/shared-sdk';
import { ProfileImage } from '@mero/shared-sdk/dist/assets';
import * as t from 'io-ts';

export type Worker = {
  readonly user: {
    readonly _id: UserId;
    readonly phone: string;
    readonly firstname?: Firstname;
    readonly lastname?: Lastname;
  };
  /**
   * @deprecated
   */
  readonly ownPageSlug: string;
  /**
   * This may be undefined until the production data is migrated
   */
  readonly ownPageId: PageId | undefined;
  readonly calendar: {
    readonly _id: CalendarId;
    readonly settings: CalendarSettings;
  };
  readonly profilePhoto: Option<ProfileImage>;
  readonly businessHours: BusinessHours;
  readonly services: WorkerService[];
  readonly ownPageExpired?: true;
};

const JSON: t.Type<Worker, JSONable> = t.intersection(
  [
    t.type(
      {
        user: t.intersection([
          t.type({
            _id: UserId,
            phone: t.string,
          }),
          HasOptionalFirstLastName,
        ]),
        ownPageSlug: t.string,
        ownPageId: optionull(PageId),
        calendar: t.type({
          _id: CalendarId,
          settings: CalendarSettings,
        }),
        profilePhoto: optionull(ProfileImage),
        businessHours: BusinessHours.JSON,
        services: t.array(WorkerService),
      },
      '!',
    ),
    t.partial(
      {
        ownPageExpired: t.literal(true),
      },
      '?',
    ),
  ],
  'Worker',
);

type PageWithSlug = {
  readonly _id: PageId;
  readonly slug: string;
};

const isOwnPage = (worker: Pick<Worker, 'ownPageId' | 'ownPageSlug'>, page: PageWithSlug): boolean => {
  // new field to define worker own page
  if (isSome(worker.ownPageId)) {
    return worker.ownPageId === page._id;
  }

  // fall back to ownPageSlug if ownPageId is not defined
  return worker.ownPageSlug === page.slug;
};

const isInvited = (worker: Pick<Worker, 'ownPageId' | 'ownPageSlug'>, page: PageWithSlug): boolean => {
  return !isOwnPage(worker, page);
};

const providesAll =
  (ids: ServiceId[]) =>
  <W extends Worker>(w: W): boolean =>
    ids.every((id) => w.services.some((s) => s._id === id));

export const Worker = {
  JSON,
  providesAll,
  isOwnPage,
  isInvited,
};
