import { CalendarSlotsInterval } from './calendar-slots-interval';
import { optionull } from '@mero/shared-sdk';
import * as t from 'io-ts';

export const CalendarSettings = t.intersection(
  [
    t.type({
      timezone: t.string,
      maxAppointmentsPerClient: t.number,
      minHoursBeforeClientsCanCancelAppointments: t.number,
      minHoursBeforeTodayAppointments: t.number,
      isPrivate: t.boolean,
      getNotifications: t.boolean,
      calendarInterval: t.type({
        value: t.number,
        unit: t.string,
      }),
      /**
       * Maximum number of days in the future
       * a client can be on the waiting list
       */
      maxWaitingListDaysPerClient: optionull(t.number),
    }),
    t.partial({
      /**
       * Custom booking slots interval configuration
       */
      slotsInterval: CalendarSlotsInterval,
      clientAppointmentReminders: t.array(
        t.type({
          unit: t.string,
          value: t.number,
        }),
      ),
    }),
  ],
  'CalendarSettings',
);
export type CalendarSettings = t.TypeOf<typeof CalendarSettings>;
