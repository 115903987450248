import { ObjectId } from '@mero/shared-sdk';
import * as Eq from 'fp-ts/Eq';
import * as t from 'io-ts';

export interface WorkerIdBrand {
  readonly WorkerId: unique symbol;
}

export type WorkerId = t.Branded<ObjectId, WorkerIdBrand>;

const JSON: t.Type<WorkerId, string> = t.brand(ObjectId, (_id: ObjectId): _id is WorkerId => true, 'WorkerId');

const workerId: Eq.Eq<WorkerId> = {
  equals: (x, y) => x === y,
};

export const WorkerId = {
  JSON,
  ...workerId,
};
