import { CheckoutItemTotalScaledNumber } from '../checkoutItemTotalScaledNumber';
import { CheckoutMembershipItemTotal } from '../checkoutMembershipItemTotal';
import { CheckoutTotals } from '../checkoutTotals';
import { CheckoutTransactionMembershipPreview } from '../checkoutTransactionMembershipPreview';
import { CheckoutUserPreview } from '../checkoutUserPreview';
import { CompanyVatStatus } from '../companies';
import { MeroUnits, ScaledNumber } from '@mero/shared-sdk';

export type MembershipInstallment<Unit extends MeroUnits.Any> = {
  readonly type: 'MembershipInstallment';
  readonly transactionItemId?: string;
  readonly membership: CheckoutTransactionMembershipPreview;
  readonly total: CheckoutMembershipItemTotal<ScaledNumber, Unit>;
  /**
   * This is weird, membership sale should be probably assigned to user who originally sold the memberhip (this is a subsequent payment)
   */
  readonly saleOwner: CheckoutUserPreview;
};

export const membershipInstallment = <Unit extends MeroUnits.Any>(params: {
  transactionItemId?: string;
  membership: CheckoutTransactionMembershipPreview;
  total: CheckoutMembershipItemTotal<ScaledNumber, Unit>;
  saleOwner: CheckoutUserPreview;
}): MembershipInstallment<Unit> => {
  return {
    type: 'MembershipInstallment',
    transactionItemId: params.transactionItemId,
    membership: params.membership,
    total: params.total,
    saleOwner: params.saleOwner,
  };
};

const getTotals = <Unit extends MeroUnits.Any>(
  item: MembershipInstallment<Unit>,
  unit: Unit,
  companyVat: CompanyVatStatus.Any<ScaledNumber>,
  decimals: number,
): CheckoutTotals<ScaledNumber, Unit> => {
  unit;
  return CheckoutItemTotalScaledNumber.getTotals(item.total, companyVat, decimals);
};

export const MembershipInstallment = {
  getTotals,
};
