import { StyleSheet } from 'react-native';

import sizes from './sizes';

export const styles = StyleSheet.create({
  flex: {
    flex: 1,
  },
  row: {
    display: 'flex',
    flexDirection: 'row',
  },
  column: {
    display: 'flex',
    flexDirection: 'column',
  },
  /**
   * @deprecated use paddingHor24
   */
  hrPadding24: {
    paddingLeft: sizes[24],
    paddingRight: sizes[24],
  },
  /**
   * @deprecated use paddingHor16
   */
  hrPadding16: {
    paddingLeft: sizes[16],
    paddingRight: sizes[16],
  },
  /**
   * @deprecated use paddingHor8
   */
  hrPadding8: {
    paddingLeft: sizes[8],
    paddingRight: sizes[8],
  },
  padding8: {
    padding: sizes[8],
  },
  paddingHor8: {
    paddingLeft: sizes[8],
    paddingRight: sizes[8],
  },
  paddingVer8: {
    paddingTop: sizes[8],
    paddingBottom: sizes[8],
  },
  padding16: {
    padding: sizes[16],
  },
  paddingHor16: {
    paddingLeft: sizes[16],
    paddingRight: sizes[16],
  },
  paddingVer16: {
    paddingTop: sizes[16],
    paddingBottom: sizes[16],
  },
  padding24: {
    padding: sizes[24],
  },
  paddingHor24: {
    paddingLeft: sizes[24],
    paddingRight: sizes[24],
  },
  paddingVer24: {
    paddingTop: sizes[24],
    paddingBottom: sizes[24],
  },
});
