import { BoostCommissionPercent } from './boostCommissionPercent';
import { JSONable, MeroUnits, Money, ScaledNumber } from '@mero/shared-sdk';
import * as t from 'io-ts';

export type AutomaticBoostDefaultValues = {
  readonly commissionPercent: BoostCommissionPercent;
  readonly minBudget: Money<ScaledNumber, MeroUnits.RON>;
  readonly minCommissionValue: Money<ScaledNumber, MeroUnits.RON>;
};

const JSON: t.Type<AutomaticBoostDefaultValues, JSONable> = t.type(
  {
    commissionPercent: BoostCommissionPercent.codec,
    minBudget: Money.json(ScaledNumber.JSON, MeroUnits.RON.JSON),
    minCommissionValue: Money.json(ScaledNumber.JSON, MeroUnits.RON.JSON),
  },
  'AutomaticBoostDefaultValues',
);

export const AutomaticBoostDefaultValues = {
  JSON,
};
