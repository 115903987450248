import { DefinedTrimedString } from '../string';
import * as t from 'io-ts';

/**
 * Code 39 Barcode
 * Matches strings that can contain uppercase letters (A-Z), digits (0-9), and some special characters (-, ., $, /, +, %)
 */
export interface Code39StringBrand {
  readonly Code39String: unique symbol;
}

export type Code39String = t.Branded<DefinedTrimedString, Code39StringBrand>;

const JSON: t.Type<Code39String, string> = t.brand(
  DefinedTrimedString,
  (s): s is Code39String => /^[0-9A-Z\-\.\$\ /+\%]*$/.test(s),
  'Code39String',
);

export const Code39String = {
  JSON,
};
