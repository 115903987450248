import * as t from 'io-ts';

/**
 * Unbounded values interval (-Infinity, +Infinity)
 */
export const UnboundedInterval = t.partial({
  from: t.undefined,
  to: t.undefined,
});
export interface UnboundedInterval extends t.TypeOf<typeof UnboundedInterval> {}

/**
 * Left bounded values interval [from, +Infinity)
 */
export const LeftBoundedInterval = t.intersection(
  [
    t.type({
      from: t.number,
    }),
    t.partial({
      to: t.undefined,
    }),
  ],
  'LeftBoundedInterval',
);
export interface LeftBoundedInterval extends t.TypeOf<typeof LeftBoundedInterval> {}

/**
 * Right bounded values interval (-Infinity, to]
 */
export const RightBoundedInterval = t.intersection(
  [
    t.type({
      to: t.number,
    }),
    t.partial({
      from: t.undefined,
    }),
  ],
  'RightBoundedInterval',
);
export interface RightBoundedInterval extends t.TypeOf<typeof RightBoundedInterval> {}

export const BoundedInterval = t.type(
  {
    from: t.number,
    to: t.number,
  },
  'BoundedInterval',
);
export interface BoundedInterval extends t.TypeOf<typeof BoundedInterval> {}

/**
 * Unbounded, bounded, left or right bounded values interval
 */
export const Interval = t.union(
  [BoundedInterval, LeftBoundedInterval, RightBoundedInterval, UnboundedInterval],
  'Interval',
);
export type Interval = t.TypeOf<typeof Interval>;
