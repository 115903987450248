import * as t from 'io-ts';

export type Day = 'd' | 'day' | 'days';

const JSON: t.Type<Day, string> = t.union([t.literal('d'), t.literal('day'), t.literal('days')], 'Day');

export const Day = {
  JSON,
  is: JSON.is,
};
