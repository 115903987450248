import { JSONable } from '@mero/shared-sdk';
import * as t from 'io-ts';

export type CheckoutReportByServiceSortType = 'count' | 'gross' | 'discount' | 'vat' | 'total' | 'net' | 'protocol';

const COUNT = 'count' as const;
const GROSS = 'gross' as const;
const DISCOUNT = 'discount' as const;
const VAT = 'vat' as const;
const TOTAL = 'total' as const;
const NET = 'net' as const;
const PROTOCOL = 'protocol' as const;

const JSON: t.Type<CheckoutReportByServiceSortType, JSONable> = t.union(
  [
    t.literal(COUNT),
    t.literal(GROSS),
    t.literal(DISCOUNT),
    t.literal(VAT),
    t.literal(TOTAL),
    t.literal(NET),
    t.literal(PROTOCOL),
  ],
  'CheckoutReportByServiceSortType',
);

export const CheckoutReportByServiceSortType = {
  COUNT,
  GROSS,
  DISCOUNT,
  VAT,
  TOTAL,
  NET,
  PROTOCOL,
  JSON,
};
