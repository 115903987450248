import { ClientAppointment } from '../clients/client-appointment';
import {
  FeedbackId,
  JSONable,
  OptionalFirstname,
  OptionalLastname,
  ProfileImage,
  Reply,
  optionull,
} from '@mero/shared-sdk';
import * as t from 'io-ts';

type AnonymousFeedbackDetails = {
  readonly _id: FeedbackId;
  readonly isAnonymous: true;
  readonly feedback: {
    readonly score: number;
    readonly review?: string;
  };
  readonly replies?: Reply[];
};

const AnonymousFeedbackDetails: t.Type<AnonymousFeedbackDetails, JSONable> = t.intersection(
  [
    t.type(
      {
        _id: FeedbackId,
        isAnonymous: t.literal(true),
        feedback: t.intersection(
          [
            t.type(
              {
                score: t.number,
              },
              '!',
            ),
            t.partial(
              {
                review: t.string,
              },
              '?',
            ),
          ],
          'Feedback',
        ),
      },
      '!',
    ),
    t.partial(
      {
        replies: t.array(Reply),
      },
      '?',
    ),
  ],
  'AnonymousFeedbackDetails',
);

type VisibleFeedbackDetails = {
  readonly _id: FeedbackId;
  readonly isAnonymous: false | undefined;
  readonly user: {
    // readonly _id: UserId;
    readonly firstname: OptionalFirstname;
    readonly lastname: OptionalLastname;
    readonly profilePhoto: ProfileImage | undefined;
  };
  readonly feedback: {
    readonly score: number;
    readonly review?: string;
  };
  readonly replies?: Reply[];
};

const VisibleFeedbackDetails: t.Type<VisibleFeedbackDetails, JSONable> = t.intersection(
  [
    t.type(
      {
        _id: FeedbackId,
        isAnonymous: optionull(t.literal(false)),
        user: t.type(
          {
            // _id: UserId,
            firstname: OptionalFirstname,
            lastname: OptionalLastname,
            profilePhoto: optionull(ProfileImage),
          },
          'User',
        ),
        feedback: t.intersection(
          [
            t.type(
              {
                score: t.number,
              },
              '!',
            ),
            t.partial(
              {
                review: t.string,
              },
              '?',
            ),
          ],
          'Feedback',
        ),
      },
      '!',
    ),
    t.partial(
      {
        replies: t.array(Reply),
      },
      '?',
    ),
  ],
  'VisibleFeedbackDetails',
);

export type PublicFeedbackDetails = AnonymousFeedbackDetails | VisibleFeedbackDetails;

export const PublicFeedbackDetails: t.Type<PublicFeedbackDetails, JSONable> = t.union(
  [AnonymousFeedbackDetails, VisibleFeedbackDetails],
  'PublicFeedbackDetails',
);

export type PublicFeedbackDetailsWithAppointment =
  | (VisibleFeedbackDetails & {
      readonly clientAppointment?: ClientAppointment;
    })
  | (AnonymousFeedbackDetails & {
      readonly clientAppointment?: undefined;
    });

export const PublicFeedbackDetailsWithAppointment: t.Type<PublicFeedbackDetailsWithAppointment, JSONable> = t.union(
  [
    t.intersection(
      [
        VisibleFeedbackDetails,
        t.partial(
          {
            clientAppointment: ClientAppointment,
          },
          '?',
        ),
      ],
      'VisibleFeedbackDetailsWithAppointment',
    ),
    t.intersection(
      [
        AnonymousFeedbackDetails,
        t.partial(
          {
            clientAppointment: t.undefined,
          },
          '?',
        ),
      ],
      'AnonymousFeedbackDetailsWithAppointment',
    ),
  ],
  'PublicFeedbackDetailsWithAppointment',
);
