import * as t from 'io-ts';

/**
 * Receipts printing is allowed (but not required).
 */
export type Allowed = {
  readonly type: 'Allowed';
};

const JSON = t.type(
  {
    type: t.literal('Allowed'),
  },
  'Allowed',
);

export const Allowed = {
  JSON,
};
