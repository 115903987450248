import * as React from 'react';
import {
  StyleProp,
  View,
  ViewStyle,
  StyleSheet,
  TouchableOpacity,
  ScrollView,
  useWindowDimensions,
  Platform,
} from 'react-native';

import * as colors from '../../styles/colors';
import sizes from '../../styles/sizes';
import { styles as text } from '../../styles/text';
import Button from '../Button';
import HSpacer from '../HSpacer';
import { default as Icon, IconProps } from '../Icon';
import Column from '../Layout/Column';
import Line from '../Line';
import Spacer from '../Spacer';
import SmallBody from '../Text/SmallBody';
import { styles } from './styles';

export type ButtonAction = {
  readonly text: string;
  readonly onPress?: () => void;
  readonly flex?: number;
  readonly backgroundColor?: string;
};
const HeaderBg = {
  error: colors.RADICAL_RED,
  info: colors.COMET,
  warn: colors.YELLOW_ORANGE,
  success: colors.SHAMROCK,
};

const PrimaryButtonBg = {
  error: colors.RADICAL_RED,
  info: colors.DARK_BLUE,
  warn: colors.YELLOW_ORANGE,
  success: colors.SHAMROCK,
};

export type ConfirmBoxProps = React.PropsWithChildren<{
  readonly style?: StyleProp<ViewStyle>;
  readonly type: 'error' | 'info' | 'warn' | 'success';
  readonly headerTitle: string;
  readonly icon?: IconProps['type'];
  readonly canClose?: boolean;
  readonly onClose?: () => void;
  readonly leftAction?: ButtonAction;
  readonly rightAction?: ButtonAction;
  readonly defaultAction?: 'left' | 'right';
}>;

const HEADER_HEIGHT = 55;
const FOOTER_HEIGHT = 76;

const ConfirmBox: React.FC<ConfirmBoxProps> = ({
  type,
  style,
  icon,
  headerTitle,
  canClose,
  onClose,
  leftAction,
  rightAction,
  defaultAction = 'right',
  children,
}: ConfirmBoxProps) => {
  const headerBg = HeaderBg[type];
  const primaryButtonBg = PrimaryButtonBg[type];
  const primaryButtonColor = colors.WHITE;
  const secondaryButtonBg = 'transparent';
  const secondaryButtonColor = colors.BLACK;
  const [contentHeight, setContentHeight] = React.useState(0);
  const screenHeight = useWindowDimensions().height;

  const extra = HEADER_HEIGHT + FOOTER_HEIGHT;
  const totalHeight = contentHeight + extra;

  return (
    <View
      style={[
        styles.container,
        style,
        Platform.OS !== 'web'
          ? totalHeight > screenHeight
            ? { height: screenHeight - 32 }
            : { height: totalHeight }
          : undefined,
      ]}
    >
      <View style={styles.box}>
        <View style={StyleSheet.compose<ViewStyle, ViewStyle, ViewStyle>(styles.header, { backgroundColor: headerBg })}>
          {icon !== undefined ? (
            <>
              <Icon type={icon} size={sizes['24']} color={colors.WHITE} />
              <HSpacer right={sizes['8']} />
            </>
          ) : null}
          <Column flex={1}>
            <SmallBody style={[text.semibold, { color: colors.WHITE, textTransform: 'uppercase' }]}>
              {headerTitle}
            </SmallBody>
          </Column>
          {canClose ? (
            <TouchableOpacity onPress={onClose} disabled={onClose === undefined}>
              <Icon type="close" size={sizes['24']} color={colors.WHITE} />
            </TouchableOpacity>
          ) : null}
        </View>
        <ScrollView style={styles.body} showsVerticalScrollIndicator={false}>
          {Platform.OS === 'web' ? (
            children
          ) : (
            <View
              onLayout={(event) => {
                const { height } = event.nativeEvent.layout;
                setContentHeight(height);
              }}
            >
              {children}
              <Spacer size={FOOTER_HEIGHT} />
            </View>
          )}
        </ScrollView>
        <View style={styles.footer}>
          <Line />
          {leftAction !== undefined || rightAction !== undefined ? (
            <View style={styles.buttonsRow}>
              <View
                style={StyleSheet.compose<ViewStyle, ViewStyle, ViewStyle>(
                  styles.buttonBox,
                  leftAction?.flex !== undefined ? { flex: leftAction.flex } : {},
                )}
              >
                {leftAction !== undefined ? (
                  <Button
                    size="medium"
                    text={leftAction.text}
                    backgroundColor={defaultAction === 'left' ? primaryButtonBg : secondaryButtonBg}
                    disabledBackgroundColor={defaultAction === 'left' ? undefined : secondaryButtonBg}
                    color={defaultAction === 'left' ? primaryButtonColor : secondaryButtonColor}
                    onClick={leftAction.onPress}
                    disabled={leftAction.onPress === undefined}
                  />
                ) : null}
              </View>
              <HSpacer left={6} right={6} />
              <View
                style={StyleSheet.compose<ViewStyle, ViewStyle, ViewStyle>(
                  styles.buttonBox,
                  rightAction?.flex !== undefined ? { flex: rightAction.flex } : {},
                )}
              >
                {rightAction !== undefined ? (
                  <Button
                    size="medium"
                    text={rightAction.text}
                    backgroundColor={
                      rightAction.backgroundColor
                        ? rightAction.backgroundColor
                        : defaultAction === 'right'
                        ? primaryButtonBg
                        : secondaryButtonBg
                    }
                    disabledBackgroundColor={defaultAction === 'right' ? undefined : secondaryButtonBg}
                    color={defaultAction === 'right' ? primaryButtonColor : secondaryButtonColor}
                    onClick={rightAction.onPress}
                    disabled={rightAction.onPress === undefined}
                  />
                ) : null}
              </View>
            </View>
          ) : null}
        </View>
      </View>
    </View>
  );
};

export default ConfirmBox;
