import { isSome } from '@mero/api-sdk';
import { Picker } from '@react-native-picker/picker';
import * as React from 'react';
import {
  Platform,
  TextInputProps,
  View,
  ViewProps,
  TextStyle,
  Text,
  TouchableOpacity,
  Modal,
  Pressable,
  ViewStyle,
} from 'react-native';

import { colors } from '../../styles';
import { GEYSER, COMET, RADICAL_RED, DARK_BLUE } from '../../styles/colors';
import Button from '../Button';
import Icon from '../Icon';
import ModalOverlay from '../ModalOverlay';
import Spacer from '../Spacer';

type StyledViewProps = React.PropsWithChildren<{
  isError?: boolean;
  isFocused?: boolean;
  rounded?: boolean;
}>;

const StyledView: React.FC<StyledViewProps & ViewProps> = ({
  isError,
  isFocused,
  rounded,
  style,
  children,
  ...props
}) => {
  return (
    <View
      style={[
        {
          borderWidth: 1,
          borderStyle: 'solid',
          borderColor: isError ? RADICAL_RED : isFocused ? DARK_BLUE : GEYSER,
          backgroundColor: colors.WHITE,
          borderRadius: rounded ? 20 : 5,
          alignItems: 'center',
          height: 43,
          width: '100%',
          display: 'flex',
          flexDirection: 'row',
          paddingTop: 0,
          paddingRight: 0,
          paddingBottom: 0,
          paddingLeft: 0,
          position: 'relative',
          overflow: 'hidden',
        },
        style,
      ]}
      {...props}
    >
      {children}
    </View>
  );
};

type GenerateStyle = {
  placeholderColor: string;
  disabledColor: string;
  isPlaceholder: boolean;
  style?: ViewStyle;
  iconContainerStyle?: ViewStyle;
  editable: boolean;
};

const generateStyle = ({ placeholderColor, disabledColor, style, isPlaceholder, editable }: GenerateStyle) => ({
  fontSize: 16,
  fontFamily: 'open-sans',
  height: 41,
  color: editable ? (isPlaceholder ? placeholderColor : '#172B4D') : disabledColor,
  borderWidth: 0,
  ...(Platform.OS === 'web' ? { appearance: 'none' } : {}),
  ...style,
});

const isUndefined = (value: unknown): value is undefined => typeof value === 'undefined';

const DropdownIcon: React.FC = () => <Icon type="dropdown" />;

export type SelectItem<A> = {
  readonly value: A;
  readonly label: string;
};

type Primitive = number | string | undefined;

/**
 * Limiting item type to primitive types for now as Picker component is only doing object identity comparison
 * which may lead to infinite render cycle
 */
export type Props<A extends Primitive> = {
  readonly value?: A;
  readonly items: SelectItem<A>[];
  readonly isError?: boolean;
  readonly isFocused?: boolean;
  readonly placeholder?: string;
  readonly placeholderColor?: string;
  readonly disabledColor?: string;
  readonly style?: ViewStyle;
  readonly selectStyle?: TextStyle;
  readonly iconContainerStyle?: ViewStyle;
  readonly rounded?: boolean;
  readonly editable?: TextInputProps['editable'];
  readonly onChange?: (value: A) => void;
  readonly onOpen?: () => void;
  readonly onClose?: () => void;
  readonly closeText?: string;
  readonly LeftComponent?: React.ComponentType;
  readonly RightComponent?: React.ComponentType;
};

function Select<A extends Primitive>({
  value,
  items,
  isError,
  placeholder,
  placeholderColor = COMET,
  disabledColor = colors.CADET_BLUE,
  style,
  selectStyle,
  iconContainerStyle,
  rounded = false,
  editable = true,
  onChange = () => undefined,
  onOpen = () => undefined,
  onClose = () => undefined,
  closeText = 'Gata',
  LeftComponent,
  RightComponent = DropdownIcon,
}: Props<A>): React.ReactElement {
  /**
   * Find value position within items list (-1) if not found
   */
  const selectedIndex = React.useMemo(() => {
    return items.findIndex((item) => {
      return item.value === value;
    });
  }, [items, value]);

  const selectedItem = selectedIndex !== -1 ? items[selectedIndex] : undefined;

  // placeholder should disappear once a value is selected
  const hasPlaceholder = isSome(placeholder) && selectedIndex === -1;

  // select placeholder (0) if no value found (-1)
  const selectedIndexWithPlaceholder = selectedIndex + (hasPlaceholder ? 1 : 0);

  /**
   * Add placeholder if needed
   */
  const itemsWithPlaceholder = React.useMemo(() => {
    if (hasPlaceholder) {
      return [{ label: placeholder, value: undefined }, ...items];
    }

    return items;
  }, [items, placeholder, hasPlaceholder]);

  const [isOpen, setIsOpen] = React.useState(false);

  const [modalSelectedIndex, setModalSelectedIndex] = React.useState<number>(selectedIndex);

  // select placeholder (0) if no value found (-1)
  const modalSelectedIndexWithPlaceholder = modalSelectedIndex + (hasPlaceholder ? 1 : 0);

  const styles = React.useMemo(
    () =>
      generateStyle({
        placeholderColor,
        disabledColor,
        editable,
        style: selectStyle,
        iconContainerStyle,
        isPlaceholder: selectedIndex === -1,
      }),
    [selectedIndex, editable, disabledColor, selectStyle, iconContainerStyle],
  );

  const onOpenHandle = React.useCallback((): void => {
    setIsOpen(true);
    setModalSelectedIndex(selectedIndex ?? (items.length > 0 ? 0 : -1));
    onOpen();
  }, [setIsOpen, setModalSelectedIndex, selectedIndex, items, onOpen]);

  const onCloseHandle = React.useCallback((): void => {
    setIsOpen(false);
    onClose();
  }, [setIsOpen, onClose]);

  const selectedLabel = () => {
    return selectedItem?.label ?? placeholder ?? items[0]?.label ?? '';
  };

  const onValueChange = React.useCallback(
    (_, itemIndex): void => {
      // Remove placeholder offset
      const index = hasPlaceholder ? itemIndex - 1 : itemIndex;
      const selectedItem = index >= 0 ? items[index] : undefined;

      if (isSome(selectedItem)) {
        onChange(selectedItem.value);
      }
    },
    [items, hasPlaceholder, onChange],
  );

  const onModalValueChange = React.useCallback(
    (_, itemIndex): void => {
      // Remove placeholder offset
      const index = hasPlaceholder ? itemIndex - 1 : itemIndex;
      setModalSelectedIndex(index);
    },
    [hasPlaceholder, setModalSelectedIndex],
  );

  const onModalValueSubmit = React.useCallback((): void => {
    const modalSelectedItem = modalSelectedIndex >= 0 ? items[modalSelectedIndex] : undefined;
    if (isSome(modalSelectedItem)) {
      onChange(modalSelectedItem.value);
    }

    onCloseHandle();
  }, [modalSelectedIndex, onChange, onCloseHandle]);

  return (
    <>
      <StyledView rounded={rounded} isError={isError} style={style} isFocused={isOpen}>
        {LeftComponent && <LeftComponent />}
        <View
          style={{
            flex: 1,
            height: styles.height,
            justifyContent: 'center',
            alignItems: 'center',
            flexDirection: 'row',
            position: 'relative',
          }}
        >
          {Platform.OS === 'ios' ? (
            <TouchableOpacity
              style={{
                flex: 1,
                justifyContent: 'center',
                alignItems: 'center',
                height: styles.height,
                flexDirection: 'row',
                ...(!editable && { backgroundColor: colors.ATHENS_GRAY }),
              }}
              disabled={!editable}
              onPress={onOpenHandle}
            >
              <Text
                style={{
                  fontSize: styles.fontSize,
                  fontFamily: styles.fontFamily,
                  color: styles.color,
                  paddingLeft: 8,
                  flex: 1,
                }}
              >
                {selectedLabel()}
              </Text>
              {RightComponent && (
                <View
                  style={{
                    backgroundColor: style?.backgroundColor ?? colors.WHITE,
                    height: styles.height,
                    justifyContent: 'center',
                    alignItems: 'center',
                    paddingLeft: 4,
                    paddingRight: 8,
                    ...(!editable && { backgroundColor: colors.ATHENS_GRAY }),
                  }}
                >
                  <RightComponent />
                </View>
              )}
            </TouchableOpacity>
          ) : (
            <>
              <Picker
                dropdownIconColor={style?.backgroundColor ?? colors.WHITE}
                selectedValue={String(selectedIndexWithPlaceholder)}
                onValueChange={onValueChange}
                enabled={editable}
                style={[{ flex: 1, opacity: 0, height: styles.height }]}
                itemStyle={styles}
              >
                {itemsWithPlaceholder.map((item, index) => (
                  <Picker.Item key={String(index)} label={item.label} value={String(index)} />
                ))}
              </Picker>
              <View
                style={{
                  position: 'absolute',
                  top: 0,
                  right: 0,
                  bottom: 0,
                  left: 0,
                  flexDirection: 'row',
                  justifyContent: 'center',
                  alignItems: 'center',
                  ...(!editable && { backgroundColor: colors.ATHENS_GRAY }),
                }}
                pointerEvents="none"
              >
                <Text
                  style={{
                    fontSize: styles.fontSize,
                    fontFamily: styles.fontFamily,
                    color: styles.color,
                    paddingLeft: 8,
                    flex: 1,
                  }}
                >
                  {selectedLabel()}
                </Text>
                {RightComponent && (
                  <View
                    style={{
                      justifyContent: 'center',
                      alignItems: 'center',
                      paddingLeft: 4,
                      paddingRight: 8,
                      ...(!editable && { backgroundColor: colors.ATHENS_GRAY }),
                    }}
                  >
                    <RightComponent />
                  </View>
                )}
              </View>
            </>
          )}
        </View>
      </StyledView>
      <Modal animationType="slide" transparent={true} visible={isOpen} onRequestClose={onCloseHandle}>
        <ModalOverlay>
          <Pressable style={{ flex: 1 }} onPress={onCloseHandle} />
          <View style={{ backgroundColor: 'white' }}>
            <Picker selectedValue={String(modalSelectedIndexWithPlaceholder)} onValueChange={onModalValueChange}>
              {itemsWithPlaceholder.map((item, index) => (
                <Picker.Item
                  key={String(index)}
                  label={item.label}
                  value={String(index)}
                  style={{ fontSize: styles.fontSize }}
                />
              ))}
            </Picker>
            <Button
              size="small"
              backgroundColor={colors.WHITE}
              color={colors.DARK_BLUE}
              text={closeText}
              onPress={onModalValueSubmit}
            />
            <Spacer size={32} />
          </View>
        </ModalOverlay>
      </Modal>
    </>
  );
}

export default Select;
