import { ExtendedCalculator } from './extendedCalculator';
import { HasFromNumber } from './hasFromNumber';
import { PortionPercent } from './portionPercent';
import * as t from 'io-ts';

/**
 * A discount percent
 * Any number in [0, 100] is a valid discount percent
 */
export interface DiscountPercentBrand {
  readonly DiscountPercent: unique symbol;
}

export type DiscountPercent<Num> = t.Branded<PortionPercent<Num>, DiscountPercentBrand>;

export type DiscountPercentModule<Num> = {
  readonly is: (a: Num) => a is DiscountPercent<Num>;
  /**
   * Create a DiscountPercent from a number
   * @throws if given number is not a valid DiscountPercent
   */
  readonly unsafeFrom: (n: Num) => DiscountPercent<Num>;
  /**
   * Calculate discount value for given value {@link n}
   */
  readonly ofValue: (n: Num, discount: PortionPercent<Num>, decimals: number) => Num;
  /**
   * Apply discount to give value {@link n} and return the discounted value, rounding discount to {@link decimals}
   */
  readonly applyTo: (n: Num, discount: DiscountPercent<Num>, decimals: number) => Num;
  /**
   * Returns true if discount percent equals 0
   */
  readonly isZero: (discount: DiscountPercent<Num>) => boolean;
  /**
   * Build new JSON codec for DiscountPercent<Num>
   */
  readonly json: <O, I>(codec: t.Type<Num, O, I>) => t.Type<DiscountPercent<Num>, O, I>;
};

/**
 * Build new instance of DiscountPercentModule<Num>
 */
const build = <Num>(num: ExtendedCalculator<Num> & HasFromNumber<Num>): DiscountPercentModule<Num> => {
  const PortionPercentNum = PortionPercent.build(num);

  const is = (a: Num): a is DiscountPercent<Num> => {
    return PortionPercentNum.is(a);
  };

  const unsafeFrom = (n: Num): DiscountPercent<Num> => {
    if (!is(n)) {
      throw new Error('Invalid DiscountPercent value');
    }

    return n;
  };

  const applyTo = (n: Num, percent: PortionPercent<Num>, decimals: number): Num => {
    return num.sub(n, PortionPercentNum.ofValue(n, percent, decimals));
  };

  const isZero = (discount: DiscountPercent<Num>): boolean => {
    return num.isZero(discount);
  };

  const json = <O, I>(codec: t.Type<Num, O, I>): t.Type<DiscountPercent<Num>, O, I> => {
    return t.brand(codec, is, 'Percent');
  };

  return {
    is,
    ofValue: PortionPercentNum.ofValue,
    applyTo,
    isZero,
    unsafeFrom,
    json,
  };
};

export const DiscountPercent = {
  build,
};
