import * as React from 'react';
import Svg, { Circle, Path } from 'react-native-svg';

type Props = {
  size?: string | number;
  color?: string;
  disabled?: boolean;
  onPress?: () => void;
};

const AnonymousUserIcon = ({ size }: Props) => (
  <Svg width={size || 48} height={size || 48}>
    <Circle cx={24} cy={24} r={24} fill="#e9ecef" />
    <Path
      data-name="Union 5"
      d="M14.999 34a1 1 0 0 1-1-1v-1.871a4.783 4.783 0 0 1 3.919-3.506c3.379-.577 3.379-1.6 3.379-2.232v-1.022a6.235 6.235 0 0 1-2.028-4.717v-3.061c0-3.315 2.5-4.593 4.73-4.593s4.73 1.277 4.73 4.593v3.061a6.234 6.234 0 0 1-2.028 4.717v1.022c0 .637 0 1.625 3.379 2.232a4.854 4.854 0 0 1 3.919 3.506V33a1 1 0 0 1-1 1Z"
      fill="rgba(82,87,127,0.5)"
    />
  </Svg>
);

export default AnonymousUserIcon;
