import * as MeroApi from '@mero/api-sdk';
import { DateTime } from 'luxon';
import * as React from 'react';
import { View } from 'react-native';

import { colors } from '../../../styles';
import sizes from '../../../styles/sizes';
import { styles as text } from '../../../styles/text';
import { formatTimeDiff } from '../../../utils/format';
import HSpacer from '../../HSpacer';
import Icon, { NotificationCalendarClockIcon } from '../../Icon';
import Body from '../../Text/Body';
import SmallBody from '../../Text/SmallBody';
import { styles } from '../styles';

export type Props = {
  readonly notification: MeroApi.notifications.AppointmentModifiedNotification;
};

const AppointmentModifiedNotification: React.FC<Props> = ({ notification }: Props) => {
  const { payload } = notification;

  const fromTs = DateTime.fromJSDate(payload.from.start, { zone: payload.appointment.timezone });
  const toTs = DateTime.fromJSDate(payload.to.start, { zone: payload.appointment.timezone });

  return (
    <View style={styles.notificationContainer}>
      <NotificationCalendarClockIcon />
      <View style={styles.notificationBody}>
        <Body>
          Programarea ta la <Body style={text.semibold}>{payload.page.name}</Body> de{' '}
          <Body style={text.semibold}>{fromTs.toFormat('cccc d LLL', { locale: 'ro' })}</Body> a fost modificată. Noua
          programare este <Body style={text.semibold}>{toTs.toFormat('cccc d LLL', { locale: 'ro' })}</Body> ora{' '}
          <Body style={text.semibold}>{toTs.toFormat('H:mm', { locale: 'ro' })}</Body>.
        </Body>
        <SmallBody style={styles.timeText}>{formatTimeDiff(notification.createdAt, new Date())}</SmallBody>
      </View>
      <HSpacer left={8} />
      <Icon type="next" size={sizes[24]} color={colors.DARK_BLUE} />
    </View>
  );
};

export default AppointmentModifiedNotification;
