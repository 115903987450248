import { optionull } from './io';

/**
 * A value that cannot be null | undefined
 * @experimental
 */
export type Some<A> = NonNullable<A>;

/**
 * This is just a type guard, {@link value} cannot be undefined | null
 * @experimental
 */
export const some = <A>(value: Some<A>): Some<A> => {
  return value;
};

/**
 * Type guard for Some<T> values
 * @experimental
 */
export const isSome = <A>(value: Option<A>): value is Some<A> => {
  return value !== null && value !== undefined;
};

/**
 * A missing value (undefined)
 * @experimental
 */
export type None = undefined;

/**
 * @experimental
 */
export const None: None = undefined;

/**
 * Type guard for None values
 * @experimental
 */
export const isNone = <A>(value: Option<A>): value is None => {
  return value === None;
};

/**
 * Optional value of type A
 * @experimental
 */
export type Option<A> = Some<A> | undefined;

/**
 * Exclude null from {@link value} and build new option
 * @experimental
 */
export const option = <A>(value: A): Option<A> => {
  return value ?? undefined;
};

export const Option = {
  json: optionull,
};
