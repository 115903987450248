import { AdsApi } from './ads-api';
import { BookingCompletedBanner } from './booking-completed-banner';
import { HttpClient, unsafeRight, UnknownApiError, optionull } from '@mero/shared-sdk';

/**
 * AdsApi HTTP client implementation
 */
export const adsHttpClient = (env: { apiBaseUrl: string; http: HttpClient }): AdsApi => {
  const { apiBaseUrl, http } = env;

  const getBookingCompletedBannerDecoder = http.decode.response(UnknownApiError, optionull(BookingCompletedBanner));

  return {
    getBookingCompletedBanner: async ({ appointmentId } = {}) => {
      const params = {
        appointmentId,
      };

      const meta = unsafeRight(
        await http.request(
          {
            method: 'GET',
            url: `${apiBaseUrl}/ads/booking-completed-banner`,
            params: params,
          },
          getBookingCompletedBannerDecoder,
        ),
      );

      return meta;
    },
  };
};
