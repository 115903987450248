import { ScaledNumber } from './scaledNumber';
import { Zero } from './zero';
import * as t from 'io-ts';

export type ScaledNumberZero = Zero<ScaledNumber>;

const ScaledNumberZeroM = Zero.build(ScaledNumber);

const JSON: t.Type<ScaledNumberZero, t.OutputOf<typeof ScaledNumber.JSON>> = ScaledNumberZeroM.json(ScaledNumber.JSON);

export const ScaledNumberZero = {
  ...ScaledNumberZeroM,
  JSON,
};
