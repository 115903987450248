import * as React from 'react';
import Svg, { G, Path } from 'react-native-svg';

type Props = {
  size?: number | string;
  color?: string;
  disabled?: boolean;
  onPress?: () => void;
};

const ProMenuPendingClients = ({ size = 32, color = '#000000', disabled, onPress }: Props) => (
  <Svg width={size} height={size} viewBox="0 0 32 32" disabled={disabled} onPress={onPress}>
    <G transform="translate(4 4)">
      <Path d="M11.99,2A10,10,0,1,0,22,12,10,10,0,0,0,11.99,2ZM12,20a8,8,0,1,1,8-8A8,8,0,0,1,12,20Z" fill={color} />
      <Path d="M12.5,7H11v6l5.25,3.15L17,14.92l-4.5-2.67Z" fill={color} />
    </G>
  </Svg>
);

export default React.memo(ProMenuPendingClients);
