import { StyleSheet, Dimensions } from 'react-native';

import * as colors from '../../styles/colors';
import sizes from '../../styles/sizes';

export const styles = StyleSheet.create({
  container: {
    width: '100%',
    minWidth: 240,
    maxWidth: 640,
    minHeight: 64,
    maxHeight: Dimensions.get('window').height,
  },
  box: {
    flex: 1,
    margin: 16,
    borderRadius: 5,
    backgroundColor: colors.WHITE,
  },
  header: {
    height: 55,
    borderTopLeftRadius: 5,
    borderTopRightRadius: 5,
    paddingLeft: sizes['16'],
    paddingRight: sizes['16'],
    alignItems: 'center',
    flexDirection: 'row',
    backgroundColor: colors.RADICAL_RED,
  },
  body: {
    paddingLeft: sizes['24'],
    paddingRight: sizes['24'],
    paddingTop: sizes['32'],
    paddingBottom: sizes['24'],
  },
  footer: {
    height: 76,
  },
  buttonsRow: {
    paddingTop: sizes['12'],
    flex: 1,
    flexDirection: 'row',
    justifyContent: 'center',
    paddingLeft: sizes['16'],
    paddingRight: sizes['16'],
  },
  buttonBox: {
    flex: 1,
  },
});
