import * as t from 'io-ts';

export const InviteTypeWork = t.literal(0, 'InviteTypeWork');

export type InviteTypeWork = t.TypeOf<typeof InviteTypeWork>;

export const InviteTypeClientRequest = t.literal(1, 'InviteTypeClientRequest');

export type InviteTypeClientRequest = t.TypeOf<typeof InviteTypeClientRequest>;

export const InviteTypeAppointmentRequest = t.literal(2, 'InviteTypeAppointmentRequest');

export type InviteTypeAppointmentRequest = t.TypeOf<typeof InviteTypeAppointmentRequest>;

export const InviteType = t.union(
  [InviteTypeWork, InviteTypeClientRequest, InviteTypeAppointmentRequest],
  'InviteType',
);

export type InviteType = t.TypeOf<typeof InviteType>;
