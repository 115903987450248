import { CALENDAR_NAMESPACE } from '../namespaces';
import { IErrorTable } from 'exceptional.js';

export const TABLE: IErrorTable = {
  namespace: CALENDAR_NAMESPACE,
  locale: 'ro',
  errors: {
    0: 'S-a intamplat ceva neprevazut',
    1: '${message}',
    2: 'Nu am gasit niciun calendar cu acest id ${id}.',
    3: 'Ai incercat sa accesezi un calendar care nu exista ${id}.',
    4: 'Nu am putut crea programarea.',
    5: 'Nu poti face o programare pentru un profesionist care nu este pe aceasta pagina.',
    6: 'Unul din serviciile selectate nu mai este disponibil.',
    7: 'Trebuie sa alegi cel putin un serviciu pentru o programare.',
    8: 'Programarea pe care ai incercat sa o anulezi nu mai exista.',
    9: 'Nu poti anula o programare din trecut.',
    10: 'Nu am putut anula programarea.',
    11: 'Nu poti anula aceasta programare pentru ca nu iti apartine.',
    12: 'Nu iti poti face mai mult de ${count} programari la acest profesionist.',
    13: 'Aceasta programare nu poate fi anulata cu mai putin de ${hours} ore inainte de a incepe.',
    14: 'Nu am putut face programarea deoarece se suprapune cu altele.',
    15: 'Nu poti face o programare in trecut.',
    16: 'Nu am putut face programarea deoarece calendarul acestui profesionist este inactiv.',
    17: 'Nu am gasit programarea pe care incerci sa o editezi.',
    18: 'Nu poti modifica evenimentele din acest calendar deoarece este inactiv.',
    19: 'Aceasta programare nu exista.',
    20: 'Nu putem muta programarea deoarece se suprapune cu altele.',
    21: 'Nu putem face programarea deoarece nu se incadreaza in programul de lucru al profesionistului.',
    22: 'Nu am putut actualiza setarile calendarului.',
    23: 'Nu iti poti face programare deoarece unul dintre servicii nu este disponibil in ziua aleasa.',
    24: 'Nu poti actualiza status programării.',
    25: 'Nu poți face o programare la ${pageName}. Ia legătura direct cu salonul pentru a afla mai multe detalii.',
    26: 'Nu poți muta o programare în trecut',
    27: 'Acest card cadou (${id}) nu exista.',
    28: 'Acest card cadou (${code}) este invalid.',
    29: 'Acest card cadou (${code}) a fost deja folosit.',
    30: 'Nu poti folosi mai mult de ${limit} carduri cadou pentru o programare.',
    31: 'Nu poti folosi acelasi card cadou de mai multe ori.',
    32: 'Acest card cadou (${code}) a expirat.',
    33: 'Cardurile cadou nu pot fi folosite pentru servicii fara pret fix.',
    34: 'Acest salon nu accepta carduri cadou.',
    35: 'Cardul cadou ${code} nu poate fi folosit la acest salon.',
    36: 'Acest discount (${id}) nu exista.',
    37: 'Nu poti folosi mai mult de ${limit} coduri de reduceri pentru o programare.',
    38: 'Nu poti folosi acelasi cod de reducere de mai multe ori.',
    39: 'Codurile de reducere nu pot fi folosite pentru servicii fara pret fix.',
    40: 'Codul de reducere ${code} nu mai este valabil.',
    41: 'Codul de reducere ${code} nu poate fi folosit la acest salon.',
    42: 'Acest salon nu accepta coduri de reducere.',
    43: 'Codul de reducere poate fi folosit doar pentru servicii de minim ${price} lei.',
    44: 'Ai folosit deja acest cod de reducere (${code}) pentru o alta programare.',
    45: 'Programarea poate fi marcată ca neprezentat in maxim 2 zile de la inceputul programarii.',
    46: 'Codul de reducere (${code}) nu este disponibil pentru aceasta programare.',
    47: 'Codul (${code}) este expirat sau greșit',
    48: 'Codul (${code}) nu este disponibil',
  },
};
