import * as t from 'io-ts';

export type Week = 'w' | 'week' | 'weeks';

const JSON: t.Type<Week, string> = t.union([t.literal('w'), t.literal('week'), t.literal('weeks')], 'Week');

export const Week = {
  JSON,
  is: JSON.is,
};
