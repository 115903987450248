import { MomentTimeUnit } from './moment-time-unit';
import * as t from 'io-ts';

/**
 * A subset of MomentTimeUnit containing only short values
 */
export const ShortTimeUnit = t.keyof(
  {
    /**
     * Years
     */
    y: true,
    /**
     * Quarters
     */
    Q: true,
    /**
     * Months
     */
    M: true,
    /**
     * Weeks
     */
    w: true,
    /**
     * Days
     */
    d: true,
    /**
     * Hours
     */
    h: true,
    /**
     * Minutes
     */
    m: true,
    /**
     * Seconds
     */
    s: true,
    /**
     * Milliseconds
     */
    ms: true,
  },
  'ShortTimeUnit',
);

export type ShortTimeUnit = t.TypeOf<typeof ShortTimeUnit>;

/**
 * Converts ShortTimeUnit value to longer MomentTimeUnit version,
 * ex: 'd' => 'days', 'y' => 'years
 */
const toLong = (unit: ShortTimeUnit): MomentTimeUnit => {
  switch (unit) {
    case 'y':
      return 'years';
    case 'Q':
      return 'quarters';
    case 'M':
      return 'months';
    case 'w':
      return 'weeks';
    case 'd':
      return 'days';
    case 'h':
      return 'hours';
    case 'm':
      return 'minutes';
    case 's':
      return 'seconds';
    case 'ms':
      return 'milliseconds';
  }
};

export const shortTimeUnit = {
  toLong,
};
