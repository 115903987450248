import * as React from 'react';
import { StyleProp, ViewStyle } from 'react-native';

import BaseView from '../Base';

type Props = React.PropsWithChildren<{
  flex?: number;
  reversed?: boolean;
  flexWrap?: ViewStyle['flexWrap'];
  justifyContent?: ViewStyle['justifyContent'];
  alignItems?: ViewStyle['alignItems'];
  style?: StyleProp<ViewStyle>;
}>;

const Row: React.FC<Props> = ({ children, reversed, ...props }) => {
  return (
    <BaseView {...props} flexDirection={reversed ? 'row-reverse' : 'row'}>
      {children}
    </BaseView>
  );
};

export default Row;
