import * as React from 'react';
import Svg, { Path } from 'react-native-svg';

type Props = {
  size?: number | string;
  disabled?: boolean;
  onPress?: () => void;
};

const File = ({ size = 32, disabled, onPress }: Props) => (
  <Svg width={size} height={size} viewBox={`-16 0 32 32`} disabled={disabled} onPress={onPress}>
    <Path
      d="M-3.083 19.083h6.166v1.542h-6.166v-1.542Zm0-3.083h6.166v1.542h-6.166V16Zm4.625-7.708h-6.167c-.848 0-1.542.693-1.542 1.541v12.334a1.54 1.54 0 0 0 1.534 1.541h9.258c.848 0 1.542-.693 1.542-1.541v-9.25L1.542 8.292Zm3.083 13.875h-9.25V9.833H.771v3.855h3.854v8.479Z"
      fill="#52577f"
      fill-rule="evenodd"
    />
  </Svg>
);

export default React.memo(File);
