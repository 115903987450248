import * as React from 'react';
import { ImageBackground, ViewStyle } from 'react-native';
import {
  Dimensions,
  ImageStyle,
  Modal,
  StyleProp,
  FlatList,
  View,
  Image,
  TouchableOpacity,
  Platform,
} from 'react-native';
import Swiper from 'react-native-web-swiper';

import { useSimpleReducer } from '../../hooks/useSimpleReducer';

import Icon from '../Icon';
import PhotoPlaceholder from '../PhotoPlaceholder';
import Text from '../Text';
import styles from './styles';

type Props = {
  imageStyle?: StyleProp<ImageStyle>;
  style?: StyleProp<ViewStyle>;
  lastImageStyle?: StyleProp<ImageStyle>;
  onPhotoPress?: () => void;
  spaceBetween?: number;
  width: number;
  height: number;
  limit?: number;
  data: {
    url: string;
    fullScreenUrl?: string;
  }[];
};

const PhotoSlider = ({
  onPhotoPress,
  spaceBetween = 1,
  data,
  style,
  imageStyle = {},
  lastImageStyle = {},
  width,
  height,
  limit = 0,
}: Props): React.ReactElement | null => {
  const windowSize = Dimensions.get('window');
  const viewWidth = windowSize.width;
  const viewHeight = windowSize.height;
  const [state, updateState] = useSimpleReducer({
    showGallery: false,
    currentIndex: 0,
    galleryCurrentIndex: 0,
  });

  const { showGallery, currentIndex, galleryCurrentIndex } = state;

  const sliderRef = React.useRef<FlatList>(null);
  const swiperRef = React.useRef<Swiper>(null);

  const imageSize = {
    width: data.length === 1 && viewWidth < 1090 ? viewWidth : width,
    height,
  };

  const goTo = (step: number) => () => {
    updateState({
      currentIndex: currentIndex + step,
    });
    sliderRef.current?.scrollToIndex({
      index: currentIndex + step,
      animated: true,
    });
  };

  const renderItem = ({ item, index }: any) => {
    const itemContainerStyle = [
      styles.itemContainer,
      { paddingRight: index === data.length - 1 ? 0 : spaceBetween },
      ...(index === data.length - 1 || index === limit - 1 ? [styles.itemContainerLast, lastImageStyle] : []),
    ];

    const itemStyle = [imageSize, imageStyle];

    return (
      <View style={itemContainerStyle}>
        {limit > 0 && limit !== data.length && limit - 1 === index ? (
          <ImageBackground style={itemStyle} imageStyle={itemStyle} source={{ uri: item.url }}>
            <TouchableOpacity
              style={[
                itemStyle,
                { flex: 1, justifyContent: 'center', alignItems: 'center', backgroundColor: 'rgba(0, 0, 0, 0.8)' },
              ]}
              activeOpacity={1}
              onPress={() => {
                if (onPhotoPress) {
                  onPhotoPress();
                }
              }}
            >
              <Text d1 style={{ fontFamily: 'open-sans-semibold' }} color="#ffffff">
                +{data.length - limit}
              </Text>
            </TouchableOpacity>
          </ImageBackground>
        ) : (
          <TouchableOpacity
            activeOpacity={1}
            onPress={() => {
              if (onPhotoPress) {
                onPhotoPress();
              } else {
                updateState({
                  showGallery: true,
                  currentIndex: index,
                  galleryCurrentIndex: index,
                });
              }
            }}
          >
            <Image style={itemStyle} source={{ uri: item.url }} resizeMode="cover" />
          </TouchableOpacity>
        )}
      </View>
    );
  };

  return viewWidth ? (
    <>
      {showGallery && (
        <Modal
          visible={showGallery}
          transparent={false}
          statusBarTranslucent={Platform.OS === 'android'}
          onRequestClose={() =>
            updateState({
              showGallery: false,
            })
          }
        >
          <View
            style={{
              width: '100%',
              paddingTop: (Platform.OS === 'ios' ? 28 : 16) + 16,
              paddingRight: 16,
              justifyContent: 'center',
              alignItems: 'center',
              zIndex: 10001,
              flexDirection: 'row',
              backgroundColor: '#000000',
            }}
          >
            <View style={{ flex: 1, alignItems: 'center', marginLeft: 32 }}>
              <Text color="#ffffff">
                {(galleryCurrentIndex ?? 0) + 1} / {data.length}
              </Text>
            </View>
            <TouchableOpacity
              onPress={() =>
                updateState({
                  showGallery: false,
                })
              }
            >
              <Icon type="close" color="#ffffff" />
            </TouchableOpacity>
          </View>
          <Swiper
            ref={swiperRef}
            controlsEnabled={false}
            from={currentIndex}
            loop
            minDistanceForAction={0.1}
            onIndexChanged={(index) => {
              updateState({
                galleryCurrentIndex: index,
              });
            }}
          >
            {data.map(({ url, fullScreenUrl }) => (
              <View
                key={url}
                style={{
                  flex: 1,
                  alignItems: 'center',
                  justifyContent: 'center',
                  backgroundColor: '#000000',
                }}
              >
                {Platform.OS === 'web' ? (
                  <Image
                    style={{ width: '-webkit-fill-available', height: '-webkit-fill-available' }}
                    source={{ uri: fullScreenUrl ?? url }}
                    resizeMode="contain"
                  />
                ) : (
                  <Image
                    style={{ borderWidth: 0, marginTop: -129, width: viewWidth, height: viewHeight }}
                    source={{ uri: fullScreenUrl ?? url }}
                    resizeMode="contain"
                  />
                )}
              </View>
            ))}
          </Swiper>
          {galleryCurrentIndex > 0 && (
            <TouchableOpacity
              onPress={() => {
                swiperRef.current?.goToPrev();
                updateState({
                  galleryCurrentIndex: (swiperRef.current?.getActiveIndex() ?? 0) - 1,
                });
              }}
              style={[styles.previousButton, styles.buttonGallery]}
            >
              <Icon type="previous" />
            </TouchableOpacity>
          )}
          {galleryCurrentIndex < data.length - 1 && (
            <TouchableOpacity
              onPress={() => {
                swiperRef.current?.goToNext();
                updateState({
                  galleryCurrentIndex: (swiperRef.current?.getActiveIndex() ?? 0) + 1,
                });
              }}
              style={[styles.nextButton, styles.buttonGallery]}
            >
              <Icon type="next" />
            </TouchableOpacity>
          )}
        </Modal>
      )}

      <FlatList
        style={style}
        ref={sliderRef}
        initialNumToRender={3}
        maxToRenderPerBatch={30}
        data={limit ? data.slice(0, limit) : data}
        horizontal
        showsHorizontalScrollIndicator={false}
        pagingEnabled={Platform.OS === 'web'}
        renderItem={renderItem}
        scrollEnabled={viewWidth < 1090}
        ListEmptyComponent={() => {
          const placeholderWidth = viewWidth < 1090 ? viewWidth : width;
          const placeholderHeight = placeholderWidth / 1.875;
          return <PhotoPlaceholder width={placeholderWidth} height={placeholderHeight} />;
        }}
        keyExtractor={(item) => item.url}
      />
      {currentIndex > 0 && (
        <TouchableOpacity onPress={goTo(-1)} style={[styles.previousButton, styles.button]}>
          <Icon type="previous" />
        </TouchableOpacity>
      )}
      {currentIndex < data.length - 1 && (
        <TouchableOpacity onPress={goTo(1)} style={[styles.nextButton, styles.button]}>
          <Icon type="next" />
        </TouchableOpacity>
      )}
    </>
  ) : null;
};

export default PhotoSlider;
