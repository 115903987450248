import * as t from 'io-ts';

export interface ClientFeedbackIdBrand {
  readonly ClientFeedbackId: unique symbol;
}

export type ClientFeedbackId = t.Branded<string, ClientFeedbackIdBrand>;

const JSON: t.Type<ClientFeedbackId, string> = t.brand(
  t.string,
  (_id: string): _id is ClientFeedbackId => true,
  'ClientFeedbackId',
);

export const ClientFeedbackId = {
  JSON,
};
