import { ProfileImage } from '../assets';
import { JSONable, OptionalFirstname, OptionalLastname, optionull } from '../common';
import { UserId } from '../users/user-id';
import { FeedbackId } from './feedback-id';
import * as t from 'io-ts';

export type Reply = {
  readonly user: {
    readonly _id: UserId;
    readonly firstname: OptionalFirstname;
    readonly lastname: OptionalLastname;
    readonly profilePhoto: ProfileImage | undefined;
  };
  readonly message: string;
};

export const Reply: t.Type<Reply, JSONable> = t.type(
  {
    user: t.type(
      {
        _id: UserId,
        firstname: OptionalFirstname,
        lastname: OptionalLastname,
        profilePhoto: optionull(ProfileImage),
      },
      'User',
    ),
    message: t.string,
  },
  'Reply',
);

export type FeedbackDetails = {
  readonly _id: FeedbackId;
  readonly isAnonymous?: boolean;
  readonly user: {
    readonly _id: UserId;
    readonly firstname: OptionalFirstname;
    readonly lastname: OptionalLastname;
    readonly profilePhoto: ProfileImage | undefined;
  };
  readonly feedback: {
    readonly score: number;
    readonly review?: string;
  };
  readonly replies?: Reply[];
};

export const FeedbackDetails: t.Type<FeedbackDetails, JSONable> = t.intersection(
  [
    t.type(
      {
        _id: FeedbackId,
        user: t.type(
          {
            _id: UserId,
            firstname: OptionalFirstname,
            lastname: OptionalLastname,
            profilePhoto: optionull(ProfileImage),
          },
          'User',
        ),
        feedback: t.intersection(
          [
            t.type({
              score: t.number,
            }),
            t.partial({
              review: t.string,
            }),
          ],
          'Feedback',
        ),
      },
      '!',
    ),
    t.partial(
      {
        isAnonymous: t.boolean,
        replies: t.array(Reply),
      },
      '?',
    ),
  ],
  'FeedbackDetails',
);
