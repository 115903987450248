import * as React from 'react';
import { View } from 'react-native';

import { RADICAL_RED } from '../../styles/colors';
import { styles as text } from '../../styles/text';
import Spacer from '../Spacer';
import Body from '../Text/Body';
import SmallBody from '../Text/SmallBody';
import { Props as TextInput } from '../TextInput';

export type Props = React.PropsWithChildren<{
  label: string;
  isError?: boolean;
  highlightLabelOnError?: boolean;
  errorText?: string;
  onChange?: TextInput['onChange'];
  /**
   * @deprecated use children
   */
  Input?: React.ComponentType<TextInput>;
}>;

const InputWithLabel: React.FC<Props> = ({
  Input,
  label,
  isError,
  highlightLabelOnError = true,
  errorText,
  onChange,
  children,
}: Props) => {
  return (
    <View>
      <Body style={[text.semibold, isError && highlightLabelOnError ? { color: RADICAL_RED } : undefined]}>
        {label}
      </Body>
      <Spacer size={8} />
      {children !== undefined ? children : Input !== undefined ? <Input isError={isError} onChange={onChange} /> : null}
      {isError && errorText && (
        <>
          <Spacer size={2} />
          <SmallBody style={{ color: RADICAL_RED }}>{errorText}</SmallBody>
        </>
      )}
    </View>
  );
};

export default InputWithLabel;
