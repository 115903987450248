import { StyleSheet } from 'react-native';

import * as colors from '../../styles/colors';

export const styles = StyleSheet.create({
  notificationContainer: {
    flexDirection: 'row',
  },
  notificationBody: {
    flexDirection: 'column',
    flex: 1,
    marginLeft: 12,
  },
  timeText: {
    marginTop: 6,
    color: colors.COMET,
    fontSize: 12,
    lineHeight: 17,
  },
});
