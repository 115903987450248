import * as React from 'react';
import { View, Animated, Easing, TouchableOpacity } from 'react-native';

import * as colors from '../../styles/colors';
import Icon from '../Icon';
import Body from '../Text/Body';
import { styles } from './toast.style';

export type ToastType = 'success' | 'info' | 'warning' | 'error';

export type ToastProps = {
  id: number;
  type: ToastType;
  text: string;
  autoDismiss?: boolean;
  manualDismiss?: boolean;
  onDismiss: () => void;
};

const ToastColors = {
  success: colors.SHAMROCK,
  info: colors.COMET,
  warning: colors.YELLOW_ORANGE,
  error: colors.RADICAL_RED,
} as const;
type ColorType = keyof typeof ToastColors;

/**
 * Map toast types to a background color.
 */
const backgroundColor: { [k in ToastType]: (typeof ToastColors)[ColorType] } = {
  success: ToastColors.success,
  info: ToastColors.info,
  warning: ToastColors.warning,
  error: ToastColors.error,
};

/**
 * Component used to render the actual toast.
 */
export const Toast: React.FC<ToastProps> = ({ type, text, onDismiss, manualDismiss }) => {
  const animation = React.useRef(new Animated.Value(0)).current;

  React.useEffect(() => {
    Animated.timing(animation, {
      easing: Easing.out(Easing.ease),
      toValue: 1,
      duration: 100,
      useNativeDriver: true,
    }).start();
  }, [animation]);

  return (
    <Animated.View
      style={[
        styles.toastContainer,
        {
          backgroundColor: backgroundColor[type],
          transform: [
            {
              translateY: animation.interpolate({
                inputRange: [0, 1],
                outputRange: [-70, 0],
              }),
            },
          ],
        },
      ]}
    >
      <Body style={{ color: colors.WHITE, flex: 1 }}>{text}</Body>
      {manualDismiss ? (
        <TouchableOpacity style={styles.dismissContainer} onPress={onDismiss}>
          <Icon type="close" size={24} color={colors.WHITE} disabled />
        </TouchableOpacity>
      ) : null}
    </Animated.View>
  );
};
export type Toast = ToastProps;
