import { HasUserId } from './user-id';
import { UserProfile } from './user-profile';
import { OptionalEmail } from '@mero/shared-sdk';
import * as t from 'io-ts';

export const User = t.intersection(
  [
    HasUserId,
    t.type({
      phone: t.string,
      profile: UserProfile,
      isActive: t.boolean,
      isLocal: t.boolean,
      isGhost: t.boolean,
      hasPin: t.boolean,
    }),
    t.partial({
      email: OptionalEmail.TRUSTED_JSON,
      facebook: t.type({
        id: t.union([t.string, t.null]),
      }),
    }),
  ],
  'User',
);

export type User = t.TypeOf<typeof User>;
