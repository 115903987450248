import { JSONable } from '../../common';
import { PositiveInt } from '../../numbers';
import * as t from 'io-ts';

/**
 * Event occurrs {@link WhenOccurred['times']} times, including first time
 */
export type WhenOccurred = {
  readonly times: PositiveInt;
  /**
   * This property is defined for easier type refinement checks
   */
  readonly date?: null | undefined;
};

/**
 * Event recurrence ends once ocurred given number of times
 */
const JSON: t.Type<WhenOccurred, JSONable> = t.intersection(
  [
    t.type(
      {
        times: PositiveInt.JSON,
      },
      '!',
    ),
    t.partial(
      {
        /**
         * Date has higher priority so it shouldn't be defined for this case
         */
        date: t.union([t.null, t.undefined]),
      },
      '?',
    ),
  ],
  'WhenOccurred',
);

export const WhenOccurred = {
  JSON,
  is: JSON.is,
};
