import { PageId } from '../../pages';
import { OtherProAppointmentNotificationSettings } from './otherProAppointmentNotificationSettings';
import { OwnAppointmentNotificationSettings } from './ownAppointmentNotificationSettings';
import { UserId } from '@mero/shared-sdk';
import { JSONable } from '@mero/shared-sdk';
import * as t from 'io-ts';

export type ProNotificationSettings = {
  readonly userId: UserId;
  readonly pageId: PageId;
  //settings for own appointments
  readonly ownAppointmentNotificationSettings: OwnAppointmentNotificationSettings;
  //settings for other pros appointments
  readonly otherProAppointmentNotificationSettings: OtherProAppointmentNotificationSettings;
};

const JSON: t.Type<ProNotificationSettings, JSONable> = t.type(
  {
    pageId: PageId,
    userId: UserId,
    ownAppointmentNotificationSettings: OwnAppointmentNotificationSettings.JSON,
    otherProAppointmentNotificationSettings: OtherProAppointmentNotificationSettings.JSON,
  },
  'ProNotificationSettings',
);

export const ProNotificationSettings = {
  JSON,
};
