import { StyleSheet } from 'react-native';

import { colors } from '../../styles';

export const styles = StyleSheet.create({
  container: {
    width: '100%',
    flexDirection: 'row',
    paddingTop: 12,
    paddingRight: 16,
    paddingBottom: 12,
    paddingLeft: 16,
    borderRadius: 5,
    backgroundColor: colors.RADICAL_RED,
  },
  text: {
    flex: 1,
    fontSize: 14,
    fontFamily: 'open-sans-semibold',
    lineHeight: 19,
    paddingTop: 6,
    paddingBottom: 6,
    color: colors.WHITE,
  },
  button: {
    width: 32,
    height: 32,
  },
});
