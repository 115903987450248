import { DefinedTrimedString } from './string';
import * as t from 'io-ts';

export interface ObjectIdBrand {
  readonly ObjectId: unique symbol;
}

export const ObjectId = t.brand(
  DefinedTrimedString,
  (id: string): id is t.Branded<DefinedTrimedString, ObjectIdBrand> => /^[a-f\d]{24}$/i.test(id),
  'ObjectId',
);

export type ObjectId = t.TypeOf<typeof ObjectId>;
