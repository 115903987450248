import { BoostCommissionPercent } from './boostCommissionPercent';
import * as t from 'io-ts';
import * as tt from 'io-ts-types';

export const PromotionInterval = t.intersection(
  [
    t.type(
      {
        from: tt.DateFromISOString,
        to: tt.DateFromISOString,
      },
      '!',
    ),
    t.partial(
      {
        commissionPercent: BoostCommissionPercent.codec,
      },
      '?',
    ),
  ],
  'PromotionInterval',
);

export type PromotionInterval = t.TypeOf<typeof PromotionInterval>;
