import * as React from 'react';

import LoginButton from '../Button/LoginButton';

export type Props = {
  title?: string;
  onPress?: () => void;
  disabled?: boolean;
};

export default function PhoneSignInButton({ title, onPress, disabled }: Props): React.ReactElement {
  return (
    <LoginButton icon="phone" text={title ?? 'Utilizează numărul de mobil'} onClick={onPress} disabled={disabled} />
  );
}
