import { CheckoutClientPreview } from '../checkoutClientPreview';
import { CheckoutTotals } from '../checkoutTotals';
import { CheckoutTransactionCode } from '../checkoutTransactionCode';
import { CheckoutTransactionDetails } from '../checkoutTransactionDetails';
import { CheckoutTransactionId } from '../checkoutTransactionId';
import { CheckoutTransactionPayment } from '../checkoutTransactionPayment';
import { MeroUnits, ScaledNumber } from '@mero/shared-sdk';

/**
 * Draft transaction Preview
 */
export type Draft<Unit extends MeroUnits.Any> = {
  readonly _id: CheckoutTransactionId;
  readonly status: 'Draft';
  readonly unit: Unit;
  readonly code: CheckoutTransactionCode;
  readonly createdAt: Date;
  readonly client?: CheckoutClientPreview;
  readonly total: CheckoutTotals<ScaledNumber, Unit>;
  readonly paymentTypes: CheckoutTransactionPayment.Any<ScaledNumber, Unit>['type'][];
};

export type AnyDraft = Draft<MeroUnits.RON> | Draft<MeroUnits.EUR>;

/**
 * Finished transaction Preview
 */
export type Finished<Unit extends MeroUnits.Any> = {
  readonly _id: CheckoutTransactionId;
  readonly status: 'Finished';
  readonly unit: Unit;
  readonly code: CheckoutTransactionCode;
  readonly createdAt: Date;
  readonly finishedAt: Date;
  readonly client?: CheckoutClientPreview;
  readonly total: CheckoutTotals<ScaledNumber, Unit>;
  readonly paymentTypes: CheckoutTransactionPayment.Any<ScaledNumber, Unit>['type'][];
  readonly isProtocol: boolean;
};

export type AnyFinished = Finished<MeroUnits.RON> | Finished<MeroUnits.EUR>;

/**
 * Deleted transaction Preview
 */
export type Deleted<Unit extends MeroUnits.Any> = {
  readonly _id: CheckoutTransactionId;
  readonly status: 'Deleted';
  readonly unit: Unit;
  readonly code: CheckoutTransactionCode;
  readonly createdAt: Date;
  readonly finishedAt: Date;
  readonly deletedAt: Date;
  readonly client?: CheckoutClientPreview;
  readonly total: CheckoutTotals<ScaledNumber, Unit>;
  readonly paymentTypes: CheckoutTransactionPayment.Any<ScaledNumber, Unit>['type'][];
  readonly isProtocol: boolean;
};

export type AnyDeleted = Deleted<MeroUnits.RON> | Deleted<MeroUnits.EUR>;

export type Of<Unit extends MeroUnits.Any> = Draft<Unit> | Finished<Unit> | Deleted<Unit>;

export type Any = AnyDraft | AnyFinished | AnyDeleted;

export const fromDraft = <Unit extends MeroUnits.Any>(details: CheckoutTransactionDetails.Draft<Unit>): Draft<Unit> => {
  return {
    _id: details._id,
    status: 'Draft',
    unit: details.unit,
    code: details.code,
    createdAt: details.createdAt,
    client: details.client,
    total: details.total,
    paymentTypes: details.payments.map((payment) => payment.type),
  };
};

export const fromFinished = <Unit extends MeroUnits.Any>(
  details: CheckoutTransactionDetails.Finished<Unit>,
): Finished<Unit> => {
  return {
    _id: details._id,
    status: 'Finished',
    unit: details.unit,
    code: details.code,
    createdAt: details.createdAt,
    finishedAt: details.finishedAt,
    client: details.client,
    total: details.total,
    paymentTypes: details.payments.map((payment) => payment.type),
    isProtocol: details.isProtocol,
  };
};

export const fromDeleted = <Unit extends MeroUnits.Any>(
  details: CheckoutTransactionDetails.Deleted<Unit>,
): Deleted<Unit> => {
  return {
    _id: details._id,
    status: 'Deleted',
    unit: details.unit,
    code: details.code,
    createdAt: details.createdAt,
    finishedAt: details.finishedAt,
    deletedAt: details.deletedAt,
    client: details.client,
    total: details.total,
    paymentTypes: details.payments.map((payment) => payment.type),
    isProtocol: details.isProtocol,
  };
};

export const fromDetails = <Unit extends MeroUnits.Any>(details: CheckoutTransactionDetails.Of<Unit>): Of<Unit> => {
  switch (details.status) {
    case 'Draft':
      return fromDraft(details);
    case 'Finished':
      return fromFinished(details);
    case 'Deleted':
      return fromDeleted(details);
  }
};
