import * as React from 'react';

/**
 * Type function returns 2nd function param type or void for give function R
 */
export type ExtractPayloadType<F> = F extends () => unknown
  ? void
  : F extends (state: infer S) => infer S
  ? void
  : F extends (state: infer S, payload: infer P) => infer S
  ? P
  : void;

/**
 * Base type for reducers declarations
 */
export type Reducers<S> = {
  readonly [key: string]: (state: S, payload: any) => S;
};

/**
 * This type function converts reducer function to dispatch method
 *  by hiding state argument and return type, ex:
 * (state: S) => S becomes () => void
 * (state: S, value: P) => S becomes (value: P) => void
 */
export type Reducer2Dispatch<R> = R extends () => unknown
  ? () => void
  : R extends (state: infer S) => infer S
  ? () => void
  : R extends (state: infer S, payload: infer P) => infer S
  ? (payload: P) => void
  : never;

/**
 * This type function converts reducers definition type to dispatchers, ex:
 * { inc: (state: S, payload: P) => S } becomes { inc: (payload: P) => void }
 */
export type ExtractDispatch<R extends Reducers<any>> = {
  readonly [k in keyof R]: Reducer2Dispatch<R[k]>;
};

/**
 * Action type to dispatch to reducer
 */
export type ReducerAction<R extends Reducers<any>, K extends keyof R = keyof R> = {
  readonly type: K;
  readonly payload: ExtractPayloadType<R[K]>;
};

/**
 * This function converts model reducers to dispatch methods
 * @param reducers model reducers
 * @param dispatch  dispatch function to trigger actions with
 */
const reducers2Dispatch = <R extends Reducers<any>>(
  reducers: R,
  dispatch: (a: ReducerAction<R>) => void,
): ExtractDispatch<R> =>
  Object.fromEntries(
    Object.keys(reducers).map((name: keyof R) => {
      const m = (p: ExtractPayloadType<R[typeof name]>): void => {
        dispatch({ type: name, payload: p });
      };

      // do not return directly, this assignment makes type inference work
      const result = [name, m];

      return result;
    }),
  );

/**
 * This function creates new react reducer using reducers and returns type-safe dispatch methods
 */
export const useReactReducer = <S, R extends Reducers<S>>(state: S, reducers: R): [S, ExtractDispatch<R>] => {
  const reducer = React.useCallback(
    (state: S, action: ReducerAction<R>): S => {
      if (action.type in reducers) {
        return reducers[action.type](state, action.payload);
      } else {
        return state;
      }
    },
    [reducers],
  );

  const [s, dispatchFn] = React.useReducer(reducer, state);

  const dispatch = React.useMemo(() => reducers2Dispatch(reducers, dispatchFn), [reducers, dispatchFn]);

  return [s, dispatch];
};

export type ModelContextProviderProps<S> = {
  readonly value?: S;
  readonly children: React.ReactNode | React.ReactNode[];
};

/**
 * Type defining structure for React.Context type given State, Reducers and Effects
 */
export type ModelContextState<S, E> = [S, E];

/**
 * ModelContext wraps State, Reducers and Effects into React.Context and provides simpler interface for data mutation
 */
export type ModelContext<S, E> = {
  readonly Provider: React.FunctionComponent<ModelContextProviderProps<S>>;
  readonly useContext: () => ModelContextState<S, E>;
};

/**
 * This function will create new model context builder for given model (default state, reducers and effects)
 * which can be then used to handle state for pages
 *
 * Returned builder takes an optional state argument, to overwrite previous default
 *
 * @param state default model state (this one is used here more for type inference, so dont' need to specify S explicitely)
 * @param reducers state reducers record(s)
 * @param effects state efects record(s)
 */
export const createModel = <S, R extends Reducers<S>, E extends Record<string, unknown>>(
  state: S,
  reducers: R,
  effects: (dispatch: ExtractDispatch<R>) => E,
): ((newDefault?: S) => ModelContext<S, E>) => {
  return (newDefault) => {
    const defaultState = newDefault ?? state;
    const emptyDispatch = reducers2Dispatch(reducers, () => {
      // pass, no actions over default state, use a reducer
      if ('warn' in console) {
        // TODO: maybe throw an error here, only allow explicit provider
        console.warn("Empty dispatch method called: looks like you didn't use a Provider for this model context");
      }
    });
    const Context = React.createContext<ModelContextState<S, E>>([
      defaultState,
      {
        ...emptyDispatch,
        ...effects(emptyDispatch),
      },
    ]);

    const Provider = ({
      children,
      value,
    }: ModelContextProviderProps<S>): React.ReactElement<ModelContextProviderProps<S>> => {
      // TODO: add a note about check for undefined, theoretically undefined is a valid default value too, but use undefined when creatingModelContext ...
      const [s, dispatch] = useReactReducer(value !== undefined ? value : defaultState, reducers);
      // Initialize effects dispatch only once
      const effDispatch = React.useMemo(() => effects(dispatch), [dispatch]);

      const contextValue = React.useMemo<ModelContextState<S, E>>(() => {
        return [s, effDispatch];
      }, [s, effDispatch]);

      return <Context.Provider value={contextValue}>{children}</Context.Provider>;
    };

    const useContext = () => React.useContext(Context);

    return { Provider, useContext };
  };
};

/**
 * This function will create new context for given model (default state, reducers and effects)
 * which can be then used to handle state for pages
 *
 * Provider will accept optional value or use default state
 */
export const createModelContext = <S, R extends Reducers<S>, E extends Record<string, unknown>>(
  state: S,
  reducers: R,
  effects: (dispatch: ExtractDispatch<R>) => E,
): ModelContext<S, E> => createModel(state, reducers, effects)();
