import { JSONable, optionull } from '@mero/shared-sdk';
import * as t from 'io-ts';

export type SavedCardPreview = {
  readonly id: string;
  readonly brand: string;
  readonly digits: string | undefined;
  readonly livemode: boolean;
};

const JSON: t.Type<SavedCardPreview, JSONable> = t.type(
  {
    id: t.string,
    brand: t.string,
    digits: optionull(t.string),
    livemode: t.boolean,
  },
  'SavedCardPreview',
);

export const SavedCardPreview = {
  JSON,
};
