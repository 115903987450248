import * as React from 'react';
import { StyleProp, View, ViewStyle, StyleSheet } from 'react-native';

import { styles } from './styles';

export type Props = React.PropsWithChildren<{
  style?: StyleProp<ViewStyle>;
}>;

const ModalOverlay: React.FC<Props> = ({ children, style }: Props) => {
  return (
    <View style={StyleSheet.compose<ViewStyle, ViewStyle, ViewStyle>(styles.container, style)}>
      <View style={styles.background} />
      {children}
    </View>
  );
};

export default ModalOverlay;
