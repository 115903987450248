import * as React from 'react';
import Svg, { G, Circle, Path } from 'react-native-svg';

type Props = {
  size?: number | string;
  disabled?: boolean;
  onPress?: () => void;
};

const MembershipRoundSmall = ({ size = 32, disabled, onPress }: Props) => (
  <Svg width={size} height={size} viewBox={`0 0 ${size} ${size}`} disabled={disabled} onPress={onPress}>
    <G transform="translate(0 -.5)">
      <Circle cx={16} cy={16} r={16} fill="#e9ecef" transform="translate(0 .5)" />
      <G fill="none">
        <Path d="M5.5 6h21v21h-21Z" data-name="Path 8275" />
        <G
          stroke="#52577f"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeMiterlimit={10}
          transform="translate(8.125 10.594)"
        >
          <Path d="M12.9 0v.258a1.29 1.29 0 1 1-2.58 0V0H5.159v.258a1.29 1.29 0 0 1-2.58 0V0H0v11.35h15.478V0ZM9.287 4.127h4.127M9.287 6.191h4.127M9.287 8.254h2.064" />
          <Circle cx={1.398} cy={1.398} r={1.398} data-name="layer1" transform="translate(3.265 3.355)" />
          <Path
            d="M4.643 6.191a2.605 2.605 0 0 0-2.578 2.657v.443h5.159v-.439a2.605 2.605 0 0 0-2.581-2.661Z"
            data-name="layer1"
          />
        </G>
      </G>
    </G>
  </Svg>
);

export default React.memo(MembershipRoundSmall);
