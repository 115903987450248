import { StyleSheet } from 'react-native';

import * as colors from '../../styles/colors';

export const styles = StyleSheet.create({
  textSkeleton: {
    height: 16,
    width: 148,
    marginTop: 3,
    marginBottom: 3,
    borderRadius: 4,
    backgroundColor: colors.ATHENS_GRAY,
  },
});
