import { CheckoutTransactionCodeC } from '../../checkout/checkoutHttpClient/checkoutJson';
import { CheckoutTransactionCode } from '../../checkout/checkoutTransactionCode';
import { ClientId } from '../../clients';
import { ServiceId } from '../../services/service-id';
import {
  JSONable,
  MeroUnits,
  Money,
  ScaledNumber,
  optionull,
  OptionalFirstname,
  OptionalLastname,
} from '@mero/shared-sdk';
import * as t from 'io-ts';
import * as tt from 'io-ts-types';

export type CheckoutSaleOwnerServiceSalesReportItem<Unit extends MeroUnits.Any> = {
  readonly service: {
    readonly _id: ServiceId;
    readonly name: string;
  };
  readonly client:
    | {
        readonly _id: ClientId;
        readonly firstname: OptionalFirstname;
        readonly lastname: OptionalLastname;
        readonly phone: string;
      }
    | undefined;
  readonly finishedAt: Date;
  /**
   * Transaction code
   */
  readonly code: CheckoutTransactionCode;
  /**
   * Services totals (includes only sold services, without services paid with membership)
   */
  readonly serviceTotal: Money<ScaledNumber, Unit>;
  /**
   * Membership consumption total
   */
  readonly membershipConsumption: Money<ScaledNumber, Unit>;
  /**
   * Protocol
   */
  readonly protocol: Money<ScaledNumber, Unit>;
  /**
   * Services totals (includes sold services and services paid with membership)
   */
  readonly total: Money<ScaledNumber, Unit>;
};

const json = <Unit extends MeroUnits.Any>(
  unit: Unit,
): t.Type<CheckoutSaleOwnerServiceSalesReportItem<Unit>, JSONable> => {
  return t.type(
    {
      service: t.type(
        {
          _id: ServiceId,
          name: t.string,
        },
        'Service',
      ),
      client: optionull(
        t.type(
          {
            _id: ClientId,
            firstname: OptionalFirstname,
            lastname: OptionalLastname,
            phone: t.string,
          },
          'Client',
        ),
      ),
      finishedAt: tt.DateFromISOString,
      code: CheckoutTransactionCodeC,
      serviceTotal: Money.json(ScaledNumber.JSON, t.literal(unit)),
      total: Money.json(ScaledNumber.JSON, t.literal(unit)),
      protocol: Money.json(ScaledNumber.JSON, t.literal(unit)),
      membershipConsumption: Money.json(ScaledNumber.JSON, t.literal(unit)),
    },
    `CheckoutSaleOwnerServiceSalesReportItem<${unit}>`,
  );
};

export const CheckoutSaleOwnerServiceSalesReportItem = {
  json,
};
