import { FeatureFlagsMp } from './featureFlagsMp';
import { JSONable } from '@mero/shared-sdk';
import * as t from 'io-ts';

export type MarketplaceBootState = {
  readonly featureFlags: FeatureFlagsMp;
  readonly shouldUpdateApp: boolean;
};

const JSON: t.Type<MarketplaceBootState, JSONable> = t.type(
  {
    featureFlags: FeatureFlagsMp,
    shouldUpdateApp: t.boolean,
  },
  'MarketplaceBootState',
);

export const MarketplaceBootState = {
  JSON,
};
