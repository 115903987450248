import { useReducer, useCallback } from 'react';

const reducer = <T, P>(state: T, action: { type: 'update'; payload: P }) => {
  switch (action.type) {
    case 'update':
      return { ...state, ...action.payload };
    default:
      throw new Error();
  }
};

export const useSimpleReducer = <T extends Record<string, unknown>, P extends Record<string, unknown>>(
  initialState: T,
): [any, (p: P) => void] => {
  const [state, dispatch] = useReducer(reducer, initialState);

  const updateState = useCallback(
    (payload: P) =>
      dispatch({
        type: 'update',
        payload,
      }),
    [],
  );

  return [state, updateState];
};
