import { ObjectId } from '@mero/shared-sdk';
import * as t from 'io-ts';

export interface MembershipTemplateIdBrand {
  readonly MembershipTemplateId: unique symbol;
}

export type MembershipTemplateId = t.Branded<ObjectId, MembershipTemplateIdBrand>;

const JSON: t.Type<MembershipTemplateId, string> = t.brand(
  ObjectId,
  (_id: ObjectId): _id is MembershipTemplateId => true,
  'MembershipTemplateId',
);

export const MembershipTemplateId = {
  JSON,
};
