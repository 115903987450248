import * as React from 'react';
import Svg, { Path, G, Circle } from 'react-native-svg';

import { BLACK } from '../../../styles/colors';

type Props = {
  size?: string | number;
  color?: string;
  disabled?: boolean;
  onPress?: () => void;
};

const Location: React.FC<Props> = ({ size = 24, color = BLACK, disabled = false, onPress }) => (
  <Svg width={size} height={size} viewBox="0 0 24 24" disabled={disabled} onPress={onPress}>
    <G
      transform="translate(-5 3)"
      fill="none"
      stroke={color}
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeMiterlimit={10}
      strokeWidth={1.3}
    >
      <Path d="M17 2a5 5 0 00-5 5c0 4.5 5 9.75 5 9.75S22 11.5 22 7a5 5 0 00-5-5z" />
      <Circle cx={2.5} cy={2.5} r={2.5} transform="translate(14.5 4.69)" />
    </G>
  </Svg>
);

export default Location;
