import { Day } from './day';
import { Month } from './month';
import { Week } from './week';
import { Year } from './year';
import * as t from 'io-ts';

export { Day } from './day';
export { Month } from './month';
export { Week } from './week';
export { Year } from './year';

export type Any = Day | Week | Month | Year;

export const JSON = t.union([Day.JSON, Week.JSON, Month.JSON, Year.JSON], 'RecurrenceTimeUnit');

export const is = JSON.is;

export const DAYS: Day = 'days';

export const WEEKS: Week = 'weeks';

export const MONTHS: Month = 'months';

export const YEARS: Year = 'years';

export const equals = (a: Any, b: Any): boolean => {
  if (Day.is(a) && Day.is(b)) {
    return true;
  }

  if (Week.is(a) && Week.is(b)) {
    return true;
  }

  if (Month.is(a) && Month.is(b)) {
    return true;
  }

  if (Year.is(a) && Year.is(b)) {
    return true;
  }

  return false;
};
