import { Firstname, Lastname } from '../common';
import { UserId } from '../users/user-id';
import { UsersApi } from '../users/users-api';
import { BoostClient } from './boost-client';
import { ClientAppointment } from './client-appointment';
import { ClientHistoryRecordArray, ClientNoteHistoryRecord } from './client-history-record';
import { ClientHistoryType } from './client-history-type';
import { ClientId } from './client-id';
import { InviteStatus } from './client-invite-status';
import { ClientProfile } from './client-profile';
import { ClientUser } from './client-user';
import { ClientsApi } from './clients-api';
import { ClientPageStats } from './page-client-stats';
import { SavedClient } from './saved-client';
import {
  HttpClient,
  unsafeRight,
  UnknownApiError,
  OptionalPhoneNumber,
  DecodeError,
  Email,
  DateString,
  PhoneNumber,
} from '@mero/shared-sdk';
import * as A from 'fp-ts/lib/Array';
import * as E from 'fp-ts/lib/Either';
import * as O from 'fp-ts/lib/Option';
import { pipe } from 'fp-ts/lib/function';
import * as t from 'io-ts';
import { NonEmptyString, NumberFromString } from 'io-ts-types';

/**
 * ClientsApi HTTP client implementation
 */
export const clientsHttpClient = (env: { apiBaseUrl: string; http: HttpClient; users: UsersApi }): ClientsApi => {
  const { apiBaseUrl, http, users } = env;

  const unknownResponseDecoder = http.decode.response(UnknownApiError, t.unknown);

  const getClientByIdDecoder = http.decode.response(UnknownApiError, SavedClient);
  const getClientProfileByIdDecoder = http.decode.response(UnknownApiError, ClientProfile);
  const deleteClientDecoder = unknownResponseDecoder;
  const updateClientDecoder = http.decode.response(UnknownApiError, t.unknown);
  const updateClientNoteDecoder = unknownResponseDecoder;
  const createClientHistoryNoteDecoder = http.decode.response(UnknownApiError, ClientNoteHistoryRecord);
  const deleteClientHistoryNoteDecoder = unknownResponseDecoder;
  const updateClientHistoryNoteDecoder = unknownResponseDecoder;
  const searchClientsDecoder = http.decode.response(UnknownApiError, t.array(SavedClient));
  const countClientsDecoder = http.decode.response(UnknownApiError, t.type({ count: t.number }));
  const createClientDecoder = http.decode.response(
    UnknownApiError,
    t.type({
      _id: ClientId,
    }),
  );
  const createClientInvite = unknownResponseDecoder;
  const getClientInvitationStatus = http.decode.response(UnknownApiError, InviteStatus);
  const importClientsDecoder = http.decode.response(
    UnknownApiError,
    t.type({ imported: t.number, failed: t.number, processed: t.number }),
  );
  const updateClientIsBlockedDecoder = unknownResponseDecoder;
  const updateclientIsWarnedDecoder = unknownResponseDecoder;
  const getClientAppointmentsDecoder = http.decode.response(UnknownApiError, t.array(ClientAppointment));
  const getBoostClientsDecoder = http.decode.response(
    UnknownApiError,
    t.type({
      clients: t.array(BoostClient),
    }),
  );

  return {
    getClientById: async (clientId) => {
      return unsafeRight(
        await http.request(
          {
            method: 'GET',
            url: `${apiBaseUrl}/business/client/${encodeURIComponent(clientId)}`,
          },
          getClientByIdDecoder,
        ),
      );
    },
    getClientProfileById: async (clientId) => {
      return unsafeRight(
        await http.request(
          {
            method: 'GET',
            url: `${apiBaseUrl}/business/client/${encodeURIComponent(clientId)}/profile`,
          },
          getClientProfileByIdDecoder,
        ),
      );
    },
    deleteClient: async (clientId) => {
      unsafeRight(
        await http.request(
          {
            method: 'DELETE',
            url: `${apiBaseUrl}/business/client/${encodeURIComponent(clientId)}`,
          },
          deleteClientDecoder,
        ),
      );
    },
    updateClient: async ({ clientId, firstname, lastname, birthday, email }) => {
      const params: {
        firstname: string;
        lastname?: string;
        birthday?: DateString;
        email?: Email;
      } = {
        firstname,
        lastname,
        email,
        birthday,
      };

      unsafeRight(
        await http.request(
          {
            method: 'PUT',
            url: `${apiBaseUrl}/business/client/${encodeURIComponent(clientId)}`,
            data: params,
          },
          updateClientDecoder,
        ),
      );
    },
    updateClientRemark: async ({ clientId, remark, showRemarkOnCalendar }) => {
      const params: {
        remark: string | undefined;
        showRemarkOnCalendar: boolean;
      } = {
        remark,
        showRemarkOnCalendar,
      };

      unsafeRight(
        await http.request(
          {
            method: 'PUT',
            url: `${apiBaseUrl}/business/client/${encodeURIComponent(clientId)}/remark`,
            data: params,
          },
          updateClientNoteDecoder,
        ),
      );
    },
    createClientHistoryNote: async ({ clientId, note }) => {
      const params: {
        text: string;
      } = {
        text: note,
      };

      return unsafeRight(
        await http.request(
          {
            method: 'POST',
            url: `${apiBaseUrl}/business/client/${encodeURIComponent(clientId)}/history/note`,
            data: params,
          },
          createClientHistoryNoteDecoder,
        ),
      );
    },
    deleteClientHistoryNote: async ({ clientId, noteId }) => {
      unsafeRight(
        await http.request(
          {
            method: 'DELETE',
            url: `${apiBaseUrl}/business/client/${encodeURIComponent(clientId)}/history/note/${encodeURIComponent(
              noteId,
            )}`,
          },
          deleteClientHistoryNoteDecoder,
        ),
      );
    },
    updateClientHistoryNote: async ({ clientId, noteId, note }) => {
      const params: {
        text: string;
      } = {
        text: note,
      };

      unsafeRight(
        await http.request(
          {
            method: 'PUT',
            url: `${apiBaseUrl}/business/client/${encodeURIComponent(clientId)}/history/note/${encodeURIComponent(
              noteId,
            )}`,
            data: params,
          },
          updateClientHistoryNoteDecoder,
        ),
      );
    },
    getClientHistory: async (clientId) => {
      const unknownHistoryRecordArray = http.decode.response(UnknownApiError, t.array(t.unknown));

      const unknownHistoryRecords = unsafeRight(
        await http.request(
          {
            method: 'GET',
            url: `${apiBaseUrl}/business/client/${encodeURIComponent(clientId)}/history`,
          },
          unknownHistoryRecordArray,
        ),
      );
      const withHistoryTypeCodec = t.type({
        type: ClientHistoryType,
      });

      return pipe(
        unknownHistoryRecords,
        A.map(withHistoryTypeCodec.decode),
        A.chain(
          E.fold(
            () => [],
            (n) => [n],
          ),
        ),
        ClientHistoryRecordArray.decode,
        E.getOrElseW((e) => {
          throw new DecodeError(e);
        }),
      );
    },
    getPageClientStats: async (params) => {
      return unsafeRight(
        await http.request(
          {
            method: 'GET',
            url: `${apiBaseUrl}/business/pages/${encodeURIComponent(params.pageId)}/clients/${encodeURIComponent(
              params.clientId,
            )}/stats`,
            params: {
              currency: encodeURIComponent(params.currency),
            },
          },
          http.decode.response(UnknownApiError, ClientPageStats.json(params.currency)),
        ),
      );
    },
    search: async ({ pageId, ...query }) => {
      const params: {
        search?: string;
        userId?: string;
        isBlocked?: boolean;
        isWarned?: boolean;
        isFavourite?: boolean;
        autocomplete?: boolean;
        offset?: number;
        limit?: number;
        sortBy?: 'lastAppointment' | 'user.fullname';
      } = {
        ...query,
      };

      return unsafeRight(
        await http.request(
          {
            method: 'GET',
            url: `${apiBaseUrl}/business/client/page/${encodeURIComponent(pageId)}`,
            params: params,
          },
          searchClientsDecoder,
        ),
      );
    },
    count: async ({ pageId, ...query }) => {
      const params: {
        search?: string;
        isBlocked?: boolean;
        isWarned?: boolean;
        isFavourite?: boolean;
        autocomplete?: boolean;
      } = {
        ...query,
      };

      const { count } = unsafeRight(
        await http.request(
          {
            method: 'GET',
            url: `${apiBaseUrl}/business/client/page/${encodeURIComponent(pageId)}/count`,
            params: params,
          },
          countClientsDecoder,
        ),
      );

      return count;
    },
    createClient: async ({ pageId, userId, firstname, lastname, remark, showRemarkOnCalendar, email, birthday }) => {
      const data: {
        userId: UserId;
        firstname: Firstname;
        lastname?: Lastname;
        remark?: NonEmptyString;
        showRemarkOnCalendar?: boolean;
        email?: Email;
        birthday?: DateString;
      } = {
        userId,
        firstname,
        lastname,
        remark,
        showRemarkOnCalendar,
        email,
        birthday,
      };

      const { _id: clientId } = unsafeRight(
        await http.request(
          {
            method: 'POST',
            url: `${apiBaseUrl}/business/client/page/${encodeURIComponent(pageId)}/add`,
            data: data,
          },
          createClientDecoder,
        ),
      );

      return clientId;
    },
    createClientByPhone: async ({
      pageId,
      phone,
      firstname,
      lastname,
      remark,
      showRemarkOnCalendar,
      email,
      birthday,
    }) => {
      const data: {
        phone: PhoneNumber;
        firstname: Firstname;
        lastname?: Lastname;
        remark?: NonEmptyString;
        showRemarkOnCalendar?: boolean;
        email?: Email;
        birthday?: DateString;
      } = {
        phone,
        firstname,
        lastname,
        remark,
        showRemarkOnCalendar,
        email,
        birthday,
      };

      const { _id: clientId } = unsafeRight(
        await http.request(
          {
            method: 'POST',
            url: `${apiBaseUrl}/business/client/page/${encodeURIComponent(pageId)}/add`,
            data: data,
          },
          createClientDecoder,
        ),
      );

      return clientId;
    },
    createClientInvite: async (workerId) => {
      const data = {
        toWorkerId: workerId,
      };

      unsafeRight(
        await http.request(
          {
            method: 'POST',
            url: `${apiBaseUrl}/business/invite/client-join`,
            data,
          },
          createClientInvite,
        ),
      );
    },
    getClientInvitationStatus: async (workerId) => {
      return unsafeRight(
        await http.request(
          {
            method: 'GET',
            url: `${apiBaseUrl}/business/invite/client/status/${workerId}`,
          },
          getClientInvitationStatus,
        ),
      );
    },
    importClients: async ({ pageId, clientData, updateClients }) => {
      const requestBody = {
        clientData,
        updateClients,
      };
      return unsafeRight(
        await http.request(
          {
            method: 'POST',
            url: `${apiBaseUrl}/business/client/page/${encodeURIComponent(pageId)}/import`,
            data: requestBody,
          },
          importClientsDecoder,
        ),
      );
    },
    updateClientIsBlocked: async ({ clientId, isBlocked }) => {
      if (isBlocked) {
        unsafeRight(
          await http.request(
            {
              method: 'POST',
              url: `${apiBaseUrl}/business/client/${encodeURIComponent(clientId)}/block`,
            },
            updateClientIsBlockedDecoder,
          ),
        );
      } else {
        unsafeRight(
          await http.request(
            {
              method: 'DELETE',
              url: `${apiBaseUrl}/business/client/${encodeURIComponent(clientId)}/block`,
            },
            updateClientIsBlockedDecoder,
          ),
        );
      }
    },
    updateClientIsWarned: async ({ clientId, isWarned }) => {
      if (isWarned) {
        unsafeRight(
          await http.request(
            {
              method: 'POST',
              url: `${apiBaseUrl}/business/client/${encodeURIComponent(clientId)}/warn`,
            },
            updateclientIsWarnedDecoder,
          ),
        );
      } else {
        unsafeRight(
          await http.request(
            {
              method: 'DELETE',
              url: `${apiBaseUrl}/business/client/${encodeURIComponent(clientId)}/warn`,
            },
            updateclientIsWarnedDecoder,
          ),
        );
      }
    },
    getClientAppointments: async (clientId) =>
      unsafeRight(
        await http.request(
          {
            method: 'GET',
            url: `${apiBaseUrl}/business/client/${encodeURIComponent(clientId)}/appointments`,
          },
          getClientAppointmentsDecoder,
        ),
      ),
    getBoostClients: async (query) => {
      const queryParams: {
        type: 'new' | 'claimed';
        from: string;
        to: string;
        offset?: string;
      } = {
        type: query.type,
        from: query.period.from.toISOString(),
        to: query.period.to.toISOString(),
        offset: query.offset ? NumberFromString.encode(query.offset) : undefined,
      };

      const { clients } = unsafeRight(
        await http.request(
          {
            method: 'GET',
            url: `${apiBaseUrl}/business/page/${encodeURIComponent(query.pageId)}/boost-clients`,
            params: queryParams,
          },
          getBoostClientsDecoder,
        ),
      );

      return clients;
    },
    requestBoostClientClaim: async ({ pageId, userId, reason, userPhoneNumber }) => {
      const requestBody: {
        userId: string;
        reason: string;
        userPhoneNumber: OptionalPhoneNumber;
      } = {
        userId,
        reason,
        userPhoneNumber,
      };
      await unsafeRight(
        await http.request(
          {
            method: 'POST',
            url: `${apiBaseUrl}/business/page/${encodeURIComponent(pageId)}/boost-claims`,
            data: requestBody,
          },
          unknownResponseDecoder,
        ),
      );
    },
    acceptBoostClientCommission: async ({ pageId, userId }) => {
      await unsafeRight(
        await http.request(
          {
            method: 'PUT',
            url: `${apiBaseUrl}/business/page/${encodeURIComponent(pageId)}/boost-clients/${encodeURIComponent(
              userId,
            )}/accept-commission`,
          },
          unknownResponseDecoder,
        ),
      );
    },
  };
};
