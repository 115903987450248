import * as MeroApi from '@mero/api-sdk';
import { DateTime } from 'luxon';
import * as React from 'react';
import { View } from 'react-native';

import { colors } from '../../../styles';
import sizes from '../../../styles/sizes';
import { styles as text } from '../../../styles/text';
import { formatTimeDiff } from '../../../utils/format';
import HSpacer from '../../HSpacer';
import Icon from '../../Icon';
import Body from '../../Text/Body';
import SmallBody from '../../Text/SmallBody';
import { styles } from '../styles';

export type Props = {
  readonly notification: MeroApi.notifications.ClientAppointmentMadeNotification;
};

const ClientAppointmentMadeNotification: React.FC<Props> = ({ notification }: Props) => {
  const { payload } = notification;

  const date = DateTime.fromJSDate(payload.appointment.start, { zone: payload.appointment.timezone });

  return (
    <View style={styles.notificationContainer}>
      <Icon type="notification_calendar" />
      <View style={styles.notificationBody}>
        <Body>
          <Body style={text.semibold}>
            {payload.client.firstname} {payload.client.lastname}
          </Body>{' '}
          și-a făcut o programare in <Body style={text.semibold}>{date.toFormat('d LLL yyyy', { locale: 'ro' })}</Body>{' '}
          la ora <Body style={text.semibold}>{date.toFormat('H:mm', { locale: 'ro' })}</Body> la {payload.page.name}.
          {payload.appointment.coupons?.some((coupon) => coupon.type === MeroApi.CouponType.GIFTCARD) ? (
            <Body> Un card cadou a fost adăugat.</Body>
          ) : null}
          {payload.appointment.coupons?.some((coupon) => coupon.type === MeroApi.CouponType.DISCOUNT) ? (
            <Body> Un cod de reducere a fost adăugat.</Body>
          ) : null}
          {payload.appointment.coupons?.some((coupon) => coupon.type === MeroApi.CouponType.VOUCHER) ? (
            <Body> Un voucher a fost adăugat.</Body>
          ) : null}
          {payload.appointment.coupons?.some((coupon) =>
            [MeroApi.CouponType.GIFTCARD, MeroApi.CouponType.DISCOUNT, MeroApi.CouponType.VOUCHER].every(
              (type) => type !== coupon.type,
            ),
          ) ? (
            <Body> Un cod de reducere a fost adăugat.</Body>
          ) : null}
        </Body>
        <SmallBody style={styles.timeText}>
          {formatTimeDiff(notification.createdAt, new Date())} • {payload.worker.firstname}{' '}
          {payload.worker.lastname ?? ''} {/* Appointment duration not available in notification payload! */}
        </SmallBody>
      </View>
      <HSpacer left={8} />
      <Icon type="next" size={sizes[24]} color={colors.DARK_BLUE} />
    </View>
  );
};

export default ClientAppointmentMadeNotification;
