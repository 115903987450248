import * as React from 'react';
import Svg, { Path, G } from 'react-native-svg';

type Props = {
  size?: string | number;
  color?: string;
  disabled?: boolean;
  onPress?: () => void;
};

const Expand: React.FC<Props> = ({ size = 24, color = '#080de0', disabled = false, onPress }) => {
  return (
    <Svg width={size} height={size} viewBox={`0 0 ${size} ${size}`} disabled={disabled} onPress={onPress}>
      <G transform="translate(5850 -2177) rotate(90)">
        <Path
          d="M5.885,11.743,4.5,10.358,9.069,5.869,4.5,1.384,5.885,0l5.954,5.869Z"
          transform="translate(2180.522 5832)"
          fill={color}
        />
      </G>
    </Svg>
  );
};

export default React.memo(Expand);
