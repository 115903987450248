import { HasOptionalFirstLastName } from '../../common';
import { PageId } from '../../pages/page-id';
import { WorkerId } from '../../workers/workerId';
import { AppointmentId } from '../appointment-id';
import { BlockedTimeReason } from '../blocked-time-reason';
import { CalendarId } from '../calendar-id';
import { Recurrence } from './recurrence';
import { Type } from './type';
import { HasId, JSONable, ProfileImage } from '@mero/shared-sdk';
import * as t from 'io-ts';
import * as tt from 'io-ts-types';

type Payload = {
  readonly page: HasId<PageId>;
  readonly worker: HasId<WorkerId> &
    HasOptionalFirstLastName & {
      readonly profilePhoto: ProfileImage;
    };
  readonly reason: BlockedTimeReason;
};

const Payload: t.Type<Payload, JSONable> = t.type(
  {
    page: HasId.json(PageId),
    worker: t.intersection([
      HasId.json(WorkerId.JSON),
      HasOptionalFirstLastName,
      t.type({
        profilePhoto: ProfileImage,
      }),
    ]),
    reason: BlockedTimeReason,
  },
  'BlockedCalendarEntryPayload',
);

export type BlockedTime = {
  readonly type: Type.BlockedTime;
  readonly _id: AppointmentId;
  readonly calendarId: CalendarId;
  readonly start: Date;
  readonly end: Date;
  readonly occurrenceIndex: number;
  readonly payload: Payload;
} & Recurrence.Any;

const JSON: t.Type<BlockedTime, JSONable> = t.intersection(
  [
    t.type(
      {
        type: Type.BlockedTime.JSON,
        _id: AppointmentId,
        calendarId: CalendarId,
        start: tt.DateFromISOString,
        end: tt.DateFromISOString,
        occurrenceIndex: t.number,
        payload: Payload,
      },
      '!',
    ),
    Recurrence.Any.JSON,
  ],
  'CalendarEntry.BlockedTime',
);

export const BlockedTime = {
  JSON,
};
