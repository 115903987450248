import { ObjectId } from '@mero/shared-sdk';
import * as t from 'io-ts';

export interface CalendarIdBrand {
  readonly CalendarId: unique symbol;
}

export const CalendarId = t.brand(
  ObjectId,
  (_id: ObjectId): _id is t.Branded<ObjectId, CalendarIdBrand> => true,
  'CalendarId',
);

export type CalendarId = t.TypeOf<typeof CalendarId>;
