import * as React from 'react';
import Svg, { G, Circle, Path, Rect } from 'react-native-svg';

type Props = {
  size?: number | string;
  disabled?: boolean;
  onPress?: () => void;
};

const CashAndCard = ({ size = 32, disabled, onPress }: Props) => (
  <Svg width={size} height={size} viewBox={`0 0 ${size} ${size}`} disabled={disabled} onPress={onPress}>
    <G data-name="Group 7660" transform="translate(-15244 5808)">
      <Circle cx="16" cy="16" r="16" fill="#e9ecef" data-name="Ellipse 687" transform="translate(15244 -5808)" />
      <G data-name="Group 7655">
        <Path fill="none" d="M15249-5803h22v22h-22z" data-name="Rectangle 59" />
        <G data-name="mark_email_unread-24px (1)">
          <Path fill="none" d="M15251.754-5801.18h16.5v16.5h-16.5z" data-name="Rectangle 65" />
          <G data-name="Group 7106">
            <G fill="#e9ecef" data-name="Group 7110">
              <G stroke="#52577f" data-name="Rectangle 2690" transform="translate(15258.175 -5796.882)">
                <Rect width="10.079" height="12.039" stroke="none" rx="2" />
                <Rect width="9.079" height="11.039" x="0.5" y="0.5" fill="none" rx="1.5" />
              </G>
              <Path d="m15257.748-5796.557 6.68-3.68-5.15 17.964-1.535-2.27Z" data-name="Path 9080" />
            </G>
            <G fill="#e9ecef" data-name="Group 7109">
              <G stroke="#52577f" data-name="Rectangle 2690" transform="translate(15251.747 -5798.702)">
                <Rect width="9.799" height="12.039" stroke="none" rx="2" />
                <Rect width="8.799" height="11.039" x="0.5" y="0.5" fill="none" rx="1.5" />
              </G>
              <Path d="m15262.29-5786.189-5.41.49 3.91-13.997 1.498 1.306Z" data-name="Path 9081" />
            </G>
            <Path d="m15257.685-5783.817 4.954-16.576.536.164-4.954 16.576Z" data-name="Path 9082" />
          </G>
        </G>
      </G>
    </G>
  </Svg>
);

export default React.memo(CashAndCard);
