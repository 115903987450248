import { ObjectId } from '../common/object-id';
import * as t from 'io-ts';

export interface UserIdBrand {
  readonly UserId: unique symbol;
}

export const UserId = t.brand(ObjectId, (_id: ObjectId): _id is t.Branded<ObjectId, UserIdBrand> => true, 'UserId');

export type UserId = t.TypeOf<typeof UserId>;

export const HasUserId = t.type(
  {
    _id: UserId,
  },
  'HasUserId',
);
export interface HasUserId extends t.TypeOf<typeof HasUserId> {}
