import { PageRoleId } from './page-role-id';
import { Option } from '@mero/shared-sdk';
import * as t from 'io-ts';

export const PageRolePreview = t.strict(
  {
    /**
     * Unique role identifier
     */
    id: PageRoleId,
    /**
     * Role name (to display to users)
     */
    name: t.string,
    /**
     * When set to true - means role assumes a worker profile
     */
    isWorkerRole: Option.json(t.boolean),
    /**
     * When set to true - means role assumes an account owner
     */
    isOwnerRole: Option.json(t.boolean),
  },
  'PageRolePreview',
);

export interface PageRolePreview extends t.TypeOf<typeof PageRolePreview> {}
