import { MembershipTemplateId } from '../memberships/membershipTemplateId';
import { CheckoutItemTotalScaledNumber } from './checkoutItemTotalScaledNumber';
import { CheckoutPurchasedMembershipValidity } from './checkoutPurchasedMembershipValidity';
import { CheckoutTotals } from './checkoutTotals';
import { CheckoutTotalsScaledNumber } from './checkoutTotalsScaledNumber';
import { Membership, MembershipItem } from './checkoutTransactionItem/membership';
import { CompanyVatStatus } from './companies';
import { AppliedDiscount, ScaledNumber, MeroUnits } from '@mero/shared-sdk';
import * as Nea from 'fp-ts/NonEmptyArray';

/**
 * Membership template details
 */
export type CheckoutTransactionMembershipTemplateDetails<Unit extends MeroUnits.Any> = {
  readonly _id: MembershipTemplateId;
  /**
   * Membership name
   */
  readonly name: string;
  /**
   * Membership availability period (can be only use within this period, unless it's unlimited)
   */
  readonly validity: CheckoutPurchasedMembershipValidity;
  /**
   * Membership items
   */
  readonly items: Nea.NonEmptyArray<MembershipItem<Unit>>;
  /**
   * Optional global discount for all membership items
   */
  readonly discount: AppliedDiscount<ScaledNumber, Unit> | undefined;
};

const getPrice = <Unit extends MeroUnits.Any>(
  template: CheckoutTransactionMembershipTemplateDetails<Unit>,
  unit: Unit,
  companyVat: CompanyVatStatus.Any<ScaledNumber>,
  decimals: number,
): CheckoutTotals<ScaledNumber, Unit> => {
  const subtotal = template.items.reduce((totals, item): CheckoutTotals<ScaledNumber, Unit> => {
    switch (item.type) {
      case 'Service': {
        return CheckoutTotalsScaledNumber.add(
          totals,
          CheckoutItemTotalScaledNumber.getTotals(item.total, companyVat, decimals),
        );
      }
      case 'Product': {
        return totals;
      }
    }
  }, CheckoutTotalsScaledNumber.zero(unit));

  if (template.discount) {
    return CheckoutTotalsScaledNumber.applyDiscount(subtotal, template.discount, decimals);
  } else {
    return subtotal;
  }
};

export const CheckoutTransactionMembershipTemplateDetails = {
  getPrice,
};
