import { CompanyEmitReceiptStatus } from './companyEmitReceiptStatus';
import { CompanyVatStatus } from './companyVatStatus';
import {
  decode,
  Email,
  err,
  ok,
  Result,
  ScaledNumber,
  StrictPhoneNumber,
  validated,
  Validated,
} from '@mero/shared-sdk';
import { NonEmptyString } from 'io-ts-types';

/**
 * Checkout company details
 * @deprecated use billing details
 */
export type CheckoutCompanyDetails = {
  readonly name: NonEmptyString;
  readonly regNo: NonEmptyString;
  readonly fiscalCode: NonEmptyString;
  readonly countryCode: NonEmptyString;
  readonly county?: NonEmptyString;
  readonly city: NonEmptyString;
  readonly address: NonEmptyString;
  readonly phone?: StrictPhoneNumber;
  readonly email?: Email;
  readonly vatStatus: CompanyVatStatus.Any<ScaledNumber>;
  readonly emitReceiptStatus: CompanyEmitReceiptStatus.Any;
};

const validate = (details: CheckoutCompanyDetails): Result<Validated<CheckoutCompanyDetails>, Error> => {
  const fiscalCode = decode(NonEmptyString, details.fiscalCode.toUpperCase());
  if (!fiscalCode.isOk) {
    return fiscalCode;
  }

  if (details.vatStatus.type === 'VatPayer') {
    if (!/^RO\d+$/.test(fiscalCode.value)) {
      return err(new Error(`Invalid fiscal code "${details.fiscalCode}"`));
    }
  } else {
    if (!/^\d+$/.test(fiscalCode.value)) {
      return err(new Error(`Invalid fiscal code "${details.fiscalCode}"`));
    }
  }

  return ok(
    validated({
      ...details,
      fiscalCode: fiscalCode.value,
    }),
  );
};

export const CheckoutCompanyDetails = {
  validate,
};
