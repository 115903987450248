import { JSONable } from '../common';
import * as t from 'io-ts';

export type UserFlags = {
  /**
   * User can create appointments in marketplace
   */
  readonly canCreateAppointmentInMp: boolean;
  /**
   * User wants to receive SMS messages from MERO short number
   * (can be set to false only if user has ghost account)
   */
  readonly meroCloudSmsNotificationsEnabled: boolean;
};

const DEFAULT = {
  canCreateAppointmentInMp: true,
  meroCloudSmsNotificationsEnabled: true,
};

const JSON: t.Type<UserFlags, JSONable> = t.type(
  {
    canCreateAppointmentInMp: t.boolean,
    meroCloudSmsNotificationsEnabled: t.boolean,
  },
  'UserFlags',
);

export const UserFlags = {
  JSON,
  DEFAULT,
};
