import { Code128Type, Code39Type, EANType, UPCType } from './barcodeType';
import { Code39String } from './code39String';
import { Code128String } from './code128String';
import { EANString } from './eANString';
import { UPCString } from './uPCString';
import * as t from 'io-ts';

export * from './code39String';
export * from './code128String';
export * from './uPCString';
export * from './eANString';
export * from './barcodeType';
export * from './barcodeValue';

export type Code39 = {
  readonly type: Code39Type;
  readonly value: Code39String;
};

export type Code128 = {
  readonly type: Code128Type;
  readonly value: Code128String;
};

export type UPC = {
  readonly type: UPCType;
  readonly value: UPCString;
};

export type EAN = {
  readonly type: EANType;
  readonly value: EANString;
};

export type Any = Code39 | Code128 | UPC | EAN;

export const JSON = t.union(
  [
    t.type(
      {
        type: Code39Type.JSON,
        value: Code39String.JSON,
      },
      'Code39Barcode',
    ),
    t.type(
      {
        type: Code128Type.JSON,
        value: Code128String.JSON,
      },
      'Code128Barcode',
    ),
    t.type(
      {
        type: UPCType.JSON,
        value: UPCString.JSON,
      },
      'UPCBarcode',
    ),
    t.type(
      {
        type: EANType.JSON,
        value: EANString.JSON,
      },
      'EANBarcode',
    ),
  ],
  'Barcode',
);
