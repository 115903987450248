import * as React from 'react';
import { StyleProp, TextStyle, TouchableOpacityProps, TouchableOpacity, View } from 'react-native';

import Body from '../Text/Body';

type ContainerProps = React.PropsWithChildren<{
  height: number;
  backgroundColor: string;
}>;

const Container: React.FC<ContainerProps> = ({ height, backgroundColor, children }) => {
  return (
    <View
      style={{
        width: '100%',
        padding: 3,
        flexDirection: 'row',
        justifyContent: 'center',
        height: height,
        backgroundColor: backgroundColor,
        borderRadius: 25,
      }}
    >
      {children}
    </View>
  );
};

type ButtonProps = React.PropsWithChildren<{
  width: number;
  height?: number;
  isSelected: boolean;
  backgroundColor: string;
  selectedBackgroundColor?: string;
}>;

const Button: React.FC<ButtonProps & TouchableOpacityProps> = ({
  width,
  height = 45,
  isSelected,
  backgroundColor,
  selectedBackgroundColor,
  children,
  ...props
}) => {
  return (
    <TouchableOpacity
      style={{
        height: height - 6,
        width: `${width}%`,
        justifyContent: 'center',
        alignItems: 'center',
        backgroundColor: isSelected ? selectedBackgroundColor : backgroundColor,
        borderRadius: 25,
      }}
      {...props}
    >
      {children}
    </TouchableOpacity>
  );
};

type Props<T extends string | number> = {
  backgroundColor?: string;
  color?: string;
  onChange?: (v: T) => void;
  selectedBackgroundColor?: string;
  selectedColor?: string;
  defaultValue?: T;
  buttons: { label: string; value: T }[];
  height?: number;
  textProps?: StyleProp<TextStyle>;
};

const Switch = <T extends string | number>({
  defaultValue,
  buttons,
  onChange = () => null,
  backgroundColor = '#F2F2FE',
  color = '#080DE0',
  selectedBackgroundColor = '#081EE0',
  selectedColor = '#ffffff',
  height = 45,
  textProps = {},
}: Props<T>) => {
  if (buttons.length < 2) {
    return null;
  }
  const [selectedValue, setSelectedValue] = React.useState(defaultValue ?? buttons[0].value);

  const buttonWidth = Math.floor(100 / buttons.length);

  const onSelect = (value: T) => () => {
    setSelectedValue(value);
    onChange(value);
  };

  const renderButtons = () =>
    buttons.map(({ label, value }) => {
      const isSelected = selectedValue === value;
      return (
        <Button
          key={String(value)}
          width={buttonWidth}
          height={height}
          isSelected={isSelected}
          onPress={onSelect(value)}
          backgroundColor={backgroundColor}
          selectedBackgroundColor={selectedBackgroundColor}
        >
          <Body style={[{ color: isSelected ? selectedColor : color }, textProps]}>{label}</Body>
        </Button>
      );
    });

  React.useEffect(() => {
    if (typeof defaultValue !== 'undefined' && defaultValue !== selectedValue) {
      setSelectedValue(defaultValue);
    }
  }, [defaultValue]);

  return (
    <Container backgroundColor={backgroundColor} height={height}>
      {renderButtons()}
    </Container>
  );
};

export default Switch;
