import { OnlinePaymentsPayoutDestination } from './onlinePaymentsPayoutDestination';
import { OnlinePaymentsPayoutId } from './onlinePaymentsPayoutId';
import { JSONable, MeroUnits, Money, Option, ScaledNumber } from '@mero/shared-sdk';
import * as t from 'io-ts';
import * as tt from 'io-ts-types';

export type OnlinePaymentsPayoutPreview = {
  readonly id: OnlinePaymentsPayoutId;
  readonly createdAt: Date;
  readonly amount: Money<ScaledNumber, MeroUnits.RON>;
  /**
   * `paid`, `pending`, `in_transit`, `canceled` or `failed`
   */
  readonly status: string;
  readonly destination: Option<OnlinePaymentsPayoutDestination>;
};

const JSON: t.Type<OnlinePaymentsPayoutPreview, JSONable> = t.type(
  {
    id: OnlinePaymentsPayoutId.JSON,
    createdAt: tt.DateFromISOString,
    amount: Money.json(ScaledNumber.JSON, MeroUnits.RON.JSON),
    status: t.string,
    destination: Option.json(OnlinePaymentsPayoutDestination.JSON),
  },
  'OnlinePaymentsPayoutPreview',
);

export const OnlinePaymentsPayoutPreview = {
  JSON,
};
