import { ObjectId } from '@mero/shared-sdk';
import * as t from 'io-ts';

export interface WorkerDepartmentIdBrand {
  readonly WorkerDepartmentId: unique symbol;
}

export type WorkerDepartmentId = t.Branded<ObjectId, WorkerDepartmentIdBrand>;

const JSON = t.brand(ObjectId, (_id: ObjectId): _id is WorkerDepartmentId => true, 'WorkerDepartmentId');

export const WorkerDepartmentId = {
  JSON,
};
