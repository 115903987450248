import { DefinedTrimedString, Email, Firstname, JSONable, Lastname, StrictPhoneNumber } from '@mero/shared-sdk';
import * as t from 'io-ts';

export type Person = {
  readonly type: 'Person';
  readonly firstname: Firstname;
  readonly lastname: Lastname;
  readonly email: Email;
  readonly phone: StrictPhoneNumber;
  readonly address: {
    readonly county: DefinedTrimedString;
    readonly city: DefinedTrimedString;
    readonly address: DefinedTrimedString;
  };
};

const JSON: t.Type<Person, JSONable> = t.type(
  {
    type: t.literal('Person'),
    firstname: Firstname,
    lastname: Lastname,
    email: Email.TRUSTED_JSON,
    phone: StrictPhoneNumber,
    address: t.type(
      {
        county: DefinedTrimedString,
        city: DefinedTrimedString,
        address: DefinedTrimedString,
      },
      'Address',
    ),
  },
  'Person',
);

export const Person = {
  JSON,
};
