/**
 * Represents a closed date interval [from: Date, to: Date]
 */
export type DateInterval = {
  readonly from: Date;
  readonly to: Date;
};

/**
 * Syntax sugar for creating {@link DateInterval} from 2 dates
 */
const of = (a: Date, b: Date): DateInterval => {
  if (a > b) {
    return {
      from: b,
      to: a,
    };
  } else {
    return {
      from: a,
      to: b,
    };
  }
};

/**
 * @returns true if intervals {@link a} and {@link b} are the same
 */
const equals = (a: DateInterval, b: DateInterval): boolean => {
  return a.from.getTime() === b.from.getTime() && a.to.getTime() === b.to.getTime();
};

/**
 * @returns true if {@link date} is contained by {@link interval}
 */
const contains = (interval: DateInterval, date: Date): boolean => {
  return interval.from <= date && date <= interval.to;
};

/**
 * @returns true if intervals overlap
 */
const overlaps = (a: DateInterval, b: DateInterval): boolean => {
  // sort intervals by start date
  const left = a.from < b.from ? a : b;
  const right = a.from < b.from ? b : a;

  if (left.to < right.from) {
    // No overlap
    return false;
  } else {
    // There is a partial or full overlap
    return true;
  }
};

/**
 * Try to merge 2 {@link DateInterval} into one if they overlap.
 * @returns Merged {@link DateInterval} or undefined if intervals do not overlap
 */
const merge = (a: DateInterval, b: DateInterval): DateInterval | undefined => {
  // sort intervals by start date
  const left = a.from < b.from ? a : b;
  const right = a.from < b.from ? b : a;

  if (left.to < right.from) {
    // No overlap
    return undefined;
  } else if (left.to < right.to) {
    // There is an overlap, merge intervals
    return DateInterval.of(left.from, right.to);
  } else {
    // Left contains right
    return left;
  }
};

/**
 * Adds 2 {@link DateInterval} together. If intervals overlaps - returns merged interval,
 *  otherwise returns array of 2 intervals (sorted by start).
 */
const add = (a: DateInterval, b: DateInterval): [DateInterval] | [DateInterval, DateInterval] => {
  const merged = merge(a, b);

  if (merged) {
    return [merged];
  } else {
    return a.from < b.from ? [a, b] : [b, a];
  }
};

/**
 * Subtracts {@link subtract} from {@link source}. If intervals overlaps - returns interval(s) without {@link subtract} interval
 */
const sub = (source: DateInterval, subtract: DateInterval): [] | [DateInterval] | [DateInterval, DateInterval] => {
  if (subtract.from > source.to || subtract.to < source.from) {
    // no overlap
    return [source];
  } else if (subtract.from > source.from) {
    if (subtract.to < source.to) {
      // let contains right
      return [
        DateInterval.of(source.from, new Date(subtract.from.getTime() - 1)),
        DateInterval.of(new Date(subtract.to.getTime() + 1), source.to),
      ];
    } else {
      // subtract overlaps source from the right
      return [DateInterval.of(source.from, new Date(subtract.from.getTime() - 1))];
    }
  } else {
    if (subtract.to < source.to) {
      return [DateInterval.of(new Date(subtract.to.getTime() + 1), source.to)];
    } else {
      // subtract contains source
      return [];
    }
  }
};

export const DateInterval = {
  of,
  equals,
  contains,
  overlaps,
  merge,
  add,
  sub,
};
