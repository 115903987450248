import * as React from 'react';
import Svg, { Rect, Path, G } from 'react-native-svg';

import * as colors from '../../../styles/colors';

type Props = {
  size?: number | string;
  color?: string;
  disabled?: boolean;
  onPress?: () => void;
};

const ArrowRight = ({ size = 24, color = colors.DARK_BLUE, disabled, onPress }: Props) => (
  <Svg width={size} height={size} viewBox={`0 0 ${size} ${size}`} disabled={disabled} onPress={onPress}>
    <G id="Group_5794" data-name="Group 5794" transform="translate(-2177 -5826)">
      <Path
        id="iconmonstr-arrow-63"
        d="M5.885,11.743,4.5,10.358,9.069,5.869,4.5,1.384,5.885,0l5.954,5.869Z"
        transform="translate(2180.522 5832)"
        fill={color}
      />
      <Rect
        id="Rectangle_2"
        data-name="Rectangle 2"
        width={size}
        height={size}
        transform="translate(2177 5826)"
        fill="none"
      />
    </G>
  </Svg>
);

export default React.memo(ArrowRight);
