import { ObjectId } from '@mero/shared-sdk';
import * as t from 'io-ts';

export interface AppointmentIdBrand {
  readonly AppointmentId: unique symbol;
}

export const AppointmentId = t.brand(
  ObjectId,
  (_id: ObjectId): _id is t.Branded<ObjectId, AppointmentIdBrand> => true,
  'AppointmentId',
);

export type AppointmentId = t.TypeOf<typeof AppointmentId>;
