import { PageRolePreview } from './page-role-preview';
import * as t from 'io-ts';

export const PageRoleOverview = t.intersection(
  [
    PageRolePreview,
    t.type({
      permissionsOverview: t.array(
        t.type(
          {
            title: t.string,
            isActive: t.boolean,
          },
          'PagePermissionOverview',
        ),
      ),
    }),
  ],
  'PageRoleOverview',
);

export interface PageRoleOverview extends t.TypeOf<typeof PageRoleOverview> {}
