import { DayTime, JSONable } from '@mero/shared-sdk';
import * as t from 'io-ts';

/**
 * A closed interval of time in a day
 */
export type BulkDailyBounds = {
  readonly from: DayTime;
  readonly to: DayTime;
};

const JSON: t.Type<BulkDailyBounds, JSONable> = t.type(
  {
    from: DayTime.JSON,
    to: DayTime.JSON,
  },
  'DailyBounds',
);

const FULL_DAY: BulkDailyBounds = {
  from: DayTime.DAY_START,
  to: DayTime.DAY_END,
};

/**
 * Check if the given bounds cover the whole day
 */
const isFullDay = (bounds: BulkDailyBounds): boolean => {
  return DayTime.isDayStart(bounds.from) && DayTime.isDayEnd(bounds.to);
};

/**
 * Merge two daily bounds by taking the earliest start and the latest end
 */
const merge = (a: BulkDailyBounds, b: BulkDailyBounds): BulkDailyBounds => {
  return {
    from: DayTime.min(a.from, b.from),
    to: DayTime.max(a.to, b.to),
  };
};

const tryMerge = (a: BulkDailyBounds | undefined, b: BulkDailyBounds | undefined): BulkDailyBounds | undefined => {
  if (a && b) {
    return merge(a, b);
  }

  return a ?? b;
};

/**
 * Build a daily bounds from two day times
 * It normalizes from an to value so from <= to
 */
const of = (from: DayTime, to: DayTime): BulkDailyBounds => ({
  from: DayTime.min(from, to),
  to: DayTime.max(from, to),
});

export const BulkDailyBounds = {
  JSON,
  FULL_DAY,
  isFullDay,
  merge,
  tryMerge,
  of,
};
