import * as React from 'react';
import Svg, { Path, G, Circle } from 'react-native-svg';

import { WHITE } from '../../../styles/colors';

type Props = {
  size?: string | number;
  color?: string;
  backgroundColor?: string;
  disabled?: boolean;
  onPress?: () => void;
};

const NotificationAlertIcon = ({
  size = 32,
  color = WHITE,
  backgroundColor = '#a27af1',
  disabled = false,
  onPress,
}: Props) => (
  <Svg width={size} height={size} viewBox="0 0 32 32" disabled={disabled} onPress={onPress}>
    <Circle cx="16" cy="16" r="16" fill={backgroundColor} />
    <G transform="translate(7 7)">
      <Path d="M0,0H18V18H0Z" fill="none" />
      <Path
        d="M9.508,17.125a1.5,1.5,0,0,0,1.5-1.5h-3A1.5,1.5,0,0,0,9.508,17.125Zm4.5-8.25a4.562,4.562,0,0,0-3.375-4.74v-.51a1.125,1.125,0,1,0-2.25,0v.51a4.564,4.564,0,0,0-3.375,4.74v3.75l-1.5,1.5v.75h12v-.75l-1.5-1.5Zm-1.5,4.5h-6v-4.5a3.075,3.075,0,0,1,3-3.375,3.075,3.075,0,0,1,3,3.375ZM6.193,3.685,5.12,2.612A7.819,7.819,0,0,0,2.03,8.5h1.5A6.334,6.334,0,0,1,6.193,3.685ZM15.485,8.5h1.5A7.866,7.866,0,0,0,13.9,2.612L12.83,3.685A6.371,6.371,0,0,1,15.485,8.5Z"
        transform="translate(-0.507 -0.625)"
        fill={color}
      />
    </G>
  </Svg>
);

export default React.memo(NotificationAlertIcon);
