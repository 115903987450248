import * as t from 'io-ts';

export type PageFeedbackScoreCounts = {
  readonly 1: number;
  readonly 2: number;
  readonly 3: number;
  readonly 4: number;
  readonly 5: number;
};

export const PageFeedbackScoreCounts = {
  CODEC: t.type(
    {
      1: t.number,
      2: t.number,
      3: t.number,
      4: t.number,
      5: t.number,
    },
    'PageFeedbackCountScore',
  ),
  ZERO: {
    1: 0,
    2: 0,
    3: 0,
    4: 0,
    5: 0,
  } as const,
  /**
   * Get total number of ratings
   */
  getTotal: (countScore: PageFeedbackScoreCounts): number => {
    return countScore[1] + countScore[2] + countScore[3] + countScore[4] + countScore[5];
  },
  /**
   * Get ratings average
   * @returns rating average or undefined if no ratings
   */
  getAverage: (countScore: PageFeedbackScoreCounts): number | undefined => {
    const total = PageFeedbackScoreCounts.getTotal(countScore);

    if (total === 0) {
      return undefined;
    }

    return (countScore[1] * 1 + countScore[2] * 2 + countScore[3] * 3 + countScore[4] * 4 + countScore[5] * 5) / total;
  },
};
