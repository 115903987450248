import * as React from 'react';
import Svg, { G, Path } from 'react-native-svg';

import { colors } from '../../..';

type Props = {
  size?: number | string;
  color?: string;
  disabled?: boolean;
  onPress?: () => void;
};

const ArrowUp = ({ size = 24, color = colors.DARK_BLUE, disabled, onPress }: Props) => (
  <Svg width={size} height={size} viewBox={`0 0 ${size} ${size}`} disabled={disabled} onPress={onPress}>
    <G data-name="Group 4275">
      <Path fill={color} d="m17.743 14.593-1.385 1.385-4.489-4.569-4.485 4.569L6 14.593l5.869-5.954Z" />
      <Path fill="none" d="M0 24V0h24v24z" data-name="Rectangle 2" />
    </G>
  </Svg>
);

export default React.memo(ArrowUp);
