import * as React from 'react';
import { Keyboard } from 'react-native';

const useKeyboardIsOpen = (): boolean => {
  const [isKeyboardOpen, setIsKeyboardOpen] = React.useState(false);
  React.useEffect(() => {
    const onShow = () => {
      setIsKeyboardOpen(true);
    };
    const onHide = () => {
      setIsKeyboardOpen(false);
    };

    const didShowListener = Keyboard.addListener('keyboardDidShow', onShow);
    const didHideListener = Keyboard.addListener('keyboardDidHide', onHide);

    // cleanup function
    return () => {
      didShowListener.remove();
      didHideListener.remove();
    };
  }, []);

  return isKeyboardOpen;
};

export default useKeyboardIsOpen;
