import { HasOptionalFirstLastName, UserId } from '@mero/shared-sdk';
import * as t from 'io-ts';

export type OperationUserPreview = {
  readonly _id: UserId;
  readonly profile: HasOptionalFirstLastName & {
    readonly phone: string;
  };
};

export const OperationUserPreview = {
  JSON: t.type(
    {
      _id: UserId,
      profile: t.intersection([
        HasOptionalFirstLastName,
        t.type({
          phone: t.string,
        }),
      ]),
    },
    'OperationUserPreview',
  ),
};
