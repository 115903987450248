import { UserOrderId } from './userOrderId';
import { JSONable, Option } from '@mero/shared-sdk';
import * as t from 'io-ts';

export type UserOrderPaymentSummary = {
  readonly orderId: UserOrderId;
  readonly item:
    | {
        readonly type: 'Appointment';
        readonly title: string;
        readonly appointmentId: string;
        readonly occurrenceIndex: Option<number>;
      }
    | {
        readonly type: 'SalonGiftCard';
        readonly title: string;
      };
};

const JSON: t.Type<UserOrderPaymentSummary, JSONable> = t.type(
  {
    orderId: UserOrderId.JSON,
    item: t.union(
      [
        t.type(
          {
            type: t.literal('Appointment'),
            title: t.string,
            appointmentId: t.string,
            occurrenceIndex: Option.json(t.number),
          },
          'Appointment',
        ),
        t.type(
          {
            type: t.literal('SalonGiftCard'),
            title: t.string,
          },
          'SalonGiftCard',
        ),
      ],
      'Item',
    ),
  },
  'UserOrderPaymentSummary',
);

export const UserOrderPaymentSummary = {
  JSON,
};
