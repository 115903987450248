import { Numbers } from '../../numbers';
import { RecurrenceTimeUnit } from '../recurrenceTimeUnit';
import * as t from 'io-ts';

export type Every2Weeks = {
  readonly value: Numbers.Two;
  readonly unit: RecurrenceTimeUnit.Week;
};

const JSON = t.type(
  {
    value: Numbers.Two,
    unit: RecurrenceTimeUnit.Week.JSON,
  },
  'Every2Weeks',
);

export const Every2Weeks = {
  JSON,
  is: JSON.is,
};
