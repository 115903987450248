import { ProductBrandId } from '../../productBrand';
import { ProductCategoryId } from '../../productCategory';

export type ProductCountersResult = {
  readonly all: number;
  readonly active: number;
  readonly inactive: number;
  readonly categories: {
    [categoryId: ProductCategoryId]: number;
  };
  readonly brands: {
    [brandId: ProductBrandId]: number;
  };
  readonly noCategory: number;
  readonly noBrand: number;
};

export const ProductCountersResult = {};
