import * as React from 'react';
import { KeyboardAvoidingView, KeyboardAvoidingViewProps, Platform } from 'react-native';

const AvoidKeyboard: React.FC<React.PropsWithChildren<KeyboardAvoidingViewProps>> = (props) => {
  return (
    <KeyboardAvoidingView behavior={Platform.OS == 'ios' ? 'padding' : 'height'} {...props}>
      {props.children}
    </KeyboardAvoidingView>
  );
};

export default AvoidKeyboard;
