import * as React from 'react';
import { View } from 'react-native';

type SizeSpec = 'xxxs' | 'xxs' | 'xs' | 's' | 'm' | 'l' | 'xl' | 'xxl';

type Props = {
  children?: React.ReactNode | React.ReactNodeArray;
  left?: SizeSpec | number;
  right?: SizeSpec | number;
};

const getPadding = (size: SizeSpec | number): number => {
  if (typeof size === 'number') {
    return size;
  } else {
    switch (size) {
      case 'xxxs':
        return 4;
      case 'xxs':
        return 6;
      case 'xs':
        return 8;
      case 's':
        return 12;
      case 'm':
        return 16;
      case 'l':
        return 24;
      case 'xl':
        return 32;
      case 'xxl':
        return 48;
    }
  }
};

const HSpacer: React.FC<Props> = ({ left = 0, right = 0, children = null }) => {
  return (
    <View
      style={{
        paddingLeft: getPadding(left),
        paddingRight: getPadding(right),
      }}
    >
      {children}
    </View>
  );
};

export default HSpacer;
