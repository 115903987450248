import { OnlinePaymentsPayoutId } from './onlinePaymentsPayoutId';
import { OnlinePaymentsPayoutSchedule } from './onlinePaymentsPayoutSchedule';
import { OnlinePaymentsProApi } from './onlinePaymentsProApi';
import {
  HasId,
  HttpClient,
  isSome,
  MeroUnits,
  Money,
  ScaledNumber,
  UnknownApiError,
  unsafeRight,
} from '@mero/shared-sdk';
import * as tt from 'io-ts-types';

export const onlinePaymentsProHttpClient = (env: { apiBaseUrl: string; http: HttpClient }): OnlinePaymentsProApi => {
  const { apiBaseUrl, http } = env;
  const enc = encodeURIComponent;

  const RESPONSES = OnlinePaymentsProApi.RESPONSES;
  const getAccountBalanceDecoder = http.decode.response(UnknownApiError, RESPONSES.getAccountBalance);
  const getStatusDecoder = http.decode.response(UnknownApiError, RESPONSES.getStatus);
  const createPayoutDecoder = http.decode.response(UnknownApiError, RESPONSES.createPayout);
  const listPayoutsDecoder = http.decode.response(UnknownApiError, RESPONSES.listPayouts);
  const updatePayoutScheduleDecoder = http.decode.response(UnknownApiError, RESPONSES.updatePayoutSchedule);
  const updatePayoutDestinationDecoder = http.decode.response(UnknownApiError, RESPONSES.updatePayoutDestination);
  const listPagePaymentsDecoder = http.decode.response(UnknownApiError, RESPONSES.listPagePayments);
  const exportPagePaymentsDecoder = http.decode.response(UnknownApiError, RESPONSES.exportPagePayments);
  const listClientPaymentsDecoder = http.decode.response(UnknownApiError, RESPONSES.listClientPayments);

  const listAppointmentPaymentsDecoder = http.decode.response(UnknownApiError, RESPONSES.listAppointmentPayments);
  const getUserOrderDetailsDecoder = http.decode.response(UnknownApiError, RESPONSES.getUserOrderDetails);
  const getUserOrderPaymentDetailsDecoder = http.decode.response(UnknownApiError, RESPONSES.getUserOrderPaymentDetails);
  const refundPaymentDecoder = http.decode.response(UnknownApiError, RESPONSES.refundPayment);

  return {
    getAccountBalance: async (pageId) => {
      return unsafeRight(
        await http.request(
          {
            method: 'GET',
            url: `${apiBaseUrl}/pro/pages/${enc(pageId)}/online-payments/account-balance`,
          },
          getAccountBalanceDecoder,
        ),
      );
    },
    getStatus: async (pageId) => {
      return unsafeRight(
        await http.request(
          {
            method: 'GET',
            url: `${apiBaseUrl}/pro/pages/${enc(pageId)}/online-payments/status`,
          },
          getStatusDecoder,
        ),
      );
    },
    createPayout: async (params): Promise<HasId<OnlinePaymentsPayoutId>> => {
      return unsafeRight(
        await http.request(
          {
            method: 'POST',
            url: `${apiBaseUrl}/pro/pages/${enc(params.pageId)}/online-payments/payouts`,
            data: {
              amount: params.amount,
            },
          },
          createPayoutDecoder,
        ),
      );
    },
    listPayouts: async (params) => {
      return unsafeRight(
        await http.request(
          {
            method: 'GET',
            url: `${apiBaseUrl}/pro/pages/${enc(params.pageId)}/online-payments/payouts`,
            params: {
              ...(isSome(params.page) ? { page: params.page } : {}),
              ...(isSome(params.createdAt)
                ? {
                    createdAtGte: tt.DateFromISOString.encode(params.createdAt.gte),
                    createdAtLte: tt.DateFromISOString.encode(params.createdAt.lte),
                  }
                : {}),
            },
          },
          listPayoutsDecoder,
        ),
      );
    },
    updatePayoutSchedule: async (params) => {
      unsafeRight(
        await http.request(
          {
            method: 'PUT',
            url: `${apiBaseUrl}/pro/pages/${enc(params.pageId)}/online-payments/settings/payout-schedule`,
            data: {
              schedule: OnlinePaymentsPayoutSchedule.JSON.encode(params.schedule),
            },
          },
          updatePayoutScheduleDecoder,
        ),
      );
    },
    updatePayoutDestination: async (params) => {
      unsafeRight(
        await http.request(
          {
            method: 'PUT',
            url: `${apiBaseUrl}/pro/pages/${enc(params.pageId)}/online-payments/settings/payout-destination`,
            data: params.destination,
          },
          updatePayoutDestinationDecoder,
        ),
      );
    },
    listPagePayments: async (params) => {
      return unsafeRight(
        await http.request(
          {
            method: 'GET',
            url: `${apiBaseUrl}/pro/pages/${enc(params.pageId)}/online-payments/user-payments`,
            params: {
              createdAtGte: tt.DateFromISOString.encode(params.createdAt.gte),
              createdAtLte: tt.DateFromISOString.encode(params.createdAt.lte),
              page: params.page,
            },
          },
          listPagePaymentsDecoder,
        ),
      );
    },
    exportPagePayments: async (params) => {
      return unsafeRight(
        await http.request(
          {
            method: 'GET',
            url: `${apiBaseUrl}/pro/pages/${enc(params.pageId)}/online-payments/user-payments/export`,
            params: {
              createdAtGte: tt.DateFromISOString.encode(params.createdAt.gte),
              createdAtLte: tt.DateFromISOString.encode(params.createdAt.lte),
              format: params.format,
            },
          },
          exportPagePaymentsDecoder,
        ),
      );
    },
    listClientPayments: async (params) => {
      return unsafeRight(
        await http.request(
          {
            method: 'GET',
            url: `${apiBaseUrl}/pro/pages/${enc(params.pageId)}/online-payments/clients/${enc(
              params.clientId,
            )}/user-payments`,
            params: {
              page: params.page,
            },
          },
          listClientPaymentsDecoder,
        ),
      );
    },
    listAppointmentPayments: async ({ pageId, appointmentId, occurrenceIndex }) => {
      return unsafeRight(
        await http.request(
          {
            method: 'GET',
            url: `${apiBaseUrl}/pro/pages/${enc(pageId)}/online-payments/appointments/${enc(
              appointmentId,
            )}/user-payments`,
            params: {
              occurrenceIndex: occurrenceIndex,
            },
          },
          listAppointmentPaymentsDecoder,
        ),
      );
    },
    getUserOrderDetails: async ({ pageId, userOrderId }) => {
      return unsafeRight(
        await http.request(
          {
            method: 'GET',
            url: `${apiBaseUrl}/pro/pages/${enc(pageId)}/online-payments/user-orders/${enc(userOrderId)}`,
          },
          getUserOrderDetailsDecoder,
        ),
      );
    },
    getUserOrderPaymentDetails: async ({ pageId, userOrderPaymentId }) => {
      return unsafeRight(
        await http.request(
          {
            method: 'GET',
            url: `${apiBaseUrl}/pro/pages/${enc(pageId)}/online-payments/user-payments/${enc(userOrderPaymentId)}`,
          },
          getUserOrderPaymentDetailsDecoder,
        ),
      );
    },
    refundPayment: async ({ pageId, userOrderPaymentId, amount }) => {
      unsafeRight(
        await http.request(
          {
            method: 'POST',
            url: `${apiBaseUrl}/pro/pages/${enc(pageId)}/online-payments/user-payments/${enc(
              userOrderPaymentId,
            )}/refund`,
            data: {
              amount: Money.json(ScaledNumber.JSON, MeroUnits.RON.JSON).encode(amount),
            },
          },
          refundPaymentDecoder,
        ),
      );
    },
  };
};
