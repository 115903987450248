export type CheckoutCashRegisterDriverType = 'daisy-expertsx' | 'datecs-dp25' | 'fiscalwire' | 'fiscal-net';

const DAISY_EXPERTSX = 'daisy-expertsx' as const;
const DATECS_DP25 = 'datecs-dp25' as const;
const FISCALWIRE = 'fiscalwire' as const;
const FISCAL_NET = 'fiscal-net' as const;

export const CheckoutCashRegisterDriverType = {
  DAISY_EXPERTSX,
  DATECS_DP25,
  FISCALWIRE,
  FISCAL_NET,
};
