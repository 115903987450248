import * as t from 'io-ts';

export interface OnlinePaymentsPayoutIdBrand {
  readonly OnlinePaymentsPayoutId: unique symbol;
}

export type OnlinePaymentsPayoutId = t.Branded<string, OnlinePaymentsPayoutIdBrand>;

const JSON: t.Type<OnlinePaymentsPayoutId, string> = t.brand(
  t.string,
  (s): s is OnlinePaymentsPayoutId => true,
  'OnlinePaymentsPayoutId',
);

export const OnlinePaymentsPayoutId = {
  JSON,
};
