import * as E from 'fp-ts/lib/Either';
import * as t from 'io-ts';
import * as React from 'react';

import { Props as TextInputProps, default as TextInput } from '../TextInput';

export type ValueIO<A> = {
  /**
   * Input value
   */
  readonly input: string;
  /**
   * Decode result
   */
  readonly decoded: t.Validation<A>;
};

export type Props<A extends t.Mixed> = Omit<TextInputProps, 'onChange' | 'isError'> & {
  readonly codec: A;

  /**
   * When set to true - validation error will be shown for invalid value
   */
  readonly showError?: boolean;
  /**
   * value
   */
  readonly onChange?: (value: ValueIO<t.TypeOf<A>>) => void;
};

const TypeSafeTextInput = <A extends t.Mixed>({
  codec,
  onChange,
  showError,
  ...props
}: Props<A>): React.ReactElement => {
  const [isValid, setIsValid] = React.useState(
    React.useMemo(() => E.isRight(codec.decode(props.value)), [props.value]),
  );

  const onChangeCallback = React.useCallback(
    (s: string) => {
      const r = codec.decode(s);
      setIsValid(E.isRight(r));
      if (onChange !== undefined) {
        onChange({ input: s, decoded: r });
      }
    },
    [codec, onChange, setIsValid],
  );
  return <TextInput {...props} isError={showError && !isValid} onChange={onChangeCallback} />;
};

export default TypeSafeTextInput;
