import { PercentNumber } from '@mero/shared-sdk';
import * as t from 'io-ts';

export interface BoostCommissionPercentBrand {
  readonly BoostCommissionPercent: unique symbol;
}

export type BoostCommissionPercent = t.Branded<PercentNumber, BoostCommissionPercentBrand>;

export const BoostCommissionPercent = {
  /**
   * Checks if given value is a valid BoostCommissionPercent
   * - {@link BoostCommissionPercent} must be a valid {@link PercentNumber}
   * - {@link BoostCommissionPercent} >= 0
   * - {@link BoostCommissionPercent} <= 100
   */
  is: (value: number): value is BoostCommissionPercent => PercentNumber.is(value) && value >= 0 && value <= 100,
  /**
   * Parses a number to a {@link BoostCommissionPercent} or throws when invalid.
   */
  unsafeFrom: (percent: number): BoostCommissionPercent => {
    if (!BoostCommissionPercent.is(percent)) {
      throw new Error('Invalid Percent value');
    }

    return percent;
  },
  /**
   * Returns the value representing {@link percent} of given {@link value}
   */
  ofValue: (value: number, percent: BoostCommissionPercent): number => PercentNumber.ofValue(value, percent, 2),
  /**
   * IO Codec for {@link BoostCommissionPercent} type values
   */
  codec: t.brand(
    PercentNumber.JSON,
    (value): value is BoostCommissionPercent => BoostCommissionPercent.is(value),
    'BoostCommissionPercent',
  ),
};
