import { ProductsApi } from '../productsApi/productsApi';
import * as ProductsJson from './productsJson';
import { HttpClient, UnknownApiError, unsafeRight } from '@mero/shared-sdk';
import * as t from 'io-ts';

export const productsHttpClient = (env: { apiBaseUrl: string; http: HttpClient }): ProductsApi => {
  const { apiBaseUrl, http } = env;

  const unknownResponseDecoder = http.decode.response(UnknownApiError, t.unknown);

  const getProductByIdDecoder = http.decode.response(UnknownApiError, ProductsJson.ProductC);
  const getProductsByIdsDecoder = http.decode.response(UnknownApiError, ProductsJson.ProductSearchHitsC);
  const deleteProductsDecoder = unknownResponseDecoder;
  const createProductDecoder = http.decode.response(UnknownApiError, ProductsJson.ProductC);
  const updateProductDecoder = http.decode.response(UnknownApiError, ProductsJson.ProductC);
  const uploadGalleryImageDecoder = http.decode.response(UnknownApiError, ProductsJson.ProfileImageC);
  const getAllCategoriesDecoder = http.decode.response(UnknownApiError, ProductsJson.ProductCategoriesArrayC);
  const deleteCategoryDecoder = unknownResponseDecoder;
  const createCategoryDecoder = http.decode.response(UnknownApiError, ProductsJson.ProductCategoryC);
  const updateCategoryDecoder = http.decode.response(UnknownApiError, ProductsJson.ProductCategoryC);
  const createBrandDecoder = http.decode.response(UnknownApiError, ProductsJson.ProductBrandC);
  const deleteBrandDecoder = unknownResponseDecoder;
  const reorderCategoriesAndProductsDecoder = unknownResponseDecoder;
  const getBrandsDecoder = http.decode.response(UnknownApiError, ProductsJson.ProductBrandsArrayC);
  const deleteGalleryImageDecoder = unknownResponseDecoder;
  const uploadBrandImageDecoder = http.decode.response(UnknownApiError, ProductsJson.ProfileImageC);
  const updateBrandDecoder = http.decode.response(UnknownApiError, ProductsJson.ProductBrandC);
  const reorderGalleryImagesDecoder = http.decode.response(UnknownApiError, ProductsJson.ProductGalleryC);
  const getUnitsOfMeasurementDecoder = http.decode.response(UnknownApiError, ProductsJson.ProductMeasureSpecC);
  const deleteBrandImageDecoder = unknownResponseDecoder;
  const searchProductsDecoder = http.decode.response(UnknownApiError, ProductsJson.ProductSearchResultC);
  const updateProductArchivedStatus = unknownResponseDecoder;
  const importProductsDecoder = http.decode.response(UnknownApiError, ProductsJson.ImportProductsC);
  const getSearchCountersDecoder = http.decode.response(UnknownApiError, ProductsJson.ProductCountersResultC);

  return {
    getUnitsOfMeasurement: async (pageId) => {
      return unsafeRight(
        await http.request(
          {
            method: 'GET',
            url: `${apiBaseUrl}/business/page/${encodeURIComponent(pageId)}/products/units-of-measurement`,
          },
          getUnitsOfMeasurementDecoder,
        ),
      );
    },

    getProductById: async ({ pageId, productId }) => {
      return unsafeRight(
        await http.request(
          {
            method: 'GET',
            url: `${apiBaseUrl}/business/page/${encodeURIComponent(pageId)}/products/${encodeURIComponent(productId)}`,
          },
          getProductByIdDecoder,
        ),
      );
    },

    getProductsByIds: async ({ pageId, productIds }) => {
      return unsafeRight(
        await http.request(
          {
            method: 'POST',
            url: `${apiBaseUrl}/business/page/${encodeURIComponent(pageId)}/products/by-ids`,
            data: {
              productIds,
            },
          },
          getProductsByIdsDecoder,
        ),
      );
    },

    search: async (pageId, params) => {
      return unsafeRight(
        await http.request(
          {
            method: 'GET',
            url: `${apiBaseUrl}/business/page/${encodeURIComponent(pageId)}/products`,
            params: params,
          },
          searchProductsDecoder,
        ),
      );
    },

    getSearchCounters: async (pageId, params) => {
      return unsafeRight(
        await http.request(
          {
            method: 'GET',
            url: `${apiBaseUrl}/business/page/${encodeURIComponent(pageId)}/products/counters`,
            params: params,
          },
          getSearchCountersDecoder,
        ),
      );
    },

    deleteProducts: async ({ productIds, pageId }) => {
      unsafeRight(
        await http.request(
          {
            method: 'DELETE',
            url: `${apiBaseUrl}/business/page/${encodeURIComponent(pageId)}/products`,
            data: productIds,
          },
          deleteProductsDecoder,
        ),
      );
    },

    updateProductArchivedStatus: async ({ pageId, productId, isArchived }) => {
      unsafeRight(
        await http.request(
          {
            method: 'PATCH',
            url: `${apiBaseUrl}/business/page/${encodeURIComponent(pageId)}/products/${encodeURIComponent(
              productId,
            )}/archived`,
            data: { isArchived },
          },
          updateProductArchivedStatus,
        ),
      );
    },

    createProduct: async ({ pageId, product }) => {
      return unsafeRight(
        await http.request(
          {
            method: 'POST',
            url: `${apiBaseUrl}/business/page/${encodeURIComponent(pageId)}/products`,
            data: product,
          },
          createProductDecoder,
        ),
      );
    },

    updateProduct: async ({ pageId, productId, product }) => {
      return unsafeRight(
        await http.request(
          {
            method: 'PUT',
            url: `${apiBaseUrl}/business/page/${encodeURIComponent(pageId)}/products/${encodeURIComponent(productId)}`,
            data: product,
          },
          updateProductDecoder,
        ),
      );
    },

    uploadGalleryImage: async ({ pageId, productId, image }) => {
      const formData = new FormData();
      const ext = image.blob.type.split('/')[1];

      if (image.platform === 'web') {
        formData.append('image', new File([image.blob], `galleryImage.${ext}`, { type: image.blob.type }));
      } else {
        const uri = image.platform === 'ios' ? image.uri.replace('file://', '') : image.uri;

        formData.append('image', {
          name: `galleryImage.${ext}`,
          type: image.blob.type,
          uri: uri,
        } as any);
      }

      return unsafeRight(
        await http.request(
          {
            method: 'POST',
            url: `${apiBaseUrl}/business/page/${encodeURIComponent(pageId)}/products/${encodeURIComponent(
              productId,
            )}/gallery`,
            data: formData,
            headers: {
              'Content-Type': 'multipart/form-data',
            },
          },
          uploadGalleryImageDecoder,
        ),
      );
    },

    reorderGalleryImages: async ({ pageId, productId, imageIds }) => {
      return unsafeRight(
        await http.request(
          {
            method: 'PUT',
            url: `${apiBaseUrl}/business/page/${encodeURIComponent(pageId)}/products/${encodeURIComponent(
              productId,
            )}/gallery`,
            data: imageIds,
          },
          reorderGalleryImagesDecoder,
        ),
      );
    },

    deleteGalleryImage: async ({ pageId, productId, imageId }) => {
      unsafeRight(
        await http.request(
          {
            method: 'DELETE',
            url: `${apiBaseUrl}/business/page/${encodeURIComponent(pageId)}/products/${encodeURIComponent(
              productId,
            )}/gallery/${encodeURIComponent(imageId)}`,
          },
          deleteGalleryImageDecoder,
        ),
      );
    },

    getAllCategories: async (pageId) => {
      return unsafeRight(
        await http.request(
          {
            method: 'GET',
            url: `${apiBaseUrl}/business/page/${encodeURIComponent(pageId)}/products/categories`,
          },
          getAllCategoriesDecoder,
        ),
      );
    },

    deleteCategory: async ({ pageId, categoryId }) => {
      unsafeRight(
        await http.request(
          {
            method: 'DELETE',
            url: `${apiBaseUrl}/business/page/${encodeURIComponent(pageId)}/products/categories/${encodeURIComponent(
              categoryId,
            )}`,
          },
          deleteCategoryDecoder,
        ),
      );
    },

    createCategory: async ({ pageId, name }) => {
      return unsafeRight(
        await http.request(
          {
            method: 'POST',
            url: `${apiBaseUrl}/business/page/${encodeURIComponent(pageId)}/products/categories`,
            data: { name },
          },
          createCategoryDecoder,
        ),
      );
    },

    updateCategory: async ({ pageId, categoryId, name }) => {
      return unsafeRight(
        await http.request(
          {
            method: 'PUT',
            url: `${apiBaseUrl}/business/page/${encodeURIComponent(pageId)}/products/categories/${encodeURIComponent(
              categoryId,
            )}`,
            data: { name },
          },
          updateCategoryDecoder,
        ),
      );
    },

    reorderCategoriesAndProducts: async ({ pageId, newOrder }) => {
      unsafeRight(
        await http.request(
          {
            method: 'POST',
            url: `${apiBaseUrl}/business/page/${encodeURIComponent(pageId)}/products/categories/reorder}`,
            data: newOrder,
          },
          reorderCategoriesAndProductsDecoder,
        ),
      );
    },

    createBrand: async ({ pageId, name }) => {
      return unsafeRight(
        await http.request(
          {
            method: 'POST',
            url: `${apiBaseUrl}/business/page/${encodeURIComponent(pageId)}/products/brands`,
            data: { name },
          },
          createBrandDecoder,
        ),
      );
    },

    updateBrand: async ({ pageId, brandId, name }) => {
      return unsafeRight(
        await http.request(
          {
            method: 'PUT',
            url: `${apiBaseUrl}/business/page/${encodeURIComponent(pageId)}/products/brands/${encodeURIComponent(
              brandId,
            )}`,
            data: { name },
          },
          updateBrandDecoder,
        ),
      );
    },

    uploadBrandImage: async ({ pageId, brandId, image }) => {
      const formData = new FormData();
      const ext = image.blob.type.split('/')[1];

      if (image.platform === 'web') {
        formData.append('image', new File([image.blob], `brandImage.${ext}`, { type: image.blob.type }));
      } else {
        const uri = image.platform === 'ios' ? image.uri.replace('file://', '') : image.uri;

        formData.append('image', {
          name: `brandImage.${ext}`,
          type: image.blob.type,
          uri: uri,
        } as any);
      }

      return unsafeRight(
        await http.request(
          {
            method: 'POST',
            url: `${apiBaseUrl}/business/page/${encodeURIComponent(pageId)}/products/brands/${encodeURIComponent(
              brandId,
            )}/image`,
            data: formData,
            headers: {
              'Content-Type': 'multipart/form-data',
            },
          },
          uploadBrandImageDecoder,
        ),
      );
    },

    deleteBrandImage: async ({ pageId, brandId }) => {
      unsafeRight(
        await http.request(
          {
            method: 'DELETE',
            url: `${apiBaseUrl}/business/page/${encodeURIComponent(pageId)}/products/brands/${encodeURIComponent(
              brandId,
            )}/image`,
          },
          deleteBrandImageDecoder,
        ),
      );
    },

    deleteBrand: async ({ pageId, brandId }) => {
      unsafeRight(
        await http.request(
          {
            method: 'DELETE',
            url: `${apiBaseUrl}/business/page/${encodeURIComponent(pageId)}/products/brands/${encodeURIComponent(
              brandId,
            )}`,
          },
          deleteBrandDecoder,
        ),
      );
    },

    getAllBrands: async ({ pageId }) => {
      return unsafeRight(
        await http.request(
          {
            method: 'GET',
            url: `${apiBaseUrl}/business/page/${encodeURIComponent(pageId)}/products/brands`,
          },
          getBrandsDecoder,
        ),
      );
    },

    importProducts: async ({ pageId, file }) => {
      const formData = new FormData();
      const ext = file.blob.type.split('/')[1];

      if (file.platform === 'web') {
        formData.append('file', new File([file.blob], `importProducts.${ext}`, { type: file.blob.type }));
      } else {
        const uri = file.platform === 'ios' ? file.uri.replace('file://', '') : file.uri;

        formData.append('file', {
          name: `importProducts.${ext}`,
          type: file.blob.type,
          uri: uri,
        } as any);
      }

      return unsafeRight(
        await http.request(
          {
            method: 'POST',
            url: `${apiBaseUrl}/business/page/${encodeURIComponent(pageId)}/products/import`,
            data: formData,
            headers: {
              'Content-Type': 'multipart/form-data',
            },
          },
          importProductsDecoder,
        ),
      );
    },
  };
};
