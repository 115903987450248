import { WorkerId } from '../workers';
import { WorkerDepartmentId } from './workerDepartmentId';
import { JSONable } from '@mero/shared-sdk';
import * as t from 'io-ts';

export type WorkerDepartment = {
  readonly _id: WorkerDepartmentId;
  readonly workerIds: WorkerId[];
  readonly name: string;
};

const JSON: t.Type<WorkerDepartment, JSONable> = t.type(
  {
    _id: WorkerDepartmentId.JSON,
    workerIds: t.array(WorkerId.JSON),
    name: t.string,
  },
  'WorkerDepartment',
);

export const WorkerDepartment = {
  JSON,
};
