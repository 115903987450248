import * as t from 'io-ts';

export interface CheckoutCashRegisterDriverIdBrand {
  readonly CheckoutCashRegisterDriverId: unique symbol;
}

export type CheckoutCashRegisterDriverId = t.Branded<string, CheckoutCashRegisterDriverIdBrand>;

export const CheckoutCashRegisterDriverId = {
  //
};
