import * as React from 'react';
import Svg, { Path } from 'react-native-svg';

type Props = {
  size?: number | string;
  color?: string;
  disabled?: boolean;
  onPress?: () => void;
};

const ProMenuReports = ({ size = 32, color = '#000000', disabled, onPress }: Props) => (
  <Svg width={size} height={size} viewBox="0 0 32 32" disabled={disabled} onPress={onPress}>
    <Path
      d="M9,17H7V10H9Zm4,0H11V7h2Zm4,0H15V13h2Zm2.5,2.1H4.5V5h15ZM19.5,3H4.5a2.006,2.006,0,0,0-2,2V19a2.006,2.006,0,0,0,2,2h15a2.006,2.006,0,0,0,2-2V5A2.006,2.006,0,0,0,19.5,3Z"
      transform="translate(4 4)"
      fill={color}
    />
  </Svg>
);

export default React.memo(ProMenuReports);
