import { JSONable } from '@mero/shared-sdk';
import * as t from 'io-ts';

export type Pending = 0;

const VALUE: Pending = 0;

const JSON: t.Type<Pending, JSONable> = t.literal(VALUE);

export const Pending = {
  JSON,
  VALUE,
};
