import { CheckoutClientSalesReportItem } from './checkoutClientSalesReportItem';
import { CheckoutClientSalesReportTotals } from './checkoutClientSalesReportTotals';
import { JSONable, MeroUnits } from '@mero/shared-sdk';
import * as t from 'io-ts';

export type CheckoutClientSalesReportPage<Unit extends MeroUnits.Any> = {
  readonly items: CheckoutClientSalesReportItem<Unit>[];
  readonly totals: CheckoutClientSalesReportTotals<Unit>;
};

const json = <Unit extends MeroUnits.Any>(unit: Unit): t.Type<CheckoutClientSalesReportPage<Unit>, JSONable> => {
  return t.type(
    {
      items: t.array(CheckoutClientSalesReportItem.json(unit)),
      totals: CheckoutClientSalesReportTotals.json(unit),
    },
    `CheckoutClientSalesReportPage<${unit}>`,
  );
};

export const CheckoutClientSalesReportPage = {
  json,
};
