import * as React from 'react';
import { TextProps, Text } from 'react-native';

import { styles } from '../../styles/text';

export type D2Props = React.PropsWithChildren<TextProps>;

const D2: React.FC<D2Props> = (props: D2Props) => (
  // This style of settings props avoids generation of _rest helper function in js output
  <Text {...props} style={[styles.d2, props.style]}>
    {props.children}
  </Text>
);

export default D2;
