import { ObjectId } from '@mero/shared-sdk';
import * as t from 'io-ts';

export interface NotificationIdBrand {
  readonly NotificationId: unique symbol;
}

export const NotificationId = t.brand(
  ObjectId,
  (_id: ObjectId): _id is t.Branded<ObjectId, NotificationIdBrand> => true,
  'NotificationId',
);

export type NotificationId = t.TypeOf<typeof NotificationId>;
