import * as t from 'io-ts';

export interface SubCategoryIdBrand {
  readonly SubCategoryId: unique symbol;
}

export const SubCategoryId = t.brand(
  t.string,
  (_id: string): _id is t.Branded<string, SubCategoryIdBrand> => true,
  'SubCategoryId',
);

export type SubCategoryId = t.TypeOf<typeof SubCategoryId>;

export const HasSubCategoryId = t.type(
  {
    id: SubCategoryId,
  },
  'HasSubCategoryId',
);
export interface HasSubCategoryId extends t.TypeOf<typeof HasSubCategoryId> {}
