import * as React from 'react';
import Svg, { G, Path, Rect } from 'react-native-svg';

type Props = {
  size?: string | number;
  color?: string;
  disabled?: boolean;
  onPress?: () => void;
};

const Gift = ({ size = 24, color = '#eaf4ff', disabled, onPress }: Props) => (
  <Svg width={size} height={size} viewBox={`0 0 24 24`} disabled={disabled} onPress={onPress}>
    <G data-name="Group 7496">
      <Path fill={color} d="M4 0h16a4 4 0 0 1 4 4v16a4 4 0 0 1-4 4H4a4 4 0 0 1-4-4V4a4 4 0 0 1 4-4Z" />
      <G fill="none" data-name="Group 6880">
        <G stroke="#3193f8" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit={10}>
          <Path d="M16.976 12.584v5.806H7.023v-5.806m-.829-2.488h11.612v2.488H6.194ZM10.341 10.096v8.294m3.318-8.294v8.294M10.797 6.571a1.534 1.534 0 0 0-1.285-.623 1.659 1.659 0 0 0-1.1 2.9c.871.767 3.587 1.244 3.587 1.244a6.751 6.751 0 0 0-1.202-3.521Zm2.405 0a1.534 1.534 0 0 1 1.286-.623 1.659 1.659 0 0 1 1.1 2.9c-.871.767-3.587 1.244-3.587 1.244a6.751 6.751 0 0 1 1.202-3.521Z" />
        </G>
        <Path d="M0 0h24v24H0z" data-name="Rectangle 2603" />
      </G>
    </G>
  </Svg>
);

export default React.memo(Gift);
