import { HasOptionalFirstLastName } from '../common';
import { UserId } from '../users';
import { PageRolePreview } from './page-role-preview';
import { Firstname, JSONable, Lastname, Option, optionull } from '@mero/shared-sdk';
import { ProfileImage } from '@mero/shared-sdk/dist/assets';
import * as t from 'io-ts';

export type PageMemberPreview = {
  readonly user: {
    readonly _id: UserId;
    readonly phone: Option<string>;
    readonly profile: {
      readonly firstname?: Firstname;
      readonly lastname?: Lastname;
      readonly photo?: ProfileImage;
    };
  };
  readonly roles: PageRolePreview[];
};

const JSON: t.Type<PageMemberPreview, JSONable> = t.exact(
  t.intersection(
    [
      t.type(
        {
          user: t.type(
            {
              _id: UserId,
              phone: Option.json(t.string),
              profile: t.intersection([HasOptionalFirstLastName, t.partial({ photo: optionull(ProfileImage) })], '*'),
            },
            '!',
          ),
          roles: t.array(PageRolePreview),
        },
        '!',
      ),
      t.partial(
        {
          canUpdateProfile: t.boolean,
        },
        '?',
      ),
    ],
    '*',
  ),
  'PageMemberPreview',
);

export const PageMemberPreview = {
  JSON,
};
