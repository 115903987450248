import { OptionalEmail, optionull, OptionalDefinedString } from '@mero/shared-sdk';
import * as t from 'io-ts';

export const PaymentIntent = t.intersection(
  [
    t.type({
      publishableKey: t.string,
      clientSecret: t.string,
      amount: t.number,
      status: t.string,
    }),
    t.partial({
      lastPaymentError: optionull(t.string),
      paymentMethod: optionull(t.string),
    }),
  ],
  'PaymentIntent',
);
export type PaymentIntent = t.TypeOf<typeof PaymentIntent>;

/**
 * Types used for the user billing details.
 */
export interface UserBillingEmailBrand {
  readonly UserBillingEmail: unique symbol;
}

export const UserBillingEmail = t.brand(
  OptionalEmail.TRUSTED_JSON,
  (p: string | undefined): p is t.Branded<OptionalEmail, UserBillingEmailBrand> => true,
  'UserBillingEmail',
);

export interface UserBillingCountryBrand {
  readonly UserBillingCountry: unique symbol;
}

export const UserBillingCountry = t.brand(
  OptionalDefinedString,
  (p: string | undefined): p is t.Branded<OptionalDefinedString, UserBillingCountryBrand> => true,
  'UserBillingCountry',
);

export interface UserBillingCountyBrand {
  readonly UserBillingCounty: unique symbol;
}

export const UserBillingCounty = t.brand(
  OptionalDefinedString,
  (p: string | undefined): p is t.Branded<OptionalDefinedString, UserBillingCountyBrand> => true,
  'UserBillingCounty',
);

export interface UserBillingCityBrand {
  readonly UserBillingCity: unique symbol;
}

export const UserBillingCity = t.brand(
  OptionalDefinedString,
  (p: string | undefined): p is t.Branded<OptionalDefinedString, UserBillingCityBrand> => true,
  'UserBillingCity',
);

export interface UserBillingAddressBrand {
  readonly UserBillingAddress: unique symbol;
}

export const UserBillingAddress = t.brand(
  OptionalDefinedString,
  (p: string | undefined): p is t.Branded<OptionalDefinedString, UserBillingAddressBrand> => true,
  'UserBillingAddress',
);

export const UserBillingDetails = t.type({
  email: UserBillingEmail,
  country: UserBillingCountry,
  county: UserBillingCounty,
  city: UserBillingCity,
  address: UserBillingAddress,
});
export type UserBillingDetails = t.TypeOf<typeof UserBillingDetails>;
