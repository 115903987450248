import * as React from 'react';
import { TextProps, Text } from 'react-native';

import { styles } from '../../styles/text';

export type BodyProps = React.PropsWithChildren<TextProps> & {
  readonly label?: boolean;
};

const Body: React.FC<BodyProps> = (props: BodyProps) => (
  // This style of settings props avoids generation of _rest helper function in js output
  <Text {...props} style={[styles.body, props.style]}>
    {props.children}
  </Text>
);

export default Body;
