import * as React from 'react';
import { TextInputProps, TextInput as TextInputRN, View, ViewProps } from 'react-native';

import { DARK_BLUE, GEYSER, RADICAL_RED, BLACK, ATHENS_GRAY, COMET } from '../../styles/colors';

type StyledViewProps = React.PropsWithChildren<{
  isFocused?: boolean;
  isError?: boolean;
  withBorder?: boolean;
  rounded?: boolean;
  hasRightIcon?: boolean;
  hasLeftIcon?: boolean;
  background?: string;
  multiline?: boolean;
  numberOfLines?: number;
  editable?: boolean;
}>;

const StyledView: React.FC<StyledViewProps & ViewProps> = ({
  isFocused = false,
  isError = false,
  withBorder,
  rounded,
  hasRightIcon = false,
  hasLeftIcon = false,
  background,
  multiline = false,
  numberOfLines,
  editable,
  children,
  style,
  ...props
}) => {
  return (
    <View
      style={[
        {
          backgroundColor: editable === false ? ATHENS_GRAY : background || 'transparent',
          borderWidth: withBorder ? 1 : 0,
          borderStyle: withBorder ? 'solid' : undefined,
          borderColor: isError ? RADICAL_RED : isFocused && editable ? DARK_BLUE : GEYSER,
          borderRadius: rounded ? 20 : 5,
          alignItems: multiline && numberOfLines && numberOfLines > 0 ? undefined : 'center',
          height: multiline && numberOfLines && numberOfLines > 0 ? 22 * numberOfLines + 21 : 43,
          width: '100%',
          display: 'flex',
          flexDirection: 'row',
          paddingTop: 0,
          paddingRight: hasRightIcon || rounded ? 16 : 14,
          paddingBottom: 0,
          paddingLeft: hasLeftIcon || rounded ? 16 : 14,
        },
        style,
      ]}
      {...props}
    >
      {children}
    </View>
  );
};

type StyledTextInputRnProps = {
  isMultiline: boolean;
  hasRightIcon?: boolean;
  hasLeftIcon?: boolean;
};

const StyledTextInputRN = React.forwardRef<TextInputRN | null, StyledTextInputRnProps & TextInputProps>(
  function StyledTextInputRN({ isMultiline, hasRightIcon = false, hasLeftIcon = false, style, ...props }, ref) {
    const textInputRef = React.useRef<TextInputRN>(null);
    React.useImperativeHandle<TextInputRN | null, TextInputRN | null>(ref, () => textInputRef.current);

    return (
      <TextInputRN
        ref={textInputRef}
        style={[
          {
            paddingTop: isMultiline ? 12 : 0,
            paddingRight: hasRightIcon ? 12 : 0,
            paddingBottom: isMultiline ? 12 : 0,
            paddingLeft: hasLeftIcon ? 12 : 0,
            fontSize: 16,
            lineHeight: 22,
            fontFamily: 'open-sans',
            flex: 1,
          },
          style,
        ]}
        {...props}
      />
    );
  },
);

export type Props = Omit<TextInputProps, 'onChange'> & {
  value?: string;
  isError?: boolean;
  isFocused?: boolean;
  autoFocus?: boolean;
  onFocus?: () => void;
  onBlur?: () => void;
  placeholder?: string;
  placeholderColor?: string;
  LeftComponent?: React.ComponentType;
  RightComponent?: React.ComponentType;
  withBorder?: boolean;
  rounded?: boolean;
  onChange?: (value: string) => void;
  background?: string;
  multiline?: boolean;
  numberOfLines?: number;
  textColor?: string;
  /**
   * @deprecated move this into props
   */
  textInputProps?: TextInputProps;
  styles?: {
    container?: ViewProps['style'];
    textInput?: TextInputProps['style'];
  };
};

const TextInput = React.forwardRef<TextInputRN, Props>(function TextInput(
  {
    value,
    isError = false,
    onFocus,
    onBlur,
    placeholder = '',
    placeholderColor = COMET,
    LeftComponent,
    RightComponent,
    withBorder = true,
    rounded = false,
    onChange,
    onSubmitEditing,
    textContentType,
    keyboardAppearance,
    keyboardType,
    returnKeyType,
    returnKeyLabel,
    editable,
    background,
    multiline,
    numberOfLines,
    autoFocus,
    textColor,
    styles = {},
    textInputProps: oldTextInputProps,
    ...textInputProps
  },
  ref,
) {
  const [isFocused, setIsFocused] = React.useState(false);
  const hasLeftIcon = !!LeftComponent;
  const hasRightIcon = !!RightComponent;

  const textInputRef = React.useRef<TextInputRN>(null);
  React.useImperativeHandle<TextInputRN | null, TextInputRN | null>(ref, () => textInputRef.current);

  const onFocusCallback = React.useCallback(() => {
    setIsFocused(true);
    if (onFocus !== undefined) {
      onFocus();
    }
  }, [onFocus, setIsFocused]);

  const onBlurCallback = React.useCallback(() => {
    setIsFocused(false);
    if (onBlur !== undefined) {
      onBlur();
    }
  }, [onBlur, setIsFocused]);

  const isMultiline = (numberOfLines ?? 1) > 1;

  return (
    <StyledView
      withBorder={withBorder}
      rounded={rounded}
      hasLeftIcon={hasLeftIcon}
      hasRightIcon={hasRightIcon}
      isFocused={isFocused}
      isError={isError}
      background={background}
      multiline={multiline}
      numberOfLines={numberOfLines}
      editable={editable}
      style={styles.container}
    >
      {LeftComponent && <LeftComponent />}
      <StyledTextInputRN
        ref={textInputRef}
        style={[{ color: textColor || BLACK }, styles.textInput]}
        onFocus={onFocusCallback}
        onBlur={onBlurCallback}
        value={value}
        onChangeText={onChange}
        onSubmitEditing={onSubmitEditing}
        placeholder={placeholder}
        hasLeftIcon={hasLeftIcon}
        hasRightIcon={hasRightIcon}
        placeholderTextColor={placeholderColor}
        textContentType={textContentType}
        keyboardAppearance={keyboardAppearance}
        keyboardType={keyboardType}
        returnKeyType={returnKeyType}
        returnKeyLabel={returnKeyLabel}
        editable={editable}
        multiline={multiline}
        numberOfLines={numberOfLines}
        isMultiline={isMultiline}
        autoFocus={autoFocus}
        textAlignVertical={isMultiline ? 'top' : 'center'}
        {...oldTextInputProps}
        {...textInputProps}
      />
      {RightComponent && <RightComponent />}
    </StyledView>
  );
});

export default TextInput;
