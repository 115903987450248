import { AppsApi } from './apps';
import { AuthenticationApi } from './auth';

export interface IApiConfig {
  appId: string;
  appSecret?: string;
  hostname: string;
  version: string;
  accessToken?: string;
}

export class NovaAuthApiSdk {
  /**
   * Class constructor.
   */
  constructor(private _config: IApiConfig) {}

  setAccessToken(token: string) {
    this._config.accessToken = token;
  }

  apps(): AppsApi {
    return new AppsApi(this._config);
  }

  authentications(): AuthenticationApi {
    return new AuthenticationApi(this._config);
  }
}

export function NovaAuthAPI({ config }: { config: IApiConfig }) {
  if (!config.appId) throw 'App ID is required.';
  return new NovaAuthApiSdk(config);
}
