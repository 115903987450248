import { CheckoutTotals } from '../checkoutTotals';
import { CheckoutTotalsScaledNumber } from '../checkoutTotalsScaledNumber';
import { CheckoutTransactionCompany } from '../checkoutTransactionCompany';
import { CheckoutTransactionItem } from '../checkoutTransactionItem';
import { CheckoutTransactionPayment } from '../checkoutTransactionPayment';
import { MembershipItemEffectivePrice } from '../checkoutTransactionPayment/membership';
import { AppliedDiscount, MeroUnits, ScaledNumber } from '@mero/shared-sdk';

type IsItemPaidWithMembershipResult<Unit extends MeroUnits.Any> =
  | {
      readonly isPaidWithMembership: true;
      /**
       * Item effective price for one unit
       */
      readonly effectivePrice: MembershipItemEffectivePrice<ScaledNumber, Unit>;
    }
  | {
      readonly isPaidWithMembership: false;
    };

export const isItemPaidWithMembership = <Unit extends MeroUnits.Any>(
  item: CheckoutTransactionItem.Service<Unit> | CheckoutTransactionItem.Product<Unit>,
  payments: CheckoutTransactionPayment.Any<ScaledNumber, Unit>[],
): IsItemPaidWithMembershipResult<Unit> => {
  for (const payment of payments) {
    if (!CheckoutTransactionPayment.isMembership(payment)) {
      continue;
    }

    for (const paymentItem of payment.items) {
      switch (paymentItem.type) {
        case 'Service': {
          if (
            item.type === 'Service' &&
            paymentItem.transactionItemId === item.transactionItemId &&
            paymentItem.quantity === item.quantity &&
            paymentItem.service._id === item.service._id
          ) {
            return {
              isPaidWithMembership: true,
              effectivePrice: paymentItem.effectivePrice,
            };
          } else {
            break;
          }
        }
        case 'Product': {
          // FIXME: implement product support
          break;
        }
      }
    }
  }

  return {
    isPaidWithMembership: false,
  };
};

/**
 * Compute transaction totals
 */
export const computeTransactionTotals = <Unit extends MeroUnits.Any>(
  transaction: {
    readonly unit: Unit;
    readonly items: CheckoutTransactionItem.Any<Unit>[];
    readonly discount?: AppliedDiscount<ScaledNumber, Unit>;
    readonly company: CheckoutTransactionCompany.Any;
    readonly payments: CheckoutTransactionPayment.Any<ScaledNumber, Unit>[];
  },
  decimals: number,
): CheckoutTotals<ScaledNumber, Unit> => {
  const companyVat = CheckoutTransactionCompany.getVatStatus(transaction.company);
  const totals = transaction.items.reduce((totals, item) => {
    return CheckoutTotalsScaledNumber.add(
      totals,
      CheckoutTransactionItem.getTotals(item, transaction.unit, transaction.payments, companyVat, decimals),
    );
  }, CheckoutTotalsScaledNumber.zero(transaction.unit));

  return transaction.discount
    ? CheckoutTotalsScaledNumber.applyDiscount(totals, transaction.discount, decimals)
    : totals;
};

/**
 * @returns true if a discount may be applied to this transaction
 */
export const mayHaveDiscount = <Unit extends MeroUnits.Any>(transaction: {
  readonly items: CheckoutTransactionItem.Any<Unit>[];
}): boolean => {
  const haveMembershipItems = transaction.items.some((item) => {
    return CheckoutTransactionItem.isMembership(item) || CheckoutTransactionItem.isMembershipInstallment(item);
  });

  // Transaction discount not allowed with membership and/or membership installments items
  if (haveMembershipItems) {
    return false;
  }

  return true;
};
