import { Price } from '../../services/price';
import { ServiceId } from '../../services/service-id';
import { BookingServiceCustomPrice } from '../bookingServiceCustomPrice';
import { JSONable, PositiveInt, Option } from '@mero/shared-sdk';
import * as t from 'io-ts';

export type BookedService = {
  readonly _id: ServiceId;
  readonly name: string;
  readonly description: string;
  readonly durationInMinutes: number;
  readonly private: boolean;
  readonly availableDays: number;
  readonly automaticApproval: boolean;
  readonly price: Price;
  readonly color: string;
  readonly quantity: Option<PositiveInt>;
  readonly customPrice: Option<BookingServiceCustomPrice>;
};

const JSON: t.Type<BookedService, JSONable> = t.type(
  {
    _id: ServiceId,
    name: t.string,
    description: t.string,
    durationInMinutes: t.number,
    private: t.boolean,
    availableDays: t.number,
    automaticApproval: t.boolean,
    price: Price,
    color: t.string,
    quantity: Option.json(PositiveInt.JSON),
    customPrice: Option.json(BookingServiceCustomPrice.JSON),
  },
  'CalendarEntry.BookedService',
);

export const BookedService = {
  JSON,
};
