import * as t from 'io-ts';

export const PageBoostSettings = t.type(
  {
    enabled: t.boolean,
  },
  'PageBoostSettings',
);

export type PageBoostSettings = t.TypeOf<typeof PageBoostSettings>;
