import * as React from 'react';
import { BackHandler, NativeEventSubscription } from 'react-native';

export const useHardwareBackPress = (action: () => void): void => {
  const backHandler = React.useRef<{ event?: NativeEventSubscription }>({});

  const backAction = () => {
    action();
    return true;
  };

  React.useEffect(() => {
    backHandler.current.event = BackHandler.addEventListener('hardwareBackPress', backAction);

    return () => {
      if (backHandler.current.event) {
        backHandler.current.event.remove();
      }
    };
  }, []);
};

export default useHardwareBackPress;
