import { optionull } from './io';
import { JSONable } from './jsonable';
import * as t from 'io-ts';

export type Paged<Data> = {
  /**
   * Page data
   */
  readonly data: Data;
  /**
   * Next page token - opaque string
   */
  readonly next: string | undefined;
  /**
   * Prev page token - opaque string
   */
  readonly prev: string | undefined;
};

/**
 * Build new JSON codec for {@link Paged<Data>} type
 * @param data codec for {@link Data}
 */
const json = <Data>(data: t.Type<Data, JSONable>): t.Type<Paged<Data>, JSONable> => {
  return t.type(
    {
      data: data,
      next: optionull(t.string),
      prev: optionull(t.string),
    },
    `Paged<${data.name}>`,
  );
};

export const Paged = {
  json,
};
