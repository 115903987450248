import * as React from 'react';
import Svg, { G, Path, Circle } from 'react-native-svg';

type Props = {
  size?: string | number;
  color?: string;
  disabled?: boolean;
  onPress?: () => void;
};

const Discount = ({ size = 24, disabled, onPress }: Props) => (
  <Svg width={size} height={size} viewBox={`0 0 ${size} ${size}`} disabled={disabled} onPress={onPress}>
    <G fill="none">
      <Path d="M0 0h24v24H0z" />
      <G
        transform="translate(3.769 3.045)"
        stroke="#52577f"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit={10}
        strokeWidth={1.4}
      >
        <Circle cx={2} cy={2} r={2} transform="translate(2.231 3.955)" />
        <Circle cx={2} cy={2} r={2} transform="translate(10.231 10.955)" />
        <Path d="M12.832 4.346 3.275 14.7" />
      </G>
    </G>
  </Svg>
);

export default React.memo(Discount);
