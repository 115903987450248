import { BillingInfo } from './billing-info';
import { InvoiceId } from './invoice-id';
import { Tracking } from './order';
import { OrderId } from './order-id';
import { SubscriptionId } from './subscription-id';
import * as t from 'io-ts';
import { DateFromISOString } from 'io-ts-types';

export const Invoice = t.type({
  _id: InvoiceId,
  createdAt: DateFromISOString,
  subscriptionId: SubscriptionId,
  orderId: OrderId,
  billing: BillingInfo,
  tracking: Tracking,
  downloadUrl: t.string,
});

export type Invoice = t.TypeOf<typeof Invoice>;
