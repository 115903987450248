import { MarketplaceRoute } from './index';
import { MarketplaceLinkingApi } from './marketplaceLinkingApi';
import { HttpClient, unsafeRight, UnknownApiError } from '@mero/shared-sdk';

export const marketplaceLinkingHttpClient = (env: { apiBaseUrl: string; http: HttpClient }): MarketplaceLinkingApi => {
  const { apiBaseUrl, http } = env;

  const unknownError = UnknownApiError;

  const getLinkComponentsResponseDecoder = http.decode.response(unknownError, MarketplaceRoute.JSON);

  return {
    resolveRoute: async (link) => {
      const requestBody = { link };
      return unsafeRight(
        await http.request(
          {
            method: 'POST',
            url: `${apiBaseUrl}/mp/linking/resolve`,
            data: requestBody,
          },
          getLinkComponentsResponseDecoder,
        ),
      );
    },
  };
};
