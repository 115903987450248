import * as React from 'react';
import Svg, { Path, G } from 'react-native-svg';

type Props = {
  width?: string | number;
  height?: string | number;
  color?: string;
  disabled?: boolean;
  onPress?: () => void;
  rotate?: boolean;
};

const Dropdown: React.FC<Props> = ({
  width = 10,
  height = 5,
  color = '#172B4D',
  disabled = false,
  onPress,
  rotate = false,
}) => {
  const transform = rotate ? `translate(${width} ${height}) rotate(180)` : '';
  return (
    <Svg width={width} height={height} viewBox={`0 0 ${width} ${height}`} disabled={disabled} onPress={onPress}>
      <G transform={transform} opacity="0.95">
        <Path d={`M0,0H${width}L${height},5Z`} fill={color} />
      </G>
    </Svg>
  );
};

export default React.memo(Dropdown);
