import { DecodeError } from './error';
import { ok, Result, err } from './result';
import * as E from 'fp-ts/Either';
import { flow, pipe } from 'fp-ts/function';
import * as t from 'io-ts';
import * as tt from 'io-ts-types';

/**
 * Constructs a codec to map between literal types, ex:
 * ```typescript
 * const Yes = literalIso(true, 'yes') // t.Type<true, 'yes'>
 * ```
 * @param a literal value to decode to when [o] successfully decoded
 * @param o literal value to encode [a] to
 * @param name custom type name
 * @deprecated doesn't work well with union types, use t.literal().value for switch/case statements
 */
export const literalIso = <A extends string | number | boolean, O extends string | number | boolean>(
  a: A,
  o: O,
  name?: string,
): t.Type<A, O, unknown> => {
  const aName = `${a}`;
  const oName = `${o}`;

  const codecA = t.literal(a, aName);
  const codecO = t.literal(o, oName);

  const codec = new t.Type<A, O, unknown>(
    name ?? `${aName}≅${oName}`,
    codecA.is,
    (i, c) =>
      pipe(
        codecO.validate(i, c),
        E.map(() => a),
      ),
    () => codecO.encode(o),
  );
  // Simulate literal type to make it work with union types, but THIS BREAKS ENCODING
  // @ts-expect-error
  codec._tag = codecO._tag;
  // @ts-expect-error
  codec.value = codecO.value;

  return codec;
};

/**
 * Decodes to type A | undefined from input of A | null | undefined
 * null is converted to undefined
 */
export const optionull = <A extends t.Mixed>(
  c: A,
): t.Type<t.TypeOf<A> | undefined, t.OutputOf<A> | undefined, t.InputOf<A> | null | undefined> => {
  const deCodec = t.union([c, t.undefined, t.null]);
  const enCodec = t.union([c, t.undefined]);

  return new t.Type<t.TypeOf<A> | undefined, t.OutputOf<A> | undefined, t.InputOf<A> | null | undefined>(
    `Optionull<${c.name}>`,
    enCodec.is,
    flow(
      deCodec.validate,
      E.map((a: t.TypeOf<A> | null | undefined): t.TypeOf<A> | undefined => a ?? undefined),
    ),
    enCodec.encode,
  );
};

/**
 * Decodes to type A | null from input of A | null | undefined
 * undefined is converted to null
 */
export const nullable = <A extends t.Mixed>(
  c: A,
): t.Type<t.TypeOf<A> | null, t.OutputOf<A> | null, t.InputOf<A> | null | undefined> => {
  const deCodec = t.union([c, t.undefined, t.null]);
  const enCodec = t.union([c, t.undefined]);

  return new t.Type<t.TypeOf<A> | undefined, t.OutputOf<A> | undefined, t.InputOf<A> | null | undefined>(
    `Optionull<${c.name}>`,
    enCodec.is,
    flow(
      deCodec.validate,
      E.map((a: t.TypeOf<A> | null | undefined): t.TypeOf<A> | undefined => a ?? null),
    ),
    enCodec.encode,
  );
};

/**
 * Decodes null | undefined to undefined, encodes undefined to null
 */
export const UndefinedFromNull: t.Type<undefined, null> = tt.mapOutput(optionull(t.undefined), () => null);

/**
 * Decode type {@link A} from input {@link value} and wrap it into a Result
 */
export const decode = <A, O, I>(type: t.Type<A, O, I>, value: I): Result<A, DecodeError> => {
  const decoded = type.decode(value);

  if (E.isLeft(decoded)) {
    return err(new DecodeError(decoded.left));
  }

  return ok(decoded.right);
};

/**
 * Decode type {@link A} from input {@link value} or throw a {@link DecodeError} if decoding fails
 */
export const unsafeDecode = <A, O, I>(codec: t.Type<A, O, I>, value: I): A => {
  const result = codec.decode(value);

  if (E.isLeft(result)) {
    throw new DecodeError(result.left);
  }

  return result.right;
};
