import { isDefined } from '../common';

/**
 * Create new generator that generates no elements
 */
export const empty = function* (): Generator<never, void> {
  // pass
};

/**
 * Generates all given elements
 */
export const of = function* <A>(...value: A[]): Generator<A, void, undefined> {
  yield* value;
};

/**
 * Generate {@link count} numbers starting {@link from} with step `1`
 * @param from number to start from
 * @param count number of elements to generate. If `undefined` - will generate indefinitely
 */
export const range = function* (from: number, count?: number): Generator<number, void> {
  let i = from;
  if (isDefined(count)) {
    const end = from + count;
    while (i < end) {
      yield i;
      i += 1;
    }
  } else {
    while (true) {
      yield i;
      i += 1;
    }
  }
};

/**
 * Shorthand for `range(0, count)`
 */
export const interval = function* (count: number): Generator<number, void> {
  return yield* range(0, count);
};

/**
 * Skip first {@link count} elements from given generator
 */
export const skip = function (count: number): <A, R>(g: Generator<A, R>) => Generator<A, R> {
  return function* <A, R>(g: Generator<A, R>): Generator<A, R> {
    let i = 0;

    while (i < count) {
      const result = g.next();
      if (result.done) {
        return result.value;
      }
      i += 1;
    }

    return yield* g;
  };
};

/**
 * Take first {@link count} elements from given generator
 */
export const take = function (count: number): <A, R>(g: Generator<A, R>) => Generator<A, R | undefined> {
  return function* <A, R>(g: Generator<A, R>): Generator<A, R | undefined> {
    let i = 0;
    while (i < count) {
      const result = g.next();
      if (result.done) {
        return result.value;
      }
      yield result.value;
      i += 1;
    }

    return undefined;
  };
};

/**
 * Skip elements from given generator while predicate {@link p} returns true
 */
export const skipWhile = function <A>(p: (el: A) => boolean): <R, N>(g: Generator<A, R, N>) => Generator<A, R, N> {
  return function* <R, N>(g: Generator<A, R, N>): Generator<A, R, N> {
    let el: IteratorResult<A, R>;

    do {
      el = g.next();
      if (el.done) {
        return el.value;
      }
    } while (p(el.value));

    // Predicate returned false for last element, should be emitted
    yield el.value;

    return yield* g;
  };
};

/**
 * Skip elements from given generator until {@link p} returns true
 */
export const skipUntil = function <A>(p: (el: A) => boolean): <R, N>(g: Generator<A, R, N>) => Generator<A, R, N> {
  return skipWhile((el: A) => !p(el));
};

/**
 * Take first elements from given generator while predicate {@link p} returns true
 */
export const takeWhile = function <A>(p: (el: A) => boolean): <R>(g: Generator<A, R>) => Generator<A, R | undefined> {
  return function* <R>(g: Generator<A, R>): Generator<A, R | undefined> {
    for (const el of g) {
      if (p(el)) {
        yield el;
      } else {
        break;
      }
    }

    return undefined;
  };
};

/**
 * Take first elements from given generator until predicate {@link p} returns true
 */
export const takeUntil = function <A>(p: (el: A) => boolean): <R>(g: Generator<A, R>) => Generator<A, R | undefined> {
  return takeWhile((el: A) => !p(el));
};

/**
 * Map generator elements using given function {@link fn}
 */
export const map = function <A, B>(fn: (el: A) => B): <R>(g: Generator<A, R>) => Generator<B, R> {
  return function* <R>(g: Generator<A, R>): Generator<B, R> {
    while (true) {
      const el = g.next();
      if (el.done) {
        return el.value;
      } else {
        yield fn(el.value);
      }
    }
  };
};

/**
 * Map generator elements using given function {@link fn} and flattens result
 */
export const chain = function <A, B, R>(fn: (el: A) => Generator<B, R>): <R>(g: Generator<A, R>) => Generator<B, R> {
  return function* <R>(g: Generator<A, R>): Generator<B, R> {
    while (true) {
      const el = g.next();
      if (el.done) {
        return el.value;
      } else {
        yield* fn(el.value);
      }
    }
  };
};

/**
 * Filter elements using given predicate {@link p}
 */
export const filter = function <A>(p: (el: A) => boolean): <R>(g: Generator<A, R>) => Generator<A, R> {
  return function* <R>(g: Generator<A, R>): Generator<A, R> {
    while (true) {
      const el = g.next();
      if (el.done) {
        return el.value;
      } else if (p(el.value)) {
        yield el.value;
      }
    }
  };
};

/**
 * Generate new series of elements by applying {@link fn} to each element of given generator and it's previous result
 *
 * https://en.wikipedia.org/wiki/Prefix_sum#Scan_higher_order_function
 */
export const scan = function <S, T>(acc: S, f: (acc: S, item: T) => S): <R>(g: Generator<T, R>) => Generator<S, R> {
  return function* <R>(g: Generator<T, R>): Generator<S, R> {
    let state: S = acc;
    while (true) {
      const el = g.next();
      if (el.done) {
        return el.value;
      } else {
        state = f(state, el.value);
        yield state;
      }
    }
  };
};

/**
 * Collect generated elements into an array
 */
export const collect = function <A, R>(g: Generator<A, R>): A[] {
  const result: A[] = [];

  for (const el of g) {
    result.push(el);
  }

  return result;
};
