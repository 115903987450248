import * as React from 'react';
import Svg, { Rect, Path, G } from 'react-native-svg';

import { BLACK } from '../../../styles/colors';

type Props = {
  size?: string | number;
  color?: string;
  disabled?: boolean;
  onPress?: () => void;
};

const Back: React.FC<Props> = ({ size = 32, color = BLACK, disabled = false, onPress }) => (
  <Svg width={size} height={size} viewBox="0 0 32 32" disabled={disabled} onPress={onPress}>
    <Rect id="bg" width={size} height={size} fill="none" />
    <G transform="translate(-8 -10)">
      <Path
        d="M14,32H34"
        transform="translate(0 -5.499)"
        fill="none"
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit="10"
        strokeWidth="2"
      />
      <Path
        d="M22.332,18,14,26.5,22.332,35"
        transform="translate(0 0)"
        fill="none"
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit="10"
        strokeWidth="2"
      />
    </G>
  </Svg>
);

export default React.memo(Back);
