export type CheckoutPageSettings = {
  readonly checkoutEnabled: boolean;
};

const DEFAULT: CheckoutPageSettings = {
  checkoutEnabled: false,
};

export const CheckoutPageSettings = {
  DEFAULT,
};
