import { JSONable, RecurrenceRule } from '@mero/shared-sdk';
import * as t from 'io-ts';

/**
 * Recurrent calendar entry properties
 */
export type Recurrent = {
  readonly recurrent: true;
  readonly recurrenceRule: RecurrenceRule.Any;
};

const JSON: t.Type<Recurrent, JSONable> = t.type(
  {
    recurrent: t.literal(true),
    recurrenceRule: RecurrenceRule.JSON,
  },
  'Recurrent',
);

export const Recurrent = {
  JSON,
};
