import { JSONable } from './jsonable';
import * as t from 'io-ts';

export type MeroAppType = 'mero-pro' | 'mero-mp' | 'mero-sms';

const JSON: t.Type<MeroAppType, JSONable> = t.union(
  [t.literal('mero-pro'), t.literal('mero-mp'), t.literal('mero-sms')],
  'MeroAppType',
);

const parse = JSON.decode;

export const MeroAppType = {
  JSON,
  parse,
};
