import { JSONable } from '@mero/shared-sdk';
import * as t from 'io-ts';

export type CheckoutReportBySaleOwnerAndServiceSortType =
  | 'service-total'
  | 'membership-consumption'
  | 'total'
  | 'protocol'
  | 'finished-at';

const SERVICE_TOTAL = 'service-total' as const;
const MEMBERSHIP_CONSUMPTION = 'membership-consumption' as const;
const TOTAL = 'total' as const;
const PROTOCOL = 'protocol' as const;
const FINISHED_AT = 'finished-at' as const;

const JSON: t.Type<CheckoutReportBySaleOwnerAndServiceSortType, JSONable> = t.union(
  [
    t.literal(SERVICE_TOTAL),
    t.literal(MEMBERSHIP_CONSUMPTION),
    t.literal(TOTAL),
    t.literal(PROTOCOL),
    t.literal(FINISHED_AT),
  ],
  'CheckoutReportBySaleOwnerAndServiceSortType',
);

export const CheckoutReportBySaleOwnerAndServiceSortType = {
  SERVICE_TOTAL,
  MEMBERSHIP_CONSUMPTION,
  TOTAL,
  PROTOCOL,
  FINISHED_AT,
  JSON,
};
