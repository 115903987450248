import * as React from 'react';
import Svg, { G, Path, Rect } from 'react-native-svg';

type Props = {
  size?: string | number;
  color?: string;
  disabled?: boolean;
  onPress?: () => void;
};

const GoDown = ({ size = 24, color = '#0820e0', disabled, onPress }: Props) => (
  <Svg width={size} height={size} viewBox={`0 0 ${size} ${size}`} disabled={disabled} onPress={onPress}>
    <G id="Orion_arrow-up" transform="translate(6.022 4.313)">
      <Path
        id="layer2"
        d="M32,29.372V14"
        transform="translate(-26.023 -14)"
        fill="none"
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit="10"
        strokeWidth="1.7"
      />
      <Path
        id="layer1"
        d="M29.956,14l-5.978,5.978L18,14"
        transform="translate(-18 -4.606)"
        fill="none"
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit="10"
        strokeWidth="1.7"
      />
    </G>
    <Rect id="Rectangle_2673" data-name="Rectangle 2673" width="24" height="24" fill="none" />
  </Svg>
);

export default React.memo(GoDown);
