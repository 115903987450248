import { Code39String } from './code39String';
import { Code128String } from './code128String';
import { EANString } from './eANString';
import { UPCString } from './uPCString';
import * as t from 'io-ts';

export type BarcodeValue = Code39String | Code128String | UPCString | EANString;

const JSON = t.union([Code39String.JSON, Code128String.JSON, UPCString.JSON, EANString.JSON]);

export const BarcodeValue = {
  JSON,
};
