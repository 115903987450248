import { JSONable } from '@mero/shared-sdk';
import * as t from 'io-ts';

export type ASAP = 'asap';
export const ASAP: ASAP = 'asap';

export type In2Weeks = '1-2w';
export const In2Weeks: In2Weeks = '1-2w';

export type MoreThan2Weeks = '>2w';
export const MoreThan2Weeks = '>2w';

export type NotDecided = 'not-decided';
export const NotDecided: NotDecided = 'not-decided';

export type Any = ASAP | In2Weeks | MoreThan2Weeks | NotDecided;

export const JSON: t.Type<Any, JSONable> = t.union(
  [t.literal(ASAP), t.literal(In2Weeks), t.literal(MoreThan2Weeks), t.literal(NotDecided)],
  'OnboardingLaunchEta',
);
