import { OnlinePaymentsPayoutDestination } from './onlinePaymentsPayoutDestination';
import { OnlinePaymentsPayoutSchedule } from './onlinePaymentsPayoutSchedule';
import { JSONable, Option } from '@mero/shared-sdk';
import * as t from 'io-ts';

export type OnlinePaymentsPayoutSettings = {
  readonly schedule: Option<OnlinePaymentsPayoutSchedule.Any>;
  readonly destination: Option<OnlinePaymentsPayoutDestination>;
};

const JSON: t.Type<OnlinePaymentsPayoutSettings, JSONable> = t.type(
  {
    schedule: Option.json(OnlinePaymentsPayoutSchedule.JSON),
    destination: Option.json(OnlinePaymentsPayoutDestination.JSON),
  },
  'OnlinePaymentsPayoutSettings',
);

export const OnlinePaymentsPayoutSettings = {
  JSON,
};
