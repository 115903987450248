/**
 * Company is not a VAT payer
 */
export type NoVat = {
  readonly type: 'NoVat';
};

export const NO_VAT: NoVat = {
  type: 'NoVat',
};
