import { JSONable } from '@mero/shared-sdk';
import * as t from 'io-ts';

export type CheckoutReportFormatType = 'csv' | 'xlsx';

const CSV = 'csv' as const;
const XLSX = 'xlsx' as const;

const JSON: t.Type<CheckoutReportFormatType, JSONable> = t.union(
  [t.literal(CSV), t.literal(XLSX)],
  'CheckoutReportFormatType',
);

export const CheckoutReportFormatType = {
  CSV,
  XLSX,
  JSON,
};
