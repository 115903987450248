import * as React from 'react';
import Svg, { Path, G } from 'react-native-svg';

const Calendar: React.FC = () => (
  <Svg width="17" height="18" viewBox="0 0 17 18">
    <G id="Linecons_small-calendar" transform="translate(-1)">
      <G id="calendar_1_" transform="translate(1)">
        <Path
          id="Union_3"
          data-name="Union 3"
          d="M1.546,18A1.556,1.556,0,0,1,0,16.434V3.326A1.555,1.555,0,0,1,1.546,1.761H3.284V.587a.58.58,0,1,1,1.159,0V1.761H7.92V.587a.58.58,0,1,1,1.159,0V1.761h3.477V.587a.579.579,0,1,1,1.159,0V1.761h1.739A1.555,1.555,0,0,1,17,3.326V16.434A1.556,1.556,0,0,1,15.455,18ZM1.159,3.326V16.434a.389.389,0,0,0,.387.392H15.455a.389.389,0,0,0,.386-.392V3.326a.389.389,0,0,0-.386-.391H13.715V4.108a.579.579,0,1,1-1.159,0V2.935H9.08V4.108a.58.58,0,1,1-1.159,0V2.935H4.443V4.108a.58.58,0,1,1-1.159,0V2.935H1.546A.389.389,0,0,0,1.159,3.326ZM7.341,14.673V12.913H9.659v1.761Zm-4.057,0V12.913H5.6v1.761ZM11.4,11.739V9.978h2.318v1.761Zm-4.056,0V9.978H9.659v1.761Zm-4.057,0V9.978H5.6v1.761ZM11.4,8.8V7.043h2.318V8.8Zm-4.056,0V7.043H9.659V8.8Zm-4.057,0V7.043H5.6V8.8Z"
          transform="translate(0)"
          fill="#52577f"
        />
      </G>
    </G>
  </Svg>
);

export default React.memo(Calendar);
