import { ServiceId } from '../../../services/service-id';
import { JSONable, PortionPercentScaledNumber } from '@mero/shared-sdk';
import * as t from 'io-ts';

type ApplyToCustomers = 'All' | 'New';

const ApplyToCustomers = {
  JSON: t.union([t.literal('All'), t.literal('New')]),
};

export type ServicesPercentItem = {
  readonly serviceId: ServiceId;
  readonly minPercent: PortionPercentScaledNumber;
};

export type RequireAdvancePaymentOption =
  | {
      readonly type: 'Off';
    }
  | {
      readonly type: 'AllServices';
      readonly minPercent: PortionPercentScaledNumber;
      readonly applyToCustomers: ApplyToCustomers;
    }
  | {
      readonly type: 'SpecificServices';
      readonly applyToCustomers: ApplyToCustomers;
      readonly servicesMap: ServicesPercentItem[];
    };

const JSON: t.Type<RequireAdvancePaymentOption, JSONable> = t.union(
  [
    t.type(
      {
        type: t.literal('Off'),
      },
      'Off',
    ),
    t.type(
      {
        type: t.literal('AllServices'),
        minPercent: PortionPercentScaledNumber.JSON,
        applyToCustomers: ApplyToCustomers.JSON,
      },
      'AllServices',
    ),
    t.type(
      {
        type: t.literal('SpecificServices'),
        applyToCustomers: ApplyToCustomers.JSON,
        servicesMap: t.array(
          t.type(
            {
              serviceId: ServiceId,
              minPercent: PortionPercentScaledNumber.JSON,
            },
            'ServiceMap',
          ),
        ),
      },
      'SpecificServices',
    ),
  ],
  'RequireAdvancePaymentOption',
);

const DEFAULT: RequireAdvancePaymentOption = {
  type: 'Off',
};

export const RequireAdvancePaymentOption = {
  JSON,
  DEFAULT,
};
