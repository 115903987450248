import * as React from 'react';
import Svg, { G, Line, Path } from 'react-native-svg';

type Props = {
  size?: number | string;
  disabled?: boolean;
  color?: string;
  onPress?: () => void;
};

const AndroidShare: React.FC<Props> = ({ size = 24, color, disabled, onPress }) => {
  return (
    <Svg width="17.646" height="19.515" viewBox="0 0 17.646 19.515" onPress={onPress} disabled={disabled}>
      <G transform="translate(-183.185 -264.799)">
        <Path
          d="M183.935,286.744a2.27,2.27,0,1,1,2.269,2.322A2.3,2.3,0,0,1,183.935,286.744Z"
          transform="translate(0 -12.188)"
          stroke={color}
          strokeLinejoin="round"
          strokeWidth="1.5"
        />
        <Path
          d="M218.2,265.867a2.241,2.241,0,0,1,3.1.852,2.351,2.351,0,0,1-.832,3.171,2.242,2.242,0,0,1-3.1-.853A2.35,2.35,0,0,1,218.2,265.867Z"
          transform="translate(-21.665)"
          stroke={color}
          strokeLinejoin="round"
          strokeWidth="1.5"
        />
        <Line
          x1="7.443"
          y2="4.388"
          transform="translate(188.25 269.041)"
          fill="none"
          stroke={color}
          strokeLinejoin="round"
          strokeWidth="1.7"
        />
        <Path
          d="M218.2,307.621a2.24,2.24,0,0,0,3.1-.852,2.351,2.351,0,0,0-.832-3.171,2.241,2.241,0,0,0-3.1.853A2.349,2.349,0,0,0,218.2,307.621Z"
          transform="translate(-21.665 -24.375)"
          stroke={color}
          strokeLinejoin="round"
          strokeWidth="1.5"
        />
        <Line
          x1="7.513"
          y1="4.201"
          transform="translate(188.215 275.777)"
          fill="none"
          stroke={color}
          strokeLinejoin="round"
          strokeWidth="1.7"
        />
      </G>
    </Svg>
  );
};

export default AndroidShare;
