import * as t from 'io-ts';

const ReportMetric = t.type({
  count: t.number,
  income: t.number,
  percent: t.number,
});

export const BookingStats = t.type({
  totalCount: t.number,
  totalIncome: t.number,
  acceptedStats: ReportMetric,
  cancelledStats: ReportMetric,
  noShowStats: ReportMetric,
  pendingStats: ReportMetric,
  finishedStats: ReportMetric,
  futureStats: ReportMetric,
});

export type BookingStats = t.TypeOf<typeof BookingStats>;

export const ClientStats = t.type({
  totalCount: t.number,
  totalIncome: t.number,
  averageBookingValue: t.number,
  newClientStats: t.type({
    count: t.number,
    income: t.number,
  }),
  existingClientStats: t.type({
    count: t.number,
    income: t.number,
  }),
  newFromProClientStats: t.type({
    count: t.number,
    income: t.number,
  }),
});

export type ClientStats = t.TypeOf<typeof ClientStats>;

export const ServiceStats = t.type({
  name: t.string,
  count: t.number,
  income: t.number,
});

export type ServiceStats = t.TypeOf<typeof ServiceStats>;

export const AnalyticsStatsNew = t.type(
  {
    bookings: BookingStats,
    clients: ClientStats,
    services: t.array(ServiceStats),
  },
  'AnalyticsStatsNew',
);
export type AnalyticsStatsNew = t.TypeOf<typeof AnalyticsStatsNew>;
