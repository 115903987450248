import { CalendarId } from './calendar-id';
import * as t from 'io-ts';

export const Calendar = t.type(
  {
    _id: CalendarId,
    active: t.boolean,
  },
  'Calendar',
);

export type Calendar = t.TypeOf<typeof Calendar>;
