import { JSONable } from '../jsonable';
import { Latitude } from './latitude';
import { Longitude } from './longitude';
import * as t from 'io-ts';

export type LatLng = {
  readonly lat: Latitude;
  readonly lng: Longitude;
};

const JSON: t.Type<LatLng, JSONable> = t.type(
  {
    lat: Latitude.JSON,
    lng: Longitude.JSON,
  },
  'LatLng',
);

export const LatLng = {
  JSON,
};
