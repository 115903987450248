import { UserId } from '../users';
import { UserDeviceId } from './user-device-id';
import { UserDeviceType } from './user-device-type';
import * as t from 'io-ts';
import { DateFromISOString } from 'io-ts-types';

export const UserDevice = t.intersection(
  [
    t.type({
      _id: UserDeviceId,
      identifier: t.string,
      scope: t.string,
      type: UserDeviceType,
      registeredTo: UserId,
    }),
    t.partial({
      bundleId: t.string,
      lastUsedAt: DateFromISOString,
      sendingFailureCount: t.number,
    }),
  ],
  'UserDevice',
);

export type UserDevice = t.TypeOf<typeof UserDevice>;
