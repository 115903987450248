import { CheckoutSaleOwnerSalesReportItem } from './checkoutSaleOwnerSalesReportItem';
import { CheckoutSaleOwnerSalesReportTotals } from './checkoutSaleOwnerSalesReportTotals';
import { JSONable, MeroUnits } from '@mero/shared-sdk';
import * as t from 'io-ts';

export type CheckoutSaleOwnerSalesReportPage<Unit extends MeroUnits.Any> = {
  readonly items: CheckoutSaleOwnerSalesReportItem<Unit>[];
  readonly totals: CheckoutSaleOwnerSalesReportTotals<Unit>;
};

const json = <Unit extends MeroUnits.Any>(unit: Unit): t.Type<CheckoutSaleOwnerSalesReportPage<Unit>, JSONable> => {
  return t.type(
    {
      items: t.array(CheckoutSaleOwnerSalesReportItem.json(unit)),
      totals: CheckoutSaleOwnerSalesReportTotals.json(unit),
    },
    `CheckoutSaleOwnerSalesReportPage<${unit}>`,
  );
};

export const CheckoutSaleOwnerSalesReportPage = {
  json,
};
