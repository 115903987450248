import { Company } from './company';
import { Person } from './person';
import { JSONable } from '@mero/shared-sdk';
import * as t from 'io-ts';

export type Any = Company | Person;

const JSON: t.Type<Any, JSONable> = t.union([Company.JSON, Person.JSON], 'Any');

export const isCompany = (details: Any): details is Company => {
  return details.type === 'Company';
};

export const isPerson = (details: Any): details is Person => {
  return details.type === 'Person';
};

export const Any = {
  JSON,
};
