import { PageId } from '../pages';
import { CustomizedNotificationTemplateId } from './customized-notification-template-id';
import { CustomizedNotificationType } from './customized-notification-type';
import { JSONable } from '@mero/shared-sdk';
import * as t from 'io-ts';

export type CustomizedNotificationTemplate = {
  readonly _id: CustomizedNotificationTemplateId;
  readonly pageId: PageId;
  readonly template: string;
  readonly type: CustomizedNotificationType;
};

const JSON: t.Type<CustomizedNotificationTemplate, JSONable> = t.type(
  {
    _id: CustomizedNotificationTemplateId.JSON,
    pageId: PageId,
    template: t.string,
    type: CustomizedNotificationType.JSON,
  },
  'CustomizedNotificationTemplate',
);

export const CustomizedNotificationTemplate = {
  JSON,
};
