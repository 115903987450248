import * as React from 'react';
import Svg, { Path } from 'react-native-svg';

type Props = {
  size?: number | string;
  color?: string;
  disabled?: boolean;
  onPress?: () => void;
};

const ProMenuServices = ({ size = 32, color = '#000000', disabled, onPress }: Props) => (
  <Svg width={size} height={size} viewBox="0 0 32 32" disabled={disabled} onPress={onPress}>
    <Path
      d="M4,10.5A1.5,1.5,0,1,0,5.5,12,1.5,1.5,0,0,0,4,10.5Zm0-6A1.5,1.5,0,1,0,5.5,6,1.5,1.5,0,0,0,4,4.5Zm0,12A1.5,1.5,0,1,0,5.5,18,1.5,1.5,0,0,0,4,16.5ZM7,19H21V17H7Zm0-6H21V11H7ZM7,5V7H21V5Z"
      transform="translate(4 4)"
      fill={color}
    />
  </Svg>
);

export default React.memo(ProMenuServices);
