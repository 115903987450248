import { Category } from './category';
import { Filters } from './filters';
import { MainCategoryId } from './main-category-id';
import { Product } from './product';
import { Sort } from './sort';
import { SubCategoryId } from './sub-category-id';
import { HttpClient, UnknownApiError, unsafeRight } from '@mero/shared-sdk';
import * as t from 'io-ts';

export interface ShopApi {
  /**
   * Search main categories
   */
  readonly searchCategories: () => Promise<{ categories: Category[] }>;

  /**
   * Search for products
   */
  readonly search: (payload: {
    query?: string;
    size?: number;
    sort?: Sort;
    offset?: number;
    mainCategories?: MainCategoryId[];
    subCategories?: SubCategoryId[];
    filters?: Filters;
  }) => Promise<{ results: Product[]; total: number }>;

  readonly filters: (payload: {
    query?: string;
    mainCategories?: MainCategoryId[];
    subCategories?: SubCategoryId[];
  }) => Promise<{ filters: Filters }>;
}

export const shopHttpClient = (env: { apiBaseUrl: string; http: HttpClient }): ShopApi => {
  const { apiBaseUrl, http } = env;

  const searchCategoriesDecoder = http.decode.optionalResponse(
    UnknownApiError,
    t.type({ categories: t.array(Category) }),
  );
  const searchDecoder = http.decode.optionalResponse(
    UnknownApiError,
    t.type({ results: t.array(Product), total: t.number }),
  );

  const filtersDecoder = http.decode.optionalResponse(UnknownApiError, t.type({ filters: Filters }));

  return {
    searchCategories: async () =>
      unsafeRight(
        await http.request(
          {
            method: 'GET',
            url: `${apiBaseUrl}/barber-store/search/categories`,
          },
          searchCategoriesDecoder,
        ),
      ),
    search: async (payload) =>
      unsafeRight(
        await http.request(
          {
            method: 'POST',
            url: `${apiBaseUrl}/barber-store/search`,
            data: payload,
          },
          searchDecoder,
        ),
      ),
    filters: async (payload) =>
      unsafeRight(
        await http.request(
          {
            method: 'POST',
            url: `${apiBaseUrl}/barber-store/search/filters`,
            data: payload,
          },
          filtersDecoder,
        ),
      ),
  };
};
