import { JSONable, PositiveInt } from '@mero/shared-sdk';
import * as t from 'io-ts';

export type MembershipTemplateValidity =
  | {
      readonly type: 'Unlimited';
    }
  | {
      readonly type: 'Limited';
      readonly days: PositiveInt;
    };

const JSON: t.Type<MembershipTemplateValidity, JSONable> = t.union(
  [
    t.type(
      {
        type: t.literal('Unlimited'),
      },
      'Unlimited',
    ),
    t.type(
      {
        type: t.literal('Limited'),
        days: PositiveInt.JSON,
      },
      'Limited',
    ),
  ],
  'MembershipTemplateValidity',
);

export const MembershipTemplateValidity = {
  JSON,
};
