import * as React from 'react';
import Svg, { Path, Circle, Rect, G } from 'react-native-svg';

import { WHITE } from '../../../styles/colors';

type Props = {
  size?: string | number;
  color?: string;
  backgroundColor?: string;
  disabled?: boolean;
  onPress?: () => void;
};

const NotificationCalendarOpenIcon = ({
  size = 32,
  color = WHITE,
  backgroundColor = '#52577f',
  disabled = false,
  onPress,
}: Props) => (
  <Svg width={size} height={size} viewBox="0 0 32 32" disabled={disabled} onPress={onPress}>
    <Circle cx="16" cy="16" r="16" fill={backgroundColor} />
    <G transform="translate(-117 -189)">
      <Rect width="18" height="18" transform="translate(124 196)" fill="none" />
      <Path
        d="M11.667,11.667H9.333V9.333h2.333Zm-3.5-5.833H5.833V8.167H8.167Zm3.5,0H9.333V8.167h2.333Zm-7,3.5H2.333v2.333H4.667Zm3.5,0H5.833v2.333H8.167Zm-3.5-3.5H2.333V8.167H4.667ZM14,1.167V14H0V1.167H1.75V1.75a1.167,1.167,0,0,0,2.333,0V1.167H9.917V1.75a1.167,1.167,0,0,0,2.333,0V1.167Zm-1.167,3.5H1.167v8.167H12.833ZM11.667.583a.583.583,0,1,0-1.167,0V1.75a.583.583,0,1,0,1.167,0ZM3.5,1.75a.583.583,0,0,1-1.167,0V.583A.583.583,0,0,1,3.5.583Z"
        transform="translate(126 198)"
        fill={color}
      />
    </G>
  </Svg>
);

export default React.memo(NotificationCalendarOpenIcon);
