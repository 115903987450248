import * as React from 'react';
import Svg, { Rect, Circle, Line, G } from 'react-native-svg';

import { BLACK } from '../../../styles/colors';

type Props = {
  size?: string | number;
  color?: string;
  disabled?: boolean;
  onPress?: () => void;
};

const Search: React.FC<Props> = ({ size = 24, color = BLACK, disabled = false, onPress }) => (
  <Svg width={size} height={size} viewBox="0 0 24 24" disabled={disabled} onPress={onPress}>
    <G transform="translate(-32 -50)">
      <Rect width={size} height={size} transform="translate(32 50)" fill="none" />
      <G transform="translate(-254 -77)">
        <Circle
          cx="4.997"
          cy="4.997"
          r="4.997"
          transform="translate(292 133)"
          fill="none"
          stroke={color}
          strokeLinejoin="round"
          strokeWidth="1.5"
        />
        <Line
          x2="3.748"
          y2="3.748"
          transform="translate(300.745 141.745)"
          fill="none"
          stroke={color}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="1.5"
        />
      </G>
    </G>
  </Svg>
);

export default React.memo(Search);
