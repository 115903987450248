import { ObjectId } from '@mero/shared-sdk';
import * as t from 'io-ts';

export interface GiftCardIdBrand {
  readonly GiftCardId: unique symbol;
}

export const GiftCardId = t.brand(
  ObjectId,
  (_id: ObjectId): _id is t.Branded<ObjectId, GiftCardIdBrand> => true,
  'GiftCardId',
);

export type GiftCardId = t.TypeOf<typeof GiftCardId>;
