import { Allowed } from './allowed';
import { Disabled } from './disabled';
import * as t from 'io-ts';

export type Any = Allowed | Disabled;

export const ALLOWED: Allowed = {
  type: 'Allowed',
};

export const DISABLED: Disabled = {
  type: 'Disabled',
};

export const JSON = t.union([Allowed.JSON, Disabled.JSON], 'Any');
