import { JSONable } from '@mero/shared-sdk';
import * as t from 'io-ts';

export type CashRegistryExportFormatType = 'xlsx' | 'pdf';

const XLSX = 'xlsx' as const;

const PDF = 'pdf' as const;

const JSON: t.Type<CashRegistryExportFormatType, JSONable> = t.union([t.literal(XLSX), t.literal(PDF)]);

export const CashRegistryExportFormatType = {
  XLSX,
  PDF,
  JSON,
};
