import { ExtendedCalculator } from '../numbers';
import { Eq } from 'fp-ts/lib/Eq';
import * as t from 'io-ts';

export type Money<Num, Unit> = {
  /**
   * Amount of {@link Unit}
   */
  readonly amount: Num;
  readonly unit: Unit;
};

export type MoneyModule<Num, AnyUnit> = {
  /**
   * Return zero value in Num Money domain
   */
  readonly zero: <Unit extends AnyUnit>(unit: Unit) => Money<Num, Unit>;

  /**
   * Sum two money values
   */
  readonly add: <Unit extends AnyUnit>(a: Money<Num, Unit>, b: Money<Num, Unit>) => Money<Num, Unit>;

  /**
   * Subract value {@link b} from {@link a}
   */
  readonly sub: <Unit extends AnyUnit>(a: Money<Num, Unit>, b: Money<Num, Unit>) => Money<Num, Unit>;

  /**
   * Multiply value {@link a} by {@link b}
   */
  readonly mul: <Unit extends AnyUnit>(a: Money<Num, Unit>, y: Num) => Money<Num, Unit>;

  /**
   * Divide value {@link a} by {@link b}
   */
  readonly div: <Unit extends AnyUnit>(a: Money<Num, Unit>, b: Num, decimals: number) => Money<Num, Unit>;

  /**
   * Check if value is zero
   */
  readonly isZero: <Unit extends AnyUnit>(a: Money<Num, Unit>) => boolean;

  /**
   * Check if value has given unit
   */
  readonly hasUnit: <Unit extends AnyUnit>(
    a: Money<Num, AnyUnit>,
    isUnit: (a: AnyUnit) => a is Unit,
  ) => a is Money<Num, Unit>;

  /**
   * Compare two money values
   */
  readonly equals: <UnitA extends AnyUnit, UnitB extends AnyUnit>(
    a: Money<Num, UnitA>,
    b: Money<Num, UnitB>,
  ) => boolean;

  /**
   * @returns true if {@link a} is less than {@link b}
   */
  readonly lessThan: <Unit extends AnyUnit>(a: Money<Num, Unit>, b: Money<Num, Unit>) => boolean;

  /**
   * @returns true if {@link a} is less than or equal to {@link b}
   */
  readonly lessThanOrEqual: <Unit extends AnyUnit>(a: Money<Num, Unit>, b: Money<Num, Unit>) => boolean;

  /**
   * @returns true if {@link a} is greater than {@link b}
   */
  readonly greaterThan: <Unit extends AnyUnit>(a: Money<Num, Unit>, b: Money<Num, Unit>) => boolean;

  /**
   * @returns true if {@link a} is greater than or equal to {@link b}
   */
  readonly greaterThanOrEqual: <Unit extends AnyUnit>(a: Money<Num, Unit>, b: Money<Num, Unit>) => boolean;

  /**
   * Build new codec for {@link Money}
   */
  readonly json: <NumCodec extends t.Type<Num, any>, UnitCodec extends t.Type<AnyUnit, any>>(
    num: NumCodec,
    unit: UnitCodec,
  ) => t.Type<Money<Num, AnyUnit>, Money<t.OutputOf<NumCodec>, t.OutputOf<UnitCodec>>>;
};

const of = <Num, Unit>(amount: Num, unit: Unit): Money<Num, Unit> => ({
  amount,
  unit,
});

const json = <NumC extends t.Mixed, UnitC extends t.Mixed>(
  num: NumC,
  unit: UnitC,
): t.Type<Money<t.TypeOf<NumC>, t.TypeOf<UnitC>>, Money<t.OutputOf<NumC>, t.OutputOf<UnitC>>> => {
  return t.strict(
    {
      amount: num,
      unit,
    },
    `Money<${num.name}, ${unit.name}>`,
  );
};

const moneyModule = <Num, AnyUnit>(
  calculator: ExtendedCalculator<Num>,
  units: Eq<AnyUnit>,
): MoneyModule<Num, AnyUnit> => ({
  zero: <Unit extends AnyUnit>(unit: Unit): Money<Num, Unit> => {
    return of(calculator.zero(), unit);
  },
  add: <Unit extends AnyUnit>(a: Money<Num, Unit>, b: Money<Num, Unit>): Money<Num, Unit> => {
    return of(calculator.add(a.amount, b.amount), a.unit);
  },
  sub: <Unit extends AnyUnit>(a: Money<Num, Unit>, b: Money<Num, Unit>): Money<Num, Unit> => {
    return of(calculator.sub(a.amount, b.amount), a.unit);
  },
  mul: <Unit extends AnyUnit>(a: Money<Num, Unit>, y: Num): Money<Num, Unit> => {
    return of(calculator.mul(a.amount, y), a.unit);
  },
  div: <Unit extends AnyUnit>(a: Money<Num, Unit>, b: Num, decimals: number): Money<Num, Unit> => {
    return of(calculator.div(a.amount, b, decimals), a.unit);
  },
  isZero: <Unit extends AnyUnit>(a: Money<Num, Unit>): boolean => {
    return calculator.isZero(a.amount);
  },
  hasUnit: <Unit extends AnyUnit>(a: Money<Num, AnyUnit>, isUnit: (a: AnyUnit) => a is Unit): a is Money<Num, Unit> => {
    return isUnit(a.unit);
  },
  equals: <UnitA extends AnyUnit, UnitB extends AnyUnit>(a: Money<Num, UnitA>, b: Money<Num, UnitB>): boolean => {
    return calculator.equals(a.amount, b.amount) && units.equals(a.unit, b.unit);
  },
  lessThan: <Unit extends AnyUnit>(a: Money<Num, Unit>, b: Money<Num, Unit>): boolean => {
    return calculator.lessThan(a.amount, b.amount);
  },
  lessThanOrEqual: <Unit extends AnyUnit>(a: Money<Num, Unit>, b: Money<Num, Unit>): boolean => {
    return calculator.lessThanOrEqual(a.amount, b.amount);
  },
  greaterThan: <Unit extends AnyUnit>(a: Money<Num, Unit>, b: Money<Num, Unit>): boolean => {
    return calculator.greaterThan(a.amount, b.amount);
  },
  greaterThanOrEqual: <Unit extends AnyUnit>(a: Money<Num, Unit>, b: Money<Num, Unit>): boolean => {
    return calculator.greaterThanOrEqual(a.amount, b.amount);
  },
  json: <NumCodec extends t.Type<Num, any>, UnitCodec extends t.Type<AnyUnit, any>>(
    num: NumCodec,
    unit: UnitCodec,
  ): t.Type<Money<Num, AnyUnit>, Money<t.OutputOf<NumCodec>, t.OutputOf<UnitCodec>>> => {
    return json(num, unit);
  },
});

export const Money = {
  of,
  json,
  /**
   * Build new Money module
   */
  build: <Num, AnyUnit>(calculator: ExtendedCalculator<Num>, units: Eq<AnyUnit>): MoneyModule<Num, AnyUnit> =>
    moneyModule(calculator, units),
};
