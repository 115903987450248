import { AreaName } from './areaName';
import { BusinessApi } from './businessApi';
import { CityName } from './cityName';
import { DistrictName } from './districtName';
import { HttpClient, UnknownApiError, unsafeRight } from '@mero/shared-sdk';
import * as t from 'io-ts';

export const businessHttpClient = (env: { apiBaseUrl: string; http: HttpClient }): BusinessApi => {
  const { apiBaseUrl, http } = env;

  const listDistrictsDecoder = http.decode.response(UnknownApiError, t.array(DistrictName.JSON));
  const listCitiesDecoder = http.decode.response(UnknownApiError, t.array(CityName));
  const listAreasDecoder = http.decode.response(UnknownApiError, t.array(AreaName));

  return {
    getDistricts: async () => {
      return unsafeRight(
        await http.request(
          {
            method: 'GET',
            url: `${apiBaseUrl}/business/districts`,
          },
          listDistrictsDecoder,
        ),
      );
    },
    getCities: async (params) => {
      return unsafeRight(
        await http.request(
          {
            method: 'GET',
            url: `${apiBaseUrl}/business/districts/${encodeURIComponent(params.district)}/cities`,
          },
          listCitiesDecoder,
        ),
      );
    },
    getBillingCities: async (params) => {
      return unsafeRight(
        await http.request(
          {
            method: 'GET',
            url: `${apiBaseUrl}/business/districts/${encodeURIComponent(params.district)}/billing-cities`,
          },
          listCitiesDecoder,
        ),
      );
    },
    getAreas: async (params) => {
      return unsafeRight(
        await http.request(
          {
            method: 'GET',
            url: `${apiBaseUrl}/business/city/${encodeURIComponent(params.city)}/areas`,
          },
          listAreasDecoder,
        ),
      );
    },
  };
};
