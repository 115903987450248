import * as React from 'react';
import Svg, { Path, G, Circle } from 'react-native-svg';

import { COMET } from '../../../styles/colors';

type Props = {
  size?: number | string;
  color?: string;
  disabled?: boolean;
  onPress?: () => void;
};

const CheckedOutline: React.FC<Props> = ({ size = 24, disabled = false, color = COMET, onPress }) => (
  <Svg width={size} height={size} viewBox="0 0 25.697 25.697" disabled={disabled} onPress={onPress}>
    <G
      transform="translate(11.275 11.276)"
      fill="none"
      stroke={color}
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeMiterlimit={10}
      data-name="Circle and Icon"
    >
      <Circle cx={8.485} cy={8.485} r={8.485} transform="rotate(-45 -3.314 13.372)" strokeWidth={1.2} />
      <Path d="M-1.925 1.581L.594 4.1 5.075-.381" strokeWidth={1.5} />
    </G>
  </Svg>
);

export default React.memo(CheckedOutline);
