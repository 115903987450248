import { NumericString } from '../numericString';
import * as t from 'io-ts';

/**
 * UPC Barcode
 * Matches 12-digit numeric strings
 */
interface UPCStringBrand {
  readonly UPCString: unique symbol;
}

export type UPCString = t.Branded<NumericString, UPCStringBrand>;

const JSON: t.Type<UPCString, string> = t.brand(
  NumericString.JSON,
  (s): s is UPCString => /^[0-9]{12}$/.test(s),
  'UPCString',
);

export const UPCString = {
  JSON,
};
