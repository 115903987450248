import * as React from 'react';
import Svg, { Path, Circle, Rect, G } from 'react-native-svg';

type Props = {
  size?: string | number;
  color?: string;
  disabled?: boolean;
  onPress?: () => void;
};

const ClientHistoryIcon = ({ size = 32, disabled = false, onPress }: Props) => (
  <Svg width={size} height={size} viewBox="0 0 32 32" disabled={disabled} onPress={onPress}>
    <Circle id="Mask" cx="16" cy="16" r="16" fill="#e9ecef" />
    <G transform="translate(7 7)">
      <G>
        <Rect width="18" height="18" fill="none" />
      </G>
      <G transform="translate(2.251 2.25)">
        <G>
          <G>
            <Path
              d="M16.5,8.34H11.415L13.47,6.225A5.279,5.279,0,0,0,6.06,6.15a5.156,5.156,0,0,0,0,7.343,5.265,5.265,0,0,0,7.41,0A4.885,4.885,0,0,0,15,9.825h1.5a7.006,7.006,0,0,1-1.98,4.717,6.79,6.79,0,0,1-9.54,0,6.63,6.63,0,0,1-.015-9.435,6.74,6.74,0,0,1,9.488,0L16.5,3ZM10.125,6.75V9.938L12.75,11.5l-.54.908L9,10.5V6.75Z"
              transform="translate(-3.001 -3)"
              fill="#52577f"
            />
          </G>
        </G>
      </G>
    </G>
  </Svg>
);

export default React.memo(ClientHistoryIcon);
