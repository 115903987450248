import { ObjectId } from '@mero/shared-sdk';
import * as t from 'io-ts';

export interface MembershipPaymentIdBrand {
  readonly MembershipPaymentId: unique symbol;
}

export type MembershipPaymentId = t.Branded<ObjectId, MembershipPaymentIdBrand>;

const JSON: t.Type<MembershipPaymentId, string> = t.brand(
  ObjectId,
  (_id: ObjectId): _id is MembershipPaymentId => true,
  'MembershipPaymentId',
);

export const MembershipPaymentId = {
  JSON,
};
