import * as t from 'io-ts';

export interface LongitudeBrand {
  readonly Longitude: unique symbol;
}

/**
 * https://en.wikipedia.org/wiki/Longitude
 */
export type Longitude = t.Branded<number, LongitudeBrand>;

const JSON: t.Type<Longitude, number> = t.brand(
  t.number,
  (val: number): val is Longitude => val >= -180 && val <= 180,
  'Longitude',
);

export const Longitude = {
  JSON,
};
