import * as t from 'io-ts';

export interface UserOrderPaymentIdBrand {
  readonly UserOrderPaymentId: unique symbol;
}

export type UserOrderPaymentId = t.Branded<string, UserOrderPaymentIdBrand>;

const JSON: t.Type<UserOrderPaymentId, string> = t.brand(
  t.string,
  (_id: string): _id is UserOrderPaymentId => true,
  'UserOrderPaymentId',
);

export const UserOrderPaymentId = {
  JSON,
};
