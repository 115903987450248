import { MERO_SMS_NAMESPACE } from '../namespaces';
import { IErrorTable } from 'exceptional.js';

export const TABLE: IErrorTable = {
  namespace: MERO_SMS_NAMESPACE,
  locale: 'ro',
  errors: {
    0: 'S-a intamplat ceva neprevazut',
    1: 'Exista un dispozitiv inregistrat cu acest identificator',
    2: 'Nu ai dreptul sa faci aceasta operatiune',
    3: 'Nu poti trimite mesaje pagini',
    4: 'Nu exista niciun dispozitiv inregistrat cu acest id',
    5: 'Nu exista niciun mesaj inregistrat cu acest id',
    6: 'Numarul de telefon nu este valid',
  },
};
