import { Coupon } from '../../coupons';
import { MarketplaceCouponsApi } from './marketplaceCouponsApi';
import { HttpClient, UnknownApiError, unsafeRight } from '@mero/shared-sdk';

export const marketplaceCouponsHttpClient = (env: { apiBaseUrl: string; http: HttpClient }): MarketplaceCouponsApi => {
  const { apiBaseUrl, http } = env;

  const getCouponByCodeDecoder = http.decode.response(UnknownApiError, Coupon.JSON);

  return {
    getCouponByCode: async ({ pageId, code, bookingStartingPoint }) => {
      return unsafeRight(
        await http.request(
          {
            method: 'GET',
            url: `${apiBaseUrl}/mp/pages/${encodeURIComponent(pageId)}/coupons`,
            params: {
              code: code,
              bookingStartingPoint: bookingStartingPoint,
            },
          },
          getCouponByCodeDecoder,
        ),
      );
    },
  };
};
