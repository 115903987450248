import * as MeroApi from '@mero/api-sdk';
import * as luxon from 'luxon';
import * as React from 'react';

import { colors } from '../../../styles';
import Body from '../../Text/Body';
import SmallBody from '../../Text/SmallBody';

export type Props = {
  readonly size?: 'small' | 'large';

  /**
   * Recurrence rule of the appointment
   */
  readonly recurrenceRule?: MeroApi.RecurrenceRule.Any;
};

/**
 * Format a recurrence rule to a display text.
 */
const formatRecurrenceRule = (recurrenceRule?: MeroApi.RecurrenceRule.Any) => {
  if (!recurrenceRule) return 'Nu se repetă';

  const displayStr = 'Se repetă ';

  let intervalStr = '';
  switch (recurrenceRule.repeatEvery.unit) {
    case 'day':
    case 'days': {
      if (recurrenceRule.repeatEvery.value === 1) {
        intervalStr = 'zilnic';
      } else {
        intervalStr = `la fiecare ${recurrenceRule.repeatEvery.value} zile`;
      }
      break;
    }

    case 'week':
    case 'weeks': {
      if (recurrenceRule.repeatEvery.value === 1) {
        intervalStr = 'săptămânal';
      } else {
        intervalStr = `la fiecare ${recurrenceRule.repeatEvery.value} săptămâni`;
      }
      break;
    }

    case 'month':
    case 'months': {
      if (recurrenceRule.repeatEvery.value === 1) {
        intervalStr = 'lunar';
      } else {
        intervalStr = `la fiecare ${recurrenceRule.repeatEvery.value} luni`;
      }
      break;
    }

    case 'year':
    case 'years': {
      if (recurrenceRule.repeatEvery.value === 1) {
        intervalStr = 'anual';
      } else {
        intervalStr = `la fiecare ${recurrenceRule.repeatEvery.value} ani`;
      }
      break;
    }
  }

  let endsOnStr = '';
  if (recurrenceRule.endsOn.date) {
    const dateStr = luxon.DateTime.fromJSDate(recurrenceRule.endsOn.date).toFormat('d.LL.yyyy');
    endsOnStr = ` până la ${dateStr}`;
  } else if (recurrenceRule.endsOn.times > 0) {
    endsOnStr = ` de ${recurrenceRule.endsOn.times} ori`;
  }

  return displayStr + intervalStr + endsOnStr;
};

/**
 * Component used to render the recurrence rule
 * of an appointment in a user readable manner.
 */
export default function UserAppointmentRecurrenceRule({ recurrenceRule, size = 'small' }: Props): React.ReactElement {
  const [displayStr, setDisplayStr] = React.useState('');

  React.useEffect(() => {
    setDisplayStr(formatRecurrenceRule(recurrenceRule));
  }, []);

  if (size === 'small') {
    return <SmallBody style={{ color: colors.COMET }}>{displayStr}</SmallBody>;
  }

  return <Body>{displayStr}</Body>;
}
