import { Money } from '../../money';
import { MeroUnits } from '../meroUnits';

/**
 * {@link AppliedDiscountValue} represents a fixed amount discount
 */
export type AppliedDiscountValue<Num, Unit extends MeroUnits.Any> = {
  readonly type: 'Value';
  readonly value: Money<Num, Unit>;
};

/**
 * Build new instance of {@link AppliedDiscountValue}
 */
const of = <Num, Unit extends MeroUnits.Any>(value: Money<Num, Unit>): AppliedDiscountValue<Num, Unit> => {
  return {
    type: 'Value',
    value,
  };
};

export const AppliedDiscountValue = {
  of,
};
