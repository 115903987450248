import { PositiveInt } from '@mero/shared-sdk';

type Unlimited = {
  readonly type: 'Unlimited';
  readonly from: Date;
};

type Limited = {
  readonly type: 'Limited';
  readonly from: Date;
  readonly days: PositiveInt;
};

export type CheckoutPurchasedMembershipValidity = Unlimited | Limited;

const unlimited = (from: Date): Unlimited => {
  return {
    type: 'Unlimited',
    from: from,
  };
};

const limited = (from: Date, days: PositiveInt): Limited => {
  return {
    type: 'Limited',
    from: from,
    days: days,
  };
};

export const CheckoutPurchasedMembershipValidity = {
  unlimited,
  limited,
};
