import { AnalyticsStats } from './analytics';
import { AnalyticsStatsNew } from './analytics2';
import { AnalyticsApi } from './analytics-api';
import { AnalyticsBoostGlobalStats, AnalyticsBoostStats } from './boost-stats';
import { MonthlyPerformanceReport } from './monthlyPerformanceReport';
import { HttpClient, UnknownApiError, unsafeRight } from '@mero/shared-sdk';
import * as t from 'io-ts';

export const analyticsHttpClient = (env: { apiBaseUrl: string; http: HttpClient }): AnalyticsApi => {
  const { apiBaseUrl, http } = env;

  const getBoostStatsDecoder = http.decode.response(UnknownApiError, t.type({ result: AnalyticsBoostStats }));
  const getBoostGlobalStatsDecoder = http.decode.response(
    UnknownApiError,
    t.type({ result: AnalyticsBoostGlobalStats }),
  );
  const getAnalyticsDecoder = http.decode.response(UnknownApiError, t.type({ result: t.array(AnalyticsStats) }));
  const getAnalyticsNewDecoder = http.decode.response(UnknownApiError, t.type({ result: AnalyticsStatsNew }));
  const getMonthlyPerformanceReportDecoder = http.decode.response(
    UnknownApiError,
    t.type({ result: MonthlyPerformanceReport.JSON }),
  );

  return {
    /**
     * Fetch boost stats for a page.
     */
    getPageBoostStats: async ({ pageId, from, to }): Promise<AnalyticsBoostStats> => {
      const params = { from, to };
      return unsafeRight(
        await http.request(
          {
            method: 'GET',
            url: `${apiBaseUrl}/analytics/page/${encodeURIComponent(pageId)}/boost-stats`,
            params,
          },
          getBoostStatsDecoder,
        ),
      ).result;
    },

    /**
     * Fetch boost global stats for a page.
     */
    getPageBoostGlobalStats: async ({ pageId }): Promise<AnalyticsBoostGlobalStats> => {
      return unsafeRight(
        await http.request(
          {
            method: 'GET',
            url: `${apiBaseUrl}/analytics/page/${encodeURIComponent(pageId)}/boost-global-stats`,
          },
          getBoostGlobalStatsDecoder,
        ),
      ).result;
    },

    getAnalytics: async ({ pageId, from, to, workerId }): Promise<AnalyticsStats> => {
      const params = { from, to, workerId };
      const { result } = unsafeRight(
        await http.request(
          {
            method: 'GET',
            url: `${apiBaseUrl}/analytics/page/${encodeURIComponent(pageId)}/statistics`,
            params,
          },
          getAnalyticsDecoder,
        ),
      );

      const stats = result.find(() => true);
      if (!stats) {
        throw new Error('No stats found');
      }

      return stats;
    },

    getAnalyticsNew: async ({ pageId, from, to, workerId }) => {
      const params = { from, to, workerId };
      const { result } = unsafeRight(
        await http.request(
          {
            method: 'GET',
            url: `${apiBaseUrl}/analytics/page/${encodeURIComponent(pageId)}/statistics2`,
            params,
          },
          getAnalyticsNewDecoder,
        ),
      );

      return result;
    },

    getMonthlyPerformanceReport: async ({ pageId, month, year }) => {
      const params = { month, year };
      const { result } = unsafeRight(
        await http.request(
          {
            method: 'GET',
            url: `${apiBaseUrl}/analytics/page/${encodeURIComponent(pageId)}/monthly-performance-report`,
            params,
          },
          getMonthlyPerformanceReportDecoder,
        ),
      );
      return result;
    },
  };
};
