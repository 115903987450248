import { TABLE as ADMIN_TABLE } from './admin';
import { TABLE as ANALYTICS_TABLE } from './analytics';
import { TABLE as ASSETS_TABLE } from './assets';
import { TABLE as BUSINESS_TABLE } from './business';
import { TABLE as CALENDARS_TABLE } from './calendars';
import { TABLE as DEFAULLT_TABLE } from './default';
import { TABLE as MERO_SMS_TABLE } from './meroSms';
import { TABLE as NOTIFICATIONS_TABLE } from './notifications';
import { TABLE as PAYMENTS_TABLE } from './payments';
import { TABLE as USERS_TABLE } from './users';
import { registerTable } from 'exceptional.js';

export function load() {
  registerTable(DEFAULLT_TABLE);
  registerTable(USERS_TABLE);
  registerTable(BUSINESS_TABLE);
  registerTable(CALENDARS_TABLE);
  registerTable(NOTIFICATIONS_TABLE);
  registerTable(ASSETS_TABLE);
  registerTable(PAYMENTS_TABLE);
  registerTable(ADMIN_TABLE);
  registerTable(ANALYTICS_TABLE);
  registerTable(MERO_SMS_TABLE);
}
