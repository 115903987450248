import { CheckoutItemTotal } from '../checkoutItemTotal';
import { CheckoutItemTotalScaledNumber } from '../checkoutItemTotalScaledNumber';
import { CheckoutTotals } from '../checkoutTotals';
import { CheckoutUserPreview } from '../checkoutUserPreview';
import { CompanyVatStatus } from '../companies';
import { MeroUnits, ScaledNumber } from '@mero/shared-sdk';

export type Amount<Unit extends MeroUnits.Any> = {
  readonly type: 'Amount';
  readonly transactionItemId?: string;
  readonly total: CheckoutItemTotal<ScaledNumber, Unit>;
  readonly saleOwner: CheckoutUserPreview;
  readonly note?: string;
};

export const amount = <Unit extends MeroUnits.Any>(params: {
  transactionItemId?: string;
  total: CheckoutItemTotal<ScaledNumber, Unit>;
  saleOwner: CheckoutUserPreview;
  note?: string;
}): Amount<Unit> => {
  return {
    type: 'Amount',
    transactionItemId: params.transactionItemId,
    total: params.total,
    saleOwner: params.saleOwner,
    note: params.note,
  };
};

const getTotals = <Unit extends MeroUnits.Any>(
  item: Amount<Unit>,
  unit: Unit,
  companyVat: CompanyVatStatus.Any<ScaledNumber>,
  decimals: number,
): CheckoutTotals<ScaledNumber, Unit> => {
  unit;
  return CheckoutItemTotalScaledNumber.getTotals(item.total, companyVat, decimals);
};

export const Amount = {
  getTotals,
};
