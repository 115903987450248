import * as t from 'io-ts';

export const BookingCompletedBanner = t.type(
  {
    imageUrl: t.string,
    externalLinkUrl: t.string,
    campaignName: t.string,
    active: t.boolean,
  },
  'BookingCompletedBanner',
);
export type BookingCompletedBanner = t.TypeOf<typeof BookingCompletedBanner>;
