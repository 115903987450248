import { JSONable } from '@mero/shared-sdk';
import * as t from 'io-ts';

export type PageGiftCardSettings = {
  readonly perSalonEnabled: boolean;
  readonly universalEnabled: boolean;
};

const JSON: t.Type<PageGiftCardSettings, JSONable> = t.type(
  {
    perSalonEnabled: t.boolean,
    universalEnabled: t.boolean,
  },
  'PageGiftCardSettings',
);

const DEFAULT: PageGiftCardSettings = {
  perSalonEnabled: false,
  universalEnabled: true,
};

export const PageGiftCardSettings = {
  JSON,
  DEFAULT,
};
