import * as React from 'react';
import { TouchableOpacity } from 'react-native';

import Button from '..';
import { ATHENS_GRAY, BLACK, WHITE } from '../../../styles/colors';
import Icon from '../../Icon';

type Props = {
  icon?: 'google' | 'facebook' | 'apple' | 'phone';
  text: string;
  onClick?: () => void;
  disabled?: boolean;
};

const LoginButton = ({ text, onClick, icon, disabled }: Props) => {
  return (
    <Button
      text={text}
      LeftComponent={
        icon
          ? () => (
              <TouchableOpacity onPress={onClick}>
                <Icon type={icon} disabled />
              </TouchableOpacity>
            )
          : undefined
      }
      size="login"
      backgroundColor={WHITE}
      color={BLACK}
      disabledBackgroundColor={ATHENS_GRAY}
      withShadow
      onClick={onClick}
      disabled={disabled}
    />
  );
};

export default LoginButton;
