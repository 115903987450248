import { extractApiError, isError, NetworkError } from '@mero/api-sdk';
import * as t from 'io-ts';

import { useToast } from '../components/Toast';

const extractUnknownApiError = extractApiError(t.unknown.is);

/**
 * @returns a function that will show an error to user
 * It will select ApiError if present within the hierarchy or fallbacks to default message
 * If default message is not provided - the message from the error will be displayed
 */
export const useShowError = (): ((e: unknown, defaultMessage?: string, networkErrorMessage?: string) => void) => {
  const toast = useToast();

  return (e: unknown, defaultMessage?: string, networkErrorMessage?: string): void => {
    // Try to find api error within hierarchy or fallback to original value
    const err = extractUnknownApiError(e);

    if (err) {
      toast.show({
        type: 'error',
        text: err.message,
        autoDismiss: true,
      });
    } else if (NetworkError.is(e)) {
      toast.show({
        type: 'error',
        text: networkErrorMessage ?? 'Ceva nu a mers bine. Verifică conexiunea la internet și încearcă din nou.',
        autoDismiss: true,
      });
    } else {
      toast.show({
        type: 'error',
        text: defaultMessage ?? (isError(e) ? e.message : 'S-a intamplat ceva neprevazut'),
        autoDismiss: true,
      });
    }
  };
};

export default useShowError;
