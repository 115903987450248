import { BasicCalculator } from './basicCalculator';

export type ExtendedCalculator<Num> = BasicCalculator<Num> & {
  /**
   * Check if {@link a} is zero
   */
  readonly isZero: (a: Num) => boolean;
  /**
   * Check if {@link a} is equal to {@link b}
   */
  readonly equals: (a: Num, b: Num) => boolean;

  /**
   * @returns true if {@link a} is less than {@link b}
   */
  readonly lessThan: (a: Num, b: Num) => boolean;

  /**
   * @returns true if {@link a} is less than or equal to {@link b}
   */
  readonly lessThanOrEqual: (a: Num, b: Num) => boolean;

  /**
   * @returns true if {@link a} is greater than {@link b}
   */
  readonly greaterThan: (a: Num, b: Num) => boolean;

  /**
   * @returns true if {@link a} is greater than or equal to {@link b}
   */
  readonly greaterThanOrEqual: (a: Num, b: Num) => boolean;
};

const isZero =
  <Num>(calculator: BasicCalculator<Num>) =>
  (a: Num): boolean => {
    return calculator.compare(a, calculator.zero()) === 0;
  };

const equals =
  <Num>(calculator: BasicCalculator<Num>) =>
  (a: Num, b: Num): boolean => {
    return calculator.compare(a, b) === 0;
  };

const lessThan =
  <Num>(calculator: BasicCalculator<Num>) =>
  (a: Num, b: Num): boolean => {
    return calculator.compare(a, b) === -1;
  };

const lessThanOrEqual =
  <Num>(calculator: BasicCalculator<Num>) =>
  (a: Num, b: Num): boolean => {
    return calculator.compare(a, b) !== 1;
  };

const greaterThan =
  <Num>(calculator: BasicCalculator<Num>) =>
  (a: Num, b: Num): boolean => {
    return calculator.compare(a, b) === 1;
  };

const greaterThanOrEqual =
  <Num>(calculator: BasicCalculator<Num>) =>
  (a: Num, b: Num): boolean => {
    return calculator.compare(a, b) !== -1;
  };

const build = <Num>(calculator: BasicCalculator<Num>): ExtendedCalculator<Num> => {
  return {
    ...calculator,
    isZero: isZero(calculator),
    equals: equals(calculator),
    lessThan: lessThan(calculator),
    lessThanOrEqual: lessThanOrEqual(calculator),
    greaterThan: greaterThan(calculator),
    greaterThanOrEqual: greaterThanOrEqual(calculator),
  };
};

export const ExtendedCalculator = {
  build,
};
