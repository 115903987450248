import * as t from 'io-ts';

/**
 * Receipts printing is not allowed.
 */
export type Disabled = {
  readonly type: 'Disabled';
};

const JSON = t.type(
  {
    type: t.literal('Disabled'),
  },
  'Disabled',
);

export const Disabled = {
  JSON,
};
