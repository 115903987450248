import * as t from 'io-ts';

export const InviteStatusPending = t.literal(0, 'InviteStatusPending');

export type InviteStatusPending = t.TypeOf<typeof InviteStatusPending>;

export const InviteStatusAccepted = t.literal(1, 'InviteStatusAccepted');

export type InviteStatusAccepted = t.TypeOf<typeof InviteStatusAccepted>;

export const InviteStatusRejected = t.literal(2, 'InviteStatusRejected');

export type InviteStatusRejected = t.TypeOf<typeof InviteStatusRejected>;

export const InviteStatus = t.union([InviteStatusPending, InviteStatusAccepted, InviteStatusRejected], 'InviteStatus');

export type InviteStatus = t.TypeOf<typeof InviteStatus>;
