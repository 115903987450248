import * as React from 'react';
import { Keyboard, View, ViewProps } from 'react-native';

/**
 * DismissKeyboardView allows touch events to be passed through to the underlying view, while dismissing the keyboard.
 */
const DismissKeyboardView: React.FC<React.PropsWithChildren<ViewProps>> = ({ children, ...props }) => {
  return (
    <View
      onStartShouldSetResponderCapture={() => {
        Keyboard.dismiss();
        return false;
      }}
      {...props}
    >
      {children}
    </View>
  );
};

export default DismissKeyboardView;
