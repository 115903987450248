import { CalendarId } from './calendar-id';
import * as t from 'io-ts';

export const HasCalendarId = t.type(
  {
    id: CalendarId,
  },
  'HasCalendarId',
);

export type HasCalendarId = t.TypeOf<typeof CalendarId>;
