import { PageId } from '../pages';
import { MembershipItem } from './membershipItem';
import { MembershipItemDetails } from './membershipItemDetails';
import { MembershipTemplateId } from './membershipTemplateId';
import { MembershipTemplateValidity } from './membershipTemplateValidity';
import {
  AppliedDiscount,
  AppliedDiscountScaledNumber,
  DefinedString,
  MeroCurrency,
  MeroUnits,
  Money,
  OptionalDefinedString,
  ScaledNumber,
} from '@mero/shared-sdk';

const MoneyScaledNumber = Money.build(ScaledNumber, MeroUnits);

export type MembershipTemplateDetails<Unit extends MeroUnits.Any> = {
  readonly _id: MembershipTemplateId;
  readonly unit: Unit;
  readonly pageId: PageId;
  readonly name: DefinedString;
  readonly validFor: MembershipTemplateValidity;
  readonly items: MembershipItemDetails<Unit>[];
  readonly status: 'Active' | 'Inactive' | 'Deleted';
  readonly discount: AppliedDiscount<ScaledNumber, Unit> | undefined;
  readonly description: OptionalDefinedString;
  readonly termsAndConditions: OptionalDefinedString;
  readonly sellingPrice: Money<ScaledNumber, Unit>;
};

function getPrice<Unit extends MeroUnits.Any>(
  membershipTemplateDetails: MembershipTemplateDetails<Unit>,
): Money<ScaledNumber, Unit> {
  const itemsTotal = membershipTemplateDetails.items.reduce(
    (sum, item) => MoneyScaledNumber.add(sum, MembershipItem.getSellingPrice(item, membershipTemplateDetails.unit)),
    MoneyScaledNumber.zero(membershipTemplateDetails.unit),
  );

  if (membershipTemplateDetails.discount) {
    return AppliedDiscountScaledNumber.applyTo(
      itemsTotal,
      membershipTemplateDetails.discount,
      MeroCurrency[membershipTemplateDetails.unit].exponent,
    );
  }

  return itemsTotal;
}

export const MembershipTemplateDetails = {
  getPrice,
};
