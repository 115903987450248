import { SubscriptionFeatureId } from './subscriptionFeatureId';
import { JSONable } from '@mero/shared-sdk';
import * as t from 'io-ts';

export type SubscriptionFeaturePreview = {
  readonly _id: SubscriptionFeatureId;
  readonly name: string;
};

const JSON: t.Type<SubscriptionFeaturePreview, JSONable> = t.type(
  {
    _id: SubscriptionFeatureId.JSON,
    name: t.string,
  },
  'SubscriptionFeaturePreview',
);

export const SubscriptionFeaturePreview = {
  JSON,
};
