import { DefinedTrimedString, UndefinedString } from '../string';
import * as t from 'io-ts';

export interface FirstnameBrand {
  readonly Firstname: unique symbol;
}

export const Firstname = t.brand(
  DefinedTrimedString,
  (p: string): p is t.Branded<DefinedTrimedString, FirstnameBrand> => true,
  'Firstname',
);
export type Firstname = t.TypeOf<typeof Firstname>;

export const OptionalFirstname = t.union([Firstname, UndefinedString], 'OptionalFirstname');
export type OptionalFirstname = t.TypeOf<typeof OptionalFirstname>;

export const HasFirstname = t.type(
  {
    firstname: Firstname,
  },
  'HasFirstname',
);
export type HasFirstname = t.TypeOf<typeof HasFirstname>;

export const HasOptionalFirstname = t.partial(
  {
    firstname: OptionalFirstname,
  },
  'HasOptionalFirstname',
);
export type HasOptionalFirstname = t.TypeOf<typeof HasOptionalFirstname>;

export interface FirstnameInputBrand {
  readonly FirstnameInput: unique symbol;
}

export const FirstnameInput = t.brand(
  Firstname,
  (name): name is t.Branded<Firstname, FirstnameInputBrand> => name.length >= 2,
  'FirstnameInput',
);

export type FirstnameInput = t.TypeOf<typeof FirstnameInput>;
