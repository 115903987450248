import { Worker } from './worker';
import { WorkerId } from './workerId';
import { HasId } from '@mero/shared-sdk';
import * as t from 'io-ts';

export type SavedWorker = HasId<WorkerId> & Worker;

const JSON = t.intersection([HasId.json(WorkerId.JSON), Worker.JSON], 'SavedWorker');

export const SavedWorker = {
  JSON,
};
