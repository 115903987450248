import * as React from 'react';
import { TextProps, Text } from 'react-native';

import { styles } from '../../styles/text';

export type LabelProps = React.PropsWithChildren<TextProps>;

const Label: React.FC<LabelProps> = (props: LabelProps) => (
  // This style of settings props avoids generation of _rest helper function in js output
  <Text {...props} style={[styles.label, props.style]}>
    {props.children}
  </Text>
);

export default Label;
