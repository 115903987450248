import { literalIso } from '@mero/shared-sdk';
import * as t from 'io-ts';

export const MonthlyBillingCycle = literalIso('monthly', 0);
export type MonthlyBillingCycle = t.TypeOf<typeof MonthlyBillingCycle>;

export const QuarterlyBillingCycle = literalIso('quarterly', 1);
export type QuarterlyBillingCycle = t.TypeOf<typeof QuarterlyBillingCycle>;

export const HalfYearBillingCycle = literalIso('halfYear', 2);
export type HalfYearBillingCycle = t.TypeOf<typeof HalfYearBillingCycle>;

export const AnnuallyBillingCycle = literalIso('annually', 3);
export type AnnuallyBillingCycle = t.TypeOf<typeof AnnuallyBillingCycle>;

export const BillingCycle = t.union(
  [MonthlyBillingCycle, QuarterlyBillingCycle, HalfYearBillingCycle, AnnuallyBillingCycle],
  'BillingCycle',
);
export type BillingCycle = t.TypeOf<typeof BillingCycle>;
