import * as MeroApi from '@mero/api-sdk';
import { DateTime } from 'luxon';
import * as React from 'react';
import { View } from 'react-native';

import { styles as text } from '../../../styles/text';
import { formatTimeDiff } from '../../../utils/format';
import Icon from '../../Icon';
import Body from '../../Text/Body';
import SmallBody from '../../Text/SmallBody';
import { styles } from '../styles';

export type Props = {
  readonly notification: MeroApi.notifications.AppointmentCancelledByClientNotification;
};

const AppointmentCancelledByClientNotification = ({ notification }: Props): React.ReactElement | null => {
  const payload = notification.payload;
  const { firstname, lastname } = payload.client;

  const appTs = DateTime.fromJSDate(payload.appointment.start, { zone: payload.appointment.timezone });

  return (
    <View style={styles.notificationContainer}>
      <Icon type="notification_calendar_cancel" />
      <View style={styles.notificationBody}>
        <Body>
          <Body style={text.semibold}>
            {firstname} {lastname}
          </Body>{' '}
          și-a anulat programarea din{' '}
          <Body style={text.semibold}>{appTs.toFormat('d LLL yyyy', { locale: 'ro' })}</Body> ora{' '}
          <Body style={text.semibold}>{appTs.toFormat('H:mm', { locale: 'ro' })}</Body>
          {payload.worker && (
            <>
              {' '}
              la{' '}
              <Body style={text.semibold}>
                {payload.worker.firstname} {payload.worker.lastname}
              </Body>
            </>
          )}{' '}
          de la {payload.page.name}. <Body style={text.semibold}>Motiv</Body>: "{payload.reason}".
        </Body>
        <SmallBody style={styles.timeText}>{formatTimeDiff(notification.createdAt, new Date())}</SmallBody>
      </View>
    </View>
  );
};

export default AppointmentCancelledByClientNotification;
