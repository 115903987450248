import * as MeroApi from '@mero/api-sdk';
import * as React from 'react';
import { View } from 'react-native';

import { colors } from '../../../styles';
import sizes from '../../../styles/sizes';
import { styles as text } from '../../../styles/text';
import { formatTimeDiff } from '../../../utils/format';
import HSpacer from '../../HSpacer';
import Icon from '../../Icon';
import Body from '../../Text/Body';
import SmallBody from '../../Text/SmallBody';
import { styles } from '../styles';

export type Props = {
  readonly notification: MeroApi.notifications.ClientAppointmentPaymentReceivedNotification;
};

const ClientAppointmentPaymentReceivedNotification = ({ notification }: Props): React.ReactElement | null => {
  const { payload } = notification;

  return (
    <View style={styles.notificationContainer}>
      <Icon type="notification_client_accepted" />
      <View style={styles.notificationBody}>
        <Body>
          A fost acceptată plata de la{' '}
          <Body style={text.semibold}>
            {payload.client.firstname} {payload.client.lastname}
          </Body>{' '}
          în valoare de <Body style={text.semibold}>{payload.amount / 100} RON</Body>.
        </Body>
        <SmallBody style={styles.timeText}>
          {formatTimeDiff(notification.createdAt, new Date())} • {payload.worker.firstname}{' '}
          {payload.worker.lastname ?? ''} {/* Appointment duration not available in notification payload! */}
        </SmallBody>
      </View>
      <HSpacer left={8} />
      <Icon type="next" size={sizes[24]} color={colors.DARK_BLUE} />
    </View>
  );
};

export default ClientAppointmentPaymentReceivedNotification;
