import { DefinedTrimedString, JSONable } from '@mero/shared-sdk';
import * as t from 'io-ts';

export type CompanyName = DefinedTrimedString;

const JSON: t.Type<CompanyName, JSONable> = DefinedTrimedString;

export const CompanyName = {
  JSON,
};
