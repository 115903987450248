import { JSONable, PortionPercentScaledNumber } from '@mero/shared-sdk';
import * as t from 'io-ts';

export type CheckoutReportPercents = {
  /**
   * Sum of gross values (price before discount is applied)
   */
  readonly gross: PortionPercentScaledNumber;
  /**
   * Sum of discounts
   */
  readonly discount: PortionPercentScaledNumber;
  /**
   * Sum of VAT values
   */
  readonly vat: PortionPercentScaledNumber;
  /**
   * gross - discount (includes VAT)
   */
  readonly total: PortionPercentScaledNumber;
  /**
   * total - vat
   */
  readonly net: PortionPercentScaledNumber;
  /**
   * Gross value for transactions that are marked as _protocol_ transactions
   */
  readonly protocol: PortionPercentScaledNumber;
};

const PERCENT_ZERO = PortionPercentScaledNumber.zero();

/**
 * Get percents zero value for given unit
 */
const ZERO: CheckoutReportPercents = {
  gross: PERCENT_ZERO,
  discount: PERCENT_ZERO,
  vat: PERCENT_ZERO,
  total: PERCENT_ZERO,
  net: PERCENT_ZERO,
  protocol: PERCENT_ZERO,
};

/**
 * Builew new JSON codec for type {@link CheckoutReportPercents} with given unit {@link unit}
 */
const JSON: t.Type<CheckoutReportPercents, JSONable> = t.type(
  {
    gross: PortionPercentScaledNumber.JSON,
    discount: PortionPercentScaledNumber.JSON,
    vat: PortionPercentScaledNumber.JSON,
    total: PortionPercentScaledNumber.JSON,
    net: PortionPercentScaledNumber.JSON,
    protocol: PortionPercentScaledNumber.JSON,
  },
  `CheckoutReportPercents`,
);

export const CheckoutReportPercents = {
  ZERO,
  JSON,
};
