import { ASSETS_NAMESPACE } from '../namespaces';
import { IErrorTable } from 'exceptional.js';

export const TABLE: IErrorTable = {
  namespace: ASSETS_NAMESPACE,
  locale: 'ro',
  errors: {
    0: 'S-a intamplat ceva neprevazut.',
    1: '${message}.',
  },
};
