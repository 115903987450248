import { NonRecurrent } from './nonRecurrent';
import { Recurrent } from './recurrent';
import { JSONable } from '@mero/shared-sdk';
import * as t from 'io-ts';

export type Any = Recurrent | NonRecurrent;

/**
 * Calendar entry recurrence properties
 */
const JSON: t.Type<Any, JSONable> = t.union([Recurrent.JSON, NonRecurrent.JSON], 'Any');

export const Any = {
  JSON,
};
