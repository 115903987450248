import { JSONable, Option } from '@mero/shared-sdk';
import * as t from 'io-ts';

export type Monthly = {
  readonly type: 'Monthly';
  /**
   * Day of month
   */
  readonly day: Option<number>;
};

const JSON: t.Type<Monthly, JSONable> = t.type(
  {
    type: t.literal('Monthly'),
    day: Option.json(t.number),
  },
  'Monthly',
);

export const Monthly = {
  JSON,
};
