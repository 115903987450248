import { AppointmentId } from '../../calendar/appointment-id';
import { ClientId } from '../../clients/client-id';
import { PageId } from '../../pages/page-id';
import { OnlinePaymentsAccountBalance } from './onlinePaymentsAccountBalance';
import { OnlinePaymentsPayoutId } from './onlinePaymentsPayoutId';
import { OnlinePaymentsPayoutPreview } from './onlinePaymentsPayoutPreview';
import { OnlinePaymentsPayoutSchedule } from './onlinePaymentsPayoutSchedule';
import { OnlinePaymentsStatus } from './onlinePaymentsStatus';
import { UserOrderDetails } from './userOrderDetails';
import { UserOrderId } from './userOrderId';
import { UserOrderPaymentDetails } from './userOrderPaymentDetails';
import { UserOrderPaymentId } from './userOrderPaymentId';
import { UserOrderPaymentPreview } from './userOrderPaymentPreview';
import { ScaledNumber, Money, MeroUnits, HasId, Paged, Option } from '@mero/shared-sdk';
import * as t from 'io-ts';

/**
 * Manage online payments, by PRO
 * - enable/disable/configure online payments
 * - view and manage user payments (list, view, refund)
 * - view and manage payouts
 */
export interface OnlinePaymentsProApi {
  /**
   * Get payments account balance data
   */
  readonly getAccountBalance: (pageId: PageId) => Promise<OnlinePaymentsAccountBalance>;

  /**
   * Read payments account status
   */
  readonly getStatus: (pageId: PageId) => Promise<OnlinePaymentsStatus>;

  /**
   * Create account payout
   */
  readonly createPayout: (params: {
    readonly pageId: PageId;
    readonly amount: Money<ScaledNumber, MeroUnits.RON>;
  }) => Promise<HasId<OnlinePaymentsPayoutId>>;

  /**
   * List account payout transactions
   */
  readonly listPayouts: (params: {
    readonly pageId: PageId;
    readonly page: Option<string>;
    readonly createdAt?: {
      readonly gte: Date;
      readonly lte: Date;
    };
  }) => Promise<Paged<OnlinePaymentsPayoutPreview[]>>;

  /**
   * Update payout schedule
   */
  readonly updatePayoutSchedule: (params: {
    readonly pageId: PageId;
    readonly schedule: OnlinePaymentsPayoutSchedule.Any;
  }) => Promise<void>;

  /**
   * Update payout destination bank account
   */
  readonly updatePayoutDestination: (params: {
    readonly pageId: PageId;
    readonly destination: {
      readonly type: 'BankAccount';
      readonly iban: string;
    };
  }) => Promise<void>;

  /**
   * List page online payments
   */
  readonly listPagePayments: (params: {
    readonly pageId: PageId;
    readonly createdAt: {
      readonly gte: Date;
      readonly lte: Date;
    };
    readonly page: Option<string>;
  }) => Promise<Paged<UserOrderPaymentPreview[]>>;

  /**
   * Export page payments a
   */
  readonly exportPagePayments: (params: {
    readonly pageId: PageId;
    readonly createdAt: {
      readonly gte: Date;
      readonly lte: Date;
    };
    readonly format: 'csv';
  }) => Promise<{ downloadUrl: string }>;

  /**
   * List page client online payments
   */
  readonly listClientPayments: (params: {
    readonly pageId: PageId;
    readonly clientId: ClientId;
    readonly page: Option<string>;
  }) => Promise<Paged<UserOrderPaymentPreview[]>>;

  /**
   * List appointment payments
   */
  readonly listAppointmentPayments: (params: {
    readonly pageId: PageId;
    readonly appointmentId: AppointmentId;
    readonly occurrenceIndex: number;
  }) => Promise<Paged<UserOrderPaymentPreview[]>>;

  /**
   * Get user order details by ID
   */
  readonly getUserOrderDetails: (params: {
    readonly pageId: PageId;
    readonly userOrderId: UserOrderId;
  }) => Promise<UserOrderDetails>;

  /**
   * Get user order payment details by ID
   */
  readonly getUserOrderPaymentDetails: (params: {
    readonly pageId: PageId;
    readonly userOrderPaymentId: UserOrderPaymentId;
  }) => Promise<UserOrderPaymentDetails>;

  /**
   * Refund user order payment for given amount
   */
  readonly refundPayment: (params: {
    readonly pageId: PageId;
    readonly userOrderPaymentId: UserOrderPaymentId;
    readonly amount: Money<ScaledNumber, MeroUnits.RON>;
  }) => Promise<void>;
}

const RESPONSES = {
  getAccountBalance: OnlinePaymentsAccountBalance.JSON,
  getStatus: OnlinePaymentsStatus.JSON,
  createPayout: HasId.json(OnlinePaymentsPayoutId.JSON),
  listPayouts: Paged.json(t.array(OnlinePaymentsPayoutPreview.JSON)),
  updatePayoutSchedule: t.unknown,
  updatePayoutDestination: t.unknown,
  listPagePayments: Paged.json(t.array(UserOrderPaymentPreview.JSON)),
  exportPagePayments: t.type(
    {
      downloadUrl: t.string,
    },
    'ExportPagePaymentResponse',
  ),
  listClientPayments: Paged.json(t.array(UserOrderPaymentPreview.JSON)),
  listAppointmentPayments: Paged.json(t.array(UserOrderPaymentPreview.JSON)),
  getUserOrderDetails: UserOrderDetails.JSON,
  getUserOrderPaymentDetails: UserOrderPaymentDetails.JSON,
  refundPayment: t.unknown,
};

export const OnlinePaymentsProApi = {
  RESPONSES,
};
