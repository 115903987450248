import { DefinedTrimedString, UndefinedString } from '../string';
import * as t from 'io-ts';

export interface LastnameBrand {
  readonly Lastname: unique symbol;
}

export const Lastname = t.brand(
  DefinedTrimedString,
  (p: string): p is t.Branded<DefinedTrimedString, LastnameBrand> => true,
  'Lastname',
);
export type Lastname = t.TypeOf<typeof Lastname>;

export const OptionalLastname = t.union([Lastname, UndefinedString], 'OptionalLastname');
export type OptionalLastname = t.TypeOf<typeof OptionalLastname>;

export const HasLastname = t.type(
  {
    lastname: Lastname,
  },
  'HasLastname',
);
export type HasLastname = t.TypeOf<typeof HasLastname>;

export const HasOptionalLastname = t.partial(
  {
    lastname: OptionalLastname,
  },
  'HasOptionalLastname',
);
export type HasOptionalLastname = t.TypeOf<typeof HasOptionalLastname>;
