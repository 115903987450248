import { JSONable } from '@mero/shared-sdk';
import * as t from 'io-ts';

export type Daily = {
  readonly type: 'Daily';
};

const JSON: t.Type<Daily, JSONable> = t.type(
  {
    type: t.literal('Daily'),
  },
  'Daily',
);

export const Daily = {
  JSON,
};
