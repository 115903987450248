import { JSONable } from '@mero/shared-sdk';
import * as t from 'io-ts';

export type NonEditable = {
  readonly type: 'NonEditable';
};

const JSON: t.Type<NonEditable, JSONable> = t.type(
  {
    type: t.literal('NonEditable'),
  },
  'NonEditable',
);

export const NonEditable = {
  JSON,
};
