import { ObjectId } from '@mero/shared-sdk';
import * as t from 'io-ts';

export interface WaitingListIdBrand {
  readonly WaitingListId: unique symbol;
}

export const WaitingListId = t.brand(
  ObjectId,
  (_id: ObjectId): _id is t.Branded<ObjectId, WaitingListIdBrand> => true,
  'WaitingListId',
);

export type WaitingListId = t.TypeOf<typeof WaitingListId>;
