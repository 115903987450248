import { USER_NAMESPACE } from '../namespaces';
import { IErrorTable } from 'exceptional.js';

export const TABLE: IErrorTable = {
  namespace: USER_NAMESPACE,
  locale: 'ro',
  errors: {
    0: 'S-a intamplat ceva neprevazut',
    1: 'Acest numar de telefon (${phone}) este deja folosit.',
    2: 'Codul introdus nu este valid.',
    3: 'Acest numar de telefon (${phone}) nu este inregistrat.',
    4: 'Acest utilizator nu exista.',
    5: 'Acest cont are deja un profil de Facebook asociat.',
    6: 'Nu am putut actualiza profilul utilizatorului.',
    7: 'Acest cont nu este activ.',
    8: 'Acest profil de facebook este folosit de alt utilizator.',
    9: 'Nu am putut actualiza numarul de telefon al utilizatorului.',
    10: 'Nu am putut crea utilizatorul.',
    11: 'Nu am putut genera codul pentru numarul de telefon introdus.',
    12: '${message}',
    13: 'Numarul de telefon introdus nu este valid.',
    14: 'Contul dumneavoastră nu are un cod pin asociat, folosiţi opţiunea de resetare pin pentru a continua.',
    15: 'Pinul introdus este greşit.',
    16: 'Codul pin curent este greşit.',
    17: 'Codul pin introdus este diferit de confirmarea acestuia.',
    18: 'Aţi introdus codul pin greşit de prea multe ori, va trebui să îl resetaţi prin SMS.',
    19: 'Numarul nou de telefon nu a putut fi verificat',
  },
};
