import { ClientTypeReport } from './clientTypeReport';
import { MonthlyCountChangeReport } from './monthlyCountChangeReport';
import { JSONable } from '@mero/shared-sdk';
import * as t from 'io-ts';

export type MonthlyPerformanceReport = {
  readonly bookingsReport: {
    readonly total: MonthlyCountChangeReport;
    readonly finished: MonthlyCountChangeReport;
    readonly noShow: MonthlyCountChangeReport;
    readonly cancelled: MonthlyCountChangeReport;
    readonly pending: MonthlyCountChangeReport;
  };
  readonly clientsReport: {
    readonly total: MonthlyCountChangeReport;
    readonly newClients: ClientTypeReport;
    readonly newClientsFromPro: ClientTypeReport;
    readonly existingClients: ClientTypeReport;
  };
};

const JSON: t.Type<MonthlyPerformanceReport, JSONable> = t.type(
  {
    bookingsReport: t.type(
      {
        total: MonthlyCountChangeReport.JSON,
        finished: MonthlyCountChangeReport.JSON,
        noShow: MonthlyCountChangeReport.JSON,
        cancelled: MonthlyCountChangeReport.JSON,
        pending: MonthlyCountChangeReport.JSON,
      },
      'BookingsReport',
    ),
    clientsReport: t.type(
      {
        total: MonthlyCountChangeReport.JSON,
        newClients: ClientTypeReport.JSON,
        newClientsFromPro: ClientTypeReport.JSON,
        existingClients: ClientTypeReport.JSON,
      },
      'ClientsReport',
    ),
  },
  'MonthlyPerformanceReport',
);

export const MonthlyPerformanceReport = {
  JSON: JSON,
};
