import { CheckoutTotals } from '../../checkout';
import {
  JSONable,
  MeroUnits,
  Money,
  ScaledNumber,
  isDefined,
  AppliedDiscountScaledNumber,
  AppliedDiscount,
  MeroCurrency,
} from '@mero/shared-sdk';
import * as t from 'io-ts';

export type CheckoutReportTotals<Unit extends MeroUnits.Any> = {
  /**
   * Sum of gross values (price before discount is applied)
   */
  readonly gross: Money<ScaledNumber, Unit>;
  /**
   * Sum of discounts
   */
  readonly discount: Money<ScaledNumber, Unit>;
  /**
   * Sum of VAT values 0 for protocol transactions
   */
  readonly vat: Money<ScaledNumber, Unit>;
  /**
   * gross - discount (includes VAT) or 0 for protocol transactions
   */
  readonly total: Money<ScaledNumber, Unit>;
  /**
   * total - vat or 0 for protocol transactions
   */
  readonly net: Money<ScaledNumber, Unit>;
  /**
   * Total value for transactions that are marked as _protocol_ transactions
   */
  readonly protocol: Money<ScaledNumber, Unit>;
};

const MoneyScaledNumber = Money.build(ScaledNumber, MeroUnits);

const NO_MONEY: { [Unit in MeroUnits.Any]: Money<ScaledNumber, Unit> } = {
  [MeroUnits.RON.code]: MoneyScaledNumber.zero(MeroUnits.RON.code),
  [MeroUnits.EUR.code]: MoneyScaledNumber.zero(MeroUnits.EUR.code),
};

const zeroMoney = <Unit extends MeroUnits.Any>(unit: Unit): Money<ScaledNumber, Unit> => {
  return NO_MONEY[unit];
};

const ZERO_TOTALS: { [Unit in MeroUnits.Any]: CheckoutReportTotals<Unit> } = {
  [MeroUnits.RON.code]: {
    gross: zeroMoney(MeroUnits.RON.code),
    discount: zeroMoney(MeroUnits.RON.code),
    vat: zeroMoney(MeroUnits.RON.code),
    total: zeroMoney(MeroUnits.RON.code),
    net: zeroMoney(MeroUnits.RON.code),
    protocol: zeroMoney(MeroUnits.RON.code),
  },
  [MeroUnits.EUR.code]: {
    gross: zeroMoney(MeroUnits.EUR.code),
    discount: zeroMoney(MeroUnits.EUR.code),
    vat: zeroMoney(MeroUnits.EUR.code),
    total: zeroMoney(MeroUnits.EUR.code),
    net: zeroMoney(MeroUnits.EUR.code),
    protocol: zeroMoney(MeroUnits.EUR.code),
  },
};

/**
 * Get totals zero value for given unit
 */
const zero = <Unit extends MeroUnits.Any>(unit: Unit): CheckoutReportTotals<Unit> => {
  return ZERO_TOTALS[unit];
};

const add = <Unit extends MeroUnits.Any>(
  a: CheckoutReportTotals<Unit>,
  b: CheckoutReportTotals<Unit>,
): CheckoutReportTotals<Unit> => {
  return {
    gross: MoneyScaledNumber.add(a.gross, b.gross),
    discount: MoneyScaledNumber.add(a.discount, b.discount),
    vat: MoneyScaledNumber.add(a.vat, b.vat),
    total: MoneyScaledNumber.add(a.total, b.total),
    net: MoneyScaledNumber.add(a.net, b.net),
    protocol: MoneyScaledNumber.add(a.protocol, b.protocol),
  };
};

const fromCheckoutTotals = <Unit extends MeroUnits.Any>(
  transaction: {
    readonly unit: Unit;
    readonly isProtocol: boolean;
  },
  totals: CheckoutTotals<ScaledNumber, Unit>,
): CheckoutReportTotals<Unit> => {
  const zero = zeroMoney(transaction.unit);
  const gross = totals.subtotal;
  const discount = isDefined(totals.discount) ? totals.discount : zero;
  const total = totals.total;

  if (transaction.isProtocol) {
    // vat, total and net fields represent real money paid by user, which are not really paid
    // for protocol transactions so vat, total and net are set to zero
    return {
      gross: gross,
      discount: discount,
      vat: zero,
      total: zero,
      net: zero,
      protocol: total,
    };
  } else {
    const vat = isDefined(totals.vat) ? totals.vat : zero;
    const net = MoneyScaledNumber.sub(totals.total, vat);

    return {
      gross: gross,
      discount: discount,
      vat: vat,
      total: total,
      net: net,
      protocol: zero,
    };
  }
};

const applyDiscount = <Unit extends MeroUnits.Any>(
  totals: CheckoutReportTotals<Unit>,
  discount: AppliedDiscount<ScaledNumber, Unit>,
): CheckoutReportTotals<Unit> => {
  const zero = MoneyScaledNumber.zero(totals.total.unit);

  const minZero = (money: Money<ScaledNumber, Unit>): Money<ScaledNumber, Unit> => {
    if (MoneyScaledNumber.lessThan(money, zero)) {
      return zero;
    }

    return money;
  };

  const discountValue =
    discount.type === 'Percent'
      ? AppliedDiscountScaledNumber.percentToValue(totals.total, discount, MeroCurrency[totals.total.unit].exponent)
      : discount;

  const discountSum = MoneyScaledNumber.add(totals.discount, discountValue.value);
  const newDiscount = MoneyScaledNumber.greaterThan(discountSum, totals.gross) ? totals.gross : discountSum;

  const newTotal = minZero(
    AppliedDiscountScaledNumber.applyTo(totals.total, discountValue, MeroCurrency[totals.total.unit].exponent),
  );
  const newVat =
    ScaledNumber.isZero(totals.total.amount) || ScaledNumber.isZero(totals.vat.amount)
      ? zero
      : minZero(
          MoneyScaledNumber.div(
            MoneyScaledNumber.mul(newTotal, totals.vat.amount),
            totals.total.amount,
            MeroCurrency[totals.total.unit].exponent,
          ),
        );
  const newNet = minZero(MoneyScaledNumber.sub(newTotal, newVat));
  const newProtocol = minZero(
    AppliedDiscountScaledNumber.applyTo(totals.protocol, discountValue, MeroCurrency[totals.protocol.unit].exponent),
  );

  return {
    gross: totals.gross,
    discount: newDiscount,
    total: newTotal,
    vat: newVat,
    net: newNet,
    protocol: newProtocol,
  };
};

/**
 * Build new JSON codec for type {@link CheckoutReportTotals} with given unit {@link unit}
 */
const json = <Unit extends MeroUnits.Any>(unit: Unit): t.Type<CheckoutReportTotals<Unit>, JSONable> => {
  const unitC = t.literal(unit);

  return t.type(
    {
      gross: Money.json(ScaledNumber.JSON, unitC),
      discount: Money.json(ScaledNumber.JSON, unitC),
      vat: Money.json(ScaledNumber.JSON, unitC),
      total: Money.json(ScaledNumber.JSON, unitC),
      net: Money.json(ScaledNumber.JSON, unitC),
      protocol: Money.json(ScaledNumber.JSON, unitC),
    },
    `CheckoutReportTotals<${unit}>`,
  );
};

export const CheckoutReportTotals = {
  zero,
  add,
  fromCheckoutTotals,
  applyDiscount,
  json,
};
