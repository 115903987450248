import { proBootHttpClient } from './boot';
import { proClientFeedbackHttpClient } from './clientFeedback';
import { inventoriesHttpClient } from './inventories';
import { MeroProApi } from './meroProApi';
import { onlinePaymentsProHttpClient } from './onlinePayments';
import { pageBillingDetailsHttpClient } from './pageBillingDetails';
import { paymentsProHttpClient } from './payments';
import { proUserSatisfactionHttpClient } from './proUserSatisfaction';
import { productSalesHttpClient } from './productSales';
import { productsHttpClient } from './products';
import { proServicesSearchHttpClient } from './servicesSearch';
import { subscriptionProHttpClient } from './subscription';
import { HttpClient } from '@mero/shared-sdk';

export const meroProHttpClient = (env: { apiBaseUrl: string; http: HttpClient }): MeroProApi => {
  const boot = proBootHttpClient(env);
  const subscription = subscriptionProHttpClient(env);
  const payments = paymentsProHttpClient(env);
  const onlinePayments = onlinePaymentsProHttpClient(env);
  const products = productsHttpClient(env);
  const productSales = productSalesHttpClient(env);
  const inventories = inventoriesHttpClient(env);
  const pageBillingDetails = pageBillingDetailsHttpClient(env);
  const clientFeedback = proClientFeedbackHttpClient(env);
  const userSatisfaction = proUserSatisfactionHttpClient(env);
  const servicesSearch = proServicesSearchHttpClient(env);

  return {
    boot,
    subscription,
    payments,
    onlinePayments,
    products,
    productSales,
    inventories,
    pageBillingDetails,
    clientFeedback,
    userSatisfaction,
    servicesSearch,
  };
};
