import * as t from 'io-ts';

export const LogLevel = t.union(
  [t.literal('error'), t.literal('warn'), t.literal('info'), t.literal('debug')],
  'LogLevel',
);

export type LogLevel = t.TypeOf<typeof LogLevel>;

const LogLevelPriority: { [k in LogLevel]: number } = {
  error: 0,
  warn: 10,
  info: 20,
  debug: 30,
};

const shouldLog = (level: LogLevel, atLevel: LogLevel): boolean => LogLevelPriority[level] <= LogLevelPriority[atLevel];

export type LogFn = <Data extends any[]>(msg: string, ...data: Data) => void;

export type Logger = {
  error: LogFn;
  exception: (e: unknown) => void;
  warn: LogFn;
  info: LogFn;
  debug: LogFn;
};

const nop = (): void => {
  // pass
};

const ts = (): string => new Date().toISOString();

export type ConsoleLoggerEnv = {
  readonly level: LogLevel;
  readonly reportException?: (e: unknown) => void;
};

export const consoleLogger = ({ level, reportException = nop }: ConsoleLoggerEnv): Logger => ({
  error: shouldLog('error', level)
    ? (message, ...data) => {
        console.error(`${ts()} error: ${message}`, ...data);
      }
    : nop,
  exception: shouldLog('error', level)
    ? (e: unknown) => {
        console.error(e);
        try {
          reportException(e);
        } catch (err) {
          console.error(err);
        }
      }
    : nop,
  warn: shouldLog('warn', level)
    ? (message, ...data) => {
        console.warn(`${ts()} warn: ${message}`, ...data);
      }
    : nop,
  info: shouldLog('info', level)
    ? (message, ...data) => {
        console.info(`${ts()} info: ${message}`, ...data);
      }
    : nop,
  debug: shouldLog('debug', level)
    ? (message, ...data) => {
        console.debug(`${ts()} debug: ${message}`, ...data);
      }
    : nop,
});
