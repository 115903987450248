import * as MeroApi from '@mero/api-sdk';
import * as React from 'react';
import { View } from 'react-native';

import { colors } from '../../../styles';
import sizes from '../../../styles/sizes';
import { styles as text } from '../../../styles/text';
import { formatTimeDiff } from '../../../utils/format';
import HSpacer from '../../HSpacer';
import Icon, { NotificationCalendarOpenIcon, NotificationCalendarCancelIcon } from '../../Icon';
import Body from '../../Text/Body';
import SmallBody from '../../Text/SmallBody';
import { styles } from '../styles';

export type Props = {
  readonly notification: MeroApi.notifications.PrivateWorkerClientJoinRequestFulfilledNotification;
};

const PrivateWorkerClientJoinRequestFulfilledNotification: React.FC<Props> = ({ notification }: Props) => {
  const { payload } = notification;

  return (
    <View style={styles.notificationContainer}>
      {payload.accepted ? (
        <>
          <NotificationCalendarOpenIcon />
          <View style={styles.notificationBody}>
            <Body>
              Acum poți să-ți faci programare la{' '}
              <Body style={text.semibold}>
                {payload.worker.firstname} {payload.worker.lastname ?? ''}
              </Body>{' '}
              de la <Body style={text.semibold}>{payload.page.name}</Body>.
            </Body>
            <SmallBody style={styles.timeText}>{formatTimeDiff(notification.createdAt, new Date())}</SmallBody>
          </View>
          <HSpacer left={8} />
          <Icon type="next" size={sizes[24]} color={colors.DARK_BLUE} />
        </>
      ) : (
        <>
          <NotificationCalendarCancelIcon />
          <View style={styles.notificationBody}>
            <Body>
              Cererea ta la{' '}
              <Body style={text.semibold}>
                {payload.worker.firstname} {payload.worker.lastname ?? ''}
              </Body>{' '}
              de la <Body style={text.semibold}>{payload.page.name}</Body> a fost refuzată
            </Body>
            <SmallBody style={styles.timeText}>{formatTimeDiff(notification.createdAt, new Date())}</SmallBody>
          </View>
          <HSpacer left={8} />
          <Icon type="next" size={sizes[24]} color={colors.DARK_BLUE} />
        </>
      )}
    </View>
  );
};

export default PrivateWorkerClientJoinRequestFulfilledNotification;
