import * as t from 'io-ts';

export interface FavoriteIdBrand {
  readonly FavoriteId: unique symbol;
}

export type FavoriteId = t.Branded<string, FavoriteIdBrand>;

const JSON: t.Type<FavoriteId, string> = t.brand(t.string, (_id: string): _id is FavoriteId => true, 'FavoriteId');

export const FavoriteId = {
  JSON,
};
