import * as React from 'react';
import Svg, { Path } from 'react-native-svg';

import { COMET, WHITE } from '../../../styles/colors';

type Props = {
  size?: string | number;
  color?: string;
  backgroundColor?: string;
  disabled?: boolean;
  onPress?: () => void;
};

const Star = ({ size = 'normal', color = COMET, backgroundColor = WHITE, disabled = false, onPress }: Props) => {
  switch (size) {
    case 'contain':
      return (
        <Svg viewBox="0 0 47 45" disabled={disabled} onPress={onPress}>
          <Path
            id="Polygon_3"
            data-name="Polygon 3"
            d="M22.5,0l6.188,15.213L45,16.425,32.512,27.038,36.406,43,22.5,34.346,8.594,43l3.894-15.962L0,16.425l16.313-1.211Z"
            transform="translate(1 1)"
            fill={backgroundColor}
            stroke={color}
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="1"
          />
        </Svg>
      );
    case 'tiny':
      return (
        <Svg width="12" height="11" viewBox="0 0 12 11" disabled={disabled} onPress={onPress}>
          <Path
            id="Polygon_3"
            data-name="Polygon 3"
            d="M5.5,0,7.013,3.538,11,3.82,7.947,6.288,8.9,10,5.5,7.988,2.1,10l.952-3.712L0,3.82l3.987-.282Z"
            transform="translate(0.5 0.5)"
            fill={backgroundColor}
            stroke={color}
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="1"
          />
        </Svg>
      );
    case 'medium':
      return (
        <Svg width="25.091" height="24" viewBox="0 0 25.091 24" disabled={disabled} onPress={onPress}>
          <Path
            id="Path_8321"
            data-name="Path 8321"
            d="M14.545,21.288,22.3,26l-2.057-8.88,6.85-5.975-9.02-.771L14.545,2,11.02,10.375,2,11.145,8.85,17.12,6.792,26Z"
            transform="translate(-2 -2)"
            fill={backgroundColor}
          />
        </Svg>
      );
    case 'small':
      return (
        <Svg width="29.5" height="28.5" viewBox="0 0 29.5 28.5" disabled={disabled} onPress={onPress}>
          <Path
            id="Polygon_3"
            data-name="Polygon 3"
            d="M14,0l3.85,9.552L28,10.313l-7.771,6.664L22.652,27,14,21.566,5.348,27,7.771,16.977,0,10.313l10.15-.761Z"
            transform="translate(0.75 0.75)"
            fill={backgroundColor}
            stroke={color}
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="1.5"
          />
        </Svg>
      );
    default:
      return (
        <Svg width="47" height="45" viewBox="0 0 47 45" disabled={disabled} onPress={onPress}>
          <Path
            id="Polygon_3"
            data-name="Polygon 3"
            d="M22.5,0l6.188,15.213L45,16.425,32.512,27.038,36.406,43,22.5,34.346,8.594,43l3.894-15.962L0,16.425l16.313-1.211Z"
            transform="translate(1 1)"
            fill={backgroundColor}
            stroke={color}
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="2"
          />
        </Svg>
      );
  }
};

export default React.memo(Star);
