import * as React from 'react';
import Svg, { Path, G, Circle } from 'react-native-svg';

const PendingLarge = () => (
  <Svg width="88" height="88" viewBox="0 0 88 88">
    <G id="Orion_time" transform="translate(-1 -1)">
      <Path id="layer1" d="M43,0A43,43,0,1,1,0,43,43,43,0,0,1,43,0Z" transform="translate(2 2)" fill="#fbac40" />
      <Path
        id="opacity"
        d="M41.864,2A42.711,42.711,0,0,0,8.881,17.523,42.8,42.8,0,0,1,69.14,77.782,42.8,42.8,0,0,0,41.864,2Z"
        transform="translate(2.936 0)"
        fill="#000064"
        opacity="0.15"
      />
      <Path
        id="opacity-2"
        data-name="opacity"
        d="M46.12,55.226a2.828,2.828,0,0,1-1.35-.341L29,45.516V13.853a2.853,2.853,0,0,1,5.707,0V42.109l12.766,7.752a2.853,2.853,0,0,1-1.352,5.364Z"
        transform="translate(11.519 3.84)"
        fill="#000064"
        opacity="0.15"
      />
      <Circle
        id="stroke"
        cx="43"
        cy="43"
        r="43"
        transform="translate(2 2)"
        fill="none"
        stroke="#3b3f5c"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit="10"
        strokeWidth="2"
      />
      <Path
        id="stroke-2"
        data-name="stroke"
        d="M32,10V42.813L50.546,52.8"
        transform="translate(12.799 3.413)"
        fill="none"
        stroke="#3b3f5c"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit="10"
        strokeWidth="2"
      />
    </G>
  </Svg>
);

export default React.memo(PendingLarge);
