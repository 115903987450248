import * as React from 'react';
import Svg, { Circle, G, Path } from 'react-native-svg';

import { SHAMROCK, WHITE } from '../../../styles/colors';

type Props = {
  size?: string | number;
  color?: string;
  backgroundColor?: string;
  disabled?: boolean;
  onPress?: () => void;
};

const CheckOutline = ({ size = 24, color = WHITE, backgroundColor = SHAMROCK, disabled, onPress }: Props) => (
  <Svg width={size} height={size} viewBox={`0 0 ${size} ${size}`} disabled={disabled} onPress={onPress}>
    <G id="Group_6744" data-name="Group 6744" transform="translate(-126 -558)">
      <Circle
        id="Ellipse_11"
        data-name="Ellipse 11"
        cx={+size / 2}
        cy={+size / 2}
        r={+size / 2}
        transform="translate(126 558)"
        fill={backgroundColor}
      />
      <Path
        id="Path"
        d="M5.76,11.681l3.094,3.094L16.588,7.04"
        transform="translate(126.99 559.21)"
        fill="none"
        stroke={color}
        strokeLinecap="square"
        strokeMiterlimit="10"
        strokeWidth="2"
      />
    </G>
  </Svg>
);

export default React.memo(CheckOutline);
