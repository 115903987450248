import { MeroUnits, Money } from '@mero/shared-sdk';

export type Cash<Num, Unit extends MeroUnits.Any> = {
  readonly type: 'Cash';
  readonly total: Money<Num, Unit>;
};

export const cash = <Num, Unit extends MeroUnits.Any>(total: Money<Num, Unit>): Cash<Num, Unit> => {
  return {
    type: 'Cash',
    total: total,
  };
};
