import { JSONable } from '@mero/shared-sdk';
import * as t from 'io-ts';

export type FeatureFlagsPro = {
  readonly barberStore?: {
    readonly enabled: boolean;
  };
  readonly authenticationWithFacebook?: {
    readonly enabled: boolean;
  };
  readonly notificationsRefresh?:
    | {
        readonly enabled: true;
        readonly value: number;
      }
    | {
        readonly enabled: false;
      };
  readonly selectivePaymentMethod?:
    | {
        readonly enabled: true;
        readonly value: 'stripe';
      }
    | {
        readonly enabled: false;
      };
  readonly boost?: {
    readonly enabled: boolean;
  };
  readonly calendarDebounce?:
    | {
        readonly enabled: true;
        readonly value: number;
      }
    | {
        readonly enabled: false;
      };
};

export const FeatureFlagsPro: t.Type<FeatureFlagsPro, JSONable> = t.partial(
  {
    barberStore: t.type(
      {
        enabled: t.boolean,
      },
      'BarberStore',
    ),
    authenticationWithFacebook: t.type(
      {
        enabled: t.boolean,
      },
      'AuthenticationWithFacebook',
    ),
    notificationsRefresh: t.union(
      [
        t.type(
          {
            enabled: t.literal(true),
            value: t.number,
          },
          'Enabled',
        ),
        t.type(
          {
            enabled: t.literal(false),
          },
          'Disabled',
        ),
      ],
      'NotificationsRefresh',
    ),
    selectivePaymentMethod: t.union(
      [
        t.type(
          {
            enabled: t.literal(true),
            value: t.literal('stripe'),
          },
          'Disabled',
        ),
        t.type(
          {
            enabled: t.literal(false),
          },
          'Enabled',
        ),
      ],
      'NotificationsRefresh',
    ),
    boost: t.type(
      {
        enabled: t.boolean,
      },
      'Boost',
    ),
    calendarDebounce: t.union(
      [
        t.type(
          {
            enabled: t.literal(true),
            value: t.number,
          },
          'Enabled',
        ),
        t.type(
          {
            enabled: t.literal(false),
          },
          'Disabled',
        ),
      ],
      'CalendarDebounce',
    ),
  },
  'FeatureFlagsPro',
);
