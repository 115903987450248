import { PAYMENTS_NAMESPACE } from '../namespaces';
import { IErrorTable } from 'exceptional.js';

export const TABLE: IErrorTable = {
  namespace: PAYMENTS_NAMESPACE,
  locale: 'ro',
  errors: {
    0: 'S-a intamplat ceva neprevazut',
    1: '${message}',
    2: 'Nu am gasit pagina cautata.',
    3: 'Datele de facturare sunt incorecte.',
    4: 'Comanda MERO nu poate fi plasata deoarece lipsesc datele de facturare ale paginii.',
    5: 'Exista deja o comanda in curs de procesare pentru plata acestei pagini.',
    6: 'Aceasta comanda MERO nu exista.',
    7: '',
    8: 'Plata cu cardul nu a fost configurata pentru contul acestui salon.',
    9: 'Nu am putut procesa plata (${reason}).',
    10: 'Detaliile de facturare sunt incomplete',
    11: 'O companie cu codul fiscal ${fiscalCode} există deja',
  },
};
