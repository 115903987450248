import * as React from 'react';
import { Keyboard, Platform, Pressable, PressableProps } from 'react-native';

const DismissKeyboard: React.FC<React.PropsWithChildren<PressableProps>> = (props) => {
  return (
    <Pressable onPressIn={() => Platform.OS !== 'web' && Keyboard.dismiss()} {...props}>
      {props.children}
    </Pressable>
  );
};

export default DismissKeyboard;
