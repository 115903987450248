import { NOTIFICATIONS_NAMESPACE } from '../namespaces';
import { IErrorTable } from 'exceptional.js';

export const TABLE: IErrorTable = {
  namespace: NOTIFICATIONS_NAMESPACE,
  locale: 'ro',
  errors: {
    0: 'S-a intamplat ceva neprevazut',
    1: '${message}',
    2: 'Nu am putut crea reamintirea.',
    3: 'Reamintirea a fost deja trimisa.',
  },
};
