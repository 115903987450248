import { ObjectId } from '@mero/shared-sdk';
import * as t from 'io-ts';

export interface SubscriptionIdBrand {
  readonly SubscriptionId: unique symbol;
}

export const SubscriptionId = t.brand(
  ObjectId,
  (_id: ObjectId): _id is t.Branded<ObjectId, SubscriptionIdBrand> => true,
  'SubscriptionId',
);

export type SubscriptionId = t.TypeOf<typeof SubscriptionId>;

export const HasSubscriptionId = t.type(
  {
    _id: SubscriptionId,
  },
  'HasSubscriptionId',
);
export interface HasSubscriptionId extends t.TypeOf<typeof HasSubscriptionId> {}
