import { NumberCalculator } from './numberCalculator';
import { Positive, PositiveModule } from './positive';
import * as t from 'io-ts';

/**
 * Any number greater than 0
 */
export type PositiveNumber = Positive<number>;

type PositiveNumberModule = PositiveModule<number> & {
  readonly JSON: t.Type<PositiveNumber, number>;
};

const PositiveNumberN = Positive.build(NumberCalculator);

export const PositiveNumber: PositiveNumberModule = {
  ...PositiveNumberN,
  JSON: PositiveNumberN.json(t.number),
};
