import { JSONable, MeroUnits, Money, ScaledNumber, optionull } from '@mero/shared-sdk';
import * as t from 'io-ts';
import * as tt from 'io-ts-types';

export type AutomaticBoostStatus =
  | {
      //page has an active promotion interval
      readonly type: 'Active';
      readonly start: Date;
      readonly budget: Money<ScaledNumber, MeroUnits.RON> | undefined;
      readonly activePromotionInterval: {
        readonly from: Date;
        readonly to: Date;
      };
      readonly automaticReactivation: boolean;
    }
  | {
      //page is eligible but boost was not activated
      readonly type: 'Inactive';
    }
  | {
      //page is not eligible for boost
      readonly type: 'Unavailable';
    };

const JSON: t.Type<AutomaticBoostStatus, JSONable> = t.union(
  [
    t.type({
      type: t.literal('Active'),
      start: tt.DateFromISOString,
      budget: optionull(Money.json(ScaledNumber.JSON, MeroUnits.RON.JSON)),
      activePromotionInterval: t.type({
        from: tt.DateFromISOString,
        to: tt.DateFromISOString,
      }),
      automaticReactivation: t.boolean,
    }),
    t.type({
      type: t.literal('Inactive'),
    }),
    t.type({
      type: t.literal('Unavailable'),
    }),
  ],
  'AutomaticBoostStatus',
);

export const AutomaticBoostStatus = {
  JSON,
};
