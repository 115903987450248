import * as React from 'react';
import Svg, { Path, G, Rect } from 'react-native-svg';

type Props = {
  size?: number | string;
  color?: string;
  disabled?: boolean;
  onPress?: () => void;
};

const ProMenuSubscription = ({ size = 32, color = '#000000', disabled, onPress }: Props) => (
  <Svg width={size} height={size} viewBox="0 0 32 32" disabled={disabled} onPress={onPress}>
    <G transform="translate(4 4)">
      <Path
        d="M19.5,3.5,18,2,16.5,3.5,15,2,13.5,3.5,12,2,10.5,3.5,9,2,7.5,3.5,6,2V16H3v3a3,3,0,0,0,3,3H18a3,3,0,0,0,3-3V2ZM15,20H6a1,1,0,0,1-1-1V18H15Zm4-1a1,1,0,0,1-2,0V16H8V5H19Z"
        fill={color}
      />
      <Rect width="6" height="2" transform="translate(9 7)" fill={color} />
      <Rect width="2" height="2" transform="translate(16 7)" fill={color} />
      <Rect width="6" height="2" transform="translate(9 10)" fill={color} />
      <Rect width="2" height="2" transform="translate(16 10)" fill={color} />
    </G>
  </Svg>
);

export default React.memo(ProMenuSubscription);
