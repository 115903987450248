import { UserOrderId } from './userOrderId';
import { JSONable, MeroUnits, Money, Option, ScaledNumber } from '@mero/shared-sdk';
import * as t from 'io-ts';
import * as tt from 'io-ts-types';

/**
 * Order details within UserOrderPayment details
 */
export type UserOrderDetails = {
  readonly _id: UserOrderId;
  readonly byUser: {
    readonly firstname: Option<string>;
    readonly lastname: Option<string>;
    readonly phone: Option<string>;
  };
  readonly items: UserOrderDetailsItem[];
  readonly subtotal: Money<ScaledNumber, MeroUnits.RON>;
  readonly discount: Option<Money<ScaledNumber, MeroUnits.RON>>;
  readonly vat: Money<ScaledNumber, MeroUnits.RON>;
  readonly total: Money<ScaledNumber, MeroUnits.RON>;
  readonly company: Company;
  readonly createdAt: Date;
};

type AppointmentItem = {
  readonly type: 'Appointment';
  readonly title: string;
  readonly quantity: ScaledNumber;
  readonly price: Money<ScaledNumber, MeroUnits.RON>;
  readonly appointmentId: string;
  readonly occurrenceIndex: Option<number>;
};

type SalonGiftCardItem = {
  readonly type: 'SalonGiftCard';
  readonly title: string;
  readonly quantity: ScaledNumber;
  readonly price: Money<ScaledNumber, MeroUnits.RON>;
  readonly giftCardId: string;
};

export type UserOrderDetailsItem = AppointmentItem | SalonGiftCardItem;

type Company = {
  readonly name: string;
  readonly regNo: string;
  readonly fiscalCode: string;
  readonly countryCode: string;
  readonly city: string;
  readonly address: string;
};

const JSON: t.Type<UserOrderDetails, JSONable> = t.type(
  {
    _id: UserOrderId.JSON,
    byUser: t.type(
      {
        firstname: Option.json(t.string),
        lastname: Option.json(t.string),
        phone: Option.json(t.string),
      },
      'ByUser',
    ),
    items: t.array(
      t.union(
        [
          t.type(
            {
              type: t.literal('Appointment'),
              title: t.string,
              quantity: ScaledNumber.JSON,
              price: Money.json(ScaledNumber.JSON, MeroUnits.RON.JSON),
              appointmentId: t.string,
              occurrenceIndex: Option.json(t.number),
            },
            'Appointment',
          ),
          t.type(
            {
              type: t.literal('SalonGiftCard'),
              title: t.string,
              quantity: ScaledNumber.JSON,
              price: Money.json(ScaledNumber.JSON, MeroUnits.RON.JSON),
              giftCardId: t.string,
            },
            'SalonGiftCard',
          ),
        ],
        'Item',
      ),
    ),
    subtotal: Money.json(ScaledNumber.JSON, MeroUnits.RON.JSON),
    discount: Option.json(Money.json(ScaledNumber.JSON, MeroUnits.RON.JSON)),
    vat: Money.json(ScaledNumber.JSON, MeroUnits.RON.JSON),
    total: Money.json(ScaledNumber.JSON, MeroUnits.RON.JSON),
    company: t.type(
      {
        name: t.string,
        regNo: t.string,
        fiscalCode: t.string,
        countryCode: t.string,
        city: t.string,
        address: t.string,
      },
      'Company',
    ),
    createdAt: tt.DateFromISOString,
  },
  'UserOrderDetails',
);

export const UserOrderDetails = {
  JSON,
};
