import { enumType } from '../common';
import { OrderId } from './order-id';
import { SubscriptionId } from './subscription-id';
import { SubscriptionTier } from './subscriptionTier';
import { BillingCompanyId, MeroMoney, MeroUnits, optionull } from '@mero/shared-sdk';
import * as t from 'io-ts';
import { DateFromISOString } from 'io-ts-types';

export enum OrderTypesEnum {
  novabooker,
  merchant,
}

const OrderTypes = enumType<OrderTypesEnum>(OrderTypesEnum);

export enum OrderStatusEnum {
  pending = 0,
  complete = 1,
  cancelled = 2,
  refund = 3,
  deleted = 4,
  completing = 5,
}

const OrderStatus = enumType<OrderStatusEnum>(OrderStatusEnum);

export const Currency = t.union([t.literal('EUR'), t.literal('RON')]);

export type Currency = t.TypeOf<typeof Currency>;

export const Tracking = t.type({
  series: t.string,
  subseries: t.string,
  number: t.number,
});

export type Tracking = t.TypeOf<typeof Tracking>;

export enum ProductScopeEnum {
  base = 0,
  workerSeat = 1,
  workerSeatProRata = 2,
  smsNotifications = 3,
  outstandingBalance = 4,
  boost = 5,
}

export const ProductScope = enumType<ProductScopeEnum>(ProductScopeEnum);

export const Product = t.intersection([
  t.type({
    name: t.string,
    pid: t.string,
    pcode: t.string,
    price: t.number,
    scope: ProductScope,
  }),
  t.partial({
    isPriceEur: t.boolean,
    tier: SubscriptionTier.JSON,
    seatCount: t.number,
  }),
]);

export type Product = t.TypeOf<typeof Product>;

export const OrderLineItem = t.type({
  product: Product,
  quantity: t.number,
});

export type OrderLineItem = t.TypeOf<typeof OrderLineItem>;

export const Order = t.type(
  {
    _id: OrderId,
    type: OrderTypes,
    createdAt: DateFromISOString,
    status: OrderStatus,
    subscriptionId: SubscriptionId,
    discountPercent: t.number,
    meroCompany: BillingCompanyId.JSON,
    /**
     * Order value before discount or VAT applied
     */
    subtotal: optionull(t.number),
    /**
     * Order final value (to be paid by user), with discount and VAT applied
     */
    total: t.number,
    totalRON: optionull(MeroMoney.json(MeroUnits.RON.code)),
    /**
     * Discount value when applied to total (includes VAT)
     */
    subtotalDiscount: optionull(t.number),
    tracking: Tracking,
    ronEurExchangeRate: t.number,
    currency: Currency,
    items: t.array(OrderLineItem),
  },
  'Order',
);

export type Order = t.TypeOf<typeof Order>;
