import { ADMIN_NAMESPACE } from '../namespaces';
import { IErrorTable } from 'exceptional.js';

export const TABLE: IErrorTable = {
  namespace: ADMIN_NAMESPACE,
  locale: 'ro',
  errors: {
    0: 'S-a intamplat ceva neprevazut',
    1: 'Emailul acesta (${email}) este deja inresgistrat.',
    2: 'Ne pare rau, dar parolele nu corespund.',
    3: 'Nu am putut crea adminul.',
    4: 'Nu am gasit niciun cont cu aces email (${email}) inresgistrat.',
    5: 'Ne pare rau, dar datele introduse sunt gresite.',
    6: 'Nu ai permisiuni pentru a accesa aceasta resursa.',
    7: 'Abonamentul nu a fost achitat, pagina nu poate fi modificată',
  },
};
