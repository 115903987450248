/**
 * Currently account and page is one thing
 */
export enum AccountPermission {
  /**
   * Manage account subscription (edit billing details, pay online, ...)
   */
  manageSubscription = 'manageSubscription',
  /**
   * Can add/transfer account (page) ownership, manage permissions or delete account (page)
   */
  manageOwnership = 'manageOwnership',
  /**
   * Can view own page permissions
   */
  readOwnPermissions = 'readOwnPermissions',
  /**
   * Can manage page permissions (all but ownership, see manageOwnership), add/delete members, change roles
   */
  managePermissions = 'managePermissions',
}

export enum PagePermission {
  /**
   * Manage page details (name, description, address, contact phone number and profile photo)
   */
  manageDetails = 'manageDetails',
  /**
   * Manage page gallery
   */
  manageGallery = 'manageGallery',
  /**
   * Manage page services
   */
  manageServices = 'manageServices',
  /**
   * Manage page work schedule
   */
  manageWorkingHours = 'manageWorkingHours',
  /**
   * Manage page online payments configuration
   */
  manageOnlinePayments = 'manageOnlinePayments',
  /**
   * Read page billing details records (used for mero and user payments)
   */
  readBillingDetails = 'readBillingDetails',
  /**
   * Manage page billing details records (used for mero and user payments)
   */
  manageBillingDetails = 'manageBillingDetails',
  /**
   * Read page notification settings
   */
  readNotificationSettings = 'readNotificationSettings',
  /**
   * Manage page notification settings
   */
  manageNotificationSettings = 'manageNotificationSettings',
  /**
   * Manage page giftcards
   */
  manageGiftcards = 'manageGiftcards',
  /**
   * Manage page discounts
   */
  manageDiscounts = 'manageDiscounts',
  /**
   * Manage page checkout settings
   */
  manageCheckout = 'manageCheckout',
  /**
   * User can send SMS notifications to page clients or workers via MERO SMS Android app
   */
  sendSMSNotifications = 'sendSMSNotifications',
  /**
   * User can activate/deactivate boost
   */
  manageBoost = 'manageBoost',
  /**
   * User can manage memberships
   */
  manageMemberships = 'manageMemberships',
}

export enum BookingsPermission {
  /**
   * Read own worker calendar entries
   */
  readOwn = 'readOwn',
  /**
   * Read own calendar entries details
   */
  readOwnDetails = 'readOwnDetails',
  /**
   * Mark own appointments as no-show (even if cannot writeOwn)
   */
  markOwnNoShow = 'markOwnNoShow',
  /**
   * Edit own worker calendar entries (including markOwnNoShow)
   */
  writeOwn = 'writeOwn',
  /**
   * Create/edit/delete own blocked time slots
   */
  writeOwnBlockedTime = 'writeOwnBlockedTime',
  /**
   * Read and accept/reject own profile booking requests
   */
  manageOwnBookingRequests = 'manageOwnBookingRequests',

  /**
   * Read all workers calendar entries
   */
  readAll = 'readAll',
  /**
   * Read all calendar entries details
   */
  readAllDetails = 'readAllDetails',
  /**
   * Mark all appointments as no-show (even if cannot writeAll)
   */
  markAllNoShow = 'markAllNoShow',
  /**
   * Edit all workers calendar entries (including markAllNoShow)
   */
  writeAll = 'writeAll',
  /**
   * Create/edit/delete blocked time slots for any PRO
   */
  writeAllBlockedTime = 'writeAllBlockedTime',
  /**
   * Read and accept/reject booking requests for any PRO
   */
  manageAllBookingRequests = 'manageAllBookingRequests',
}

export enum CheckoutPermission {
  /**
   * Can read all checkout info
   */
  readAll = 'readAll',
  /**
   * Can write any checkout info
   */
  writeAll = 'writeAll',
  /**
   * User is allowed to download receipts
   */
  isReceiptPrinter = 'isReceiptPrinter',
}

export enum ClientsPermission {
  /**
   * Search clients starting from 3 symbols
   */
  limitedSearch = 'limitedSearch',
  /**
   * Search clients, results will be returned starting from 1 symbol
   */
  search = 'search',
  /**
   * List all clients (search from 0 symbols)
   */
  listAll = 'listAll',
  /**
   * Read client details
   */
  viewDetails = 'viewDetails',
  /**
   * View list of own profile pending client invites
   */
  readOwnInvites = 'readOwnInvites',
  /**
   * Accept or reject own profile pending clients invitations
   */
  writeOwnInvites = 'writeOwnInvites',
  /**
   * View list of all pending client invites
   */
  readAllInvites = 'readAllInvites',
  /**
   * Can accept or reject all pending clients invitations
   */
  writeAllInvites = 'writeAllInvites',
  /**
   * Can accept boost client permission
   */
  acceptBoostClientCommission = 'acceptBoostClientCommission',
  /**
   * Can claim boost client
   */
  requestBoostClientClaim = 'requestBoostClientClaim',
  /**
   * Manage all clients (edit details, add notes, delete, ...)
   */
  manageAll = 'manageAll',
  /**
   * Can add feedback for a client
   */
  addClientFeedback = 'addClientFeedback',
}

export enum ProProfilePermission {
  /**
   * Edit own own worker profile details (name, phone number)
   */
  editOwnDetails = 'editOwnDetails',
  /**
   * Edit own PRO profile (working schedule, customize services, calendar settings and profile details)
   */
  editOwn = 'editOwn',
  /**
   * Delete own PRO profile
   */
  deleteOwn = 'deleteOwn',
  /**
   * Can list all profiles (firstname, lastname, phone)
   */
  listAll = 'listAll',
  /**
   * Manage all PRO profiles (edit, delete, change working schedule, customize services ...)
   */
  manageAll = 'manageAll',
}

export enum NotificationsPermission {
  /**
   * Receive "Appointment Made" notifications for ALL workers
   */
  receiveAllProsClientAppointmentMade = 'receiveAllProsClientAppointmentMade',
  /**
   * Receive "Appointment Canceled" notifications for ALL workers
   */
  receiveAllProsAppointmentCanceledByClient = 'receiveAllProsAppointmentCanceledByClient',
  /**
   * Receive "Payment Made" notifications for ALL workers
   */
  receiveAllProsClientPaymentMade = 'receiveAllProsClientPaymentMade',
  /**
   * Send own manual sms
   */
  canSendOwnManualSMS = 'canSendOwnManualSMS',
  /**
   * Send all manual sms
   */
  sendAllManualSMS = 'sendAllManualSMS',
  /**
   * Customize notification template
   */
  customizeNotificationTemplates = 'customizeNotificationTemplates',
  /**
   * Receive notifications from appointment actions on other workers calendars
   */
  receiveOtherProsAppointmentAction = 'receiveOtherProsAppointmentAction',
}

export enum StatisticsPermission {
  /**
   * Can view statistics related only to own (pro) activity
   */
  readOwn = 'readOwn',
  /**
   * Can view all read statistics
   */
  readAll = 'readAll',
}

export enum ReviewsPermission {
  /**
   * Read own reviews
   */
  readOwn = 'readOwn',
  /**
   * Can read all reviews
   */
  readAll = 'readAll',
  /**
   * Can manage all reviews
   */
  manageAll = 'manageAll',

  /**
   * Can manage settings
   */
  manageSettings = 'manageSettings',
}

export enum MarketingPermission {
  /**
   * Manage all marketing campaigns and settings
   */
  manageAll = 'manageAll',
}

export enum ServicesPermission {
  /**
   * Can read all page services
   */
  readAll = 'readAll',
}

export enum ProductsPermission {
  /**
   * Can manage all products, brand, categories
   */
  manageAll = 'manageAll',
}

export enum OwnNotificationsSettingsPermission {
  /**
   * Can manage all notification settings for own user
   */
  manageAll = 'manageAll',

  /**
   * Can manage own appointments notifications settings for own user
   */
  manageOwnAppointments = 'manageOwnAppointments',

  /**
   * Can manage other pro(s) appointments notifications settings for own user
   */
  manageOthersAppointments = 'manageOthersAppointments',

  /**
   * Can manage sms reminders settings for own user
   */
  manageSmsReminder = 'manageSmsReminder',

  /**
   * Can manage own waiting list notifications settings for own user
   */
  manageOwnWaitingList = 'manageOwnWaitingList',

  /**
   * Can manage other pro(s) waiting list notifications settings for own user
   */
  manageOthersWaitingList = 'manageOthersWaitingList',

  /**
   * Can manage fulfill requests notifications settings for own user
   */
  manageFulfillRequest = 'manageFulfillRequest',
}

export type AccountPermissions = Readonly<{ [K in AccountPermission]: boolean }>;
export type PagePermissions = Readonly<{ [K in PagePermission]: boolean }>;
export type BookingsPermissions = Readonly<{ [K in BookingsPermission]: boolean }>;
export type CheckoutPermissions = Readonly<{ [K in CheckoutPermission]: boolean }>;
export type ClientsPermissions = Readonly<{ [K in ClientsPermission]: boolean }>;
export type ProProfilePermissions = Readonly<{ [K in ProProfilePermission]: boolean }>;
export type NotificationsPermissions = Readonly<{ [K in NotificationsPermission]: boolean }>;
export type StatisticsPermissions = Readonly<{ [K in StatisticsPermission]: boolean }>;
export type ReviewsPermissions = Readonly<{ [K in ReviewsPermission]: boolean }>;
export type MarketingPermissions = Readonly<{ [K in MarketingPermission]: boolean }>;
export type ServicesPermissions = Readonly<{ [K in ServicesPermission]: boolean }>;
export type ProductsPermissions = Readonly<{ [K in ProductsPermission]: boolean }>;
export type OwnNotificationsSettingsPermissions = Readonly<{ [K in OwnNotificationsSettingsPermission]: boolean }>;

export type MeroPermissions = Readonly<{
  account: AccountPermissions;
  page: PagePermissions;
  bookings: BookingsPermissions;
  checkout: CheckoutPermissions;
  clients: ClientsPermissions;
  proProfile: ProProfilePermissions;
  notifications: NotificationsPermissions;
  statistics: StatisticsPermissions;
  reviews: ReviewsPermissions;
  marketing: MarketingPermissions;
  services: ServicesPermissions;
  products: ProductsPermissions;
  ownNotificationsSettings: OwnNotificationsSettingsPermissions;
}>;

export const NO_PERMISSIONS: MeroPermissions = {
  account: {
    manageSubscription: false,
    manageOwnership: false,
    readOwnPermissions: false,
    managePermissions: false,
  },
  page: {
    manageDetails: false,
    manageGallery: false,
    manageServices: false,
    manageWorkingHours: false,
    manageOnlinePayments: false,
    readBillingDetails: false,
    manageBillingDetails: false,
    readNotificationSettings: false,
    manageNotificationSettings: false,
    manageGiftcards: false,
    manageDiscounts: false,
    manageCheckout: false,
    sendSMSNotifications: false,
    manageBoost: false,
    manageMemberships: false,
  },
  bookings: {
    readOwn: false,
    readOwnDetails: false,
    markOwnNoShow: false,
    writeOwn: false,
    writeOwnBlockedTime: false,
    manageOwnBookingRequests: false,
    readAll: false,
    readAllDetails: false,
    markAllNoShow: false,
    writeAll: false,
    writeAllBlockedTime: false,
    manageAllBookingRequests: false,
  },
  checkout: {
    readAll: false,
    writeAll: false,
    isReceiptPrinter: false,
  },
  clients: {
    limitedSearch: false,
    search: false,
    listAll: false,
    viewDetails: false,
    readOwnInvites: false,
    writeOwnInvites: false,
    readAllInvites: false,
    writeAllInvites: false,
    acceptBoostClientCommission: false,
    requestBoostClientClaim: false,
    addClientFeedback: false,
    manageAll: false,
  },
  proProfile: {
    editOwnDetails: false,
    editOwn: false,
    deleteOwn: false,
    listAll: false,
    manageAll: false,
  },
  notifications: {
    receiveAllProsClientAppointmentMade: false,
    receiveAllProsAppointmentCanceledByClient: false,
    receiveAllProsClientPaymentMade: false,
    canSendOwnManualSMS: false,
    sendAllManualSMS: false,
    customizeNotificationTemplates: false,
    receiveOtherProsAppointmentAction: false,
  },
  statistics: {
    readOwn: false,
    readAll: false,
  },
  reviews: {
    readOwn: false,
    readAll: false,
    manageAll: false,
    manageSettings: false,
  },
  marketing: {
    manageAll: false,
  },
  services: {
    readAll: false,
  },
  products: {
    manageAll: false,
  },
  ownNotificationsSettings: {
    manageOwnAppointments: false,
    manageOthersAppointments: false,
    manageFulfillRequest: false,
    manageSmsReminder: false,
    manageOwnWaitingList: false,
    manageOthersWaitingList: false,
    manageAll: false,
  },
};

export const mergeMeroPermissions = (a: MeroPermissions, b: MeroPermissions): MeroPermissions => ({
  account: {
    manageSubscription: a.account.manageSubscription || b.account.manageSubscription,
    manageOwnership: a.account.manageOwnership || b.account.manageOwnership,
    readOwnPermissions: a.account.readOwnPermissions || b.account.readOwnPermissions,
    managePermissions: a.account.managePermissions || b.account.managePermissions,
  },
  page: {
    manageDetails: a.page.manageDetails || b.page.manageDetails,
    manageGallery: a.page.manageGallery || b.page.manageGallery,
    manageServices: a.page.manageServices || b.page.manageServices,
    manageWorkingHours: a.page.manageWorkingHours || b.page.manageWorkingHours,
    manageOnlinePayments: a.page.manageOnlinePayments || b.page.manageOnlinePayments,
    readBillingDetails: a.page.readBillingDetails || b.page.readBillingDetails,
    manageBillingDetails: a.page.manageBillingDetails || b.page.manageBillingDetails,
    readNotificationSettings: a.page.readNotificationSettings || b.page.readNotificationSettings,
    manageNotificationSettings: a.page.manageNotificationSettings || b.page.manageNotificationSettings,
    manageGiftcards: a.page.manageGiftcards || b.page.manageGiftcards,
    manageDiscounts: a.page.manageDiscounts || b.page.manageDiscounts,
    manageCheckout: a.page.manageCheckout || b.page.manageCheckout,
    sendSMSNotifications: a.page.sendSMSNotifications || b.page.sendSMSNotifications,
    manageBoost: a.page.manageBoost || b.page.manageBoost,
    manageMemberships: a.page.manageMemberships || b.page.manageMemberships,
  },
  bookings: {
    readOwn: a.bookings.readOwn || b.bookings.readOwn,
    readOwnDetails: a.bookings.readOwnDetails || b.bookings.readOwnDetails,
    markOwnNoShow: a.bookings.markOwnNoShow || b.bookings.markOwnNoShow,
    writeOwn: a.bookings.writeOwn || b.bookings.writeOwn,
    writeOwnBlockedTime: a.bookings.writeOwnBlockedTime || b.bookings.writeOwnBlockedTime,
    manageOwnBookingRequests: a.bookings.manageOwnBookingRequests || b.bookings.manageOwnBookingRequests,
    readAll: a.bookings.readAll || b.bookings.readAll,
    readAllDetails: a.bookings.readAllDetails || b.bookings.readAllDetails,
    markAllNoShow: a.bookings.markAllNoShow || b.bookings.markAllNoShow,
    writeAll: a.bookings.writeAll || b.bookings.writeAll,
    writeAllBlockedTime: a.bookings.writeAllBlockedTime || b.bookings.writeAllBlockedTime,
    manageAllBookingRequests: a.bookings.manageAllBookingRequests || b.bookings.manageAllBookingRequests,
  },
  checkout: {
    readAll: a.checkout.readAll || b.checkout.readAll,
    writeAll: a.checkout.writeAll || b.checkout.writeAll,
    isReceiptPrinter: a.checkout.isReceiptPrinter || b.checkout.isReceiptPrinter,
  },
  clients: {
    limitedSearch: a.clients.limitedSearch || b.clients.limitedSearch,
    search: a.clients.search || b.clients.search,
    listAll: a.clients.listAll || b.clients.listAll,
    viewDetails: a.clients.viewDetails || b.clients.viewDetails,
    readOwnInvites: a.clients.readOwnInvites || b.clients.readOwnInvites,
    writeOwnInvites: a.clients.writeOwnInvites || b.clients.writeOwnInvites,
    readAllInvites: a.clients.readAllInvites || b.clients.readAllInvites,
    writeAllInvites: a.clients.writeAllInvites || b.clients.writeAllInvites,
    acceptBoostClientCommission: a.clients.acceptBoostClientCommission || b.clients.acceptBoostClientCommission,
    requestBoostClientClaim: a.clients.requestBoostClientClaim || b.clients.requestBoostClientClaim,
    addClientFeedback: a.clients.addClientFeedback || b.clients.addClientFeedback,
    manageAll: a.clients.manageAll || b.clients.manageAll,
  },
  proProfile: {
    editOwnDetails: a.proProfile.editOwnDetails || b.proProfile.editOwnDetails,
    editOwn: a.proProfile.editOwn || b.proProfile.editOwn,
    deleteOwn: a.proProfile.deleteOwn || b.proProfile.deleteOwn,
    listAll: a.proProfile.listAll || b.proProfile.listAll,
    manageAll: a.proProfile.manageAll || b.proProfile.manageAll,
  },
  notifications: {
    receiveAllProsClientAppointmentMade:
      a.notifications.receiveAllProsClientAppointmentMade || b.notifications.receiveAllProsClientAppointmentMade,
    receiveAllProsAppointmentCanceledByClient:
      a.notifications.receiveAllProsAppointmentCanceledByClient ||
      b.notifications.receiveAllProsAppointmentCanceledByClient,
    receiveAllProsClientPaymentMade:
      a.notifications.receiveAllProsClientPaymentMade || b.notifications.receiveAllProsClientPaymentMade,
    canSendOwnManualSMS: a.notifications.canSendOwnManualSMS || b.notifications.canSendOwnManualSMS,
    sendAllManualSMS: a.notifications.sendAllManualSMS || b.notifications.sendAllManualSMS,
    customizeNotificationTemplates:
      a.notifications.customizeNotificationTemplates || b.notifications.customizeNotificationTemplates,
    receiveOtherProsAppointmentAction:
      a.notifications.receiveOtherProsAppointmentAction || b.notifications.receiveOtherProsAppointmentAction,
  },
  statistics: {
    readOwn: a.statistics.readOwn || b.statistics.readOwn,
    readAll: a.statistics.readAll || b.statistics.readAll,
  },
  reviews: {
    readOwn: a.reviews.readOwn || b.reviews.readOwn,
    readAll: a.reviews.readAll || b.reviews.readAll,
    manageAll: a.reviews.manageAll || b.reviews.manageAll,
    manageSettings: a.reviews.manageSettings || b.reviews.manageSettings,
  },
  marketing: {
    manageAll: a.marketing.manageAll || b.marketing.manageAll,
  },
  services: {
    readAll: a.services.readAll || b.services.readAll,
  },
  products: {
    manageAll: a.products.manageAll || b.products.manageAll,
  },
  ownNotificationsSettings: {
    manageAll: a.ownNotificationsSettings.manageAll || b.ownNotificationsSettings.manageAll,
    manageOwnAppointments:
      a.ownNotificationsSettings.manageOwnAppointments || b.ownNotificationsSettings.manageOwnAppointments,
    manageOthersAppointments:
      a.ownNotificationsSettings.manageOthersAppointments || b.ownNotificationsSettings.manageOthersAppointments,
    manageFulfillRequest:
      a.ownNotificationsSettings.manageFulfillRequest || b.ownNotificationsSettings.manageFulfillRequest,
    manageSmsReminder: a.ownNotificationsSettings.manageSmsReminder || b.ownNotificationsSettings.manageSmsReminder,
    manageOwnWaitingList:
      a.ownNotificationsSettings.manageOwnWaitingList || b.ownNotificationsSettings.manageOwnWaitingList,
    manageOthersWaitingList:
      a.ownNotificationsSettings.manageOthersWaitingList || b.ownNotificationsSettings.manageOthersWaitingList,
  },
});
