import { CheckoutTotals } from '../checkoutTotals';
import { CheckoutTransactionPayment } from '../checkoutTransactionPayment';
import { CompanyVatStatus } from '../companies';
import { Amount } from './amount';
import { Any } from './any';
import { Booking } from './booking';
import { Membership } from './membership';
import { MembershipInstallment } from './membershipInstallment';
import { Product } from './product';
import { Service } from './service';
import { MeroUnits, ScaledNumber } from '@mero/shared-sdk';

export * from './any';
export * from './amount';
export * from './booking';
export * from './membership';
export * from './membershipInstallment';
export * from './product';
export * from './service';

export const isAmount = <Unit extends MeroUnits.Any>(item: Any<Unit>): item is Amount<Unit> => {
  return item.type === 'Amount';
};

export const isBooking = <Unit extends MeroUnits.Any>(item: Any<Unit>): item is Booking<Unit> => {
  return item.type === 'Booking';
};

export const isMembership = <Unit extends MeroUnits.Any>(item: Any<Unit>): item is Membership<Unit> => {
  return item.type === 'Membership';
};

export const isMembershipInstallment = <Unit extends MeroUnits.Any>(
  item: Any<Unit>,
): item is MembershipInstallment<Unit> => {
  return item.type === 'MembershipInstallment';
};

export const isProduct = <Unit extends MeroUnits.Any>(item: Any<Unit>): item is Product<Unit> => {
  return item.type === 'Product';
};

export const isService = <Unit extends MeroUnits.Any>(item: Any<Unit>): item is Service<Unit> => {
  return item.type === 'Service';
};

/**
 * Return {@link CheckoutTotals} value for given item
 */
export const getTotals = <Unit extends MeroUnits.Any>(
  item: Any<Unit>,
  unit: Unit,
  payments: CheckoutTransactionPayment.Any<ScaledNumber, Unit>[],
  companyVat: CompanyVatStatus.Any<ScaledNumber>,
  decimals: number,
  includeItemsPaidWithMembership = false,
): CheckoutTotals<ScaledNumber, Unit> => {
  switch (item.type) {
    case 'Service': {
      return Service.getTotals(item, unit, payments, companyVat, decimals, includeItemsPaidWithMembership);
    }
    case 'Booking': {
      return Booking.getTotals(item, unit, payments, companyVat, decimals, includeItemsPaidWithMembership);
    }
    case 'Amount': {
      return Amount.getTotals(item, unit, companyVat, decimals);
    }
    case 'Product': {
      return Product.getTotals(item, unit, payments, companyVat, decimals, includeItemsPaidWithMembership);
    }
    case 'Membership': {
      return Membership.getTotals(item, unit, companyVat, decimals);
    }
    case 'MembershipInstallment': {
      return MembershipInstallment.getTotals(item, unit, companyVat, decimals);
    }
  }
};

/**
 * Check if given item is fully or partially paid with a membership
 */
export const isPaidWithMembership = <Unit extends MeroUnits.Any>(
  item: Any<Unit>,
  unit: Unit,
  payments: CheckoutTransactionPayment.Any<ScaledNumber, Unit>[],
  companyVat: CompanyVatStatus.Any<ScaledNumber>,
  decimals: number,
):
  | {
      readonly isPaidWithMembership: true;
      readonly totals: CheckoutTotals<ScaledNumber, Unit>;
    }
  | {
      readonly isPaidWithMembership: false;
    } => {
  switch (item.type) {
    case 'Service': {
      return Service.isPaidWithMembership(item, unit, payments, companyVat, decimals);
    }
    case 'Booking': {
      return Booking.isPaidWithMembership(item, unit, payments, companyVat, decimals);
    }
    case 'Amount': {
      return { isPaidWithMembership: false };
    }
    case 'Product': {
      return Product.isPaidWithMembership(item, unit, payments, companyVat, decimals);
    }
    case 'Membership': {
      return { isPaidWithMembership: false };
    }
    case 'MembershipInstallment': {
      return { isPaidWithMembership: false };
    }
  }
};
