import { StyleSheet } from 'react-native';

import { colors } from '../../styles';
import { WHITE } from '../../styles/colors';

export const MEDIUM = 1090;

export const styles = StyleSheet.create({
  container: {
    width: '100%',
    backgroundColor: WHITE,
    borderRadius: 5,
    shadowColor: '#8898AA',
    shadowOpacity: 0.15,
    shadowRadius: 16,
    elevation: 16,
  },
  review: {
    marginHorizontal: 16,
    marginBottom: 16,
    padding: 16,
    borderRadius: 8,
    // boxShadow: '0px 0px 32px rgba(136, 152, 170, 0.15)',
  },
  profileImage: {
    borderRadius: 30,
    borderWidth: 1,
    borderColor: colors.ATHENS_GRAY,
  },
  padding16: {
    paddingHorizontal: 16,
  },
  row: {
    flexDirection: 'row',
  },
  gallery: {
    width: '100%',
    padding: 8,
    paddingRight: 0,
  },
  profileExtrasContainer: {
    position: 'absolute',
    left: 16,
    bottom: 16,
    display: 'flex',
    flexDirection: 'row',
  },
  promotedLabelContainer: {
    backgroundColor: '#F1EAFF',
    borderRadius: 4,
    marginRight: 8,
    paddingLeft: 2,
    paddingRight: 8,
    flexDirection: 'row',
    alignItems: 'center',
  },
  promotedLabel: {
    paddingLeft: 2,
    color: colors.ELECTRIC_VIOLET,
    fontFamily: 'open-sans-semibold',
  },
  giftCardIconBg: {
    width: 24,
    height: 24,
    backgroundColor: '#EAF4FF',
    borderRadius: 4,
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    marginRight: 8,
  },
  promoIconBg: {
    width: 24,
    height: 24,
    backgroundColor: colors.RADICAL_RED,
    borderRadius: 4,
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    marginRight: 8,
  },
  favoriteButton: {
    position: 'absolute',
    width: 32,
    height: 32,
    right: 16,
    top: 16,
    // backgroundColor: 'rgba(0, 0, 0, 0.6)',
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: 22,
  },
});
