import { BusinessCategoryId } from './businessCategoryId';
import { price, Price } from './price';
import { SavedService } from './saved-service';
import { customizeService } from './service';
import { HasServiceId } from './service-id';
import { ServiceGroupId } from './serviceGroupId';
import * as t from 'io-ts';

export const MergedService = t.intersection(
  [
    HasServiceId,
    t.type({
      categoryId: BusinessCategoryId,
      name: t.string,
      description: t.string,
      durationInMinutes: t.type({
        from: t.number,
        to: t.number,
      }),
      default: t.boolean,
      private: t.boolean,
      availableDays: t.number,
      automaticApproval: t.boolean,
      price: Price,
      color: t.string,
    }),
    t.partial({
      groupIds: t.union([t.array(ServiceGroupId), t.null]),
    }),
  ],
  'MergedService',
);
export interface MergedService extends t.TypeOf<typeof MergedService> {}

export const mergedService = {
  fromSavedService: (service: SavedService): MergedService => {
    return {
      ...service,
      durationInMinutes: {
        from: service.durationInMinutes,
        to: service.durationInMinutes,
      },
    };
  },
};

/**
 * Merge service to be displayed as one to user
 */
export const mergeServices = (pageService: SavedService, workerServices: SavedService[]): MergedService | undefined => {
  const services = workerServices.map((workerService) => customizeService(pageService, workerService));

  if (workerServices.length === 0) {
    // Cannot merge services if there are no worker services
    return undefined;
  }

  const [first, ...rest] = services;

  return {
    ...pageService,
    price: rest.reduce((a, b) => price.merge(a, b.price), first.price),
    durationInMinutes: {
      from: Math.min(first.durationInMinutes, ...rest.map((sv) => sv.durationInMinutes)),
      to: Math.max(first.durationInMinutes, ...rest.map((sv) => sv.durationInMinutes)),
    },
  };
};
