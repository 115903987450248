import { GroupWithWorkerServices } from './group-with-worker-services';
import { WorkerService } from './worker-service';
import * as t from 'io-ts';

export const GroupedWorkerServices = t.type(
  {
    grouped: t.array(GroupWithWorkerServices),
    others: t.array(WorkerService),
  },
  'GroupedWorkerServices',
);
export interface GroupedWorkerServices extends t.TypeOf<typeof GroupedWorkerServices> {}
