import { StyleSheet } from 'react-native';

/**
 * Define toast styles.
 */
export const styles = StyleSheet.create({
  toastContainer: {
    borderRadius: 8,
    marginVertical: 4,
    padding: 16,
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    maxWidth: 343,
    alignSelf: 'center',
  },
  dismissContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
  },
});
