import { JSONable, Option } from '@mero/shared-sdk';
import * as t from 'io-ts';

export type Weekly = {
  readonly type: 'Weekly';
  readonly day: Option<'monday' | 'tuesday' | 'wednesday' | 'thursday' | 'friday' | 'saturday' | 'sunday'>;
};

const JSON: t.Type<Weekly, JSONable> = t.type(
  {
    type: t.literal('Weekly'),
    day: Option.json(
      t.union(
        [
          t.literal('monday'),
          t.literal('tuesday'),
          t.literal('wednesday'),
          t.literal('thursday'),
          t.literal('friday'),
          t.literal('saturday'),
          t.literal('sunday'),
        ],
        'Day',
      ),
    ),
  },
  'Weekly',
);

export const Weekly = {
  JSON,
};
