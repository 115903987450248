import * as React from 'react';
import Svg, { G, Line, Path, Rect } from 'react-native-svg';

type Props = {
  onPress?: () => void;
};

const GiftCard = ({ onPress }: Props) => (
  <Svg width="120" height="120" viewBox="0 0 120 120" onPress={onPress}>
    <G id="Group_6811" data-name="Group 6811" transform="translate(-123.668 -123.681)">
      <G id="Orion_credit-card" transform="translate(136.668 142.681)">
        <Path
          id="layer2"
          d="M92.111,17.506A4.506,4.506,0,0,0,87.606,13H6.506A4.506,4.506,0,0,0,2,17.506V28.019H92.111Z"
          transform="translate(0 0)"
          fill="#a983be"
        />
        <Path
          id="layer1"
          d="M2,23V60.546a4.506,4.506,0,0,0,4.506,4.506h81.1a4.506,4.506,0,0,0,4.506-4.506V23"
          transform="translate(0 5.019)"
          fill="#29489e"
        />
        <Path
          id="opacity"
          d="M20,13,77.071,70.071h1.5a4.506,4.506,0,0,0,4.506-4.506v-1.5L32.015,13ZM83.078,52.2V29.671L66.257,13H43.429Z"
          transform="translate(9.033 0)"
          fill="#fff"
          opacity="0.2"
        />
        <Path
          id="stroke"
          d="M92.111,17.506A4.506,4.506,0,0,0,87.606,13H6.506A4.506,4.506,0,0,0,2,17.506V28.019H92.111ZM2,28.019V65.565a4.506,4.506,0,0,0,4.506,4.506h81.1a4.506,4.506,0,0,0,4.506-4.506V28.019"
          transform="translate(0 0)"
          fill="none"
          stroke="#172b4d"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="2"
        />
        <G id="Component_67_123" data-name="Component 67 – 123" transform="translate(13.186 36.035)">
          <G id="Group_1" data-name="Group 1">
            <Path
              id="Path_1"
              data-name="Path 1"
              d="M312.014.835a3.225,3.225,0,0,0-4.694.385,3.607,3.607,0,0,0,.5,4.908,3.225,3.225,0,0,0,4.694-.385A3.607,3.607,0,0,0,312.014.835ZM311.1,6.645c-.948.385-2.253-.707-2.9-2.467S307.779.66,308.74.31c.98-.357,2.243.713,2.893,2.473S312.051,6.26,311.1,6.645Z"
              transform="translate(-284.658 0)"
              fill="#ffe1e1"
            />
            <Path
              id="Path_2"
              data-name="Path 2"
              d="M224.13,6.539c-.424,0-.771-.393-1.047-.849a1.717,1.717,0,0,0-1.606-.717,2.446,2.446,0,0,0,2.662-2.419c0-1.624-1.269-2.263-2.7-2.263h-2.787a1.044,1.044,0,0,1,.672,1.019V6.191a1.044,1.044,0,0,1-.672,1.019h2.717a1.044,1.044,0,0,1-.672-1.019V5.043h.049c.682,0,1.031,1.05,1.14,1.257a1.516,1.516,0,0,0,1.368.968,1.558,1.558,0,0,0,1.454-1.061A.557.557,0,0,1,224.13,6.539Zm-3.288-1.675H220.7V.469h.169a2.059,2.059,0,0,1,1.893,2.2A2.114,2.114,0,0,1,220.843,4.863Z"
              transform="translate(-203.018 -0.268)"
              fill="#ffe1e1"
            />
            <Path
              id="Path_3"
              data-name="Path 3"
              d="M126.774,7.2a1.184,1.184,0,0,0,.823-1.186V1.467a1.184,1.184,0,0,0-.823-1.186h5.565V2.543c0-.7-.417-2.082-3.368-2.082V3.544c1.772,0,1.772-1.089,1.772-1.089V4.92s0-1.2-1.772-1.2v2.4a.845.845,0,0,0,.873.9,2.381,2.381,0,0,0,2.5-2.217V7.2h-5.565Z"
              transform="translate(-117.71 -0.26)"
              fill="#ffe1e1"
            />
            <Path
              id="Path_4"
              data-name="Path 4"
              d="M6.613,5.963A1.212,1.212,0,0,1,5.784,7.2H8.817a1.212,1.212,0,0,1-.829-1.239V1.52A1.212,1.212,0,0,1,8.817.281h-2.2L4.635,4.557,2.22.281H.242a1.926,1.926,0,0,1,.98,1.7V5.64A1.522,1.522,0,0,1,0,7.2H2.6a1.525,1.525,0,0,1-1.2-1.562V1.663L4.027,6.278,6.615.692Z"
              transform="translate(0 -0.26)"
              fill="#ffe1e1"
            />
          </G>
        </G>
        <Line
          id="Line_693"
          data-name="Line 693"
          x2="17.348"
          transform="translate(14.484 51.052)"
          fill="none"
          stroke="#ffe1e1"
          strokeLinecap="round"
          strokeWidth="1.5"
        />
        <Line
          id="Line_694"
          data-name="Line 694"
          x2="9.348"
          transform="translate(14.484 56.052)"
          fill="none"
          stroke="#ffe1e1"
          strokeLinecap="round"
          strokeWidth="1.2"
        />
      </G>
      <Rect
        id="Rectangle_2526"
        data-name="Rectangle 2526"
        width="120"
        height="120"
        transform="translate(123.668 123.681)"
        fill="none"
      />
      <G id="Orion_giftbox" transform="translate(193.23 127.523)">
        <Path
          id="layer1-2"
          data-name="layer1"
          d="M20.107,22H4v9.664H20.107Z"
          transform="translate(0 -3.894)"
          fill="#ffe1e1"
        />
        <Path
          id="layer2-2"
          data-name="layer2"
          d="M24,22H36.885v9.664H24Z"
          transform="translate(-3.893 -3.894)"
          fill="#a983be"
        />
        <Path
          id="layer1-3"
          data-name="layer1"
          d="M33.771,22v9.664H49.878V22ZM8,31.664H20.885v22.55H8Z"
          transform="translate(-0.779 -3.894)"
          fill="#ffe1e1"
        />
        <Path
          id="layer2-3"
          data-name="layer2"
          d="M24,34H36.885V56.55H24Z"
          transform="translate(-3.893 -6.23)"
          fill="#a983be"
        />
        <Path
          id="layer1-4"
          data-name="layer1"
          d="M40,34H52.885V56.55H40Z"
          transform="translate(-7.008 -6.23)"
          fill="#ffe1e1"
        />
        <Path
          id="opacity-2"
          data-name="opacity"
          d="M4,22H49.1v3.221H4Z"
          transform="translate(0 -3.894)"
          fill="#fff"
          opacity="0.25"
        />
        <Path
          id="opacity-3"
          data-name="opacity"
          d="M8,34H46.656v5.637H8Z"
          transform="translate(-0.779 -6.23)"
          opacity="0.15"
        />
        <Path
          id="stroke-2"
          data-name="stroke"
          d="M45.878,27.77V50.32H7.221V27.77M4,18.106H49.1V27.77H4Zm16.107,0V50.32M32.992,18.106V50.32M21.879,4.415A5.96,5.96,0,0,0,16.885,2a6.443,6.443,0,0,0-4.268,11.275C16,16.254,26.55,18.106,26.55,18.106,26.55,13.274,23.811,6.59,21.879,4.415Zm9.342,0A5.96,5.96,0,0,1,36.214,2a6.443,6.443,0,0,1,4.268,11.275C37.1,16.254,26.55,18.106,26.55,18.106c0-4.832,2.738-11.516,4.671-13.691Z"
          transform="translate(0 0)"
          fill="none"
          stroke="#06115e"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="2"
        />
      </G>
    </G>
  </Svg>
);

export default React.memo(GiftCard);
