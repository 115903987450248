import { MeroUnits, Money } from '@mero/shared-sdk';

export type Card<Num, Unit extends MeroUnits.Any> = {
  readonly type: 'Card';
  readonly total: Money<Num, Unit>;
};

export const card = <Num, Unit extends MeroUnits.Any>(total: Money<Num, Unit>): Card<Num, Unit> => {
  return {
    type: 'Card',
    total: total,
  };
};
