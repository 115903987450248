import * as t from 'io-ts';

export const ResponsiveImage = t.type(
  {
    _id: t.string,
    croppedSmall: t.string,
    croppedLarge: t.string,
    small: t.string,
    medium: t.string,
    large: t.string,
    original: t.string,
  },
  'ResponsiveImage',
);

export type ResponsiveImage = t.TypeOf<typeof ResponsiveImage>;

export const ResponsiveImagePreview = t.type(
  {
    _id: t.string,
    medium: t.string,
  },
  'ResponsiveImagePreview',
);

export type ResponsiveImagePreview = t.TypeOf<typeof ResponsiveImagePreview>;
