import { Favorite } from './favorite';
import { MarketplaceFavoritesApi } from './marketplaceFavoritesApi';
import { HttpClient, Option, UnknownApiError, unsafeRight } from '@mero/shared-sdk';
import * as t from 'io-ts';

export const marketplaceFavoritesHttpClient = (env: {
  apiBaseUrl: string;
  http: HttpClient;
}): MarketplaceFavoritesApi => {
  const { apiBaseUrl, http } = env;

  const addUserFavoriteDecoder = http.decode.response(UnknownApiError, t.unknown);
  const deleteUserFavoriteDecoder = http.decode.response(UnknownApiError, t.unknown);
  const listUserFavoritesDecoder = http.decode.response(UnknownApiError, t.array(Favorite));
  const isFavoriteDecoder = http.decode.response(
    UnknownApiError,
    t.type({
      isFavorite: t.boolean,
    }),
  );

  return {
    addUserFavorite: async ({ pageId, favoriteStartingPoint, campaignId, campaignSource }) => {
      const requestBody: {
        favoriteStartingPoint: Option<string>;
        campaignId: Option<string>;
        campaignSource: Option<string>;
      } = {
        favoriteStartingPoint: favoriteStartingPoint,
        campaignId: campaignId,
        campaignSource: campaignSource,
      };

      unsafeRight(
        await http.request(
          {
            method: 'POST',
            url: `${apiBaseUrl}/user/favorites/${encodeURIComponent(pageId)}`,
            data: requestBody,
          },
          addUserFavoriteDecoder,
        ),
      );
    },
    removeUserFavorite: async (pageId) => {
      unsafeRight(
        await http.request(
          {
            method: 'DELETE',
            url: `${apiBaseUrl}/user/favorites/${encodeURIComponent(pageId)}`,
          },
          deleteUserFavoriteDecoder,
        ),
      );
    },
    listUserFavorites: async (query) => {
      const favorites = unsafeRight(
        await http.request(
          {
            method: 'GET',
            url: `${apiBaseUrl}/user/favorites`,
            params: query,
          },
          listUserFavoritesDecoder,
        ),
      );

      return favorites;
    },
    isFavorite: async (pageId) => {
      const response = unsafeRight(
        await http.request(
          {
            method: 'GET',
            url: `${apiBaseUrl}/user/favorites/${encodeURIComponent(pageId)}/is-favorite`,
          },
          isFavoriteDecoder,
        ),
      );

      return response.isFavorite;
    },
  };
};
