export const notImplemented = (): never => {
  throw new Error('Not implemented');
};

export const notImplementedAsync = async (): Promise<never> => {
  throw new Error('not implemented');
};

/**
 * Checks if value is non null or undefined
 */
export const isDefined = <T>(value: T | null | undefined): value is T => value !== null && value !== undefined;

/**
 * Check if given object field is non null or undefined
 */
export const isFieldDefined = <P extends Record<string | number | symbol, unknown>, K extends keyof P>(
  data: P,
  field: K,
): data is P & { [Key in K]-?: P[K] } => {
  return isDefined(data[field]);
};
