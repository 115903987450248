import { CheckoutItemVatStatus } from '../checkoutItemVatStatus';
import { CheckoutTransactionMembershipPreview } from '../checkoutTransactionMembershipPreview';
import { CheckoutTransactionServicePreview } from '../checkoutTransactionServicePreview';
import { MeroUnits, Money, PositiveInt } from '@mero/shared-sdk';

export type MembershipItemEffectivePrice<Num, Unit extends MeroUnits.Any> = {
  /**
   * Service price from membership
   */
  readonly amount: Money<Num, Unit>;
  /**
   * VAT status for the {@link MembershipItemService['amount']}
   */
  readonly vatStatus: CheckoutItemVatStatus.Any<Num>;
};

export type MembershipItemService<Num, Unit extends MeroUnits.Any> = {
  readonly type: 'Service';
  readonly transactionItemId: string;
  readonly service: CheckoutTransactionServicePreview;
  readonly effectivePrice: MembershipItemEffectivePrice<Num, Unit>;
  /**
   * Consumed service sessions quantity >= 1
   */
  readonly quantity: PositiveInt;
};

export type MembershipItemProduct = {
  readonly type: 'Product';
  // FIXME: implement product payment item support
};

export type MembershipItem<Num, Unit extends MeroUnits.Any> = MembershipItemService<Num, Unit> | MembershipItemProduct;

export const MembershipItem = {
  service: <Num, Unit extends MeroUnits.Any>(params: {
    transactionItemId: string;
    service: CheckoutTransactionServicePreview;
    effectivePrice: MembershipItemEffectivePrice<Num, Unit>;
    quantity: PositiveInt;
  }): MembershipItemService<Num, Unit> => ({
    type: 'Service',
    transactionItemId: params.transactionItemId,
    service: params.service,
    quantity: params.quantity,
    effectivePrice: params.effectivePrice,
  }),
  product: (): MembershipItemProduct => {
    return {
      type: 'Product',
    };
  },
};

export type Membership<Num, Unit extends MeroUnits.Any> = {
  readonly type: 'Membership';
  readonly membership: CheckoutTransactionMembershipPreview;
  readonly items: MembershipItem<Num, Unit>[];
};

export const membership = <Num, Unit extends MeroUnits.Any>(params: {
  membership: CheckoutTransactionMembershipPreview;
  items: MembershipItem<Num, Unit>[];
}): Membership<Num, Unit> => {
  return {
    type: 'Membership',
    membership: params.membership,
    items: params.items,
  };
};
