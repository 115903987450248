import * as React from 'react';
import Svg, { Path, Rect, G, Defs, ClipPath } from 'react-native-svg';

import { BLACK } from '../../../styles/colors';

type Props = {
  size?: string | number;
  color?: string;
  disabled?: boolean;
  onPress?: () => void;
};

const Instagram: React.FC<Props> = ({ size = 24, color = BLACK, disabled = false, onPress }) => (
  <Svg width={size} height={size} viewBox="0 0 32 32" disabled={disabled} onPress={onPress}>
    <Defs>
      <ClipPath id="clip-path">
        <Rect width={size} height={size} fill="none" />
      </ClipPath>
    </Defs>
    <G clipPath="url(#clip-path)">
      <G>
        <Path d="M0,0H24V24H0Z" fill="none" />
        <G transform="translate(1 1)">
          <Path
            d="M14.637,2H7.361A5.363,5.363,0,0,0,2,7.35v7.26a5.362,5.362,0,0,0,5.361,5.35h7.276A5.363,5.363,0,0,0,20,14.609V7.35A5.363,5.363,0,0,0,14.637,2Zm3.551,12.61a3.547,3.547,0,0,1-3.551,3.544H7.361A3.547,3.547,0,0,1,3.81,14.61V7.35A3.547,3.547,0,0,1,7.361,3.807h7.276A3.548,3.548,0,0,1,18.188,7.35Zm0,0"
            transform="translate(0 0)"
            fill="none"
            stroke={color}
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeMiterlimit="10"
            strokeWidth="1"
          />
          <Path
            d="M21.182,13.933a4.645,4.645,0,1,0,4.654,4.645A4.645,4.645,0,0,0,21.182,13.933Zm0,7.485a2.838,2.838,0,1,1,2.845-2.838A2.838,2.838,0,0,1,21.182,21.418Zm5.779-7.45a1.116,1.116,0,1,1-1.116-1.113A1.116,1.116,0,0,1,26.961,13.968Zm0,0"
            transform="translate(-10.182 -7.598)"
            fill="none"
            stroke={color}
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeMiterlimit="10"
            strokeWidth="1"
          />
        </G>
      </G>
    </G>
  </Svg>
);

export default React.memo(Instagram);
