import { BusinessCategoryId } from './businessCategoryId';
import { Price } from './price';
import { ServiceGroupId } from './serviceGroupId';
import * as t from 'io-ts';

export const Service = t.intersection(
  [
    t.type({
      categoryId: BusinessCategoryId,
      name: t.string,
      description: t.string,
      durationInMinutes: t.number,
      default: t.boolean,
      private: t.boolean,
      availableDays: t.number,
      automaticApproval: t.boolean,
      price: Price,
      color: t.string,
    }),
    t.partial({
      groupIds: t.union([t.array(ServiceGroupId), t.null]),
      isCustom: t.union([t.boolean, t.null]),
    }),
  ],
  'Service',
);
export interface Service extends t.TypeOf<typeof Service> {}

/**
 * Apply customizable fields to base service
 */
export const customizeService = <S extends Service>(base: S, custom: S): S => {
  if (custom.isCustom) {
    return {
      ...base,
      description: custom.description,
      durationInMinutes: custom.durationInMinutes,
      private: custom.private,
      availableDays: custom.availableDays,
      automaticApproval: custom.automaticApproval,
      price: custom.price,
      color: custom.color,
    };
  } else {
    return base;
  }
};
