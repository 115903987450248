import { AppointmentId } from '../calendar';
import { ServiceId } from '../services';
import { MembershipPurchaseId } from './membershipPurchaseId';
import { PurchasedMembershipHistoryRecordId } from './purchasedMembershipHistoryRecordId';
import {
  AppointmentMembershipPaymentCancelledHistoryType,
  AppointmentPaidUsingMembershipHistoryType,
  MembershipCancelledByProHistoryType,
  MembershipPaymentCancelledByProHistoryType,
  MembershipPaymentCollectedByProHistoryType,
  MembershipPurchasedByClientHistoryType,
  ServicesMembershipPaymentCancelledHistoryType,
  ServicesPaidUsingMembershipHistoryType,
} from './purchasedMembershipHistoryType';
import { OptionalFirstname, OptionalLastname, StrictPhoneNumber, UserId, optionull, JSONable } from '@mero/shared-sdk';
import * as t from 'io-ts';
import { DateFromISOString } from 'io-ts-types';

type ByUser = {
  readonly _id: UserId;
  readonly firstname: OptionalFirstname;
  readonly lastname: OptionalLastname;
  readonly phone: StrictPhoneNumber;
};

const ByUser: t.Type<ByUser, JSONable> = t.type(
  {
    _id: UserId,
    firstname: OptionalFirstname,
    lastname: OptionalLastname,
    phone: StrictPhoneNumber,
  },
  'ByUser',
);

type PurchasedMembershipHistoryBase = {
  readonly _id: PurchasedMembershipHistoryRecordId;
  readonly membershipId: MembershipPurchaseId;
  readonly date: Date;
};

const PurchasedMembershipHistoryBase: t.Type<PurchasedMembershipHistoryBase, JSONable> = t.type(
  {
    _id: PurchasedMembershipHistoryRecordId.JSON,
    membershipId: MembershipPurchaseId.JSON,
    date: DateFromISOString,
  },
  'PurchasedMembershipHistoryBase',
);

export type MembershipPurchasedByClient = PurchasedMembershipHistoryBase & {
  readonly type: MembershipPurchasedByClientHistoryType;
  readonly payload: {
    /**
     * True if the membership was purchased "online" (on marketplace)
     * Sales by pro ar considered "offline"
     */
    readonly online: boolean;
  };
};

const MembershipPurchasedByClient: t.Type<MembershipPurchasedByClient, JSONable> = t.intersection(
  [
    PurchasedMembershipHistoryBase,
    t.type({
      type: MembershipPurchasedByClientHistoryType,
      payload: t.type(
        {
          online: t.boolean,
        },
        'Payload',
      ),
    }),
  ],
  'MembershipPurchasedByClient',
);

export type PaymentCollectedByPro = PurchasedMembershipHistoryBase & {
  readonly type: MembershipPaymentCollectedByProHistoryType;
  readonly payload: {
    readonly transactionCode: string;
    readonly byProUser: ByUser;
    readonly partialPayment: boolean;
  };
};

const PaymentCollectedByPro: t.Type<PaymentCollectedByPro, JSONable> = t.intersection(
  [
    PurchasedMembershipHistoryBase,
    t.type({
      type: MembershipPaymentCollectedByProHistoryType,
      payload: t.type(
        {
          transactionCode: t.string,
          byProUser: ByUser,
          partialPayment: t.boolean,
        },
        'Payload',
      ),
    }),
  ],
  'PaymentCollectedByPro',
);

export type AppointmentPaidUsingMembership = PurchasedMembershipHistoryBase & {
  readonly type: AppointmentPaidUsingMembershipHistoryType;
  readonly payload: {
    readonly byProUser: ByUser;
    readonly appointmentId: AppointmentId;
    readonly occurrenceIndex: number | undefined;
    readonly start: Date;
  };
};

const AppointmentPaidUsingMembership: t.Type<AppointmentPaidUsingMembership, JSONable> = t.intersection(
  [
    PurchasedMembershipHistoryBase,
    t.type({
      type: AppointmentPaidUsingMembershipHistoryType,
      payload: t.type(
        {
          byProUser: ByUser,
          appointmentId: AppointmentId,
          start: DateFromISOString,
          occurrenceIndex: optionull(t.number),
        },
        'Payload',
      ),
    }),
  ],
  'AppointmentPaidUsingMembership',
);

export type AppointmentMembershipPaymentCancelled = PurchasedMembershipHistoryBase & {
  readonly type: AppointmentMembershipPaymentCancelledHistoryType;
  readonly payload: {
    readonly byProUser: ByUser;
    readonly appointmentId: AppointmentId;
    readonly occurrenceIndex: number | undefined;
    readonly start: Date;
  };
};

const AppointmentMembershipPaymentCancelled: t.Type<AppointmentMembershipPaymentCancelled, JSONable> = t.intersection(
  [
    PurchasedMembershipHistoryBase,
    t.type({
      type: AppointmentMembershipPaymentCancelledHistoryType,
      payload: t.type(
        {
          byProUser: ByUser,
          appointmentId: AppointmentId,
          occurrenceIndex: optionull(t.number),
          start: DateFromISOString,
        },
        'Payload',
      ),
    }),
  ],
  'AppointmentMembershipPaymentCancelled',
);

export type ServicesPaidUsingMembership = PurchasedMembershipHistoryBase & {
  readonly type: ServicesPaidUsingMembershipHistoryType;
  readonly payload: {
    readonly byProUser: ByUser;
    readonly services: {
      readonly _id: ServiceId;
      readonly name: string | undefined;
    }[];
  };
};

const ServicesPaidUsingMembership: t.Type<ServicesPaidUsingMembership, JSONable> = t.intersection(
  [
    PurchasedMembershipHistoryBase,
    t.type({
      type: ServicesPaidUsingMembershipHistoryType,
      payload: t.type(
        {
          byProUser: ByUser,
          services: t.array(
            t.type(
              {
                _id: ServiceId,
                name: optionull(t.string),
              },
              'Service',
            ),
            'Services',
          ),
        },
        'Payload',
      ),
    }),
  ],
  'ServicesPaidUsingMembership',
);

export type ServicesMembershipPaymentCancelled = PurchasedMembershipHistoryBase & {
  readonly type: ServicesMembershipPaymentCancelledHistoryType;
  readonly payload: {
    readonly byProUser: ByUser;
    readonly services: {
      readonly _id: ServiceId;
      readonly name: string | undefined;
    }[];
  };
};

const ServicesMembershipPaymentCancelled: t.Type<ServicesMembershipPaymentCancelled, JSONable> = t.intersection(
  [
    PurchasedMembershipHistoryBase,
    t.type({
      type: ServicesMembershipPaymentCancelledHistoryType,
      payload: t.type(
        {
          byProUser: ByUser,
          services: t.array(
            t.type(
              {
                _id: ServiceId,
                name: optionull(t.string),
              },
              'Service',
            ),
            'Services',
          ),
        },
        'Payload',
      ),
    }),
  ],
  'ServicesMembershipPaymentCancelled',
);

export type MembershipCancelledByPro = PurchasedMembershipHistoryBase & {
  readonly type: MembershipCancelledByProHistoryType;
  readonly payload: {
    readonly byProUser: ByUser;
  };
};

const MembershipCancelledByPro: t.Type<MembershipCancelledByPro, JSONable> = t.intersection(
  [
    PurchasedMembershipHistoryBase,
    t.type({
      type: MembershipCancelledByProHistoryType,
      payload: t.type(
        {
          byProUser: ByUser,
        },
        'Payload',
      ),
    }),
  ],
  'MembershipCancelledByPro',
);

export type PaymentCancelledByPro = PurchasedMembershipHistoryBase & {
  readonly type: MembershipPaymentCancelledByProHistoryType;
  readonly payload: {
    readonly transactionCode: string;
    readonly byProUser: ByUser | undefined;
  };
};

const PaymentCancelledByPro: t.Type<PaymentCancelledByPro, JSONable> = t.intersection(
  [
    PurchasedMembershipHistoryBase,
    t.type({
      type: MembershipPaymentCancelledByProHistoryType,
      payload: t.type(
        {
          transactionCode: t.string,
          byProUser: optionull(ByUser),
        },
        'Payload',
      ),
    }),
  ],
  'PaymentCancelledByPro',
);

export type AnyPurchasedMembershipHistoryRecord =
  | MembershipPurchasedByClient
  | PaymentCollectedByPro
  | AppointmentPaidUsingMembership
  | AppointmentMembershipPaymentCancelled
  | ServicesPaidUsingMembership
  | ServicesMembershipPaymentCancelled
  | MembershipCancelledByPro
  | PaymentCancelledByPro;

export const AnyPurchasedMembershipHistoryRecord: t.Type<AnyPurchasedMembershipHistoryRecord, JSONable> = t.union(
  [
    MembershipPurchasedByClient,
    PaymentCollectedByPro,
    AppointmentPaidUsingMembership,
    AppointmentMembershipPaymentCancelled,
    ServicesPaidUsingMembership,
    ServicesMembershipPaymentCancelled,
    MembershipCancelledByPro,
    PaymentCancelledByPro,
  ],
  'AnyPurchasedMembershipHistoryRecord',
);

export const PurchasedMembershipHistoryRecordArray = t.array(AnyPurchasedMembershipHistoryRecord);

export type PurchasedMembershipHistoryRecordArray = t.TypeOf<typeof PurchasedMembershipHistoryRecordArray>;
