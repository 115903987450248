import { Home } from './home';
import { SearchResults } from './searchResults';
import { JSONable } from '@mero/shared-sdk';
import * as t from 'io-ts';

export type Any = Home | SearchResults;

const JSON: t.Type<Any, JSONable> = t.union([SearchResults.JSON, Home.JSON], 'Any');

export const Any = {
  JSON,
};
