import {
  AppointmentMadeByPageNotificationType,
  AppointmentCancelledByWorkerNotificationType,
  AppointmentModifiedNotificationType,
  AppointmentReminderNotificationType,
} from './notification-type';
import { JSONable } from '@mero/shared-sdk';
import * as t from 'io-ts';

export const CustomizedNotificationTypes = {
  appointmentMadeByPage: AppointmentMadeByPageNotificationType.value,
  appointmentCancelledByWorker: AppointmentCancelledByWorkerNotificationType.value,
  appointmentModified: AppointmentModifiedNotificationType.value,
  appointmentReminder: AppointmentReminderNotificationType.value,
};

export type CustomizedNotificationType =
  | AppointmentMadeByPageNotificationType
  | AppointmentCancelledByWorkerNotificationType
  | AppointmentModifiedNotificationType
  | AppointmentReminderNotificationType;

const JSON: t.Type<CustomizedNotificationType, JSONable> = t.union(
  [
    AppointmentMadeByPageNotificationType,
    AppointmentCancelledByWorkerNotificationType,
    AppointmentModifiedNotificationType,
    AppointmentReminderNotificationType,
  ],
  'CustomizedNotificationType',
);

export const CustomizedNotificationType = {
  JSON,
};
