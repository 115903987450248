import { AppointmentPaymentsOption } from './appointmentPaymentsOption';
import { PageGiftCardSettings } from './pageGiftCardSettings';
import { RequireAdvancePaymentOption } from './requireAdvancePaymentOption';
import { JSONable, None, Option } from '@mero/shared-sdk';
import * as t from 'io-ts';

export type OnlinePaymentsSettings = {
  /**
   * This field is optional for backwards compatibility, until migration is executed
   * None means "On"
   */
  readonly appointmentPayments: AppointmentPaymentsOption;
  readonly requireAdvancePayment: RequireAdvancePaymentOption;
  readonly advancePaymentTos: Option<string>;
  readonly giftCards: PageGiftCardSettings;
};

const JSON: t.Type<OnlinePaymentsSettings, JSONable> = t.type(
  {
    appointmentPayments: AppointmentPaymentsOption.JSON,
    requireAdvancePayment: RequireAdvancePaymentOption.JSON,
    advancePaymentTos: Option.json(t.string),
    giftCards: PageGiftCardSettings.JSON,
  },
  'PageOnlinePaymentsSettings',
);

const DEFAULT: OnlinePaymentsSettings = {
  appointmentPayments: AppointmentPaymentsOption.DEFAULT,
  requireAdvancePayment: RequireAdvancePaymentOption.DEFAULT,
  advancePaymentTos: None,
  giftCards: PageGiftCardSettings.DEFAULT,
};

export const OnlinePaymentsSettings = {
  JSON,
  DEFAULT,
};
