import * as t from 'io-ts';

export type Monthly = 0;
export const Monthly = t.literal(0);

export type Quarterly = 1;
export const Quarterly = t.literal(1);

export type HalfYear = 2;
export const HalfYear = t.literal(2);

export type Annually = 3;
export const Annually = t.literal(3);

export type Any = Monthly | Quarterly | HalfYear | Annually;

export const JSON = t.union([Monthly, Quarterly, HalfYear, Annually], 'BillingCycle');
