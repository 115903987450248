import { CompanyVatStatus } from '../companies/companyVatStatus';
import { CheckoutTransactionCompanyDetails } from './checkoutTransactionCompanyDetails';
import { Company } from './company';
import { None } from './none';
import { ScaledNumber } from '@mero/shared-sdk';

export type { Company } from './company';
export type { None } from './none';

export type Any = None | Company;

export const NONE: None = {
  type: 'None',
};

export const company = (company: CheckoutTransactionCompanyDetails, receipt: Company['receipt']): Company => ({
  type: 'Company',
  company,
  emitReceipt: undefined,
  receipt,
});

export const getVatStatus = (company: Any): CompanyVatStatus.Any<ScaledNumber> => {
  return company.type === 'Company' ? company.company.vatStatus : CompanyVatStatus.NO_VAT;
};
