import * as React from 'react';
import Svg, { G, Path, Rect } from 'react-native-svg';

type Props = {
  size?: string | number;
  color?: string;
  disabled?: boolean;
  onPress?: () => void;
};

const Checkmark: React.FC<Props> = ({ size = 24, color = '#3497fd', disabled = false, onPress }) => (
  <Svg width={size} height={size} viewBox="0 0 24 24" disabled={disabled} onPress={onPress}>
    <G transform="translate(-20 -593)">
      <Rect width="24" height="24" transform="translate(20 593)" fill="none" />
      <Path
        d="M1.021,15.165a1.021,1.021,0,1,1,0-2.043H6.534V1.021a1.022,1.022,0,0,1,2.044,0V13.877a1.022,1.022,0,0,1-.986,1.288Z"
        transform="translate(34.723 595) rotate(45)"
        fill={color}
      />
    </G>
  </Svg>
);

export default React.memo(Checkmark);
