import { AvailabilityFilter } from '../productSearch';
import * as t from 'io-ts';

export type Consumable = {
  readonly type: 'Consumable';
};

export const Consumable: ProductAvailabilityModule<Consumable> = {
  value: {
    type: 'Consumable',
  },
  JSON: t.type(
    {
      type: t.literal('Consumable'),
    },
    'Consumable',
  ),
};

export type OfflineRetail = {
  readonly type: 'Retail';
  readonly channel: 'Offline';
};

export const OfflineRetail: ProductAvailabilityModule<OfflineRetail> = {
  value: {
    type: 'Retail',
    channel: 'Offline',
  },
  JSON: t.type(
    {
      type: t.literal('Retail'),
      channel: t.literal('Offline'),
    },
    'OfflineRetail',
  ),
};

export type OnlineRetail = {
  readonly type: 'Retail';
  readonly channel: 'Online';
};

export const OnlineRetail: ProductAvailabilityModule<OnlineRetail> = {
  value: {
    type: 'Retail',
    channel: 'Online',
  },
  JSON: t.type(
    {
      type: t.literal('Retail'),
      channel: t.literal('Online'),
    },
    'OnlineRetail',
  ),
};

export type ProductAvailabilityModule<Availability extends Any> = {
  value: Availability;
  JSON: t.Type<Availability>;
};

export type Any = OnlineRetail | OfflineRetail | Consumable;

export const JSON = t.union([OnlineRetail.JSON, OfflineRetail.JSON, Consumable.JSON], 'ProductAvailability');

export const toStr = (availability: Any): AvailabilityFilter => {
  if (Consumable.JSON.is(availability)) {
    return 'Consumable';
  }
  if (OfflineRetail.JSON.is(availability)) {
    return 'OfflineRetail';
  }
  return 'OnlineRetail';
};
