import { JSONable, optionull } from '@mero/shared-sdk';
import * as t from 'io-ts';

export type OtherProAppointmentNotificationSettings = {
  /**
   * other pros add a booking
   */
  readonly appointmentMadeByAnotherPro: boolean;
  /**
   * other pros cancel a booking
   */
  readonly appointmentCancelledByAnotherPro: boolean;
  /**
   * other pros change a booking
   */
  readonly appointmentModifiedByAnotherPro: boolean;
  /**
   * other pros approve or reject a booking with manual confirmation
   */
  readonly appointmentRequestFulfilledByAnotherPro: boolean;
  /**
   * client creates a booking
   */
  readonly appointmentMadeByClient: boolean;
  /**
   * client cancels a booking
   */
  readonly appointmentCancelledByClient: boolean;
  /**
   * client was added to waiting list in other pros calendars
   */
  readonly clientAddedToWaitingList: boolean | undefined;
};

const JSON: t.Type<OtherProAppointmentNotificationSettings, JSONable> = t.type(
  {
    appointmentMadeByAnotherPro: t.boolean,
    appointmentCancelledByAnotherPro: t.boolean,
    appointmentModifiedByAnotherPro: t.boolean,
    appointmentRequestFulfilledByAnotherPro: t.boolean,
    appointmentMadeByClient: t.boolean,
    appointmentCancelledByClient: t.boolean,
    clientAddedToWaitingList: optionull(t.boolean),
  },
  'OtherProAppointmentNotificationSettings',
);

const DEFAULT: OtherProAppointmentNotificationSettings = {
  appointmentMadeByAnotherPro: false,
  appointmentCancelledByAnotherPro: false,
  appointmentModifiedByAnotherPro: false,
  appointmentRequestFulfilledByAnotherPro: false,
  appointmentMadeByClient: true,
  appointmentCancelledByClient: true,
  clientAddedToWaitingList: false,
};

export const OtherProAppointmentNotificationSettings = {
  JSON,
  DEFAULT,
};
