import {
  AccountPermissions,
  BookingsPermissions,
  CheckoutPermissions,
  ClientsPermissions,
  MarketingPermissions,
  MeroPermissions,
  NotificationsPermissions,
  PagePermissions,
  ProProfilePermissions,
  ProductsPermissions,
  ReviewsPermissions,
  StatisticsPermissions,
  ServicesPermissions,
  OwnNotificationsSettingsPermissions,
} from './mero-permissions';

const hasAny = <T extends { [K in string]: boolean }>(permissions: T): boolean =>
  Object.values(permissions).reduce((acc, val) => acc || val, false);

export class MeroPermissionsWrapper {
  public readonly account: AccountPermissionsWrapper;
  public readonly page: PagePermissionsWrapper;
  public readonly bookings: BookingsPermissionsWrapper;
  public readonly checkout: CheckoutPermissionsWrapper;
  public readonly clients: ClientsPermissionsWrapper;
  public readonly proProfiles: ProProfilePermissionsWrapper;
  public readonly notifications: NotificationsPermissionsWrapper;
  public readonly statistics: StatisticsPermissionsWrapper;
  public readonly reviews: ReviewsPermissionsWrapper;
  public readonly marketing: MarketingPermissionsWrapper;
  public readonly services: ServicesPermissionsWrapper;
  public readonly products: ProductsPermissionsWrapper;
  public readonly ownNotificationsSettings: OwnNotificationsSettingsPermissionsWrapper;

  private readonly _hasAny: boolean;

  constructor(permissions: MeroPermissions) {
    this.account = new AccountPermissionsWrapper(permissions.account);
    this.page = new PagePermissionsWrapper(permissions.page);
    this.bookings = new BookingsPermissionsWrapper(permissions.bookings);
    this.checkout = new CheckoutPermissionsWrapper(permissions.checkout);
    this.clients = new ClientsPermissionsWrapper(permissions.clients);
    this.proProfiles = new ProProfilePermissionsWrapper(permissions.proProfile);
    this.notifications = new NotificationsPermissionsWrapper(permissions.notifications, this.clients);
    this.statistics = new StatisticsPermissionsWrapper(permissions.statistics);
    this.reviews = new ReviewsPermissionsWrapper(permissions.reviews);
    this.marketing = new MarketingPermissionsWrapper(permissions.marketing);
    this.services = new ServicesPermissionsWrapper(permissions.services);
    this.products = new ProductsPermissionsWrapper(permissions.products);
    this.ownNotificationsSettings = new OwnNotificationsSettingsPermissionsWrapper(
      permissions.ownNotificationsSettings,
    );

    this._hasAny =
      this.account.hasAny() ||
      this.page.hasAny() ||
      this.bookings.hasAny() ||
      this.clients.hasAny() ||
      this.proProfiles.hasAny() ||
      this.notifications.hasAny() ||
      this.statistics.hasAny() ||
      this.reviews.hasAny() ||
      this.marketing.hasAny() ||
      this.services.hasAny() ||
      this.products.hasAny() ||
      this.ownNotificationsSettings.hasAny();
  }

  public hasAny(): boolean {
    return this._hasAny;
  }
}

export class AccountPermissionsWrapper {
  private _hasAny: boolean;

  constructor(private permissions: AccountPermissions) {
    this._hasAny = hasAny(this.permissions);
  }

  public hasAny(): boolean {
    return this._hasAny;
  }

  public canManageSubscription(): boolean {
    return this.permissions.manageSubscription;
  }

  public canManageOwnership(): boolean {
    return this.permissions.manageOwnership;
  }

  public canDeleteAccount(): boolean {
    return this.canManageOwnership();
  }

  public canManagePermissions(): boolean {
    return this.permissions.managePermissions || this.permissions.manageOwnership;
  }

  public canReadOwnPermissions(): boolean {
    return this.permissions.readOwnPermissions;
  }

  public canReadRoles(): boolean {
    return this.canReadOwnPermissions();
  }
}

export class PagePermissionsWrapper {
  private _hasAny: boolean;

  constructor(private permissions: PagePermissions) {
    this._hasAny = hasAny(this.permissions);
  }

  public hasAny(): boolean {
    return this._hasAny;
  }

  public canManageDetails(): boolean {
    return this.permissions.manageDetails;
  }

  public canPublish(): boolean {
    return this.canManageDetails();
  }

  public canManageGallery(): boolean {
    return this.permissions.manageGallery;
  }

  public canManageServices(): boolean {
    return this.permissions.manageServices;
  }

  public canManageWorkingHours(): boolean {
    return this.permissions.manageWorkingHours;
  }

  public canManageOnlinePayments(): boolean {
    return this.permissions.manageOnlinePayments;
  }

  public canReadBillingDetails(): boolean {
    return this.permissions.readBillingDetails || this.canManageBillingDetails();
  }

  public canManageBillingDetails(): boolean {
    return this.permissions.manageBillingDetails;
  }

  public canReadNotificationSettings(): boolean {
    return this.permissions.readNotificationSettings;
  }

  public canManageNotificationSettings(): boolean {
    return this.permissions.manageNotificationSettings;
  }

  public canManageGiftcards(): boolean {
    return this.permissions.manageGiftcards;
  }

  public canManageDiscounts(): boolean {
    return this.permissions.manageDiscounts;
  }

  public canManageMemberships(): boolean {
    return this.permissions.manageMemberships;
  }

  public canManageCheckout(): boolean {
    return this.permissions.manageCheckout;
  }

  /**
   * User can connect MERO SMS gateway app using his account and send SMS notifications
   */
  public canSendSMSNotifications(): boolean {
    return this.permissions.sendSMSNotifications;
  }

  /**
   * User can activate/deactivate MERO Boost
   */
  public canManageBoost(): boolean {
    return this.permissions.manageBoost;
  }
}

export class CheckoutPermissionsWrapper {
  private _hasAny: boolean;

  constructor(private permissions: CheckoutPermissions) {
    this._hasAny = hasAny(this.permissions);
  }

  public hasAny(): boolean {
    return this._hasAny;
  }

  public canReadAll(): boolean {
    return this.permissions.readAll;
  }

  public canWriteAll(): boolean {
    return this.permissions.writeAll;
  }

  /**
   * User can download receipt files for printing
   * Frontend only check, backend does not get authorization on download endpoint, so can't check permissions
   */
  public isReceiptPrinter(): boolean {
    return this.permissions.isReceiptPrinter;
  }
}

export class BookingsPermissionsWrapper {
  private _hasAny: boolean;

  constructor(private permissions: BookingsPermissions) {
    this._hasAny = hasAny(this.permissions);
  }

  public hasAny(): boolean {
    return this._hasAny;
  }

  public canReadOwnBookings(): boolean {
    return this.permissions.readOwn || this.permissions.readAll;
  }

  public canReadOwnBookingDetails(): boolean {
    return this.permissions.readOwnDetails || this.permissions.readAllDetails;
  }

  public canMarkOwnAsNoShow(): boolean {
    return this.permissions.markOwnNoShow || this.permissions.markAllNoShow;
  }

  public canWriteOwnBookings(): boolean {
    return this.permissions.writeOwn || this.permissions.writeAll;
  }

  public canWriteOwnBlockedTime(): boolean {
    return this.permissions.writeOwnBlockedTime || this.permissions.writeAllBlockedTime;
  }

  public canManageOwnBookingRequests(): boolean {
    return this.permissions.manageOwnBookingRequests || this.permissions.manageAllBookingRequests;
  }

  public canReadAllBookings(): boolean {
    return this.permissions.readAll || this.canWriteAllBookings();
  }

  public canReadAllBookingDetails(): boolean {
    return this.permissions.readAllDetails || this.canWriteAllBookings();
  }

  public canMarkAllAsNoShow(): boolean {
    return this.permissions.markAllNoShow;
  }

  public canWriteAllBookings(): boolean {
    return this.permissions.writeAll;
  }

  public canWriteAllBlockedTime(): boolean {
    return this.permissions.writeAllBlockedTime;
  }

  public canManageAllBookingRequests(): boolean {
    return this.permissions.manageAllBookingRequests;
  }
}

export class ClientsPermissionsWrapper {
  private _hasAny: boolean;

  constructor(private permissions: ClientsPermissions) {
    this._hasAny = hasAny(this.permissions);
  }

  public hasAny(): boolean {
    return this._hasAny;
  }

  /**
   * PROs can search clients from 3 symbols
   * @returns
   */
  public canUseLimitedSearch(): boolean {
    return this.permissions.limitedSearch || this.canUseSearch() || this.canManageAll();
  }

  /**
   * PRO can search clients from 1 symbol
   * @returns
   */
  public canUseSearch(): boolean {
    return this.permissions.search || this.canListAllClients() || this.canManageAll();
  }

  /**
   * PRO can list all clients
   */
  public canListAllClients(): boolean {
    return this.permissions.listAll || this.canManageAll();
  }

  /**
   * Get number of symbols that pro can search clients from
   */
  public getSearchMinSymbols(): number {
    if (this.canListAllClients()) {
      return 0;
    } else if (this.canUseSearch()) {
      return 1;
    } else if (this.canUseLimitedSearch()) {
      return 2;
    } else {
      return Number.MAX_SAFE_INTEGER;
    }
  }

  public canViewClientDetails(): boolean {
    return this.permissions.viewDetails || this.canManageAll();
  }

  public canReadOwnInvites(): boolean {
    return this.permissions.readOwnInvites || this.canWriteOwnInvites() || this.canReadAllInvites();
  }

  public canWriteOwnInvites(): boolean {
    return this.permissions.writeOwnInvites || this.canWriteAllInvites();
  }

  public canReadAllInvites(): boolean {
    return this.permissions.readAllInvites || this.canWriteAllInvites();
  }

  public canWriteAllInvites(): boolean {
    return this.permissions.writeAllInvites;
  }

  public canCreateNewClient(): boolean {
    return true;
  }

  public canAcceptBoostClientCommission(): boolean {
    return this.permissions.acceptBoostClientCommission;
  }

  public canRequestBoostClientClaim(): boolean {
    return this.permissions.requestBoostClientClaim;
  }

  public canManageAll(): boolean {
    return this.permissions.manageAll;
  }

  /**
   * Pro can add feedback for a client
   */
  public canAddClientFeedback(): boolean {
    return this.permissions.addClientFeedback;
  }
}

export class OwnNotificationsSettingsPermissionsWrapper {
  private _hasAny: boolean;

  constructor(private permissions: OwnNotificationsSettingsPermissions) {
    this._hasAny = hasAny(this.permissions);
  }

  public hasAny(): boolean {
    return this._hasAny;
  }

  public canManageAll(): boolean {
    return this.permissions.manageAll;
  }

  public canManageOwnAppointments(): boolean {
    return this.permissions.manageOwnAppointments;
  }

  public canManageOthersAppointments(): boolean {
    return this.permissions.manageOthersAppointments;
  }

  public canManageSmsReminder(): boolean {
    return this.permissions.manageSmsReminder;
  }

  public canManageOwnWaitingList(): boolean {
    return this.permissions.manageOwnWaitingList;
  }

  public canManageOthersWaitingList(): boolean {
    return this.permissions.manageOthersWaitingList;
  }

  public canManageFulfillRequest(): boolean {
    return this.permissions.manageFulfillRequest;
  }
}

export class ProProfilePermissionsWrapper {
  private _hasAny: boolean;

  constructor(private permissions: ProProfilePermissions) {
    this._hasAny = hasAny(this.permissions);
  }

  public hasAny(): boolean {
    return this._hasAny;
  }

  public canReadOwnProfileDetails(): boolean {
    return true;
  }

  public canEditOwnProfileDetails(): boolean {
    return this.permissions.editOwnDetails || this.canManageAllProfiles();
  }

  public canEditOwnProfile(): boolean {
    return this.permissions.editOwn || this.canManageAllProfiles();
  }

  public canDeleteOwnProfile(): boolean {
    return this.permissions.deleteOwn || this.canManageAllProfiles();
  }

  public canListAllProfiles(): boolean {
    return this.permissions.listAll;
  }

  public canReadAllProfileDetails(): boolean {
    return this.canManageAllProfiles();
  }

  public canManageAllProfiles(): boolean {
    return this.permissions.manageAll;
  }

  public canInviteNewWorkers(): boolean {
    return this.canManageAllProfiles();
  }
}

export class NotificationsPermissionsWrapper {
  private _hasAny: boolean;

  constructor(private permissions: NotificationsPermissions, private clients: ClientsPermissionsWrapper) {
    this._hasAny = hasAny(this.permissions);
  }

  public hasAny(): boolean {
    return this._hasAny;
  }

  public canReceiveAllProsClientAppointmentMade(): boolean {
    return this.permissions.receiveAllProsClientAppointmentMade;
  }

  public canReceiveAllProsAppointmentCanceledByClient(): boolean {
    return this.permissions.receiveAllProsAppointmentCanceledByClient;
  }

  public canReceiveAllProsClientPaymentMade(): boolean {
    return this.permissions.receiveAllProsClientPaymentMade;
  }

  /**
   * PRO can manually send SMS reminder notifications for own appointments clients
   */
  public canSendOwnManualSMSNotifications(): boolean {
    return this.permissions.canSendOwnManualSMS;
  }

  /**
   * PRO can manually send SMS reminder notifications for all appointments clients
   */
  public canSendAllManualSMSNotifications(): boolean {
    return this.permissions.sendAllManualSMS;
  }

  /**
   * PRO can customize notification templates
   */
  public canCustomizeNotificationTemplates(): boolean {
    return this.permissions.customizeNotificationTemplates;
  }

  /**
   * PRO can receive notifications from appointment actions for other workers calendars
   */
  public canReceiveOtherProsAppointmentAction(): boolean {
    return this.permissions.receiveOtherProsAppointmentAction;
  }
}

export class StatisticsPermissionsWrapper {
  private _hasAny: boolean;

  constructor(private permissions: StatisticsPermissions) {
    this._hasAny = hasAny(this.permissions);
  }

  public hasAny(): boolean {
    return this._hasAny;
  }

  public canReadOwnStatistics(): boolean {
    return this.permissions.readOwn || this.canReadAllStatistics();
  }

  public canReadAllStatistics(): boolean {
    return this.permissions.readAll;
  }
}

export class ReviewsPermissionsWrapper {
  private _hasAny: boolean;

  constructor(private permissions: ReviewsPermissions) {
    this._hasAny = hasAny(this.permissions);
  }

  public hasAny(): boolean {
    return this._hasAny;
  }

  public canReadOwnReviews(): boolean {
    return this.permissions.readOwn || this.canReadAllReviews();
  }

  public canReadAllReviews(): boolean {
    return this.permissions.readAll;
  }

  public canManageAllReviews(): boolean {
    return this.permissions.manageAll;
  }

  public canManageSettings(): boolean {
    return this.permissions.manageSettings;
  }
}

export class MarketingPermissionsWrapper {
  private _hasAny: boolean;

  constructor(private permissions: MarketingPermissions) {
    this._hasAny = hasAny(this.permissions);
  }

  public hasAny(): boolean {
    return this._hasAny;
  }

  public canManageAll(): boolean {
    return this.permissions.manageAll;
  }
}

export class ServicesPermissionsWrapper {
  private _hasAny: boolean;

  constructor(private permissions: ServicesPermissions) {
    this._hasAny = hasAny(this.permissions);
  }

  public hasAny(): boolean {
    return this._hasAny;
  }

  public canReadAll(): boolean {
    return this.permissions.readAll;
  }
}

export class ProductsPermissionsWrapper {
  private _hasAny: boolean;

  constructor(private permissions: ProductsPermissions) {
    this._hasAny = hasAny(this.permissions);
  }

  public hasAny(): boolean {
    return this._hasAny;
  }

  public canManageAll(): boolean {
    return this.permissions.manageAll;
  }
}
