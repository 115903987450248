import { CheckoutCashRegisterDriverId } from './checkoutCashRegisterDriverId';
import { CheckoutCashRegisterDriverType } from './checkoutCashRegisterDriverType';

export type CheckoutCashRegisterDriverDetails = {
  readonly driverId: CheckoutCashRegisterDriverId;
  readonly name: string;
};

const fromDriver = (driver: CheckoutCashRegisterDriverType, name: string): CheckoutCashRegisterDriverDetails => {
  return {
    driverId: driver as CheckoutCashRegisterDriverId,
    name: name,
  };
};

export const CheckoutCashRegisterDriverDetails = {
  fromDriver,
};
