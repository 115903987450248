import { ExtendedCalculator } from './extendedCalculator';
import { NonNegative } from './nonNegative';
import { NonZero } from './nonZero';
import * as t from 'io-ts';

export type Positive<Num> = NonNegative<Num> & NonZero<Num>;

export type PositiveModule<Num> = {
  /**
   * Checks if value is a valid Positive<Num> value
   */
  readonly is: (a: Num) => a is Positive<Num>;
  /**
   * Create a Positive<Num> from a number
   */
  readonly unsafeFrom: (n: Num) => Positive<Num>;
  /**
   * Build new JSON codec for Postive<Num>
   */
  readonly json: <O, I>(codec: t.Type<Num, O, I>) => t.Type<Positive<Num>, O, I>;
};

const build = <Num>(num: Pick<ExtendedCalculator<Num>, 'greaterThan' | 'zero'>): PositiveModule<Num> => {
  const is = (a: Num): a is Positive<Num> => {
    return num.greaterThan(a, num.zero());
  };

  const unsafeFrom = (n: Num): Positive<Num> => {
    if (!is(n)) {
      throw new Error('Invalid Positive<Num> value');
    }

    return n;
  };

  const json = <O, I>(codec: t.Type<Num, O, I>): t.Type<Positive<Num>, O, I> => {
    return t.brand(codec, is, `NonNegative`);
  };

  return {
    is,
    unsafeFrom,
    json,
  };
};

export const Positive = {
  build,
};
