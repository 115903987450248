import { Deleted, AnyDeleted } from './deleted';
import { Draft, AnyDraft } from './draft';
import { Finished, AnyFinished } from './finished';
import { MeroUnits } from '@mero/shared-sdk';

export * from './draft';
export * from './finished';
export * from './deleted';

export { computeTransactionTotals, mayHaveDiscount } from './utils';

export type Of<Unit extends MeroUnits.Any> = Draft<Unit> | Finished<Unit> | Deleted<Unit>;

export type Any = AnyDraft | AnyFinished | AnyDeleted;

export const isDraft = <Unit extends MeroUnits.Any>(transaction: Of<Unit>): transaction is Draft<Unit> => {
  return transaction.status === 'Draft';
};

export const isFinished = <Unit extends MeroUnits.Any>(transaction: Of<Unit>): transaction is Finished<Unit> => {
  return transaction.status === 'Finished';
};

export const isDeleted = <Unit extends MeroUnits.Any>(transaction: Of<Unit>): transaction is Deleted<Unit> => {
  return transaction.status === 'Deleted';
};
