import * as React from 'react';
import Svg, { Path } from 'react-native-svg';

import { BLACK } from '../../../styles/colors';

type Props = {
  size?: string | number;
  color?: string;
  disabled?: boolean;
  onPress?: () => void;
};

const Calendar: React.FC<Props> = ({ size = 24, color = BLACK, disabled = false, onPress }) => (
  <Svg width={size} height={size} viewBox="0 0 24 24" disabled={disabled} onPress={onPress}>
    <Path data-name="Rectangle 1517" fill="none" d="M0 0h24v24H0z" />
    <Path
      data-name="Union 3"
      d="M3 24a2 2 0 0 1-2-2V5.25a2 2 0 0 1 2-2h2.25v-1.5a.75.75 0 0 1 1.5 0v1.5h4.5v-1.5a.75.75 0 0 1 1.5 0v1.5h4.5v-1.5a.75.75 0 1 1 1.5 0v1.5H21a2 2 0 0 1 2 2V22a2 2 0 0 1-2 2ZM2.5 5.25V22a.5.5 0 0 0 .5.5h18a.5.5 0 0 0 .5-.5V5.25a.5.5 0 0 0-.5-.5h-2.25v1.5a.75.75 0 1 1-1.5 0v-1.5h-4.5v1.5a.75.75 0 1 1-1.5 0v-1.5h-4.5v1.5a.75.75 0 1 1-1.5 0v-1.5H3a.5.5 0 0 0-.5.5Zm8 14.5V17.5h3v2.25Zm-5.251 0V17.5h3v2.25ZM15.75 16v-2.25h3V16Zm-5.25 0v-2.25h3V16Zm-5.25 0v-2.25h3V16Zm10.5-3.75V10h3v2.25Zm-5.25 0V10h3v2.25Zm-5.251 0V10h3v2.25Z"
      fill={color}
    />
  </Svg>
);

export default React.memo(Calendar);
