import { Email, optionull, PhoneNumberParser } from '@mero/shared-sdk';
import * as t from 'io-ts';

export const BillingInfo = t.intersection(
  [
    t.type({
      firstname: t.string,
      lastname: t.string,
      email: Email.TRUSTED_JSON,
      phone: PhoneNumberParser,
      address: t.string,
    }),
    t.partial({
      county: optionull(t.string),
      city: optionull(t.string),
      company: t.type({
        name: t.string,
        fiscalCode: t.string,
        iban: optionull(t.string),
        registrationNumber: t.string,
      }),
    }),
  ],
  'BillingInfo',
);
export type BillingInfo = t.TypeOf<typeof BillingInfo>;
