import {
  CheckoutTotalsScaledNumber,
  CheckoutTransactionCompany,
  CheckoutTransactionItem,
  CheckoutTransactionPayment,
} from '../../checkout';
import { CheckoutReportTotals } from './checkoutReportTotals';
import { AppliedDiscount, MeroUnits, ScaledNumber, Money, MeroCurrency } from '@mero/shared-sdk';

export type CheckoutReportTotalsByType<Unit extends MeroUnits.Any> = {
  /**
   * Totals not split by type (sum of services + products + amounts + memberships + membership installments)
   */
  readonly all: CheckoutReportTotals<Unit>;
  /**
   * Totals for purchased services
   */
  readonly services: CheckoutReportTotals<Unit>;
  /**
   * Totals for purchased products
   */
  readonly products: CheckoutReportTotals<Unit>;
  /**
   * Totals for purchased memberships
   */
  readonly memberships: CheckoutReportTotals<Unit>;
  /**
   * Totals for custom amounts
   */
  readonly amounts: CheckoutReportTotals<Unit>;
};

const MoneyScaledNumber = Money.build(ScaledNumber, MeroUnits);

/**
 * Get totals zero value for given unit
 */
const zero = <Unit extends MeroUnits.Any>(unit: Unit): CheckoutReportTotalsByType<Unit> => {
  return {
    all: CheckoutReportTotals.zero(unit),
    services: CheckoutReportTotals.zero(unit),
    products: CheckoutReportTotals.zero(unit),
    memberships: CheckoutReportTotals.zero(unit),
    amounts: CheckoutReportTotals.zero(unit),
  };
};

const add = <Unit extends MeroUnits.Any>(
  a: CheckoutReportTotalsByType<Unit>,
  b: CheckoutReportTotalsByType<Unit>,
): CheckoutReportTotalsByType<Unit> => {
  return {
    all: CheckoutReportTotals.add(a.all, b.all),
    services: CheckoutReportTotals.add(a.services, b.services),
    products: CheckoutReportTotals.add(a.products, b.products),
    memberships: CheckoutReportTotals.add(a.memberships, b.memberships),
    amounts: CheckoutReportTotals.add(a.amounts, b.amounts),
  };
};

/**
 * Get total value ONLY for items paid with memberships
 */
const getMembershipPaidItemsTotals = <Unit extends MeroUnits.Any>(
  transaction: {
    readonly unit: Unit;
    readonly items: CheckoutTransactionItem.Any<Unit>[];
    readonly payments: CheckoutTransactionPayment.Any<ScaledNumber, Unit>[];
    readonly company: CheckoutTransactionCompany.Company;
    readonly isProtocol: boolean;
  },
  decimals: number,
): CheckoutReportTotalsByType<Unit> => {
  return transaction.items.reduce((acc, item) => {
    switch (item.type) {
      case 'Service': {
        const result = CheckoutTransactionItem.Service.isPaidWithMembership(
          item,
          transaction.unit,
          transaction.payments,
          transaction.company.company.vatStatus,
          decimals,
        );
        if (result.isPaidWithMembership) {
          const serviceTotals: CheckoutReportTotals<Unit> = CheckoutReportTotals.fromCheckoutTotals(
            transaction,
            result.totals,
          );
          const totalsByType: CheckoutReportTotalsByType<Unit> = {
            all: serviceTotals,
            services: serviceTotals,
            products: CheckoutReportTotals.zero(transaction.unit),
            memberships: CheckoutReportTotals.zero(transaction.unit),
            amounts: CheckoutReportTotals.zero(transaction.unit),
          };

          return add(acc, totalsByType);
        } else {
          return acc;
        }
      }
      case 'Booking': {
        const checkoutTotals = item.items.reduce((acc, item) => {
          switch (item.type) {
            case 'Service': {
              const result = CheckoutTransactionItem.Service.isPaidWithMembership(
                item,
                transaction.unit,
                transaction.payments,
                transaction.company.company.vatStatus,
                decimals,
              );
              if (result.isPaidWithMembership) {
                return CheckoutTotalsScaledNumber.add(acc, result.totals);
              } else {
                return acc;
              }
            }
            case 'Product': {
              const result = CheckoutTransactionItem.Product.isPaidWithMembership(
                item,
                transaction.unit,
                transaction.payments,
                transaction.company.company.vatStatus,
                decimals,
              );
              if (result.isPaidWithMembership) {
                return CheckoutTotalsScaledNumber.add(acc, result.totals);
              } else {
                return acc;
              }
            }
          }
        }, CheckoutTotalsScaledNumber.zero(transaction.unit));

        // Booking currently may only contain services
        const serviceTotals: CheckoutReportTotals<Unit> = CheckoutReportTotals.fromCheckoutTotals(
          transaction,
          checkoutTotals,
        );
        const totalsByType: CheckoutReportTotalsByType<Unit> = {
          all: serviceTotals,
          services: serviceTotals,
          products: CheckoutReportTotals.zero(transaction.unit),
          memberships: CheckoutReportTotals.zero(transaction.unit),
          amounts: CheckoutReportTotals.zero(transaction.unit),
        };

        return add(acc, totalsByType);
      }
      case 'Amount': {
        return acc;
      }
      case 'Product': {
        const result = CheckoutTransactionItem.Product.isPaidWithMembership(
          item,
          transaction.unit,
          transaction.payments,
          transaction.company.company.vatStatus,
          decimals,
        );
        if (result.isPaidWithMembership) {
          const productTotals = CheckoutReportTotals.fromCheckoutTotals(transaction, result.totals);
          const totalsByType: CheckoutReportTotalsByType<Unit> = {
            all: productTotals,
            services: CheckoutReportTotals.zero(transaction.unit),
            products: productTotals,
            memberships: CheckoutReportTotals.zero(transaction.unit),
            amounts: CheckoutReportTotals.zero(transaction.unit),
          };

          return add(acc, totalsByType);
        } else {
          return acc;
        }
      }
      case 'Membership': {
        return acc;
      }
      case 'MembershipInstallment': {
        return acc;
      }
    }
  }, zero(transaction.unit));
};

const fromCheckoutTransaction = <Unit extends MeroUnits.Any>(
  transaction: {
    readonly unit: Unit;
    readonly items: CheckoutTransactionItem.Any<Unit>[];
    readonly payments: CheckoutTransactionPayment.Any<ScaledNumber, Unit>[];
    readonly company: CheckoutTransactionCompany.Company;
    readonly isProtocol: boolean;
    readonly discount?: AppliedDiscount<ScaledNumber, Unit>;
  },
  decimals: number,
  includeItemsPaidWithMembership = false,
): CheckoutReportTotalsByType<Unit> => {
  const totalsExcludingItemsPaidWithMembership = transaction.items.reduce((acc, item) => {
    switch (item.type) {
      case 'Service': {
        const checkoutTotals = CheckoutTransactionItem.Service.getTotals(
          item,
          transaction.unit,
          transaction.payments,
          transaction.company.company.vatStatus,
          decimals,
          false,
        );
        const serviceTotals: CheckoutReportTotals<Unit> = CheckoutReportTotals.fromCheckoutTotals(
          transaction,
          checkoutTotals,
        );
        const totalsByType: CheckoutReportTotalsByType<Unit> = {
          all: serviceTotals,
          services: serviceTotals,
          products: CheckoutReportTotals.zero(transaction.unit),
          memberships: CheckoutReportTotals.zero(transaction.unit),
          amounts: CheckoutReportTotals.zero(transaction.unit),
        };

        return add(acc, totalsByType);
      }
      case 'Booking': {
        const checkoutTotals = item.items.reduce((acc, item) => {
          switch (item.type) {
            case 'Service': {
              return CheckoutTotalsScaledNumber.add(
                acc,
                CheckoutTransactionItem.Service.getTotals(
                  item,
                  transaction.unit,
                  transaction.payments,
                  transaction.company.company.vatStatus,
                  decimals,
                  false,
                ),
              );
            }
            case 'Product': {
              return CheckoutTotalsScaledNumber.add(
                acc,
                CheckoutTransactionItem.Product.getTotals(
                  item,
                  transaction.unit,
                  transaction.payments,
                  transaction.company.company.vatStatus,
                  decimals,
                  false,
                ),
              );
            }
          }
        }, CheckoutTotalsScaledNumber.zero(transaction.unit));

        // Booking currently may only contain services
        const serviceTotals: CheckoutReportTotals<Unit> = CheckoutReportTotals.fromCheckoutTotals(
          transaction,
          checkoutTotals,
        );
        const totalsByType: CheckoutReportTotalsByType<Unit> = {
          all: serviceTotals,
          services: serviceTotals,
          products: CheckoutReportTotals.zero(transaction.unit),
          memberships: CheckoutReportTotals.zero(transaction.unit),
          amounts: CheckoutReportTotals.zero(transaction.unit),
        };

        return add(acc, totalsByType);
      }
      case 'Amount': {
        const checkoutTotals = CheckoutTransactionItem.Amount.getTotals(
          item,
          transaction.unit,
          transaction.company.company.vatStatus,
          decimals,
        );
        const totals: CheckoutReportTotals<Unit> = CheckoutReportTotals.fromCheckoutTotals(transaction, checkoutTotals);
        const totalsByType: CheckoutReportTotalsByType<Unit> = {
          all: totals,
          services: CheckoutReportTotals.zero(transaction.unit),
          products: CheckoutReportTotals.zero(transaction.unit),
          memberships: CheckoutReportTotals.zero(transaction.unit),
          amounts: totals,
        };

        return add(acc, totalsByType);
      }
      case 'Product': {
        const checkoutTotals = CheckoutTransactionItem.Product.getTotals(
          item,
          transaction.unit,
          transaction.payments,
          transaction.company.company.vatStatus,
          decimals,
          false,
        );
        const productTotals: CheckoutReportTotals<Unit> = CheckoutReportTotals.fromCheckoutTotals(
          transaction,
          checkoutTotals,
        );
        const totalsByType: CheckoutReportTotalsByType<Unit> = {
          all: productTotals,
          services: CheckoutReportTotals.zero(transaction.unit),
          products: productTotals,
          memberships: CheckoutReportTotals.zero(transaction.unit),
          amounts: CheckoutReportTotals.zero(transaction.unit),
        };

        return add(acc, totalsByType);
      }
      case 'Membership': {
        const checkoutTotals = CheckoutTransactionItem.Membership.getTotals(
          item,
          transaction.unit,
          transaction.company.company.vatStatus,
          decimals,
        );
        const membershipTotals: CheckoutReportTotals<Unit> = CheckoutReportTotals.fromCheckoutTotals(
          transaction,
          checkoutTotals,
        );
        const totalsByType: CheckoutReportTotalsByType<Unit> = {
          all: membershipTotals,
          services: CheckoutReportTotals.zero(transaction.unit),
          products: CheckoutReportTotals.zero(transaction.unit),
          memberships: membershipTotals,
          amounts: CheckoutReportTotals.zero(transaction.unit),
        };

        return add(acc, totalsByType);
      }
      case 'MembershipInstallment': {
        const checkoutTotals = CheckoutTransactionItem.MembershipInstallment.getTotals(
          item,
          transaction.unit,
          transaction.company.company.vatStatus,
          decimals,
        );
        const membershipTotals: CheckoutReportTotals<Unit> = CheckoutReportTotals.fromCheckoutTotals(
          transaction,
          checkoutTotals,
        );
        const totalsByType: CheckoutReportTotalsByType<Unit> = {
          all: membershipTotals,
          services: CheckoutReportTotals.zero(transaction.unit),
          products: CheckoutReportTotals.zero(transaction.unit),
          memberships: membershipTotals,
          amounts: CheckoutReportTotals.zero(transaction.unit),
        };

        return add(acc, totalsByType);
      }
    }
  }, zero(transaction.unit));

  const applyTransactionDiscount = (totals: CheckoutReportTotalsByType<Unit>): CheckoutReportTotalsByType<Unit> => {
    const discount = transaction.discount;
    if (discount) {
      switch (discount.type) {
        case 'Value': {
          //divide discount value proportionally between transaction items types
          /**
           * Will apply transaction discount proportionally
           */
          const applyScaledDiscount = (totals: CheckoutReportTotals<Unit>): CheckoutReportTotals<Unit> => {
            // For protocol transactions - total is zero, should use protocol value as transaction total
            const { value, total } = transaction.isProtocol
              ? {
                  total: totalsExcludingItemsPaidWithMembership.all.protocol,
                  value: totals.protocol,
                }
              : {
                  total: totalsExcludingItemsPaidWithMembership.all.total,
                  value: totals.total,
                };

            if (MoneyScaledNumber.isZero(total)) {
              // cannot compute discount ratio, will be divided by zero
              return totals;
            }

            const discountValue = AppliedDiscount.value(
              MoneyScaledNumber.div(
                MoneyScaledNumber.mul(discount.value, value.amount),
                total.amount,
                MeroCurrency[transaction.unit].exponent,
              ),
            );

            return CheckoutReportTotals.applyDiscount(totals, discountValue);
          };

          const all = CheckoutReportTotals.applyDiscount(totalsExcludingItemsPaidWithMembership.all, discount);
          const services = applyScaledDiscount(totalsExcludingItemsPaidWithMembership.services);
          const products = applyScaledDiscount(totalsExcludingItemsPaidWithMembership.products);
          /**
           * currently checkout transaction discount is not allowed if there is a membership or
           * membership installment item, so this should never happen
           */
          const memberships = applyScaledDiscount(totalsExcludingItemsPaidWithMembership.memberships);
          const amounts = applyScaledDiscount(totalsExcludingItemsPaidWithMembership.amounts);

          return {
            all: all,
            services: services,
            products: products,
            memberships: memberships,
            amounts: amounts,
          };
        }
        case 'Percent': {
          //apply discount percent to all items
          return {
            all: CheckoutReportTotals.applyDiscount(totalsExcludingItemsPaidWithMembership.all, transaction.discount),
            services: CheckoutReportTotals.applyDiscount(
              totalsExcludingItemsPaidWithMembership.services,
              transaction.discount,
            ),
            products: CheckoutReportTotals.applyDiscount(
              totalsExcludingItemsPaidWithMembership.products,
              transaction.discount,
            ),
            /**
             * currently checkout transaction discount is not allowed if there is a membership or
             * membership installment item, so this should never happen
             */
            memberships: CheckoutReportTotals.applyDiscount(
              totalsExcludingItemsPaidWithMembership.memberships,
              transaction.discount,
            ),
            amounts: CheckoutReportTotals.applyDiscount(
              totalsExcludingItemsPaidWithMembership.amounts,
              transaction.discount,
            ),
          };
        }
      }
    }
    return totals;
  };
  const totalsWithAppliedDiscount = applyTransactionDiscount(totalsExcludingItemsPaidWithMembership);

  return includeItemsPaidWithMembership
    ? add(totalsWithAppliedDiscount, getMembershipPaidItemsTotals(transaction, decimals))
    : totalsWithAppliedDiscount;
};

export const CheckoutReportTotalsByType = {
  fromCheckoutTransaction,
  zero,
  add,
};
