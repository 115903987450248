import { JSONable } from '@mero/shared-sdk';
import * as t from 'io-ts';
import * as tt from 'io-ts-types';

export type Editable = {
  readonly type: 'Editable';
  readonly fields: {
    /**
     * Either can edit appointment start date
     */
    readonly start: boolean;
    /**
     * Either can edit appointment end date
     */
    readonly end: boolean;
    /**
     * Either can edit appointment client
     */
    readonly client: boolean;
    /**
     * Either can edit appointment worker
     */
    readonly worker: boolean;
    /**
     * Either can edit appointment notes
     */
    readonly notes: boolean;
    /**
     * Either can edit appointment services
     */
    readonly services: boolean;
    /**
     * Either can edit recurrence rule
     */
    readonly recurrenceRule: boolean;
  };
  /**
   * Status is valid until this date, after this date reload state from server
   */
  readonly validUntil: Date;
};

const JSON: t.Type<Editable, JSONable> = t.type(
  {
    type: t.literal('Editable'),
    fields: t.type({
      start: t.boolean,
      end: t.boolean,
      client: t.boolean,
      worker: t.boolean,
      notes: t.boolean,
      services: t.boolean,
      recurrenceRule: t.boolean,
    }),
    validUntil: tt.DateFromISOString,
  },
  'Editable',
);

export const Editable = {
  JSON,
};
