import { Firstname, Lastname } from '../common';
import { PageId } from '../pages/page-id';
import { UserId } from '../users/user-id';
import { WorkerId } from './workerId';
import { JSONable, optionull } from '@mero/shared-sdk';
import { ProfileImage } from '@mero/shared-sdk/dist/assets';
import * as t from 'io-ts';

export type WorkerSearchItem = {
  readonly _id: WorkerId;
  readonly user: {
    readonly _id: UserId;
    readonly firstname: Firstname;
    readonly lastname: Lastname;
    readonly fullname: string;
    readonly phone: string;
  };
  readonly profilePhoto: ProfileImage;
  readonly ownPageInfo:
    | {
        readonly _id: PageId;
        readonly name: string;
      }
    | undefined;
};

const JSON: t.Type<WorkerSearchItem, JSONable> = t.type(
  {
    _id: WorkerId.JSON,
    user: t.type({
      _id: UserId,
      firstname: Firstname,
      lastname: Lastname,
      fullname: t.string,
      phone: t.string,
    }),
    profilePhoto: ProfileImage,
    ownPageInfo: optionull(
      t.type({
        _id: PageId,
        name: t.string,
      }),
    ),
  },
  'WorkerSerachItem',
);

export const WorkerSearchItem = {
  JSON,
};
