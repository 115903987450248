import { ProServicesSearchApi } from './proServicesSearchApi';
import { ServiceWithAvailableMemberships } from './serviceWithAvailableMemberships';
import { HttpClient, Paged, UnknownApiError, unsafeRight } from '@mero/shared-sdk';
import * as t from 'io-ts';

export const proServicesSearchHttpClient = (env: { apiBaseUrl: string; http: HttpClient }): ProServicesSearchApi => {
  const { apiBaseUrl, http } = env;

  const searchServicesDecoder = http.decode.response(
    UnknownApiError,
    Paged.json(t.array(ServiceWithAvailableMemberships.JSON)),
  );

  return {
    searchServices: async ({ pageId, filters, clientId, limit, page }) => {
      return unsafeRight(
        await http.request(
          {
            method: 'GET',
            url: `${apiBaseUrl}/pro/pages/${encodeURIComponent(pageId)}/services`,
            params: {
              search: filters?.search,
              workerId: filters?.workerId,
              clientId: clientId,
              limit: limit,
              page: page,
            },
          },
          searchServicesDecoder,
        ),
      );
    },
  };
};
