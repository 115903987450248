import { DefinedString } from '@mero/shared-sdk';
import * as t from 'io-ts';

export interface UnitBrand {
  readonly Unit: unique symbol;
}

/**
 * Opaque measure unit identifier
 */
export type Unit = t.Branded<DefinedString, UnitBrand>;

const JSON: t.Type<Unit, string> = t.brand(DefinedString, (s): s is Unit => true, 'Unit');

const unsafeFrom = (unit: string): Unit => {
  if (!JSON.is(unit)) {
    throw new Error(`Invalid ProductMeasure.Unit value "${unit}"`);
  }

  return unit;
};

export const Unit = {
  JSON,
  unsafeFrom,
};
