import { isDefined } from './utils';
import * as Eq from 'fp-ts/Eq';
import * as t from 'io-ts';

export type HasId<T> = {
  readonly _id: T;
};

/**
 * Build a JSON codec using given {@param idC} codec for `_id` field
 */
const json = <C extends t.Mixed>(idC: C): t.Type<HasId<t.TypeOf<C>>, HasId<t.OutputOf<C>>> =>
  t.type({ _id: idC }, `HasId<${idC.name}>`);

/**
 * Find all {@param items} matching given list of {@param ids} (using {@param eq} to compare IDs)
 */
const findByIds = <T, A extends HasId<T>>(items: A[], ids: T[], eq: Eq.Eq<T>): (A | undefined)[] => {
  return ids.map((id) => items.find((ws) => eq.equals(ws._id, id)));
};

/**
 * Get all {@param items} matching given list of {@param ids} (using {@param eq} to compare IDs)
 *
 * List is returned in exact same order as {@param ids}
 * @throw an {@link Error} if some of the elements was not found in {@param items}
 */
const getByIds = <T, A extends HasId<T>>(items: A[], ids: T[], eq: Eq.Eq<T>): A[] => {
  const found = findByIds(items, ids, eq).filter(isDefined);

  if (found.length !== ids.length) {
    const unavailableIds = ids.filter((id: T) => !found.some((ws) => eq.equals(ws._id, id))).map(String);

    throw Error(`Unable to find elements with _id: ${unavailableIds.join(', ')}`);
  }

  return found;
};

export const HasId = {
  json,
  findByIds,
  getByIds,
};
