import * as React from 'react';
import Svg, { G, Rect, Circle } from 'react-native-svg';

type Props = {
  size?: string | number;
  color?: string;
  disabled?: boolean;
  onPress?: () => void;
};

const AndroidSlider = ({ size = 24, color = '#0481ff', disabled, onPress }: Props) => (
  <Svg width={size} height={size} viewBox={`0 0 ${size} ${size}`} disabled={disabled} onPress={onPress}>
    <G id="ON" transform="translate(-289 -574)">
      <Rect
        id="Rectangle_2588"
        data-name="Rectangle 2588"
        width="24"
        height="24"
        transform="translate(289 574)"
        fill="none"
      />
      <G id="Group_6506" data-name="Group 6506" transform="translate(-2465 17440.396)">
        <Rect
          id="Rectangle_2587"
          data-name="Rectangle 2587"
          width="23"
          height="12"
          rx="6"
          transform="translate(2754 -16860.396)"
          fill={color}
        />
        <G
          id="Ellipse_662"
          data-name="Ellipse 662"
          transform="translate(2764 -16861.396)"
          fill="#fff"
          stroke="#0481ff"
          stroke-width="1"
        >
          <Circle cx="7" cy="7" r="7" stroke="none" />
          <Circle cx="7" cy="7" r="6.5" fill="none" />
        </G>
      </G>
    </G>
  </Svg>
);

export default React.memo(AndroidSlider);
