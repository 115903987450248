import * as t from 'io-ts';

export const DownloadReport = t.type(
  {
    downloadUrl: t.string,
  },
  'DownloadReport',
);

export type DownloadReport = t.TypeOf<typeof DownloadReport>;
