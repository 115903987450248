import { MarketplaceBootApi } from './marketplaceBootApi';
import { MarketplaceBootState } from './marketplaceBootState';
import { HttpClient, UnknownApiError, unsafeRight } from '@mero/shared-sdk';

export const marketplaceBootHttpClient = (env: { apiBaseUrl: string; http: HttpClient }): MarketplaceBootApi => {
  const { apiBaseUrl, http } = env;

  const getBootStateDecoder = http.decode.response(UnknownApiError, MarketplaceBootState.JSON);

  return {
    getBootState: async () => {
      return unsafeRight(
        await http.request(
          {
            method: 'GET',
            url: `${apiBaseUrl}/marketplace/boot`,
          },
          getBootStateDecoder,
        ),
      );
    },
  };
};
