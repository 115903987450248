import * as MeroApi from '@mero/api-sdk';
import * as React from 'react';
import { View } from 'react-native';

import * as colors from '../../../styles/colors';
import sizes from '../../../styles/sizes';
import AppointmentStatus from '../../AppointmentStatus';
import Icon from '../../Icon';
import Line from '../../Line';
import Spacer from '../../Spacer';
import UserAppointmentDate from '../UserAppointmentDate';
import UserAppointmentRecurrenceRule from '../UserAppointmentRecurrenceRule';
import UserAppointmentSummary from '../UserAppointmentSummary';
import { styles } from './styles';

export type Props = {
  /**
   * Appointment to display.
   */
  appointment: MeroApi.calendar.UserAppointment;
  /**
   * Do not show right arrow (item not clickable)
   */
  hideArrow?: boolean;
};

export default function UserAppointmentListItem({ appointment, hideArrow }: Props): React.ReactElement {
  const [isInPast, setIsInPast] = React.useState(false);
  React.useEffect(() => {
    setIsInPast(appointment.start.getTime() < Date.now());
  }, []);
  return (
    <View style={[styles.container]}>
      <View style={[styles.header]}>
        <View>
          <AppointmentStatus status={appointment.status} inPast={isInPast}></AppointmentStatus>
          <Spacer size="4"></Spacer>
          <UserAppointmentDate date={appointment.start} timezone={appointment.timezone}></UserAppointmentDate>
          {!!appointment.recurrenceRule && (
            <>
              <Spacer size="4"></Spacer>
              <UserAppointmentRecurrenceRule
                recurrenceRule={appointment.recurrenceRule}
              ></UserAppointmentRecurrenceRule>
            </>
          )}
        </View>
        {!hideArrow ? (
          <View style={styles.iconContainer}>
            <Icon type="next" size={sizes[24]} color={colors.DARK_BLUE}></Icon>
          </View>
        ) : null}
      </View>

      <Spacer size="16"></Spacer>
      <Line color="#E9ECEF"></Line>
      <Spacer size="16"></Spacer>

      <UserAppointmentSummary appointment={appointment}></UserAppointmentSummary>
    </View>
  );
}
