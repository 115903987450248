import * as t from 'io-ts';
import { DateFromISOString } from 'io-ts-types';

export const AvailableDay = t.type(
  {
    date: DateFromISOString,
    available: t.boolean,
    workingDay: t.boolean,
  },
  'AvailableDay',
);

export type AvailableDay = t.TypeOf<typeof AvailableDay>;

export const AvailableDayArray = t.array(AvailableDay);

export type AvailableDayArray = t.TypeOf<typeof AvailableDayArray>;
