import { HasOptionalFirstLastName } from '../common';
import { SavedWorker } from './savedWorker';
import { WorkerId } from './workerId';
import { Firstname, HasId, JSONable, Lastname } from '@mero/shared-sdk';
import { ProfileImage } from '@mero/shared-sdk/dist/assets/profile-image';
import * as t from 'io-ts';

/**
 * @deprecated use SavedWorkerDirectly
 */
export type WorkerPreview = {
  readonly _id: WorkerId;
  readonly firstname?: Firstname;
  readonly lastname?: Lastname;
  readonly profilePhoto?: ProfileImage;
};

/**
 * @deprecated use SavedWorkerDirectly
 */
const JSON: t.Type<WorkerPreview, JSONable> = t.intersection(
  [
    HasId.json(WorkerId.JSON),
    HasOptionalFirstLastName,
    t.partial(
      {
        profilePhoto: ProfileImage,
      },
      '?',
    ),
  ],
  'WorkerPreview',
);

/**
 * @deprecated use SavedWorkerDirectly
 */
const fromSavedWorker = (w: SavedWorker): WorkerPreview => ({
  _id: w._id,
  firstname: w.user.firstname,
  lastname: w.user.lastname,
  profilePhoto: w.profilePhoto,
});

export const WorkerPreview = {
  JSON,
  fromSavedWorker,
};

/**
 * @deprecated use SavedWorkerDirectly
 */
export const workerPreview = {
  /**
   * @deprecated use SavedWorkerDirectly
   */
  fromSavedWorker: (w: SavedWorker): WorkerPreview => ({
    _id: w._id,
    firstname: w.user.firstname,
    lastname: w.user.lastname,
    profilePhoto: w.profilePhoto,
  }),
};
