import { ObjectId } from '@mero/shared-sdk';
import * as t from 'io-ts';

export interface AppointmentIntentIdBrand {
  readonly AppointmentIntentId: unique symbol;
}

export type AppointmentIntentId = t.Branded<ObjectId, AppointmentIntentIdBrand>;

const JSON = t.brand(ObjectId, (_id: ObjectId): _id is AppointmentIntentId => true, 'AppointmentIntentId');

export const AppointmentIntentId = {
  JSON,
};
