import { JSONable } from '../common';
import { Money } from '../money';
import { Numbers } from '../numbers';
import { MeroCurrency } from './meroCurrency';
import { MeroUnits } from './meroUnits';
import * as t from 'io-ts';

export type Currency = MeroUnits.EUR | MeroUnits.RON;

/**
 * Get type of {@link MeroMoney} for given {@link Unit}
 */
export type Of<Unit extends MeroUnits.Any> = Money<number, Unit>;

/**
 * Any mero money supported
 */
export type Any = Of<MeroUnits.EUR> | Of<MeroUnits.RON>;

export const json = <Unit extends MeroUnits.Any>(unit: Unit): t.Type<Of<Unit>, JSONable> => {
  return t.type(
    {
      amount: t.number,
      unit: t.literal(unit),
    },
    `MeroMoney<${unit}>`,
  );
};

export const JSON = t.type(
  {
    amount: t.number,
    unit: MeroUnits.JSON,
  },
  'MeroMoney',
);

export const of = <Unit extends MeroUnits.Any>(amount: number, unit: Unit): Of<Unit> => {
  return {
    amount,
    unit,
  };
};

/**
 * Round {@link amount} to max {@link unit} decimal positions, ex: 2 decimals for RON
 */
export const roundedOf = <Unit extends MeroUnits.Any>(amount: number, unit: Unit): Of<Unit> => {
  return of(MeroCurrency[unit].roundToFixed(amount), unit);
};

/**
 * Rounds given {@link money.amount} to the least significant digit, based un {@link money.unit}
 * ex. 1/100 for EUR/cent
 */
export const roundToFixed = <Unit extends MeroUnits.Any>(money: Of<Unit>): Of<Unit> => {
  return roundedOf(money.amount, money.unit);
};

export const getAmountFixed = <Unit extends MeroUnits.Any>(money: Of<Unit>): string => {
  return MeroCurrency[money.unit].toFixed(money.amount);
};

export const ron = (amount: number): Of<MeroUnits.RON> => of(amount, MeroUnits.RON.code);

export const eur = (amount: number): Of<MeroUnits.EUR> => of(amount, MeroUnits.EUR.code);

/**
 * Compares two {@link MeroMoney} instances to be equals down to least significant digit
 */
export const equalsDownToFixed = (a: Any, b: Any): boolean => {
  if (a.unit !== b.unit) {
    return false;
  }

  const fixedA = getAmountFixed(a);
  const fixedB = getAmountFixed(b);

  return fixedA === fixedB;
};

const MeroMoneyM = Money.build(Numbers, MeroUnits);

export const zero = MeroMoneyM.zero;
export const add = MeroMoneyM.add;
export const sub = MeroMoneyM.sub;
export const mul = MeroMoneyM.mul;
export const isZero = MeroMoneyM.isZero;
export const hasUnit = MeroMoneyM.hasUnit;
export const equals = MeroMoneyM.equals;
