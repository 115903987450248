import * as t from 'io-ts';

export type Active = 'Active';

export const Active: ProductStatusModule<Active> = {
  code: 'Active',
  JSON: t.literal('Active'),
  is: (status: string): status is Active => status === Active.code,
};

export type Inactive = 'Inactive';

export const Inactive: ProductStatusModule<Inactive> = {
  code: 'Inactive',
  JSON: t.literal('Inactive'),
  is: (status: string): status is Inactive => status === Inactive.code,
};

export type Deleted = 'Deleted';

export const Deleted: ProductStatusModule<Deleted> = {
  code: 'Deleted',
  JSON: t.literal('Deleted'),
  is: (status: string): status is Deleted => status === Deleted.code,
};

type ProductStatusModule<T extends Any> = {
  /**
   * Status code
   */
  readonly code: T;
  /**
   * JSON codec for the status
   */
  readonly JSON: t.Type<T>;
  /**
   * Check if {@link status} is of the given {@link T} type
   */
  readonly is: (status: string) => status is T;
};

export type Any = Active | Inactive | Deleted;

export const JSON = t.union([Active.JSON, Inactive.JSON, Deleted.JSON], 'ProductStatus');

export const is = (unit: string): unit is Any => JSON.is(unit);

export const equals = <A extends Any>(a: A, b: Any): b is A => a === b;
