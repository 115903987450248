import { FeatureFlagsMp } from '../marketplace';
import { FeatureFlagsPro } from '../pro';
import { FeatureFlagsApi } from './feature-flags-api';
import { HttpClient, UnknownApiError, unsafeRight } from '@mero/shared-sdk';

export const featureFlagsHttpClient = (env: { apiBaseUrl: string; http: HttpClient }): FeatureFlagsApi => {
  const { apiBaseUrl, http } = env;

  const featureFlagsProDecoder = http.decode.response(UnknownApiError, FeatureFlagsPro);
  const featureFlagsMpDecoder = http.decode.response(UnknownApiError, FeatureFlagsMp);

  return {
    getFeatureFlagsPro: async () => {
      return unsafeRight(
        await http.request(
          {
            method: 'GET',
            url: `${apiBaseUrl}/feature-flags/pro`,
          },
          featureFlagsProDecoder,
        ),
      );
    },
    getFeatureFlagsMp: async () => {
      return unsafeRight(
        await http.request(
          {
            method: 'GET',
            url: `${apiBaseUrl}/feature-flags/mp`,
          },
          featureFlagsMpDecoder,
        ),
      );
    },
  };
};
