import { AppointmentId } from '../../calendar';
import { ProClientFeedbackApi } from './clientFeedbackApi';
import { ClientFeedbackDetails } from './clientFeedbackDetails';
import { HttpClient, ReviewScore, UnknownApiError, optionull, unsafeRight } from '@mero/shared-sdk';
import * as t from 'io-ts';

export const proClientFeedbackHttpClient = (env: { apiBaseUrl: string; http: HttpClient }): ProClientFeedbackApi => {
  const { apiBaseUrl, http } = env;

  const createClientFeedbackDecoder = http.decode.response(UnknownApiError, t.unknown);
  const getAppointmentClientFeedbackDecoder = http.decode.response(
    UnknownApiError,
    t.type({
      canCreateOrUpdateClientFeedback: t.boolean,
      clientFeedback: optionull(ClientFeedbackDetails.JSON),
    }),
  );
  const updateClientFeedbackDecoder = http.decode.response(UnknownApiError, t.unknown);
  const deleteClientFeedbackDecoder = http.decode.response(UnknownApiError, t.unknown);

  return {
    createClientFeedback: async ({ pageId, appointmentId, occurrenceIndex, feedback }) => {
      const requestBody: {
        readonly appointmentId: AppointmentId;
        readonly occurrenceIndex: number;
        readonly feedback: {
          readonly score: ReviewScore;
          readonly review: string | undefined;
        };
      } = {
        appointmentId: appointmentId,
        occurrenceIndex: occurrenceIndex,
        feedback: feedback,
      };

      unsafeRight(
        await http.request(
          {
            method: 'POST',
            url: `${apiBaseUrl}/pro/page/${encodeURIComponent(pageId)}/client-feedback`,
            data: requestBody,
          },
          createClientFeedbackDecoder,
        ),
      );
    },
    getAppointmentClientFeedback: async ({ pageId, appointmentId, occurrenceIndex }) => {
      return unsafeRight(
        await http.request(
          {
            method: 'GET',
            url: `${apiBaseUrl}/pro/page/${encodeURIComponent(pageId)}/appointment/${encodeURIComponent(
              appointmentId,
            )}/client-feedback`,
            params: {
              occurrenceIndex: occurrenceIndex,
            },
          },
          getAppointmentClientFeedbackDecoder,
        ),
      );
    },
    updateClientFeedback: async ({ pageId, clientFeedbackId, feedback }) => {
      const requestBody: {
        readonly feedback: {
          readonly score: ReviewScore;
          readonly review: string | undefined;
        };
      } = {
        feedback: feedback,
      };

      unsafeRight(
        await http.request(
          {
            method: 'PUT',
            url: `${apiBaseUrl}/pro/page/${encodeURIComponent(pageId)}/client-feedback/${encodeURIComponent(
              clientFeedbackId,
            )}`,
            data: requestBody,
          },
          updateClientFeedbackDecoder,
        ),
      );
    },
    deleteClientFeedback: async ({ pageId, clientFeedbackId }) => {
      unsafeRight(
        await http.request(
          {
            method: 'DELETE',
            url: `${apiBaseUrl}/pro/page/${encodeURIComponent(pageId)}/client-feedback/${encodeURIComponent(
              clientFeedbackId,
            )}`,
          },
          deleteClientFeedbackDecoder,
        ),
      );
    },
  };
};
