import * as React from 'react';
import Svg, { Path, G } from 'react-native-svg';

import { BLACK } from '../../../styles/colors';

type Props = {
  size?: number | string;
  color?: string;
  disabled?: boolean;
  onPress?: () => void;
};

const Mail: React.FC<Props> = ({ size = 32, color = BLACK, disabled = false, onPress }) => (
  <Svg width={size} height={size} viewBox="0 0 24 24" disabled={disabled} onPress={onPress}>
    <G transform="translate(-24 -780)">
      <G transform="translate(24 780)">
        <Path d="M0,0H24V24H0Z" fill="none" />
        <G transform="translate(1 -6)">
          <Path
            d="M2,12l9,8.22L20,12"
            fill="none"
            stroke={color}
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeMiterlimit="10"
            strokeWidth="1.2"
          />
          <Path
            d="M2,12H20V24H2Z"
            fill="none"
            stroke={color}
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeMiterlimit="10"
            strokeWidth="1.2"
          />
        </G>
      </G>
    </G>
  </Svg>
);

export default React.memo(Mail);
