import { ProUserSatisfactionApi } from './proUserSatisfactionApi';
import { HttpClient, UnknownApiError, unsafeRight } from '@mero/shared-sdk';
import * as t from 'io-ts';

export const proUserSatisfactionHttpClient = (env: {
  apiBaseUrl: string;
  http: HttpClient;
}): ProUserSatisfactionApi => {
  const { apiBaseUrl, http } = env;

  const addResponseDecoder = http.decode.response(UnknownApiError, t.unknown);
  const canUserAddResponseDecoder = http.decode.response(UnknownApiError, t.type({ allowed: t.boolean }));
  return {
    canUserRespond: async (pageId) => {
      return unsafeRight(
        await http.request(
          {
            method: 'GET',
            url: `${apiBaseUrl}/pro/pages/${encodeURIComponent(pageId)}/user-satisfaction/can-respond`,
          },
          canUserAddResponseDecoder,
        ),
      );
    },

    addResponse: async ({ pageId, response }) => {
      unsafeRight(
        await http.request(
          {
            method: 'POST',
            url: `${apiBaseUrl}/pro/pages/${encodeURIComponent(pageId)}/user-satisfaction`,
            data: { response },
          },
          addResponseDecoder,
        ),
      );
    },
  };
};
