import { RecurrenceEndCriteria } from '../../recurrenceEndCriteria';
import { RecurrenceInterval } from '../../recurrenceInterval';
import * as t from 'io-ts';

export type MonthlyIndefinite = {
  readonly repeatEvery: RecurrenceInterval.Monthly;
  readonly endsOn: RecurrenceEndCriteria.Indefinite;
};

export const MONTHLY_INDEFINITE: MonthlyIndefinite = {
  repeatEvery: RecurrenceInterval.MONTHLY,
  endsOn: RecurrenceEndCriteria.INDEFINITE,
};

const JSON = t.type(
  {
    repeatEvery: RecurrenceInterval.Monthly.JSON,
    endsOn: RecurrenceEndCriteria.Indefinite.JSON,
  },
  'MonthlyIndefiniteRecurrenceRule',
);

export const MonthlyIndefinite = {
  JSON,
  is: JSON.is,
};
