import { GiftCard } from '../../giftCards/gift-card';
import { MarketplaceGiftCardsApi } from './marketplaceGiftCardsApi';
import { HttpClient, UnknownApiError, unsafeRight } from '@mero/shared-sdk';
import * as t from 'io-ts';

export const marketplaceGiftCardsHttpClient = (env: {
  apiBaseUrl: string;
  http: HttpClient;
}): MarketplaceGiftCardsApi => {
  const { apiBaseUrl, http } = env;

  const createNewGiftCardDecoder = http.decode.optionalResponse(
    UnknownApiError,
    t.type({
      result: GiftCard,
    }),
  );

  return {
    createNewGiftCard: async ({ price, pageId }) =>
      unsafeRight(
        await http.request(
          {
            method: 'POST',
            url: `${apiBaseUrl}/business/gift-cards`,
            data: {
              price,
              pageId,
            },
          },
          createNewGiftCardDecoder,
        ),
      ).result,
  };
};
