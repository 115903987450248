import { JSONable } from '@mero/shared-sdk';
import * as t from 'io-ts';

export type Rejected = 2;

const VALUE: Rejected = 2;

const JSON: t.Type<Rejected, JSONable> = t.literal(VALUE);

export const Rejected = {
  JSON,
  VALUE,
};
