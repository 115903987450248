import * as React from 'react';
import Svg, { Rect, Path, G } from 'react-native-svg';

const DEFAULT_COLOR = '#394553';

type Props = {
  size?: number | string;
  color?: string;
  disabled?: boolean;
  onPress?: () => void;
};

const Clock: React.FC<Props> = ({ size = 32, color = DEFAULT_COLOR, disabled, onPress }: Props) => (
  <Svg width={size} height={size} viewBox="0 0 24 24" disabled={disabled} onPress={onPress}>
    <G transform="translate(-24 -2415)">
      <G transform="translate(24 2415)">
        <Rect width={size} height={size} fill="none" />
        <G transform="translate(6 6)">
          <G transform="translate(4.212 3.123)">
            <Path
              d="M71.94,54.875v3.408l-2.127,1.251"
              transform="translate(-69.813 -54.875)"
              fill="none"
              stroke={color}
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="1.5"
            />
          </G>
          <Path
            d="M66.344,50.9A6.245,6.245,0,1,1,60.1,44.652,6.245,6.245,0,0,1,66.344,50.9Z"
            transform="translate(-53.854 -44.652)"
            fill="none"
            stroke={color}
            strokeLinejoin="round"
            strokeWidth="1.5"
          />
        </G>
      </G>
    </G>
  </Svg>
);

export default React.memo(Clock);
