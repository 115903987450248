import * as React from 'react';
import { View } from 'react-native';

import SafeAreaView from '../SafeAreaView';
import { NewToast, ToastContext } from './context';
import { Toast, ToastProps } from './toast';
import { styles } from './toastContainer.style';

export { useToast } from './context';

type ToastProviderProps = React.PropsWithChildren<{
  dismissAfterSeconds: number;
}>;

/**
 * Toast provider implementation.
 */
export const ToastProvider: React.FC<ToastProviderProps> = ({ children, dismissAfterSeconds }) => {
  const [activeToasts, setActiveToasts] = React.useState<ToastProps[]>([]);

  const dismissToast = React.useCallback(
    (id: number) => {
      setActiveToasts((toasts) => toasts.filter((t) => t.id !== id));
    },
    [setActiveToasts],
  );

  const showNewToast = React.useCallback(
    (newToast: NewToast) => {
      const toastId = Date.now(); // pretty much random, good enough for now
      const toast: Toast = {
        ...newToast,
        autoDismiss: newToast.autoDismiss ?? true, // default to autodismiss
        manualDismiss: newToast.manualDismiss ?? true,
        id: toastId,
        onDismiss: () => dismissToast(toastId),
      };
      setActiveToasts((toasts) => toasts.concat([toast]));

      if (toast.autoDismiss) {
        setTimeout(() => dismissToast(toastId), dismissAfterSeconds * 1000);
      }

      // return toast id in order to be able to dismiss
      // a toast programmatically if needed
      return toastId;
    },
    [children, setActiveToasts, dismissToast],
  );

  /**
   * Build the context value and memoize it since
   * providing a new value on next rendering (like when adding a toast :/)
   * will cause the children to re-render
   */
  const contextValue = React.useMemo(
    () => ({ show: showNewToast, dismiss: dismissToast }),
    [showNewToast, dismissToast],
  );

  return (
    <ToastContext.Provider value={contextValue}>
      {/* render other children */}
      {children}

      {/* render toast stack */}
      <View style={styles.container}>
        {/* when we have something to show, also add a safe area */}
        {activeToasts.length > 0 ? <SafeAreaView edges={['top']} /> : null}

        {activeToasts.map((toastProps) => (
          <View key={toastProps.id}>
            <Toast {...toastProps} />
          </View>
        ))}
      </View>
    </ToastContext.Provider>
  );
};
