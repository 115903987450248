import * as React from 'react';
import { View, ViewStyle, StyleProp } from 'react-native';

type Props = React.PropsWithChildren<{
  style?: StyleProp<ViewStyle>;
  leftContainerStyle?: StyleProp<ViewStyle>;
  LeftComponent?: React.ComponentType;
  centerContainerStyle?: StyleProp<ViewStyle>;
  rightContainerStyle?: StyleProp<ViewStyle>;
  RightComponent?: React.ComponentType;
}>;

const ListItem: React.FC<Props> = ({
  style,
  leftContainerStyle,
  centerContainerStyle,
  LeftComponent,
  rightContainerStyle,
  RightComponent,
  children,
}: Props) => {
  return (
    <View style={[{ flexDirection: 'row' }, style]}>
      {(LeftComponent !== undefined || leftContainerStyle !== undefined) && (
        <View
          style={[
            {
              flexDirection: 'column',
            },
            leftContainerStyle,
          ]}
        >
          {LeftComponent && <LeftComponent />}
        </View>
      )}
      <View style={[{ flex: 1 }, centerContainerStyle]}>{children}</View>
      {(RightComponent !== undefined || rightContainerStyle !== undefined) && (
        <View
          style={[
            {
              flexDirection: 'column',
            },
            rightContainerStyle,
          ]}
        >
          {RightComponent && <RightComponent />}
        </View>
      )}
    </View>
  );
};

export default ListItem;
