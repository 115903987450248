import { ConsumedProductPayload } from './consumedProductPayload';
import { JSONable } from '@mero/shared-sdk';
import * as t from 'io-ts';

export type ConsumedProductsPayload = ConsumedProductPayload[];

const JSON: t.Type<ConsumedProductsPayload, JSONable> = t.array(ConsumedProductPayload.JSON, 'ConsumedProductsPayload');

export const ConsumedProductsPayload = {
  JSON,
};
