import { JSONable } from '@mero/shared-sdk';
import * as t from 'io-ts';

export type PageBillingDetailsUsageLabel = string;

const JSON: t.Type<PageBillingDetailsUsageLabel, JSONable> = t.string;

const MERO_SUBSCRIPTION = 'MeroSubscription' as const;
/**
 * @deprecated
 */
const MERO_GIFT_CARDS = 'MeroGiftCards' as const;
const USER_PAYMENTS = 'UserPayments' as const;

export const PageBillingDetailsUsageLabel = {
  JSON,
  MERO_SUBSCRIPTION,
  MERO_GIFT_CARDS,
  USER_PAYMENTS,
};
