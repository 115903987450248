import * as React from 'react';
import Svg, { Path } from 'react-native-svg';

import { BLACK } from '../../../styles/colors';

type Props = {
  color?: string;
  disabled?: boolean;
  size?: number | string;
  onPress?: () => void;
};

const HomeMenu: React.FC<Props> = ({ size = 24, color = BLACK, disabled = false, onPress }) => (
  <Svg width={size} height={size} viewBox={`0 0 ${size} ${size}`} disabled={disabled} onPress={onPress}>
    <Path
      d="M13.608,4,2.4,15.6a.095.095,0,0,0,.066.163h2.8V26h5.69V19.552h5.31V26h5.69V15.759h2.8a.095.095,0,0,0,.066-.163Z"
      transform="translate(-1.505 -3.15)"
      fill="none"
      stroke={color}
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeMiterlimit="10"
      strokeWidth="1.7"
    />
  </Svg>
);

export default React.memo(HomeMenu);
