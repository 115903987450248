import * as React from 'react';
import Svg, { Path } from 'react-native-svg';

type Props = {
  size?: number | string;
  color?: string;
  disabled?: boolean;
  onPress?: () => void;
};

const ProMenuTeam = ({ size = 32, color = '#000000', disabled, onPress }: Props) => (
  <Svg width={size} height={size} viewBox="0 0 32 32" disabled={disabled} onPress={onPress}>
    <Path
      d="M16.5,13A11.987,11.987,0,0,0,12,14a11.8,11.8,0,0,0-4.5-1C5.33,13,1,14.08,1,16.25V19H23V16.25C23,14.08,18.67,13,16.5,13Zm-4,4.5H2.5V16.25c0-.54,2.56-1.75,5-1.75s5,1.21,5,1.75Zm9,0H14V16.25a1.819,1.819,0,0,0-.52-1.22,9.647,9.647,0,0,1,3.02-.53c2.44,0,5,1.21,5,1.75ZM7.5,12A3.5,3.5,0,1,0,4,8.5,3.5,3.5,0,0,0,7.5,12Zm0-5.5a2,2,0,1,1-2,2A2.006,2.006,0,0,1,7.5,6.5Zm9,5.5A3.5,3.5,0,1,0,13,8.5,3.5,3.5,0,0,0,16.5,12Zm0-5.5a2,2,0,1,1-2,2A2.006,2.006,0,0,1,16.5,6.5Z"
      transform="translate(4 4)"
      fill={color}
    />
  </Svg>
);

export default React.memo(ProMenuTeam);
