import * as t from 'io-ts';

/**
 * New stock is added to the inventory
 */
export const New = t.literal('New');
export type New = t.TypeOf<typeof New>;

/**
 * Stock is returned by the customer back to the inventory
 */
export const CustomerReturn = t.literal('CustomerReturn');
export type CustomerReturn = t.TypeOf<typeof CustomerReturn>;

/**
 * Stock is transfered from another inventory to this one
 */
export const TransferredTo = t.literal('TransferredTo');
export type TransferredTo = t.TypeOf<typeof TransferredTo>;

/**
 * Stock is adjusted to reflect reality
 */
export const Adjusted = t.literal('Adjusted');
export type Adjusted = t.TypeOf<typeof Adjusted>;

/**
 * Other reason
 */
export const Other = t.type({ type: t.literal('Other'), value: t.string });
export type Other = t.TypeOf<typeof Other>;

export type All = New | CustomerReturn | TransferredTo | Adjusted | Other;

export const All = {
  JSON: t.union([New, CustomerReturn, TransferredTo, Adjusted, Other], 'All'),
  list: () => ['New', 'CustomerReturn', 'TransferredTo', 'Adjusted', 'Other'],
};
