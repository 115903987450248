import * as React from 'react';
import { TextProps, Text } from 'react-native';

import { styles } from '../../styles/text';

export type H3sProps = React.PropsWithChildren<TextProps>;

const H3s: React.FC<H3sProps> = (props: H3sProps) => (
  // This style of settings props avoids generation of _rest helper function in js output
  <Text accessibilityRole="header" aria-level="3" {...props} style={[styles.h3s, props.style]}>
    {props.children}
  </Text>
);

export default H3s;
