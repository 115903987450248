import * as t from 'io-ts';

export const Tracking = t.partial(
  {
    pixel: t.string,
  },
  'Tracking',
);

export type Tracking = t.TypeOf<typeof Tracking>;
