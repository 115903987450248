import { JSONable } from './jsonable';
import * as t from 'io-ts';

type Asc = 'ASC';
type Desc = 'DESC';

export type SortDirection = Asc | Desc;

const ASC: Asc = 'ASC';
const DESC: Desc = 'DESC';

const JSON: t.Type<SortDirection, JSONable> = t.union([t.literal(ASC), t.literal(DESC)], 'SortDirection');

export const SortDirection = {
  ASC,
  DESC,
  JSON,
};
