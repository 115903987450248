import * as MeroApi from '@mero/api-sdk';
import * as React from 'react';
import { View } from 'react-native';

import { formatTimeDiff } from '../../../utils/format';
import Icon from '../../Icon';
import Body from '../../Text/Body';
import SmallBody from '../../Text/SmallBody';
import { styles } from '../styles';

export type Props = {
  readonly notification: MeroApi.notifications.GenericProNotification;
};

const GenericProNotification = ({ notification }: Props): React.ReactElement | null => {
  const { payload } = notification;

  return (
    <View style={styles.notificationContainer}>
      <Icon type="notification_client_accepted" />
      <View style={styles.notificationBody}>
        <Body>{payload.text}</Body>
        <SmallBody style={styles.timeText}>{formatTimeDiff(notification.createdAt, new Date())}</SmallBody>
      </View>
    </View>
  );
};

export default GenericProNotification;
