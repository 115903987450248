import { IApiConfig } from '.';
import { request, requestWithAuth } from './utils';

export interface IApp {
  _id: string;
  name: string;
  domain: string;
  redirectUris: string[];
}

export class AppsApi {
  /**
   * Class constructor.
   */
  constructor(private _config: IApiConfig) {}

  /**
   * Get an app public profile by id.
   */
  async getAppProfile(id: string): Promise<{
    _id: string;
    name: string;
  }> {
    const data = await request({
      config: this._config,
      method: 'GET',
      path: `/apps/${id}/profile`,
    });

    return data.result;
  }

  /**
   * Get an app access token.
   */
  async getAppAccessToken(): Promise<string> {
    const data = await request({
      config: this._config,
      method: 'POST',
      path: '/apps/token',
      body: {
        appId: this._config.appId,
        appSecret: this._config.appSecret,
      },
    });

    return data.result;
  }

  /**
   * Get client info.
   */
  async getClientProfile(params: { clientToken: string }) {
    const data = await requestWithAuth({
      config: this._config,
      method: 'GET',
      path: '/apps/clients/me',
      query: params,
    });

    return data.result;
  }
}
