import * as React from 'react';
import { TouchableOpacity } from 'react-native';

import { CADET_BLUE, DARK_BLUE, RADICAL_RED } from '../../styles/colors';
import CheckedIcon from '../Icon/Checked';
import UncheckedIcon from '../Icon/Unchecked';

export type Props = {
  value?: boolean;
  color?: 'blue' | 'red';
  disabled?: boolean;
  onValueChange?: (checked: boolean) => void;
};

function CheckboxInner({ value = false, color, disabled, onValueChange }: Props): React.ReactElement {
  const onClick = React.useCallback(() => {
    const newValue = !value;

    if (onValueChange) {
      onValueChange(newValue);
    }
  }, [value, onValueChange]);

  const colorValue = disabled ? CADET_BLUE : color === 'red' ? RADICAL_RED : DARK_BLUE;

  return (
    <TouchableOpacity onPress={onClick} disabled={disabled}>
      {/* Not using onClick on Icon (on Svg) as it generates an error on web :( */}
      {value ? (
        <CheckedIcon disabled={true} color={colorValue} />
      ) : (
        <UncheckedIcon disabled={true} color={colorValue} />
      )}
    </TouchableOpacity>
  );
}

const Checkbox: React.FC<Props> = ({ value, color = 'blue', disabled = false, onValueChange }) => {
  // When value provided - invalidate on its change
  return (
    <CheckboxInner
      key={value !== undefined ? `${value}` : undefined}
      value={value}
      color={color}
      disabled={disabled}
      onValueChange={onValueChange}
    />
  );
};

export default Checkbox;
