import { HasOptionalFirstLastName } from '../common';
import { Coupon } from '../coupons';
import { PageId } from '../pages';
import { ServicePreview } from '../services';
import { UserId } from '../users';
import { WorkerId } from '../workers';
import { AppointmentId } from './appointment-id';
import { AppointmentStatus } from './appointment-status';
import { CalendarId } from './calendar-id';
import { CalendarSettings } from './calendar-settings';
import { FeedbackId, Option } from '@mero/shared-sdk';
import { HasId, Location, RecurrenceRule, optionull } from '@mero/shared-sdk';
import { ProfileImage } from '@mero/shared-sdk/dist/assets';
import * as t from 'io-ts';
import { DateFromISOString } from 'io-ts-types';

export const UserAppointment = t.intersection(
  [
    t.type({
      _id: AppointmentId,
      occurrenceIndex: optionull(t.number),
      calendarId: CalendarId,
      userId: UserId,
      start: DateFromISOString,
      end: DateFromISOString,
      recurrent: t.boolean,
      timezone: t.string,
      status: AppointmentStatus,
      page: t.intersection(
        [
          t.type({
            _id: PageId,
            name: t.string,
            slug: t.string,
            profilePhoto: ProfileImage,
          }),
          t.partial({
            phone: t.string,
            hasOnlinePaymentsEnabled: t.boolean,
            location: optionull(Location),
          }),
        ],
        'UserAppointmentPage',
      ),
      worker: t.intersection([
        HasId.json(WorkerId.JSON),
        t.type({
          profilePhoto: ProfileImage,
        }),
        t.partial({
          calendar: t.type({
            _id: CalendarId,
            settings: CalendarSettings,
          }),
        }),
        HasOptionalFirstLastName,
      ]),
      bookedServices: t.array(ServicePreview),
    }),
    t.partial({
      recurrenceRule: optionull(RecurrenceRule.JSON),
      // TODO: move this type to reviews folder or something
      feedback: t.intersection([
        t.partial({ _id: FeedbackId }),
        t.type({
          score: t.number,
        }),
        t.partial({
          review: t.string,
          isAnonymous: t.boolean,
        }),
      ]),
      canAddOrEditFeedback: t.boolean,
      canReport: t.boolean,
      coupons: Option.json(t.array(Coupon.JSON)),
      cancelInfo: t.intersection([
        t.type({
          by: t.union([t.literal('pro'), t.literal('client')]),
        }),
        t.partial({
          at: DateFromISOString,
          reason: t.string,
        }),
      ]),
    }),
  ],
  'UserAppointment',
);

export type UserAppointment = t.TypeOf<typeof UserAppointment>;

export const UserAppointmentArray = t.array(UserAppointment);

export type UserAppointmentArray = t.TypeOf<typeof UserAppointment>;
