import { StyleSheet } from 'react-native';

import * as colors from '../../styles/colors';
import sizes from '../../styles/sizes';

export const styles = StyleSheet.create({
  infoContainer: {
    paddingLeft: sizes['24'],
    paddingRight: sizes['24'],
    paddingTop: sizes['16'],
    paddingBottom: sizes['16'],
    backgroundColor: colors.COMET,
  },
  errorContainer: {
    paddingLeft: sizes['24'],
    paddingRight: sizes['24'],
    paddingTop: sizes['16'],
    paddingBottom: sizes['16'],
    backgroundColor: colors.RADICAL_RED,
  },
  text: {
    color: colors.WHITE,
  },
  buttonsRow: {
    paddingTop: sizes['12'],
    width: '100%',
    flexDirection: 'row',
  },
  buttonBox: {
    flex: 1,
  },
});
