import { ImageServiceRef } from './imageServiceRef';
import { JSONable, ResponsiveImage } from '@mero/shared-sdk';
import * as t from 'io-ts';

export type PageImage = ResponsiveImage & {
  readonly services?: ImageServiceRef[];
};

const JSON: t.Type<PageImage, JSONable> = t.intersection(
  [
    ResponsiveImage,
    t.partial(
      {
        services: t.array(ImageServiceRef.JSON),
      },
      '?',
    ),
  ],
  'PageImage',
);

export const PageImage = {
  JSON,
};
