import * as t from 'io-ts';

/**
 * @deprecated use SubscriptionTierId
 */
const TRIAL = 0;
/**
 * @deprecated use SubscriptionTierId
 */
const PAY_PER_SEAT = 5;
/**
 * @deprecated use SubscriptionTierId
 */
const BOOST = 6;

/**
 * @deprecated use SubscriptionTierId
 */
const JSON = t.number;

/**
 * @deprecated use SubscriptionTierId
 */
export type SubscriptionTier = number;

/**
 * @deprecated use SubscriptionTierId
 */
export const SubscriptionTier = {
  TRIAL,
  PAY_PER_SEAT,
  BOOST,
  JSON,
};
