import * as React from 'react';
import { View, TextInput, Text, TextStyle, Pressable } from 'react-native';

import useKeyboardIsOpen from '../../hooks/useKeyboardIsOpen';

import { styles } from './styles';

export type Props = {
  onFill: (pin: string) => void;
  status?: 'active' | 'waiting' | 'success' | 'fail';
};

export default function PinInput({ onFill, status = 'active' }: Props): React.ReactElement {
  const keyboardIsOpen = useKeyboardIsOpen();

  const len = 6;
  const [pin, setPin] = React.useState('');
  const inputEl = React.useRef<TextInput>(null);

  const onTextChange = (newPin: string) => {
    if (status !== 'active') {
      return;
    }

    const numericPin = newPin.replace(/\D/g, '');
    if (numericPin.length <= len) {
      setPin(numericPin);
      if (numericPin.length == len) {
        setTimeout(() => {
          onFill(numericPin);
          setPin('');
        }, 300);
      }
    }
  };

  const onBlurHandler = () => {
    inputEl.current?.focus();
  };

  const textStyle: TextStyle = {
    fontSize: 24,
  };

  const fakeInputs = [...Array(len)].map((_, i) => {
    return (
      <View key={i} style={styles.fakeInput}>
        <Text style={textStyle}>{i < pin.length - 1 ? '●' : pin[i]}</Text>
      </View>
    );
  });

  return (
    <Pressable
      style={styles.container}
      onPress={() => {
        if (!keyboardIsOpen) {
          if (inputEl.current?.isFocused()) {
            inputEl.current?.blur();
          }
          inputEl.current?.focus();
        }
      }}
    >
      <TextInput
        ref={inputEl}
        onChangeText={onTextChange}
        value={pin}
        keyboardType={'numeric'}
        autoFocus={true}
        caretHidden={true}
        maxLength={len}
        selectTextOnFocus={false}
        style={styles.realInput}
        onBlur={onBlurHandler}
      />
      <View style={styles.fakeInputsContainer}>{fakeInputs}</View>
    </Pressable>
  );
}
