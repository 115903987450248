import { Appointment } from './appointment';
import { BlockedTime } from './blockedTime';
import { JSONable } from '@mero/shared-sdk';
import * as t from 'io-ts';

export type Any = Appointment | BlockedTime;

const JSON: t.Type<Any, JSONable> = t.union([Appointment.JSON, BlockedTime.JSON]);

export const Any = {
  JSON,
};
