import { ObjectId } from '@mero/shared-sdk';
import * as t from 'io-ts';

export interface CustomizedNotificationTemplateIdBrand {
  readonly CustomizedNotificationTemplateId: unique symbol;
}

export type CustomizedNotificationTemplateId = t.Branded<ObjectId, CustomizedNotificationTemplateIdBrand>;

const JSON = t.brand(
  ObjectId,
  (_id: ObjectId): _id is CustomizedNotificationTemplateId => true,
  'CustomizedNotificationTemplateId',
);

export const CustomizedNotificationTemplateId = {
  JSON,
};
