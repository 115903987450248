import * as t from 'io-ts';

type IsRegistered = {
  readonly isRegistered: true;
  readonly user: {
    readonly hasPin: boolean;
  };
};

type NotRegistered = {
  readonly isRegistered: false;
};

export type IsPhoneRegisteredResponse = IsRegistered | NotRegistered;

const JSON: t.Type<IsPhoneRegisteredResponse> = t.union(
  [
    t.type(
      {
        isRegistered: t.literal(true),
        user: t.type(
          {
            hasPin: t.boolean,
          },
          'User',
        ),
      },
      'IsRegistered',
    ),
    t.type(
      {
        isRegistered: t.literal(false),
      },
      'NotRegistered',
    ),
  ],
  'IsPhoneRegisteredResponse',
);

export const IsPhoneRegisteredResponse = {
  JSON,
};
