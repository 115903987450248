import { JSONable } from '@mero/shared-sdk';
import * as t from 'io-ts';

const JSON = t.type(
  {
    type: t.literal('InvalidConfiguration'),
    message: t.string,
  },
  'InvalidConfiguration',
);

/**
 * Online payments invalid configuration error
 */
export class InvalidConfiguration extends Error {
  public readonly type = 'InvalidConfiguration';

  constructor(message: string) {
    super(message);
  }

  public static JSON: t.Type<InvalidConfiguration, JSONable> = JSON.pipe(
    new t.Type<InvalidConfiguration, t.TypeOf<typeof JSON>, t.TypeOf<typeof JSON>>(
      JSON.name,
      (e): e is InvalidConfiguration => {
        return e instanceof InvalidConfiguration;
      },
      (e): t.Validation<InvalidConfiguration> => {
        return t.success(new InvalidConfiguration(e.message));
      },
      (e) => {
        return {
          type: e.type,
          message: e.message,
        };
      },
    ),
  );
}
