import { CheckoutCompanyId } from '../companies/checkoutCompanyId';
import { Company } from './company';
import { None } from './none';
import { HasId } from '@mero/shared-sdk';

export type { Company } from './company';
export type { None } from './none';

export type Any = None | Company;

export const NONE: None = {
  type: 'None',
};

export const company = (company: HasId<CheckoutCompanyId>, receipt: Company['receipt']): Company => ({
  type: 'Company',
  company,
  receipt,
});
