import { DiscountPercent } from '../../numbers/discountPercent';

/**
 * {@link AppliedDiscountPercent} represents a discoutnt expressed in a percent from original price
 */
export type AppliedDiscountPercent<Num> = {
  readonly type: 'Percent';
  readonly percent: DiscountPercent<Num>;
};

/**
 * Build new instance of {@link AppliedDiscountPercent}
 */
const of = <Num>(percent: DiscountPercent<Num>): AppliedDiscountPercent<Num> => {
  return {
    type: 'Percent',
    percent,
  };
};

export const AppliedDiscountPercent = {
  of,
};
