import * as React from 'react';

import Favorite from '../Favorite';

type Props = {
  readonly size?: number | string;
  readonly disabled?: boolean;
  readonly onPress?: () => void;
  readonly color?: string;
};

const FavoriteFilled: React.FC<Props> = ({ size = 32, color = '#FFFFFF', disabled, onPress }) => {
  return <Favorite size={size} color={color} disabled={disabled} onPress={onPress} isFilled />;
};

export default FavoriteFilled;
