import { ClientId } from '../clients/client-id';
import { CouponId, CouponType } from '../coupons';
import { MembershipItemConsumptionLock } from '../memberships/membershipConsumptionLock';
import { PageId } from '../pages';
import { ServiceId } from '../services';
import { UserId } from '../users/user-id';
import { WorkerId } from '../workers';
import { AppointedDay } from './appointed-day';
import { AppointmentHistoryRecord } from './appointment-history-record';
import { AppointmentId } from './appointment-id';
import { AppointmentStatus } from './appointment-status';
import { AppointmentIntentId } from './appointmentIntentId';
import { AvailableDay } from './available-day';
import { BlockedTimeReason } from './blocked-time-reason';
import { BookAppointment2Response } from './bookAppointment2Response';
import { BookedServicesPayload } from './bookedServicesPayload';
import { BulkCalendarsResponse } from './bulkCalendarsResponse';
import { Calendar } from './calendar';
import { CalendarId } from './calendar-id';
import { CalendarSettings } from './calendar-settings';
import { CalendarAvailableDays } from './calendarAvailableDays';
import { CalendarDayAvailableTimeSlots } from './calendarDayAvailableTimeSlots';
import { CalendarEntry } from './calendarEntry';
import { CalendarEntryDetails } from './calendarEntryDetails';
import { ConsumedProductsPayload } from './consumedProductsPayload';
import { UserAppointment } from './user-appointment';
import { WaitingList } from './waiting-list';
import { WaitingListId } from './waiting-list-id';
import { WaitingListDetails } from './waitingListDetails';
import { DateString, Money, Option } from '@mero/shared-sdk';
import { DayTime, DefaultSocketEvents, RecurrenceRule } from '@mero/shared-sdk';
import { ScaledNumber } from '@mero/shared-sdk';
import { MeroUnits } from '@mero/shared-sdk';
import { Paged } from '@mero/shared-sdk';
import * as Nea from 'fp-ts/lib/NonEmptyArray';

enum CalendarEvents {
  'calendar-entry-added' = 'calendar-entry-added',
  'calendar-entry-updated' = 'calendar-entry-updated',
  'calendar-entry-removed' = 'calendar-entry-removed',
}

export type CalendarSocketEvents<A> = {
  [k in CalendarEvents]: <A>(v: A) => void;
};

type SocketHandlers<A> = DefaultSocketEvents<A> | CalendarSocketEvents<A>;

export interface CalendarApi {
  /**
   * Get calendar available days
   */
  getAvailability: (params: {
    calendarId: CalendarId;
    from: Date;
    to: Date;
    requiredServiceIds: ServiceId[];
  }) => Promise<AvailableDay[]>;

  /**
   * Get calendar available days for given list of calendar
   */
  getCalendarsAvailability: (params: {
    readonly pageId: PageId;
    readonly calendarIds: CalendarId[];
    readonly from: DateString;
    readonly to: DateString;
    readonly requiredServiceIds: ServiceId[];
  }) => Promise<CalendarAvailableDays[]>;

  /**
   * Get available time slots for given day
   */
  getAvailableTimeSlots: (params: {
    calendarId: string;
    day: Date;
    requiredServiceIds: ServiceId[];
  }) => Promise<DayTime[]>;

  /**
   * Get available time slots for given day all calendars
   */
  getCalendarsAvailableTimeSlots: (params: {
    readonly pageId: PageId;
    readonly calendarIds: string[];
    readonly day: DateString;
    readonly requiredServiceIds: ServiceId[];
  }) => Promise<CalendarDayAvailableTimeSlots[]>;

  /**
   * Book a client appointment.
   */
  readonly bookAppointment: (params: {
    readonly calendarId: CalendarId;
    readonly start: Date;
    readonly pageId: PageId;
    readonly workerId: WorkerId;
    readonly bookedServiceIds: ServiceId[];
    readonly bookingStartingPoint?: string;
    readonly campaignId?: string;
    readonly campaignSource?: string;
    readonly coupons?: {
      readonly _id: CouponId;
      readonly type: CouponType;
    }[];
  }) => Promise<AppointmentId>;

  /**
   * Book a client appointment with an upfront payment.
   */
  bookPrepaidAppointment: (params: {
    readonly pageId: PageId;
    readonly workerId: WorkerId;
    readonly start: Date;
    readonly bookedServiceIds: ServiceId[];
    readonly bookingStartingPoint?: string;
    readonly payment: {
      readonly paymentMethodId: string;
      readonly amount: Money<ScaledNumber, MeroUnits.RON>;
    };
    readonly campaignId?: string;
    readonly campaignSource?: string;
    readonly redirectUrl?: string;
    readonly coupons?: {
      readonly _id: CouponId;
      readonly type: CouponType;
    }[];
  }) => Promise<BookAppointment2Response>;

  /**
   * Cancel an appointment intent (ex. when user cancels the payment)
   */
  cancelAppointmentIntent: (params: { pageId: PageId; appointmentIntentId: AppointmentIntentId }) => Promise<void>;

  /**
   * Wait for an appointment intent to be finished (payment to be processed or failed)
   */
  waitAppointmentIntentFinish: (params: {
    pageId: PageId;
    appointmentIntentId: AppointmentIntentId;
  }) => Promise<AppointmentId>;

  /**
   * Get authorized user appointments.
   */
  fetchUserAppointments: (filters: {
    readonly pageId?: PageId;
    readonly limit?: number;
    readonly offset?: number;
    readonly includeDeleted?: boolean;
  }) => Promise<UserAppointment[]>;

  /**
   * Get all appointments where user can leave feedback.
   */
  fetchUserAppointmentsToLeaveFeedback: () => Promise<UserAppointment[]>;

  /**
   * Fetch user appointment by id
   */
  fetchUserAppointment: (id: AppointmentId, occurrenceIndex?: number) => Promise<UserAppointment>;

  /**
   * Get user's next appointment
   */
  getUserNextAppointment: () => Promise<UserAppointment | undefined>;

  /**
   * Cancel user appointment
   */
  readonly cancelUserAppointment: (params: {
    readonly calendarId: CalendarId;
    readonly appointmentId: AppointmentId;
    readonly occurrenceIndex: number;
    readonly reason: string;
  }) => Promise<void>;

  /**
   * Get calendar by ID
   */
  getCalendarById: (id: string) => Promise<Calendar>;

  /**
   * Get calendar entries.
   */
  getCalendarEntries: (params: {
    calendarId: CalendarId;
    from: Date;
    to: Date;
    includeDeleted?: boolean;
  }) => Promise<CalendarEntry.Any[]>;

  /**
   * Get calendar entries in bulk, for multiple calendars at once
   */
  getBulkCalendarData: (params: {
    pageId: PageId;
    calendarIds: CalendarId[];
    from: Date;
    to: Date;
    includeDeleted?: boolean;
    hasFinishedCheckoutTransactions?: boolean;
  }) => Promise<BulkCalendarsResponse>;

  /**
   * Create own appointment.
   * @deprecated
   */
  readonly createOwnAppointment: (params: {
    readonly calendarId: CalendarId;
    readonly start: Date;
    readonly end: Date;
    readonly recurrenceRule?: RecurrenceRule.Any;
    readonly pageId: PageId;
    readonly workerId: WorkerId;
    /**
     * @deprecated
     */
    readonly userId?: UserId;
    readonly clientId?: ClientId;
    readonly bookedServiceIds: ServiceId[];
    readonly note?: string;
    readonly override: boolean;
  }) => Promise<AppointmentId>;

  /**
   * Create own appointment
   */
  readonly createOwnAppointment2: (params: {
    readonly pageId: PageId;
    readonly start: Date;
    readonly end: Date;
    readonly recurrenceRule: Option<RecurrenceRule.Any>;
    readonly workerId: WorkerId;
    readonly clientId: Option<ClientId>;
    readonly bookedServices: BookedServicesPayload;
    readonly consumedProducts: ConsumedProductsPayload;
    readonly note: Option<string>;
    readonly override: boolean;
    readonly membershipConsumptionLocks: Option<MembershipItemConsumptionLock[]>;
  }) => Promise<AppointmentId>;

  /**
   * Fetch a calendar entry by id.
   */
  getCalendarEntryById: (params: {
    readonly pageId: PageId;
    readonly entryId: AppointmentId;
    readonly occurrenceIndex?: number;
  }) => Promise<CalendarEntryDetails.Any>;

  /**
   * Modify an existing appointment.
   * @deprecated
   */
  readonly updateAppointment: (params: {
    readonly calendarId: CalendarId;
    readonly appointmentId: AppointmentId;
    readonly occurrenceIndex: Option<number>;
    readonly start: Date;
    readonly end: Date;
    readonly recurrent: boolean;
    readonly recurrenceRule: Option<RecurrenceRule.Any>;
    readonly bookedServiceIds: ServiceId[];
    readonly workerId: WorkerId;
    readonly note: Option<string>;
    readonly override: boolean;
    readonly onlyOnce: boolean;
    readonly clientId: Option<ClientId>;
  }) => Promise<void>;

  /**
   * Modify an existing appointment
   */
  readonly updateAppointment2: (params: {
    readonly pageId: PageId;
    readonly appointmentId: AppointmentId;
    readonly start: Date;
    readonly end: Date;
    readonly recurrent: boolean;
    readonly recurrenceRule: Option<RecurrenceRule.Any>;
    readonly bookedServices: BookedServicesPayload;
    readonly consumedProducts: ConsumedProductsPayload;
    /**
     * New workerId for appointment
     * Undefined means no worker change
     */
    readonly workerId: Option<WorkerId>;
    readonly note: Option<string>;
    readonly override: boolean;
    readonly occurrenceIndex: Option<number>;
    readonly onlyOnce: boolean;
    readonly clientId: Option<ClientId>;
    readonly membershipConsumptionLocks: Option<MembershipItemConsumptionLock[]>;
  }) => Promise<void>;

  /**
   * Create own appointment.
   */
  createBlockedTime: (params: {
    calendarId: CalendarId;
    start: Date;
    end: Date;
    recurrenceRule?: RecurrenceRule.Any;
    pageId: PageId;
    workerId: WorkerId;
    reason: BlockedTimeReason;
    override: boolean;
  }) => Promise<AppointmentId>;

  /**
   * Delete a blocked time slot from the calendar.
   */
  cancelBlockedTime: (params: {
    calendarId: CalendarId;
    entryId: AppointmentId;
    occurrenceIndex?: number;
    onlyOnce: boolean;
  }) => Promise<void>;

  /**
   * Modify a blocked time slot
   */
  updateBlockedTime: (params: {
    calendarId: CalendarId;
    appointmentId: AppointmentId;
    start: Date;
    end: Date;
    recurrent: boolean;
    recurrenceRule?: RecurrenceRule.Any;
    reason: BlockedTimeReason;
    override: boolean;
    occurrenceIndex?: number;
    onlyOnce: boolean;
  }) => Promise<void>;

  /**
   * Update calendar settings.
   */
  readonly updateSettings: (params: {
    readonly calendarId: CalendarId;
    readonly pageId: PageId;
    readonly newSettings: CalendarSettings;
  }) => Promise<void>;

  /**
   * Get array of dates where a calendar has appointments.
   */
  getAppointedDays: (params: { calendarId: CalendarId; from: Date; to: Date }) => Promise<AppointedDay[]>;

  /**
   * Update calendar entry status.
   */
  updateCalendarAppointmentStatus: (params: {
    calendarId: CalendarId;
    entryId: AppointmentId;
    newStatus: AppointmentStatus;
    occurrenceIndex: number;
  }) => Promise<void>;

  /**
   * Cancel an appointment from the calendar.
   */
  cancelAppointment: (params: {
    calendarId: CalendarId;
    entryId: AppointmentId;
    occurrenceIndex?: number;
    onlyOnce: boolean;
    reason: string;
  }) => Promise<void>;

  /**
   * Get user waiting list days.
   */
  getAppointmentWaitingList: (params: { workerId: WorkerId }) => Promise<WaitingList[]>;

  /**
   * Check if user reached appointment opening interests limit for worker
   */
  readonly checkWaitingListLimitReached: (params: { readonly workerId: WorkerId }) => Promise<{
    readonly limitReached: boolean;
  }>;

  /**
   * Add user to the waiting list for a specific day.
   */
  setAppointmentWaitingList: (params: {
    readonly pageId: PageId;
    readonly workerId: WorkerId;
    /**
     * calendar day for waiting list
     */
    readonly date: DateString;
    readonly serviceIds: Nea.NonEmptyArray<ServiceId>;
    readonly bookingStartingPoint: string | undefined;
    readonly campaignId: string | undefined;
    readonly campaignSource: string | undefined;
  }) => Promise<WaitingList>;

  /**
   * Fetch waiting list by pro, paged
   */
  readonly fetchPagedWaitingList: (params: {
    readonly pageId: PageId;
    readonly workerId?: WorkerId;
    readonly limit: number;
    readonly page?: string;
  }) => Promise<Paged<WaitingListDetails[]>>;

  /**
   * Delete user from the waiting list on a specific day.
   */
  deleteAppointmentWaitingList: (params: { workerId: WorkerId; waitingListId: WaitingListId }) => Promise<void>;

  /**
   * Delete waiting list entry by worker
   */
  deleteAppointmentWaitingListByWorker: (params: {
    readonly pageId: PageId;
    readonly waitingListId: WaitingListId;
  }) => Promise<void>;

  socket: <A>(params: { calendarId: CalendarId; handlers: SocketHandlers<A> }) => Promise<SocketIOClient.Socket>;

  /**
   * Fetch user appointment ICS file by id.
   * @param id
   */
  getUserAppointmentIcs: (id: AppointmentId, userId: UserId) => string;

  /**
   * Fetch user appointment ICS file by id.
   * @param id
   */
  getCalendarIcsUrl: (calendarId: CalendarId, workerId: WorkerId) => string;

  /**
   * Fetch page client user appointment
   */
  fetchClientUserAppointments: (params: {
    clientId: ClientId;
    offset?: number;
    limit?: number;
  }) => Promise<UserAppointment[]>;

  /**
   * Fetch page client user appointment, paged
   */
  fetchPagedClientUserAppointments: (params: {
    readonly clientId: ClientId;
    readonly limit: number;
    readonly page?: string;
  }) => Promise<
    Paged<UserAppointment[]> & {
      readonly futureBookingsCount?: number;
      readonly pastBookingsCount?: number;
    }
  >;

  /**
   * Fetch appointment history
   */
  fetchAppointmentHistory: (params: { appointmentId: AppointmentId }) => Promise<AppointmentHistoryRecord[]>;
}
