import { Currency } from '../../money';
import { MeroUnits } from '../meroUnits';

export type EUR = Currency<MeroUnits.EUR>;

export const EUR: MeroCurrencyModule<MeroUnits.EUR> = {
  code: MeroUnits.EUR.code,
  exponent: 2,
  base: 10,
  toFixed: (amount: number): string => {
    return amount.toFixed(EUR.exponent);
  },
  roundToFixed: (amount: number): number => {
    return Math.round(amount * Math.pow(EUR.base, EUR.exponent)) / Math.pow(EUR.base, EUR.exponent);
  },
};

export type RON = Currency<MeroUnits.RON>;

export const RON: MeroCurrencyModule<MeroUnits.RON> = {
  code: MeroUnits.RON.code,
  exponent: 2,
  base: 10,
  toFixed: (amount: number): string => {
    return amount.toFixed(RON.exponent);
  },
  roundToFixed: (amount: number): number => {
    return Math.round(amount * Math.pow(RON.base, RON.exponent)) / Math.pow(RON.base, RON.exponent);
  },
};

export type MeroCurrencyModule<Unit extends MeroUnits.Any> = Currency<Unit> & {
  /**
   * Only base 10 is supported
   */
  readonly base: 10;
  /**
   * Format the given {@link amount} to a string rounded to {@link Unit} least significant digit
   * ex. 1/100 for EUR/cents
   */
  readonly toFixed: (amount: number) => string;
  /**
   * Round give {@link amount} to {@link Unit} least significant digit
   * ex. 1/100 for EUR/cents
   */
  readonly roundToFixed: (amount: number) => number;
};

/**
 * Currencies supported by MERO
 */
export type Any = EUR | RON;
