import { PageId } from '../pages';
import { ReminderId } from './reminder-id';
import { UserId } from '@mero/shared-sdk';
import * as t from 'io-ts';
import { DateFromISOString } from 'io-ts-types';

const BookedService = t.type(
  {
    name: t.string,
  },
  'BookedService',
);

type BookedService = t.TypeOf<typeof BookedService>;

const ReminderUserInfo = t.type(
  {
    phone: t.string,
    firstname: t.string,
    lastname: t.string,
  },
  'ReminderUserInfo',
);

type ReminderUserInfo = t.TypeOf<typeof ReminderUserInfo>;

const ManualSMSStatus = t.union(
  [
    t.type({ type: t.literal('sent'), at: DateFromISOString, by: UserId }),
    t.type({ type: t.literal('deleted'), at: DateFromISOString, by: UserId }),
  ],
  'ManualSMSStatus',
);

type ManualSMSStatus = t.TypeOf<typeof ManualSMSStatus>;

export const AppointmentReminderInfo = t.intersection(
  [
    t.type({
      reminderId: ReminderId,
      pageId: PageId,
      user: ReminderUserInfo,
      start: DateFromISOString,
      bookedServices: t.array(BookedService),
      text: t.string,
      messageType: t.literal('booking_reminder'),
    }),
    t.partial({
      manualSMSStatus: ManualSMSStatus,
    }),
  ],
  'AppointmentReminderInfo',
);

export type AppointmentReminderInfo = t.TypeOf<typeof AppointmentReminderInfo>;

export const AppointmentReviewReminderInfo = t.intersection(
  [
    t.type({
      reminderId: ReminderId,
      pageId: PageId,
      user: ReminderUserInfo,
      start: DateFromISOString,
      bookedServices: t.array(BookedService),
      text: t.string,
      messageType: t.literal('ask_for_review'),
    }),
    t.partial({
      manualSMSStatus: ManualSMSStatus,
    }),
  ],
  'AppointmentReviewReminderInfo',
);

export type AppointmentReviewReminderInfo = t.TypeOf<typeof AppointmentReviewReminderInfo>;

export const AnyReminderInfo = t.union([AppointmentReminderInfo, AppointmentReviewReminderInfo], 'AnyReminderInfo');

export type AnyReminderInfo = t.TypeOf<typeof AnyReminderInfo>;
