import * as t from 'io-ts';

/**
 * At least one MERO SMS device is registered and online
 */
type Active = {
  readonly status: 'Active';
};

/**
 * There are no MERO SMS devices registered for this page
 */
type Inactive = {
  readonly status: 'Inactive';
};

/**
 * There are devices registered, but none of them is online
 */
type Offline = {
  readonly status: 'Offline';
};

export type MeroSmsPageStatus = Active | Inactive | Offline;

const JSON: t.Type<MeroSmsPageStatus> = t.union(
  [
    t.type(
      {
        status: t.literal('Active'),
      },
      'Active',
    ),
    t.type(
      {
        status: t.literal('Inactive'),
      },
      'Inactive',
    ),
    t.type(
      {
        status: t.literal('Offline'),
      },
      'Offline',
    ),
  ],
  'MeroSmsPageStatus',
);

const ACTIVE: Active = {
  status: 'Active',
};

const INACTIVE: Inactive = {
  status: 'Inactive',
};

const OFFLINE: Offline = {
  status: 'Offline',
};

export const MeroSmsPageStatus = {
  JSON,
  ACTIVE,
  INACTIVE,
  OFFLINE,
  isActive: (status: MeroSmsPageStatus): status is Active => status.status === 'Active',
  isInactive: (status: MeroSmsPageStatus): status is Inactive => status.status === 'Inactive',
  isOffline: (status: MeroSmsPageStatus): status is Offline => status.status === 'Offline',
};
