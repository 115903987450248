import * as React from 'react';
import Svg, { Path, G, Circle } from 'react-native-svg';

import { DARK_BLUE } from '../../../styles/colors';

type Props = {
  size?: number | string;
  color?: string;
  disabled?: boolean;
  onPress?: () => void;
};

const Checked: React.FC<Props> = ({ size = 24, disabled = false, color = DARK_BLUE, onPress }) => (
  <Svg width={size} height={size} viewBox="0 0 24 24" disabled={disabled} onPress={onPress}>
    <G transform="translate(-126 -558)">
      <Circle cx="12" cy="12" r="12" transform="translate(126 558)" fill={color} />
      <Path
        d="M5.76,11.681l3.094,3.094L16.588,7.04"
        transform="translate(126.99 559.21)"
        fill="none"
        stroke="#fff"
        strokeLinecap="square"
        strokeMiterlimit="10"
        strokeWidth="2"
      />
    </G>
  </Svg>
);

export default React.memo(Checked);
