import { CalendarEntry } from '../calendarEntry';
import { Recurrence } from '../calendarEntry/recurrence';
import { BlockedTimeEditableStatus } from './blockedTimeEditableStatus';
import { JSONable } from '@mero/shared-sdk';
import * as t from 'io-ts';

export type BlockedTime = CalendarEntry.BlockedTime &
  Recurrence.Any & {
    /**
     * BlockedTime editable status
     * WARNING: this field does not reflect user permissions, please check permissions independently
     */
    readonly editableStatus: BlockedTimeEditableStatus.Any;
  };

const JSON: t.Type<BlockedTime, JSONable> = t.intersection(
  [
    CalendarEntry.BlockedTime.JSON,
    Recurrence.Any.JSON,
    t.type(
      {
        editableStatus: BlockedTimeEditableStatus.Any.JSON,
      },
      '!',
    ),
  ],
  'BlockedTime',
);

export const BlockedTime = {
  JSON,
};
