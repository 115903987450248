import * as t from 'io-ts';

export type ImportProductsResponse = {
  readonly totalCount: number;
  readonly skippedCount: number;
  readonly errors: string[];
  readonly downloadUrl?: string;
};

export const ImportProductsResponse = {
  JSON: t.intersection(
    [
      t.type(
        {
          totalCount: t.number,
          skippedCount: t.number,
          errors: t.array(t.string),
        },
        '!',
      ),
      t.partial(
        {
          downloadUrl: t.string,
        },
        '?',
      ),
    ],
    'ImportProductsResponse',
  ),
};
