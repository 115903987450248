import * as React from 'react';

import * as colors from '../../../styles/colors';
import { styles as text } from '../../../styles/text';
import Label from '../../Text/Label';

/**
 * @deprecated moved to mero-pro
 */
const ClientBoostLabel: React.FC = () => {
  return <Label style={[text.semibold, { color: colors.ELECTRIC_VIOLET }]}>CLIENT BOOST</Label>;
};

export default ClientBoostLabel;
