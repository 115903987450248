import { CheckoutSaleOwnerProductSalesReportItem } from './checkoutSaleOwnerProductSalesReportItem';
import { JSONable, MeroUnits } from '@mero/shared-sdk';
import * as t from 'io-ts';

export type CheckoutSaleOwnerProductSalesReportPage<Unit extends MeroUnits.Any> = {
  readonly items: CheckoutSaleOwnerProductSalesReportItem<Unit>[];
};

const json = <Unit extends MeroUnits.Any>(
  unit: Unit,
): t.Type<CheckoutSaleOwnerProductSalesReportPage<Unit>, JSONable> => {
  return t.type(
    {
      items: t.array(CheckoutSaleOwnerProductSalesReportItem.json(unit)),
    },
    `CheckoutSaleOwnerProductSalesReportPage<${unit}>`,
  );
};

export const CheckoutSaleOwnerProductSalesReportPage = {
  json,
};
