import { Numbers } from './numbers';
import { PercentBrand, Percent, PercentModule } from './percent';
import * as t from 'io-ts';

export type PercentNumber = t.Branded<number, PercentBrand>;

export type PercentNumberModule = PercentModule<number> & {
  readonly JSON: t.Type<Percent<number>, number>;
};

const PercentN = Percent.build(Numbers);

export const PercentNumber: PercentNumberModule = {
  ...PercentN,
  JSON: PercentN.json(t.number),
};
