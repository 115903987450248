import { JSONable } from '@mero/shared-sdk';
import * as t from 'io-ts';
import { DateFromISOString } from 'io-ts-types';

export type PurchasedMembershipValidity =
  | {
      readonly type: 'Unlimited';
      readonly from: Date;
    }
  | {
      readonly type: 'Limited';
      readonly from: Date;
      readonly to: Date;
    };

const JSON: t.Type<PurchasedMembershipValidity, JSONable> = t.union(
  [
    t.type(
      {
        type: t.literal('Unlimited'),
        from: DateFromISOString,
      },
      'Unlimited',
    ),
    t.type(
      {
        type: t.literal('Limited'),
        from: DateFromISOString,
        to: DateFromISOString,
      },
      'Limited',
    ),
  ],
  'PurchasedMembershipValidity',
);

export const PurchasedMembershipValidity = {
  JSON,
};
