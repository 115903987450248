import { CheckoutSaleOwnerServiceSalesReportItem } from './checkoutReportBySaleOwnerAndServiceItem';
import { JSONable, MeroUnits } from '@mero/shared-sdk';
import * as t from 'io-ts';

export type CheckoutSaleOwnerServiceSalesReportPage<Unit extends MeroUnits.Any> = {
  readonly items: CheckoutSaleOwnerServiceSalesReportItem<Unit>[];
};

const json = <Unit extends MeroUnits.Any>(
  unit: Unit,
): t.Type<CheckoutSaleOwnerServiceSalesReportPage<Unit>, JSONable> => {
  return t.type(
    {
      items: t.array(CheckoutSaleOwnerServiceSalesReportItem.json(unit)),
    },
    `CheckoutSaleOwnerServiceSalesReportPage<${unit}>`,
  );
};

export const CheckoutSaleOwnerServiceSalesReportPage = {
  json,
};
