import * as React from 'react';
import Svg, { Path } from 'react-native-svg';

type Props = {
  size?: number | string;
  disabled?: boolean;
  onPress?: () => void;
  color?: string;
};

function AppleShare({ size = 24, color = '#ffffff', disabled, onPress }: Props) {
  return (
    <Svg width="17.609" height="24.347" viewBox="0 0 17.609 24.347">
      <Path
        id="Path_1748"
        data-name="Path 1748"
        d="M273.424,250.284a.5.5,0,0,1,0-.709l4.217-4.165,4.317,4.161a.5.5,0,1,1-.7.722l-3.115-3v12.815a.5.5,0,1,1-1,0V247.311l-3.015,2.978A.5.5,0,0,1,273.424,250.284Zm7.9,2.3a.5.5,0,1,0,0,1H285.1v14.329H270.2V253.586h3.965a.5.5,0,0,0,0-1H269.2v16.335H286.1V252.583Z"
        transform="translate(-268.846 -244.921)"
        fill={color}
        stroke={color}
        strokeWidth="0.7"
      />
    </Svg>
  );
}

export default AppleShare;
