import * as React from 'react';
import Svg, { G, Path, Circle } from 'react-native-svg';

type Props = {
  size?: number | string;
  disabled?: boolean;
  onPress?: () => void;
};

const MembershipSmall = ({ size = 24, disabled, onPress }: Props) => (
  <Svg width={size} height={size} viewBox={`0 0 ${size} ${size}`} disabled={disabled} onPress={onPress}>
    <Path fill="none" d="M0 0h24v24H0Z" data-name="Path 8275" />
    <G
      fill="none"
      stroke="#52577f"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeMiterlimit="10"
      strokeWidth="1.2"
      transform="translate(3 5.25)"
    >
      <Path d="M14.741 0v.295a1.474 1.474 0 0 1-2.948 0V0H5.9v.295a1.474 1.474 0 0 1-2.948 0V0H0v12.972h17.689V0ZM10.613 4.717h4.717m-4.717 2.358h4.717m-4.717 2.359h2.359" />
      <Circle cx="1.598" cy="1.598" r="1.598" data-name="layer1" transform="translate(3.732 3.834)" />
      <Path
        d="M5.307 7.075a2.978 2.978 0 0 0-2.947 3.037v.5h5.9v-.5a2.978 2.978 0 0 0-2.953-3.037Z"
        data-name="layer1"
      />
    </G>
  </Svg>
);

export default React.memo(MembershipSmall);
