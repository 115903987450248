import { literalIso } from '@mero/shared-sdk';
import * as t from 'io-ts';

/**
 * @deprecated use business.InviteStatusPendding
 */
export const InviteStatusPending = literalIso('pending', 0);
export type InviteStatusPending = t.TypeOf<typeof InviteStatusPending>;

/**
 * @deprecated use business.InviteStatusAccepted
 */
export const InviteStatusAccepted = literalIso('accepted', 1);
export type InviteStatusAccepted = t.TypeOf<typeof InviteStatusAccepted>;

/**
 * @deprecated use business.InviteStatusRejected
 */
export const InviteStatusRejected = literalIso('rejected', 2);
export type InviteStatusRejected = t.TypeOf<typeof InviteStatusRejected>;

/**
 * @deprecated use business.InviteStatus
 */
export const InviteStatus = t.union([InviteStatusPending, InviteStatusAccepted, InviteStatusRejected], 'InviteStatus');

/**
 * @deprecated use business.InviteStatus
 */
export type InviteStatus = t.TypeOf<typeof InviteStatus>;
