import * as React from 'react';
import Svg, { Path } from 'react-native-svg';

type Props = {
  size?: number | string;
  color?: string;
  disabled?: boolean;
  onPress?: () => void;
};

const ProMenuClientNotifications = ({ size = 32, color = '#000000', disabled, onPress }: Props) => (
  <Svg width={size} height={size} viewBox="0 0 32 32" disabled={disabled} onPress={onPress}>
    <Path
      d="M22,8.98V18a2.006,2.006,0,0,1-2,2H4a2.006,2.006,0,0,1-2-2L2.01,6A2,2,0,0,1,4,4H14.1A5.468,5.468,0,0,0,14,5a5.468,5.468,0,0,0,.1,1H4l8,5,3.67-2.29a4.83,4.83,0,0,0,1.63.98L12,13,4,8V18H20V9.9A4.952,4.952,0,0,0,22,8.98ZM16,5a3,3,0,1,0,3-3A3,3,0,0,0,16,5Z"
      transform="translate(4 4)"
      fill={color}
    />
  </Svg>
);

export default React.memo(ProMenuClientNotifications);
