import * as React from 'react';
import { View } from 'react-native';
import { NativeSafeAreaViewProps } from 'react-native-safe-area-context';

type Props = NativeSafeAreaViewProps;

const SafeAreaView: React.FC<Props> = ({ mode, edges, ...rest }: Props) => {
  return <View {...rest} />;
};

export default SafeAreaView;
