import { PageId } from '../../pages/page-id';
import { PageBillingDetails } from './pageBillingDetails';
import { PageBillingDetailsId } from './pageBillingDetailsId';
import { PageBillingDetailsUsageLabel } from './pageBillingDetailsUsageLabel';
import { JSONable, Option } from '@mero/shared-sdk';
import * as t from 'io-ts';

export type SavedPageBillingDetails = {
  readonly _id: PageBillingDetailsId;
  readonly pageId: PageId;
  readonly billing: PageBillingDetails.Any;
  readonly usage: Option<PageBillingDetailsUsageLabel[]>;
};

const JSON: t.Type<SavedPageBillingDetails, JSONable> = t.type(
  {
    _id: PageBillingDetailsId.JSON,
    pageId: PageId,
    billing: PageBillingDetails.JSON,
    usage: Option.json(t.array(PageBillingDetailsUsageLabel.JSON)),
  },
  'SavedPageBillingDetails',
);

export const SavedPageBillingDetails = {
  JSON,
};
