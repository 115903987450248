import * as React from 'react';
import { FlatList, ListRenderItem, ListRenderItemInfo, Text, TextProps, View } from 'react-native';

import { BLACK, MISCHKA } from '../../styles/colors';

type StyledTextProps = {
  color: string;
  withMarginLeft?: boolean;
};

const StyledText: React.FC<StyledTextProps & TextProps> = ({ color, withMarginLeft, style, ...props }) => {
  return (
    <Text
      style={[
        {
          color: color,
          fontSize: 16,
          fontFamily: 'open-sans',
          marginLeft: withMarginLeft ? 16 : undefined,
        },
        style,
      ]}
      {...props}
    />
  );
};

const StyledHr: React.FC = () => {
  return (
    <View
      style={{
        width: '100%',
        height: 1,
        margin: 16,
        backgroundColor: MISCHKA,
      }}
    />
  );
};

type ItemPropType =
  | string
  | {
      LeftComponent?: React.ComponentType;
      text: string;
    };

type ItemProps = {
  color?: string;
  item: ItemPropType;
};

const Item = ({ item, color = BLACK }: ItemProps) => {
  if (typeof item === 'string') {
    return <StyledText color={color}>{item}</StyledText>;
  }

  const { LeftComponent, text } = item;
  const withMarginLeft = !!LeftComponent;

  return (
    <View style={{ display: 'flex', flexDirection: 'row' }}>
      {LeftComponent && <LeftComponent />}
      <StyledText color={color} withMarginLeft={withMarginLeft}>
        {text}
      </StyledText>
    </View>
  );
};

type ListProps = {
  data?: ItemPropType[];
  withSeparator?: boolean;
  color?: string;
};

const List: React.FC<ListProps> = ({ data, withSeparator = true, color = BLACK }) => {
  // eslint-disable-next-line react/prop-types
  const renderItem: ListRenderItem<ItemPropType> = ({ index, item }: ListRenderItemInfo<ItemPropType>) => (
    <Item key={index} item={item} color={color} />
  );
  const keyExtractor = (item: ItemPropType): string => {
    if (typeof item === 'string') {
      return item;
    }

    return item.text;
  };

  return (
    <FlatList
      data={data}
      renderItem={renderItem}
      keyExtractor={keyExtractor}
      ItemSeparatorComponent={() => (withSeparator ? <StyledHr /> : null)}
      style={{ width: '100%' }}
    />
  );
};

export default List;
