import { load as enLocale } from './locales/en/index';
import { load as roLocale } from './locales/ro/index';

export * from './locales/namespaces';

// load locales
export function loadNovabookerErrors() {
  roLocale();
  enLocale();
}
export default loadNovabookerErrors;
