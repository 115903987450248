import * as MeroApi from '@mero/api-sdk';
import * as React from 'react';

import * as colors from '../../styles/colors';
import { styles as text } from '../../styles/text';
import Label from '../Text/Label';

export type Props = {
  /**
   * Booking status
   */
  readonly status: MeroApi.calendar.AppointmentStatus;
  /**
   * Booking date in past
   */
  readonly inPast: boolean;
};

export default function AppointmentStatus({ status, inPast }: Props): React.ReactElement {
  switch (status) {
    case 'accepted':
      if (inPast) {
        return <Label style={[text.semibold, { color: colors.COMET }]}>FINALIZAT</Label>;
      } else {
        return <Label style={[text.semibold, { color: colors.SHAMROCK }]}>CONFIRMAT</Label>;
      }
    case 'cancelled':
      return <Label style={[text.semibold, { color: colors.RADICAL_RED }]}>ANULAT</Label>;
    case 'noShow':
      return <Label style={[text.semibold, { color: colors.OUTRAGEOUS_ORANGE }]}>NEPREZENTAT</Label>;
    case 'pending':
      return <Label style={[text.semibold, { color: colors.COMET }]}>ÎN AȘTEPTARE</Label>;
    case 'rejected':
      return <Label style={[text.semibold, { color: colors.RADICAL_RED }]}>REFUZAT</Label>;
  }
}
