import * as React from 'react';
import Svg, { G, Rect, Path } from 'react-native-svg';

export type Props = {
  size?: number;
  onPress?: () => void;
};

const LogoIcon: React.FC<Props> = ({ size = 72, onPress }: Props) => {
  return (
    <Svg width={size} height={size} viewBox="0 0 72 72" onPress={onPress} disabled={onPress === undefined}>
      <G transform="translate(945 1602)">
        <Rect width={72} height={72} rx={17} transform="translate(-945 -1602)" fill="#0c0c0c" />
        <Path
          data-name="Path 9161"
          d="M-896.494-1554.05a6.613 6.613 0 01-4.684 6.683h17.152a6.613 6.613 0 01-4.684-6.683v-23.97a6.611 6.611 0 014.684-6.682h-12.454l-11.2 23.07-13.656-23.07h-11.187a10.4 10.4 0 015.543 9.153v19.756c0 7.322-6.911 8.426-6.911 8.426h14.689a8.385 8.385 0 01-6.807-8.426v-21.454l14.9 24.9 14.63-30.136z"
          fill="#fff"
        />
      </G>
    </Svg>
  );
};

export default LogoIcon;
