import { JSONable } from '@mero/shared-sdk';
import * as t from 'io-ts';

export interface PageBillingDetailsIdBrand {
  readonly PageBillingDetailsId: unique symbol;
}

export type PageBillingDetailsId = t.Branded<string, PageBillingDetailsIdBrand>;

const JSON = t.brand(t.string, (s): s is PageBillingDetailsId => true, 'PageBillingDetailsId');

export const PageBillingDetailsId = {
  JSON,
};
