import * as React from 'react';
import Svg, { Path } from 'react-native-svg';

type Props = {
  size?: string | number;
  color?: string;
  disabled?: boolean;
  onPress?: () => void;
};

const Info: React.FC<Props> = ({ size = 18, color = '#52577f', disabled = false, onPress }) => (
  <Svg width={size} height={size} viewBox={`0 0 ${size} ${size}`} disabled={disabled} onPress={onPress}>
    <Path d="M0,0H18V18H0Z" fill="none" />
    <Path
      d="M8.3,5.5H9.7V6.9H8.3Zm0,2.8H9.7v4.2H8.3ZM9,2a7,7,0,1,0,7,7A7,7,0,0,0,9,2ZM9,14.6A5.6,5.6,0,1,1,14.6,9,5.607,5.607,0,0,1,9,14.6Z"
      fill={color}
    />
  </Svg>
);

export default React.memo(Info);
