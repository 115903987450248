import { marketplaceBootHttpClient } from './boot';
import { marketplaceCouponsHttpClient } from './coupons';
import { marketplaceFavoritesHttpClient } from './favorites';
import { marketplaceGiftCardsHttpClient } from './giftCards';
import { marketplaceLinkingHttpClient } from './linking';
import { marketplaceMembershipsHttpClient } from './memberships';
import { MeroMarketplaceApi } from './meroMarketplaceApi';
import { marketplaceReviewsHttpClient } from './reviews';
import { HttpClient } from '@mero/shared-sdk';

export const meroMarketplaceHttpClient = (env: { apiBaseUrl: string; http: HttpClient }): MeroMarketplaceApi => {
  const boot = marketplaceBootHttpClient(env);
  const linking = marketplaceLinkingHttpClient(env);
  const favorites = marketplaceFavoritesHttpClient(env);
  const memberships = marketplaceMembershipsHttpClient(env);
  const giftCards = marketplaceGiftCardsHttpClient(env);
  const coupons = marketplaceCouponsHttpClient(env);
  const reviews = marketplaceReviewsHttpClient(env);

  return {
    boot,
    linking,
    favorites,
    memberships,
    giftCards,
    coupons,
    reviews,
  };
};
