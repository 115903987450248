import { ObjectId } from '@mero/shared-sdk';
import * as t from 'io-ts';

export interface UserDeviceIdBrand {
  readonly UserDeviceId: unique symbol;
}

export const UserDeviceId = t.brand(
  ObjectId,
  (_id: ObjectId): _id is t.Branded<ObjectId, UserDeviceIdBrand> => true,
  'UserDeviceId',
);

export type UserDeviceId = t.TypeOf<typeof UserDeviceId>;
