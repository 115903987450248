import { DateInterval } from './dateInterval';

export type Timeline = {
  /**
   * Sorted, non-overlapping events.
   */
  readonly events: readonly DateInterval[];
};

/**
 * Builds a timeline from a list of events.
 * - events are sorted by start time
 * - overlapping events are merged together, so there are no overlaps
 */
const of = (events: readonly DateInterval[]): Timeline => {
  const sorted = events.slice().sort((a, b) => a.from.getTime() - b.from.getTime());

  const merged = sorted.reduce((acc: DateInterval[], curr: DateInterval) => {
    if (acc.length > 0) {
      // try to merge last interval with current one
      const sum = DateInterval.add(acc[acc.length - 1], curr);

      // first interval is either first argument or merged interval
      acc[acc.length - 1] = sum[0];

      // if intervals wasn't merged - second argument is retruned at second position
      if (sum[1]) {
        acc.push(sum[1]);
      }

      return acc;
    } else {
      return [curr];
    }
  }, []);

  return {
    events: merged,
  };
};

const EMPTY: Timeline = {
  events: [],
};

/**
 * Returns empty timeline
 */
const empty = (): Timeline => {
  return EMPTY;
};

/**
 * Checks if timeline is empty
 */
const isEmpty = (timeline: Timeline): boolean => {
  return timeline.events.length === 0;
};

/**
 * Adds interval to timeline
 */
const addInterval = (timeline: Timeline, interval: DateInterval): Timeline => {
  return of([...timeline.events, interval]);
};

const subInterval = (timeline: Timeline, interval: DateInterval): Timeline => {
  const sorted = timeline.events;

  const merged = sorted.reduce((acc: DateInterval[], curr: DateInterval) => {
    // Timeline eventes are sorted and non-overlapping, so only need to check current event for overlaps
    if (!DateInterval.overlaps(curr, interval)) {
      // no overlap, add current event as is
      // This case is acutually covered by {@link DateInterval.sub}, but handled here to avoid unnecessary allocations
      acc.push(curr);
      return acc;
    } else {
      const diff = DateInterval.sub(curr, interval);

      acc.push(...diff);
      return acc;
    }
  }, []);

  return {
    events: merged,
  };
};

/**
 * Returns bounds of timeline or undefined for empty timeline
 */
const getBounds = (timeline: Timeline): DateInterval | undefined => {
  if (timeline.events.length === 0) {
    return undefined;
  } else {
    return {
      // As the timeline is always sorted by start time - first element always has the earliest start date
      from: timeline.events[0].from,
      // As thre are no overlaps - last element always has the latest end date
      to: timeline.events[timeline.events.length - 1].to,
    };
  }
};

export const Timeline = {
  of,
  empty,
  isEmpty,
  addInterval,
  subInterval,
  getBounds,
};
