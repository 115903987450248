import { BankTransfer } from './bankTransfer';
import { Card } from './card';
import { Cash } from './cash';
import { Giftcard } from './giftcard';
import { Membership } from './membership';
import { Online } from './online';
import { MeroUnits } from '@mero/shared-sdk';

export type Any<Num, Unit extends MeroUnits.Any> =
  | Giftcard<Num, Unit>
  | Cash<Num, Unit>
  | Card<Num, Unit>
  | BankTransfer<Num, Unit>
  | Membership<Num, Unit>
  | Online<Num, Unit>;

export type SupportsReceiptsPrinting<Num, Unit extends MeroUnits.Any> = Cash<Num, Unit> | Card<Num, Unit>;

const SUPPORTS_PRINTING_TYPES: { [K in SupportsReceiptsPrinting<any, any>['type']]: true } = {
  Cash: true,
  Card: true,
};

export const supportsReceiptsPrinting = <Num, Unit extends MeroUnits.Any>(
  payment: Any<Num, Unit>,
): payment is SupportsReceiptsPrinting<Num, Unit> => {
  return payment.type in SUPPORTS_PRINTING_TYPES;
};
