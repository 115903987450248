import { JSONable } from '@mero/shared-sdk';
import * as t from 'io-ts';

export type Accepted = 1;

const VALUE: Accepted = 1;

const JSON: t.Type<Accepted, JSONable> = t.literal(VALUE);

export const Accepted = {
  JSON,
  VALUE,
};
