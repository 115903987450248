import { PageId } from '../pages/page-id';
import { ClientUser } from './client-user';
import { OptionalDefinedString, Nullean, optionull } from '@mero/shared-sdk';
import * as t from 'io-ts';
import { DateFromISOString } from 'io-ts-types';

export const Client = t.intersection(
  [
    t.type(
      {
        pageId: PageId,
        user: ClientUser,
        isBlocked: Nullean,
        isWarned: Nullean,
        isFavourite: Nullean,
        showRemarkOnCalendar: Nullean,
      },
      '!',
    ),
    t.partial(
      {
        lastAppointment: optionull(DateFromISOString),
        remark: OptionalDefinedString,
        isBoost: Nullean,
        hideBoostDetails: Nullean,
      },
      '?',
    ),
  ],
  'Client',
);

export interface Client extends t.TypeOf<typeof Client> {}
