import { JSONable } from '@mero/shared-sdk';
import * as t from 'io-ts';

export type Manual = {
  readonly type: 'Manual';
};

const JSON: t.Type<Manual, JSONable> = t.type(
  {
    type: t.literal('Manual'),
  },
  'Manual',
);

export const Manual = {
  JSON,
};
