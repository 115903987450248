import * as t from 'io-ts';

export const BlockedTimeReason = t.union(
  [
    t.type({
      type: t.literal('break'),
    }),
    t.type({
      type: t.literal('dayOff'),
    }),
    t.type({
      type: t.literal('vacation'),
    }),
    t.type({
      type: t.literal('sickLeave'),
    }),
    t.type({
      type: t.literal('training'),
    }),
    t.type({
      type: t.literal('custom'),
      reason: t.string,
    }),
  ],
  'BlockedTimeReason',
);

export type BlockedTimeReason = t.TypeOf<typeof BlockedTimeReason>;
