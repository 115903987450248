import * as React from 'react';
import Svg, { G, Path, Circle } from 'react-native-svg';

type Props = {
  size?: string | number;
  color?: string;
  disabled?: boolean;
  onPress?: () => void;
};

const CalendarDiscount = ({ size = 12, color = '#ffffff', disabled, onPress }: Props) => (
  <Svg width={size} height={size} viewBox={`0 0 ${size} ${size}`} disabled={disabled} onPress={onPress}>
    <G data-name="Group 6854" fill="none">
      <Path data-name="Rectangle 2640" d="M0 0h12v12H0z" />
      <G
        transform="translate(-1.092 -2.455)"
        stroke="#000"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit={10}
        strokeWidth={1.4}
      >
        <Circle cx={1.844} cy={1.844} r={1.844} transform="translate(2.022 3.846)" />
        <Circle data-name="layer2" cx={1.844} cy={1.844} r={1.844} transform="translate(8.474 9.377)" />
        <Path d="M11.002 4.27 3.08 12.852" />
      </G>
    </G>
  </Svg>
);

export default React.memo(CalendarDiscount);
