import { NumericString } from '../numericString';
import * as t from 'io-ts';

/**
 * EAN Barcode
 * Matches 13-digit numeric strings
 */
interface EANStringBrand {
  readonly EANString: unique symbol;
}

export type EANString = t.Branded<NumericString, EANStringBrand>;

const JSON: t.Type<EANString, string> = t.brand(
  NumericString.JSON,
  (s): s is EANString => /^[0-9]{13}$/.test(s),
  'EANString',
);

export const EANString = {
  JSON,
};
