import { ExtendedCalculator } from './extendedCalculator';
import { HasFromNumber } from './hasFromNumber';
import { Percent } from './percent';
import * as t from 'io-ts';

export interface PortionPercentBrand {
  readonly PortionPercent: unique symbol;
}

/**
 * A valid portion {@link PortionPercent} value is a Percent type that is always in [0, 100] range.
 */
export type PortionPercent<Num> = t.Branded<Percent<Num>, PortionPercentBrand>;

export type PortionPercentModule<Num> = {
  /**
   * Checks if value is a valid PortionPercent
   */
  readonly is: (a: Num) => a is PortionPercent<Num>;
  /**
   * Create a PortionPercent from a number
   * @throws if given number is not a valid PortionPercent
   */
  readonly unsafeFrom: (n: Num) => PortionPercent<Num>;
  /**
   * Calculate portion value for given value {@link n}
   */
  readonly ofValue: (n: Num, percent: PortionPercent<Num>, decimals: number) => Num;
  /**
   * Returns zero value
   */
  readonly zero: () => PortionPercent<Num>;
  /**
   * Build new JSON codec for PortionPercent<Num>
   */
  readonly json: <O, I>(codec: t.Type<Num, O, I>) => t.Type<PortionPercent<Num>, O, I>;
};

const build = <Num>(num: ExtendedCalculator<Num> & HasFromNumber<Num>): PortionPercentModule<Num> => {
  const PercentNum = Percent.build(num);

  const is = (a: Num): a is PortionPercent<Num> => {
    return PercentNum.is(a) && num.greaterThanOrEqual(a, num.zero()) && num.lessThanOrEqual(a, num.fromNumber(100, 0));
  };

  const ofValue = (n: Num, percent: PortionPercent<Num>, decimals: number) => {
    return PercentNum.ofValue(n, percent, decimals);
  };

  const unsafeFrom = (n: Num): PortionPercent<Num> => {
    if (!is(n)) {
      throw new Error('Invalid PortionPercent value');
    }

    return n;
  };

  const zeroValue = unsafeFrom(num.zero());

  const zero = (): PortionPercent<Num> => {
    return zeroValue;
  };

  const json = <O, I>(codec: t.Type<Num, O, I>): t.Type<PortionPercent<Num>, O, I> => {
    return t.brand(codec, is, 'PortionPercent');
  };

  return {
    is,
    unsafeFrom,
    ofValue,
    zero,
    json,
  };
};

export const PortionPercent = {
  build,
};
