import { AppointmentId } from './appointment-id';
import { AppointmentIntentId } from './appointmentIntentId';
import { JSONable, MeroUnits, Money, ScaledNumber, optionull } from '@mero/shared-sdk';
import * as t from 'io-ts';

type PaymentRequired = {
  readonly type: 'PaymentRequired';
  readonly appointmentIntentId: AppointmentIntentId;
  readonly total: Money<ScaledNumber, MeroUnits.RON>;
  readonly intent: {
    readonly id: string;
    readonly status: 'requires_payment_method' | 'requires_confirmation' | 'requires_action';
    readonly clientSecret: string | undefined;
  };
  readonly stripeAccount: {
    readonly id: string;
    /**
     * @deprecated
     */
    readonly publishableKey: string | undefined;
  };
};

type Processing = {
  readonly type: 'Processing';
  readonly appointmentIntentId: AppointmentIntentId;
};

type Failed = {
  readonly type: 'Failed';
  readonly message?: string;
};

type Success = {
  readonly type: 'Success';
  readonly appointmentId: AppointmentId;
};

export type BookAppointment2Response = PaymentRequired | Processing | Failed | Success;

const paymentRequired = (params: Omit<PaymentRequired, 'type'>): PaymentRequired => ({
  ...params,
  type: 'PaymentRequired',
});

const processing = (params: Omit<Processing, 'type'>): Processing => ({
  ...params,
  type: 'Processing',
});

const failed = (params: Omit<Failed, 'type'>): Failed => ({
  ...params,
  type: 'Failed',
});

const success = (params: Omit<Success, 'type'>): Success => ({
  ...params,
  type: 'Success',
});

const JSON: t.Type<BookAppointment2Response, JSONable> = t.union(
  [
    t.type(
      {
        type: t.literal('PaymentRequired'),
        appointmentIntentId: AppointmentIntentId.JSON,
        total: Money.json(ScaledNumber.JSON, MeroUnits.RON.JSON),
        intent: t.type(
          {
            id: t.string,
            status: t.union([
              t.literal('requires_payment_method'),
              t.literal('requires_confirmation'),
              t.literal('requires_action'),
            ]),
            clientSecret: optionull(t.string),
          },
          'Intent',
        ),
        stripeAccount: t.type(
          {
            id: t.string,
            publishableKey: optionull(t.string),
          },
          'StripeAccount',
        ),
      },
      'PaymentRequired',
    ),
    t.intersection(
      [
        t.type(
          {
            type: t.literal('Failed'),
          },
          '!',
        ),
        t.partial(
          {
            message: t.string,
          },
          '?',
        ),
      ],
      'Failed',
    ),
    t.type(
      {
        type: t.literal('Processing'),
        appointmentIntentId: AppointmentIntentId.JSON,
      },
      'Processing',
    ),
    t.type(
      {
        type: t.literal('Success'),
        appointmentId: AppointmentId,
      },
      'Success',
    ),
  ],
  'BookAppointment2Response',
);

export const BookAppointment2Response = {
  JSON,
  paymentRequired,
  processing,
  failed,
  success,
};
