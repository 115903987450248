import { Accepted } from './accepted';
import { Cancelled } from './cancelled';
import { NoShow } from './noShow';
import { Pending } from './pending';
import { Rejected } from './rejected';
import * as t from 'io-ts';

export type Any = Pending | Accepted | Rejected | NoShow | Cancelled;

const JSON = t.union([Pending.JSON, Accepted.JSON, Rejected.JSON, NoShow.JSON, Cancelled.JSON], 'Any');

export const Any = {
  JSON,
};
