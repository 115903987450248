import * as t from 'io-ts';

export type Year = 'y' | 'year' | 'years';

const JSON: t.Type<Year, string> = t.union([t.literal('y'), t.literal('year'), t.literal('years')], 'Year');

export const Year = {
  JSON,
  is: JSON.is,
};
