import { CheckoutItemVatStatus } from './checkoutItemVatStatus';
import { CheckoutTotals } from './checkoutTotals';
import { CompanyVatStatus } from './companies';
import { AppliedDiscount, ExtendedCalculator, HasFromNumber, MeroUnits, Money, PortionPercent } from '@mero/shared-sdk';

/**
 * A total of a transaction item or a transaction
 */
export type CheckoutItemTotal<Num, Unit extends MeroUnits.Any> = {
  /**
   * Total calculated amount
   */
  readonly amount: Money<Num, Unit>;
  /**
   * Discount, if set, is applied to the total value (including TVA)
   */
  readonly discount?: AppliedDiscount<Num, Unit>;
  /**
   * VAT status for the {@link CheckoutItemTotal['amount']}
   */
  readonly vatStatus: CheckoutItemVatStatus.Any<Num>;
};

export type CheckoutItemTotalModule<Num> = {
  /**
   * @returns amount to pay, including VAT but without discount applied
   */
  readonly getSubtotal: <Unit extends MeroUnits.Any>(
    itemTotal: CheckoutItemTotal<Num, Unit>,
    companyVat: CompanyVatStatus.Any<Num>,
    decimals: number,
  ) => Money<Num, Unit>;

  /**
   * Returns applied discount value or undefined if not discount is set for the {@link itemTotal}
   *
   * @returns undefined if no discount is set or applied discount value (capped to subtotal)
   */
  readonly getDiscount: <Unit extends MeroUnits.Any>(
    itemTotal: CheckoutItemTotal<Num, Unit>,
    companyVat: CompanyVatStatus.Any<Num>,
    decimals: number,
  ) => Money<Num, Unit> | undefined;

  /**
   * @returns Total amount to pay, including VAT with discount applied
   */
  readonly getTotal: <Unit extends MeroUnits.Any>(
    itemTotal: CheckoutItemTotal<Num, Unit>,
    companyVat: CompanyVatStatus.Any<Num>,
    decimals: number,
  ) => Money<Num, Unit>;

  /**
   * VatAmount = NoVATProductPrice * VatPercent / 100
   * Total = NoVATProductPrice + VatAmount
   * VatFromTotal = Total * VatPercent / (100 + VatPercent)
   *
   * @returns undefined if there is no VAT or VAT amount included in total value
   */
  readonly getVat: <Unit extends MeroUnits.Any>(
    itemTotal: CheckoutItemTotal<Num, Unit>,
    companyVat: CompanyVatStatus.Any<Num>,
    decimals: number,
  ) => Money<Num, Unit> | undefined;

  /**
   * Get totals for given item
   */
  readonly getTotals: <Unit extends MeroUnits.Any>(
    itemTotal: CheckoutItemTotal<Num, Unit>,
    companyVat: CompanyVatStatus.Any<Num>,
    decimals: number,
  ) => CheckoutTotals<Num, Unit>;
};

/**
 * Build new instance of CheckoutItemTotalModule<Num, Unit>
 */
const build = <Num>(num: ExtendedCalculator<Num> & HasFromNumber<Num>): CheckoutItemTotalModule<Num> => {
  const PortionPercentNum = PortionPercent.build(num);

  const getSubtotal = <Unit extends MeroUnits.Any>(
    itemTotal: CheckoutItemTotal<Num, Unit>,
    companyVat: CompanyVatStatus.Any<Num>,
    decimals: number,
  ): Money<Num, Unit> => {
    if (companyVat.type === 'NoVat') {
      return Money.of(num.round(itemTotal.amount.amount, decimals), itemTotal.amount.unit);
    } else {
      switch (itemTotal.vatStatus.type) {
        case 'Included': {
          return Money.of(num.round(itemTotal.amount.amount, decimals), itemTotal.amount.unit);
        }
        case 'Excluded': {
          // Using explicit computation (instead of Percent.ofValue) to avoid intermediate rounding
          const vatRate = itemTotal.vatStatus.rate ?? companyVat.defaultRate;
          const oneHundred = num.fromNumber(100, 0);

          const subtotal = num.div(
            num.mul(itemTotal.amount.amount, num.add(oneHundred, vatRate)),
            oneHundred,
            decimals,
          );

          return Money.of(subtotal, itemTotal.amount.unit);
        }
      }
    }
  };

  const getDiscount = <Unit extends MeroUnits.Any>(
    itemTotal: CheckoutItemTotal<Num, Unit>,
    companyVat: CompanyVatStatus.Any<Num>,
    decimals: number,
  ): Money<Num, Unit> | undefined => {
    if (itemTotal.discount) {
      const subtotal = getSubtotal(itemTotal, companyVat, decimals);

      switch (itemTotal.discount.type) {
        case 'Percent': {
          const discount = PortionPercentNum.ofValue(subtotal.amount, itemTotal.discount.percent, decimals);

          return Money.of(discount, subtotal.unit);
        }
        case 'Value': {
          const discount = num.round(itemTotal.discount.value.amount, decimals);

          if (num.greaterThan(discount, subtotal.amount)) {
            return subtotal;
          } else {
            return Money.of(discount, subtotal.unit);
          }
        }
      }
    } else {
      return undefined;
    }
  };

  const getTotal = <Unit extends MeroUnits.Any>(
    itemTotal: CheckoutItemTotal<Num, Unit>,
    companyVat: CompanyVatStatus.Any<Num>,
    decimals: number,
  ): Money<Num, Unit> => {
    const subtotal = getSubtotal(itemTotal, companyVat, decimals);
    const discount = getDiscount(itemTotal, companyVat, decimals);

    if (discount) {
      return Money.of(num.sub(subtotal.amount, discount.amount), subtotal.unit);
    } else {
      return subtotal;
    }
  };

  const getVat = <Unit extends MeroUnits.Any>(
    itemTotal: CheckoutItemTotal<Num, Unit>,
    companyVat: CompanyVatStatus.Any<Num>,
    decimals: number,
  ): Money<Num, Unit> | undefined => {
    if (companyVat.type === 'NoVat') {
      return undefined;
    } else {
      const finalTotal = getTotal(itemTotal, companyVat, decimals);
      const vatRate = itemTotal.vatStatus.rate ?? companyVat.defaultRate;

      // finalTotal already includes VAT
      const vatValue = num.div(num.mul(finalTotal.amount, vatRate), num.add(num.fromNumber(100, 0), vatRate), decimals);

      return Money.of(vatValue, itemTotal.amount.unit);
    }
  };

  const getTotals = <Unit extends MeroUnits.Any>(
    itemTotal: CheckoutItemTotal<Num, Unit>,
    companyVat: CompanyVatStatus.Any<Num>,
    decimals: number,
  ): CheckoutTotals<Num, Unit> => {
    const subtotal = getSubtotal(itemTotal, companyVat, decimals);
    const discount = getDiscount(itemTotal, companyVat, decimals);
    const vat = getVat(itemTotal, companyVat, decimals);
    const total = getTotal(itemTotal, companyVat, decimals);

    return {
      subtotal,
      discount,
      vat,
      total,
    };
  };

  return {
    getSubtotal,
    getDiscount,
    getTotal,
    getVat,
    getTotals,
  };
};

export const CheckoutItemTotal = {
  build,
};
