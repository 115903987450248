import { SubscriptionFeatureId } from './subscriptionFeatureId';
import { SubscriptionFeaturePreview } from './subscriptionFeaturePreview';
import { isSome, JSONable, None, option, Option, some, SubscriptionTierId } from '@mero/shared-sdk';
import * as t from 'io-ts';

export type SubscriptionTierDetails = {
  readonly _id: SubscriptionTierId;
  readonly name: string;
  readonly lower: Option<SubscriptionTierDetails>;
  readonly higher: Option<SubscriptionTierDetails>;
  /**
   * All subscription features, including from lower tier
   */
  readonly features: SubscriptionFeaturePreview[];
};

const JSON: t.Type<SubscriptionTierDetails, JSONable> = t.recursion('SubscriptionTierDetails', (self) => {
  return t.type(
    {
      _id: SubscriptionTierId.JSON,
      name: t.string,
      lower: Option.json(self),
      higher: Option.json(self),
      features: t.array(SubscriptionFeaturePreview.JSON),
    },
    'SubscriptionTierDetails',
  );
});

/**
 * Get feature from given tier
 */
const getFeature = (
  tierDetails: Pick<SubscriptionTierDetails, 'features'>,
  featureId: SubscriptionFeatureId,
): Option<SubscriptionFeaturePreview> => {
  return option(
    tierDetails.features.find((preview) => {
      return SubscriptionFeatureId.equals(preview._id, featureId);
    }),
  );
};

/**
 * Check if tier includes specific feature
 */
const includesFeature = (
  tierDetails: Pick<SubscriptionTierDetails, 'features'>,
  featureId: SubscriptionFeatureId,
): boolean => {
  return isSome(getFeature(tierDetails, featureId));
};

/**
 * Find feature and the tier where that includes the feature
 * Starts looking from given tier and _higher_ until found,
 *  (assuming that current tier contains all the features from lower tiers)
 */
const findFeature = (
  tierDetails: SubscriptionTierDetails,
  featureId: SubscriptionFeatureId,
): Option<{ tier: SubscriptionTierDetails; feature: SubscriptionFeaturePreview }> => {
  // to avoid infinite loops
  const circuitBreaker: SubscriptionTierId[] = [];
  let tier: Option<SubscriptionTierDetails> = tierDetails;

  while (isSome(tier) && !circuitBreaker.includes(tier._id)) {
    const feature = getFeature(tier, featureId);
    if (isSome(feature)) {
      return some({
        tier: tier,
        feature: feature,
      });
    }

    // lookup in higher tiers, if available
    circuitBreaker.push(tier._id);
    tier = tier.higher;
  }

  return None;
};

export const SubscriptionTierDetails = {
  JSON,
  includesFeature,
  findFeature,
};
