import { ObjectId } from '@mero/shared-sdk';
import * as t from 'io-ts';

export interface ProductIdBrand {
  readonly ProductId: unique symbol;
}

export type ProductId = t.Branded<ObjectId, ProductIdBrand>;

export const ProductId = t.brand(
  ObjectId,
  (_id: ObjectId): _id is t.Branded<ObjectId, ProductIdBrand> => true,
  'ProductId',
);
