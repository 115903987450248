import * as t from 'io-ts';

export type Never = undefined;

const JSON = t.undefined;

export const NEVER: Never = undefined;

export const Never = {
  JSON,
  is: JSON.is,
};
