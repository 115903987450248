import { AppointmentId, AppointmentStatus } from '../calendar';
import { CalendarId } from '../calendar/calendar-id';
import { WaitingListId } from '../calendar/waiting-list-id';
import { ClientId } from '../clients/client-id';
import { InviteStatus } from '../clients/client-invite-status';
import { OptionalFirstname, OptionalLastname } from '../common';
import { CouponId } from '../coupons';
import { InviteId } from '../invites';
import { PageId } from '../pages';
import { ServiceId } from '../services';
import { UserId } from '../users';
import { WorkerId } from '../workers';
import { NotificationId } from './notification-id';
import * as NotificationTypes from './notification-type';
import { DateString, Option } from '@mero/shared-sdk';
import { optionull } from '@mero/shared-sdk';
import { ProfileImage } from '@mero/shared-sdk/dist/assets';
import * as t from 'io-ts';
import { DateFromISOString } from 'io-ts-types';

export const NotificationBase = t.type(
  {
    _id: NotificationId,
    userId: UserId,
    createdAt: DateFromISOString,
    seen: t.boolean,
  },
  'NotificationBase',
);

export interface NotificationBase extends t.TypeOf<typeof NotificationBase> {}

export const WorkerInviteNotification = t.intersection(
  [
    NotificationBase,
    t.type({
      type: NotificationTypes.WorkerInviteNotificationType,
      payload: t.type({
        invite: t.type({
          _id: InviteId,
          status: InviteStatus,
        }),
        page: t.type({
          _id: t.string,
          name: t.string,
          profilePhoto: optionull(ProfileImage),
        }),
      }),
    }),
  ],
  'WorkerInviteNotification',
);

export interface WorkerInviteNotification extends t.TypeOf<typeof WorkerInviteNotification> {}

export const WorkerInviteFulfilledNotification = t.intersection(
  [
    NotificationBase,
    t.type({
      type: NotificationTypes.WorkerInviteFulfilledNotificationType,
      payload: t.type({
        worker: t.type({
          _id: t.string,
          firstname: t.string,
          lastname: t.string,
          // profilePhoto: ProfileImage,
        }),
        accepted: t.boolean,
      }),
    }),
  ],
  'WorkerInviteFulfilledNotification',
);

export interface WorkerInviteFulfilledNotification extends t.TypeOf<typeof WorkerInviteFulfilledNotification> {}

export const PrivateWorkerClientJoinRequestNotification = t.intersection(
  [
    NotificationBase,
    t.type({
      type: NotificationTypes.PrivateWorkerClientJoinRequestNotificationType,
      payload: t.type({
        isForMe: t.boolean,
        invite: t.type({
          _id: InviteId,
          status: InviteStatus,
        }),
        client: t.intersection([
          t.type({
            firstname: t.string,
            lastname: t.string,
          }),
          t.partial({
            profilePhoto: optionull(ProfileImage),
          }),
        ]),
        worker: t.type({
          firstname: t.string,
          lastname: t.string,
          profilePhoto: optionull(ProfileImage),
        }),
      }),
    }),
  ],
  'PrivateWorkerClientJoinRequestNotification',
);

export interface PrivateWorkerClientJoinRequestNotification
  extends t.TypeOf<typeof PrivateWorkerClientJoinRequestNotification> {}

export const PrivateWorkerClientJoinRequestFulfilledNotification = t.intersection(
  [
    NotificationBase,
    t.type({
      type: NotificationTypes.PrivateWorkerClientJoinRequestFulfilledNotificationType,
      payload: t.type({
        worker: t.type({
          firstname: t.string,
          lastname: t.string,
          // profilePhoto: ProfileImage,
        }),
        page: t.type({
          name: t.string,
          slug: t.string,
          // profilePhoto: ProfileImage,
        }),
        accepted: t.boolean,
      }),
    }),
  ],
  'PrivateWorkerClientJoinRequestFulfilledNotification',
);

export interface PrivateWorkerClientJoinRequestFulfilledNotification
  extends t.TypeOf<typeof PrivateWorkerClientJoinRequestFulfilledNotification> {}

export const AppointmentRequestedNotification = t.intersection(
  [
    NotificationBase,
    t.type({
      type: NotificationTypes.AppointmentRequestedNotificationType,
      payload: t.type({
        page: t.type({
          _id: PageId,
          name: t.string,
          slug: t.string,
          profilePhoto: optionull(ProfileImage),
        }),
        worker: t.type({
          _id: WorkerId.JSON,
          firstname: t.string,
          lastname: t.string,
          profilePhoto: optionull(ProfileImage),
        }),
        client: t.type({
          _id: ClientId,
          firstname: OptionalFirstname,
          lastname: OptionalLastname,
          profilePhoto: optionull(ProfileImage),
        }),
        appointment: t.type({
          _id: AppointmentId,
          calendarId: CalendarId,
          start: DateFromISOString,
          status: AppointmentStatus,
          bookedServices: t.array(ServiceId),
          timezone: t.string,
          coupons: Option.json(
            t.array(
              t.type(
                {
                  _id: CouponId.JSON,
                  type: t.string,
                },
                'Coupon',
              ),
            ),
          ),
        }),
      }),
    }),
  ],
  'AppointmentRequestedNotification',
);

export interface AppointmentRequestedNotification extends t.TypeOf<typeof AppointmentRequestedNotification> {}

export const AppointmentConfirmedNotification = t.intersection(
  [
    NotificationBase,
    t.type({
      type: NotificationTypes.AppointmentConfirmedNotificationType,
      payload: t.type({
        page: t.type({
          _id: PageId,
          name: t.string,
          slug: t.string,
          // profilePhoto: ProfileImage,
        }),
        appointment: t.type({
          _id: AppointmentId,
          calendarId: t.string,
          start: DateFromISOString,
          status: AppointmentStatus,
          timezone: t.string,
        }),
      }),
    }),
  ],
  'AppointmentConfirmedNotification',
);

export interface AppointmentConfirmedNotification extends t.TypeOf<typeof AppointmentConfirmedNotification> {}

export const AppointmentMadeByPageNotification = t.intersection(
  [
    NotificationBase,
    t.type({
      type: NotificationTypes.AppointmentMadeByPageNotificationType,
      payload: t.type({
        page: t.type({
          _id: PageId,
          name: t.string,
          slug: t.string,
          // profilePhoto: ProfileImage,
        }),
        appointment: t.type({
          _id: AppointmentId,
          calendarId: t.string,
          start: DateFromISOString,
          status: AppointmentStatus,
          timezone: t.string,
        }),
      }),
    }),
  ],
  'AppointmentMadeByPageNotification',
);

export interface AppointmentMadeByPageNotification extends t.TypeOf<typeof AppointmentMadeByPageNotification> {}

export const AppointmentCancelledByClientNotification = t.intersection(
  [
    NotificationBase,
    t.type({
      type: NotificationTypes.AppointmentCancelledByClientNotificationType,
      payload: t.type({
        client: t.intersection([
          t.type({
            _id: t.string,
            firstname: t.string,
            lastname: t.string,
          }),
          t.partial({
            profilePhoto: optionull(ProfileImage),
          }),
        ]),
        appointment: t.intersection([
          t.type({
            _id: AppointmentId,
            start: DateFromISOString,
            timezone: t.string,
            occurrenceIndex: optionull(t.number),
          }),
          t.partial({
            calendarId: CalendarId,
          }),
        ]),
        page: t.type({
          _id: t.string,
          name: t.string,
        }),
        worker: t.union([
          t.undefined,
          t.type({
            _id: t.string,
            firstname: t.string,
            lastname: t.string,
          }),
        ]),
        reason: t.string,
      }),
    }),
  ],
  'AppointmentCancelledByClientNotification',
);

export interface AppointmentCancelledByClientNotification
  extends t.TypeOf<typeof AppointmentCancelledByClientNotification> {}

export const AppointmentCancelledByWorkerNotification = t.intersection(
  [
    NotificationBase,
    t.type({
      type: NotificationTypes.AppointmentCancelledByWorkerNotificationType,
      payload: t.type({
        client: t.type({
          _id: t.string, // FIXME: add ClientId type
          phone: t.string,
          firstname: OptionalFirstname,
          lastname: OptionalLastname,
        }),
        appointment: t.type({
          _id: AppointmentId,
          start: DateFromISOString,
          timezone: t.string, // FIXME: add Timezone type
          occurrenceIndex: optionull(t.number),
        }),
        page: t.intersection([
          t.type({
            _id: PageId,
            name: t.string,
          }),
          t.partial({
            profilePhoto: optionull(ProfileImage),
          }),
        ]),
      }),
    }),
  ],
  'AppointmentCancelledByWorkerNotification',
);

export interface AppointmentCancelledByWorkerNotification
  extends t.TypeOf<typeof AppointmentCancelledByWorkerNotification> {}

export const AppointmentModifiedNotification = t.intersection(
  [
    NotificationBase,
    t.type({
      type: NotificationTypes.AppointmentModifiedNotificationType,
      payload: t.type({
        appointment: t.type({
          _id: AppointmentId,
          calendarId: t.string,
          timezone: t.string,
          occurrenceIndex: optionull(t.number),
        }),
        client: t.intersection([
          t.type({
            _id: t.string, // FIXME: add ClientId type
            firstname: t.string,
            lastname: t.string,
          }),
          t.partial({
            profilePhoto: optionull(ProfileImage),
          }),
        ]),
        worker: t.type({
          _id: WorkerId.JSON,
          firstname: t.string,
          lastname: t.string,
          // profilePhoto: optionull(ProfileImage),
        }),
        page: t.type({
          _id: PageId,
          name: t.string,
          // profilePhoto: optionull(ProfileImage),
        }),
        from: t.type({
          start: DateFromISOString,
          end: DateFromISOString,
        }),
        to: t.type({
          start: DateFromISOString,
          end: DateFromISOString,
        }),
      }),
    }),
  ],
  'AppointmentModifiedNotification',
);

export interface AppointmentModifiedNotification extends t.TypeOf<typeof AppointmentModifiedNotification> {}

export const AppointmentOpenningNotification = t.intersection(
  [
    NotificationBase,
    t.type({
      type: NotificationTypes.AppointmentOpenningNotificationType,
      payload: t.type({
        worker: t.type({
          firstname: t.string,
          lastname: t.string,
          // profilePhoto: ProfileImage,
        }),
        slug: t.union([t.string, t.undefined]),
        clientName: t.string,
        date: DateFromISOString,
        // timezone: t.string,
      }),
    }),
  ],
  'AppointmentOpenningNotification',
);

export interface AppointmentOpenningNotification extends t.TypeOf<typeof AppointmentOpenningNotification> {}

export const AppointmentReminderNotification = t.intersection(
  [
    NotificationBase,
    t.type({
      type: NotificationTypes.AppointmentReminderNotificationType,
      payload: t.type({
        page: t.type({
          _id: PageId,
          name: t.string,
          // profilePhoto: ProfileImage,
        }),
        worker: t.type({
          _id: WorkerId.JSON,
          firstname: t.string,
          lastname: t.string,
          // profilePhoto: ProfileImage,
        }),
        client: t.type({
          _id: t.string, // FIXME: Add ClientId type
          profile: t.type({
            firstname: t.string,
            lastname: t.string,
          }),
        }),
        appointment: t.type({
          _id: AppointmentId,
          calendarId: CalendarId,
          start: DateFromISOString,
          end: DateFromISOString,
          timezone: t.string,
          occurrenceIndex: optionull(t.number),
        }),
      }),
    }),
  ],
  'AppointmentReminderNotification',
);

export interface AppointmentReminderNotification extends t.TypeOf<typeof AppointmentReminderNotification> {}

export const AppointmentReviewReminderNotification = t.intersection(
  [
    NotificationBase,
    t.type({
      type: NotificationTypes.AppointmentReviewReminderNotificationType,
      payload: t.type({
        page: t.type({
          _id: PageId,
          name: t.string,
          // profilePhoto: ProfileImage,
        }),
        worker: t.type({
          _id: WorkerId.JSON,
          firstname: t.string,
          lastname: t.string,
          // profilePhoto: ProfileImage,
        }),
        client: t.type({
          _id: t.string, // FIXME: Add ClientId type
          profile: t.type({
            firstname: t.string,
            lastname: t.string,
          }),
        }),
        appointment: t.type({
          _id: AppointmentId,
          calendarId: CalendarId,
          start: DateFromISOString,
          end: DateFromISOString,
          timezone: t.string,
          occurrenceIndex: optionull(t.number),
        }),
      }),
    }),
  ],
  'AppointmentReviewReminderNotification',
);

export interface AppointmentReviewReminderNotification extends t.TypeOf<typeof AppointmentReviewReminderNotification> {}

export const ClientAppointmentMadeNotification = t.intersection(
  [
    NotificationBase,
    t.type({
      type: NotificationTypes.ClientAppointmentMadeNotificationType,
      payload: t.type({
        isForMe: t.boolean,
        appointment: t.type({
          _id: AppointmentId,
          calendarId: CalendarId,
          start: DateFromISOString,
          timezone: t.string,
          coupons: Option.json(
            t.array(
              t.type(
                {
                  _id: CouponId.JSON,
                  type: t.string,
                },
                'Coupon',
              ),
            ),
          ),
        }),
        worker: t.type({
          _id: t.string,
          firstname: t.string,
          lastname: t.string,
        }),
        client: t.intersection([
          t.type({
            _id: t.string, // FIXME: add ClientId type
            firstname: t.string,
            lastname: t.string,
          }),
          t.partial({
            profilePhoto: optionull(ProfileImage),
          }),
        ]),
        page: t.type({
          _id: PageId,
          name: t.string,
        }),
      }),
    }),
  ],
  'ClientAppointmentMadeNotification',
);

export interface ClientAppointmentMadeNotification extends t.TypeOf<typeof ClientAppointmentMadeNotification> {}

export const PaymentSuccessfulNotification = t.intersection(
  [
    NotificationBase,
    t.type({
      type: NotificationTypes.PaymentSuccessfulNotificationType,
      payload: t.type({
        appointment: t.type({
          _id: AppointmentId,
        }),
        worker: t.type({
          _id: WorkerId.JSON,
          firstname: t.string,
          lastname: t.string,
        }),
        page: t.type({
          _id: PageId,
          name: t.string,
          // profilePhoto: ProfileImage,
        }),
        amount: t.number,
      }),
    }),
  ],
  'PaymentSuccessfulNotification',
);

export interface PaymentSuccessfulNotification extends t.TypeOf<typeof PaymentSuccessfulNotification> {}

export const ClientAppointmentPaymentReceivedNotification = t.intersection(
  [
    NotificationBase,
    t.type({
      type: NotificationTypes.ClientAppointmentPaymentReceivedNotificationType,
      payload: t.type({
        isForMe: t.boolean,
        appointment: t.type({
          _id: AppointmentId,
          calendarId: CalendarId,
        }),
        worker: t.type({
          _id: WorkerId.JSON,
          firstname: t.string,
          lastname: t.string,
        }),
        page: t.type({
          _id: PageId,
          name: t.string,
        }),
        client: t.intersection([
          t.type({
            _id: t.string, // FIXME: add ClientId type, or is it UserId?
            firstname: t.string,
            lastname: t.string,
          }),
          t.partial({
            profilePhoto: optionull(ProfileImage),
          }),
        ]),
        amount: t.number,
      }),
    }),
  ],
  'ClientAppointmentPaymentReceivedNotification',
);

export interface ClientAppointmentPaymentReceivedNotification
  extends t.TypeOf<typeof ClientAppointmentPaymentReceivedNotification> {}

export const GenericMarketplaceNotification = t.intersection(
  [
    NotificationBase,
    t.type({
      type: NotificationTypes.GenericMarketplaceNotificationType,
      payload: t.type({
        text: t.string,
      }),
    }),
  ],
  'GenericMarketplaceNotification',
);

export interface GenericMarketplaceNotification extends t.TypeOf<typeof GenericMarketplaceNotification> {}

export const GenericProNotification = t.intersection(
  [
    NotificationBase,
    t.type({
      type: NotificationTypes.GenericProNotificationType,
      payload: t.intersection([
        t.type({
          text: t.string,
        }),
        t.partial({
          appLinkUrl: t.string,
          webLinkUrl: t.string,
          iconUrl: t.string,
        }),
      ]),
    }),
  ],
  'GenericProNotification',
);

export interface GenericProNotification extends t.TypeOf<typeof GenericProNotification> {}

export const AppointmentMadeByAnotherProNotification = t.intersection(
  [
    NotificationBase,
    t.type({
      type: NotificationTypes.AppointmentMadeByAnotherProNotificationType,
      payload: t.type({
        appointment: t.type(
          {
            _id: AppointmentId,
            calendarId: CalendarId,
            start: DateFromISOString,
            end: DateFromISOString,
            timezone: t.string,
          },
          'Appointment',
        ),
        client: optionull(
          t.type(
            {
              _id: ClientId,
              firstname: t.string,
              lastname: t.string,
            },
            'Client',
          ),
        ),
        worker: t.type(
          {
            _id: WorkerId.JSON,
            firstname: t.string,
            lastname: t.string,
            phone: optionull(t.string),
          },
          'Worker',
        ),
        addedByUser: t.type(
          {
            _id: UserId,
            firstname: t.string,
            lastname: t.string,
            phone: optionull(t.string),
          },
          'AddedByUser',
        ),
      }),
    }),
  ],
  'AppointmentMadeByAnotherProNotification',
);

export interface AppointmentMadeByAnotherProNotification
  extends t.TypeOf<typeof AppointmentMadeByAnotherProNotification> {}

export const MeroSmsIssueNotification = t.intersection(
  [
    NotificationBase,
    t.type({
      type: NotificationTypes.MeroSmsIssueNotificationType,
      payload: t.type({
        text: t.string,
      }),
    }),
  ],
  'MeroSmsIssueNotification',
);

export interface MeroSmsIssueNotification extends t.TypeOf<typeof MeroSmsIssueNotification> {}

export const AppointmentCancelledByAnotherProNotification = t.intersection(
  [
    NotificationBase,
    t.type({
      type: NotificationTypes.AppointmentCancelledByAnotherProNotificationType,
      payload: t.type(
        {
          appointment: t.type(
            {
              _id: AppointmentId,
              calendarId: CalendarId,
              start: DateFromISOString,
              end: DateFromISOString,
              timezone: t.string,
              occurrenceIndex: optionull(t.number),
            },
            'Appointment',
          ),
          client: optionull(
            t.type(
              {
                _id: ClientId,
                firstname: t.string,
                lastname: t.string,
              },
              'Client',
            ),
          ),
          //worker the appointment is assigned to
          worker: t.type(
            {
              _id: WorkerId.JSON,
              firstname: t.string,
              lastname: t.string,
              phone: t.string,
            },
            'Worker',
          ),
          cancelledByUser: t.type(
            {
              _id: UserId,
              firstname: t.string,
              lastname: t.string,
              phone: t.string,
            },
            'CancelledByUser',
          ),
        },
        'Payload',
      ),
    }),
  ],
  'AppointmentCancelledByAnotherProNotification',
);

export interface AppointmentCancelledByAnotherProNotification
  extends t.TypeOf<typeof AppointmentCancelledByAnotherProNotification> {}

export const AppointmentModifiedByAnotherProNotification = t.intersection(
  [
    NotificationBase,
    t.type({
      type: NotificationTypes.AppointmentModifiedByAnotherProNotificationType,
      payload: t.type(
        {
          appointment: t.type(
            {
              _id: AppointmentId,
              calendarId: CalendarId,
              start: DateFromISOString,
              end: DateFromISOString,
              timezone: t.string,
              occurrenceIndex: optionull(t.number),
            },
            'Appointment',
          ),
          client: optionull(
            t.type(
              {
                _id: ClientId,
                firstname: t.string,
                lastname: t.string,
              },
              'Client',
            ),
          ),
          worker: t.type(
            {
              _id: WorkerId.JSON,
              firstname: t.string,
              lastname: t.string,
              phone: t.string,
            },
            'Worker',
          ),
          modifiedByUser: t.type(
            {
              _id: UserId,
              firstname: t.string,
              lastname: t.string,
              phone: t.string,
            },
            'ModifiedByUser',
          ),
        },
        'Payload',
      ),
    }),
  ],
  'AppointmentModifiedByAnotherProNotification',
);

export interface AppointmentModifiedByAnotherProNotification
  extends t.TypeOf<typeof AppointmentModifiedByAnotherProNotification> {}

/**
 * Notification for management about appointment changes, for user which is not appointment worker or who made the change
 */
export const GenericAppointmentActionNotification = t.intersection(
  [
    NotificationBase,
    t.type({
      type: NotificationTypes.GenericAppointmentActionNotificationType,
      payload: t.type(
        {
          appointment: t.type(
            {
              _id: AppointmentId,
              calendarId: CalendarId,
              start: DateFromISOString,
              end: DateFromISOString,
              timezone: t.string,
              occurrenceIndex: optionull(t.number),
            },
            'Appointment',
          ),
          client: optionull(
            t.type(
              {
                _id: ClientId,
                firstname: t.string,
                lastname: t.string,
              },
              'Client',
            ),
          ),
          worker: t.type(
            {
              _id: WorkerId.JSON,
              firstname: t.string,
              lastname: t.string,
              phone: t.string,
            },
            'Worker',
          ),
          byUser: t.type(
            {
              _id: UserId,
              firstname: t.string,
              lastname: t.string,
              phone: t.string,
            },
            'ByUser',
          ),
          action: t.union(
            [
              t.type(
                {
                  type: t.literal('Added'),
                },
                'Action.Added',
              ),
              t.type(
                {
                  type: t.literal('Modified'),
                },
                'Action.Modified',
              ),
              t.type(
                {
                  type: t.literal('Cancelled'),
                },
                'Action.Cancelled',
              ),
              t.type(
                {
                  type: t.literal('Accepted'),
                },
                'Action.Accepted',
              ),
              t.type(
                {
                  type: t.literal('Rejected'),
                },
                'Action.Rejected',
              ),
            ],
            'Action',
          ),
        },
        'Payload',
      ),
    }),
  ],
  'GenericAppointmentActionNotification',
);

export interface GenericAppointmentActionNotification extends t.TypeOf<typeof GenericAppointmentActionNotification> {}

export const ClientAddedToWaintingListNotification = t.intersection(
  [
    NotificationBase,
    t.type({
      type: NotificationTypes.ClientAddedToWaintingListNotificationType,
      payload: t.type(
        {
          client: t.type(
            {
              userId: t.string,
              firstname: t.string,
              lastname: t.string,
            },
            'Client',
          ),
          worker: t.type(
            {
              _id: t.string,
              firstname: t.string,
              lastname: t.string,
            },
            'Worker',
          ),
          waitingListEntryId: WaitingListId,
          date: DateString,
        },
        'Payload',
      ),
    }),
  ],
  'ClientAddedToWaintingListNotification',
);

export interface ClientAddedToWaintingListNotification extends t.TypeOf<typeof ClientAddedToWaintingListNotification> {}

export const AuthorizationCodeRequestNotification = t.intersection(
  [
    NotificationBase,
    t.type({
      type: NotificationTypes.AuthorizationCodeRequestNotificationType,
      payload: t.type(
        {
          text: t.string,
        },
        'Payload',
      ),
    }),
  ],
  'AuthorizationCodeRequestNotification',
);

export interface AuthorizationCodeRequestNotification extends t.TypeOf<typeof AuthorizationCodeRequestNotification> {}

export const Notification = t.union(
  [
    WorkerInviteNotification,
    WorkerInviteFulfilledNotification,
    PrivateWorkerClientJoinRequestNotification,
    PrivateWorkerClientJoinRequestFulfilledNotification,
    AppointmentRequestedNotification,
    AppointmentConfirmedNotification,
    AppointmentMadeByPageNotification,
    AppointmentCancelledByClientNotification,
    AppointmentCancelledByWorkerNotification,
    AppointmentModifiedNotification,
    AppointmentOpenningNotification,
    AppointmentReminderNotification,
    ClientAppointmentMadeNotification,
    PaymentSuccessfulNotification,
    ClientAppointmentPaymentReceivedNotification,
    GenericMarketplaceNotification,
    GenericProNotification,
    AppointmentReviewReminderNotification,
    AppointmentMadeByAnotherProNotification,
    MeroSmsIssueNotification,
    AppointmentCancelledByAnotherProNotification,
    AppointmentModifiedByAnotherProNotification,
    GenericAppointmentActionNotification,
    ClientAddedToWaintingListNotification,
    AuthorizationCodeRequestNotification,
  ],
  'Notification',
);

export type Notification = t.TypeOf<typeof Notification>;

export const NotificationArray = t.array(Notification);

export type NotificationArray = t.TypeOf<typeof NotificationArray>;

export const WorkerActivityNotification = t.union(
  [
    WorkerInviteFulfilledNotification,
    AppointmentCancelledByClientNotification,
    AppointmentRequestedNotification,
    AppointmentMadeByAnotherProNotification,
    ClientAppointmentMadeNotification,
    ClientAppointmentPaymentReceivedNotification,
    GenericProNotification,
    AppointmentCancelledByAnotherProNotification,
    AppointmentModifiedByAnotherProNotification,
    GenericAppointmentActionNotification,
    ClientAddedToWaintingListNotification,
    MeroSmsIssueNotification,
    AuthorizationCodeRequestNotification,
  ],
  'WorkerActivityNotification',
);

export type WorkerActivityNotification = t.TypeOf<typeof WorkerActivityNotification>;

export const WorkerRequestsNotification = t.union(
  [WorkerInviteNotification, PrivateWorkerClientJoinRequestNotification, AppointmentRequestedNotification],
  'WorkerRequestsNotification',
);

export type WorkerRequestsNotification = t.TypeOf<typeof WorkerRequestsNotification>;

/**
 * Notification subtype with notifications only sent to PROs (workers, managers, ...)
 */
export const WorkerNotification = t.union(
  [
    WorkerInviteNotification,
    WorkerInviteFulfilledNotification,
    PrivateWorkerClientJoinRequestNotification,
    AppointmentRequestedNotification,
    AppointmentCancelledByClientNotification,
    ClientAppointmentMadeNotification,
    ClientAppointmentPaymentReceivedNotification,
    GenericProNotification,
    MeroSmsIssueNotification,
    AppointmentMadeByAnotherProNotification,
    AppointmentCancelledByAnotherProNotification,
    AppointmentModifiedByAnotherProNotification,
    GenericAppointmentActionNotification,
    ClientAddedToWaintingListNotification,
    AuthorizationCodeRequestNotification,
  ],
  'WorkerNotification',
);

export type WorkerNotification = t.TypeOf<typeof WorkerNotification>;

/**
 * Notification subtype with notifications only sent to clients
 */
export const ClientNotification = t.union(
  [
    PrivateWorkerClientJoinRequestFulfilledNotification,
    AppointmentConfirmedNotification,
    AppointmentMadeByPageNotification,
    AppointmentCancelledByWorkerNotification,
    AppointmentModifiedNotification,
    AppointmentOpenningNotification,
    AppointmentReminderNotification,
    AppointmentReviewReminderNotification,
    PaymentSuccessfulNotification,
    GenericMarketplaceNotification,
  ],
  'ClientNotification',
);

export type ClientNotification = t.TypeOf<typeof ClientNotification>;

/**
 * @deprecated Use `ClientNotification.is`
 */
export const isClientNotification = (n: Notification): n is ClientNotification =>
  NotificationTypes.ClientNotificationType.is(n.type);
