import { ObjectId } from '@mero/shared-sdk';
import * as t from 'io-ts';

export interface ServiceGroupIdBrand {
  readonly ServiceGroupId: unique symbol;
}

export const ServiceGroupId = t.brand(
  ObjectId,
  (_id: ObjectId): _id is t.Branded<ObjectId, ServiceGroupIdBrand> => true,
  'ServiceGroupId',
);

export type ServiceGroupId = t.TypeOf<typeof ServiceGroupId>;
