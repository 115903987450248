import { ObjectId } from '@mero/shared-sdk';
import * as t from 'io-ts';

export interface MembershipPurchaseIdBrand {
  readonly MembershipPurchaseId: unique symbol;
}

/**
 * Future membership purchase identifier
 * This identifier is generated before a membership is purchased, to be able to identify single
 *  membership purchase without creating it in the database
 */
export type MembershipPurchaseId = t.Branded<ObjectId, MembershipPurchaseIdBrand>;

const JSON: t.Type<MembershipPurchaseId, string> = t.brand(
  ObjectId,
  (_id: ObjectId): _id is MembershipPurchaseId => true,
  'MembershipPurchaseId',
);

export const MembershipPurchaseId = {
  JSON,
};
