import { HasOptionalFirstLastName } from '../common';
import { HasUserId } from '../users/user-id';
import { DateString, PhoneNumberParser, optionull } from '@mero/shared-sdk';
import { ProfileImage } from '@mero/shared-sdk/dist/assets/profile-image';
import * as t from 'io-ts';

export const ClientUser = t.intersection(
  [
    HasUserId,
    HasOptionalFirstLastName,
    t.type({
      phone: PhoneNumberParser,
      fullname: t.string,
      email: optionull(t.string),
      birthday: optionull(DateString),
    }),
    t.partial({
      photo: optionull(ProfileImage),
      facebookId: optionull(t.string),
    }),
  ],
  'ClientUser',
);

export interface ClientUser extends t.TypeOf<typeof ClientUser> {}
