import { FeatureFlagsPro } from './featureFlagsPro';
import { JSONable } from '@mero/shared-sdk';
import * as t from 'io-ts';

export type ProBootState = {
  readonly featureFlags: FeatureFlagsPro;
  readonly shouldUpdateApp: boolean;
};

const JSON: t.Type<ProBootState, JSONable> = t.type(
  {
    featureFlags: FeatureFlagsPro,
    shouldUpdateApp: t.boolean,
  },
  'ProBootState',
);

export const ProBootState = {
  JSON,
};
