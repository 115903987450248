import { PageId } from '../pages';
import { InvoiceId } from './invoice-id';
import * as t from 'io-ts';
import { DateFromISOString } from 'io-ts-types';

export const PageMonthlyInvoiceArchive = t.type({
  _id: InvoiceId,
  createdAt: DateFromISOString,
  pageId: PageId,
  invoicesCount: t.number,
  downloadUrl: t.string,
  _tag: t.type({
    month: t.number,
    year: t.number,
  }),
});

export type PageMonthlyInvoiceArchive = t.TypeOf<typeof PageMonthlyInvoiceArchive>;
