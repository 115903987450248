import { ServiceGroup } from './group';
import { SavedService } from './saved-service';
import * as t from 'io-ts';

export const GroupWithServices = t.type(
  {
    group: ServiceGroup,
    services: t.array(SavedService),
  },
  'GroupWithServices',
);
export interface GroupWithServices extends t.TypeOf<typeof GroupWithServices> {}
