import { ObjectId } from '@mero/shared-sdk';
import * as t from 'io-ts';

export interface BoostClientIdBrand {
  readonly BoostClientId: unique symbol;
}

export const BoostClientId = t.brand(
  ObjectId,
  (_id: ObjectId): _id is t.Branded<ObjectId, BoostClientIdBrand> => true,
  'BoostClientId',
);

export type BoostClientId = t.TypeOf<typeof BoostClientId>;

export const HasBoostClientId = t.type(
  {
    _id: BoostClientId,
  },
  'HasBoostClientId',
);
export interface HasBoostClientId extends t.TypeOf<typeof HasBoostClientId> {}
