import { PageId } from '../pages/page-id';
import { ServiceId } from '../services';
import { MembershipItem } from './membershipItem';
import * as MembershipJson from './membershipJson';
import { MembershipPurchaseId } from './membershipPurchaseId';
import { MembershipTemplateId } from './membershipTemplateId';
import { MembershipTemplateValidity } from './membershipTemplateValidity';
import { MembershipsApi } from './membershipsApi';
import { PurchasedMembershipHistoryRecordArray } from './purchasedMembershipHistoryRecord';
import { PurchasedMembershipHistoryType } from './purchasedMembershipHistoryType';
import {
  HttpClient,
  unsafeRight,
  UnknownApiError,
  UserId,
  MeroUnits,
  DefinedString,
  OptionalDefinedString,
  AppliedDiscount,
  ScaledNumber,
  DecodeError,
  Paged,
} from '@mero/shared-sdk';
import * as A from 'fp-ts/lib/Array';
import * as E from 'fp-ts/lib/Either';
import { pipe } from 'fp-ts/lib/function';
import * as t from 'io-ts';

export const membershipsHttpClient = (env: { apiBaseUrl: string; http: HttpClient }): MembershipsApi => {
  const { apiBaseUrl, http } = env;

  const unknownError = UnknownApiError;
  const createMembershipTemplateDecoder = http.decode.response(
    unknownError,
    t.type({ _id: MembershipTemplateId.JSON }),
  );
  const updateMembershipTemplateDecoder = http.decode.response(unknownError, t.unknown);
  const getMembershipTemplatesForPageDecoder = http.decode.response(
    unknownError,
    Paged.json(t.array(MembershipJson.AnyMembershipTemplateC)),
  );
  const getMembershipTemplatesByIdDecoder = http.decode.response(
    unknownError,
    MembershipJson.AnyMembershipTemplateDetailsC,
  );
  const deleteMembershipTemplateDecoder = http.decode.response(unknownError, t.unknown);
  const getPurchasedMembershipByIdDecoder = http.decode.response(
    unknownError,
    MembershipJson.AnyPurchasedMembershipDetailsC,
  );
  const updatePurchasedMembershipNoteDecoder = http.decode.response(unknownError, t.unknown);
  const getPageMembershipsDecoder = http.decode.response(
    unknownError,
    Paged.json(t.array(MembershipJson.AnyPurchasedMembershipC)),
  );
  const getPageMembershipsTotalsDecoder = http.decode.response(
    unknownError,
    MembershipJson.PurchasedMembershipsTotalsC(MeroUnits.RON.code),
  );
  const getMembershipsDecoder = http.decode.response(
    unknownError,
    Paged.json(t.array(MembershipJson.AnyPurchasedMembershipC)),
  );
  const cancelMembershipDecoder = http.decode.response(unknownError, t.unknown);
  const getMembershipPaymentsDecoder = http.decode.response(
    unknownError,
    t.array(MembershipJson.AnyMembershipPaymentC),
  );
  const isServiceInAnyMembershipOrTemplateDecoder = http.decode.response(unknownError, t.boolean);
  const getPurchasedMembershipConsumptionDecoder = http.decode.response(
    unknownError,
    t.array(MembershipJson.AnyMembershipConsumptionPerTransactionC),
  );
  const getPurchasedMembershipsAvailableForTransactionDecoder = http.decode.response(
    unknownError,
    t.array(MembershipJson.AnyAvailableMembershipItemsC),
  );

  return {
    createMembershipTemplate: async <Unit extends MeroUnits.Any>(params: {
      pageId: PageId;
      template: {
        name: DefinedString;
        unit: Unit;
        items: MembershipItem<Unit>[];
        validFor: MembershipTemplateValidity;
        description: OptionalDefinedString;
        discount: AppliedDiscount<ScaledNumber, Unit> | undefined;
        termsAndConditions: OptionalDefinedString;
      };
    }) => {
      const requestBody = params.template;
      return unsafeRight(
        await http.request(
          {
            method: 'POST',
            url: `${apiBaseUrl}/business/page/${encodeURIComponent(params.pageId)}/membership-templates`,
            data: requestBody,
          },
          createMembershipTemplateDecoder,
        ),
      );
    },
    getMembershipTemplatesForPage: async (params: {
      readonly pageId: PageId;
      readonly status?: 'Active' | 'Inactive';
      readonly name?: DefinedString;
      readonly limit: number;
      readonly page?: string;
    }) => {
      const query = {
        status: params.status,
        name: params.name,
        limit: params.limit,
        page: params.page,
      };
      return unsafeRight(
        await http.request(
          {
            method: 'GET',
            url: `${apiBaseUrl}/business/page/${encodeURIComponent(params.pageId)}/membership-templates/v2`,
            params: query,
          },
          getMembershipTemplatesForPageDecoder,
        ),
      );
    },
    getMembershipTemplateById: async (params: { pageId: PageId; membershipTemplateId: MembershipTemplateId }) => {
      return unsafeRight(
        await http.request(
          {
            method: 'GET',
            url: `${apiBaseUrl}/business/page/${encodeURIComponent(
              params.pageId,
            )}/membership-templates/${encodeURIComponent(params.membershipTemplateId)}`,
          },
          getMembershipTemplatesByIdDecoder,
        ),
      );
    },
    updateMembershipTemplate: async <Unit extends MeroUnits.Any>(params: {
      pageId: PageId;
      membershipTemplateId: MembershipTemplateId;
      template: {
        name: DefinedString;
        items: MembershipItem<Unit>[];
        validFor: MembershipTemplateValidity;
        status: 'Active' | 'Inactive';
        description: OptionalDefinedString;
        discount: AppliedDiscount<ScaledNumber, Unit> | undefined;
        termsAndConditions: OptionalDefinedString;
      };
    }) => {
      const requestBody = params.template;
      unsafeRight(
        await http.request(
          {
            method: 'PUT',
            url: `${apiBaseUrl}/business/page/${encodeURIComponent(
              params.pageId,
            )}/membership-templates/${encodeURIComponent(params.membershipTemplateId)}`,
            data: requestBody,
          },
          updateMembershipTemplateDecoder,
        ),
      );
    },
    deleteMembershipTemplate: async (params: { pageId: PageId; membershipTemplateId: MembershipTemplateId }) => {
      unsafeRight(
        await http.request(
          {
            method: 'DELETE',
            url: `${apiBaseUrl}/business/page/${encodeURIComponent(
              params.pageId,
            )}/membership-templates/${encodeURIComponent(params.membershipTemplateId)}`,
          },
          deleteMembershipTemplateDecoder,
        ),
      );
    },
    getPurchasedMembershipById: async (params: { pageId: PageId; membershipPurchaseId: MembershipPurchaseId }) => {
      return unsafeRight(
        await http.request(
          {
            method: 'GET',
            url: `${apiBaseUrl}/business/page/${encodeURIComponent(
              params.pageId,
            )}/purchased-memberships/${encodeURIComponent(params.membershipPurchaseId)}`,
          },
          getPurchasedMembershipByIdDecoder,
        ),
      );
    },
    getPagePurchasedMemberships: async (params: {
      readonly pageId: PageId;
      readonly query: {
        readonly purchasedAfter?: Date;
        readonly purchasedBefore?: Date;
        readonly status?: 'Active' | 'Consumed' | 'Cancelled' | 'Expired';
        readonly search?: string;
        readonly hasDebt?: boolean;
        readonly userId?: UserId;
      };
      readonly limit: number;
      readonly page?: string;
    }) => {
      const query = {
        purchasedAfter: params.query.purchasedAfter,
        purchasedBefore: params.query.purchasedBefore,
        status: params.query.status,
        search: params.query.search,
        hasDebt: params.query.hasDebt,
        userId: params.query.userId,
        limit: params.limit,
        page: params.page,
      };
      return unsafeRight(
        await http.request(
          {
            method: 'GET',
            url: `${apiBaseUrl}/business/page/${encodeURIComponent(params.pageId)}/purchased-memberships/v2`,
            params: query,
          },
          getPageMembershipsDecoder,
        ),
      );
    },
    getPagePurchasedMembershipsTotals: async (params) => {
      return unsafeRight(
        await http.request(
          {
            method: 'GET',
            url: `${apiBaseUrl}/business/page/${encodeURIComponent(params.pageId)}/purchased-memberships-totals`,
            params: params.query,
          },
          getPageMembershipsTotalsDecoder,
        ),
      );
    },
    getUserPurchasedMembershipsByPro: async (params: {
      readonly userId: UserId;
      readonly pageId: PageId;
      readonly status?: 'Active' | 'Consumed' | 'Cancelled';
      readonly limit: number;
      readonly page?: string;
    }) => {
      const query = {
        userId: params.userId,
        status: params.status,
        limit: params.limit,
        page: params.page,
      };
      return unsafeRight(
        await http.request(
          {
            method: 'GET',
            url: `${apiBaseUrl}/business/page/${params.pageId}/purchased-memberships/pro/v2`,
            params: query,
          },
          getMembershipsDecoder,
        ),
      );
    },
    updatePurchasedMembershipNote: async (params: {
      pageId: PageId;
      membershipPurchaseId: MembershipPurchaseId;
      note: DefinedString;
    }) => {
      const requestBody = { note: params.note };
      unsafeRight(
        await http.request(
          {
            method: 'PUT',
            url: `${apiBaseUrl}/business/page/${encodeURIComponent(
              params.pageId,
            )}/purchased-memberships/${encodeURIComponent(params.membershipPurchaseId)}/note`,
            data: requestBody,
          },
          updatePurchasedMembershipNoteDecoder,
        ),
      );
    },
    cancelPurchasedMembership: async (params: { pageId: PageId; membershipPurchaseId: MembershipPurchaseId }) => {
      unsafeRight(
        await http.request(
          {
            method: 'DELETE',
            url: `${apiBaseUrl}/business/page/${encodeURIComponent(
              params.pageId,
            )}/purchased-memberships/${encodeURIComponent(params.membershipPurchaseId)}`,
          },
          cancelMembershipDecoder,
        ),
      );
    },
    getPurchasedMembershipHistory: async (params: { pageId: PageId; membershipPurchaseId: MembershipPurchaseId }) => {
      const unknownHistoryRecordArray = http.decode.response(UnknownApiError, t.array(t.unknown));

      const unknownHistoryRecords = unsafeRight(
        await http.request(
          {
            method: 'GET',
            url: `${apiBaseUrl}/business/page/${encodeURIComponent(
              params.pageId,
            )}/purchased-memberships/${encodeURIComponent(params.membershipPurchaseId)}/history`,
          },
          unknownHistoryRecordArray,
        ),
      );
      const withHistoryTypeCodec = t.type({
        type: PurchasedMembershipHistoryType,
      });

      return pipe(
        unknownHistoryRecords,
        A.map(withHistoryTypeCodec.decode),
        A.chain(
          E.fold(
            () => [],
            (n) => [n],
          ),
        ),
        PurchasedMembershipHistoryRecordArray.decode,
        E.getOrElseW((e) => {
          throw new DecodeError(e);
        }),
      );
    },
    getPurchasedMembershipPayments: async (params: { pageId: PageId; membershipPurchaseId: MembershipPurchaseId }) => {
      return unsafeRight(
        await http.request(
          {
            method: 'GET',
            url: `${apiBaseUrl}/business/page/${encodeURIComponent(
              params.pageId,
            )}/purchased-memberships/${encodeURIComponent(params.membershipPurchaseId)}/payments`,
          },
          getMembershipPaymentsDecoder,
        ),
      );
    },
    getPurchasedMembershipConsumption: async (params: {
      pageId: PageId;
      membershipPurchaseId: MembershipPurchaseId;
    }) => {
      return unsafeRight(
        await http.request(
          {
            method: 'GET',
            url: `${apiBaseUrl}/business/page/${encodeURIComponent(
              params.pageId,
            )}/purchased-memberships/${encodeURIComponent(params.membershipPurchaseId)}/consumption`,
          },
          getPurchasedMembershipConsumptionDecoder,
        ),
      );
    },
    isServiceInAnyTemplate: async (params: { pageId: PageId; serviceId: ServiceId }) => {
      return unsafeRight(
        await http.request(
          {
            method: 'GET',
            url: `${apiBaseUrl}/business/page/${encodeURIComponent(
              params.pageId,
            )}/membership-service/${encodeURIComponent(params.serviceId)}`,
          },
          isServiceInAnyMembershipOrTemplateDecoder,
        ),
      );
    },
    getPurchasedMembershipsAvailableForTransaction: async (transaction) => {
      const requestBody = {
        client: transaction.client,
        items: transaction.items,
        appointments: transaction.appointments,
        membershipPurchaseIds: transaction.membershipPurchaseIds,
      };
      return unsafeRight(
        await http.request(
          {
            method: 'POST',
            url: `${apiBaseUrl}/business/page/${encodeURIComponent(
              transaction.page._id,
            )}/available-purchased-memberships/v2`,
            data: requestBody,
          },
          getPurchasedMembershipsAvailableForTransactionDecoder,
        ),
      );
    },
  };
};
