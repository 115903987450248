import * as React from 'react';
import Svg, { Path, G, Circle } from 'react-native-svg';

type Props = {
  width?: number | string;
  height?: number | string;
  disabled?: boolean;
  onPress?: () => void;
};

const CalendarChecked = ({ width = 62, height = 54, disabled, onPress }: Props) => (
  <Svg width={width} height={width} viewBox={`0 0 ${width} ${height}`} disabled={disabled} onPress={onPress}>
    <G id="Orion_checked-window" transform="translate(-1 -5)">
      <Circle id="layer5" cx="16" cy="16" r="16" transform="translate(30 26)" fill="#78d1f4" />
      <Circle id="layer4" cx="16" cy="16" r="16" transform="translate(30 26)" fill="#44d88d" />
      <Path id="layer3" d="M2,6H54V18H2Z" fill="#a6b1c9" />
      <Path id="layer2" d="M46,26a15.9,15.9,0,0,1,7.9,2.1H54V18H2V50H32.1A16,16,0,0,1,46,26Z" fill="#fff" />
      <Path id="opacity" d="M32.1,49.9a16,16,0,0,1,4.8-21.1A16,16,0,0,0,27.2,50h4.9Z" fill="#000064" opacity="0.15" />
      <Circle id="layer1" cx="1" cy="1" r="1" transform="translate(7 11)" fill="#f27e7c" />
      <Circle id="layer1-2" data-name="layer1" cx="1" cy="1" r="1" transform="translate(14 11)" fill="#f27e7c" />
      <Circle id="layer1-3" data-name="layer1" cx="1" cy="1" r="1" transform="translate(21 11)" fill="#f27e7c" />
      <Path id="opacity-2" data-name="opacity" d="M2,18H6V50H2ZM2,6H6V18H2Z" fill="#000064" opacity="0.15" />
      <Circle
        id="stroke"
        cx="16"
        cy="16"
        r="16"
        transform="translate(30 26)"
        fill="none"
        stroke="#52577f"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
      />
      <Path
        id="stroke-2"
        data-name="stroke"
        d="M2,6H54V18H2ZM2,18V50H32M54,28V18"
        fill="none"
        stroke="#52577f"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
      />
      <Circle
        id="stroke-3"
        data-name="stroke"
        cx="1"
        cy="1"
        r="1"
        transform="translate(7 11)"
        fill="none"
        stroke="#52577f"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
      />
      <Circle
        id="stroke-4"
        data-name="stroke"
        cx="1"
        cy="1"
        r="1"
        transform="translate(14 11)"
        fill="none"
        stroke="#52577f"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
      />
      <Circle
        id="stroke-5"
        data-name="stroke"
        cx="1"
        cy="1"
        r="1"
        transform="translate(21 11)"
        fill="none"
        stroke="#52577f"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
      />
      <Path
        id="stroke-6"
        data-name="stroke"
        d="M53,38l-8,9-5-5"
        fill="none"
        stroke="#52577f"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
      />
    </G>
  </Svg>
);

export default React.memo(CalendarChecked);
