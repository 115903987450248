import * as React from 'react';
import { StyleProp, View, ViewStyle } from 'react-native';

type Props = React.PropsWithChildren<{
  flex?: number;
  flexWrap?: ViewStyle['flexWrap'];
  flexDirection?: ViewStyle['flexDirection'];
  justifyContent?: ViewStyle['justifyContent'];
  alignItems?: ViewStyle['alignItems'];
  style?: StyleProp<ViewStyle>;
}>;

const BaseView: React.FC<Props> = ({
  flex,
  flexWrap,
  flexDirection,
  justifyContent,
  alignItems,
  style,
  children,
  ...props
}) => {
  return (
    <View
      style={[
        {
          display: 'flex',
          flexDirection: flexDirection,
          flexWrap: flexWrap,
          justifyContent: justifyContent,
          alignItems: alignItems,
          flex: flex,
        },
        style,
      ]}
      {...props}
    >
      {children}
    </View>
  );
};

export default BaseView;
