import * as React from 'react';
import { TouchableOpacity, StyleSheet, ViewStyle, StyleProp } from 'react-native';

import { styles } from './styles';

export type Props = React.PropsWithChildren<{
  readonly style?: StyleProp<ViewStyle>;
  readonly onPress?: () => void;
}>;

const CircleButton: React.FC<Props> = ({ style, children, onPress }: Props) => {
  return (
    <TouchableOpacity
      style={StyleSheet.compose<ViewStyle, ViewStyle, ViewStyle>(styles.container, style)}
      onPress={onPress}
      disabled={onPress === undefined}
    >
      {children}
    </TouchableOpacity>
  );
};

export default CircleButton;
