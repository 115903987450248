import { InventoryId } from '../../pro/inventories/inventoryId';
import { ProductBarcode } from '../../pro/products/product/productBarcode';
import { ProductId } from '../../pro/products/product/productId';
import { ProductMeasure } from '../../pro/products/product/productMeasure';
import { AppointmentDiscount } from './appointmentDiscount';
import {
  JSONable,
  MeroUnits,
  ScaledNumber,
  Option,
  Money,
  DefinedTrimedString,
  Barcode,
  PositiveScaledNumber,
} from '@mero/shared-sdk';
import * as t from 'io-ts';

export type ConsumedProduct = {
  readonly _id: ProductId;
  readonly name: DefinedTrimedString;
  readonly measure: ProductMeasure.Any;
  readonly barcode: Option<ProductBarcode>;
  readonly quantity: PositiveScaledNumber;
  readonly customPrice: {
    readonly amount: Money<ScaledNumber, MeroUnits.RON>;
    readonly discount: Option<AppointmentDiscount>;
  };
  readonly price: {
    readonly retailPrice: Money<ScaledNumber, MeroUnits.RON>;
    readonly discountedPrice: Money<ScaledNumber, MeroUnits.RON>;
    readonly vatRate: ScaledNumber;
  };
  readonly inventoryId: InventoryId;
};

const moneyC = Money.json(ScaledNumber.JSON, MeroUnits.RON.JSON);

const JSON: t.Type<ConsumedProduct, JSONable> = t.type(
  {
    _id: ProductId,
    name: DefinedTrimedString,
    measure: ProductMeasure.JSON,
    barcode: Option.json(Barcode.JSON),
    quantity: PositiveScaledNumber.JSON,
    customPrice: t.type({
      amount: moneyC,
      discount: Option.json(AppointmentDiscount.JSON),
    }),
    price: t.type(
      {
        retailPrice: moneyC,
        discountedPrice: moneyC,
        vatRate: ScaledNumber.JSON,
      },
      'Price',
    ),
    inventoryId: InventoryId,
  },
  'CalendarEntry.ConsumedProduct',
);

export const ConsumedProduct = {
  JSON,
};
