import { GroupWithServices } from './group-with-services';
import { SavedService } from './saved-service';
import * as t from 'io-ts';

export const GroupedServices = t.type(
  {
    grouped: t.array(GroupWithServices),
    others: t.array(SavedService),
  },
  'GroupedServices',
);
export interface GroupedServices extends t.TypeOf<typeof GroupedServices> {}
