import { CheckoutTransactionMembershipPreview } from '../../checkoutTransactionMembershipPreview';
import { MembershipItemEffectivePrice } from '../../checkoutTransactionPayment/membership';
import { CheckoutTransactionServicePreview } from '../../checkoutTransactionServicePreview';
import { MeroUnits, PositiveInt } from '@mero/shared-sdk';

export type MembershipItemService<Num, Unit extends MeroUnits.Any> = {
  readonly type: 'Service';
  readonly service: CheckoutTransactionServicePreview;
  readonly effectivePrice: MembershipItemEffectivePrice<Num, Unit>;
  /**
   * Consumed service sessions quantity >= 1
   */
  readonly quantity: PositiveInt;
};

export type MembershipItemProduct = {
  readonly type: 'Product';
  // FIXME: implement product payment item support
};

export type MembershipItem<Num, Unit extends MeroUnits.Any> = MembershipItemService<Num, Unit> | MembershipItemProduct;

export const MembershipItem = {
  service: <Num, Unit extends MeroUnits.Any>(params: {
    service: CheckoutTransactionServicePreview;
    effectivePrice: MembershipItemEffectivePrice<Num, Unit>;
    quantity: PositiveInt;
  }): MembershipItemService<Num, Unit> => ({
    type: 'Service',
    service: params.service,
    quantity: params.quantity,
    effectivePrice: params.effectivePrice,
  }),
  product: (params: never): MembershipItemProduct => {
    params;
    return {
      type: 'Product',
    };
  },
};

export type Membership<Num, Unit extends MeroUnits.Any> = {
  readonly type: 'Membership';
  readonly membership: CheckoutTransactionMembershipPreview;
  readonly items: MembershipItem<Num, Unit>[];
};

export const membership = <Num, Unit extends MeroUnits.Any>(params: {
  membership: CheckoutTransactionMembershipPreview;
  items: MembershipItem<Num, Unit>[];
}): Membership<Num, Unit> => {
  return {
    type: 'Membership',
    membership: params.membership,
    items: params.items,
  };
};
