import * as React from 'react';

import { ToastProps } from './toast';

export type NewToast = Omit<ToastProps, 'id' | 'onDismiss'>;

type ToastContext = {
  /**
   * Show a new toast and return its id.
   */
  show: (toastConfig: NewToast) => number;

  /**
   * Dismiss a toast by id.
   */
  dismiss: (id: number) => void;
};

export const ToastContext = React.createContext<ToastContext>({
  show: () => 0,
  dismiss: () => void 0,
});

export const useToast = () => React.useContext(ToastContext);
