import { JSONable } from '@mero/shared-sdk';
import * as t from 'io-ts';

export type New = {
  readonly type: 'new';
};

export type Started = {
  readonly type: 'started';
};

export type Succeeded = {
  readonly type: 'succeeded';
};

export type PartiallyRefunded = {
  readonly type: 'partiallyRefunded';
};

export type Refunded = {
  readonly type: 'refunded';
};

export type Failed = {
  readonly type: 'failed';
};

export type UserOrderPaymentStatusPreview = New | Started | Succeeded | PartiallyRefunded | Refunded | Failed;

const JSON: t.Type<UserOrderPaymentStatusPreview, JSONable> = t.union(
  [
    t.type(
      {
        type: t.literal('new'),
      },
      'New',
    ),
    t.type(
      {
        type: t.literal('started'),
      },
      'Started',
    ),
    t.type(
      {
        type: t.literal('succeeded'),
      },
      'Succeeded',
    ),
    t.type(
      {
        type: t.literal('partiallyRefunded'),
      },
      'PartiallyRefunded',
    ),
    t.type(
      {
        type: t.literal('refunded'),
      },
      'Refunded',
    ),
    t.type(
      {
        type: t.literal('failed'),
      },
      'Failed',
    ),
  ],
  'UserOrderPaymentStatusPreview',
);

export const UserOrderPaymentStatusPreview = {
  JSON,
};
