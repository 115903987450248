import { RecurrenceRule } from '@mero/api-sdk';
import * as React from 'react';

import { formatRecurrenceRule } from '../../../utils/format';
import Body from '../../Text/Body';

export type Props = {
  readonly recurrenceRule?: RecurrenceRule.Any;
  readonly dot?: boolean;
};

/**
 * Formats calendar event recurrence text
 */
const RecurrenceText: React.FC<Props> = ({ recurrenceRule, dot }: Props) => {
  return (
    <Body>
      {formatRecurrenceRule(recurrenceRule)}
      {dot ? '.' : ''}
    </Body>
  );
};

export default RecurrenceText;
