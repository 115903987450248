import { Firstname } from './firstname';
import { OptionalLastname } from './lastname';
import * as E from 'fp-ts/lib/Either';
import { pipe } from 'fp-ts/lib/function';
import * as t from 'io-ts';

const Fullname = t.intersection(
  [
    t.type({
      firstname: Firstname,
    }),
    t.partial({
      lastname: OptionalLastname,
    }),
  ],
  'Fullname',
);

export type Fullname = t.TypeOf<typeof Fullname>;

export const FullnameFromString = new t.Type<Fullname, string, unknown>(
  'FullnameFromString',
  Fullname.is,
  (i) => {
    return pipe(
      i,
      t.string.decode,
      E.map((s) => s.trim()),
      E.chain((s): t.Validation<Fullname> => {
        const parts = s.split(/\s+/gi);

        return Fullname.decode(
          parts.length > 1
            ? {
                firstname: parts[0],
                lastname: parts.slice(1).join(' '),
              }
            : parts.length > 0
            ? {
                firstname: parts[0],
              }
            : {},
        );
      }),
    );
  },
  ({ firstname, lastname }) => `${firstname}${lastname ? ` ${lastname}` : ''}`,
);
