import * as t from 'io-ts';

export type InventoryDestination = 'Consumption' | 'Retail' | 'Both';

const CONSUMPTION = 'Consumption' as const;

const RETAIL = 'Retail' as const;

const BOTH = 'Both' as const;

const JSON = t.union([t.literal(CONSUMPTION), t.literal(RETAIL), t.literal(BOTH)]);

export const InventoryDestination = {
  CONSUMPTION,
  RETAIL,
  BOTH,
  JSON,
};
