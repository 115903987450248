import * as t from 'io-ts';

export const PageDiscountSettings = t.type(
  {
    enabled: t.boolean,
  },
  'PageDiscountSettings',
);

export type PageDiscountSettings = t.TypeOf<typeof PageDiscountSettings>;
