import * as React from 'react';
import Svg, { Path, G } from 'react-native-svg';

import { DARK_BLUE } from '../../../styles/colors';

type Props = {
  size?: string | number;
  color?: string;
  disabled?: boolean;
  onPress?: () => void;
};

const CalendarBlock: React.FC<Props> = ({ size = 24, color = DARK_BLUE, disabled, onPress }) => (
  <Svg width={size} height={size} viewBox="0 0 24 24" disabled={disabled} onPress={onPress}>
    <G transform="translate(4 2.709)">
      <Path
        d="M15.333,17H.667A.66.66,0,0,1,0,16.349V2.609a.661.661,0,0,1,.667-.653H4V.653a.667.667,0,0,1,1.334,0v1.3h5.333V.653A.667.667,0,0,1,12,.653v1.3h3.334A.66.66,0,0,1,16,2.609V16.349A.66.66,0,0,1,15.333,17Zm-14-12.785V15.7H14.666V4.215Z"
        transform="translate(0 0.291)"
        fill={color}
      />
    </G>
    <Path
      d="M20.229,16v8.457m4.229-4.229H16"
      transform="translate(12 -15.536) rotate(45)"
      fill="none"
      stroke={color}
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeMiterlimit="10"
      strokeWidth="1.2"
    />
  </Svg>
);

export default React.memo(CalendarBlock);
