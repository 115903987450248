import { JSONable } from '../../common';
import { Numbers, PositiveInt } from '../../numbers';
import { RecurrenceTimeUnit } from '../recurrenceTimeUnit';
import { Daily } from './daily';
import { Every2Weeks } from './every2Weeks';
import { Monthly } from './monthly';
import { Weekly } from './weekly';
import { Yearly } from './yearly';
import * as Eq from 'fp-ts/Eq';
import * as t from 'io-ts';

export { Daily } from './daily';
export { Weekly } from './weekly';
export { Monthly } from './monthly';
export { Yearly } from './yearly';
export { Every2Weeks } from './every2Weeks';

export type Any = {
  readonly value: PositiveInt;
  readonly unit: RecurrenceTimeUnit.Any;
};

export const DAILY: Daily = {
  value: Numbers._1,
  unit: 'day',
};

export const WEEKLY: Weekly = {
  value: Numbers._1,
  unit: 'week',
};

export const EVERY_2_WEEKS: Every2Weeks = {
  value: Numbers._2,
  unit: 'week',
};

export const MONTHLY: Monthly = {
  value: Numbers._1,
  unit: 'month',
};

export const YEARLY: Yearly = {
  value: Numbers._1,
  unit: 'year',
};

export const JSON: t.Type<Any, JSONable> = t.type(
  {
    value: PositiveInt.JSON,
    unit: RecurrenceTimeUnit.JSON,
  },
  'RecurrenceInterval',
);

export const is = JSON.is;

const eq: Eq.Eq<Any> = Eq.struct<Any>({
  value: PositiveInt,
  unit: RecurrenceTimeUnit,
});

export const equals = eq.equals;

/**
 * Builds a recurrence interval
 */
export const every = (interval: PositiveInt, unit: RecurrenceTimeUnit.Any): Any => ({
  value: interval,
  unit,
});
