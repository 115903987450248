import * as React from 'react';
import Svg, { G, Circle, Path } from 'react-native-svg';

type Props = {
  size?: number | string;
  disabled?: boolean;
  onPress?: () => void;
};

const Membership = ({ size = 32, disabled, onPress }: Props) => (
  <Svg width={size} height={size} viewBox={`0 0 ${size} ${size}`} disabled={disabled} onPress={onPress}>
    <G id="team-4" transform="translate(0 -0.5)">
      <Circle id="Mask" cx="16" cy="16" r="16" transform="translate(0 0.5)" fill="none" />
      <G id="format_list_bulleted-24px" transform="translate(4 4.5)">
        <Path id="Path_8275" d="M0,0H24V24H0Z" fill="none" />
        <G id="Orion_contact-card" transform="translate(2 4.667)">
          <Path
            id="layer2"
            d="M18.667,10v.333a1.667,1.667,0,1,1-3.333,0V10H8.667v.333a1.667,1.667,0,0,1-3.333,0V10H2V24.667H22V10Z"
            transform="translate(-2 -10)"
            fill="none"
            stroke="#52577f"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeMiterlimit="10"
            strokeWidth="1.7"
          />
          <Path
            id="layer1"
            d="M38,26h5.333M38,28.667h5.333M38,31.333h2.667"
            transform="translate(-26 -20.667)"
            fill="none"
            stroke="#52577f"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeMiterlimit="10"
            strokeWidth="1.7"
          />
          <Circle
            id="layer1-2"
            cx="1.807"
            cy="1.807"
            r="1.807"
            transform="translate(4.219 4.335)"
            fill="none"
            stroke="#52577f"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeMiterlimit="10"
            strokeWidth="1.7"
          />
          <Path
            id="layer1-3"
            d="M13.332,34A3.367,3.367,0,0,0,10,37.433V38h6.667v-.567A3.367,3.367,0,0,0,13.332,34Z"
            transform="translate(-7.332 -26)"
            fill="none"
            stroke="#52577f"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeMiterlimit="10"
            strokeWidth="1.7"
          />
        </G>
      </G>
    </G>
  </Svg>
);

export default React.memo(Membership);
