import * as t from 'io-ts';

export interface CouponIdBrand {
  readonly CouponId: unique symbol;
}

export type CouponId = t.Branded<string, CouponIdBrand>;

const JSON: t.Type<CouponId, string> = t.brand(t.string, (_id: string): _id is CouponId => true, 'CouponId');

export const CouponId = {
  JSON,
};
