import * as React from 'react';
import { View } from 'react-native';

import sizes from '../../styles/sizes';

type StyledViewProps = React.PropsWithChildren<{
  size: number;
  color?: string;
}>;

const StyledView: React.FC<StyledViewProps> = ({ size, color, children }) => {
  return (
    <View
      style={{
        height: size,
        backgroundColor: color,
      }}
    >
      {children}
    </View>
  );
};

/**
 * @deprecated
 */
type SizeSpec = 'xxxs' | 'xxs' | 'xs' | 's' | 'm' | 'l' | 'xl' | 'xxl';
type SizeSpecNumeric = '4' | '6' | '8' | '12' | '16' | '24' | '32' | '48';

const Sizes: { [k in SizeSpec | SizeSpecNumeric]: number } = {
  '4': sizes[4],
  xxxs: sizes[4],
  '6': sizes[6],
  xxs: sizes[6],
  '8': sizes[8],
  xs: sizes[8],
  '12': sizes[12],
  s: sizes[12],
  '16': sizes[16],
  m: sizes[16],
  '24': sizes[24],
  l: sizes[24],
  '32': sizes[32],
  xl: sizes[32],
  '48': sizes[48],
  xxl: sizes[48],
};

type Props = {
  size?: SizeSpec | SizeSpecNumeric | number;
  color?: string;
};

const getHeight = (size: SizeSpec | SizeSpecNumeric | number): number => {
  if (typeof size === 'number') {
    return size;
  } else {
    return Sizes[size];
  }
};

const Spacer: React.FC<Props> = ({ size = 'm', color }) => {
  return <StyledView size={getHeight(size)} color={color} />;
};

export default Spacer;
