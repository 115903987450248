import * as React from 'react';
import Svg, { G, Rect, Circle } from 'react-native-svg';

type Props = {
  size?: string | number;
  color?: string;
  disabled?: boolean;
  onPress?: () => void;
};

const AndroidApps = ({ size = 24, color = '#ffffff', disabled, onPress }: Props) => (
  <Svg width={size} height={size} viewBox={`0 0 ${size} ${size}`} disabled={disabled} onPress={onPress}>
    <G id="Group_6505" data-name="Group 6505" transform="translate(-2962 16884)">
      <Rect id="Rectangle_2586" width="24" height="24" rx="10" transform="translate(2962 -16884)" fill="#69a9f2" />
      <Circle id="Ellipse_658" cx="2.5" cy="2.5" r="2.5" transform="translate(2968 -16878)" fill={color} />
      <Circle id="Ellipse_659" cx="2.5" cy="2.5" r="2.5" transform="translate(2975 -16878)" fill={color} />
      <Circle id="Ellipse_660" cx="2.5" cy="2.5" r="2.5" transform="translate(2968 -16871)" fill={color} />
      <Circle id="Ellipse_661" cx="2.5" cy="2.5" r="2.5" transform="translate(2975 -16871)" fill={color} />
    </G>
  </Svg>
);

export default React.memo(AndroidApps);
