import { AppointmentId } from '../../calendar';
import { PageId } from '../../pages';
import { WorkerId } from '../../workers';
import { ClientFeedbackId } from './clientFeedbackId';
import { JSONable, ProfileImage, ReviewScore, UserId, optionull } from '@mero/shared-sdk';
import * as t from 'io-ts';

export type ClientFeedbackDetails = {
  readonly _id: ClientFeedbackId;
  readonly pageId: PageId;
  readonly client: {
    readonly userId: UserId;
  };
  readonly workerId: WorkerId;
  readonly appointmentId: AppointmentId;
  readonly occurrenceIndex: number;
  /**
   * Pro used that added the feedback
   */
  readonly addedByUser: {
    readonly _id: UserId;
    readonly firstname: string;
    readonly lastname: string;
    readonly photo: ProfileImage | undefined;
  };
  readonly feedback: {
    readonly score: ReviewScore;
    readonly review: string | undefined;
  };
};

const JSON: t.Type<ClientFeedbackDetails, JSONable> = t.type(
  {
    _id: ClientFeedbackId.JSON,
    pageId: PageId,
    client: t.type(
      {
        userId: UserId,
      },
      'Client',
    ),
    workerId: WorkerId.JSON,
    appointmentId: AppointmentId,
    occurrenceIndex: t.number,
    addedByUser: t.type(
      {
        _id: UserId,
        firstname: t.string,
        lastname: t.string,
        photo: optionull(ProfileImage),
      },
      'AddedByUser',
    ),
    feedback: t.type(
      {
        score: ReviewScore,
        review: optionull(t.string),
      },
      'Feedback',
    ),
  },
  'ClientFeedbackDetails',
);

export const ClientFeedbackDetails = {
  JSON,
};
