import * as React from 'react';
import Svg, { Path, G, Rect } from 'react-native-svg';

type Props = {
  width?: string | number;
  height?: string | number;
  color?: string;
  disabled?: boolean;
  onPress?: () => void;
  rotate?: boolean;
};

const Down = ({ width = 24, height = 24, color = '#080de0', disabled, onPress }: Props) => {
  return (
    <Svg width={width} height={height} viewBox={`0 0 ${width} ${height}`} disabled={disabled} onPress={onPress}>
      <G id="Group_5903" data-name="Group 5903" transform="translate(24) rotate(90)">
        <Path
          id="iconmonstr-arrow-63"
          d="M1.385,11.743,0,10.358,4.569,5.869,0,1.384,1.385,0,7.339,5.869Z"
          transform="translate(8.022 6)"
          fill={color}
        />
        <Rect id="Rectangle_2" data-name="Rectangle 2" width={width} height={height} fill="none" />
      </G>
    </Svg>
  );
};

export default React.memo(Down);
