import * as t from 'io-ts';

export const DownloadCashRegistryExport = t.type(
  {
    downloadUrl: t.string,
  },
  'DownloadCashRegistryExport',
);

export type DownloadCashRegistryExport = t.TypeOf<typeof DownloadCashRegistryExport>;
