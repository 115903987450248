import { ObjectId } from '@mero/shared-sdk';
import * as t from 'io-ts';

export interface PageIdBrand {
  readonly PageId: unique symbol;
}

export const PageId = t.brand(ObjectId, (_id: ObjectId): _id is t.Branded<ObjectId, PageIdBrand> => true, 'PageId');

export type PageId = t.TypeOf<typeof PageId>;
