import * as React from 'react';
import Svg, { Circle, Path } from 'react-native-svg';

type Props = {
  size?: number | string;
  disabled?: boolean;
  onPress?: () => void;
};

const History = ({ size = 32, disabled, onPress }: Props) => (
  <Svg width={size} height={size} viewBox={`0 0 ${size} ${size}`} disabled={disabled} onPress={onPress}>
    <Circle cx="16" cy="16" r="16" fill="#e9ecef" />
    <Path
      fill="#52577f"
      d="M22.75 14.59h-5.085l2.055-2.115a5.279 5.279 0 0 0-7.41-.075 5.156 5.156 0 0 0 0 7.343 5.265 5.265 0 0 0 7.41 0 4.885 4.885 0 0 0 1.53-3.668h1.5a7.006 7.006 0 0 1-1.98 4.717 6.79 6.79 0 0 1-9.54 0 6.63 6.63 0 0 1-.015-9.435 6.74 6.74 0 0 1 9.488 0L22.75 9.25ZM16.375 13v3.188L19 17.75l-.54.908-3.21-1.908V13Z"
    />
  </Svg>
);

export default React.memo(History);
