import { BusinessCategoryId } from '../../../services';
import { JSONable, optionull } from '@mero/shared-sdk';
import * as t from 'io-ts';

type BusinessCategoryPreview = {
  readonly _id: BusinessCategoryId;
  readonly name: string;
};

const BUSINESS_CATEGORY_PREVIEW_JSON: t.Type<BusinessCategoryPreview, JSONable> = t.type(
  {
    _id: BusinessCategoryId,
    name: t.string,
  },
  'BusinessCategoryPreview',
);

type SearchQuery =
  | {
      readonly type: 'CategoryAndCity';
      readonly categories: BusinessCategoryPreview[];
      readonly geo: {
        readonly type: 'City';
        readonly name: string;
        readonly area: string | undefined;
      };
    }
  | {
      readonly type: 'Category';
      readonly categories: BusinessCategoryPreview[];
    }
  | {
      readonly type: 'City';
      readonly geo: {
        readonly type: 'City';
        readonly name: string;
        readonly area: string | undefined;
      };
    };

const SEARCH_QUERY_JSON: t.Type<SearchQuery, JSONable> = t.union(
  [
    t.type(
      {
        type: t.literal('CategoryAndCity'),
        categories: t.array(BUSINESS_CATEGORY_PREVIEW_JSON),
        geo: t.type(
          {
            type: t.literal('City'),
            name: t.string,
            area: optionull(t.string),
          },
          'Geo',
        ),
      },
      'CategoryAndCitySearchResult',
    ),
    t.type(
      {
        type: t.literal('Category'),
        categories: t.array(BUSINESS_CATEGORY_PREVIEW_JSON),
      },
      'CategorySearchResult',
    ),
    t.type(
      {
        type: t.literal('City'),
        geo: t.type(
          {
            type: t.literal('City'),
            name: t.string,
            area: optionull(t.string),
          },
          'Geo',
        ),
      },
      'CitySearchResult',
    ),
  ],
  'SearchResults',
);

export type SearchResults = {
  readonly type: 'SearchResults';
  readonly query: SearchQuery;
};

const JSON: t.Type<SearchResults, JSONable> = t.type(
  {
    type: t.literal('SearchResults'),
    query: SEARCH_QUERY_JSON,
  },
  'SearchResults',
);

export const SearchResults = {
  JSON,
};
