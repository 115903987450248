import { JSONable } from './jsonable';
import * as t from 'io-ts';

export type MeroAppPlatform = 'ios' | 'android' | 'web';

const JSON: t.Type<MeroAppPlatform, JSONable> = t.union(
  [t.literal('ios'), t.literal('android'), t.literal('web')],
  'MeroAppPlatform',
);

const parse = JSON.decode;

export const MeroAppPlatform = {
  JSON,
  parse,
};
