import { ObjectId } from '@mero/shared-sdk';
import * as t from 'io-ts';

export interface BusinessMetaIdBrand {
  readonly BusinessMetaId: unique symbol;
}

export const BusinessMetaId = t.brand(
  ObjectId,
  (_id: ObjectId): _id is t.Branded<ObjectId, BusinessMetaIdBrand> => true,
  'BusinessMetaId',
);

export type BusinessMetaId = t.TypeOf<typeof BusinessMetaId>;

const BusinessMetaSitemapEntry = t.type({
  name: t.string,
  href: t.string,
  imageUrl: t.union([t.string, t.undefined]),
});

export const BusinessMeta = t.type(
  {
    _id: BusinessMetaId,
    sitemap: t.type({
      profiles: t.array(BusinessMetaSitemapEntry),
      categories: t.array(BusinessMetaSitemapEntry),
      cities: t.array(BusinessMetaSitemapEntry),
      categoriesAndCities: t.array(BusinessMetaSitemapEntry),
    }),
  },
  'BusinessMeta',
);

export type BusinessMeta = t.TypeOf<typeof BusinessMeta>;
