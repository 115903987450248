import * as React from 'react';
import { View } from 'react-native';

import { colors } from '../../../styles';
import HSpacer from '../../HSpacer';
import { default as Icon, NotificationCalendarOpenIcon } from '../../Icon';
import TextSkeleton from '../../TextSkeleton';
import { styles } from '../styles';

export type Props = {
  // pass
};

const UserNotificationSkeleton: React.FC<Props> = ({}: Props) => {
  return (
    <View style={{ padding: 16, flexDirection: 'row' }}>
      <NotificationCalendarOpenIcon color={colors.ATHENS_GRAY} backgroundColor={colors.ATHENS_GRAY} />
      <View style={styles.notificationBody}>
        <TextSkeleton width="fill" />
        <TextSkeleton width="medium" />
        <TextSkeleton width="long" />
        <TextSkeleton width="short" height="label" />
      </View>
      <HSpacer left={8} />
      <Icon type="arrow-right" color={colors.ATHENS_GRAY} />
    </View>
  );
};

export default UserNotificationSkeleton;
