import { ServiceGroupId } from '../../services/serviceGroupId';
import {
  CheckoutReportsApi,
  CheckoutPaymentMethodDailyReportItem,
  CheckoutPaymentMethodReportItem,
  CheckoutClientSalesReportPage,
  CheckoutSaleOwnerSalesReportPage,
  CheckoutServiceSalesReportPage,
  CheckoutSaleOwnerSalesReportTotalsByType,
  CheckoutSaleOwnerServiceSalesReportPage,
  CheckoutProductSalesReportPage,
  CheckoutSaleOwnerProductSalesReportPage,
} from '../checkoutReportsApi';
import { DownloadReport } from '../checkoutReportsApi/downloadReport';
import { HttpClient, Paged, UnknownApiError, UserId, unsafeRight } from '@mero/shared-sdk';
import * as t from 'io-ts';
import * as tt from 'io-ts-types';

/**
 * CheckoutReportsApi HTTP client implementation
 */
export const checkoutReportsHttpClient = (env: { apiBaseUrl: string; http: HttpClient }): CheckoutReportsApi => {
  const { apiBaseUrl, http } = env;
  const downloadReportDecoder = http.decode.response(UnknownApiError, DownloadReport);

  return {
    getSalesByClientReport: async ({ pageId, unit, interval, itemType, sort, page }) => {
      return unsafeRight(
        await http.request(
          {
            method: 'GET',
            url: `${apiBaseUrl}/business/page/${encodeURIComponent(pageId)}/checkout-reports/sales-by-client`,
            params: {
              unit: unit,
              from: tt.DateFromISOString.encode(interval.from),
              to: tt.DateFromISOString.encode(interval.to),
              itemType: itemType,
              ...(sort
                ? {
                    sortBy: sort.by,
                    sortDir: sort.dir,
                  }
                : {}),
              page: page ? page : undefined,
            },
          },
          http.decode.response(UnknownApiError, Paged.json(CheckoutClientSalesReportPage.json(unit))),
        ),
      );
    },
    exportSalesByClientReport: async ({ pageId, unit, interval, itemType, sort, format }) => {
      return unsafeRight(
        await http.request(
          {
            method: 'GET',
            url: `${apiBaseUrl}/business/page/${encodeURIComponent(pageId)}/checkout-reports/sales-by-client/export`,
            params: {
              unit: unit,
              from: tt.DateFromISOString.encode(interval.from),
              to: tt.DateFromISOString.encode(interval.to),
              itemType: itemType,
              ...(sort
                ? {
                    sortBy: sort.by,
                    sortDir: sort.dir,
                  }
                : {}),
              format: format,
            },
          },
          downloadReportDecoder,
        ),
      );
    },
    getSalesByOwnerReport: async ({ pageId, unit, interval, sort, page }) => {
      return unsafeRight(
        await http.request(
          {
            method: 'GET',
            url: `${apiBaseUrl}/business/page/${encodeURIComponent(pageId)}/checkout-reports/sales-by-owner`,
            params: {
              unit: unit,
              from: tt.DateFromISOString.encode(interval.from),
              to: tt.DateFromISOString.encode(interval.to),
              ...(sort
                ? {
                    sortBy: sort.by,
                    sortDir: sort.dir,
                  }
                : {}),
              page: page ? page : undefined,
            },
          },
          http.decode.response(UnknownApiError, Paged.json(CheckoutSaleOwnerSalesReportPage.json(unit))),
        ),
      );
    },
    exportSalesByOwnerReport: async ({ pageId, unit, interval, sort, format }) => {
      return unsafeRight(
        await http.request(
          {
            method: 'GET',
            url: `${apiBaseUrl}/business/page/${encodeURIComponent(pageId)}/checkout-reports/sales-by-owner/export`,
            params: {
              unit: unit,
              from: tt.DateFromISOString.encode(interval.from),
              to: tt.DateFromISOString.encode(interval.to),
              ...(sort
                ? {
                    sortBy: sort.by,
                    sortDir: sort.dir,
                  }
                : {}),
              format: format,
            },
          },
          downloadReportDecoder,
        ),
      );
    },
    getSalesByOwnerTotalsReport: async ({ pageId, unit, interval, saleOwnerId }) => {
      return unsafeRight(
        await http.request(
          {
            method: 'GET',
            url: `${apiBaseUrl}/business/page/${encodeURIComponent(pageId)}/checkout-reports/sales-by-owner-totals`,
            params: {
              unit: unit,
              from: tt.DateFromISOString.encode(interval.from),
              to: tt.DateFromISOString.encode(interval.to),
              saleOwnerId: saleOwnerId ? UserId.encode(saleOwnerId) : undefined,
            },
          },
          http.decode.response(UnknownApiError, CheckoutSaleOwnerSalesReportTotalsByType.json(unit)),
        ),
      );
    },
    getSalesByServiceReport: async ({ pageId, unit, interval, saleOwnerId, groupId, sort, page }) => {
      return unsafeRight(
        await http.request(
          {
            method: 'GET',
            url: `${apiBaseUrl}/business/page/${encodeURIComponent(pageId)}/checkout-reports/sales-by-service`,
            params: {
              unit: unit,
              from: tt.DateFromISOString.encode(interval.from),
              to: tt.DateFromISOString.encode(interval.to),
              saleOwnerId: saleOwnerId ? UserId.encode(saleOwnerId) : undefined,
              groupId: groupId ? ServiceGroupId.encode(groupId) : undefined,
              ...(sort
                ? {
                    sortBy: sort.by,
                    sortDir: sort.dir,
                  }
                : {}),
              page: page ? page : undefined,
            },
          },
          http.decode.response(UnknownApiError, Paged.json(CheckoutServiceSalesReportPage.json(unit))),
        ),
      );
    },
    getSalesByProductReport: async ({ pageId, unit, interval, saleOwnerId, categoryId, sort, page }) => {
      return unsafeRight(
        await http.request(
          {
            method: 'GET',
            url: `${apiBaseUrl}/business/page/${encodeURIComponent(pageId)}/checkout-reports/sales-by-product`,
            params: {
              unit: unit,
              from: tt.DateFromISOString.encode(interval.from),
              to: tt.DateFromISOString.encode(interval.to),
              saleOwnerId: saleOwnerId ? UserId.encode(saleOwnerId) : undefined,
              categoryId: categoryId,
              ...(sort
                ? {
                    sortBy: sort.by,
                    sortDir: sort.dir,
                  }
                : {}),
              page: page ? page : undefined,
            },
          },
          http.decode.response(UnknownApiError, Paged.json(CheckoutProductSalesReportPage.json(unit))),
        ),
      );
    },
    exportSalesByProductReport: async ({ pageId, unit, interval, saleOwnerId, categoryId, sort, format }) => {
      return unsafeRight(
        await http.request(
          {
            method: 'GET',
            url: `${apiBaseUrl}/business/page/${encodeURIComponent(pageId)}/checkout-reports/sales-by-product/export`,
            params: {
              unit: unit,
              from: tt.DateFromISOString.encode(interval.from),
              to: tt.DateFromISOString.encode(interval.to),
              saleOwnerId: saleOwnerId ? UserId.encode(saleOwnerId) : undefined,
              categoryId: categoryId,
              ...(sort
                ? {
                    sortBy: sort.by,
                    sortDir: sort.dir,
                  }
                : {}),
              format: format,
            },
          },
          downloadReportDecoder,
        ),
      );
    },
    exportSalesByServiceReport: async ({ pageId, unit, interval, saleOwnerId, groupId, sort, format }) => {
      return unsafeRight(
        await http.request(
          {
            method: 'GET',
            url: `${apiBaseUrl}/business/page/${encodeURIComponent(pageId)}/checkout-reports/sales-by-service/export`,
            params: {
              unit: unit,
              from: tt.DateFromISOString.encode(interval.from),
              to: tt.DateFromISOString.encode(interval.to),
              saleOwnerId: saleOwnerId ? UserId.encode(saleOwnerId) : undefined,
              groupId: groupId ? ServiceGroupId.encode(groupId) : undefined,
              ...(sort
                ? {
                    sortBy: sort.by,
                    sortDir: sort.dir,
                  }
                : {}),
              format: format,
            },
          },
          downloadReportDecoder,
        ),
      );
    },
    getPaymentsByTypeReport: async ({ pageId, unit, interval, saleOwnerId }) => {
      return unsafeRight(
        await http.request(
          {
            method: 'GET',
            url: `${apiBaseUrl}/business/page/${encodeURIComponent(pageId)}/checkout-reports/payments-by-type`,
            params: {
              unit: unit,
              from: tt.DateFromISOString.encode(interval.from),
              to: tt.DateFromISOString.encode(interval.to),
              saleOwnerId: saleOwnerId ? UserId.encode(saleOwnerId) : undefined,
            },
          },
          http.decode.response(UnknownApiError, CheckoutPaymentMethodReportItem.json(unit)),
        ),
      );
    },
    getPaymentsByTypeDailyReport: async ({ pageId, unit, interval, saleOwnerId }) => {
      return unsafeRight(
        await http.request(
          {
            method: 'GET',
            url: `${apiBaseUrl}/business/page/${encodeURIComponent(pageId)}/checkout-reports/payments-by-type-daily`,
            params: {
              unit: unit,
              from: tt.DateFromISOString.encode(interval.from),
              to: tt.DateFromISOString.encode(interval.to),
              saleOwnerId: saleOwnerId ? UserId.encode(saleOwnerId) : undefined,
            },
          },
          http.decode.response(UnknownApiError, t.array(CheckoutPaymentMethodDailyReportItem.json(unit))),
        ),
      );
    },
    exportPaymentsByTypeAndOwnerReport: async ({ pageId, unit, interval, saleOwnerId, format }) => {
      return unsafeRight(
        await http.request(
          {
            method: 'GET',
            url: `${apiBaseUrl}/business/page/${encodeURIComponent(
              pageId,
            )}/checkout-reports/payments-by-type-and-owner/export`,
            params: {
              unit: unit,
              from: tt.DateFromISOString.encode(interval.from),
              to: tt.DateFromISOString.encode(interval.to),
              saleOwnerId: saleOwnerId ? UserId.encode(saleOwnerId) : undefined,
              format,
            },
          },
          downloadReportDecoder,
        ),
      );
    },
    getSalesByServiceAndOwnerReport: async ({ pageId, unit, interval, saleOwnerId, sort, page }) => {
      return unsafeRight(
        await http.request(
          {
            method: 'GET',
            url: `${apiBaseUrl}/business/page/${encodeURIComponent(
              pageId,
            )}/checkout-reports/sale-owner-sales-by-service`,
            params: {
              unit: unit,
              from: tt.DateFromISOString.encode(interval.from),
              to: tt.DateFromISOString.encode(interval.to),
              saleOwnerId: UserId.encode(saleOwnerId),
              ...(sort
                ? {
                    sortBy: sort.by,
                    sortDir: sort.dir,
                  }
                : {}),
              page: page ? page : undefined,
            },
          },
          http.decode.response(UnknownApiError, Paged.json(CheckoutSaleOwnerServiceSalesReportPage.json(unit))),
        ),
      );
    },
    exportSalesByServiceAndOwnerReport: async ({ pageId, unit, interval, saleOwnerId, sort, format }) => {
      return unsafeRight(
        await http.request(
          {
            method: 'GET',
            url: `${apiBaseUrl}/business/page/${encodeURIComponent(
              pageId,
            )}/checkout-reports/sale-owner-sales-by-service/export`,
            params: {
              unit: unit,
              from: tt.DateFromISOString.encode(interval.from),
              to: tt.DateFromISOString.encode(interval.to),
              saleOwnerId: saleOwnerId ? UserId.encode(saleOwnerId) : undefined,
              ...(sort
                ? {
                    sortBy: sort.by,
                    sortDir: sort.dir,
                  }
                : {}),
              format,
            },
          },
          downloadReportDecoder,
        ),
      );
    },
    getSalesByProductAndOwnerReport: async ({ pageId, unit, interval, saleOwnerId, sort, page, availability }) => {
      return unsafeRight(
        await http.request(
          {
            method: 'GET',
            url: `${apiBaseUrl}/business/page/${encodeURIComponent(
              pageId,
            )}/checkout-reports/sale-owner-sales-by-product`,
            params: {
              unit: unit,
              from: tt.DateFromISOString.encode(interval.from),
              to: tt.DateFromISOString.encode(interval.to),
              saleOwnerId: UserId.encode(saleOwnerId),
              ...(sort
                ? {
                    sortBy: sort.by,
                    sortDir: sort.dir,
                  }
                : {}),
              page: page ? page : undefined,
              availability: availability,
            },
          },
          http.decode.response(UnknownApiError, Paged.json(CheckoutSaleOwnerProductSalesReportPage.json(unit))),
        ),
      );
    },
    exportSalesByProductAndOwnerReport: async ({ pageId, unit, interval, saleOwnerId, sort, format, availability }) => {
      return unsafeRight(
        await http.request(
          {
            method: 'GET',
            url: `${apiBaseUrl}/business/page/${encodeURIComponent(
              pageId,
            )}/checkout-reports/sale-owner-sales-by-product/export`,
            params: {
              unit: unit,
              from: tt.DateFromISOString.encode(interval.from),
              to: tt.DateFromISOString.encode(interval.to),
              saleOwnerId: saleOwnerId ? UserId.encode(saleOwnerId) : undefined,
              ...(sort
                ? {
                    sortBy: sort.by,
                    sortDir: sort.dir,
                  }
                : {}),
              format,
              availability: availability,
            },
          },
          downloadReportDecoder,
        ),
      );
    },
  };
};
