import * as t from 'io-ts';

export const AppointedDay = t.type(
  {
    date: t.string,
    total: t.number,
    used: t.number,
    active: t.boolean,
  },
  'AppointedDay',
);

export type AppointedDay = t.TypeOf<typeof AppointedDay>;

export const AppointedDayArray = t.array(AppointedDay);

export type AppointedDayArray = t.TypeOf<typeof AppointedDayArray>;
