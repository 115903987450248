import * as React from 'react';
import Svg, { Path } from 'react-native-svg';

type Props = {
  color?: string;
  disabled?: boolean;
  onPress?: () => void;
};

const Bell = ({ disabled, onPress }: Props) => (
  <Svg id="notifications_black_24dp" width="48" height="48" viewBox="0 0 48 48" disabled={disabled} onPress={onPress}>
    <Path id="Path_8768" data-name="Path 8768" d="M0,0H48V48H0Z" fill="none" />
    <Path
      id="Path_8769"
      data-name="Path 8769"
      d="M16,6.5c-4.98,0-8,4.04-8,9v12H24v-12C24,10.54,20.98,6.5,16,6.5Z"
      transform="translate(8 6.5)"
      fill="#a27af1"
      opacity="0.3"
    />
    <Path
      id="Path_8770"
      data-name="Path 8770"
      d="M20,41.5a4.012,4.012,0,0,0,4-4H16A4.012,4.012,0,0,0,20,41.5Zm12-12v-10c0-6.14-3.26-11.28-9-12.64V5.5a3,3,0,0,0-6,0V6.86C11.28,8.22,8,13.34,8,19.5v10l-4,4v2H36v-2Zm-4,2H12v-12c0-4.96,3.02-9,8-9s8,4.04,8,9Z"
      transform="translate(4 2.5)"
      fill="#a27af1"
    />
  </Svg>
);

export default React.memo(Bell);
