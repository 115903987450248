import * as MeroApi from '@mero/api-sdk';
import * as React from 'react';
import { View } from 'react-native';

import { colors } from '../../../styles';
import sizes from '../../../styles/sizes';
import { styles as text } from '../../../styles/text';
import { formatTimeDiff } from '../../../utils/format';
import HSpacer from '../../HSpacer';
import Icon, { NotificationAlertIcon } from '../../Icon';
import Body from '../../Text/Body';
import SmallBody from '../../Text/SmallBody';
import { styles } from '../styles';

export type Props = {
  readonly notification: MeroApi.notifications.AppointmentReviewReminderNotification;
};

const AppointmentReviewReminderNotification: React.FC<Props> = ({ notification }: Props) => {
  const { payload } = notification;

  return (
    <View style={styles.notificationContainer}>
      <NotificationAlertIcon />
      <View style={styles.notificationBody}>
        <Body>
          Evaluează serviciile oferite de <Body style={text.semibold}>{payload.worker.firstname}</Body> de la{' '}
          <Body style={text.semibold}>{payload.page.name}</Body>.
        </Body>
        <SmallBody style={styles.timeText}>{formatTimeDiff(notification.createdAt, new Date())}</SmallBody>
      </View>
      <HSpacer left={8} />
      <Icon type="next" size={sizes[24]} color={colors.DARK_BLUE} />
    </View>
  );
};

export default AppointmentReviewReminderNotification;
