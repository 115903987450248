import { MembershipJson, PurchasedMembershipHistoryRecordArray } from '../../memberships';
import { PurchasedMembershipHistoryType } from '../../memberships/purchasedMembershipHistoryType';
import { MarketplaceMembershipsApi } from './marketplaceMembershipsApi';
import { HttpClient, unsafeRight, UnknownApiError, DecodeError, Paged } from '@mero/shared-sdk';
import * as A from 'fp-ts/lib/Array';
import * as E from 'fp-ts/lib/Either';
import { pipe } from 'fp-ts/lib/function';
import * as t from 'io-ts';

export const marketplaceMembershipsHttpClient = (env: {
  apiBaseUrl: string;
  http: HttpClient;
}): MarketplaceMembershipsApi => {
  const { apiBaseUrl, http } = env;

  const unknownError = UnknownApiError;
  const getPurchasedMembershipByIdDecoder = http.decode.response(
    unknownError,
    MembershipJson.AnyUserPurchasedMembershipDetailsC,
  );
  const getMembershipsDecoder = http.decode.response(
    unknownError,
    Paged.json(t.array(MembershipJson.AnyUserPurchasedMembershipC)),
  );
  const getMembershipPaymentsDecoder = http.decode.response(
    unknownError,
    t.array(MembershipJson.AnyMembershipPaymentC),
  );
  const getPurchasedMembershipConsumptionDecoder = http.decode.response(
    unknownError,
    t.array(MembershipJson.AnyMembershipConsumptionPerTransactionC),
  );
  const getAppointmentMembershipsConsumptionDecoder = http.decode.response(
    unknownError,
    t.array(MembershipJson.AnyUserMembershipItemConsumptionDetailsC),
  );

  return {
    getPurchasedMembershipById: async (membershipPurchaseId) => {
      return unsafeRight(
        await http.request(
          {
            method: 'GET',
            url: `${apiBaseUrl}/mp/purchased-memberships/${encodeURIComponent(membershipPurchaseId)}`,
          },
          getPurchasedMembershipByIdDecoder,
        ),
      );
    },
    getPurchasedMemberships: async ({ limit, page }) => {
      const query = {
        limit: limit,
        page: page,
      };
      return unsafeRight(
        await http.request(
          {
            method: 'GET',
            url: `${apiBaseUrl}/mp/purchased-memberships`,
            params: query,
          },
          getMembershipsDecoder,
        ),
      );
    },
    getPurchasedMembershipHistory: async (membershipPurchaseId) => {
      const unknownHistoryRecordArray = http.decode.response(UnknownApiError, t.array(t.unknown));

      const unknownHistoryRecords = unsafeRight(
        await http.request(
          {
            method: 'GET',
            url: `${apiBaseUrl}/mp/purchased-memberships/${encodeURIComponent(membershipPurchaseId)}/history`,
          },
          unknownHistoryRecordArray,
        ),
      );
      const withHistoryTypeCodec = t.type({
        type: PurchasedMembershipHistoryType,
      });

      return pipe(
        unknownHistoryRecords,
        A.map(withHistoryTypeCodec.decode),
        A.chain(
          E.fold(
            () => [],
            (n) => [n],
          ),
        ),
        PurchasedMembershipHistoryRecordArray.decode,
        E.getOrElseW((e) => {
          throw new DecodeError(e);
        }),
      );
    },
    getPurchasedMembershipPayments: async (membershipPurchaseId) => {
      return unsafeRight(
        await http.request(
          {
            method: 'GET',
            url: `${apiBaseUrl}/mp/purchased-memberships/${encodeURIComponent(membershipPurchaseId)}/payments`,
          },
          getMembershipPaymentsDecoder,
        ),
      );
    },
    getPurchasedMembershipConsumption: async (membershipPurchaseId) => {
      return unsafeRight(
        await http.request(
          {
            method: 'GET',
            url: `${apiBaseUrl}/mp/purchased-memberships/${encodeURIComponent(membershipPurchaseId)}/consumption`,
          },
          getPurchasedMembershipConsumptionDecoder,
        ),
      );
    },
    getAppointmentMembershipsConsumption: async ({ appointmentId, occurrenceIndex }) => {
      return unsafeRight(
        await http.request(
          {
            method: 'GET',
            url: `${apiBaseUrl}/mp/appointments/${encodeURIComponent(appointmentId)}/memberships-consumption`,
            params: {
              occurrenceIndex: occurrenceIndex,
            },
          },
          getAppointmentMembershipsConsumptionDecoder,
        ),
      );
    },
  };
};
