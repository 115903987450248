import { IApiConfig } from '.';
import { request } from './utils';
import * as socketio from 'socket.io-client';

const urljoin = require('url-join');

// only one active socket is allowed.
let activeSocket: SocketIOClient.Socket | undefined;

export class AuthenticationApi {
  /**
   * Class constructor.
   */
  constructor(private _config: IApiConfig) {}

  /**
   * Create a new sms OTP auth req.
   */
  async createSmsOtpCodeRequest(params: {
    userPhone: string;
    recaptchaSiteKey: string;
    recaptchaToken: string;
    recaptchaAction: string;
  }): Promise<{ auth: { _id: string } }> {
    const data = await request({
      config: this._config,
      method: 'POST',
      path: `/apps/${this._config.appId}/authentications/sms-otp`,
      body: params,
    });

    return data.result;
  }

  /**
   * Confirm an sms OTP request.
   */
  async confirmSmsOtp(params: { _id: string; smsOtp: string }) {
    const data = await request({
      config: this._config,
      method: 'PUT',
      path: `/apps/${this._config.appId}/authentications/sms-otp/${params._id}`,
      body: {
        smsOtp: params.smsOtp,
      },
    });

    return data.result;
  }

  /**
   * Create reverse email auth.
   */
  async createReverseEmailRequest() {
    const data = await request({
      config: this._config,
      method: 'POST',
      path: `/apps/${this._config.appId}/authentications/reverse-email`,
    });

    return data.result;
  }

  /**
   * Wait for a reverse type auth response.
   */
  waitForReply(params: { authReqId: string }): Promise<string> {
    return new Promise((resolve, reject) => {
      const path = urljoin(this._config.hostname, `reverse-login-reply`);
      const socket = socketio.connect(path, {
        query: {
          appId: this._config.appId,
          _id: params.authReqId,
        },
      });
      socket.on('connect', () => {
        console.log('websocket connected');
      });
      socket.on('event', () => {
        console.log('event');
      });
      socket.on('auth', (data: any) => {
        resolve(data);
        socket.disconnect();
      });
      socket.on('error', (data: any) => {
        console.error(data);
        reject(data);
      });

      if (activeSocket) {
        activeSocket.disconnect();
        activeSocket = socket;
      }
    });
  }
}
