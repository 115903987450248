import { DailyIndefinite } from './dailyIndefinite';
import { Every2WeeksIndefinite } from './every2WeeksIndefinite';
import { MonthlyIndefinite } from './monthlyIndefinite';
import { Never } from './never';
import { WeeklyIndefinite } from './weeklyIndefinite';
import * as t from 'io-ts';

export * from './dailyIndefinite';
export * from './every2WeeksIndefinite';
export * from './monthlyIndefinite';
export * from './never';
export * from './weeklyIndefinite';

export type Any = DailyIndefinite | Every2WeeksIndefinite | MonthlyIndefinite | Never | WeeklyIndefinite;

export const JSON = t.union(
  [DailyIndefinite.JSON, Every2WeeksIndefinite.JSON, MonthlyIndefinite.JSON, Never.JSON, WeeklyIndefinite.JSON],
  'Any',
);

export const is = JSON.is;
