import { JSONable } from '../common/jsonable';
import * as t from 'io-ts';

export interface SubscriptionTierIdBrand {
  readonly SubscriptionTierId: unique symbol;
}

/**
 * Unique subscription tier identifier
 */
export type SubscriptionTierId = t.Branded<string, SubscriptionTierIdBrand>;

const JSON: t.Type<SubscriptionTierId, JSONable> = t.brand(
  t.string,
  (_id: string): _id is SubscriptionTierId => {
    return true;
  },
  'SubscriptionTierId',
);

const equals = (a: SubscriptionTierId, b: SubscriptionTierId): boolean => {
  return a === b;
};

export const SubscriptionTierId = {
  JSON,
  equals,
};
