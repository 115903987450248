import { AppointmentId } from '../calendar';
import { PageId } from '../pages';
import { CustomizedNotificationTemplate } from './customized-notification-template';
import { CustomizedNotificationTemplateId } from './customized-notification-template-id';
import { CustomizedNotificationType } from './customized-notification-type';
import { MeroSmsPageStatus } from './meroSmsPageStatus';
import { Notification, NotificationArray } from './notification';
import { NotificationId } from './notification-id';
import { NotificationType } from './notification-type';
import { NotificationsApi } from './notifications-api';
import { ProNotificationSettings } from './proNotificationSettings';
import { AnyReminderInfo } from './reminder-info';
import { UserDevice } from './user-device';
import { UserDeviceType } from './user-device-type';
import { UserNotificationsSettings } from './user-notifications-settings';
import { optionull } from '@mero/shared-sdk';
import { DecodeError, HttpClient, UnknownApiError, unsafeRight } from '@mero/shared-sdk';
import * as A from 'fp-ts/lib/Array';
import * as E from 'fp-ts/lib/Either';
import { pipe } from 'fp-ts/lib/function';
import * as t from 'io-ts';
import { DateFromISOString } from 'io-ts-types';

export const notificationsHttpClient = (env: { apiBaseUrl: string; http: HttpClient }): NotificationsApi => {
  const { apiBaseUrl, http } = env;

  const getNotificationByIdDecoder = http.decode.response(UnknownApiError, Notification);
  const getUserNotificationsSettingsDecoder = http.decode.response(UnknownApiError, UserNotificationsSettings);
  const updateUserNotificationsSettingsDecoder = http.decode.response(UnknownApiError, t.unknown);
  const markAsSeenDecoder = http.decode.response(UnknownApiError, t.unknown);
  const countUnseenDecoder = http.decode.response(UnknownApiError, t.type({ count: t.number }));
  const registerPageSmsRelayDecoder = http.decode.response(UnknownApiError, t.unknown);
  const unregisterPageSmsRelayDecoder = http.decode.response(UnknownApiError, t.unknown);
  const getUserDeviceDecoder = http.decode.optionalResponse(UnknownApiError, t.union([t.undefined, UserDevice]));
  const registerUserDeviceDecoder = http.decode.response(UnknownApiError, t.unknown);
  const unregisterUserDeviceDecoder = http.decode.response(UnknownApiError, t.unknown);
  const getMeroSmsStatusDecoder = http.decode.response(UnknownApiError, t.type({ result: t.array(UserDevice) }));
  const appointmentInfoShortCodeDecoder = http.decode.response(
    UnknownApiError,
    t.type({
      result: t.type({
        appointmentId: AppointmentId,
        occurrenceIndex: optionull(t.number),
        appointment: t.type({
          start: DateFromISOString,
          user: t.type({
            phone: t.string,
            firstname: t.string,
            lastname: t.string,
          }),
          worker: t.type({
            firstname: t.string,
            lastname: t.string,
          }),
          page: t.type({
            _id: PageId,
            name: t.string,
          }),
          canCreateFeedback: t.boolean,
        }),
      }),
    }),
  );
  const monthlyReportInfoShortCodeDecoder = http.decode.response(
    UnknownApiError,
    t.type({
      result: t.type({
        pageId: PageId,
        reportInterval: t.type({
          month: t.number,
          year: t.number,
        }),
      }),
    }),
  );
  const markManualSmsReminderAsSentDecoder = http.decode.response(UnknownApiError, t.unknown);
  const markManualSmsReminderAsDeletedDecoder = http.decode.response(UnknownApiError, t.unknown);
  const getUpcomingManualSmsRemindersDecoder = http.decode.response(UnknownApiError, t.array(AnyReminderInfo));
  const getRenderedTextForAppointmentCreatedDecoder = http.decode.response(UnknownApiError, t.type({ text: t.string }));
  const getRenderedTextForAppointmentUpdatedDecoder = http.decode.response(UnknownApiError, t.type({ text: t.string }));
  const getRenderedTextForAppointmentDeletedDecoder = http.decode.response(UnknownApiError, t.type({ text: t.string }));
  const getMeroSmsStatusForPageDecoder = http.decode.response(UnknownApiError, MeroSmsPageStatus.JSON);
  const createCustomizedNotificationTemplateDecoder = http.decode.response(
    UnknownApiError,
    t.type({ _id: CustomizedNotificationTemplateId.JSON }),
  );
  const updateCustomizedNotificationTemplateDecoder = http.decode.response(UnknownApiError, t.unknown);
  const getPageCustomizedNotificationTemplatesDecoder = http.decode.response(
    UnknownApiError,
    t.array(
      t.intersection([
        CustomizedNotificationTemplate.JSON,
        t.type({
          estimatedMessageLength: t.number,
        }),
      ]),
    ),
  );
  const getDefaultNotificationTemplateDecoder = http.decode.response(UnknownApiError, t.type({ template: t.string }));
  const deleteCustomizedNotificationTemplateDecoder = http.decode.response(UnknownApiError, t.unknown);
  const getCustomizedNotificationTemplatePreviewDecoder = http.decode.response(
    UnknownApiError,
    t.type({ text: t.string, estimatedMessageLength: t.number }),
  );

  const getAllNotificationTemplatesForPageDecoder = http.decode.response(
    UnknownApiError,
    t.array(
      t.type({
        type: CustomizedNotificationType.JSON,
        text: t.string,
        template: t.string,
        estimatedMessageLength: t.number,
      }),
    ),
  );
  const getProNotificationSettingsDecoder = http.decode.response(UnknownApiError, ProNotificationSettings.JSON);
  const updateProNotificationSettingsDecoder = http.decode.response(UnknownApiError, t.unknown);

  return {
    fetch: async ({ fromId, limit, type }) => {
      const params: {
        fromId?: NotificationId;
        limit?: number;
        type?: 'client' | 'worker' | 'worker_activity' | 'worker_requests';
      } = { fromId, limit, type };

      const unknownNotificationArray = http.decode.response(UnknownApiError, t.array(t.unknown));

      const unknownNotifications = unsafeRight(
        await http.request(
          {
            method: 'GET',
            url: `${apiBaseUrl}/notification`,
            params: params,
          },
          unknownNotificationArray,
        ),
      );

      const withNotificationTypeCodec = t.type({
        type: NotificationType,
      });

      return pipe(
        unknownNotifications,
        A.map(withNotificationTypeCodec.decode),
        A.chain(
          E.fold(
            () => [],
            (n) => [n],
          ),
        ),
        NotificationArray.decode,
        E.getOrElseW((e) => {
          throw new DecodeError(e);
        }),
      );
    },

    getNotificationById: async (notificationId) =>
      unsafeRight(
        await http.request(
          {
            method: 'GET',
            url: `${apiBaseUrl}/notification/${encodeURIComponent(notificationId)}`,
          },
          getNotificationByIdDecoder,
        ),
      ),

    getUserNotificationsSettings: async () =>
      unsafeRight(
        await http.request(
          {
            method: 'GET',
            url: `${apiBaseUrl}/notification/settings`,
          },
          getUserNotificationsSettingsDecoder,
        ),
      ),

    updateUserNotificationsSettings: async (settings) => {
      const requestBody: UserNotificationsSettings = settings;

      unsafeRight(
        await http.request(
          {
            method: 'PUT',
            url: `${apiBaseUrl}/notification/settings`,
            data: requestBody,
          },
          updateUserNotificationsSettingsDecoder,
        ),
      );
    },

    markAsSeen: async (filter) => {
      const params: {
        type?: 'client' | 'worker';
      } = {
        type: filter?.type,
      };

      unsafeRight(
        await http.request(
          {
            method: 'PUT',
            url: `${apiBaseUrl}/notification/seen`,
            params: params,
          },
          markAsSeenDecoder,
        ),
      );
    },

    countUnseen: async (filter) => {
      const params: {
        count: 'true';
        type?: 'client' | 'worker';
      } = {
        count: 'true',
        type: filter?.type,
      };

      const result = unsafeRight(
        await http.request(
          {
            method: 'GET',
            url: `${apiBaseUrl}/notification`,
            params: params,
          },
          countUnseenDecoder,
        ),
      );

      return result.count;
    },

    registerPageSmsRelay: async ({ pageId, identifier }) => {
      const requestBody: {
        identifier: string;
      } = { identifier };

      unsafeRight(
        await http.request(
          {
            method: 'POST',
            url: `${apiBaseUrl}/notification/page/${encodeURIComponent(pageId)}/sms-relay`,
            data: requestBody,
          },
          registerPageSmsRelayDecoder,
        ),
      );
    },

    unregisterPageSmsRelay: async ({ pageId, identifier }) => {
      unsafeRight(
        await http.request(
          {
            method: 'DELETE',
            url: `${apiBaseUrl}/notification/page/${encodeURIComponent(pageId)}/sms-relay/${encodeURIComponent(
              identifier,
            )}`,
          },
          unregisterPageSmsRelayDecoder,
        ),
      );
    },

    getUserDevice: async (identifier) =>
      unsafeRight(
        await http.request(
          {
            method: 'GET',
            url: `${apiBaseUrl}/notification/device/${encodeURIComponent(identifier)}`,
          },
          getUserDeviceDecoder,
        ),
      ),

    registerUserDevice: async ({ identifier, platform, bundleId }) => {
      const requestBody: {
        identifier: string;
        platform: UserDeviceType;
        bundleId: string;
      } = { identifier, platform, bundleId };

      unsafeRight(
        await http.request(
          {
            method: 'POST',
            url: `${apiBaseUrl}/notification/device`,
            data: requestBody,
          },
          registerUserDeviceDecoder,
        ),
      );
    },

    unregisterUserDevice: async ({ identifier }) => {
      unsafeRight(
        await http.request(
          {
            method: 'DELETE',
            url: `${apiBaseUrl}/notification/device/${encodeURIComponent(identifier)}`,
          },
          unregisterUserDeviceDecoder,
        ),
      );
    },

    getMeroSmsStatus: async ({ pageId }) =>
      unsafeRight(
        await http.request(
          {
            method: 'GET',
            url: `${apiBaseUrl}/notification/page/${pageId}/devices`,
          },
          getMeroSmsStatusDecoder,
        ),
      ),

    getAppointmentInfoByShortUrlCode: async ({ code }: { code: string }) =>
      unsafeRight(
        await http.request(
          {
            method: 'GET',
            url: `${apiBaseUrl}/notification/short-urls/appointments/${code}`,
          },
          appointmentInfoShortCodeDecoder,
        ),
      ).result,

    getMonthlyReportInfoByShortUrlCode: async ({ code }: { code: string }) =>
      unsafeRight(
        await http.request(
          {
            method: 'GET',
            url: `${apiBaseUrl}/notification/short-urls/monthly-report/${code}`,
          },
          monthlyReportInfoShortCodeDecoder,
        ),
      ).result,

    getUpcomingManualSmsReminders: async ({ pageId }) =>
      unsafeRight(
        await http.request(
          {
            method: 'GET',
            url: `${apiBaseUrl}/notification/page/${pageId}/reminders/manual-sms`,
          },
          getUpcomingManualSmsRemindersDecoder,
        ),
      ),
    markManualSmsReminderAsDeleted: async ({ reminderId: reminderId }) => {
      unsafeRight(
        await http.request(
          {
            method: 'DELETE',
            url: `${apiBaseUrl}/notification/reminder/${reminderId}/manual-sms`,
          },
          markManualSmsReminderAsDeletedDecoder,
        ),
      );
    },

    markManualSmsReminderAsSent: async ({ reminderId: reminderId }) => {
      unsafeRight(
        await http.request(
          {
            method: 'PUT',
            url: `${apiBaseUrl}/notification/reminder/${reminderId}/manual-sms/sent`,
          },
          markManualSmsReminderAsSentDecoder,
        ),
      );
    },

    getRenderedTextForAppointmentCreated: async ({ appointmentId }) =>
      unsafeRight(
        await http.request(
          {
            method: 'GET',
            url: `${apiBaseUrl}/notification/appointments/${appointmentId}/rendered/create`,
          },
          getRenderedTextForAppointmentCreatedDecoder,
        ),
      ),
    getRenderedTextForAppointmentUpdated: async ({ appointmentId, fromStart, fromEnd, occurrenceIndex }) =>
      unsafeRight(
        await http.request(
          {
            method: 'GET',
            url: `${apiBaseUrl}/notification/appointments/${appointmentId}/rendered/update/${fromStart}/${fromEnd}`,
            params: {
              occurrenceIndex,
            },
          },
          getRenderedTextForAppointmentUpdatedDecoder,
        ),
      ),
    getRenderedTextForAppointmentDeleted: async ({ appointmentId, occurrenceIndex }) =>
      unsafeRight(
        await http.request(
          {
            method: 'GET',
            url: `${apiBaseUrl}/notification/appointments/${appointmentId}/rendered/delete`,
            params: {
              occurrenceIndex,
            },
          },
          getRenderedTextForAppointmentDeletedDecoder,
        ),
      ),
    getMeroSmsStatusForPage: async (pageId) => {
      return unsafeRight(
        await http.request(
          {
            method: 'GET',
            url: `${apiBaseUrl}/mero-sms/page/${pageId}/status`,
          },
          getMeroSmsStatusForPageDecoder,
        ),
      );
    },
    async createCustomizedNotificationTemplate({ pageId, template, type }) {
      const requestBody = { template, type };

      return unsafeRight(
        await http.request(
          {
            method: 'POST',
            url: `${apiBaseUrl}/notification/page/${pageId}/customized-notifications`,
            data: requestBody,
          },
          createCustomizedNotificationTemplateDecoder,
        ),
      );
    },
    async updateCustomizedNotificationTemplate({ pageId, templateId, template }) {
      const requestBody = { template };

      unsafeRight(
        await http.request(
          {
            method: 'PUT',
            url: `${apiBaseUrl}/notification/page/${pageId}/customized-notifications/${templateId}`,
            data: requestBody,
          },
          updateCustomizedNotificationTemplateDecoder,
        ),
      );
    },
    async getPageCustomizedNotificationTemplates(pageId) {
      return unsafeRight(
        await http.request(
          {
            method: 'GET',
            url: `${apiBaseUrl}/notification/page/${pageId}/customized-notifications`,
          },
          getPageCustomizedNotificationTemplatesDecoder,
        ),
      );
    },
    async getDefaultNotificationTemplate({ pageId, type }) {
      return unsafeRight(
        await http.request(
          {
            method: 'GET',
            url: `${apiBaseUrl}/notification/page/${pageId}/default-notification-template/${type}`,
          },
          getDefaultNotificationTemplateDecoder,
        ),
      );
    },
    async deleteCustomizedNotificationTemplate({ pageId, templateId }) {
      unsafeRight(
        await http.request(
          {
            method: 'DELETE',
            url: `${apiBaseUrl}/notification/page/${pageId}/customized-notifications/${templateId}`,
          },
          deleteCustomizedNotificationTemplateDecoder,
        ),
      );
    },
    async createCustomizedNotificationTemplatePreview({ pageId, type, template }) {
      const requestBody: { template: string } = { template };

      return unsafeRight(
        await http.request(
          {
            method: 'POST',
            url: `${apiBaseUrl}/notification/page/${pageId}/notification-template-preview/${type}`,
            data: requestBody,
          },
          getCustomizedNotificationTemplatePreviewDecoder,
        ),
      );
    },
    async getAllNotificationTemplatesForPage({ pageId }) {
      return unsafeRight(
        await http.request(
          {
            method: 'GET',
            url: `${apiBaseUrl}/notification/page/${pageId}/all-notification-templates`,
          },
          getAllNotificationTemplatesForPageDecoder,
        ),
      );
    },
    async getProNotificationSettings({ pageId }) {
      return unsafeRight(
        await http.request(
          {
            method: 'GET',
            url: `${apiBaseUrl}/notification/page/${pageId}/pro-notification-settings`,
          },
          getProNotificationSettingsDecoder,
        ),
      );
    },
    async updateProNotificationSettings({
      pageId,
      otherProAppointmentNotificationSettings,
      ownAppointmentNotificationSettings,
    }) {
      const requestBody = { otherProAppointmentNotificationSettings, ownAppointmentNotificationSettings };
      unsafeRight(
        await http.request(
          {
            method: 'PUT',
            url: `${apiBaseUrl}/notification/page/${pageId}/pro-notification-settings`,
            data: requestBody,
          },
          updateProNotificationSettingsDecoder,
        ),
      );
    },
  };
};
