import { ScaledNumber, Money, MeroUnits, JSONable } from '@mero/shared-sdk';
import * as t from 'io-ts';

export type OnlinePaymentsAccountBalance = {
  readonly total: Money<ScaledNumber, MeroUnits.RON>;
  readonly pendingTotal: Money<ScaledNumber, MeroUnits.RON>;
  readonly availableTotal: Money<ScaledNumber, MeroUnits.RON>;
};

const JSON: t.Type<OnlinePaymentsAccountBalance, JSONable> = t.type(
  {
    total: Money.json(ScaledNumber.JSON, MeroUnits.RON.JSON),
    pendingTotal: Money.json(ScaledNumber.JSON, MeroUnits.RON.JSON),
    availableTotal: Money.json(ScaledNumber.JSON, MeroUnits.RON.JSON),
  },
  'OnlinePaymentsAccountBalance',
);

export const OnlinePaymentsAccountBalance = {
  JSON,
};
