import { JSONable, MeroUnits, Money, ScaledNumber } from '@mero/shared-sdk';
import * as t from 'io-ts';

export type CheckoutReportPaymentsByType<Unit extends MeroUnits.Any> = {
  readonly cash: Money<ScaledNumber, Unit>;
  readonly card: Money<ScaledNumber, Unit>;
  readonly bankTransfer: Money<ScaledNumber, Unit>;
};

const json = <Unit extends MeroUnits.Any>(unit: Unit): t.Type<CheckoutReportPaymentsByType<Unit>, JSONable> => {
  const unitC = t.literal(unit);

  return t.type(
    {
      cash: Money.json(ScaledNumber.JSON, unitC),
      card: Money.json(ScaledNumber.JSON, unitC),
      bankTransfer: Money.json(ScaledNumber.JSON, unitC),
    },
    'CheckoutReportPaymentsByType',
  );
};

export const CheckoutReportPaymentsByType = {
  json,
};
