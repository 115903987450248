import * as React from 'react';
import Svg, { G, Circle, Path } from 'react-native-svg';

type Props = {
  size?: number | string;
  disabled?: boolean;
  onPress?: () => void;
};

const MembershipRound = ({ size = 60, disabled, onPress }: Props) => (
  <Svg width={size} height={size} viewBox={`0 0 ${size} ${size}`} disabled={disabled} onPress={onPress}>
    <G id="team-4" transform="translate(0 -0.5)">
      <Circle id="Mask" cx="30" cy="30" r="30" transform="translate(0 0.5)" fill="#e9ecef" />
      <G id="format_list_bulleted-24px" transform="translate(10.313 10.813)">
        <Path id="Path_8275" d="M0,0H39.375V39.375H0Z" fill="none" />
        <G id="Orion_contact-card" transform="translate(4.922 8.613)">
          <Path
            id="layer2"
            d="M26.184,10v.484a2.418,2.418,0,1,1-4.837,0V10H11.674v.484a2.418,2.418,0,1,1-4.837,0V10H2V31.282H31.021V10Z"
            transform="translate(-2 -10)"
            fill="none"
            stroke="#52577f"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeMiterlimit="10"
            strokeWidth="2"
          />
          <Path
            id="layer1"
            d="M38,26h7.739M38,29.869h7.739M38,33.739h3.869"
            transform="translate(-20.588 -18.261)"
            fill="none"
            stroke="#52577f"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeMiterlimit="10"
            strokeWidth="2"
          />
          <Circle
            id="layer1-2"
            cx="2.622"
            cy="2.622"
            r="2.622"
            transform="translate(6.122 6.291)"
            fill="none"
            stroke="#52577f"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeMiterlimit="10"
            strokeWidth="2"
          />
          <Path
            id="layer1-3"
            d="M14.836,34A4.885,4.885,0,0,0,10,38.982V39.8h9.674v-.822A4.885,4.885,0,0,0,14.836,34Z"
            transform="translate(-6.13 -22.392)"
            fill="none"
            stroke="#52577f"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeMiterlimit="10"
            strokeWidth="2"
          />
        </G>
      </G>
    </G>
  </Svg>
);

export default React.memo(MembershipRound);
