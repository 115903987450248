import * as React from 'react';

import AndroidApps from './AndroidApps';
import AndroidSettings from './AndroidSettings';
import AndroidShare from './AndroidShare';
import AndroidSlider from './AndroidSlider';
import AnonymousUserIcon from './AnonymousUser';
import Apple from './Apple';
import AppleNotifications from './AppleNotifications';
import ApplePay from './ApplePay';
import AppleSettings from './AppleSettings';
import AppleShare from './AppleShare';
import AppleSlider from './AppleSlider';
import ArrowDown from './ArrowDown';
import ArrowLeft from './ArrowLeft';
import ArrowRight from './ArrowRight';
import ArrowUp from './ArrowUp';
import Back from './Back';
import BankTransfer from './BankTransfer';
import Bell from './Bell';
import Calendar from './Calendar';
import CalendarBlock from './CalendarBlock';
import CalendarChecked from './CalendarChecked';
import CalendarDiscount from './CalendarDiscount';
import CalendarGiftCard from './CalendarGiftCard';
import CalendarMenu from './CalendarMenu';
import CalendarPending from './CalendarPending';
import CalendarTab from './CalendarTab';
import CalendarToday from './CalendarToday';
import Card from './Card';
import Cash from './Cash';
import CashAndCard from './CashAndCard';
import CheckOutline from './CheckOutline';
import Checked from './Checked';
import CheckedOutline from './CheckedOutline';
import Checkmark from './Checkmark';
import CheckoutMenu from './CheckoutMenu';
import CircleChecked from './CircleChecked';
import CircleClose from './CircleClose';
import ClientBlocked from './ClientBlocked';
import ClientHistory from './ClientHistory';
import ClientHistoryNote from './ClientHistoryNote';
import ClientWarned from './ClientWarned';
import ClientsMenu from './ClientsMenu';
import Clock from './Clock';
import Close from './Close';
import Collapse from './Collapse';
import ConfirmLarge from './ConfirmLarge';
import Discount from './Discount';
import Down from './Down';
import Dropdown from './Dropdown';
import Expand from './Expand';
import Facebook from './Facebook';
import Favorite from './Favorite';
import FavoriteFilled from './FavoriteFilled';
import FavoritesMenu from './FavoritesMenu';
import File from './File';
import Gift from './Gift';
import GiftCard from './GiftCard';
import GiftCardRound from './GiftCardRound';
import GiftCardSmall from './GiftCardSmall';
import GoDown from './GoDown';
import GoRight from './GoRight';
import GoUp from './GoUp';
import Google from './Google';
import History from './History';
import HomeMenu from './HomeMenu';
import Info from './Info';
import Instagram from './Instagram';
import Location from './Location';
import LocationServices from './LocationServices';
import LocationServicesSquare from './LocationServicesSquare';
import Lock from './Lock';
import Mail from './Mail';
import Mastercard from './Mastercard';
import Membership from './Membership';
import MembershipRound from './MembershipRound';
import MembershipRoundSmall from './MembershipRoundSmall';
import MembershipSmall from './MembershipSmall';
import Menu from './Menu';
import Mero from './Mero';
import MeroMenu from './MeroMenu';
import MyAccountMenu from './MyAccountMenu';
import Next from './Next';
import NotificationAlertIcon from './NotificationAlertIcon';
import NotificationCalendarCancelIcon from './NotificationCalendarCancelIcon';
import NotificationCalendarCheckIcon from './NotificationCalendarCheckIcon';
import NotificationCalendarClockIcon from './NotificationCalendarClockIcon';
import NotificationCalendarOpenIcon from './NotificationCalendarOpenIcon';
import NotificationClientProfileIcon from './NotificationClientProfileIcon';
import NotificationPaymentIcon from './NotificationPaymentIcon';
import NotificationScissorsIcon from './NotificationScissorsIcon';
import NotificationStarIcon from './NotificationStarIcon';
import NotificationsMenu from './NotificationsMenu';
import OptionsDots from './OptionsDots';
import Payment from './Payment';
import PendingLarge from './PendingLarge';
import Phone from './Phone';
import PinStar from './PinStar';
import Plus from './Plus';
import PlusScrewSmall from './PlusScrewSmall';
import Previous from './Previous';
import ProMenuCalendarSettings from './ProMenuCalendarSettings';
import ProMenuClientNotifications from './ProMenuClientNotifications';
import ProMenuGiftcards from './ProMenuGiftcards';
import ProMenuLocation from './ProMenuLocation';
import ProMenuMarketing from './ProMenuMarketing';
import ProMenuPageDetails from './ProMenuPageDetails';
import ProMenuPayments from './ProMenuPayments';
import ProMenuPendingClients from './ProMenuPendingClients';
import ProMenuPermissions from './ProMenuPermissions';
import ProMenuPhotoGallery from './ProMenuPhotoGallery';
import ProMenuPlaces from './ProMenuPlaces';
import ProMenuProfileDetails from './ProMenuProfileDetails';
import ProMenuReports from './ProMenuReports';
import ProMenuReviews from './ProMenuReviews';
import ProMenuServices from './ProMenuServices';
import ProMenuSubscription from './ProMenuSubscription';
import ProMenuTeam from './ProMenuTeam';
import ProfileDetails from './ProfileDetails';
import ProfileNotifications from './ProfileNotifications';
import ProfilePros from './ProfilePros';
import ProfileSettings from './ProfileSettings';
import Recurring from './Recurring';
import Search from './Search';
import SearchMenu from './SearchMenu';
import Secure from './Secure';
import Star from './Star';
import Telephone from './Telephone';
import Unchecked from './Unchecked';
import User from './User';
import Visa from './Visa';
import Warn from './Warn';

export { default as NotificationCalendarOpenIcon } from './NotificationCalendarOpenIcon';
export { default as NotificationScissorsIcon } from './NotificationScissorsIcon';
export { default as NotificationCalendarCheckIcon } from './NotificationCalendarCheckIcon';
export { default as NotificationCalendarCancelIcon } from './NotificationCalendarCancelIcon';
export { default as NotificationCalendarClockIcon } from './NotificationCalendarClockIcon';
export { default as NotificationClientProfileIcon } from './NotificationClientProfileIcon';
export { default as NotificationAlertIcon } from './NotificationAlertIcon';
export { default as NotificationPaymentIcon } from './NotificationPaymentIcon';
export { default as NotificationEmptyScreenIcon } from './NotificationEmptyScreenIcon';
export { default as NotificationStarIcon } from './NotificationStarIcon';

export type IconType =
  | 'back'
  | 'close'
  | 'clock'
  | 'phone'
  | 'mail'
  | 'facebook'
  | 'apple'
  | 'google'
  | 'instagram'
  | 'search'
  | 'circle-close'
  | 'next'
  | 'checked'
  | 'checkmark'
  | 'unchecked'
  | 'checked-outline'
  | 'file'
  | 'calendar_menu'
  | 'calendar_tab'
  | 'clients_menu'
  | 'notifications_menu'
  | 'checkout-menu'
  | 'menu'
  | 'dropdown'
  | 'apple-settings'
  | 'location-services-square'
  | 'mero'
  | 'notification_calendar'
  | 'notification_calendar_cancel'
  | 'notification_client_accepted'
  | 'notification_pro_accepted'
  | 'notification_appointment_accepted'
  | 'notification_appointment_modified'
  | 'notification_alert'
  | 'notification_payment'
  | 'notification_star'
  | 'plus'
  | 'plus-screw-small'
  | 'calendar-today'
  | 'location'
  | 'location-services'
  | 'my_account_menu'
  | 'home_menu'
  | 'search_menu'
  | 'favorites_menu'
  | 'info'
  | 'arrow-left'
  | 'arrow-right'
  | 'arrow-down'
  | 'star'
  | 'circle-checked'
  | 'client-blocked'
  | 'client-warned'
  | 'user'
  | 'telephone'
  | 'expand'
  | 'collapse'
  | 'previous'
  | 'calendar-pending'
  | 'calendar-checked'
  | 'profile-details'
  | 'profile-notifications'
  | 'profile-settings'
  | 'profile-pros'
  | 'calendar'
  | 'calendar-block'
  | 'payment'
  | 'confirm-large'
  | 'pending-large'
  | 'bell'
  | 'apple-notifications'
  | 'apple-slider'
  | 'visa'
  | 'mastercard'
  | 'lock'
  | 'apple-pay'
  | 'android-apps'
  | 'android-settings'
  | 'android-slider'
  | 'pin-star'
  | 'android-share'
  | 'apple-share'
  | 'gift'
  | 'go-right'
  | 'check-outline'
  | 'down'
  | 'secure'
  | 'gift-card'
  | 'gift-card-small'
  | 'recurring'
  | 'discount'
  | 'calendar-gift-card'
  | 'calendar-discount'
  | 'mero-menu'
  | 'options-dots'
  | 'client-history-note'
  | 'client-history'
  | 'pro-menu-marketing'
  | 'pro-menu-payments'
  | 'pro-menu-places'
  | 'pro-menu-reports'
  | 'pro-menu-reviews'
  | 'pro-menu-services'
  | 'pro-menu-subscription'
  | 'pro-menu-team'
  | 'pro-menu-page-details'
  | 'pro-menu-photo-gallery'
  | 'pro-menu-permissions'
  | 'pro-menu-client-notifications'
  | 'pro-menu-pending-clients'
  | 'pro-menu-calendar-settings'
  | 'pro-menu-profile-details'
  | 'pro-menu-giftcards'
  | 'pro-menu-location'
  | 'favorite'
  | 'favorite-filled'
  | 'go-up'
  | 'go-down'
  | 'warn'
  | 'anonymous-user'
  | 'bank-transfer'
  | 'card'
  | 'card-and-cash'
  | 'cash'
  | 'giftcard-round'
  | 'history'
  | 'membership-small'
  | 'membership'
  | 'membership-round'
  | 'arrow-up'
  | 'membership-round-small';

/**
 * Icon implementation shared props
 */
export type IconImplProps = {
  readonly size?: number | string;
  readonly color?: string;
  readonly backgroundColor?: string;
  readonly disabled?: boolean;
  readonly onPress?: () => void;
  readonly width?: number;
  readonly height?: number;
  readonly rotate?: boolean;
};

const IconsMap: { [K in IconType]: React.FC<IconImplProps> } = {
  back: Back,
  next: Next,
  close: Close,
  'circle-close': CircleClose,
  clock: Clock,
  phone: Phone,
  mail: Mail,
  facebook: Facebook,
  apple: Apple,
  'apple-settings': AppleSettings,
  google: Google,
  instagram: Instagram,
  search: Search,
  checked: Checked,
  unchecked: Unchecked,
  'checked-outline': CheckedOutline,
  file: File,
  calendar_menu: CalendarMenu,
  calendar_tab: CalendarTab,
  clients_menu: ClientsMenu,
  notifications_menu: NotificationsMenu,
  'checkout-menu': CheckoutMenu,
  menu: Menu,
  dropdown: Dropdown,
  notification_calendar: NotificationCalendarOpenIcon,
  notification_calendar_cancel: NotificationCalendarCancelIcon,
  notification_client_accepted: NotificationClientProfileIcon,
  notification_pro_accepted: NotificationScissorsIcon,
  notification_appointment_accepted: NotificationCalendarCheckIcon,
  notification_appointment_modified: NotificationCalendarClockIcon,
  notification_alert: NotificationAlertIcon,
  notification_payment: NotificationPaymentIcon,
  notification_star: NotificationStarIcon,
  plus: Plus,
  'plus-screw-small': PlusScrewSmall,
  'calendar-today': CalendarToday,
  'location-services': LocationServices,
  'location-services-square': LocationServicesSquare,
  location: Location,
  mero: Mero,
  my_account_menu: MyAccountMenu,
  home_menu: HomeMenu,
  search_menu: SearchMenu,
  favorites_menu: FavoritesMenu,
  info: Info,
  'arrow-left': ArrowLeft,
  'arrow-right': ArrowRight,
  'arrow-down': ArrowDown,
  star: Star,
  checkmark: Checkmark,
  'circle-checked': CircleChecked,
  'check-outline': CheckOutline,
  'client-blocked': ClientBlocked,
  'client-warned': ClientWarned,
  user: User,
  telephone: Telephone,
  expand: Expand,
  collapse: Collapse,
  previous: Previous,
  'calendar-pending': CalendarPending,
  'calendar-checked': CalendarChecked,
  'profile-details': ProfileDetails,
  'profile-notifications': ProfileNotifications,
  'profile-settings': ProfileSettings,
  'profile-pros': ProfilePros,
  calendar: Calendar,
  'calendar-block': CalendarBlock,
  payment: Payment,
  'confirm-large': ConfirmLarge,
  'pending-large': PendingLarge,
  bell: Bell,
  'apple-notifications': AppleNotifications,
  'apple-slider': AppleSlider,
  visa: Visa,
  mastercard: Mastercard,
  lock: Lock,
  'apple-pay': ApplePay,
  'android-apps': AndroidApps,
  'android-settings': AndroidSettings,
  'android-slider': AndroidSlider,
  'pin-star': PinStar,
  'android-share': AndroidShare,
  'apple-share': AppleShare,
  gift: Gift,
  'go-right': GoRight,
  down: Down,
  secure: Secure,
  'gift-card': GiftCard,
  'gift-card-small': GiftCardSmall,
  recurring: Recurring,
  discount: Discount,
  'calendar-gift-card': CalendarGiftCard,
  'calendar-discount': CalendarDiscount,
  'mero-menu': MeroMenu,
  'options-dots': OptionsDots,
  'client-history-note': ClientHistoryNote,
  'client-history': ClientHistory,
  'pro-menu-marketing': ProMenuMarketing,
  'pro-menu-payments': ProMenuPayments,
  'pro-menu-places': ProMenuPlaces,
  'pro-menu-reports': ProMenuReports,
  'pro-menu-reviews': ProMenuReviews,
  'pro-menu-services': ProMenuServices,
  'pro-menu-subscription': ProMenuSubscription,
  'pro-menu-team': ProMenuTeam,
  'pro-menu-page-details': ProMenuPageDetails,
  'pro-menu-photo-gallery': ProMenuPhotoGallery,
  'pro-menu-permissions': ProMenuPermissions,
  'pro-menu-client-notifications': ProMenuClientNotifications,
  'pro-menu-pending-clients': ProMenuPendingClients,
  'pro-menu-calendar-settings': ProMenuCalendarSettings,
  'pro-menu-profile-details': ProMenuProfileDetails,
  'pro-menu-giftcards': ProMenuGiftcards,
  'pro-menu-location': ProMenuLocation,
  favorite: Favorite,
  'favorite-filled': FavoriteFilled,
  'go-up': GoUp,
  'go-down': GoDown,
  warn: Warn,
  'anonymous-user': AnonymousUserIcon,
  'bank-transfer': BankTransfer,
  card: Card,
  'card-and-cash': CashAndCard,
  cash: Cash,
  'giftcard-round': GiftCardRound,
  history: History,
  'membership-small': MembershipSmall,
  membership: Membership,
  'membership-round': MembershipRound,
  'arrow-up': ArrowUp,
  'membership-round-small': MembershipRoundSmall,
};

export type IconProps = {
  readonly type: IconType;
  readonly size?: number | string;
  readonly color?: string;
  readonly backgroundColor?: string;
  readonly disabled?: boolean;
  readonly onClick?: () => void;
  readonly width?: number;
  readonly height?: number;
  readonly rotate?: boolean;
};

const Icon: React.FC<IconProps> = ({
  type,
  size,
  color,
  backgroundColor,
  disabled: disabledFlag = false,
  onClick,
  width,
  height,
  rotate,
}: IconProps): React.ReactElement => {
  // Don't catch press events by default
  const disabled = disabledFlag || onClick === undefined;
  const C = IconsMap[type];

  return (
    <C
      size={size}
      color={color}
      backgroundColor={backgroundColor}
      disabled={disabled}
      onPress={onClick}
      width={width}
      height={height}
      rotate={rotate}
    />
  );
};

export default React.memo(Icon);
