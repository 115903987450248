import { PageId } from '../pages/page-id';
import { BoostClientClaimStatus } from './boost-client-claim-status';
import { HasBoostClientId } from './boost-client-id';
import { HasClientId } from './client-id';
import {
  HasId,
  HasOptionalFirstLastName,
  HasUserId,
  Nullean,
  OptionalPhoneNumber,
  optionull,
  ProfileImage,
} from '@mero/shared-sdk';
import * as t from 'io-ts';

/**
 * Boost client profile info
 */
export const BoostClient = t.intersection(
  [
    HasBoostClientId,
    t.type(
      {
        page: HasId.json(PageId),
        user: t.intersection(
          [
            HasUserId,
            HasOptionalFirstLastName,
            t.partial(
              {
                phone: OptionalPhoneNumber,
                photo: optionull(ProfileImage),
              },
              '?',
            ),
          ],
          'BoostClientUser',
        ),
      },
      '!',
    ),
    t.partial(
      {
        client: t.intersection([
          HasClientId,
          t.type({
            user: HasOptionalFirstLastName,
          }),
        ]),
        claim: t.type({
          status: BoostClientClaimStatus,
        }),
        stats: t.partial(
          {
            profit: t.number,
            cost: t.number,
          },
          '?',
        ),
        hideBoostDetails: Nullean,
      },
      '?',
    ),
  ],
  'BoostClient',
);

export interface BoostClient extends t.TypeOf<typeof BoostClient> {}
