import { PageId } from '../pages';
import { MembershipPurchaseId } from './membershipPurchaseId';
import { PurchasedMembershipTemplate } from './purchasedMembershipTemplate';
import { MeroUnits, Money, Option, ScaledNumber } from '@mero/shared-sdk';

export type UserPurchasedMembership<Unit extends MeroUnits.Any> = {
  readonly _id: MembershipPurchaseId;
  readonly unit: Unit;
  readonly page: Option<{
    readonly _id: PageId;
    readonly name: string;
  }>;
  readonly purchasedOn: Date;
  readonly template: PurchasedMembershipTemplate<Unit>;
  readonly status: 'Active' | 'Consumed' | 'Cancelled';
  readonly debt: Money<ScaledNumber, Unit>;
};

const getMembershipStatus = <Unit extends MeroUnits.Any>(
  purchasedMembership: Pick<UserPurchasedMembership<Unit>, 'status' | 'template' | 'purchasedOn'>,
): 'Active' | 'Expired' | 'Inactive' | 'Cancelled' | 'Consumed' => {
  if (purchasedMembership.status !== 'Active') {
    return purchasedMembership.status;
  }

  const now = new Date();
  if (now < purchasedMembership.template.validFor.from) {
    return 'Inactive';
  }

  if (purchasedMembership.template.validFor.type === 'Unlimited') {
    return 'Active';
  } else {
    if (now <= purchasedMembership.template.validFor.to) {
      return 'Active';
    } else {
      return 'Expired';
    }
  }
};

export const UserPurchasedMembership = {
  getMembershipStatus,
};
