import { ProBootApi } from './proBootApi';
import { ProBootState } from './proBootState';
import { HttpClient, UnknownApiError, unsafeRight } from '@mero/shared-sdk';

export const proBootHttpClient = (env: { apiBaseUrl: string; http: HttpClient }): ProBootApi => {
  const { apiBaseUrl, http } = env;

  const getBootStateDecoder = http.decode.response(UnknownApiError, ProBootState.JSON);

  return {
    getBootState: async () => {
      return unsafeRight(
        await http.request(
          {
            method: 'GET',
            url: `${apiBaseUrl}/pro/boot`,
          },
          getBootStateDecoder,
        ),
      );
    },
  };
};
