import { ProductBrandId } from '../../productBrand';
import { ProductCategoryId } from '../../productCategory';
import { ProductStatus } from '../productStatus';
import { SortDirection } from '@mero/shared-sdk';
import * as t from 'io-ts';

export type SortField = 'categoryOrder' | 'name' | 'discountedPrice' | 'vatRate' | 'createdOn';

export const SortField = {
  CREATED_ON: 'createdOn',
  NAME: 'name',
  DISCOUNTED_PRICE: 'discountedPrice',
  VAT_RATE: 'vatRate',
  CATEGORY_ORDER: 'categoryOrder',
  JSON: t.union(
    [
      t.literal('categoryOrder'),
      t.literal('name'),
      t.literal('discountedPrice'),
      t.literal('vatRate'),
      t.literal('createdOn'),
    ],
    'SortField',
  ),
};

export type AvailabilityFilter = 'OnlineRetail' | 'OfflineRetail' | 'Consumable';

export const AvailabilityFilter = {
  JSON: t.union([t.literal('Consumable'), t.literal('OfflineRetail'), t.literal('OnlineRetail')], 'Availability'),
};

export type ProductSearchType = {
  readonly search?: string;
  readonly sortBy?: SortField;
  readonly sortDirection?: SortDirection;
  readonly categoryId?: ProductCategoryId | 'none';
  readonly brandId?: ProductBrandId | 'none';
  readonly limit: number;
  readonly page?: string;
  readonly status?: ProductStatus.Any;
  readonly availability?: AvailabilityFilter;
  readonly stockValue?: 'any' | 'positive';
};
