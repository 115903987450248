import * as React from 'react';
import Svg, { Path, G, Circle } from 'react-native-svg';

const Payment: React.FC = () => (
  <Svg width="19.2" height="15.6" viewBox="0 0 19.2 15.6">
    <G transform="translate(-1.4 -7.4)">
      <Path
        d="M3,8H20.4v9.6H3Z"
        transform="translate(-0.7)"
        fill="none"
        stroke="#52577f"
        stroke-linecap="round"
        strokeLinejoin="round"
        strokeMiterlimit="10"
        strokeWidth="1.2"
      />
      <Circle
        cx="2.4"
        cy="2.4"
        r="2.4"
        transform="translate(8.6 10.4)"
        fill="none"
        stroke="#52577f"
        stroke-linecap="round"
        strokeLinejoin="round"
        strokeMiterlimit="10"
        strokeWidth="1.2"
      />
      <Path
        d="M2,48H20M2,50.4H20"
        transform="translate(0 -28)"
        fill="none"
        stroke="#52577f"
        stroke-linecap="round"
        strokeLinejoin="round"
        strokeMiterlimit="10"
        strokeWidth="1.2"
      />
      <Path
        d="M12,16h1.2m9.6,4.8H24"
        transform="translate(-7 -5.6)"
        fill="none"
        stroke="#52577f"
        stroke-linecap="round"
        strokeLinejoin="round"
        strokeMiterlimit="10"
        strokeWidth="1.2"
      />
    </G>
  </Svg>
);

export default React.memo(Payment);
