import { CheckoutProductSalesReportItem } from './checkoutProductSalesReportItem';
import { CheckoutProductSalesReportTotals } from './checkoutProductSalesReportTotals';
import { JSONable, MeroUnits } from '@mero/shared-sdk';
import * as t from 'io-ts';

export type CheckoutProductSalesReportPage<Unit extends MeroUnits.Any> = {
  readonly items: CheckoutProductSalesReportItem<Unit>[];
  readonly totals: CheckoutProductSalesReportTotals<Unit>;
};

const json = <Unit extends MeroUnits.Any>(unit: Unit): t.Type<CheckoutProductSalesReportPage<Unit>, JSONable> => {
  return t.type(
    {
      items: t.array(CheckoutProductSalesReportItem.json(unit)),
      totals: CheckoutProductSalesReportTotals.json(unit),
    },
    `CheckoutProductSalesReportPage<${unit}>`,
  );
};

export const CheckoutProductSalesReportPage = {
  json,
};
