import { ObjectId } from '@mero/shared-sdk';
import * as t from 'io-ts';

export interface ServiceIdBrand {
  readonly ServiceId: unique symbol;
}

export const ServiceId = t.brand(
  ObjectId,
  (_id: ObjectId): _id is t.Branded<ObjectId, ServiceIdBrand> => true,
  'ServiceId',
);
export type ServiceId = t.TypeOf<typeof ServiceId>;

export const HasServiceId = t.type(
  {
    _id: ServiceId,
  },
  'HasServiceId',
);
export interface HasServiceId extends t.TypeOf<typeof HasServiceId> {}
