import { DayTime } from '@mero/shared-sdk';
import * as Eq from 'fp-ts/Eq';
import * as B from 'fp-ts/boolean';
import * as N from 'fp-ts/number';
import * as t from 'io-ts';

/**
 * A left-closed, right-open working interval of time in a day
 * ex. if to is 18:00 it means at 18:00 the worker is not working anymore
 *
 * Full day is covered by wholeDay flag
 */
export type WorkInterval = {
  readonly from: DayTime;
  readonly to: DayTime;
  readonly active: boolean;
  readonly wholeDay: boolean;
};

const JSON = t.type(
  {
    from: DayTime.JSON,
    to: DayTime.JSON,
    active: t.boolean,
    wholeDay: t.boolean,
  },
  'WorkInterval',
);

const DayTimeEq: Eq.Eq<DayTime> = Eq.struct<DayTime>({
  hour: N.Eq,
  minute: N.Eq,
});

const eq: Eq.Eq<WorkInterval> = Eq.struct<WorkInterval>({
  from: DayTimeEq,
  to: DayTimeEq,
  active: B.Eq,
  wholeDay: B.Eq,
});

const FULLDAY_INACTIVE: WorkInterval = {
  from: DayTime.DAY_START,
  to: DayTime.DAY_END,
  active: false,
  wholeDay: true,
};

const FULLDAY_ACTIVE: WorkInterval = {
  from: DayTime.DAY_START,
  to: DayTime.DAY_END,
  active: true,
  wholeDay: true,
};

const isFullDay = (interval: WorkInterval): boolean => {
  return interval.wholeDay;
};

export const WorkInterval = {
  JSON,
  ...eq,
  FULLDAY_INACTIVE,
  FULLDAY_ACTIVE,
  isFullDay,
};
