import * as React from 'react';
import { View } from 'react-native';

type Props = {
  vertical?: boolean;
  color?: string;
};

const Line = ({ vertical = false, color = '#E0E0E0' }: Props) => {
  return <View style={vertical ? { width: 1, backgroundColor: color } : { height: 1, backgroundColor: color }} />;
};

export default Line;
