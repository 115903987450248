import { moneyC } from '../../memberships/membershipJson';
import { ServiceId } from '../../services/service-id';
import { CheckoutReportPercents } from './checkoutReportPercents';
import { CheckoutReportTotals } from './checkoutReportTotals';
import {
  HasId,
  JSONable,
  MeroUnits,
  PortionPercentScaledNumber,
  PositiveInt,
  Numbers,
  Money,
  ScaledNumber,
} from '@mero/shared-sdk';
import * as t from 'io-ts';

export type CheckoutServiceSalesReportItem<Unit extends MeroUnits.Any> = {
  readonly service: HasId<ServiceId> & {
    readonly name: string;
  };
  /**
   * Total number of times service was found in checkout transaction (including services on a booking)
   */
  readonly count: Numbers.Zero | PositiveInt;
  /**
   * Percentage of total count this service item was purchased
   */
  readonly percent: PortionPercentScaledNumber;
  /**
   * Items totals
   */
  readonly totals: CheckoutReportTotals<Unit>;
  /**
   * Totals percents
   */
  readonly percents: CheckoutReportPercents;
  /**
   * Total quantity of service in checkout transaction (including services on a booking)
   */
  readonly quantity: Numbers.Zero | PositiveInt;
  /**
   * Membership consumption total
   */
  readonly membershipConsumption: Money<ScaledNumber, Unit>;
};

const json = <Unit extends MeroUnits.Any>(unit: Unit): t.Type<CheckoutServiceSalesReportItem<Unit>, JSONable> => {
  return t.type(
    {
      service: t.intersection(
        [
          HasId.json(ServiceId),
          t.type(
            {
              name: t.string,
            },
            '!',
          ),
        ],
        'SaleOwner',
      ),
      count: t.union([Numbers.Zero, PositiveInt.JSON]),
      percent: PortionPercentScaledNumber.JSON,
      totals: CheckoutReportTotals.json(unit),
      percents: CheckoutReportPercents.JSON,
      quantity: t.union([Numbers.Zero, PositiveInt.JSON]),
      membershipConsumption: moneyC(unit),
    },
    `CheckoutServiceSalesReportItem<${unit}>`,
  );
};

export const CheckoutServiceSalesReportItem = {
  json,
};
